import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import { NOTIFICATION } from '../constants/types';
import InlineError from './InlineError';

class EditPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        new_password: '',
      },
      errors: {},
      passwordStrength: '',
      showPasswordInfo: false,
    };
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangePassword = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validatePassword = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;
    if (!data.new_password) {
      errors.new_password = languageMap.required;
    }
    return errors;
  };

  submit = () => {
    let errors = this.validatePassword(this.state.data);
    const languageMap = this.props.languageMap.messages;
    this.setState({
      errors,
    });
    window.console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }
    const params = {
      new_password: this.state.data.new_password,
    };
    this.setState({
      loading: true,
    });
    api.user
      .changeAffiliatePassword(params)
      .then((resp) => {
        this.setState({
          loading: false,
          data: { new_password: '' },
        });
        this.notify(this.props.languageMap.messages.updatedSuccessfully, 'success');
        this.close();
      })
      .catch((error) => {
        console.log(error.response);
        // this.notify('Wrong password.', 'error');
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.password
        ) {
          let errors = {
            new_password: error.response.data.response.password[0],
          };
          this.setState({
            errors: errors,
          });
        } else {
          let errors = {
            old_password: languageMap.wrongPassword,
          };
          this.setState({
            errors: errors,
          });
        }
        this.setState({ loading: false });
      });
  };

  render() {
    const languageMap = this.props.languageMap.components.changePassword;
    const { data, errors, loading } = this.state;

    return (
      <div className="inline-wrapper">
        <div className="key-value">
          <span className="key">{languageMap.inputTwo}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="password"
                id="new_password"
                name="new_password"
                value={data.new_password}
                onChange={this.onChangePassword}
                autoComplete="off"
              />
              {errors.new_password && <InlineError text={errors.new_password} />}
            </div>
          </div>
        </div>
        <div className="key-value">
          <span className="key"></span>
          <div className="value">
            <div className="buttons-wrap">
              <button className={'button primary medium ' + (loading ? 'disabled' : '')} onClick={this.submit}>
                {languageMap.buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPasswordForm.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { showNotification })(EditPasswordForm));
