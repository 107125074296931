import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import cross from '../../images/cross.svg';
import { isMobileDevice } from '../../utils/helper';

let videoTimer = null;
class VideoPlayerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isThumbClicked: !this.props.thumbnailSrc,
    };
    window.console.log(this.props);
  }

  componentDidMount() {
    if (this.props.useYTPlayer) {
      this.initYTPlayer();
    }
    if (this.props.thumbnailSrc && this.shouldShowVideo()) {
      // document.getElementById('yt-player').style.display = 'none';
      document.getElementById('yt-player-thumb').onclick = this.handleThumbnailClick;
    }
  }

  componentWillUnmount() {
    clearInterval(videoTimer);
  }

  initYTPlayer = () => {
    var tag = document.createElement('script');
    tag.id = 'iframe-demo';
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    //Youtube player API expects the function to be available at global scope. Hence binding it to window.
    window.onYouTubeIframeAPIReady = () => {
      //Can be bound to state.
      window.ytPlayerObj = new window.YT.Player('yt-player', {
        events: {
          onReady: window.onPlayerReady,
        },
      });
    };

    window.onPlayerReady = (event) => {
      if (this.props.thumbnailSrc) {
        document.getElementById('yt-player-thumb').onclick = () => {
          event.target.playVideo();
        };
      }
      videoTimer = setInterval(this.recordPlayAttributes, 1000);
    };

    // docReady(() => {
    //   if(this.props.thumbnailSrc && this.shouldShowVideo()) {
    //     document.getElementById('yt-player').style.display = 'none';
    //     document.getElementById('yt-player-thumb').onclick = this.handleThumbnailClick;
    //   }
    // });
  };

  recordPlayAttributes = (trackSkip = false) => {
    const ytPlayer = window.ytPlayerObj;
    if (this.props.playAttrsFlag) {
      let savedAttrs = localStorage.getItem(this.props.playAttrsFlag);
      savedAttrs = (savedAttrs && JSON.parse(savedAttrs)) || {};
      let playAttrs = {
        completedPercentage: savedAttrs.completedPercentage || 0,
        dismissCount: savedAttrs.dismissCount || 0,
      };
      if (ytPlayer && ytPlayer.getCurrentTime) {
        playAttrs.completedPercentage = parseFloat(ytPlayer.getCurrentTime() / ytPlayer.getDuration()).toFixed(4) * 100;
      }

      if (trackSkip) {
        playAttrs.dismissCount = playAttrs.dismissCount + 1;
      }

      localStorage.setItem(this.props.playAttrsFlag, JSON.stringify(playAttrs));
    }
  };

  handleThumbnailClick = () => {
    // document.getElementById('yt-player').style.display = 'block';s
    document.getElementById('yt-player-thumb').style.display = 'none';
    this.setState({
      isThumbClicked: true,
    });
  };

  close = (redirect) => {
    this.recordPlayAttributes(true);
    clearInterval(videoTimer);
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  shouldShowVideo = () => {
    const playAttrs = JSON.parse(localStorage.getItem(this.props.playAttrsFlag));
    if (playAttrs && (playAttrs.completedPercentage > 75 || playAttrs.dismissCount > 2)) {
      //Temporary fix for scroll issue
      $('html').removeClass('fixed');
      return false;
    }
    return true;
  };

  render() {
    let { url, useYTPlayer, thumbnailSrc, shouldAutoPlay } = this.props;
    let { isThumbClicked } = this.state;
    url = useYTPlayer ? url + '?enablejsapi=1' : url;
    url = thumbnailSrc || shouldAutoPlay ? url + '&autoplay=1' : url;
    let shouldShowVideo = this.shouldShowVideo();
    return shouldShowVideo ? (
      <div className="fairdee-modal-wrapper video-player">
        <div className="modal-content">
          {this.state.isThumbClicked && (
            <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          )}
          {(!thumbnailSrc || isThumbClicked) && (
            <div className="video-container">
              <iframe
                id="yt-player"
                title="video"
                src={url}
                allowFullScreen
                width="100%"
                height="500px"
                frameBorder="0"
                allow={thumbnailSrc || shouldAutoPlay ? 'autoplay' : ''}
              >
                {' '}
              </iframe>
            </div>
          )}
          {thumbnailSrc && (
            <div id="yt-player-thumb" onClick={this.handleThumbnailClick}>
              <div className="custom-close" onClick={this.close.bind(null, false)}>
                x
              </div>
              <img width={isMobileDevice() ? '100%' : '50%'} src={thumbnailSrc} />
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}

VideoPlayerModal.propTypes = {
  url: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(VideoPlayerModal));
