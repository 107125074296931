import TextField from '@material-ui/core/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { setFilter } from '../../actions/filter';
import { showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { coaMapping, currentInsurerMapEng, insurerInfoMap } from '../../commonjs/commonData';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import SearchList from '../../components/SearchList';
import { COA_EXCLUDED_INSURER_NAMES } from '../../constants';
import { MODAL_TYPE_THANKYOU, NOTIFICATION } from '../../constants/types';
import { store } from '../../index';
import { errorHandler, getFilterData, getPreviousInsurer, setFilterData } from '../../utils/helper';

const STATE = {
  current_insurer: '',
  errors: {},
  insurerInfo: {},
  data: {
    vehicle_number: '',
    coa_remarks: '',
  },
  updatedData: {},
  fileListLoading: {},
  loading: false,
};

let INSURERS_LIST = [];
const LABEL = 'label',
  FORMAT_PDF = 'formpdf',
  REMARK = 'remark';

const CoaForm = (props) => {
  const [state, setState] = useState(STATE),
    languageMap = useSelector((state) => state.languageMap.components.coaForm),
    messages = useSelector((state) => state.languageMap.messages),
    insurers_props = useSelector((state) => state.insurers),
    [filterData, set_Filter_Data] = useState({}),
    [fileList, setFileList] = useState({}),
    [currentFocus, setCurrentFocus] = useState(props.currentFocus),
    vehicleNumberRef = useRef(),
    coaRemarksRef = useRef();

  useEffect(() => {
    let filterData = getFilterData(),
      currentInsurer = getPreviousInsurer(filterData.current_insurer || ''),
      name = insurers_props[currentInsurer] ? insurers_props[currentInsurer].name : '',
      insInfo = insurerInfoMap[name],
      filteredInsurers = Object.keys(insurers_props).filter((key) => currentInsurerMapEng[insurers_props[key].name]);

    INSURERS_LIST = filteredInsurers.map((key) => ({
      name: currentInsurerMapEng[insurers_props[key].name],
      value: currentInsurerMapEng[insurers_props[key].name],
      id: currentInsurerMapEng[insurers_props[key].name],
    }));
    INSURERS_LIST = INSURERS_LIST.filter((ins) => !COA_EXCLUDED_INSURER_NAMES.includes(ins.value));

    set_Filter_Data((prevState) => ({ ...prevState, filterData: filterData || {} }));
    setState((prevState) => ({
      ...prevState,
      insurerInfo: insInfo || {},
      current_insurer: state.current_insurer || filterData.current_insurer,
    }));
  }, [insurers_props]);

  useEffect(() => {
    if (currentFocus === 'vehicle_number') {
      vehicleNumberRef.current.focus();
    }
    if (currentFocus === 'coa_remarks') {
      coaRemarksRef.current.focus();
    }
  }, [currentFocus]);

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function openThankYouModal() {
    let fileList = {};
    setFileList((prevState) => [...prevState, fileList]);
    props.showModal(MODAL_TYPE_THANKYOU, {});
  }

  function handleChange(event) {
    setState({
      ...state,
      data: { ...state.data, [event.target.name]: event.target.value },
      updatedData: { ...state.updatedData, [event.target.name]: event.target.value },
    });
  }

  function handleBlur(event) {
    let currentFocus = '';
    if (event.target.name === 'vehicle_number') {
      currentFocus = event.target.value ? 'coa_remarks' : 'vehicle_number';
    }
    setCurrentFocus(currentFocus);
  }

  function uploadFileApiCall(params) {
    return api.utils.postCoaDocument(params);
  }

  function fileUploaded(file, name) {
    if (!Array.isArray(file)) {
      file = [file];
    }
    let fileListCopy = { ...fileList };
    fileListCopy[name] = fileListCopy[name] || [];
    fileListCopy[name] = [...fileListCopy[name], ...file];
    setFileList(fileListCopy);
  }

  function deleteDocument(data, name) {
    let fileListCopy = fileList;
    fileListCopy[name] = fileListCopy[name].filter((d, ind) => ind !== data.index);
    setFileList(fileListCopy);
  }

  function handlePreviousInsurerChange(e, data) {
    if (!data.value) {
      return;
    }
    let currentInsurer = getPreviousInsurer(data.value),
      name = insurers_props[currentInsurer].name,
      insInfo = insurerInfoMap[name],
      filterData = getFilterData(),
      currentFocusCopy = data.value ? 'vehicle_number' : props.currentFocus;

    filterData.current_insurer = data.value;
    setFilterData(filterData);
    store.dispatch(setFilter(filterData));
    setCurrentFocus(currentFocusCopy);
    setState({ ...state, insurerInfo: insInfo || {} });
    if (currentInsurer) {
      currentInsurer = parseInt(currentInsurer, 10);
      setState((prevState) => ({
        ...prevState,
        current_insurer: data.value,
      }));
    } else {
      notify(messages.noInsurerInFairdee, 'info');
    }
  }

  function validate() {
    const errors = {};
    if (!fileList.old_policy || !fileList.old_policy.length) {
      errors.old_policy = messages.required;
    }
    if (!state.data.vehicle_number) {
      errors.vehicle_number = messages.required;
    } else if (
      state.data.vehicle_number !== 'ป้ายแดง' &&
      isNaN(state.data.vehicle_number.substr(state.data.vehicle_number.length - 1))
    ) {
      errors.vehicle_number = messages.invalidFormat;
    }

    return errors;
  }

  function save() {
    let vehicleData = {
      ...state.data,
      ...props.data,
    };
    vehicleData.current_insurer = state.current_insurer;
    let errors = validate(vehicleData),
      filterData = { vehicleData } || {},
      extra_filters = {},
      { current_insurer, insurance_class, sum_insured } = state;
    setState((prevState) => ({
      ...prevState,
      errors,
    }));

    errors = { ...errors, ...props.validate(vehicleData, true) };
    if (Object.keys(errors).length) {
      return;
    }

    props.setLoader(true);

    if (filterData.min_suminsured) {
      extra_filters.min_suminsured = filterData.min_suminsured;
    }
    if (filterData.max_suminsured) {
      extra_filters.max_suminsured = filterData.max_suminsured;
    }
    if (filterData.garage_type && filterData.garage_type.length) {
      extra_filters.garage_type = filterData.garage_type;
    }
    if (filterData.province) {
      extra_filters.province = filterData.province;
    }
    let params = {
      ...filterData,
      make_model: filterData.vehicleData.make_model_id,
      cc: filterData.vehicleData.cc_id,
      year: filterData.vehicleData.manufacture_year,
      extra_filters,
      model_description: filterData.vehicleData.model_description_id,
      for_commercial_vehicle: Boolean(filterData.vehicleData.for_commercial_vehicle),
      vehicle_number: filterData.vehicleData.vehicle_number,
      coa_remarks: filterData.vehicleData.coa_remarks || '',
    };
    if (sum_insured) {
      params.sum_insured = sum_insured;
    }
    if (filterData.vehicleData.insurance_class && filterData.vehicleData.insurance_class.length) {
      params.insurance_class = filterData.vehicleData.insurance_class.join(',');
    }
    if (current_insurer) {
      params.current_insurer = current_insurer;
    }
    if (filterData.vehicleData.sum_insured) {
      params.sum_insured = filterData.vehicleData.sum_insured;
    }
    params.change_of_agent = true;
    params.price_list_ids = [];
    delete params.vehicleData;
    api.utils
      .quotationQuery(params)
      .then((resp) => {
        let fileListCopy = fileList;
        Object.keys(fileListCopy).forEach((key, index) => {
          let promises = [],
            fileListLoading = state.fileListLoading,
            file = fileListCopy[key];
          fileListLoading[key] = true;
          if (Array.isArray(file)) {
            file.forEach((f) => {
              let params = new FormData();
              params.append('fairdee_quotation_query_id', parseInt(resp.message.id, 10));
              params.append('document_type', key);
              params.append('file', f);
              promises.push(uploadFileApiCall(params));
            });
          } else {
            let params = new FormData();
            params.append('fairdee_quotation_query_id', parseInt(resp.message.id, 10));
            params.append('document_type', key);
            params.append('file', file);
            promises.push(uploadFileApiCall(params));
          }
          Promise.all(promises)
            .then(() => {
              fileListLoading[key] = false;
              if (index === Object.keys(fileListCopy).length - 1) {
                openThankYouModal();
                props.setLoader(false);
              }
            })
            .catch((error) => {
              let errors = errorHandler(error.response);
              fileListLoading[key] = false;
              Object.keys(errors).forEach((key, index) => {
                ((i) => {
                  setTimeout(() => {
                    notify(`${key} -> ${errors[key]}`, 'error');
                  }, 1000 + 2000 * i);
                })(index);
              });
              props.setLoader(false);
              setState((prevState) => ({
                ...prevState,
                loading: false,
                errors,
                fileListLoading,
              }));
            });
        });
      })
      .catch((error) => {
        errorHandler(error.response, true);
        props.setLoader(false);
      });
  }

  function renderCoaText() {
    const insurerInfo = state,
      current_insurer = state.current_insurer || filterData.current_insurer;
    if (coaMapping[current_insurer]) {
      return <p className="text mb-12">{coaMapping[current_insurer][LABEL]}</p>;
    } else {
      return (
        <p className="text mb-12">
          {languageMap.textSix} {insurerInfo.agentCode}
        </p>
      );
    }
  }

  function renderCoaRemark() {
    const current_insurer = state.current_insurer || filterData.current_insurer;
    if (coaMapping[current_insurer] && coaMapping[current_insurer][REMARK]) {
      return (
        <div className="desc-wrap">
          <p>{coaMapping[current_insurer][REMARK]}</p>
        </div>
      );
    }
  }

  function renderCoaDownloadAndUploadForm() {
    const current_insurer = state.current_insurer || filterData.current_insurer;
    if (coaMapping[current_insurer] && coaMapping[current_insurer][FORMAT_PDF]) {
      return (
        <div>
          <div className="add-coa-form-wrap">
            <p className="coa-title-text">{languageMap.form}</p>
          </div>
          <div className="coa-form-download-wrap">
            <a
              className="button orange-bordered-button"
              href={coaMapping[current_insurer][FORMAT_PDF]}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              {languageMap.downloadForm}
            </a>
            <div className="form-upload-wrap">
              <FileUpload
                download={true}
                files={fileList['coa_form'] ? fileList['coa_form'] : []}
                loading={state.fileListLoading['coa_form']}
                name="coa_form"
                onFileUpload={fileUploaded}
                onFileRemove={(data) => deleteDocument(data, 'coa_form')}
                limit={1}
                multiple={false}
                btnText={languageMap.textTen}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="coa-form">
      <SearchList
        id="current_insurer"
        label={languageMap.textEleven + '<span>*</span>'}
        onChange={handlePreviousInsurerChange}
        options={INSURERS_LIST}
        value={state.current_insurer}
        name="current_insurer"
        search={true}
        isOpen={true}
        error={state.errors.current_insurer ? true : false}
        helpertext={state.errors.current_insurer}
        autoFocus={Boolean(props.currentFocus === 'current_insurer')}
      />
      <div>
        <div className="element-wrap">
          <TextField
            id="vehicle_number"
            label={languageMap.textTwo}
            className="input"
            value={state.data.vehicle_number}
            margin="normal"
            fullWidth
            error={state.errors.vehicle_number ? true : false}
            helperText={state.errors.vehicle_number}
            onChange={handleChange}
            autoComplete="off"
            name="vehicle_number"
            required
            inputRef={vehicleNumberRef}
            onBlur={handleBlur}
          />
          {state.errors && state.errors.vehicle_number && (
            <div className="error-info">
              <p className="title">{languageMap.vehicleNumberError.textOne}</p>
              <p className="text">{languageMap.vehicleNumberError.textTwo}</p>
              <p className="text">{languageMap.vehicleNumberError.textThree}</p>
              <p className="text">{languageMap.vehicleNumberError.textFour}</p>
            </div>
          )}
        </div>
        <div className="file-upload-wrap">
          <p className="title-text">{languageMap.textThree}</p>
          <FileUpload
            download={true}
            files={fileList['old_policy'] ? fileList['old_policy'] : []}
            loading={state.fileListLoading['old_policy']}
            name="old_policy"
            onFileUpload={fileUploaded}
            onFileRemove={(data) => deleteDocument(data, 'old_policy')}
            limit={2}
            multiple={true}
            btnText={languageMap.textTen}
          />
          <p className="text">{languageMap.textFour}</p>
          {state.errors.old_policy && <InlineError text={state.errors.old_policy} className={'error'} />}
        </div>
        <div className="file-upload-wrap">
          <p className="title-text">{languageMap.textFive}</p>
          {renderCoaText()}
          <FileUpload
            download={true}
            files={fileList['national_id']}
            loading={state.fileListLoading['national_id']}
            name="national_id"
            onFileUpload={fileUploaded}
            onFileRemove={(data) => deleteDocument(data, 'national_id')}
            limit={2}
            multiple={true}
            btnText={languageMap.textTen}
          />
          {state.errors.national_id && <InlineError text={state.errors.national_id} />}
        </div>
        {renderCoaDownloadAndUploadForm()}
        <div className="element-wrap">
          <TextField
            id="coa_remarks"
            label={languageMap.coaRemarks}
            className="input"
            value={state.data.coa_remarks}
            margin="normal"
            fullWidth
            error={state.errors.coa_remarks ? true : false}
            helperText={state.errors.coa_remarks}
            onChange={handleChange}
            autoComplete="off"
            name="coa_remarks"
            inputRef={coaRemarksRef}
          />
          {state.errors.national_id && <div className="error-info">{state.errors.national_id}</div>}
        </div>
        {renderCoaRemark()}
        <div className="button-wrap">
          <button className="button primary" onClick={save}>
            {languageMap.textNine}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(null, { showModal, showNotification })(CoaForm));
