import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setFilter } from '../../actions/filter';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { coaMapping, currentInsurerMapEng, insurerInfoMap } from '../../commonjs/commonData';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import SearchList from '../../components/SearchList';
import { COA_EXCLUDED_INSURER_NAMES } from '../../constants/index';
import { MODAL_TYPE_THANKYOU, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { store } from '../../index';
import {
  errorHandler,
  getAffiliateAttributes,
  getFilterData,
  getPreviousInsurer,
  setFilterData,
} from '../../utils/helper';
import { actions, events } from '../../utils/trackingEvents';

let INSURERS_LIST = [];
const imageUrl = 'imageUrl';
const label = 'label';
const formpdf = 'formpdf';
const remark = 'remark';
class CoaForm extends React.Component {
  constructor(props) {
    super(props);
    this.filterData = getFilterData() || {};
    this.state = {
      fileListLoading: {},
      fileList: {},
      errors: {},
      errors: {},
      loading: false,
      updatedData: {},
      data: {
        vehicle_number: '',
        coa_remarks: '',
      },
      current_insurer: '',
      insurerInfo: {},
      isOpen: false,
    };

    let insurers = Object.keys(this.props.insurers).filter(
      (key) => currentInsurerMapEng[this.props.insurers[key].name]
    );

    INSURERS_LIST = insurers.map((key) => {
      return {
        name: currentInsurerMapEng[this.props.insurers[key].name],
        value: currentInsurerMapEng[this.props.insurers[key].name],
        id: currentInsurerMapEng[this.props.insurers[key].name],
      };
    });
    INSURERS_LIST = INSURERS_LIST.filter((ins) => !COA_EXCLUDED_INSURER_NAMES.includes(ins.value));
  }

  componentDidMount() {
    let filterData = getFilterData(),
      currentInsurer = getPreviousInsurer(filterData.current_insurer || ''),
      name = this.props.insurers[currentInsurer] ? this.props.insurers[currentInsurer].name : '',
      insInfo = insurerInfoMap[name];

    this.setState({
      insurerInfo: insInfo || {},
      current_insurer: this.state.current_insurer || filterData.current_insurer,
    });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  openThankYouModal = () => {
    this.props.showModal(MODAL_TYPE_THANKYOU, {});
  };

  validate = () => {
    let messages = this.props.languageMap.messages;
    const errors = {};
    if (!this.state.data.vehicle_number) {
      errors.vehicle_number = messages.required;
    }
    if (!this.state.fileList.old_policy || !this.state.fileList.old_policy.length) {
      errors.old_policy = messages.required;
    }
    // if (!this.state.fileList.national_id || !this.state.fileList.national_id.length) {
    //   errors.national_id = messages.required;
    // }
    if (
      this.state.data.vehicle_number &&
      this.state.data.vehicle_number !== 'ป้ายแดง' &&
      isNaN(this.state.data.vehicle_number.substr(this.state.data.vehicle_number.length - 1))
    ) {
      errors.vehicle_number = messages.invalidFormat;
    }
    return errors;
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      updatedData: { ...this.state.updatedData, [event.target.name]: event.target.value },
    });
  };

  handlePreviousInsurerChange = (e, data) => {
    if (!data.value) {
      return;
    }
    let currentInsurer = getPreviousInsurer(data.value),
      name = this.props.insurers[currentInsurer].name,
      insInfo = insurerInfoMap[name];

    this.setState({
      insurerInfo: insInfo || {},
    });
    // const k = data.value;
    // console.log(k, coaMapping[k]['imageUrl']);
    if (currentInsurer) {
      this.currentInsurer = parseInt(currentInsurer, 10);
      this.setState({
        current_insurer: data.value,
      });
      let filterData = getFilterData();
      filterData.current_insurer = data.value;
      setFilterData(filterData);
      store.dispatch(setFilter(filterData));
    } else {
      let filterData = getFilterData();
      filterData.current_insurer = data.value;
      setFilterData(filterData);
      store.dispatch(setFilter(filterData));
      this.notify(this.props.languageMap.messages.noInsurerInFairdee, 'info');
      this.close();
    }
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  save = () => {
    let errors = this.validate(this.state.updatedData);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      window.trackEvent &&
        window.trackEvent.track(actions.Clicked, `${events.getQuotesCOAModalSendApproval} ${actions.Clicked}`, {
          quotationType: 'COA',
          quoatationQueryId: this.props.id,
          action: 'Unsuccessful',
          ...getAffiliateAttributes(),
        });
      return;
    }
    let current_insurer =
      getPreviousInsurer(this.filterData.current_insurer) || getPreviousInsurer(this.state.current_insurer);
    let params = Object.assign({}, this.state.updatedData);

    params.change_of_agent = true;
    if (current_insurer) {
      params.current_insurer_obj = parseInt(current_insurer, 10);
    }
    api.utils
      .patchQuotationQuery(this.props.id, params)
      .then((resp) => {
        window.trackEvent &&
          window.trackEvent.track(actions.Clicked, `${events.getQuotesCOAModalSendApproval} ${actions.Clicked}`, {
            quotationType: 'COA',
            quoatationQueryId: this.props.id,
            action: 'Successful',
            ...getAffiliateAttributes(),
          });
        this.openThankYouModal();
        this.close();
      })
      .catch((error) => console.log(error));
  };

  uploadFileApiCall = (params) => {
    return api.utils.postCoaDocument(params);
  };

  fileUploaded = (file, name) => {
    let promises = [];
    let fileListLoading = this.state.fileListLoading;
    fileListLoading[name] = true;
    this.setState({ fileListLoading });

    if (Array.isArray(file)) {
      file.forEach((f) => {
        let params = new FormData();
        params.append('fairdee_quotation_query_id', parseInt(this.props.id, 10));
        params.append('document_type', name);
        params.append('file', f);
        promises.push(this.uploadFileApiCall(params));
      });
    } else {
      let params = new FormData();
      params.append('fairdee_quotation_query_id', parseInt(this.props.id, 10));
      params.append('document_type', name);
      params.append('file', file);
      promises.push(this.uploadFileApiCall(params));
    }

    Promise.all(promises)
      .then((response) => {
        fileListLoading[name] = false;
        let fileList = this.state.fileList;
        response.forEach((resp, index) => {
          if (Array.isArray(file)) {
            file[index].id = resp.message.id;
            file[index].document_type = name;
          } else {
            file.id = resp.message.id;
            file.document_type = name;
          }

          if (!fileList[name]) {
            fileList[name] = [file[index] || file];
          } else {
            fileList[name].push(file[index] || file);
          }
        });
        this.setState({ fileListLoading, fileList });
      })
      .catch((error) => {
        fileListLoading[name] = false;
        let errors = errorHandler(error.response);
        Object.keys(errors).forEach((key, index) => {
          ((i) => {
            setTimeout(() => {
              this.notify(`${key} -> ${errors[key]}`, 'error');
            }, 1000 + 2000 * i);
          })(index);
        });

        this.setState({
          loading: false,
          errors,
          fileListLoading,
        });
      });
  };

  deleteDocument = (data) => {
    api.utils
      .deleteCoaDocument(data.id)
      .then((resp) => {
        let fileList = this.state.fileList;
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        this.setState(
          {
            fileList,
          },
          () => {
            window.console.log(this.state.fileList);
          }
        );
        this.notify('Deleted Successfully', 'success');
      })
      .catch((error) => {
        console.log(error);
        this.notify('Something went wrong.', 'error');
      });
  };

  renderCoaImage = () => {
    const current_insurer = this.state.current_insurer || this.filterData.current_insurer;
    const languageMap = this.props.languageMap.components.coaForm;
    if (coaMapping[current_insurer]) {
      return (
        <div className="coa-img-wrap">
          <img src={coaMapping[current_insurer][imageUrl]} height="350" width="370"></img>
        </div>
      );
    } else {
      return (
        <div className="desc-wrap">
          <p>{languageMap.textSeven}</p>
          <p>{languageMap.textEight}</p>
        </div>
      );
    }
  };

  renderCoaText = () => {
    const insurerInfo = this.state;
    const current_insurer = this.state.current_insurer || this.filterData.current_insurer;
    const languageMap = this.props.languageMap.components.coaForm;
    if (coaMapping[current_insurer]) {
      return <p className="text mb-12">{coaMapping[current_insurer][label]}</p>;
    } else {
      return (
        <p className="text mb-12">
          {languageMap.textSix} {insurerInfo.agentCode}
        </p>
      );
    }
  };

  renderCoaRemark = () => {
    const current_insurer = this.state.current_insurer || this.filterData.current_insurer;
    const languageMap = this.props.languageMap.components.coaForm;
    if (coaMapping[current_insurer] && coaMapping[current_insurer][remark]) {
      return (
        <div className="desc-wrap">
          <p>{coaMapping[current_insurer][remark]}</p>
        </div>
      );
    }
  };

  renderCoaDownloadAndUploadForm = () => {
    const { fileListLoading, fileList } = this.state;
    const current_insurer = this.state.current_insurer || this.filterData.current_insurer;
    const languageMap = this.props.languageMap.components.coaForm;
    if (coaMapping[current_insurer] && coaMapping[current_insurer][formpdf]) {
      return (
        <div>
          <div className="add-coa-form-wrap">
            <p className="coa-title-text">{languageMap.form}</p>
          </div>
          <div className="coa-form-download-wrap">
            <a
              className="button orange-bordered-button"
              href={coaMapping[current_insurer][formpdf]}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              {languageMap.downloadForm}
            </a>
            <div className="form-upload-wrap">
              <FileUpload
                download={true}
                files={fileList['coa_form']}
                loading={fileListLoading['coa_form']}
                name="coa_form"
                onFileUpload={this.fileUploaded}
                onFileRemove={this.deleteDocument}
                limit={1}
                multiple={false}
                btnText={languageMap.textTen}
              />
            </div>
          </div>
        </div>
      );
    }
  };
  render() {
    const { fileListLoading, fileList, errors, data, current_insurer } = this.state;
    const languageMap = this.props.languageMap.components.coaForm;

    return (
      <div className="fairdee-modal-wrapper customer-info coa-form">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">&nbsp;</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <div className="fairdee-form">
            {
              <div className="mt-24">
                <SearchList
                  id="current_insurer"
                  label={languageMap.textEleven}
                  onChange={this.handlePreviousInsurerChange}
                  options={INSURERS_LIST}
                  value={current_insurer}
                  name="current_insurer"
                  search={true}
                  isOpen={true}
                  error={errors.current_insurer ? true : false}
                  helpertext={errors.current_insurer}
                  autoFocus={true}
                />
              </div>
            }
            {current_insurer || getPreviousInsurer(this.filterData.current_insurer) ? (
              <div>
                <div className="element-wrap">
                  <TextField
                    id="vehicle_number"
                    label={languageMap.textTwo}
                    className="input"
                    value={data.vehicle_number}
                    margin="normal"
                    fullWidth
                    error={errors.vehicle_number ? true : false}
                    helperText={errors.vehicle_number}
                    onChange={this.handleChange}
                    autoComplete="off"
                    name="vehicle_number"
                    autoFocus={true}
                  />
                  {errors.vehicle_number && (
                    <div className="error-info">
                      <p className="title">{languageMap.vehicleNumberError.textOne}</p>
                      <p className="text">{languageMap.vehicleNumberError.textTwo}</p>
                      <p className="text">{languageMap.vehicleNumberError.textThree}</p>
                      <p className="text">{languageMap.vehicleNumberError.textFour}</p>
                    </div>
                  )}
                </div>
                <div className="file-upload-wrap">
                  <p className="title-text">{languageMap.textThree}</p>
                  <FileUpload
                    download={true}
                    files={fileList['old_policy']}
                    loading={fileListLoading['old_policy']}
                    name="old_policy"
                    onFileUpload={this.fileUploaded}
                    onFileRemove={this.deleteDocument}
                    limit={2}
                    multiple={true}
                    btnText={languageMap.textTen}
                  />
                  <p className="text">{languageMap.textFour}</p>
                  {errors.old_policy && <InlineError text={errors.old_policy} />}
                </div>
                <div className="file-upload-wrap">
                  <p className="title-text">{languageMap.textFive}</p>
                  {this.renderCoaText()}
                  <FileUpload
                    download={true}
                    files={fileList['national_id']}
                    loading={fileListLoading['national_id']}
                    name="national_id"
                    onFileUpload={this.fileUploaded}
                    onFileRemove={this.deleteDocument}
                    limit={2}
                    multiple={true}
                    btnText={languageMap.textTen}
                  />
                  {errors.national_id && <InlineError text={errors.national_id} />}
                </div>
                {this.renderCoaDownloadAndUploadForm()}
                <div className="element-wrap">
                  <TextField
                    id="coa_remarks"
                    label={languageMap.coaRemarks}
                    className="input"
                    value={data.coa_remarks}
                    margin="normal"
                    fullWidth
                    error={errors.coa_remarks ? true : false}
                    helperText={errors.coa_remarks}
                    onChange={this.handleChange}
                    autoComplete="off"
                    name="coa_remarks"
                  />
                  {errors.national_id && <div className="error-info">{errors.national_id}</div>}
                </div>
                {this.renderCoaImage()}
                {this.renderCoaRemark()}
                <div className="button-wrap">
                  <button className="button primary" onClick={this.save}>
                    {languageMap.textNine}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
CoaForm.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    insurers: state.insurers,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal, showNotification })(CoaForm));
