import React from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import apiEndpoints from '../../commonjs/apiEndpoints';
import crossLg from '../../images/cross-lg.svg';
import cross from '../../images/cross.svg';
import { isMobileDevice } from '../../utils/helper';

const tableHeadersMap = [
  'vehicle_number',
  'client_full_name',
  'policy_end_date',
  'insurer_name',
  'original_sum_insured',
  'original_premium',
  'plan_name',
  'sum_insured',
  'new_premium',
  'garage_type',
  'affiliate_discount',
  'affiliate_commission',
];

function RenewedQuotationModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.renewedQuotation);
  function close() {
    props.hideModal(null, { ...props });
  }

  window.console.log('props.renewedQuotation', props.renewedQuotation);

  function downloadDocument() {
    const link = document.createElement('a');

    link.textContent = 'download';
    link.href = `${apiEndpoints.quotationDocumentUpload}/${props.renewedQuotation.fileList['renewal_receipt'][0].id}?resp_format=pdf&download=true`;
    link.setAttribute('download', `Renewal-Receipt`);
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={`fairdee-modal-wrapper file-viewer full-width`}>
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">{languageMap.renewedPriceList}</span>
          </p>
        </div>
        <h1 className="title-text">{languageMap.renewedPriceList}</h1>
        <div className={`crm-table-wrapper ${isMobileDevice() ? 'vertical-table' : ''}`}>
          {!isMobileDevice() ? (
            <table>
              <thead>
                <tr>
                  {tableHeadersMap.map((header) => (
                    <th>{languageMap.tableHeaders[header]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.keys(props.renewedQuotation)
                    .filter((key) => tableHeadersMap.indexOf(key) > -1)
                    .map((key) => (
                      <td>{props.renewedQuotation[key]}</td>
                    ))}
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              {tableHeadersMap.map((header) => (
                <tr>
                  <th>{languageMap.tableHeaders[header]}</th>
                  <td>{props.renewedQuotation[header] || '-'}</td>
                </tr>
              ))}
            </table>
          )}
        </div>
        {props.renewedQuotation &&
          props.renewedQuotation.fileList &&
          props.renewedQuotation.fileList['renewal_receipt'] &&
          props.renewedQuotation.fileList['renewal_receipt'].length > 0 && (
            <div className="button-wrap centered">
              <div className="button primary medium" onClick={downloadDocument}>
                Download Renewal Receipt
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(RenewedQuotationModal);
