import firebase from 'firebase/app';
import 'firebase/messaging';
import api from './commonjs/api';
import { getLocalData, isMobileDevice, setLocalData } from './utils/helper';
import NotificationPopup from './utils/NotificationPopup';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDYGGzPmWI587sF3iJ2cZ_eZ8Jl6surTlE',
  authDomain: 'fairdee.firebaseapp.com',
  databaseURL: 'https://fairdee.firebaseio.com',
  projectId: 'fairdee',
  storageBucket: 'fairdee.appspot.com',
  messagingSenderId: '670918406847',
  appId: '1:670918406847:web:22ba3c76124d79c8ac0c0a',
  measurementId: 'G-CEWB28PT4T',
};

/* global firebase */
const PushNotification = (() => {
  if (!FIREBASE_CONFIG) {
    return;
  }

  let messaging = undefined;
  firebase.initializeApp(FIREBASE_CONFIG);
  // Check for if a browser supports firebase sdk api.
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      let message = payload.data.body,
        dl = decodeURIComponent(payload.data.dl);
      if (!isMobileDevice()) {
        NotificationPopup.open(message, dl);
      }
    });

    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
      messaging.useServiceWorker(registration);
    });

    function init() {
      const firebaseToken = getLocalData('firebaseToken');
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken !== firebaseToken) {
            setLocalData('firebaseToken', currentToken);
            sendTokenToServer(currentToken);
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }

    function sendTokenToServer(currentToken) {
      api.communicator
        .postFCMToken({
          token: currentToken,
          app_context: 'agent_web',
          device_metadata: JSON.stringify({
            platform: window.navigator.platform,
            appVersion: window.navigator.appVersion,
            userAgent: window.navigator.userAgent,
          }),
        })
        .then(function (response) {
          if (!response.id) {
            setLocalData('firebaseToken', null);
          }
        });
    }

    function unregister() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrations) => {
            registrations.map((registration, _index) => {
              registration.unregister().catch((_error) => {});
            });
          })
          .catch((_error) => {});
      }
    }
    return {
      init,
      unregister,
    };
  }
})();
export default PushNotification;
