import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

interface Props {
  close?: () => void;
}

const InfoModal: React.FC<Props> = ({ close }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);
  return (
    <div className="fairdee-modal">
      <div className="modal-wrapper">
        <div className="header">
          <p className="title-text border">{languageMap['Contact us!']} </p>
        </div>
        <div className="content">
          <p className="text">
            {languageMap['Mondays to Fridays 09:00 - 18:00']} <br />
            <a href="tel:064-282-2078" className="phone">
              {languageMap['064-282-2078']}{' '}
            </a>{' '}
            <br />
            {languageMap['Mondays to Saturdays 09:00 - 18:00 ']} <br />
            <a href="tel:02-114-7920" className="phone">
              {languageMap['02-114-7920 press 1']}
            </a>{' '}
            <br />
          </p>
        </div>
        <div className="button-wrapper">
          <Button className="orange-button" onClick={close}>
            {languageMap[`Got it!`]}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
