import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { store } from '../..';
import { daysInMonth, monthByNumber, unit } from '../../constants/dateDefaults';
import '../../scss/datepicker/_dropdown-datepicker.scss';

class DropdownDatepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startYear: null,
      startMonth: null,
      startDay: null,
      endYear: null,
      endMonth: null,
      endDay: null,
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
      selectedDate: '',
      selectedMonthOfYear: '',
    };

    this.renderParts = {
      year: this.renderYear.bind(this),
      month: this.renderMonth.bind(this),
      day: this.renderDay.bind(this),
      monthOfYear: this.renderMonthOfYear.bind(this),
    };
  }

  componentWillMount() {
    let startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
      selectedYear,
      selectedMonth,
      selectedDay,
      selectedMonthOfYear;

    if (this.props.startDate) {
      const date = moment(this.props.startDate);
      startYear = +date.format('YYYY');
      startMonth = +date.format('MM') - 1;
      startDay = +date.format('DD');
    } else {
      if (this.props.isThaiDate) {
        startYear = 1900 + 543;
      } else {
        startYear = 1900;
      }
      startMonth = 0;
      startDay = 1;
    }
    if (this.props.endDate) {
      const date = moment(this.props.endDate);
      endYear = +date.format('YYYY');
      endMonth = +date.format('MM') - 1;
      endDay = +date.format('DD');
    } else {
      let date = null;
      if (this.props.startDate) {
        let duration = ['100', 'years'];
        if (this.props.duration) {
          duration = this.props.duration.split(' ');
        }
        date = moment(this.props.startDate).add(parseInt(duration[0]), duration[1]);
      } else {
        if (this.props.isThaiDate) {
          date = moment().add(543, 'years');
        } else {
          date = moment();
        }
      }
      endYear = +date.format('YYYY');
      endMonth = +date.format('MM') - 1;
      endDay = +date.format('DD');
    }
    if (this.props.selectedDate) {
      const date = this.checkHolidayAndGetNext(this.props.selectedDate);
      selectedYear = date.year();
      selectedMonth = date.month();
      selectedDay = date.date();
      if (this.props.onDateChange) {
        this.props.onDateChange(moment(this.props.selectedDate).format('YYYY-MM-DD'));
      }
    } else {
      selectedYear = '';
      selectedMonth = '';
      selectedDay = '';
    }
    selectedMonthOfYear = `${selectedMonth}-${selectedYear}`;
    this.setState({
      startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
      selectedYear,
      selectedMonth,
      selectedDay,
      selectedMonthOfYear,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.startDate !== this.props.startDate && nextProps.canChangeStartDate) {
      let startYear, startMonth, startDay;
      const date = moment(nextProps.startDate);
      startYear = +date.format('YYYY');
      startMonth = +date.format('MM') - 1;
      startDay = +date.format('DD');
      this.setState({ startYear, startMonth, startDay });
    }
    if (
      nextProps.selectedDate !== this.props.selectedDate ||
      (this.state.selectedDate !== nextProps.selectedDate && nextProps.selectedDate)
    ) {
      if (!nextProps.selectedDate) {
        this.setState({ selectedMonthOfYear: null });
        return;
      }
      const format = this.props?.format || 'YYYY-MM-DD',
        date = this.checkHolidayAndGetNext(nextProps.selectedDate),
        formattedDate = date?.format(format);

      if (this.props.onDateChange) {
        this.props.onDateChange(formattedDate);
      }
      this.setState({
        selectedDate: formattedDate,
      });
      const selectedYear = date.year();
      const selectedMonth = date.month();
      const selectedDay = date.date();
      const selectedMonthOfYear = `${selectedMonth}-${selectedYear}`;
      this.setState({ selectedYear, selectedMonth, selectedDay, selectedMonthOfYear });
    }

    if (nextProps.duration && nextProps.duration !== this.props.duration) {
      const duration = nextProps.duration.split(' ');
      let startDate = null;
      if (this.props.startDate) {
        startDate = this.props.startDate;
      } else {
        if (this.props.isThaiDate) {
          date = moment().add(543, 'years');
        } else {
          date = moment();
        }
      }

      let date = moment(startDate).add(parseInt(duration[0]), duration[1]),
        endYear = +date.format('YYYY'),
        endMonth = +date.format('MM') - 1,
        endDay = +date.format('DD');

      this.setState({ endYear, endMonth, endDay });
    }
  }

  checkHolidayAndGetNext = (date) => {
    if (!date) {
      return null;
    }
    const thaiHolidays = store.getState().holidays;
    let now = moment();
    if (this.props.isThaiDate) {
      now.add(543, 'years');
    }
    date = moment(`${date} ${now.format('HH:mm:ss')}`);
    if (!date.isValid()) {
      date = now;
    }
    if (this.props.blockHolidays) {
      let additionalBlockedDays = this.props.additionalBlockedDays || [];
      additionalBlockedDays.concat(thaiHolidays).map((dateRange) => {
        if (date.isAfter(dateRange[0]) && date.isBefore(dateRange[1])) {
          date = moment(dateRange[1]).add(1, 'days');
          return true;
        }
        return false;
      });
    }
    return date;
  };

  generateYearOptions = () => {
    const { startYear, endYear } = this.state;
    const yearOptions = [];
    if (this.props.defaultValues && this.props.defaultValues.year) {
      yearOptions.push({
        text: this.props.defaultValues.year,
        value: this.props.defaultValues.year,
      });
    }

    if (this.props.options && this.props.options.yearReverse) {
      for (let i = +endYear; i >= +startYear; i--) {
        yearOptions.push({
          text: i,
          value: i,
        });
      }
    } else {
      for (let i = +startYear; i <= +endYear; i++) {
        yearOptions.push({
          text: i,
          value: i,
        });
      }
    }
    const yearList = yearOptions.map((year) => year.value);
    if (this.state.selectedYear && yearList.indexOf(this.state.selectedYear) === -1) {
      this.handleYearChange(null, { value: yearList[yearList.length - 1] });
    }

    return yearOptions;
  };

  generateMonthOfYearOptions = () => {
    const languageMapMonths = this.props.languageMap.common.months;
    const { startYear, endYear, startMonth, endMonth } = this.state;
    const monthOfYearOptions = [];
    const years = [];
    //Populate year arry - will be used to iterate and fill months later
    for (let i = +startYear; i <= +endYear; i++) {
      years.push(i);
    }

    years.forEach((year) => {
      let init = 0;
      let limit = 11;
      if (year === startYear) {
        init = startMonth;
      }
      if (year === endYear) {
        limit = endMonth;
      }

      for (let i = init; i <= limit; i++) {
        monthOfYearOptions.push({
          value: `${i}-${year}`,
          text: `${languageMapMonths[i]} ${year}`,
        });
      }
    });

    return monthOfYearOptions;
  };

  generateMonthOptions = () => {
    const languageMapMonths = this.props.languageMap.common.months;
    const { startMonth, endMonth, startYear, endYear, selectedYear } = this.state;
    let months = [];
    if (this.props.defaultValues && this.props.defaultValues.month) {
      months.push({
        text: this.props.defaultValues.month,
        value: this.props.defaultValues.month,
      });
    }

    if (selectedYear === startYear) {
      let monthLimit = 11;
      if (selectedYear === endYear && (this.props.endDate || this.props.duration)) {
        monthLimit = endMonth;
      }
      for (let i = startMonth; i <= monthLimit; i++) {
        months.push({
          value: i,
          text: languageMapMonths[i],
        });
      }
    } else if (selectedYear === endYear) {
      for (let i = 0; i <= endMonth; i++) {
        months.push({
          value: i,
          text: languageMapMonths[i],
        });
      }
    } else {
      for (let i = 0; i <= 11; i++) {
        months.push({
          value: i,
          text: languageMapMonths[i],
        });
      }
    }

    if (this.props.options && this.props.options.monthShort) {
      months = months.map((elem) => {
        return {
          value: elem.value,
          text: elem.month.substring(0, 3),
        };
      });
    }

    if (this.props.options && this.props.options.monthCaps) {
      months = months.map((elem) => {
        return {
          value: elem.value,
          text: elem.month.toUpperCase(),
        };
      });
    }

    const monthList = months.map((month) => month.value);
    if (
      this.state.selectedMonth &&
      this.state.selectedMonth >= 0 &&
      monthList.indexOf(this.state.selectedMonth) === -1
    ) {
      this.handleMonthChange(null, { value: monthList[monthList.length - 1] });
    }
    return months;
  };

  generateDayOptions = () => {
    const { startYear, startMonth, startDay, endYear, endMonth, endDay, selectedYear, selectedMonth } = this.state;
    const dayOptions = [];
    if (this.props.defaultValues && this.props.defaultValues.day) {
      dayOptions.push({
        text: this.props.defaultValues.day,
        value: this.props.defaultValues.day,
      });
    }

    let monthDays;
    if (selectedYear === startYear) {
      if (selectedMonth === startMonth) {
        monthDays =
          selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
        for (let i = startDay; i <= monthDays; i++) {
          dayOptions.push({
            text: i,
            value: i,
          });
        }
      } else {
        monthDays =
          selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
        if (selectedMonth === endMonth) {
          for (let i = 1; i <= endDay; i++) {
            dayOptions.push({
              text: i,
              value: i,
            });
          }
        } else {
          for (let i = 1; i <= monthDays; i++) {
            dayOptions.push({
              text: i,
              value: i,
            });
          }
        }
      }
    } else if (selectedYear === endYear) {
      if (selectedMonth === endMonth) {
        for (let i = 1; i <= endDay; i++) {
          dayOptions.push({
            text: i,
            value: i,
          });
        }
      } else {
        monthDays =
          selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
        for (let i = 1; i <= monthDays; i++) {
          dayOptions.push({
            text: i,
            value: i,
          });
        }
      }
    } else {
      if (selectedMonth) {
        monthDays =
          selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
        for (let i = 1; i <= monthDays; i++) {
          dayOptions.push({
            text: i,
            value: i,
          });
        }
      } else {
        for (let i = 1; i <= 31; i++) {
          dayOptions.push({
            text: i,
            value: i,
          });
        }
      }
    }
    const daysList = dayOptions.map((day) => day.value);
    if (this.state.selectedDay && daysList.indexOf(this.state.selectedDay) === -1) {
      this.handleDayChange(null, { value: daysList[daysList.length - 1] });
    }
    return dayOptions;
  };

  handleYearChange = (e, { value }) => {
    const year = parseInt(value);
    this.setState({ selectedYear: year });
    if (this.props.onYearChange) {
      this.props.onYearChange(year);
    }
    this.handleDateChange(unit.year, year);
  };

  handleMonthChange = (e, { value }) => {
    const month = parseInt(value);
    this.setState({ selectedMonth: month });
    if (this.props.onMonthChange) {
      this.props.onMonthChange(monthByNumber[month]);
    }
    this.handleDateChange(unit.month, month);
  };

  handleDayChange = (e, { value }) => {
    const day = parseInt(value);
    this.setState({ selectedDay: day });
    if (this.props.onDayChange) {
      this.props.onDayChange(day);
    }
    this.handleDateChange(unit.day, day);
  };

  handleMonthOfYearChange = (e, { value }) => {
    this.setState({ selectedMonthOfYear: value });
    const monthOfYear = value.split('-');
    if (this.props.onMonthOfYearChange) {
      this.props.monthOfYearChange(monthByNumber[`${monthOfYear[0]}-${monthOfYear[1]}`]);
    }
    this.handleDateChange(unit.monthOfYear, monthOfYear);
  };

  handleDateChange = (type, value) => {
    const dateFormat = this.props.dateFormat || 'YYYY-MM-DD';
    if (this.props.onDateChange) {
      let { selectedYear, selectedMonth, selectedDay } = this.state;
      if (type === unit.year) {
        selectedYear = value;
      } else if (type === unit.month) {
        selectedMonth = value;
      } else if (type === unit.day) {
        selectedDay = value;
      } else if (type === unit.monthOfYear) {
        selectedDay = 1;
        selectedMonth = value[0];
        selectedYear = value[1];
      }

      if (
        !isNaN(selectedYear) &&
        selectedYear !== '' &&
        !isNaN(selectedMonth) &&
        selectedMonth !== '' &&
        !isNaN(selectedDay) &&
        selectedDay !== ''
      ) {
        const selectedDate = moment(new Date(selectedYear, selectedMonth, selectedDay));
        let formattedDate = this.checkHolidayAndGetNext(selectedDate?.format(dateFormat));
        if (!formattedDate.isSame(selectedDate)) {
          selectedYear = formattedDate.year();
          selectedMonth = formattedDate.month();
          selectedDay = formattedDate.date();
          this.setState({ selectedYear, selectedMonth, selectedDay });
        }
        if (!this.props.format) {
          this.props.onDateChange(formattedDate);
          this.setState({
            selectedDate: formattedDate,
          });
        } else {
          formattedDate = formattedDate.format('YYYY-MM-DD');
          this.props.onDateChange(formattedDate);
          this.setState({
            selectedDate: formattedDate,
          });
        }
      }
    }
  };

  renderYear = () => {
    let errorText = null;
    const languageMap = this.props.languageMap.components.dropdownDatepicker;
    if (this.props.errors && this.props.errors.year) {
      errorText = this.props.errors.year;
    }
    return (
      <div key="year" className="dropdown-year">
        {this.props.showDropdownHeader && <div className="dropdown-title">Year</div>}
        <Dropdown
          placeholder={languageMap.selectYear}
          onChange={this.handleYearChange}
          value={this.state.selectedYear}
          search
          selection
          options={this.generateYearOptions()}
          className={errorText ? 'error' : ''}
          icon="angle down"
          disabled={this.props.disabled}
        />
        {errorText && <div className="error-text">{errorText}</div>}
      </div>
    );
  };

  renderMonth = () => {
    let errorText = null;
    const languageMap = this.props.languageMap.components.dropdownDatepicker;
    if (this.props.errors && this.props.errors.month) {
      errorText = this.props.errors.month;
    }
    return (
      <div key="month" className="dropdown-month">
        {this.props.showDropdownHeader && <div className="dropdown-title">Month</div>}
        <Dropdown
          placeholder={languageMap.selectMonth}
          onChange={this.handleMonthChange}
          value={this.state.selectedMonth}
          search
          selection
          options={this.generateMonthOptions()}
          className={errorText ? 'error' : ''}
          icon="angle down"
          disabled={this.props.disabled}
        />
        {errorText && <div className="error-text">{errorText}</div>}
      </div>
    );
  };

  renderMonthOfYear = () => {
    let errorText = null;
    if (this.props.errors && this.props.errors.monthOfYear) {
      errorText = this.props.errors.monthOfYear;
    }
    return (
      <div key="monthOfYear" className="dropdown-month-of-year">
        {this.props.showDropdownHeader && <div className="dropdown-title">Month - Year</div>}
        <Dropdown
          placeholder="Select month-year"
          onChange={this.handleMonthOfYearChange}
          value={this.state.selectedMonthOfYear}
          search
          selection
          options={this.generateMonthOfYearOptions()}
          className={errorText ? 'error' : ''}
          icon="angle down"
          disabled={this.props.disabled}
        />
        {errorText && <div className="error-text">{errorText}</div>}
      </div>
    );
  };

  renderDay = () => {
    let errorText = null;
    const languageMap = this.props.languageMap.components.dropdownDatepicker;
    if (this.props.errors && this.props.errors.day) {
      errorText = this.props.errors.day;
    }
    return (
      <div key="day" className="dropdown-day">
        {this.props.showDropdownHeader && <div className="dropdown-title">Date</div>}
        <Dropdown
          placeholder={languageMap.selectDay}
          onChange={this.handleDayChange}
          value={this.state.selectedDay}
          search
          selection
          options={this.generateDayOptions()}
          className={errorText ? 'error' : ''}
          icon="angle down"
          disabled={this.props.disabled}
        />
        {errorText && <div className="error-text">{errorText}</div>}
      </div>
    );
  };

  render() {
    return (
      <div
        id="dropdown-date"
        className={
          this.props.className
            ? `${this.props.className} dropdown-datepicker inline-fields`
            : 'dropdown-datepicker inline-fields'
        }
      >
        {this.props.order.map((part) => {
          return this.renderParts[part]();
        })}
      </div>
    );
  }
}

DropdownDatepicker.propTypes = {
  order: PropTypes.array,
};

DropdownDatepicker.defaultProps = {
  order: ['year', 'month', 'day'],
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps)(DropdownDatepicker);
