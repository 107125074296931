import { Button } from 'antd';
import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import { RootState } from '../../../index';
import {
  errorHandler,
  formatMobile,
  getParameterByName,
  handlecontactOps,
  isEmailOtp,
  notify,
} from '../../../utils/helper';
import RecaptchaButton from '../common/RecaptchaButton';
import AccountNotFoundModal from './AccountNotFoundModal';
import Otp from './Otp';
interface Props {
  close?: () => void;
  mobile?: string;
  openUser?: any;
  email?: string;
  errors?: any;
}

interface ErrorResponse {
  response: any;
}

const AccountFoundModal: React.FC<Props> = ({ close, mobile = '', email, openUser }) => {
  const [disableBtn, setDisableBtn] = useState(false),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    dispatch = useDispatch(),
    isIg = getParameterByName('ig') === 'true',
    isDirectAgent = getParameterByName('direct_agent') === 'true',
    isBusinessManager = getParameterByName('business_manager') === 'true',
    captchaSiteKey = RECAPTCHA_SITEKEY;

  function openAccountNotFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountNotFoundModal username={mobile} />,
        uid: 'ACCOUNT_NOT_FOUND',
      })
    );
  }

  function openOtpScreen(intent: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={mobile} intent={intent} username_type={'phone'} openUser={openUser} email={email} />,
        uid: 'OTP_SCREEN',
      })
    );
  }

  function requestOtp(intent: string, headers?: any) {
    let params: any = {
      username: mobile,
      intent,
      username_type: 'phone',
      is_email_otp: isEmailOtp(),
    };

    if (!isIg && !isDirectAgent && !isBusinessManager) {
      params.is_mlm = true;
    }
    setDisableBtn(true);
    api.user
      .requestOtp(params, headers)
      .then(() => {
        openOtpScreen(params.intent);
        setDisableBtn(false);
        close && close();
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_does_not_exist') {
          openAccountNotFound();
        } else if (errors && errors.error === 'account_exists') {
          notify('Please Login your account already exists', 'success');
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          notify(languageMap[`Too many OTP requests! Please try again in 24 hours.`], 'error');
        } else if (errors && errors.non_field_errors) {
          handlecontactOps(errors.non_field_errors);
        } else {
          errorHandler(error.response, true);
        }
        setDisableBtn(false);
      });
  }

  function renderButtons() {
    let { accounts } = openUser,
      text1 = '',
      text2 = '';
    if (accounts.includes('mlm_agent')) {
      text1 = languageMap[`Change Mobile Number`];
      text2 = languageMap[`Login as MLM`];
    } else if (accounts.includes(null)) {
      text1 = languageMap[`Login as Direct Agent`];
      text2 = languageMap[`Sign Up for MLM`];
    } else if (accounts.includes('inspection_garage')) {
      text1 = languageMap[`Login as Inspection Garage`];
      text2 = languageMap[`Sign Up for MLM`];
    } else if (accounts.includes('sub_agent')) {
      text1 = languageMap[`Login as Inspection Garage`];
      text2 = languageMap[`Sign Up for MLM`];
    } else if (accounts.includes('business_manager')) {
      text1 = languageMap[`Login as Business Manager`];
      text2 = languageMap[`Sign Up for MLM`];
    }

    function handleBtnClick(type: string, token?: string) {
      let headers:any = null
      if (token) {
        headers = {
          headers: {
            'G-Recaptcha-Response': token,
          },
        };
      }

      switch (type) {
        case 'btn1':
          if (accounts.includes('mlm_agent')) {
            close && close();
          } else {
            if (accounts.includes(null)) {
              window.history.pushState(null, '', `${window.location.pathname}?direct_agent=true`);
              requestOtp('login', headers);
            } else if (accounts.includes('inspection_garage') || accounts.includes('sub_agent')) {
              window.history.pushState(null, '', `${window.location.pathname}?ig=true`);
              requestOtp('login', headers);
            } else if (accounts.includes('business_manager')) {
              window.history.pushState(null, '', `${window.location.pathname}?business_manager=true`);
              requestOtp('login', headers);
            }
          }
          break;
        case 'btn2':
          if (accounts.includes('mlm_agent')) {
            requestOtp('login', headers);
          } else {
            requestOtp('signup', headers);
          }
          break;
        default:
          break;
      }
    }

    if (!systemSettings.should_validate_recaptcha) {
      return (
        <div className="inline-buttons with-padding">
          <Button className="orange-bordered-button medium-text button-two" onClick={() => handleBtnClick('btn1')}>
            {text1}
          </Button>
          <Button
            className={'orange-button medium-text button-two ' + (disableBtn ? 'disabled' : '')}
            onClick={() => handleBtnClick('btn2')}
          >
            {text2}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="recaptcha-btn-wrap inline">
          <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
            <RecaptchaButton
              action={handleBtnClick}
              btnType="outlined"
              name="btn1"
              label={text1}
              disableBtn={disableBtn}
            />
            <RecaptchaButton
              action={handleBtnClick}
              btnType="button"
              name="btn2"
              label={text2}
              disableBtn={disableBtn}
              className={'medium-text button-two'}
            />
          </GoogleReCaptchaProvider>
        </div>
      );
    }
  }

  function renderContent() {
    let { accounts } = openUser,
      text = '';

    if (accounts && accounts.includes(null)) {
      text = languageMap[`This mobile number is linked to an existing direct agent account.`];
    }

    if (accounts && accounts.includes('mlm_agent')) {
      text = languageMap['This mobile number is linked to an existing MLM account.'];
    }

    return (
      <div className="content">
        <p
          className="text"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
        <p className="message">{formatMobile(mobile)}</p>
      </div>
    );
  }

  return (
    <div className="fairdee-modal">
      <div className="modal-wrapper">
        <div className="header">
          <p className="title-text border">{languageMap[`Oops! Account already exists.`]}</p>
        </div>
        {renderContent()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default AccountFoundModal;
