import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/cross-blue.svg';
import { snakeToTitleCase } from '../../utils/helper';

class DocumentsModal extends React.Component {
  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    const { downloadLink, title } = this.props;
    const languageMap = this.props.languageMap.components.documentsModal;

    return (
      <div className="fairdee-modal-wrapper med message">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <p className="title">{title}</p>
          <div className="docs-wrap">
            {this.props.data.map((doc, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col">{snakeToTitleCase(languageMap[doc.document_type] || doc.document_type)}</div>
                  <div className="col">
                    <a href={`${downloadLink}/${doc.id}`} download>
                      {languageMap.textOne}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

DocumentsModal.propTypes = {
  title: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(DocumentsModal));
