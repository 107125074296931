import { CLEAR_MODAL, HIDE_MODAL, SHOW_MODAL } from '../constants/types';

export const showModal = (type, props) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    props,
  },
  uid: props.uid || Date.now(),
});

export const hideModal = (type, props) => ({
  type: HIDE_MODAL,
  uid: props.uid,
});

export const clearModal = (type, props) => ({
  type: CLEAR_MODAL,
  uid: null,
});
