/* global mobileClient */

import Button from '@material-ui/core/Button';
import vh from '@sect/100vh';
import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setAffiliate } from '../actions/affiliate';
import { tokenLogin, userProfile } from '../actions/auth';
import { setFirstLoad } from '../actions/firstload';
import { setHolidays } from '../actions/holidays';
import { setLocation } from '../actions/location';
import { showModal } from '../actions/modal';
import { setSystemSettings } from '../actions/systemSettings';
import api from '../commonjs/api';
import { CLIENT_ID, CLIENT_SECRET } from '../constants/config';
import { THAI_HOLIDAYS } from '../constants/holidays';
import { store } from '../index';
import PushNotification from '../PushNotification';
import {
  addClickListeners,
  errorHandler,
  getAccessToken,
  getLocalData,
  getParameterByName,
  isAdmin,
  isDealerOrLeadgen,
  isEmailOtp,
  isSubAgent,
  isSuperAdmin,
  logoutHelper,
  needsRedirection,
  setAffiliateIdHeader,
  setLocalData,
  setUserData,
  updateTokensFromCookie,
} from '../utils/helper';
import { Mixpanel } from '../utils/tracking';

const STATIC_ROUTES = {
  '/comparison': true,
  '/about-us': true,
  '/fairdee-form': true,
  '/line-login': true,
  '/terms-of-use': true,
  '/privacy-policy': true,
  '/aff-additional-info': true,
  '/aff-additional-info-oic': true,
  '/aff-additional-info-tnc': true,
  '/how-it-works': true,
  '/faq': true,
  '/คำถามที่พบบ่อย': true,
  '/คำนวณภาษีรถยนต์': true,
  '/วิธีการใช้งาน': true,
  '/เกี่ยวกับเรา': true,
  คลังความรู้: true,
  '/road-tax-calculator': true,
  '/tutorials': true,
};

function Loading({ error }) {
  function reload() {
    window.location.reload();
  }

  if (error) {
    try {
      if (!mobileClient) {
        return (
          <div className="reload-page">
            <div className="align-middle">
              <p>You have new changes to be loaded.</p>
              <Button variant="contained" color="secondary" onClick={reload}>
                RELOAD
              </Button>
            </div>
          </div>
        );
      } else {
        window.location.reload();
      }
    } catch (e) {
      window.console.log(e);
    }
  } else {
    return <div className="component-loading"></div>;
  }
}

function generateRedirectionPath(affiliate) {
  let redirectionPath = null;
  if (!affiliate.id) {
    return null;
  }
  if (affiliate.id && !affiliate.is_signup_complete && !isEmailOtp() && !isAdmin() && !isSuperAdmin()) {
    let params = window.location.search;
    redirectionPath = `/signup${params}`;
  }
  return redirectionPath;
}

const LoadablePage = (page) =>
  Loadable({
    loader: () => import(`../views/pages/${page}`),
    loading: Loading,
  });

const LoadablePageStatic = (page) =>
  Loadable({
    loader: () => import(`../views/static-pages/${page}`),
    loading: Loading,
  });

const LoadableCrmPage = (page) =>
  Loadable({
    loader: () => import(`../components/crm/${page}`),
    loading: Loading,
  });

const PrivateRoute = ({ component: Component, ...rest }) => {
  const affiliate = store.getState().affiliate;
  let path = generateRedirectionPath(affiliate) || '/';
  if (!affiliate || STATIC_ROUTES[window.location.pathname]) {
    return <Redirect to={path} />;
  }
  if (isDealerOrLeadgen() && isSubAgent() && window.location.pathname == '/affiliate-dashboard') {
    return <Redirect to="/buy-insurance" />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        getAccessToken() && affiliate && affiliate.id && path === '/' ? (
          <Component {...props} />
        ) : (
          <Redirect to={path} />
        )
      }
    />
  );
};

const VoluntaryInsurance = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (getAccessToken() ? <Component {...props} key={props.match.params.id} /> : <Redirect to="/" />)}
  />
);

const TokenisedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getParameterByName('t') ? <Component {...props} key={props.match.params.id} /> : <Redirect to="/" />
    }
  />
);

const PublicRoute = ({ component: Component, props, ...rest }) => {
  let path = '/buy-insurance',
    affiliate = store.getState().affiliate;

  if (
    (affiliate && affiliate.id && affiliate.is_signup_complete === false) ||
    (isEmailOtp() && affiliate.has_broker_license !== null)
  ) {
    let params = window.location.search;
    path = `/signup${params}`;
  }

  if (rest.path === '/logout') {
    path = '/';
    logoutHelper();
    return <Redirect to={path} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (!getAccessToken() || !affiliate.id ? <Component {...props} /> : <Redirect to={path} />)}
    />
  );
};

const SignupRoute = ({ component: Component, props, ...rest }) => {
  let path = `/signup`,
    affiliate = store.getState().affiliate;

  if (affiliate && affiliate.id) {
    let userObj = {
      email: affiliate.user.email,
      id: affiliate.user.id,
      agent_code: affiliate.agent_code,
      isAdmin: isAdmin() || isSuperAdmin() || '',
      accountType: affiliate.account_type || '',
    };
    window.trackEvent && window.trackEvent.userProperty(userObj);
  }

  if (affiliate.is_signup_complete === true || (isEmailOtp() && affiliate.has_broker_license !== null)) {
    path = '/buy-insurance';
  }

  if (!getAccessToken()) {
    path = '/';
  }

  return (
    <Route {...rest} render={(props) => (path !== '/signup' ? <Redirect to={path} /> : <Component {...props} />)} />
  );
};

const CustomPublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component pageType="static" {...props} />;
      }}
    />
  );
};

const LineRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

class Router extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
    this.path = '/';

    window.currentPath = props.location.pathname + props.location.search;

    this.unlisten = this.props.history.listen((location) => {
      // setTimeout(() => {
      //   scrollTop();
      // }, 1000);
      store.dispatch(setLocation(location));

      if (window.currentPath !== '/') {
        window.previousPath = window.currentPath || '/';
      }
      window.currentPath = location.pathname + location.search;
      if (window.previousPath.includes('affiliate=') && !window.currentPath.includes('affiliate=')) {
        this.getAffiliateProfile();
      } else if (window.previousPath.includes('affiliate/') && !window.currentPath.includes('affiliate=')) {
        this.getAffiliateProfile();
      }
    });
  }

  componentWillMount() {
    let intent = getParameterByName('intent'),
      t = getParameterByName('t');

    if ((intent === 'login' || intent === 'verify_email') && t) {
      this.tokenLogin(t);
    } else if (intent === 'internal_redirect') {
      updateTokensFromCookie();
      this.userProfile();
    } else {
      this.userProfile();
    }

    if (!window.previousPath) {
      window.previousPath = window.currentPath || '/';
    }
    store.dispatch(setLocation({ pathname: window.location.pathname || '/' }));

    addClickListeners();
  }

  componentDidMount() {
    Mixpanel.register();
    vh.init();
    this.getSystemSettings();
    if (!store.getState().holidays.length) {
      this.getHolidays();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getSystemSettings = async () => {
    api.system
      .getSystemSettings()
      .then((resp) => {
        store.dispatch(setSystemSettings(resp));
        setTimeout(() => this.getSystemSettings(), 60000000);
      })
      .catch((error) => {
        errorHandler(error);
        setTimeout(() => this.getSystemSettings(), 600000);
      });
  };

  getHolidays = async () => {
    const holidays = await THAI_HOLIDAYS();
    store.dispatch(setHolidays(holidays));
  };

  tokenLogin = (t) => {
    this.setState({
      loading: true,
    });
    this.props
      .tokenLogin(t)
      // .then(this.props.userProfile)
      .then(() => {
        this.setState({
          loading: false,
        });
        this.userProfile();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        store.dispatch(setFirstLoad(true));
      });
  };

  getAffiliateProfile = () => {
    api.crm
      .getAffiliateProfile({
        with_commission_rates: true,
        with_instalment_credits: true,
        instalment_credits_with_vendor: true,
      })
      .then((data) => {
        let affiliate = data.message;
        store.dispatch(setAffiliate(affiliate));
        Mixpanel.register();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  affiliateProfile = () => {
    return api.crm.getAffiliateProfile({
      with_commission_rates: true,
      with_instalment_credits: true,
      instalment_credits_with_vendor: true,
    });
  };

  affiliateProfileWithId = (id) => {
    return api.crm.affiliate(id);
  };

  userProfile = () => {
    if (window.location.pathname === '/liff-landing') {
      this.setState({
        ...this.state,
        loading: false,
      });
      return;
    }
    let params = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      token: getAccessToken(),
    };
    api.user
      .profile(params)
      .then((response) => {
        let profileDetails = response.data;

        if (profileDetails) {
          setUserData(profileDetails);
          if (profileDetails.roles && profileDetails.roles.includes('admin') && PushNotification) {
            PushNotification.init();
          }
          this.setState({
            user: profileDetails,
          });
          if (profileDetails.affiliates && profileDetails.affiliates.length) {
            let currentAffiliate = getLocalData('currentAffiliate');
            if (currentAffiliate) {
              setAffiliateIdHeader(currentAffiliate);
            } else if (profileDetails.affiliates.length > 1) {
              let id = profileDetails.affiliates.find((aff) => aff.account_type === 'mlm_agent')?.id;
              setAffiliateIdHeader(id);
              setLocalData('currentAffiliate', id);
            } else {
              let id = profileDetails.affiliates[0].id;
              setAffiliateIdHeader(id);
              setLocalData('currentAffiliate', id);
            }
          }
        }

        if (window.location.pathname === '/welcome') {
          this.setState({
            loading: false,
          });
        } else {
          this.getAllData(profileDetails);
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        store.dispatch(setFirstLoad(true));
      });
  };

  getAllData = () => {
    this.setState({
      loading: true,
    });
    let promises = [],
      affiliate = getParameterByName('affiliate');
    if (affiliate && !isNaN(affiliate)) {
      promises.push(this.affiliateProfileWithId(affiliate));
    } else if (getAccessToken()) {
      promises.push(this.affiliateProfile());
    }

    Promise.all(promises)
      .then((response) => {
        if (response[0]) {
          let affiliate = response[0].message;
          if (needsRedirection(affiliate)) {
            logoutHelper();
            return;
          }
          store.dispatch(setAffiliate(affiliate));
          Mixpanel.register(affiliate);
        }

        this.setState({
          loading: false,
        });
        store.dispatch(setFirstLoad(true));
        if (!getAccessToken()) {
          let redirectionPath = generateRedirectionPath(affiliate);
          if (redirectionPath) {
            this.props.history.push(redirectionPath);
          }
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        store.dispatch(setFirstLoad(true));
      });
  };

  renderRoutes = () => {
    return (
      <>
        {!this.state.loading && (
          <Switch>
            <CustomPublicRoute exact path="/about-us" component={LoadablePage('AboutUs', '/about-us')} />
            <CustomPublicRoute exact path="/เกี่ยวกับเรา" component={LoadablePage('AboutUs')} />
            <CustomPublicRoute exact path="/terms-of-use" component={LoadablePageStatic('TermsOfUse')} />
            <CustomPublicRoute exact path="/faq" component={LoadablePageStatic('Faq')} />
            <CustomPublicRoute exact path="/คำถามที่พบบ่อย" component={LoadablePageStatic('Faq')} />
            <CustomPublicRoute exact path="/road-tax-calculator" component={LoadablePage('RoadTaxCalculator')} />
            <CustomPublicRoute exact path="/คำนวณภาษีรถยนต์" component={LoadablePage('RoadTaxCalculator')} />
            <CustomPublicRoute exact path="/privacy-policy" component={LoadablePage('PrivacyPolicy')} />
            <CustomPublicRoute exact path="/how-it-works" component={LoadablePage('HowItWorks')} />
            <CustomPublicRoute exact path="/วิธีการใช้งาน" component={LoadablePage('HowItWorks')} />
            <CustomPublicRoute exact path="/tutorials" component={LoadablePage('Tutorials')} />
            <CustomPublicRoute exact path="/คลังความรู้" component={LoadablePage('Tutorials')} />
            <Route exact path="/comparison" component={LoadablePage('Comparison')} />
            <Route exact path="/aff-additional-info" component={LoadablePage('AffAdditionalInfo')} />
            <Route exact path="/aff-additional-info-oic" component={LoadablePage('AffAdditionalInfoOic')} />
            <Route exact path="/aff-additional-info-tnc" component={LoadablePage('AffAdditionalInfoTnc')} />
            <Route exact path="/update-phone" component={LoadablePage('UpdatePhone')} />
            <Route exact path="/line-login" component={LoadablePage('Onboarding')} />
            <SignupRoute exact path="/signup" component={LoadablePage('Signup')} />
            <PrivateRoute exact path="/compulsory-insurance" component={LoadablePage('CompulsoryInsurance')} />
            <PrivateRoute
              exact
              path="/compulsory-insurance-report"
              component={LoadablePage('CompulsoryInsuranceReport')}
            />
            <PrivateRoute
              exact
              path="/voluntary-insurance-report"
              component={LoadablePage('VoluntaryInsuranceReport')}
            />
            <PrivateRoute exact path="/affiliates" component={LoadablePage('Affiliates')} />
            <PrivateRoute exact path="/mlm-affiliates" component={LoadablePage('MlmAffiliates')} />
            <PrivateRoute exact path="/get-quotes/:quickview?" component={LoadablePage('InsurerListing')} />
            <PrivateRoute exact path="/wizard" component={LoadablePage('FairdeeWizard')} />
            <PrivateRoute exact path="/profile" component={LoadablePage('Profile')} />
            <PrivateRoute exact path="/affiliate/:id" component={LoadablePage('Affiliate')} />
            <PrivateRoute exact path="/buy-insurance-old" component={LoadablePage('BuyInsuranceNew')} />
            <PrivateRoute exact path="/dashboard" component={LoadablePage('Dashboard')} />
            <PrivateRoute exact path="/buy-insurance" component={LoadablePage('Dashboard')} />
            <PrivateRoute exact path="/voluntary-insurance-list" component={LoadablePage('VoluntaryInsuranceList')} />
            <PrivateRoute exact path="/bank-details" component={LoadablePage('BankDetails')} />
            <PrivateRoute exact path="/sale-dashboard" component={LoadablePage('SaleDashboard')} />
            <PrivateRoute exact path="/fairdee-wizard" component={LoadablePage('FairdeeWizard')} />
            <PrivateRoute exact path="/bike-insurance" component={LoadablePage('BikeInsurance')} />
            <PrivateRoute exact path="/taxi-insurance" component={LoadablePage('TaxiInsurance')} />
            <PrivateRoute exact path="/get-quotes-bike" component={LoadablePage('BikeQuotes')} />
            <PrivateRoute exact path="/get-quotes-taxi" component={LoadablePage('TaxiQuotes')} />
            <PrivateRoute exact path="/get-quotes-non-motor" component={LoadablePage('NonMotorQuotes')} />
            <PrivateRoute exact path="/report-sale" component={LoadablePage('ReportSale')} />
            <PrivateRoute exact path="/non-motor-purchase" component={LoadablePage('NonMotor')} />
            <PrivateRoute exact path="/non-motor-payment" component={LoadablePage('NonMotorPayment')} />
            <PrivateRoute exact path="/fairdee-wizard-taxi" component={LoadablePage('FairdeeWizardTaxi')} />
            <PrivateRoute exact path="/personal-accident" component={LoadablePage('PersonalAccident')} />
            <PrivateRoute exact path="/crm" component={LoadablePage('Crm')} />
            <PrivateRoute exact path="/crm/affiliate/:id" component={LoadableCrmPage('CrmAffiliate')} />
            <PrivateRoute exact path="/crm/affiliates" component={LoadableCrmPage('CrmAffiliates')} />
            <PrivateRoute exact path="/staff-for-tasks" component={LoadablePage('StaffForTasks')} />
            <PrivateRoute path="/fairdee-quote-affiliate" component={LoadablePage('QuotationShare')} />
            <PrivateRoute path="/affiliate-dashboard" component={LoadablePage('AffiliateDashboard')} />
            <PrivateRoute path="/leads" component={LoadablePage('Leads')} />
            <PrivateRoute path="/policies" component={LoadablePage('PoliciesInProgress')} />
            <PrivateRoute path="/trackable-link" component={LoadablePage('TrackableLinkGen')} />
            <PrivateRoute path="/shortened-url" component={LoadablePage('UrlShortner')} />
            <PrivateRoute path="/banner-upload" component={LoadablePage('BannerUpdate')} />
            <PrivateRoute path="/accounting" component={LoadablePage('Accounting')} />
            <PrivateRoute path="/price-list-admin" component={LoadablePage('PriceListAdmin')} />
            <PrivateRoute path="/policy-admin" component={LoadablePage('PolicyAdmin')} />
            <PrivateRoute exact path="/corona-wizard" component={LoadablePage('CoronaWizard')} />
            <PrivateRoute exact path="/dengue-wizard" component={LoadablePage('DengueWizard')} />
            <PrivateRoute exact path="/pa-wizard" component={LoadablePage('PAWizard')} />
            <PrivateRoute exact path="/manage-staff" component={LoadablePage('ManageStaff')} />
            <PrivateRoute exact path="/lg" component={LoadablePage('Lg')} />
            <PrivateRoute exact path="/subagent-portal" component={LoadablePage('SubagentPortal')} />
            <TokenisedRoute exact path="/fairdee-form" component={LoadablePage('TokenisedWizardView')} />
            <TokenisedRoute path="/fairdee-quote" component={LoadablePage('QuotationShare')} />
            <PublicRoute exact path="/" component={LoadablePage('Landing')} />
            <PublicRoute exact path="/affiliate-signup" component={LoadablePage('AffiliateSignup')} />
            <VoluntaryInsurance exact path="/voluntary-insurance" component={LoadablePage('AffiliatePurchase')} />
            <VoluntaryInsurance exact path="/non-standard" component={LoadablePage('AffiliatePurchase')} />
            <VoluntaryInsurance path="/non-motor" component={LoadablePage('AffiliatePurchase')} />
            <VoluntaryInsurance exact path="/manual-quotation" component={LoadablePage('AffiliatePurchase')} />
            <PrivateRoute path="/insurer-settings" component={LoadablePage('InsurerSettings')} />
            <PublicRoute exact path="/insurance/:id" component={LoadablePage('InsuranceType')} />
            <LineRoute exact path="/line-landing" component={LoadablePage('LineLanding')} />
            <PrivateRoute path="/csv-upload" component={LoadablePage('CSVUpload')} />
            <PrivateRoute path="/policies-landing" component={LoadablePage('PoliciesLanding')} />
            <PrivateRoute path="/account" component={LoadablePage('Account')} />
            <PrivateRoute path="/referrals" component={LoadablePage('Referrals')} />
            <PrivateRoute path="/commissions" component={LoadablePage('Commissions')} />
            <PrivateRoute path="/teams" component={LoadablePage('Teams')} />
            <PrivateRoute path="/income-dashboard" component={LoadablePage('IncomeDashboard')} />
            <PrivateRoute path="/downline" component={LoadablePage('Downline')} />
            <PrivateRoute path="/manual-billing-statement" component={LoadablePage('ManualBillingStatement')} />
            <PrivateRoute path="/historical-reports" component={LoadablePage('HistoricalReports')} />
            <PrivateRoute path="/holidays" component={LoadablePage('Holidays')} />
            <PrivateRoute path="/printing-backlog" component={LoadablePage('PrintingBacklog')} />
            <PrivateRoute path="/printing-backlog-gql" component={LoadablePage('PrintingBacklogGQL')} />
            <PrivateRoute path="/event-calendar" component={LoadablePage('EventCalendar')} />
            <PrivateRoute path="/chatwoot-conversation" component={LoadablePage('ChatwootConversation')} />
            <PrivateRoute path="/non-motor-orders/:id" component={LoadablePage('NonMotorOrders')} />
            <PrivateRoute path="/chatwoot-payments" component={LoadablePage('ChatwootPayments')} />
            <PublicRoute path="/logout" />
            <Route path="/welcome" component={LoadablePage('Welcome')} />
            <Redirect from="*" to="/" />
          </Switch>
        )}
      </>
    );
  };

  render() {
    return <>{this.renderRoutes()}</>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withRouter(connect(mapStateToProps, { tokenLogin, userProfile, showModal })(Router));
