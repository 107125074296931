import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../constants/types';

export const showNotification = (type, props) => ({
  type: SHOW_NOTIFICATION,
  payload: {
    type,
    props,
  },
  uid: props.uid || Date.now(),
});

export const hideNotification = (type, props) => ({
  type: HIDE_NOTIFICATION,
  uid: props.uid,
});
