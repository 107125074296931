import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const antIcon = <LoadingOutlined spin />;

function Loader({ titleText, secondaryText }) {
  return (
    <div className="loader-backdrop">
      <div className="circular-loader">
        <Spin indicator={antIcon} className="loading-icon" />
        <p className="title">{titleText}</p>
        <p className="info-text">{secondaryText}</p>
      </div>
    </div>
  );
}

export default Loader;
