import React, { useEffect, useState } from 'react';
import { isAdmin } from '../../utils/helper';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import apiEndpoints from '../../commonjs/apiEndpoints';
import { NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import EventForm from '../../components/event-calendar/EventForm';

function EventFormModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.eventForm);

  function close() {
    props.hideModal(null, { ...props });
  }

  return (
    <div className="fairdee-modal-wrapper customer-info remark">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">{props.data ? languageMap['Edit Event'] : languageMap['Add New Event']}</span>
          </p>
        </div>
        <h1 className="title-text">{props.data ? languageMap['Edit Event'] : languageMap['Add New Event']}</h1>
        <EventForm data={props.data} close={close} setRefreshPage={props.setRefreshPage} />
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showNotification })(EventFormModal);
