import MomentUtils from '@date-io/moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import validator from 'validator';
import { setAffiliate } from '../actions/affiliate';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import { default as PROVINCES } from '../constants/provinces.json';
import { NOTIFICATION } from '../constants/types';
import { store } from '../index';
import {
  errorHandler,
  getDistrictOptions,
  getSubDistrictOptions,
  thaiToDefaultDate,
  toThaiDate,
  validateThaiNationalId,
} from '../utils/helper';
import InlineError from './InlineError';

const MOMENT = moment().locale('th').add(543, 'years');

class EditAffiliateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { address: {}, additional_info: {}, user: {}, address_nid: {} },
      loading: false,
      openModal: false,
      districts: [],
      sub_districts: [],
      districts_nid: [],
      sub_districts_nid: [],
      editable: false,
      errors: {},
      fileList: {},
      fileListLoading: {},
      updatedData: {},
      updatedBankData: {},
      bankDetails: {},
      imagePreviewUrl: props.user.profile_pic || null,
      file: null,
    };

    PROVINCES.map((data) => {
      data.key = data.provincecode;
      data.text = data.provincename;
      data.value = data.provincename;

      return data;
    });
    window.console.log(props);
  }

  componentDidMount() {
    window.console.log(this.props.affiliate);
    let data = JSON.parse(JSON.stringify(this.props.affiliate));
    if (data.user.dob) {
      data.user.dob = toThaiDate(data.user.dob);
    }
    window.console.log(data.user, toThaiDate(data.user.dob));
    if (!data.address) {
      data.address = {};
    }
    if (!data.address_nid) {
      data.address_nid = {};
    }
    this.setState({
      data,
    });
    this.populateDropDowns(data);
    this.populateDropDowns(data, 'address_nid');
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  populateDropDowns = async (affiliate, addressKey = 'address') => {
    if (!affiliate[addressKey]) {
      return;
    }
    let districtsKey = 'districts';
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    if (affiliate[addressKey].subdistrict) {
      let provinceName = affiliate[addressKey].province,
        districtName = affiliate[addressKey].district,
        province = PROVINCES.find((province) => province.provincename === provinceName);

      let districts = [],
        subDistricts = [];

      if (province) {
        // Populate list of districts if province selected
        districts = await getDistrictOptions(province.provincecode);
      }
      if (districtName) {
        // Populate list of sub-districts if district selected
        let district = districts.find((district) => district.districtname === districtName);
        if (district) {
          subDistricts = await getSubDistrictOptions(district.districtcode);
        }
      }
      this.setState({
        [districtsKey]: districts,
        [subDistrictsKey]: subDistricts,
      });
    }
  };

  validate = (data) => {
    let errors = {};
    let messages = this.props.languageMap.messages;
    if (data.national_id && !validateThaiNationalId(data.national_id)) {
      errors.national_id = messages.invalidFormat;
    }
    if (
      data.address &&
      data.address.postal_code &&
      (!validator.isNumeric(data.address.postal_code) || data.address.postal_code.length !== 5)
    ) {
      errors.postal_code = messages.invalidFormat;
    }
    if (!data.communication_email) {
      errors.communication_email = messages.required;
    }
    if (data.communication_email && !validator.isEmail(data.communication_email)) {
      errors.communication_email = messages.email;
    }
    return errors;
  };

  handleChange = (name, addressKey) => (e) => {
    let { data, districts, sub_districts, districts_nid, sub_districts_nid } = this.state;
    let updatedData = this.state.updatedData;
    let value = null;
    if (moment.isMoment(e)) {
      value = e;
    } else {
      value = e.target.value;
    }
    if (name === 'fullname' || name === 'dob' || name === 'gender' || name === 'phone') {
      data.user[name] = value;
      if (!updatedData.user) {
        updatedData.user = {};
      }
      updatedData.user[name] = value;
    } else if (name === 'first_line' || name === 'postal_code') {
      data[addressKey][name] = value;
      if (!updatedData[addressKey]) {
        updatedData[addressKey] = {};
      }
      updatedData[addressKey][name] = value;
    } else if (name === 'has_sold_before' || name === 'has_broker_license' || name === 'line_contact_url') {
      if (name === 'has_sold_before' || name === 'has_broker_license') {
        data.additional_info[name] = e.target.checked;
        if (!updatedData.additional_info) {
          updatedData.additional_info = {};
        }
        updatedData.additional_info[name] = e.target.checked;
      } else {
        data.additional_info[name] = e.target.value;
        if (!updatedData.additional_info) {
          updatedData.additional_info = {};
        }
        updatedData.additional_info[name] = e.target.value;
      }
    } else if (name === 'copy_address') {
      data.copy_address = e.target.checked;
      if (e.target.checked) {
        let copyAddress = (({ first_line, subdistrict, district, province, postal_code }) => ({
          first_line,
          subdistrict,
          district,
          province,
          postal_code,
        }))(data.address);
        if (updatedData.address) {
          copyAddress = (({ first_line, subdistrict, district, province, postal_code }) => ({
            first_line,
            subdistrict,
            district,
            province,
            postal_code,
          }))(updatedData.address);
        }

        data.address_nid = copyAddress;
        updatedData.address_nid = copyAddress;
        districts_nid = districts;
        sub_districts_nid = sub_districts;
      } else {
        data.address_nid = {};
        updatedData.address_nid = {};
        districts_nid = [];
        sub_districts_nid = [];
      }
    } else {
      data[name] = value;
      updatedData[name] = value;
    }
    this.setState(
      {
        data,
        updatedData,
        districts_nid,
        sub_districts_nid,
      },
      () => {
        window.console.log(data);
      }
    );
  };

  handleProvinceChange = async (e, data, addressKey = 'address') => {
    let updatedValues = {
      district: null,
      subdistrict: null,
      province: data.value,
    };
    let districtsKey = 'districts';
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    let provinceCode = PROVINCES.find((province) => province.provincename === data.value).provincecode;
    let districts = await getDistrictOptions(provinceCode);

    let stateData = this.state.data;
    window.console.log(this.state.data);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [districtsKey]: districts,
      [subDistrictsKey]: [],
      data: stateData,
      updatedData,
    }));
  };

  handleDistrictChange = async (e, data, addressKey = 'address') => {
    let updatedValues = {
      district: data.value,
      subdistrict: null,
    };
    let subDistrictsKey = 'sub_districts';
    let districtsKey = 'districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }
    let districts = this.state[districtsKey];
    let districtCode = districts.find((district) => district.districtname === data.value).districtcode;
    let subDistricts = await getSubDistrictOptions(districtCode);
    let stateData = this.state.data;

    window.console.log(this.state.data);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [subDistrictsKey]: subDistricts,
      data: stateData,
      updatedData,
    }));
  };

  handleSubDistrictChange = (e, data, addressKey = 'address') => {
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      subDistrictsKey = 'sub_districts_nid';
    }
    let sub_districts = this.state[subDistrictsKey];

    let updatedValues = {
      subdistrict: data.value,
      postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === data.value).zip,
    };
    let stateData = this.state.data;
    window.console.log(this.state.data);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      data: stateData,
      updatedData,
    }));
  };

  updateProfilePic = () => {
    let FD = new FormData();
    FD.append('image', this.state.file);
    this.setState({ loading: true });
    api.user
      .profilePic(FD)
      .then((resp) => {
        window.console.log('******************** userProfilePic *******************', resp.profile_pic_url);
        this.setState({
          userProfile: { ...this.state.userProfile, profile_pic: resp.profile_pic_url },
          loading: false,
          file: null,
        });
        this.notify('Profile picture successfully updated', 'success');
      })
      .catch((error) => {
        errorHandler(error.response, true);
        this.setState({ loading: false });
      });
  };

  handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    window.console.log('file ', file.size / 1024 + 'kb');
    this.setState({
      errors: { ...this.state.errors },
    });
    if (file.size / 1024 / 1024 > 2) {
      this.setState({
        errors: { ...this.state.errors, fileSize: 'File size cannot be bigger than 2MB' },
      });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  submit = () => {
    let data = { ...this.state.updatedData },
      id = this.props.affiliate.id;

    window.console.log(data);

    if (!Object.keys(data).length) {
      return;
    }

    let errors = this.validate(data);
    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    if (data.user && data.user.dob) {
      data.user.dob = thaiToDefaultDate(data.user.dob);
    }
    let params = {};
    if (data.user) {
      params = { ...params, ...data.user };
    }
    if (data.national_id) {
      params.national_id = data.national_id;
    }
    if (data.address) {
      params.address = data.address;
    }
    if (data.address_nid) {
      params.address_nid = data.address_nid;
    }
    if (data.additional_info) {
      params = { ...params, ...data.additional_info };
    }
    if (data.communication_email) {
      params.communication_email = data.communication_email;
    }
    window.console.log(params);
    api.crm
      .patchAffiliate(id, params)
      .then((resp) => {
        window.console.log(resp);
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
        this.setState({
          updatedData: {},
        });
        store.dispatch(setAffiliate(resp.data));
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => console.log(error));
  };

  renderAddressFields = (is_nid = false) => {
    const { data, errors } = this.state;
    const languageMap = this.props.languageMap.components.userAddress;

    let addressKey = 'address';
    let districtsKey = 'districts';
    let subDistrictsKey = 'subDistricts';

    if (is_nid) {
      addressKey = 'address_nid';
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    const districts = this.state[districtsKey];
    const sub_districts = this.state[subDistrictsKey];

    return (
      <>
        {is_nid && <div className="section-title">{languageMap.address_nid}</div>}
        {is_nid && (
          <div className="key-value">
            <div className="key">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(data.copy_address)}
                    onChange={this.handleChange('copy_address')}
                    value="true"
                    color="primary"
                    className="checkbox"
                  />
                }
                label={languageMap.copy_address}
              />
            </div>
          </div>
        )}
        <div className="key-value">
          <span className="key">{languageMap.textThree}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="text"
                onChange={this.handleChange('first_line', addressKey)}
                value={data[addressKey].first_line || ''}
              />
              {errors.postal_code && <InlineError text={errors.postal_code} />}
            </div>
          </div>
        </div>
        <div className="key-value">
          <span className="key">{languageMap.textFive}</span>
          <div className="value">
            <div className="form-field">
              <Dropdown
                selection
                options={PROVINCES}
                onChange={(e, data) => this.handleProvinceChange(e, data, addressKey)}
                name="province"
                value={data[addressKey].province}
              />
            </div>
          </div>
        </div>

        {districts.length ? (
          <div className="key-value">
            <span className="key">{languageMap.textSix}</span>
            <div className="value">
              <div className="form-field">
                <Dropdown
                  selection
                  options={districts}
                  onChange={(e, data) => this.handleDistrictChange(e, data, addressKey)}
                  name="district"
                  value={data[addressKey].district}
                />
              </div>
            </div>
          </div>
        ) : null}
        {sub_districts && sub_districts.length ? (
          <div className="key-value">
            <span className="key">{languageMap.textSeven}</span>
            <div className="value">
              <div className="form-field">
                <Dropdown
                  selection
                  options={sub_districts}
                  onChange={(e, data) => this.handleSubDistrictChange(e, data, addressKey)}
                  name="subdistrict"
                  value={data[addressKey].subdistrict}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="key-value">
          <span className="key">{languageMap.textFour}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="number"
                onChange={this.handleChange('postal_code', addressKey)}
                value={data[addressKey].postal_code || ''}
              />
              {errors.postal_code && <InlineError text={errors.postal_code} />}
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { data, errors, imagePreviewUrl, loading, file } = this.state;
    const languageMap = this.props.languageMap.components.userAddress;

    window.console.log('LOADING....', loading);

    return (
      <div className="inline-wrapper">
        <div>
          <div className="key-value">
            <span className="key">
              <div className="profile-img-wrap">
                <img src={imagePreviewUrl} alt="" />
              </div>
            </span>
            <div className="value">
              <div className="form-field upload">
                <label htmlFor="">Upload Profile Picture</label>
                <input className="file-input" type="file" onChange={(e) => this.handleImageChange(e)} />
              </div>
              {file ? (
                <div className="form-field upload">
                  <label htmlFor="" className={loading ? 'disabled' : ''} onClick={this.updateProfilePic}>
                    Save
                  </label>
                </div>
              ) : null}
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textNine}</span>
            <div className="value">
              <div className="form-field">
                <input type="text" onChange={this.handleChange('fullname')} value={data.user.fullname || ''} />
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.phone}</span>
            <div className="value">
              <div className="form-field">
                <input type="text" onChange={this.handleChange('phone')} value={data.user.phone || ''} />
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.email}</span>
            <div className="value">
              <div className="form-field">
                <p className="display-val-nopadding">{data.user.email || ''}</p>
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.communicationEmail}</span>
            <div className="value">
              <div className={'form-field ' + (errors.communication_email ? 'error' : '')}>
                <input
                  type="text"
                  id="communication_email"
                  onChange={this.handleChange('communication_email')}
                  value={data.communication_email || ''}
                />
                {errors.communication_email && <InlineError text={errors.communication_email} required />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.line_contact_url}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="text"
                  onChange={this.handleChange('line_contact_url')}
                  value={data.additional_info.line_contact_url || ''}
                />
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textTen}</span>
            <div className="value">
              <div className={'form-field ' + (errors.national_id ? 'error' : '')}>
                <input type="number" onChange={this.handleChange('national_id')} value={data.national_id || ''} />
                {errors.national_id && <InlineError text={errors.national_id} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textEleven}</span>
            <div className="value">
              <div className="form-field">
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDatePicker
                    clearable
                    animateYearScrolling={false}
                    id="dob"
                    // label={languageMap.textEleven}
                    className="datepicker"
                    value={data.user.dob}
                    onChange={this.handleChange('dob')}
                    maxDate={MOMENT.clone().subtract(18, 'year')}
                    minDate={MOMENT.clone().subtract(100, 'year')}
                    format="DD/MM/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    initialFocusedDate={MOMENT.clone().subtract(18, 'year')}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textTwelve}</span>
            <div className="value">
              <div className="form-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={data.user.gender}
                    onChange={this.handleChange('gender')}
                    row
                    className="radio-group"
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio color="primary" />}
                      label={languageMap.textThirteen}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio color="primary" />}
                      label={languageMap.textFourteen}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key"></span>
            <div className="value">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(data.additional_info.has_sold_before)}
                    onChange={this.handleChange('has_sold_before')}
                    value="true"
                    color="primary"
                    className="checkbox"
                  />
                }
                label={languageMap.textFifteen}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(data.additional_info.has_broker_license)}
                    onChange={this.handleChange('has_broker_license')}
                    value="true"
                    color="primary"
                    className="checkbox"
                  />
                }
                label={languageMap.textSixteen}
              />
            </div>
          </div>
          {this.renderAddressFields()}
          {this.renderAddressFields(true)}

          <div className="key-value">
            <span className="key"></span>
            <div className="value">
              <div className="buttons-wrap">
                <button className="button medium" onClick={this.submit}>
                  {languageMap.textEight}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditAffiliateForm.propTypes = {};

function mapStateToProps(state) {
  return {
    user: state.user,
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { showNotification })(EditAffiliateForm));
