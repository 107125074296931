import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { doScrolling, isMobileDevice } from '../utils/helper';
import InlineError from './InlineError';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
};

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.options || [],
      selected: props.value || [],
      selectedValue: props.value ? props.value.join(',') : '',
      error: props.error || '',
    };
  }

  componentWillMount() {
    this.timer = null;
    this.componentMounted = true;
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        showOptions: false,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    window.console.log(nextProps.value);
    if (this.props.autoFocus !== nextProps.autoFocus) {
      this.handleFocus(null, true);
    }
    if (this.props.value !== nextProps.value && !this.firstChange) {
      this.firstChange = true;
      this.setState({
        selectedValue: nextProps.value ? nextProps.value.join(',') : '',
        selected: nextProps.value,
      });
    }
  }

  handleFocus = (e, scrollElement) => {
    this.setState({
      showOptions: true,
    });
    if (scrollElement && isMobileDevice()) {
      setTimeout(() => {
        window.console.log(this.node);
        doScrolling(this.node, 500, 70);
      }, 500);
    }
  };

  onCheckboxChange = (e, data) => {
    if (this.state.selectAll) {
      this.setState({
        selectAll: false,
      });
    }
    let selected = this.state.selected;
    if (data.checked) {
      selected.push(data.value);
    } else {
      let index = selected.indexOf(data.value);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    }
    let selectedValue = selected.join(',');
    this.setState(
      {
        selected,
        selectedValue,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(null, selected, data);
        }
      }
    );
  };

  selectAll = (e, data) => {
    window.console.log(data);
    let selectedValue = this.state.selectedValue;
    let allOptions = JSON.parse(JSON.stringify(this.state.options)).join(',');
    let selected = [];
    let selectAll = this.state.selectAll;
    if (data.checked) {
      selectedValue = allOptions;
      selectAll = true;
      selected = allOptions.split(',');
    } else {
      selectedValue = '';
      selected = [];
      selectAll = false;
    }
    this.setState(
      {
        [data.name]: data.checked,
        selected,
        selectedValue,
        selectAll,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(null, selectedValue ? selectedValue.split(',') : []);
        }
        window.console.log('Selected', this.state.selected, selected, this.props.options, selectedValue);
      }
    );
  };

  render() {
    const { options, selectAll, selected, selectedValue, showOptions } = this.state;
    const { label, error, name } = this.props;

    return (
      <div className="checkbox-wrap" ref={(node) => (this.node = node)}>
        <div className="form">
          <label
            htmlFor="search"
            className={selectedValue ? 'selected' : ''}
            dangerouslySetInnerHTML={{ __html: label || '' }}
          ></label>
          {error && (
            <p className="error-wrap">
              <InlineError text={error} />
            </p>
          )}
        </div>
        <div className="options-wrap">
          {options.map((key, index) => {
            return (
              <Form.Field key={index}>
                <Checkbox
                  label={key}
                  name={key}
                  value={key}
                  text="selected"
                  onChange={this.onCheckboxChange}
                  checked={selected.indexOf(key) !== -1 ? true : false}
                />
              </Form.Field>
            );
          })}
          <Form.Field>
            <Checkbox
              label="Select All"
              name="selectAll"
              text="selectAll"
              onChange={this.selectAll}
              checked={selectAll}
            />
          </Form.Field>
        </div>
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {})(CheckboxGroup);
