import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import 'moment/locale/th';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { setFilter } from '../../actions/filter';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { store } from '../../index';
import { getFilterData, setFilterData } from '../../utils/helper';

const MOMENT = moment().locale('th').add(543, 'years');

class ProvinceModal extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('ProvinceModal => ', props);

    this.state = {
      data: {
        province: '',
        // owner_birth_year: null
      },
      provinces: [],
      errors: {},
    };
  }

  componentDidMount() {
    this.getProvinces();
  }

  getProvinces = () => {
    api.masterData
      .getProvinces()
      .then((provinces) => {
        this.setState({
          provinces,
        });
      })
      .catch((error) => console.log(error));
  };

  handleChange = (name) => (event) => {
    if (!event) {
      // Happening in case of date picker.
      return;
    }
    let value = null;
    if (moment.isMoment(event)) {
      value = event;
    } else {
      value = event.target.value;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  validate = (data) => {
    let errors = {};
    const messages = this.props.languageMap.messages;

    Object.keys(this.props.data).forEach((key) => {
      if (!data[key] && this.props.data[key]) {
        errors[key] = messages.required;
      }
    });
    return errors;
  };

  submit = () => {
    this.setState({
      errors: {},
    });
    let data = { ...this.state.data };
    let errors = this.validate(data);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }

    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        delete data[key];
      }
    });
    // if(data.owner_birth_year) {
    //   data.owner_birth_year = thaiToDefaultYear(moment(data.owner_birth_year));
    // }
    let filterData = { ...getFilterData(), ...data };
    setFilterData(filterData);
    store.dispatch(setFilter(filterData));
    this.props.action(data);
    this.close();
  };

  render() {
    const { provinces, errors, data } = this.state;
    const languageMap = this.props.languageMap.components.provinceModal;
    const { province, owner_birth_year } = this.props.data;

    return (
      <div className="fairdee-modal-wrapper medium province-modal">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />

              <span className="title-text">&nbsp;</span>
            </p>
          </div>
          {/* <h1 className="title-text">{languageMap.textOne}</h1> */}
          <Form noValidate className="quotation-form">
            {province ? (
              <FormControl className="province-wrap">
                <p className="sub-text">{languageMap.textOne}</p>
                <p className="sub-text">{languageMap.textFour}</p>
                <div className="select-wrap">
                  <InputLabel htmlFor="select-multiple">{languageMap.textTwo}</InputLabel>
                  <Select
                    value={data.province}
                    onChange={this.handleChange('province')}
                    name="province"
                    input={<Input id="select-multiple" />}
                  >
                    {provinces.map((province) => (
                      <MenuItem key={province.id} value={province.id}>
                        {province.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.province && <InlineError text={errors.province} />}
                </div>
              </FormControl>
            ) : null}
            {/* {
              owner_birth_year ? <div className="form-field mb-40">
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <DatePicker
                    autoOk
                    clearable
                    animateYearScrolling={false}
                    id="owner_birth_year"
                    label={languageMap.textFive}
                    initialFocusedDate={MOMENT.clone().subtract(18, 'year').format('YYYY')}
                    className="full-width"
                    value={data.owner_birth_year}
                    onChange={this.handleChange('owner_birth_year')}
                    maxDate={MOMENT.clone().subtract(18, 'year').format('YYYY')}
                    minDate={MOMENT.clone().subtract(100, 'year').format('YYYY')}
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    invalidDateMessage={this.props.languageMap.messages.invalidDateMessage}
                    minDateMessage={this.props.languageMap.messages.minDateMessage}
                    views={['year']}
                  />
                </MuiPickersUtilsProvider>
                {errors.owner_birth_year && <InlineError text={errors.owner_birth_year} className="top-offset" />}
              </div> : null
            } */}
            <Form.Field className="button-wrap">
              <button className="button primary medium" onClick={this.submit}>
                {languageMap.textThree}
              </button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

ProvinceModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(ProvinceModal));
