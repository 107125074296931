import React from 'react';
import { isAdmin } from '../../utils/helper';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import apiEndpoints from '../../commonjs/apiEndpoints';
import { NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

function DownloadDocumentsModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.policiesInprocessTable);
  let supportedTypes = {
    copy_of_policy: 'copyOfPolicy',
    copy_of_compulsory_policy: 'copyOfCompulsoryPolicy',
    cover_note: 'coverNote',
    wht_certificate: 'whtCert',
    wht_certificate_alternative: 'whtCertAlternative',
    incentive_wht_certificate: 'incentiveWhtCert',
    referral_incentive_wht_certificate: 'referralIncentiveWhtCert',
    temporary_receipt: 'temporaryReceipt',
  };

  if (isAdmin()) {
    supportedTypes['address'] = 'address';
  }

  function close() {
    props.hideModal(null, { ...props });
  }

  function notifyAndReturn() {
    props.showNotification(NOTIFICATION, {
      message: languageMap.fileDoesnotExist,
      type: 'error',
      autoClose: true,
    });
  }

  function downloadDocument(document_type) {
    let { quotation, sale } = props;

    const link = document.createElement('a');
    let url = '',
      str = '';
    let params = {
      download: true,
      resp_format: 'pdf',
    };

    if (
      document_type === 'wht_certificate' ||
      document_type === 'temporary_receipt' ||
      document_type === 'incentive_wht_certificate' ||
      document_type === 'wht_certificate_alternative'
    ) {
      let flow = 'f1';
      if (!sale || !['payment_verified', 'commission_transferred'].includes(sale.payment_status)) {
        return notifyAndReturn();
      }

      if (document_type === 'incentive_wht_certificate') {
        flow = 'f5';
      } else if (document_type === 'wht_certificate') {
        flow = 'f3';
      }
      //Alternative is only for UI and flow purpose
      if (document_type === 'wht_certificate_alternative') {
        document_type = 'wht_certificate';
      }
      params = {
        ...params,
        fairdee_sale_id: sale.id,
        document_type,
        flow,
      };
      url = apiEndpoints.generateSaleDocuments;
    }

    if (document_type === 'cover_note') {
      if (!quotation.cover_note_code) {
        return notifyAndReturn();
      }

      params = {
        ...params,
        quotation_id: quotation.id,
      };
      url = apiEndpoints.downloadCoverNote;
    }

    if (document_type === 'copy_of_policy') {
      if (!props.is_corona && !props.is_dengue && !props.is_pa) {
        if (!sale.voluntary_policy || !sale.voluntary_policy.documents || !sale.voluntary_policy.documents.length) {
          return notifyAndReturn();
        }

        let doc = sale.voluntary_policy.documents[0];
        url = apiEndpoints.policyDocument + '/' + doc.id;
      } else {
        if (props.data.documents.some((document) => document.document_type === 'policy')) {
          if (props.is_corona) {
            url = apiEndpoints.covidDocument;
          } else if (props.is_dengue) {
            url = apiEndpoints.dengueDocument;
          } else if (props.is_pa) {
            url = apiEndpoints.paDocument;
          }
          url = url + '/' + props.data.documents.filter((document) => document.document_type === 'policy')[0].id;
        } else {
          return notifyAndReturn();
        }
      }
    }

    if (document_type === 'copy_of_compulsory_policy') {
      if (!sale.compulsory_policy || !sale.compulsory_policy.documents || !sale.compulsory_policy.documents.length) {
        return notifyAndReturn();
      }

      let doc = sale.compulsory_policy.documents[0];
      url = apiEndpoints.policyDocument + '/' + doc.id;
    }

    if (document_type === 'address') {
      if (!quotation.policy_delivery_address?.id) {
        return notifyAndReturn();
      }

      params = {};
      url = `${apiEndpoints.downloadAddress}/${quotation.id}`;
    }

    for (var key in params) {
      if (str !== '') {
        str += '&';
      }
      if (params[key] && params[key].constructor && params[key].constructor === Array) {
        params[key].forEach((data, index) => {
          if (index !== 0) {
            str += '&';
          }
          str += key + '=' + encodeURIComponent(data);
        });
      } else {
        str += key + '=' + encodeURIComponent(params[key]);
      }
    }

    link.textContent = 'download';
    link.href = `${url}?${str}`;
    link.setAttribute('download', `${document_type}`);
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="fairdee-modal-wrapper customer-info remark">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">{languageMap.downloadDocument}</span>
          </p>
        </div>
        <h1 className="title-text">{languageMap.downloadDocument}</h1>
        <div className="download-btn-group">
          {Object.keys(supportedTypes).map((key) =>
            props.documents.indexOf(key) > -1 ? (
              <div className="button medium orange-bordered-button" key={key} onClick={() => downloadDocument(key)}>
                {languageMap[supportedTypes[key]]}
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showNotification })(DownloadDocumentsModal);
