import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { setLanguage, setLanguageMap } from '../actions/language';
import { showModal } from '../actions/modal';
import { FAIRDEE_CONTACT_NUMBER, FAIRDEE_CONTACT_NUMBER_LABEL, FAIRDEE_FB_URL } from '../constants';
import { MOBILE_MODAL } from '../constants/types';
import close from '../images/close.svg';
import LogoPlus from '../images/fairdee-x-plus/common/logo-plus.svg';
import ThailandFlag from '../images/fairdee-x-plus/icons/thailand-flag.svg';
import UkFlag from '../images/fairdee-x-plus/icons/uk-flag.svg';
import menuIcon from '../images/hamburger.png';
import Calculator from '../images/icons/calculator.svg';
import Motor from '../images/icons/car.svg';
import fbDark from '../images/icons/fb.png';
import Home from '../images/icons/home.svg';
import AboutUs from '../images/icons/info.svg';
import Faq from '../images/icons/messages.svg';
import phoneDark from '../images/icons/phone.svg';
import HowItWorks from '../images/icons/settings.svg';
import line from '../images/latest-landing/line.png';
import { languageMap as LangMap } from '../language-map/mapping';
import '../scss/_landing-layout.scss';
import { getLineUrl, getParameterByName, isMobileDevice, setLanguage as setLanguageLocally } from '../utils/helper';
import { Mixpanel } from '../utils/tracking';
import Login from './fairdee-x-plus/auth/LoginPhone';
import Register from './fairdee-x-plus/auth/Register';

function LatestHeader({ isIg, isDirectAgent, isBusinessManager }) {
  const languageMapping = useSelector((state) => state.languageMap);
  const languageMap = languageMapping.components.latestLanding;
  const languageMapXplus = languageMapping.fairdeeXplus;
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch(),
    history = useHistory(),
    [showActionSheet, setActionSheet] = useState(false),
    [collapseMap, setCollapseMap] = useState({}),
    node = useRef(null);

  useEffect(() => {
    if (getParameterByName('intent') === 'login') {
      login();
    }
  }, []);

  function login() {
    Mixpanel.track('Login/signup', {
      object: 'Login Button',
      objectContext: {
        parent: 'Landing Header',
      },
      action: 'Clicked',
    });
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Login isIg={isIg} isDirectAgent={isDirectAgent} isBusinessManager={isBusinessManager} />,
        uid: 'LOGIN',
      })
    );
  }

  function register() {
    Mixpanel.track('Login/signup', {
      object: 'Register Button',
      objectContext: {
        parent: 'Landing Header',
      },
      action: 'Clicked',
    });
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Register isIg={isIg} isDirectAgent={isDirectAgent} isBusinessManager={isBusinessManager} />,
        uid: 'REGISTER',
      })
    );
  }

  function toggleLanguage(language) {
    setLanguageLocally(language);
    dispatch(setLanguageMap(LangMap[language]));
    dispatch(setLanguage(language));
  }

  function toggleActionSheet() {
    setActionSheet(!showActionSheet);
  }

  function handleCollapse(current) {
    setCollapseMap({ ...collapseMap, [current]: !collapseMap[current] });
  }

  function renderMobileHeader() {
    return (
      <div className="landing-header">
        <div className="container">
          <div className="content">
            <div className="logo-wrap">
              <NavLink to="/">
                <img src={LogoPlus} alt="" />
              </NavLink>
            </div>
            <div className="menu">
              <div className="social-icons">
                <a href={FAIRDEE_FB_URL} target="_blank" rel="noopener noreferrer">
                  <img src={fbDark} alt="" />
                </a>
                <a href={getLineUrl()} target="_blank" rel="noopener noreferrer">
                  <img src={line} alt="" />
                </a>
              </div>
              <img src={menuIcon} alt="" onClick={toggleActionSheet} />
            </div>
          </div>
        </div>
        <div
          className={'action-sheet navigation no-translate ' + (showActionSheet ? 'show' : '')}
          onClick={toggleActionSheet}
        >
          <div className="content" ref={node} onClick={(e) => e.stopPropagation()}>
            <div className="img-wrap">
              <img src={close} alt="" onClick={toggleActionSheet} />
            </div>
            {!isIg ? (
              <div className="buttons-wrap auth">
                <button className="button" onClick={login}>
                  {languageMapXplus.Login}
                </button>
                <button className="button orange" onClick={register}>
                  {languageMapXplus.Signup}
                </button>
              </div>
            ) : null}
            <div className="links-wrap">
              <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.homeLink}>
                <span className="icon">
                  <img src={Home} alt="" />
                </span>
                {languageMap.header.home}
              </NavLink>
              <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.roadTaxLink}>
                <span className="icon">
                  <img src={Calculator} alt="" />
                </span>
                {languageMap.header.roadTax}
              </NavLink>
              <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.faqLink}>
                <span className="icon">
                  <img src={Faq} alt="" />
                </span>
                {languageMap.header.faq}
              </NavLink>
            </div>
            <div className={'collapse-wrap ' + (collapseMap['mi'] ? 'open' : '')}>
              <p onClick={() => handleCollapse('mi')}>
                <span className="icon">
                  <img src={Motor} alt="" />
                </span>{' '}
                {languageMap.subHeader.motorInsurance.title} <ArrowDropDownIcon />
              </p>
              {collapseMap['mi'] && (
                <div className="open-wrap">
                  <span className="list" onClick={() => openContentView('type1')}>
                    {languageMap.subHeader.motorInsurance.type1}
                  </span>
                  <span className="list" onClick={() => openContentView('type2')}>
                    {languageMap.subHeader.motorInsurance.type2plus}
                  </span>
                  <span className="list" onClick={() => openContentView('type3')}>
                    {languageMap.subHeader.motorInsurance.type3plus}
                  </span>
                  <span className="list" onClick={() => openContentView('type23')}>
                    {languageMap.subHeader.motorInsurance.type2and3}
                  </span>
                </div>
              )}
            </div>
            {/* <div className={'collapse-wrap ' + (collapseMap['oi'] ? 'open' : '')}>
              <p onClick={() => handleCollapse('oi')}>
                <span className="icon">
                  <img src={Badge} alt="" />
                </span>{' '}
                {languageMap.subHeader.otherInsurances.title} <ArrowDropDownIcon />
              </p>
              {collapseMap['oi'] && (
                <div className="open-wrap">
                  <span className="list" onClick={() => openContentView('covid')}>
                    {languageMap.subHeader.otherInsurances.covid}
                  </span>
                  <span className="list" onClick={() => openContentView('dengue')}>
                    {languageMap.subHeader.otherInsurances.dengue}
                  </span>
                  <span className="list" onClick={() => openContentView('pa')}>
                    {languageMap.subHeader.otherInsurances.pa}
                  </span>
                </div>
              )}
            </div> */}
            <div className={'collapse-wrap ' + (collapseMap['fl'] ? 'open' : '')}>
              <p onClick={() => handleCollapse('fl')}>
                <span className="icon">
                  <img src={HowItWorks} alt="" />
                </span>{' '}
                {languageMap.subHeader.fairDeeLibrary.title}
                <ArrowDropDownIcon />
              </p>
              {collapseMap['fl'] && (
                <div className="open-wrap">
                  <span className="list" onClick={() => handleContentChange('news')}>
                    {languageMap.subHeader.fairDeeLibrary.newsFromFairdee}
                  </span>
                  <span className="list" onClick={() => handleContentChange('clubFairdee')}>
                    {languageMap.subHeader.fairDeeLibrary.clubFairdee}
                  </span>
                  <span className="list" onClick={() => handleContentChange('insuranceKnowledge')}>
                    {languageMap.subHeader.fairDeeLibrary.insuranceKnowledge}
                  </span>
                  <span className="list" onClick={() => handleContentChange('packages')}>
                    {languageMap.subHeader.fairDeeLibrary.insurancePackages}
                  </span>
                  <span className="list" onClick={() => handleContentChange('hiw')}>
                    {languageMap.subHeader.fairDeeLibrary.howItWorks}
                  </span>
                </div>
              )}
            </div>
            <div className="links-wrap">
              <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.aboutUsLink}>
                <span className="icon">
                  <img src={AboutUs} alt="" />
                </span>
                {languageMap.header.aboutUs}
              </NavLink>
            </div>
            <div className="bottom-section">
              <p className="text">Language</p>
              <div className="language-toggle">
                <span className={language === 'thai' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'thai')}>
                  <img src={ThailandFlag} alt="" />
                  ไทย
                </span>
                <span className={language === 'english' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'english')}>
                  <img src={UkFlag} alt="" />
                  EN
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function openContentView(view) {
    history.push(`/insurance/${view}`);
    toggleActionSheet();
  }

  function handleContentChange(name) {
    if (isMobileDevice()) {
      toggleActionSheet();
    }
    if (name === 'hiw') {
      history.push(`/how-it-works`);
      return;
    }
    history.push(`/tutorials?name=${name}`);
  }

  function renderDesktopView() {
    return (
      <div className="landing-header">
        <div className="container">
          <div className="content">
            <div className="logo-wrap">
              <NavLink to="/">
                <img src={LogoPlus} alt="" />
              </NavLink>
            </div>
            <div className="menu">
              <a href={`tel:${FAIRDEE_CONTACT_NUMBER}`} className="phone">
                <img src={phoneDark} alt="" />
                {FAIRDEE_CONTACT_NUMBER_LABEL}{' '}
              </a>
              <div className="social-icons">
                <a href={FAIRDEE_FB_URL} target="_blank" rel="noopener noreferrer">
                  <img src={fbDark} alt="" />
                </a>
                <a href={getLineUrl()} target="_blank" rel="noopener noreferrer">
                  <img src={line} alt="" />
                </a>
              </div>
              {!isIg ? (
                <>
                  <button className="button" onClick={login}>
                    {languageMapXplus.Login}
                  </button>
                  <button className="button orange" onClick={register}>
                    {languageMapXplus.Signup}
                  </button>
                </>
              ) : null}
              <div className="language-toggle">
                <span className={language === 'thai' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'thai')}>
                  <img src={ThailandFlag} alt="" />
                  ไทย
                </span>
                <span className={language === 'english' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'english')}>
                  <img src={UkFlag} alt="" />
                  EN
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-header">
          <div className="container">
            <span className="link">
              <NavLink to="/" activeClassName="active" exact>
                {languageMap.header.textThree}{' '}
              </NavLink>
            </span>
            <span className="link">
              {languageMap.subHeader.motorInsurance.title}
              <ArrowDropDownIcon />
              <div className="link-ddl">
                <div>
                  <span className="link" onClick={() => openContentView('type1')}>
                    {languageMap.subHeader.motorInsurance.type1}
                  </span>
                  <span className="link" onClick={() => openContentView('type2')}>
                    {languageMap.subHeader.motorInsurance.type2plus}
                  </span>
                  <span className="link" onClick={() => openContentView('type3')}>
                    {languageMap.subHeader.motorInsurance.type3plus}
                  </span>
                  <span className="link" onClick={() => openContentView('type23')}>
                    {languageMap.subHeader.motorInsurance.type2and3}
                  </span>
                </div>
              </div>
            </span>
            {/* <span className="link">
              {languageMap.subHeader.otherInsurances.title}
              <ArrowDropDownIcon />
              <div className="link-ddl">
                <div>
                  <span className="link" onClick={() => openContentView('covid')}>
                    {languageMap.subHeader.otherInsurances.covid}
                  </span>
                  <span className="link" onClick={() => openContentView('dengue')}>
                    {languageMap.subHeader.otherInsurances.dengue}
                  </span>
                  <span className="link" onClick={() => openContentView('pa')}>
                    {languageMap.subHeader.otherInsurances.pa}
                  </span>
                </div>
              </div>
            </span> */}
            <NavLink to={languageMap.header.roadTaxLink} activeClassName="active" exact>
              {languageMap.header.roadTax}
            </NavLink>
            <NavLink to={languageMap.header.faqLink} activeClassName="active" exact>
              {languageMap.header.faq}
            </NavLink>
            <span className="link">
              {languageMap.subHeader.fairDeeLibrary.title}
              <ArrowDropDownIcon />
              <div className="link-ddl">
                <div>
                  <span className="link" onClick={() => handleContentChange('news')}>
                    {languageMap.subHeader.fairDeeLibrary.newsFromFairdee}
                  </span>
                  <span className="link" onClick={() => handleContentChange('clubFairdee')}>
                    {languageMap.subHeader.fairDeeLibrary.clubFairdee}
                  </span>
                  <span className="link" onClick={() => handleContentChange('insuranceKnowledge')}>
                    {languageMap.subHeader.fairDeeLibrary.insuranceKnowledge}
                  </span>
                  <span className="link" onClick={() => handleContentChange('packages')}>
                    {languageMap.subHeader.fairDeeLibrary.insurancePackages}
                  </span>
                  <span className="link" onClick={() => handleContentChange('hiw')}>
                    {languageMap.subHeader.fairDeeLibrary.howItWorks}
                  </span>
                </div>
              </div>
            </span>
            <NavLink to={languageMap.header.aboutUsLink}>{languageMap.header.aboutUs}</NavLink>
          </div>
        </div>
      </div>
    );
  }

  if (isMobileDevice()) {
    return renderMobileHeader();
  }

  return renderDesktopView();
}

export default LatestHeader;
