import { Button, Checkbox, DatePicker, Input } from 'antd';
import 'antd/dist/antd.css';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../commonjs/api';
import { errorHandler, notify, toUTCTime } from '../../utils/helper';
import './_event-form.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../..';

const { TextArea } = Input;

interface FormData {
  accountApplicable: any;
  eventName: string;
  startDateTime: Moment | null;
  endDateTime: Moment | null;
  registrationLink: string;
  eventLocation: string;
  locationLink: string;
  eventDescription: string;
}

interface PropsType {
  data: any;
  close?: () => void;
  setRefreshPage: Dispatch<SetStateAction<boolean>>;
}

const EventForm: React.FC<PropsType> = ({ data, close, setRefreshPage }) => {
  const [formData, setFormData] = useState<FormData>({
      accountApplicable: data?.account_applicable || {
        FM: false,
        FD: false,
        AO: false,
        IG: false,
      },
      eventName: data?.name || '',
      startDateTime: moment(data?.start_time) || null,
      endDateTime: moment(data?.end_time) || null,
      registrationLink: data?.registration_link || '',
      eventLocation: data?.location || '',
      locationLink: data?.location_link || '',
      eventDescription: data?.description || '',
    }),
    languageMap = useSelector((state : RootState) => state.languageMap.components.eventForm),
    [startDatePickerParentElement, setStartDatePickerParentElement] = useState(document.getElementById('start-time-datepicker')),
    [endDatePickerParentElement, setEndDatePickerParentElement] = useState(document.getElementById('end-time-datepicker'));

  useEffect(() => {
    let startDatePicker = document.getElementById('start-time-datepicker');
    setStartDatePickerParentElement(startDatePicker);

    let endDatePicker = document.getElementById('end-time-datepicker');
    setEndDatePickerParentElement(endDatePicker);
  }, []);

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    let updatedAccounts: any = {};

    checkedValues.forEach((value: any) => {
      updatedAccounts = { ...updatedAccounts, [value]: true };
    });

    const allAccounts = ['FM', 'FD', 'IG', 'AO'];
    allAccounts.forEach((value) => {
      updatedAccounts[value] = checkedValues.includes(value);
    });

    setFormData((prevData) => ({ ...prevData, accountApplicable: updatedAccounts }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateTimeChange = (name: any, date: Moment | null) => {
    setFormData((prevData) => ({ ...prevData, [name]: date }));
  };

  const handleSubmit = () => {
    let accountApplicable: any = {};
    Object.keys(formData.accountApplicable).map((key) => {
      if (key === 'FD') {
        accountApplicable.direct_agent = formData.accountApplicable[key];
      } else if (key === 'FM') {
        accountApplicable.mlm_agent = formData.accountApplicable[key];
      } else if (key === 'AO') {
        accountApplicable.ao_agent = formData.accountApplicable[key];
      } else if (key === 'IG') {
        accountApplicable.inspection_garage = formData.accountApplicable[key];
      }
    });
    let params = {
      name: formData.eventName,
      account_applicable: accountApplicable,
      start_time: toUTCTime(formData.startDateTime),
      end_time: toUTCTime(formData.endDateTime),
      registration_link: formData.registrationLink,
      location: formData.eventLocation,
      location_link: formData.locationLink,
      description: formData.eventDescription,
    };
    if (data && data?.id) {
      api.utils
        .patchCalendarEvent(data.id, params)
        .then(() => {
          setRefreshPage(true);
          notify(languageMap['Edited Successfully'], 'success');
        })
        .catch((error) => {
          errorHandler(error.response, true);
        });
    } else {
      api.utils
        .postCalendarEvents(params)
        .then((response) => {
          setRefreshPage(true);
          notify(languageMap['Event Created Successfully'], 'success');
        })
        .catch((error) => {
          errorHandler(error.response, true);
        });
    }
    close && close();
  };

  const disabledDate = (current: any) => {
    return (
      current &&
      current < moment().startOf('minute') &&
      (formData?.startDateTime ? current < formData?.startDateTime : true)
    );
  };

  return (
    <div className="event-form">
      <div>
        <label className="label" htmlFor="account_applicable">
          {languageMap['Account Applicable']} *
        </label>
        <Checkbox.Group
          options={['FM', 'FD', 'IG', 'AO']}
          value={Object.keys(formData.accountApplicable).filter((key) => formData.accountApplicable[key])}
          onChange={handleCheckboxChange}
          name="account_applicable"
          className="input checkbox"
        />
      </div>
      <div>
        <label className="label" htmlFor="eventName">
        {languageMap['Event Name']} *
        </label>
        <Input
          name="eventName"
          value={formData.eventName}
          defaultValue={data?.name || ''}
          onChange={handleChange}
          className="input"
        />
      </div>

      <div>
        <label className="label">{languageMap['Start Date and Time']} *</label>
        <div className="datepicker-view" id="start-time-datepicker"></div>
        {startDatePickerParentElement ? (
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value={formData.startDateTime}
            onChange={(date) => handleDateTimeChange('startDateTime', date)}
            disabledDate={(current: any) => disabledDate(current)}
            getPopupContainer={() => startDatePickerParentElement}
            className="input"
          />
        ) : null}
      </div>

      <div>
        <label className="label">{languageMap['End Date and Time']} *</label>
        <div className="datepicker-view" id="end-time-datepicker"></div>
        {endDatePickerParentElement ? (
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value={formData.endDateTime}
            onChange={(date) => handleDateTimeChange('endDateTime', date)}
            disabledDate={(current: any) => disabledDate(current)}
            getPopupContainer={() => endDatePickerParentElement}
            className="input"
          />
        ) : null}
      </div>

      <div>
        <label className="label">{languageMap['Event Registration Link']} *</label>
        <Input name="registrationLink" value={formData.registrationLink} onChange={handleChange} className="input" />
      </div>

      <div>
        <label className="label">{languageMap['Event Location']}</label>
        <Input name="eventLocation" value={formData.eventLocation} onChange={handleChange} className="input" />
      </div>

      <div>
        <label className="label">{languageMap['Location Link']}</label>
        <Input name="locationLink" value={formData.locationLink} onChange={handleChange} className="input" />
      </div>

      <div>
        <label className="label">{languageMap['Event Description']}</label>
        <TextArea name="eventDescription" value={formData.eventDescription} onChange={handleChange} className="text-box-input" maxLength={160} />
        <div className='sub-label-text'>*{languageMap['max character 160 words']}</div>
      </div>

      <div>
        <Button type="primary" className="orange-button submit-button" onClick={handleSubmit}>
          {data ? <span>{languageMap['Update Event']}</span> : <span>{languageMap['Create Event']}</span>}
        </Button>
      </div>
    </div>
  );
};

export default EventForm;
