import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import Validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { NOTIFICATION } from '../../constants/types';
import { snakeToTitleCase } from '../../utils/helper';

const wht = { label: 'WHT Certificate', key: 'wht_certificate' };
const tax = { label: 'Receipt/Tax Invoice', key: 'tax_invoice' };
const payment = { label: 'Payment Voucher', key: 'payment_voucher' };
const receipt = { label: 'Receipt Voucher', key: 'receipt_voucher' };
const internal = { label: 'Internal Transfer Voucher', key: 'internal_transfer' };
const temporary = { label: 'Payment Voucher', key: 'temporary_receipt' };

const FLOW_DOC_MAP = {
  f1: [receipt, temporary, wht],
  f3: [payment, wht],
  f5: [payment, wht],
};

function GenerateSaleDocModal(props) {
  const [email, setEmail] = useState('');
  const [documentTypes, setDocumentTypes] = useState(FLOW_DOC_MAP[props.flow].map((type) => type.key));
  const [errors, setErrors] = useState({});
  const languageMap = useSelector((state) => state.languageMap);

  function validate() {
    const errors = {};

    if (!email) {
      errors.email = languageMap.messages.required;
    }
    if (email && !Validator.isEmail(email)) {
      errors.email = languageMap.messages.email;
    }
    if (!documentTypes.length) {
      errors.documentTypes = languageMap.messages.required;
    }

    return errors;
  }

  function handleInputChange(e) {
    setEmail(e.target.value);
  }

  function generate() {
    const errorList = validate();
    setErrors(errorList);
    if (Object.keys(errorList).length) {
      return;
    }
    let params = {
      ...props.apiParams,
      document_types: documentTypes,
      email_to: [email],
      flow: props.flow,
    };

    if (!props.is_corona) {
      params = { ...params, document_types: documentTypes, flow: props.flow };
    }
    let promise;
    if (props.is_corona) {
      promise = api.utils.generateCovidSaleDocuments(params);
    } else {
      promise = api.utils.generateSaleDocuments(params);
    }
    promise
      .then((res) => {
        props.showNotification(NOTIFICATION, {
          message: 'Successfully initiated doc generation',
          type: 'success',
          autoClose: true,
        });
        close();
      })
      .catch((err) => {
        props.showNotification(NOTIFICATION, {
          message: 'Failed',
          type: 'error',
          autoClose: true,
        });
      });
  }

  function handleCheckboxChange(e, data) {
    const selectedDocs = documentTypes;
    if (data.checked) {
      selectedDocs.push(data.name);
    } else {
      selectedDocs.splice(selectedDocs.indexOf(data.name), 1);
    }
    setDocumentTypes(selectedDocs.slice());
  }

  function close() {
    props.hideModal(null, { ...props });
  }

  return (
    <div className="fairdee-modal-wrapper generate-documents-modal medium">
      <div className="modal-content">
        {props.flow && !props.is_corona && (
          <div className="checkbox-wrapper">
            <div className="bold-text title">Document Types in the Selected Flow({snakeToTitleCase(props.flow)})</div>
            <div className="checkboxes">
              {FLOW_DOC_MAP[props.flow].map((option, index) => (
                <Checkbox
                  key={index}
                  label={option.label}
                  name={option.key}
                  value="1"
                  text="insurance_class"
                  onChange={handleCheckboxChange}
                  checked={documentTypes.indexOf(option.key) !== -1 ? true : false}
                />
              ))}
            </div>
            <InlineError text={errors.documentTypes} />
          </div>
        )}
        <div className="margin-top-10">
          This will generate documents for
          {(props.apiParams.fairdee_sale_ids || props.apiParams.covid_form_ids).length === 0 && (
            <span className="bold-text"> All sales in the date range.</span>
          )}
          {(props.apiParams.fairdee_sale_ids || props.apiParams.covid_form_ids).length > 0 && (
            <span className="bold-text"> selected sales.</span>
          )}
        </div>
        <div className="margin-top-10">Please provide the Email ID in which you'd like to receive the mail.</div>
        <FormControl component="div" className="form-element email-docs">
          <Input
            id="email"
            type="text"
            className="form-element mui-field"
            value={email}
            onChange={handleInputChange}
            placeholder="abc@xyz.com"
          />
        </FormControl>
        <InlineError text={errors.email} />
        <div className="options-container">
          <div className="row">
            <Button variant="outlined" className="secondary" onClick={close}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" className="primary" onClick={generate}>
              Generate Documents
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { showModal, hideModal, showNotification })(GenerateSaleDocModal);
