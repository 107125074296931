import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import { NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import empty from '../../images/empty.svg';
import { formatDateTime } from '../../utils/helper';

const PAGE_SIZE_LIST = [25, 50, 100, 500];

const styles = (theme) => ({
  root: {
    width: '100%',

    overflowX: 'auto',
    background: 'transparent',
    boxShadow: 'none',
  },
  table: {
    minWidth: 700,
  },
  pagination: {
    textAlign: 'right',
  },
  linkButton: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  actionCell: {
    minWidth: '250px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    minWidth: '120px',
  },
  inline: {
    flexGrow: 1,
    flexBasis: 0,
  },
});

const CenteredTableCell = withStyles((theme) => ({
  root: {
    textAlign: 'center !important',
  },
}))(TableCell);

class CompulsoryInvoiceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      insuranceList: null,
      totalPages: undefined,
      currentPage: undefined,
      nextPageUrl: undefined,
      previousPageUrl: undefined,
      loading: true,
      emptyList: false,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.getinsuranceList(undefined, { policy_bought: true });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  getinsuranceList = (url = undefined, params = {}) => {
    api.utils
      .compulsaryInsurance(url, { affiliate: this.props.id, ...params })
      .then((response) => {
        if (!response.results.length) {
          this.setState({
            emptyList: true,
          });
        }

        let data = response,
          insuranceList = data.results.map((row) => {
            let total_premium = '-';
            if (row.premium) {
              total_premium = parseFloat(row.premium) + parseFloat(row.tax) + parseFloat(row.duty);
            }
            if (row.is_cancelled) {
              row.disabledText = 'Policy is cancelled';
              row.disabled = true;
            }
            if (row.invoice_detail) {
              row.disabledText = 'Invoice already created';
              row.disabled = true;
            }

            return {
              ...row,
              name: `${row.salutation} ${row.first_name} ${row.last_name}`,
              vehicle: `${row.model_name}, ${row.make_name}`,
              total_premium: total_premium,
            };
          });
        this.setState({
          insuranceList: insuranceList,
          totalPages: data.total_pages,
          currentPage: data.current_page,
          nextPageUrl: data.next,
          previousPageUrl: data.previous,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (ind) => (event) => {
    let insuranceList = this.state.insuranceList;
    insuranceList[ind].checked = event.target.checked;
    let selectAll = false;
    let allSelected = insuranceList.filter((row) => !row.is_cancelled && !row.invoice_detail);
    let selected = insuranceList.filter((row) => !row.is_cancelled && !row.invoice_detail && row.checked);
    window.console.log(selected.length, allSelected.length);
    if (allSelected.length === selected.length) {
      selectAll = true;
    }
    this.setState({
      insuranceList,
      selectAll,
    });
  };

  handlePageSizeChange = (name) => (event) => {
    let value = event.target.value;
    window.console.log(value);
    this.getinsuranceList(undefined, { policy_bought: true, limit: value });
    this.setState({
      [name]: value,
    });
  };

  handleChangeSelectAll = (event) => {
    let checked = event.target.checked;
    window.console.log(checked);
    let insuranceList = this.state.insuranceList.map((row) => {
      if (row.is_cancelled) {
        row.checked = false;
      } else if (row.invoice_detail) {
        row.checked = false;
      } else {
        row.checked = checked;
      }
      return row;
    });
    window.console.log(insuranceList);
    this.setState({
      selectAll: checked,
      insuranceList,
    });
  };

  downloadPdf = (id) => {
    let url = apiEndpoints.invoice + '/' + id + '?resp_format=pdf';
    let win = window.open(url, '_blank');
    win.focus();
  };

  generateInvoice = () => {
    let selectedSales = this.state.insuranceList.filter((data) => data.checked).map((data) => data.id);
    window.console.log(selectedSales);
    if (selectedSales.length) {
      let params = {
        invoice_date: moment().format('YYYY-MM-DD'),
        compulsory_insurance_form_ids: selectedSales,
      };
      api.crm
        .postInvoice(params)
        .then((response) => {
          window.console.log(response);
          this.downloadPdf(response.id);
          this.notify('สร้างใบแจ้งหนี้สำเร็จแล้ว', 'success');
          this.close();
        })
        .catch((error) => {
          window.console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.constructor === Object
          ) {
            window.console.log(error.response.data.errors);
            Object.keys(error.response.data.errors).forEach((key) => {
              window.console.log(error.response.data.errors[key]);
              error.response.data.errors[key].forEach((data, index) => {
                window.console.log(data);
                ((i) => {
                  setTimeout(() => {
                    this.notify(data, 'error');
                  }, 1000 + 2000 * i);
                })(index);
              });
            });
          }
        });
    }
  };

  renderDesktopView = () => {
    const { classes } = this.props;
    const { insuranceList, loading, selectAll, nextPageUrl, previousPageUrl, currentPage, totalPages } = this.state;
    const languageMap = this.props.languageMap.components.compulsoryInvoicenvoiceModal;
    const TABLE_HEADERS = languageMap.tableHeader || [
      'Created At',
      'Customer Name',
      'Mobile',
      'Vehicle Number',
      'Vehicle',
      'Vehicle',
    ];

    window.console.log('render desktop view.....', insuranceList);

    return (
      <div className="custom-table-wrapper">
        <Paper className={classes.root}>
          {(loading || !insuranceList) && <CircularProgress />}
          {!loading && insuranceList && (
            <div className="sale-type">
              <Table className={classes.table + ' custom-table'}>
                <TableHead>
                  <TableRow>
                    {insuranceList.length > 1 ? (
                      <th>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(selectAll)}
                              onChange={this.handleChangeSelectAll}
                              value="select"
                            />
                          }
                          label="เลือกทั้งหมด"
                        />
                      </th>
                    ) : (
                      <CenteredTableCell>เลือก</CenteredTableCell>
                    )}
                    {TABLE_HEADERS.map((header, index) => (
                      <CenteredTableCell key={index}>{header}</CenteredTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insuranceList.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <CenteredTableCell>
                          {row.disabled ? (
                            <p className="helper-text">{row.disabledText}</p>
                          ) : (
                            <Checkbox checked={Boolean(row.checked)} onChange={this.handleChange(index)} />
                          )}
                        </CenteredTableCell>
                        <CenteredTableCell>{formatDateTime(row.created_at)}</CenteredTableCell>
                        <CenteredTableCell component="th" scope="row">
                          {row.name}
                        </CenteredTableCell>
                        <CenteredTableCell>{row.mobile}</CenteredTableCell>
                        <CenteredTableCell>{row.vehicle_number}</CenteredTableCell>
                        <CenteredTableCell>{row.vehicle}</CenteredTableCell>
                        <CenteredTableCell>{row.total_premium}</CenteredTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={TABLE_HEADERS.length + 1} className={classes.pagination}>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <IconButton
                        onClick={this.getinsuranceList.bind(null, previousPageUrl, {})}
                        disabled={!Boolean(previousPageUrl)}
                        aria-label="Previous Page"
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      <IconButton
                        onClick={this.getinsuranceList.bind(null, nextPageUrl, {})}
                        disabled={!Boolean(nextPageUrl)}
                        aria-label="Next Page"
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          )}
        </Paper>
      </div>
    );
  };

  render() {
    const { emptyList, pageSize } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.compulsoryInvoicenvoiceModal;

    return (
      <div className="fairdee-modal-wrapper full-screen">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          {/* <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title">Generate Invoices</span>
            </p>
          </div> */}
          <h1 className="title">
            {languageMap.textOne}
            {!emptyList && (
              <div className="inline-wrap">
                <button className="button medium" onClick={this.generateInvoice}>
                  {languageMap.textThree}
                </button>
                <TextField
                  select
                  label="Page Size"
                  className={classes.textField + ' ' + classes.inline}
                  value={pageSize || ''}
                  autoComplete="off"
                  onChange={this.handlePageSizeChange('pageSize')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  {PAGE_SIZE_LIST.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </h1>
          <div className="content-wrapper">
            {emptyList ? (
              <div className="empty-wrapper">
                <img src={empty} alt="" />
                <p className="title">{languageMap.textFour}</p>
                <p className="text">{languageMap.textFive}</p>
              </div>
            ) : (
              this.renderDesktopView()
            )}
          </div>
        </div>
      </div>
    );
  }
}

CompulsoryInvoiceModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { hideModal, showNotification })(withStyles(styles)(CompulsoryInvoiceModal))
);
