import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideNotification } from '../../actions/notification';
import Notification from '../../components/Notification';
import { NOTIFICATION } from '../../constants/types';

const notificationComponents = {
  [NOTIFICATION]: Notification,
};

const NotificationRoot = ({ notification, hideNotification }) => {
  let ind = 0;
  return notification.map((data, index) => {
    if (!data.type) {
      return null;
    }
    if (data.props.autoClose) {
      const time = data.props.time || 5000;
      data.props.time = time;
      setTimeout(() => {
        hideNotification(data.type, data.props);
      }, time);
    }

    data.props.style = {
      marginTop: ind * 80,
    };
    data.props.uid = data.uid;

    ind++;

    const NotificationComponent = notificationComponents[data.type];
    return <NotificationComponent key={data.uid} {...data.props} />;
  });
};

const mapState = ({ notification }) => {
  return { notification };
};

export default withRouter(connect(mapState, { hideNotification })(NotificationRoot));
