import { ChatOutlined } from '@material-ui/icons';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import CalendarIconDark from '../../images/icons/calendar-icon-dark.svg';
import CalendarIconLight from '../../images/icons/calendar-icon-light.svg';
import ComissionDark from '../../images/icons/comission-dark.svg';
import ComissionLight from '../../images/icons/comission-light.svg';
import GraphDark from '../../images/icons/graph-dark.svg';
import GraphLight from '../../images/icons/graph-light.svg';
import HomeDark from '../../images/icons/home-dark.svg';
import HomeLight from '../../images/icons/home-light.svg';
import UserDark from '../../images/icons/user-dark.svg';
import UserLight from '../../images/icons/user-light.svg';
import {
  isAdmin,
  isAgent,
  isDealerOrLeadgen,
  isInspectionGarage,
  isMember,
  isMlmAgent,
  isMobileDevice,
  isSubAgent,
  isTelesales,
} from '../../utils/helper';
import { Mixpanel } from '../../utils/tracking';
import NavList from '../NavList';
import Refer from './Refer';

function Sidebar(props) {
  const languageMap = useSelector((state) => state.languageMap.components.subMenu),
    user = useSelector((state) => state.user),
    history = useHistory();

  function trackNavlick(name) {
    Mixpanel.track('Sub Menu', {
      object: name,
      action: 'Clicked',
    });
  }

  const changeNav = (url) => {
    history.push(url);
  };

  if (isMobileDevice() && !isMember()) {
    return null;
  }

  return (
    <div className="sidebar">
      <div className="fixed-section">
        <div className="nav-wrap">
          <div
            onClick={() => {
              changeNav('/buy-insurance');
            }}
            className={'list ' + (window.location.pathname === '/buy-insurance' ? 'active' : '')}
          >
            <span className="icon">
              <img src={HomeDark} className="dark" alt="" />
              <img src={HomeLight} className="light" alt="" />
            </span>
            <NavLink className="text" to="/buy-insurance" onClick={() => trackNavlick('Home')} title={languageMap.home}>
              {languageMap.home}
            </NavLink>
          </div>
          {(isAdmin() || isInspectionGarage()) && (
            <div
              onClick={() => {
                changeNav('/subagent-portal');
              }}
              className={'list ' + (window.location.pathname === '/subagent-portal' ? 'active' : '')}
            >
              <span className="icon">
                <PeopleIcon />
              </span>
              <NavLink className="text" to="/subagent-portal" onClick={() => trackNavlick('Leads')} title={'Subagent'}>
                {'Subagent'}
              </NavLink>
            </div>
          )}
          {!isSubAgent() && (
            <div
              onClick={() => {
                changeNav('/leads');
              }}
              className={'list ' + (window.location.pathname === '/leads' ? 'active' : '')}
            >
              <span className="icon">
                <img src={UserDark} className="dark" alt="" />
                <img src={UserLight} className="light" alt="" />
              </span>
              <NavLink className="text" to="/leads" onClick={() => trackNavlick('Leads')} title={languageMap.leads}>
                {languageMap.leads}
              </NavLink>
            </div>
          )}

          {
            <div
              onClick={() => {
                changeNav('/policies');
              }}
              className={'list ' + (window.location.pathname === '/policies' ? 'active' : '')}
            >
              <span className="icon">
                <img src={GraphDark} className="dark" alt="" />
                <img src={GraphLight} className="light" alt="" />
              </span>
              <NavLink className="text" to="/policies" onClick={() => trackNavlick('Policies')} title={languageMap.pip}>
                {languageMap.pip}
              </NavLink>
            </div>
          }
          {isAdmin() ? (
            <div
              className={'list ' + (window.location.pathname === '/event-calendar' ? 'active' : '')}
              onClick={changeNav.bind(null, '/event-calendar')}
            >
              <span className="icon">
                <img src={CalendarIconDark} className="dark" alt="" style={{ height: '24px' }} />
                <img src={CalendarIconLight} className="light" alt="" style={{ height: '24px' }} />
              </span>
              <span className="text" title={languageMap.eventCalendar}>
                {languageMap.eventCalendar}
              </span>
            </div>
          ) : null}
          {(isAgent() || isSubAgent() || isMlmAgent()) && !isAdmin() ? (
            <div
              onClick={() => {
                changeNav('/compulsory-insurance-report');
              }}
              className={'list ' + (window.location.pathname === '/compulsory-insurance-report' ? 'active' : '')}
            >
              <span className="icon">
                <AssessmentIcon />
              </span>
              <NavLink
                className="text"
                to="/compulsory-insurance-report"
                onClick={() => trackNavlick('CompulsoryInsuranceReport')}
                title={languageMap.compulsoryInsurancereport}
              >
                {languageMap.compulsoryInsurancereport}
              </NavLink>
            </div>
          ) : null}
          {!isDealerOrLeadgen() && !isSubAgent() && (
            <div
              onClick={() => {
                changeNav('/affiliate-dashboard');
              }}
              className={'list ' + (window.location.pathname === '/affiliate-dashboard' ? 'active' : '')}
            >
              <span className="icon">
                <img src={ComissionDark} className="dark" alt="" />
                <img src={ComissionLight} className="light" alt="" />
              </span>
              <NavLink
                className="text"
                to="/affiliate-dashboard"
                onClick={() => trackNavlick('Dashboard')}
                title={languageMap.comissionPromotions}
              >
                {languageMap.comissionPromotions}
              </NavLink>
            </div>
          )}
          {isAdmin() ? <NavList roles={user.roles || []}></NavList> : null}
          {isAdmin() || isInspectionGarage() || isSubAgent() ? (
            <div
              className={'list ' + (window.location.pathname === '/historical-reports' ? 'active' : '')}
              onClick={changeNav.bind(null, '/historical-reports')}
            >
              <span className="icon">
                <AssessmentIcon />
              </span>
              <span className="text" title={languageMap.historicalReportRequest}>
                {languageMap.historicalReportRequest}
              </span>
            </div>
          ) : null}
          {isAdmin() ? (
            <div
              className={'list ' + (window.location.pathname === '/holidays' ? 'active' : '')}
              onClick={changeNav.bind(null, '/holidays')}
            >
              <span className="icon">
                <AssessmentIcon />
              </span>
              <span className="text" title={languageMap.holidays}>
                {languageMap.holidays}
              </span>
            </div>
          ) : null}
          {isAdmin() && (
            <div className={'list ' + (window.location.pathname === '/csv-upload' ? 'active' : '')}>
              <span className="icon">
                <AllInclusiveIcon />
              </span>
              <NavLink
                className="text"
                to="/csv-upload"
                onClick={() => trackNavlick('CSVUpload')}
                title={languageMap.csvUpload}
              >
                {languageMap.csvUpload || 'CSV Upload'}
              </NavLink>
            </div>
          )}
          {isAdmin() ? (
            <>
              <div
                className={'list ' + (window.location.pathname === '/printing-backlog' ? 'active' : '')}
                onClick={changeNav.bind(null, '/printing-backlog')}
              >
                <span className="icon">
                  <AssessmentIcon />
                </span>
                <span className="text" title={languageMap.printingBacklog}>
                  {languageMap.printingBacklog}
                </span>
              </div>
              <div
                className={'list ' + (window.location.pathname === '/chatwoot-conversation' ? 'active' : '')}
                onClick={changeNav.bind(null, '/chatwoot-conversation')}
              >
                <span className="icon">
                  <ChatOutlined />
                </span>
                <NavLink
                  className="text"
                  to="/chatwoot-conversation"
                  onClick={() => trackNavlick('ChatwootConversation')}
                  title={languageMap.csvUpload}
                >
                  {languageMap.chatwootConversations || 'Chatwoot Conversation'}
                </NavLink>
              </div>
              <div
                className={'list ' + (window.location.pathname === '/chatwoot-payments' ? 'active' : '')}
                onClick={changeNav.bind(null, '/chatwoot-payments')}
              >
                <span className="icon">
                  <ChatOutlined />
                </span>
                <NavLink
                  className="text"
                  to="/chatwoot-payments"
                  onClick={() => trackNavlick('ChatwootPayments')}
                  title={languageMap.csvUpload}
                >
                  {languageMap.chatwootPayments || 'Chatwoot Payments'}
                </NavLink>
              </div>
            </>
          ) : null}
        </div>
        {!isAdmin() && !isTelesales() && !isSubAgent() && !isMlmAgent() ? <Refer /> : null}
      </div>
    </div>
  );
}

export default Sidebar;
