import TextField from '@material-ui/core/TextField';
import { Button, Select, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import api from '../../commonjs/api';
import {
  COMPULSORY_MOTOR_PRODUCTS,
  NON_MOTOR_PRODUCTS,
  OTHERS,
  SUBAGENT_PRODUCT_CHOICES,
  VOLUNTARY_MOTOR_PRODUCTS,
} from '../../constants';
import { errorHandler, localCopy, notify, snakeToTitleCase, toFixedNumber, utcToThaiDate } from '../../utils/helper';

const { Option } = Select;

function SubagentCommissionForm({ close, action, affiliate, masterAgent, languageMap }) {
  if (affiliate.commission_rates && affiliate.commission_rates.length) {
    affiliate.commission_rates.map((d) => {
      d.errors = {};
      return d;
    });
  }
  const [commission_rates, setComissions] = useState(
      affiliate.commission_rates && affiliate.commission_rates.length ? localCopy(affiliate.commission_rates) : []
    ),
    langMap = languageMap.commissionsForm,
    [loading, setLoading] = useState(false),
    [insurerFilter, setInsurerFilter] = useState(null),
    [productFilter, setProductFilter] = useState(),
    today = moment().tz('asia/bangkok').format('YYYY-MM-DD');

  useEffect(() => {
    populateCommissionRates(affiliate);
  }, [affiliate]);

  function populateCommissionRates(affiliate) {
    if (affiliate && affiliate.commission_rates) {
      let commission_rates = localCopy(affiliate.commission_rates);
      commission_rates = commission_rates.map((data, index) => {
        const insuranceClass = data.insurance_class ? `_${data.insurance_class.name}` : '',
          vehicle_category = data.vehicle_category ? `_${data.vehicle_category.name}` : '',
          insurer_code = `_${data.insurer.code}`,
          uniqueKey = `${data.product}${insurer_code}${insuranceClass}${vehicle_category}`;
        data.hasChanged = false;
        data.errors = {};
        data.index = index;
        data.value = (data.value * 100).toFixed(2);
        data[uniqueKey] = data.value;
        return data;
      });
      setComissions(commission_rates);
    }
  }

  function getProductLabel(data) {
    if (!productFilter) {
      if (data?.insurance_class) {
        return langMap.vmiClass;
      }
      if (data?.vehicle_category) {
        return langMap.cmiCarCode;
      }
      return langMap.product;
    } else if (COMPULSORY_MOTOR_PRODUCTS.includes(productFilter)) {
      return langMap.cmiCarCode;
    } else if (VOLUNTARY_MOTOR_PRODUCTS.includes(productFilter)) {
      return langMap.vmiClass;
    } else if (productFilter === OTHERS) {
      return langMap.otherInsuranceProducts;
    }
  }

  function getProductValue(data) {
    if (!productFilter) {
      if (data.insurance_class) {
        return langMap[data.insurance_class.name];
      }
      if (data.vehicle_category) {
        return langMap[data.vehicle_category.name];
      }
    } else if (COMPULSORY_MOTOR_PRODUCTS.includes(productFilter)) {
      return data.vehicle_category?.name;
    } else if (VOLUNTARY_MOTOR_PRODUCTS.includes(productFilter)) {
      return data.insurance_class?.name ? langMap[data.insurance_class.name] : '';
    } else if (productFilter === OTHERS) {
      return data.product;
    }
    return '';
  }

  const validateCommission = (data) => {
    let errors = {};

    if (!data.commission_type) {
      errors.commission_type = 'Required Field';
    }
    if (!data.product) {
      errors.product = 'Required Field';
    }
    if (!data.value) {
      errors.value = 'Required Field';
    }
    if (data.value && !validator.isDecimal(data.value)) {
      errors.value = 'Invalid Value';
    }

    if (+data.value <= 0) {
      errors.value = 'Must be greater than 0';
    }

    const masterAffiliateCommission = masterAgent.commission_rates.filter(
      (cr) =>
        cr.product === data.product &&
        cr.commission_type === data.commission_type &&
        cr.insurance_class?.name === data.insurance_class?.name &&
        cr.vehicle_category?.name === data.vehicle_category?.name &&
        cr.insurer.name === data.insurer.name &&
        utcToThaiDate(cr.valid_from) <= today &&
        utcToThaiDate(cr.valid_till) >= today
    )[0];
    if (
      masterAffiliateCommission &&
      toFixedNumber(masterAffiliateCommission.value * 100, 3) < toFixedNumber(data.value, 3)
    ) {
      errors.value = `Max available ${toFixedNumber(masterAffiliateCommission.value * 100, 3)}`;
    }
    return errors;
  };

  const createAffiliateCommissionRequest = (params) => {
    return api.crm.postSubagentCommission(params);
  };

  const patchAffiliateCommissionRequest = (id, params) => {
    return api.crm.patchSubagentCommssison(id, params);
  };

  const createAffiliateCommission = () => {
    let promises = [],
      hasError = false;

    commission_rates.forEach((data, index) => {
      if (data.hasChanged) {
        let errors = validateCommission(data);
        let commission_ratesTemp = [...commission_rates];
        commission_ratesTemp[index].errors = errors;
        setComissions(commission_ratesTemp);
        if (Object.keys(errors).length) {
          hasError = true;
          return;
        }
        let params = {
          affiliate: affiliate.id,
          commission_type: data.commission_type,
          product: data.product,
          value: toFixedNumber(data.value / 100, 4),
          insurer: data.insurer.id,
          insurance_class_name: data.insurance_class?.name,
          vehicle_category_name: data.vehicle_category?.name,
        };

        if (params.value <= 0) {
          return;
        }

        if (data.id) {
          promises.push(patchAffiliateCommissionRequest(data.id, params));
          return;
        }
        promises.push(createAffiliateCommissionRequest(params));
      }
    });
    if (!promises.length && !hasError) {
      return;
    } else if (!promises.length) {
      let data = commission_rates[0];
      let errors = validateCommission(data);
      let commission_ratesTemp = [...commission_rates];
      commission_ratesTemp[0].errors = errors;
      setComissions(commission_ratesTemp);
      return;
    } else if (!promises.length) {
      return;
    }
    setLoading(true);
    Promise.all(promises)
      .then((response) => {
        let commission_ratesTemp = [...commission_rates];
        response.forEach((resp) => {
          commission_ratesTemp.forEach((data) => {
            data.hasChanged = false;
            if (!data.id && resp.product === data.product && resp.commission_type === data.commission_type) {
              data.id = resp.id;
            }
          });
        });
        setLoading(true);
        setComissions(commission_ratesTemp);
        notify('Updated Successfully', 'success');

        action && action();
        close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
        setLoading(false);
      });
  };

  const handleChange = (name, index) => (event) => {
    let commission_ratesTemp = [...commission_rates],
      value = event.target.value;
    commission_ratesTemp.forEach((commission) => {
      if (commission.index === index) {
        commission[name] = value;
        commission.hasChanged = true;
      }
    });
    setComissions(commission_ratesTemp);
  };

  const handleFilterChange = (name, value) => {
    if (name === 'product') {
      setProductFilter(value);
    } else if (name === 'insurer') {
      setInsurerFilter(value);
    }
  };

  return (
    <div className="affiliate-discount-form">
      <Space className="filter-wrapper" size="small">
        <Select
          onChange={(value) => handleFilterChange('insurer', value)}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder={langMap.insurerFilter}
          value={insurerFilter}
          allowClear={true}
        >
          {affiliate.available_insurers.map((obj) => {
            return (
              <Option key={obj.insurer} value={obj.insurer}>
                {obj.name}
              </Option>
            );
          })}
        </Select>
        <Select
          onChange={(value) => handleFilterChange('product', value)}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder={langMap.productFilter}
          value={productFilter}
        >
          {SUBAGENT_PRODUCT_CHOICES.map((product) => {
            return (
              <Option key={product} value={product}>
                {langMap[product]}
              </Option>
            );
          })}
        </Select>
      </Space>
      {commission_rates
        // for commission rates with validity period show them via ScheduleCommission Modal to avoid table bloating.
        .filter((commRates) => !insurerFilter || commRates.insurer.id.toString() === insurerFilter?.toString())
        .filter((commRate) => {
          // if productFilter not selected then don't apply any filter and show all products.
          if (!productFilter) {
            return true;
          }
          if (productFilter === OTHERS) {
            return NON_MOTOR_PRODUCTS.includes(commRate.product);
          }
          return commRate.product === productFilter;
        })
        .map((data, index) => {
          const isDefaultProduct = data.is_product_default;
          return (
            <div key={index} className={`${isDefaultProduct ? 'default' : ''}`}>
              {!productFilter ? (
                <TextField
                  required
                  label={getProductLabel()}
                  value={langMap[data.product] || ''}
                  error={Boolean(data.errors.product)}
                  helperText={data.errors.product}
                  autoComplete="off"
                  disabled
                />
              ) : null}
              <TextField
                required
                label={getProductLabel(data)}
                value={snakeToTitleCase(getProductValue(data))}
                error={Boolean(data.errors.product)}
                helperText={data.errors.product}
                autoComplete="off"
                disabled
              ></TextField>
              {!insurerFilter ? (
                <TextField
                  required
                  label={'insurer'}
                  value={data.insurer.code.toUpperCase()}
                  error={Boolean(data.errors.insurer)}
                  helperText={data.errors.insurer}
                  autoComplete="off"
                  disabled
                ></TextField>
              ) : null}
              <TextField
                required
                label={langMap.commission_type}
                value={langMap[data.commission_type] || ''}
                error={Boolean(data.errors.commission_type)}
                helperText={data.errors.commission_type}
                autoComplete="off"
                disabled={true} // not allowed for edit
                onChange={handleChange('commission_type', data.index)}
              />
              <TextField
                label={langMap.value}
                value={data.value}
                error={Boolean(data.errors.value)}
                helperText={data.errors.value}
                autoComplete="off"
                required
                onChange={handleChange('value', data.index)}
                inputProps={{ maxLength: 5 }}
              />
            </div>
          );
        })}
      <div className="button-wrap">
        <Button
          className={`orange-button medium-text with-right-margin ${loading ? 'disabled' : ''}`}
          onClick={createAffiliateCommission}
        >
          {langMap.apply}
        </Button>
        <Button className={`orange-bordered-button medium-text ${loading ? 'disabled' : ''}`} onClick={() => close()}>
          {langMap.cancel}
        </Button>
      </div>
    </div>
  );
}

export default SubagentCommissionForm;
