import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { logout } from '../actions/auth';
import { setLanguage, setLanguageMap } from '../actions/language';
import api from '../commonjs/api';
import { FAIRDEE_CONTACT_NUMBER, FAIRDEE_CONTACT_NUMBER_LABEL } from '../constants';
import { CLIENT_ID, CLIENT_SECRET } from '../constants/config';
import close from '../images/close.svg';
import menuIcon from '../images/hamburger.png';
import Calculator from '../images/icons/calculator.svg';
import Home from '../images/icons/home.svg';
import AboutUs from '../images/icons/info.svg';
import Faq from '../images/icons/messages.svg';
import HowItWorks from '../images/icons/settings.svg';
import logo from '../images/latest-landing/logo.svg';
import phoneDark from '../images/phone.svg';
import profileIcon from '../images/profile-icon.svg';
import { languageMap as LangMap } from '../language-map/mapping';
import '../scss/_landing-layout.scss';
import {
  detectPlatform,
  errorHandler,
  getAccessToken,
  getLocalData,
  isAdmin,
  isAgent,
  isMobileDevice,
  isSubAgent,
  isSuperAdmin,
  isTelesales,
  setLanguage as setLanguageLocally,
} from '../utils/helper';
import { Mixpanel } from '../utils/tracking';
import GlobalSearch from './global-search/GlobalSearch';
import NavList from './NavList';

function AuthenticatedHeader() {
  const languageMap = useSelector((state) => state.languageMap.components.latestLanding);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch(),
    [affiliate, setAffiliate] = useState({}),
    affiliateId = getLocalData('currentAffiliate'),
    user = useSelector((state) => state.user),
    [showActionSheet, setActionSheet] = useState(false),
    node = useRef(null),
    [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    getAffiliate(affiliateId);
  }, []);

  useEffect(() => {
    if (affiliate && affiliate.id) {
      let userObj = {
        email: affiliate.user.email,
        id: affiliate.user.id,
        agent_code: affiliate.agent_code,
      };
      window.trackEvent && window.trackEvent.userProperty(userObj);
    }
  }, [affiliate]);

  function toggleLanguage(language) {
    window.console.log(language);
    setLanguageLocally(language);
    dispatch(setLanguageMap(LangMap[language]));
    dispatch(setLanguage(language));
  }

  function toggleActionSheet() {
    setActionSheet(!showActionSheet);
    window.console.log(showActionSheet);
  }

  function getAffiliate(id = affiliateId) {
    if (!id) {
      return;
    }
    api.crm
      .affiliate(id, {})
      .then((data) => {
        setAffiliate(data.message);
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  }

  useEffect(() => {
    if (user.id) {
      window.trackEvent &&
        window.trackEvent.register({
          Name: user.fullname || '',
          Identity: user.id,
        });
    }
  }, [user]);

  useEffect(() => {
    if (affiliate.id && affiliate.user.id === user.id) {
      let userProperty = {
        email: affiliate.user.email,
        id: affiliate.user.id,
        agent_code: affiliate.agent_code,
        isAdmin: isAdmin() || isSuperAdmin() || '',
        accountType: affiliate.account_type || '',
      };

      window.trackEvent && window.trackEvent.userProperty(userProperty);
    }
  }, [affiliate]);

  const handleLogout = () => {
    let params = { client_id: CLIENT_ID, client_secret: CLIENT_SECRET, token: getAccessToken() };
    dispatch(logout(params));
    dispatch(setAffiliate({}));
    window.trackEvent && window.trackEvent.unRegister();
  };

  const deleteFCMToken = () => {
    Mixpanel.resetMixpanelRegister();
    if (isAdmin()) {
      const platform = detectPlatform(),
        app_context_map = {
          'ios-wv': 'agent_ios',
          'android-wv': 'agent_android',
        },
        app_context = app_context_map[platform] || 'agent_web';
      api.communicator.deleteFCMToken(user.id, { app_context });
    }
    handleLogout();
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function renderMobileHeader() {
    return (
      <div className="landing-header authenticated">
        <div className="content">
          <div className="logo-wrap">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
          </div>
          <div className="menu">
            {isAgent() && !isAdmin() ? (
              <Link to="/profile" className="profile">
                {affiliate.agent_code ? (
                  <label htmlFor="" className="label orange">
                    {' '}
                    {affiliate.agent_code}{' '}
                  </label>
                ) : (
                  <img src={profileIcon} alt="" />
                )}
              </Link>
            ) : (
              <Link to="/profile" className="profile">
                {user.fullname ? (
                  <label htmlFor="" className="label orange">
                    {' '}
                    {user.fullname}{' '}
                  </label>
                ) : (
                  <img src={profileIcon} alt="" />
                )}
              </Link>
            )}
            {isAdmin() ? <span className="admin-tag">ADMIN</span> : null}
            <img src={menuIcon} alt="" onClick={toggleActionSheet} />
          </div>
        </div>
        <div className={'action-sheet navigation ' + (showActionSheet ? 'show' : '')} onClick={toggleActionSheet}>
          <div className="content" ref={node} onClick={(e) => e.stopPropagation()}>
            <div className="img-wrap">
              <img src={close} alt="" onClick={toggleActionSheet} />
            </div>
            {isAdmin() ? <NavList roles={['admin']} toggleNav={toggleActionSheet} /> : null}
            <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.homeLink}>
              <span className="icon">
                <img src={Home} alt="" />
              </span>
              {languageMap.header.home}
            </NavLink>
            <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.roadTaxLink}>
              <span className="icon">
                <img src={Calculator} alt="" />
              </span>
              {languageMap.header.roadTax}
            </NavLink>
            <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.faqLink}>
              <span className="icon">
                <img src={Faq} alt="" />
              </span>
              {languageMap.header.faq}
            </NavLink>
            {!isTelesales() && !isSubAgent() ? (
              <>
                <NavLink
                  activeClassName="active"
                  exact
                  onClick={toggleActionSheet}
                  to={languageMap.header.howItWorksLink}
                >
                  <span className="icon">
                    <img src={HowItWorks} alt="" />
                  </span>
                  {languageMap.header.howItWorks}
                </NavLink>
              </>
            ) : null}
            <NavLink activeClassName="active" exact onClick={toggleActionSheet} to={languageMap.header.aboutUsLink}>
              <span className="icon">
                <img src={AboutUs} alt="" />
              </span>
              {languageMap.header.aboutUs}
            </NavLink>

            <div className="bottom-section">
              <p className="text">Language</p>
              <div className="language-toggle">
                <span className={language === 'thai' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'thai')}>
                  ไทย
                </span>
                <span className={language === 'english' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'english')}>
                  EN
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDesktopView() {
    return (
      <div className="landing-header authenticated">
        <div className="content">
          <div className="logo-wrap">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
            <a href={`tel:${FAIRDEE_CONTACT_NUMBER}`} className="phone">
              <img src={phoneDark} alt="" />
              {FAIRDEE_CONTACT_NUMBER_LABEL}{' '}
            </a>
          </div>
          <GlobalSearch />
          <div className="menu unset-flex-grow">
            {isAgent() && !isAdmin() ? (
              <>
                <a className="user-name" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  {affiliate.agent_code ? affiliate.agent_code : affiliate.user?.fullname}
                  <ExpandMoreIcon />
                </a>
                <Menu
                  className="dd-menu"
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <NavLink to={languageMap.header.profileLink}>{languageMap.header.profile}</NavLink>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      deleteFCMToken();
                    }}
                  >
                    {languageMap.header.logout}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <a className="user-name" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  {affiliate.agent_code ? affiliate.agent_code : affiliate.user?.fullname}
                  <ExpandMoreIcon />
                </a>
                <Menu
                  className="dd-menu"
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <NavLink to={languageMap.header.profileLink}>{languageMap.header.profile}</NavLink>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      deleteFCMToken();
                    }}
                  >
                    {languageMap.header.logout}
                  </MenuItem>
                </Menu>
              </>
            )}
            {isAdmin() ? <span className="admin-tag">ADMIN</span> : null}
            <div className="language-toggle">
              <span className={language === 'thai' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'thai')}>
                ไทย
              </span>
              <span className={language === 'english' ? 'active' : ''} onClick={toggleLanguage.bind(null, 'english')}>
                EN
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isMobileDevice()) {
    return renderMobileHeader();
  }

  return renderDesktopView();
}

export default AuthenticatedHeader;
