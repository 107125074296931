import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FAIRDEE_FB_URL } from '../constants';
import lineIcon from '../images/line.svg';
import iconFour from '../images/onboarding/icon-eight.png';
import { getLineUrl, isMobileDevice } from '../utils/helper';

class NewFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  goToHowItWorks = () => {
    this.props.history.push('/how-it-works');
  };

  goToAboutUs = () => {
    this.props.history.push('/about-us');
  };

  goToPrivacyPolicy = () => {
    this.props.history.push('/privacy-policy');
  };

  goToTermsOfUse = () => {
    this.props.history.push('/terms-of-use');
  };

  goToFaq = () => {
    this.props.history.push('/faq');
  };

  gotoPage = (page) => {
    this.props.history.push(page);
  };

  renderDesktopFooter = () => {
    const languageMap = this.props.languageMap.components.newLanding.footer,
      affilaite = this.props.affilaite || undefined;

    return (
      <div className="static-footer">
        <div className="wrapper">
          <div className="find-more-section">
            <div className="find-more-header">{languageMap.findOutMore}</div>
            {affilaite && affilaite.account_type !== 'telesales' ? (
              <div className="find-more-links" onClick={() => this.gotoPage(languageMap.howItWorksLink)}>
                {languageMap.howItWorks}
              </div>
            ) : null}

            <div className="find-more-links" onClick={() => this.gotoPage(languageMap.aboutUsLink)}>
              {languageMap.aboutUs}
            </div>
            <div className="find-more-links" onClick={this.goToPrivacyPolicy}>
              {languageMap.privacyPolicy}
            </div>
            <div className="find-more-links" onClick={this.goToTermsOfUse}>
              {languageMap.termsOfUse}
            </div>
            <div className="find-more-links" onClick={() => this.gotoPage(languageMap.faqLink)}>
              {languageMap.faq}
            </div>
          </div>
          <div>
            <a href={FAIRDEE_FB_URL} target="_blank" rel="noopener noreferrer">
              <img src={iconFour} alt="" />
            </a>
            <div className="licence-info">ใบอนุญาตนายหน้า ว.00012/2562</div>
            <div className="fairdee-copyright">
              <p className="text-one">
                <span>&copy;</span>
                {languageMap.textOne}
              </p>
              <p className="text-two">{languageMap.textTwo}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMobileFooter = () => {
    const languageMap = this.props.languageMap.components.newLanding.footer,
      affilaite = this.props.affilaite || undefined;

    return (
      <div className="static-footer">
        <div className="wrapper">
          <div className="licence-info">ใบอนุญาตนายหน้า ว.00012/2562</div>
          <div className="find-more-section">
            <div className="find-more-header">{languageMap.findOutMore}</div>
            {affilaite && affilaite.account_type !== 'telesales' ? (
              <div className="find-more-links" onClick={this.goToHowItWorks}>
                {languageMap.howItWorks}
              </div>
            ) : null}
            <div className="find-more-links" onClick={this.goToAboutUs}>
              {languageMap.aboutUs}
            </div>
            <div className="find-more-links" onClick={this.goToPrivacyPolicy}>
              {languageMap.privacyPolicy}
            </div>
            <div className="find-more-links" onClick={this.goToTermsOfUse}>
              {languageMap.termsOfUse}
            </div>
            <div className="find-more-links" onClick={this.goToFaq}>
              {languageMap.faq}
            </div>
          </div>
          <div>
            <div className="connect-icon-holder">
              <a href={FAIRDEE_FB_URL} target="_blank" rel="noopener noreferrer">
                <img src={iconFour} alt="" />
              </a>
              <a href={getLineUrl()} target="_blank">
                <img src={lineIcon} alt="" />
              </a>
            </div>
            <div className="fairdee-copyright">
              <p className="text-one">
                <span>&copy;</span>
                {languageMap.textOne}
              </p>
              <p className="text-two">{languageMap.textTwo}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <>{isMobileDevice() ? this.renderMobileFooter() : this.renderDesktopFooter()}</>;
  }
}

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, {})(NewFooter));
