import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import WelcomeBanner from '../../../images/fairdee-x-plus/auth/pin-set-success.svg';
import CloseIcon from '../../../images/fairdee-x-plus/common/close.svg';
import { RootState } from '../../../index';

interface Props {
  close?: () => void;
}

const SetPhoneSuccess: React.FC<Props> = ({ close }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);

  return (
    <div className="mobile-layout login-register welcome-modal">
      <div className="content-wrapper">
        <img src={CloseIcon} alt="close" onClick={close} className="close" />
        <div className="img-wrap">
          <img src={WelcomeBanner} alt="welcome-banner" />
        </div>
        <p className="title-text">{languageMap[`You have successfuly verified the mobile number`]}</p>
        <p className="text">{languageMap[`Use this mobile number whenever you login`]} </p>
        <Button className="orange-button" onClick={close}>
          {languageMap[`Got it!`]}
        </Button>
      </div>
    </div>
  );
};

export default SetPhoneSuccess;
