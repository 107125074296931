import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InfoModal from '../../components/fairdee-x-plus/common/InfoModal';
import { MOBILE_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler, isAdmin } from '../../utils/helper';

function AffiliateCodeGenerateModal(props) {
  const languageMap = useSelector((state) => state.languageMap),
    leadgenModalLang = languageMap.components.leadGen.modal,
    fairdeeXplusLang = languageMap.fairdeeXplus,
    [nestedAffiliates, setNestedAffiliates] = useState(''),
    [affiliate, setAffiliate] = useState({});

  useEffect(() => {
    loadNestedAffiliates();
  }, [props]);

  function loadNestedAffiliates() {
    api.user.getNestedAffiliates({}, props.affiliate_id).then((res) =>
      setNestedAffiliates(
        res.results.map((affiliate) => ({
          text: `${affiliate.agent_code}-${affiliate.fullname}`,
          value: affiliate.id,
          name: affiliate.name,
        }))
      )
    );
  }

  function close() {
    props.hideModal(null, { ...props });
  }

  function openBlockInfoModal() {
    props.showModal(MOBILE_MODAL, {
      component: (
        <InfoModal
          title={fairdeeXplusLang[`Unable to Proceed`]}
          text={
            fairdeeXplusLang[
              `This agent is affiliated with account FD-XXXXX which has been temporarily blocked. Please do not proceed with any further actions. You will be penalised for doing so.`
            ]
          }
        />
      ),
      uid: 'INFO_MODAL',
      className: 'full-screen',
    });
  }

  function action() {
    if (affiliate.is_blocked) {
      openBlockInfoModal();
      return;
    }
    let promise = '';
    if (isAdmin()) {
      promise = api.user.postLeadGenCode(
        { affiliate_id: affiliate.value, num_codes_to_generate: 10 },
        props.affiliate_id
      );
    } else {
      promise = api.user.postLeadGenCode({ affiliate_id: affiliate.value, num_codes_to_generate: 10 });
    }

    promise
      .then(() => {
        notify(languageMap.messages.updatedSuccessfully, 'success');
        props.action && props.action();
        close();
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">Choose Affiliate</span>
          </p>
        </div>
        <h1 className="title-text">Choose Affiliate</h1>
        <div className="qr-details">
          <div className="form-wrapper">
            <div className="form-field">
              <label htmlFor="lg_account">{leadgenModalLang.leadGenAccount}</label>
              <Dropdown
                fluid
                search
                selection
                options={nestedAffiliates}
                onChange={(e, data) => setAffiliate(data)}
                value={affiliate.value}
                name="lg_account"
              />
            </div>
            <div className="button-wrap">
              <button className="button medium orange-bordered-button" onClick={close}>
                {leadgenModalLang.close}
              </button>
              <button
                className={`button medium ${!affiliate ? ' disabled' : ''}`}
                onClick={action}
                disabled={!affiliate.value}
              >
                {leadgenModalLang.continue}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showModal, showNotification })(AffiliateCodeGenerateModal);
