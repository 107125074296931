import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Form, FormField, Label, Radio } from 'semantic-ui-react';
import apiEndpoints from '../../commonjs/apiEndpoints';
import {
  ENDORSEMENT_INSURER_PAYMENT_STATUSES,
  ENDORSEMENT_PAYMENT_STATUSES,
  ENDORSEMENT_STATUSES,
} from '../../constants';
import { isSuperAdmin } from '../../utils/helper';
import FileUpload from '../FileUpload';
import InlineError from '../InlineError';

function AdditionalDetails(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const endorsementLangMap = languageMap.components.endorsement;
  const modalLangMap = endorsementLangMap.endorsementModal;
  const statusLangMap = languageMap.statusMappingEndorsements;
  const BANKS = languageMap.banks;

  const endorsementStatusMap = ENDORSEMENT_STATUSES.map((status) => ({
    text: statusLangMap[status],
    value: status,
  }));

  const bankNamesMap = Object.keys(BANKS).map((bank) => ({
    text: BANKS[bank],
    value: bank,
  }));

  const paymentStatusMap = ENDORSEMENT_PAYMENT_STATUSES.map((status) => ({
    text: statusLangMap[status],
    value: status,
  }));

  const insurerStatusMap = ENDORSEMENT_INSURER_PAYMENT_STATUSES.map((status) => ({
    text: statusLangMap[status],
    value: status,
  }));

  function shouldEnableField() {
    return !props.data.settle_with_affiliate;
  }

  function gotoWizard() {
    let search = '?id=' + props.data.fairdeesale.quotation.id + '&step=6',
      pathname = '/fairdee-wizard';
    const link = document.createElement('a');
    if (props.data.fairdeesale.quotation.quotation_query.for_motorcycle) {
      search += '&forBike=true';
    }

    link.setAttribute('target', '_blank');
    link.href = pathname + search;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function isApproveDisabled() {
    const data = props.data;
    if (data.type_of_endorsement === 'premium_unaffected') {
      return data.is_approved;
    }

    return !data.bank_detail || !data.bank_detail.id || data.is_approved;
  }

  return (
    <Form className="no-frills-form">
      {isSuperAdmin() && (
        <>
          <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
            {modalLangMap.approve}
          </Label>
          <Form.Group widths={2} unstackable>
            <Form.Field disabled={isApproveDisabled()}>
              <label>Approve Endorsement</label>
              <div className="button primary medium" onClick={props.approveEndorsement}>
                Approve Endorsement
              </div>
            </Form.Field>
            <Form.Field>
              <label>Go To Wizard</label>
              <div className="button primary medium" onClick={gotoWizard}>
                Go To Wizard
              </div>
            </Form.Field>
          </Form.Group>
        </>
      )}
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.refundType}
      </Label>
      <Form.Field>
        <Radio
          label={modalLangMap.fairdeeToCbc}
          name="radioGroup"
          value="fairdee_to_cbc"
          checked={props.data?.refund_type === 'fairdee_to_cbc'}
          onChange={(e, data) => props.onRadioChange(e, data, 'refund_type')}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label={modalLangMap.insurerToCbc}
          name="radioGroup"
          value="insurer_to_cbc"
          checked={props.data?.refund_type === 'insurer_to_cbc'}
          onChange={(e, data) => props.onRadioChange(e, data, 'refund_type')}
        />
      </Form.Field>

      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.shortRate}
      </Label>
      <Form.Group widths={2} unstackable>
        <Form.Field>
          <Checkbox
            label={modalLangMap.applyShortRate}
            name="is_short_rate_applicable"
            checked={Boolean(props.data.is_short_rate_applicable)}
            onChange={(e, data) => props.onCheckboxChange(e, data, 'is_short_rate_applicable')}
            disabled={
              props.data.is_approved ||
              props.data.endorsement_date <= props.data.fairdeesale.quotation.policy_start_date
            }
          />
        </Form.Field>
        {Boolean(props.data.endorsement_date <= props.data.fairdeesale.quotation.policy_start_date) ? (
          <FormField>
            <label>
              {' '}
              *{modalLangMap['Short Rate is not applicable if endorsement date is on/before policy start date']}
            </label>
          </FormField>
        ) : null}
      </Form.Group>

      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.bankDetails}
      </Label>
      <Form.Group widths={2} unstackable>
        <Form.Field>
          <Checkbox
            label={modalLangMap.copyFromAffiliate}
            name="copy_from_affiliate"
            checked={Boolean(props.data.settle_with_affiliate)}
            onChange={(e, data) => props.onCheckboxChange(e, data, 'settle_with_affiliate')}
            disabled={
              props.blockDataUpdate ||
              !props.affiliate ||
              !props.affiliate.bank_details ||
              !props.affiliate.bank_details.length
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={2} unstackable>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="ac_holder_name">{modalLangMap.accountHolderName}*</label>
          <input
            type="text"
            id="ac_holder_name"
            name="ac_holder_name"
            disabled={!shouldEnableField()}
            placeholder={modalLangMap.accountHolderName}
            onChange={props.handleChange.bind(null, 'ac_holder_name')}
            value={props.data?.bank_detail?.ac_holder_name}
          />
          {props.errors.ac_holder_name && <InlineError text={props.errors.ac_holder_name} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="ac_number">{modalLangMap.accountNumber}*</label>
          <input
            type="text"
            id="ac_number"
            name="ac_number"
            disabled={!shouldEnableField()}
            placeholder={modalLangMap.accountNumber}
            onChange={props.handleChange.bind(null, 'ac_number')}
            value={props.data?.bank_detail?.ac_number}
          />
          {props.errors.ac_number && <InlineError text={props.errors.ac_number} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="bank_name">{modalLangMap.bankName}*</label>
          <Dropdown
            placeholder={modalLangMap.bankName}
            options={bankNamesMap}
            value={props.data?.bank_detail?.bank_name}
            onChange={(e, data) => props.onDropdownChange(e, data, 'bank_name')}
            search
            selection
          />
          {props.errors.bank_name && <InlineError text={props.errors.bank_name} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="branch_code">{modalLangMap.branchCode}*</label>
          <input
            type="number"
            id="branch_code"
            name="branch_code"
            disabled={!shouldEnableField()}
            placeholder={modalLangMap.branchCode}
            onChange={props.handleChange.bind(null, 'branch_code')}
            value={props.data?.bank_detail?.branch_code}
          />
          {props.errors.branch_code && <InlineError text={props.errors.branch_code} />}
        </Form.Field>
      </Form.Group>
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.documents}
      </Label>
      <Form.Group widths={2} unstackable>
        <Form.Field>
          <label>{modalLangMap.endorsementDocument}</label>
          <FileUpload
            download={true}
            files={props.fileList['endorsement_document']}
            loading={props.fileListLoading['endorsement_document']}
            name="endorsement_document"
            onFileUpload={props.fileUploaded}
            onFileRemove={props.deleteDocument}
            downloadPath={apiEndpoints.endorsementDocument}
            limit={5}
            multiple={true}
            hideUpload={props.blockUpdate}
            disableDelete={props.blockDataUpdate}
            accept="image/jpeg,image/png,application/pdf"
            disabled={props.blockDataUpdate}
          />
          {props.errors.endorsement_document && <InlineError text={props.errors.endorsement_document} />}
        </Form.Field>
        <Form.Field>
          <label>{`${modalLangMap.customerID}${!props.data.settle_with_affiliate ? '*' : ''}`}</label>
          <FileUpload
            download={true}
            files={props.fileList['customer_id']}
            loading={props.fileListLoading['customer_id']}
            name="customer_id"
            onFileUpload={props.fileUploaded}
            onFileRemove={props.deleteDocument}
            downloadPath={apiEndpoints.endorsementDocument}
            limit={3}
            multiple={true}
            hideUpload={props.blockUpdate}
            disableDelete={props.blockDataUpdate}
            accept="image/jpeg,image/png,application/pdf"
            disabled={props.blockDataUpdate}
          />
          {props.errors.customer_id && <InlineError text={props.errors.customer_id} />}
        </Form.Field>
        <Form.Field>
          <label>{`${modalLangMap.policy}${!props.data.settle_with_affiliate ? '*' : ''}`}</label>
          <FileUpload
            download={true}
            files={props.fileList['policy']}
            loading={props.fileListLoading['policy']}
            name="policy"
            onFileUpload={props.fileUploaded}
            onFileRemove={props.deleteDocument}
            downloadPath={apiEndpoints.endorsementDocument}
            limit={5}
            multiple={true}
            hideUpload={props.blockUpdate}
            disableDelete={props.blockDataUpdate}
            accept="image/jpeg,image/png,application/pdf"
            disabled={props.blockDataUpdate}
          />
          {props.errors.policy && <InlineError text={props.errors.policy} />}
        </Form.Field>
        <Form.Field>
          <label>{`${modalLangMap.passbook}`}</label>
          <FileUpload
            download={true}
            files={props.fileList['passbook']}
            loading={props.fileListLoading['passbook']}
            name="passbook"
            onFileUpload={props.fileUploaded}
            onFileRemove={props.deleteDocument}
            downloadPath={apiEndpoints.endorsementDocument}
            limit={2}
            multiple={true}
            hideUpload={props.blockUpdate}
            disableDelete={props.blockDataUpdate}
            accept="image/jpeg,image/png,application/pdf"
            disabled={props.blockDataUpdate}
          />
          {props.errors.passbook && <InlineError text={props.errors.passbook} />}
        </Form.Field>
      </Form.Group>
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.endorsementStatuses}
      </Label>
      {
        <Form.Group widths={2} unstackable>
          <Form.Field disabled={props.blockDataUpdate}>
            <label htmlFor="endorsement_status">{modalLangMap.endorsementStatus}</label>
            <Dropdown
              placeholder={endorsementLangMap['Select Endorsement Status']}
              options={endorsementStatusMap}
              value={props.data.endorsement_status}
              onChange={(e, data) => props.onDropdownChange(e, data, 'endorsement_status')}
              search
              selection
            />
          </Form.Field>
          <Form.Field disabled={props.blockDataUpdate}>
            <label htmlFor="payment_status">{modalLangMap.endorsementPaymentStatus}</label>
            <Dropdown
              placeholder={endorsementLangMap['Select Payment Status']}
              options={paymentStatusMap}
              value={props.data.payment_status}
              onChange={(e, data) => props.onDropdownChange(e, data, 'payment_status')}
              search
              selection
            />
          </Form.Field>
          <Form.Field disabled={props.blockDataUpdate}>
            <label htmlFor="insurer_payment_status">{modalLangMap.endorsementInsurerPaymentStatus}</label>
            <Dropdown
              placeholder={endorsementLangMap['Select Insurer Payment Status']}
              options={insurerStatusMap}
              value={props.data.insurer_payment_status}
              onChange={(e, data) => props.onDropdownChange(e, data, 'insurer_payment_status')}
              search
              selection
            />
          </Form.Field>
        </Form.Group>
      }
    </Form>
  );
}

export default AdditionalDetails;
