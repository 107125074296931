import MomentUtils from '@date-io/moment';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Button, Space } from 'antd';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import validator from 'validator';
import { setAffiliate } from '../actions/affiliate';
import { register } from '../actions/auth';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import { CLIENT_ID } from '../constants/config';
import { default as PROVINCES } from '../constants/provinces.json';
import { NOTIFICATION } from '../constants/types';
import { store } from '../index';
import {
  errorHandler,
  getDistrictOptions,
  getSubDistrictOptions,
  thaiToDefaultDate,
  toThaiDate,
  validateThaiNationalId,
} from '../utils/helper';
import InlineError from './InlineError';

const MOMENT = moment().locale('th').add(543, 'years');
const MANDATORY_FIELDS = ['email', 'fullname', 'phone', 'national_id', 'credits'];
const NUMERIC_FIELDS = ['commission_rate', 'credits', 'phone', 'postal_code'];
class SubAgentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { address: {}, additional_info: {}, user: {}, address_nid: {} },
      loading: false,
      openModal: false,
      districts: [],
      sub_districts: [],
      districts_nid: [],
      sub_districts_nid: [],
      editable: false,
      errors: {},
      fileList: {},
      fileListLoading: {},
      updatedData: {},
      updatedBankData: {},
      bankDetails: {},
      imagePreviewUrl: (props.user && props.user.profile_pic) || null,
      file: null,
      masterApiLoading: false,
      affiliate: props.affiliate || {},
    };

    PROVINCES.map((data) => {
      data.key = data.provincecode;
      data.text = data.provincename;
      data.value = data.provincename;

      return data;
    });
  }

  componentDidMount() {
    let data = { ...this.state.data };
    if (this.props.affiliate) {
      data = JSON.parse(JSON.stringify(this.props.affiliate));
    }
    if (data.user.dob) {
      data.user.dob = toThaiDate(data.user.dob);
    }

    if (
      this.props.affiliate?.credit &&
      this.props.affiliate.credit.find((credit) => credit.product === 'combined_credit')
    ) {
      data.credits = this.props.affiliate.credit.find((credit) => credit.product === 'combined_credit').limit;
    }

    if (!data.address) {
      data.address = {};
    }
    if (!data.address_nid) {
      data.address_nid = {};
    }
    this.setState({
      data,
    });
    this.populateDropDowns(data);
    this.populateDropDowns(data, 'address_nid');
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  populateDropDowns = async (affiliate, addressKey = 'address') => {
    if (!affiliate[addressKey]) {
      return;
    }
    let districtsKey = 'districts';
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    if (affiliate[addressKey].subdistrict) {
      let provinceName = affiliate[addressKey].province,
        districtName = affiliate[addressKey].district,
        province = PROVINCES.find((province) => province.provincename === provinceName);

      let districts = [],
        subDistricts = [];

      if (province) {
        // Populate list of districts if province selected
        districts = await getDistrictOptions(province.provincecode);
      }
      if (districtName) {
        // Populate list of sub-districts if district selected
        let district = districts.find((district) => district.districtname === districtName);
        if (district) {
          subDistricts = await getSubDistrictOptions(district.districtcode);
        }
      }
      this.setState({
        [districtsKey]: districts,
        [subDistrictsKey]: subDistricts,
      });
    }
  };

  validate = (data) => {
    let errors = {};
    let messages = this.props.languageMap.messages;

    MANDATORY_FIELDS.forEach((field) => {
      if (!data[field] && (!data.user || !data.user[field])) {
        errors[field] = messages.required;
      }
    });

    if (data.national_id && !validateThaiNationalId(data.national_id)) {
      errors.national_id = messages.invalidFormat;
    }
    if (!data.user || !validator.isEmail(data.user.email)) {
      errors.email = messages.invalidFormat;
    }

    if (this.state.updatedData.credits) {
      let creditsToCheck = data.credits,
        masterCredits = this.props.masterAgent.credit.find((credit) => credit.product === 'combined_credit');
      if (this.props.affiliate?.id) {
        let affiliateCompulsoryCredits = this.props.affiliate.credit.find(
          (credit) => credit.product === 'combined_credit'
        );
        if (affiliateCompulsoryCredits) {
          creditsToCheck = +data.credits - +affiliateCompulsoryCredits.limit;
        }
      }
      if (
        !masterCredits ||
        +creditsToCheck > +masterCredits.available_to_disburse ||
        +creditsToCheck > +masterCredits.available
      ) {
        errors.credits = `Max available ${
          Math.min(+masterCredits.available_to_disburse, +masterCredits.available) || 0
        }`;
      }
    }

    if (
      data.address &&
      data.address.postal_code &&
      (isNaN(data.address.postal_code) || data.address.postal_code.length !== 5)
    ) {
      errors.postal_code = messages.invalidFormat;
    }
    return errors;
  };

  handleChange = (name, addressKey) => (e) => {
    let { data, districts, sub_districts, districts_nid, sub_districts_nid } = this.state;
    let updatedData = this.state.updatedData;
    let value = null;
    if (moment.isMoment(e)) {
      value = e;
    } else {
      value = e.target.value;
    }
    if (NUMERIC_FIELDS.includes(name) && isNaN(value)) {
      return;
    }
    if (name === 'fullname' || name === 'dob' || name === 'gender' || name === 'phone' || name === 'email') {
      data.user[name] = value;
      if (!updatedData.user) {
        updatedData.user = {};
      }
      updatedData.user[name] = value;
    } else if (name === 'first_line' || name === 'postal_code') {
      data[addressKey][name] = value;
      if (!updatedData[addressKey]) {
        updatedData[addressKey] = {};
      }
      updatedData[addressKey][name] = value;
    } else if (name === 'has_broker_license') {
      value = value === 'true' ? true : false;
      data.additional_info[name] = value;
      data[name] = value;
      if (!updatedData.additional_info) {
        updatedData.additional_info = {};
      }
      updatedData.additional_info[name] = value;
      updatedData[name] = value;
    } else if (name === 'copy_address') {
      data.copy_address = e.target.checked;
      if (e.target.checked) {
        let copyAddress = (({ first_line, subdistrict, district, province, postal_code }) => ({
          first_line,
          subdistrict,
          district,
          province,
          postal_code,
        }))(data.address);
        if (updatedData.address) {
          copyAddress = (({ first_line, subdistrict, district, province, postal_code }) => ({
            first_line,
            subdistrict,
            district,
            province,
            postal_code,
          }))(updatedData.address);
        }

        data.address_nid = copyAddress;
        updatedData.address_nid = copyAddress;
        districts_nid = districts;
        sub_districts_nid = sub_districts;
      } else {
        data.address_nid = {};
        updatedData.address_nid = {};
        districts_nid = [];
        sub_districts_nid = [];
      }
    } else {
      data[name] = value;
      updatedData[name] = value;
    }

    this.setState(
      {
        data,
        updatedData,
        districts_nid,
        sub_districts_nid,
      },
      () => {}
    );
  };

  handleProvinceChange = async (e, data, addressKey = 'address') => {
    let updatedValues = {
      district: null,
      subdistrict: null,
      province: data.value,
    };
    let districtsKey = 'districts';
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    let provinceCode = PROVINCES.find((province) => province.provincename === data.value).provincecode;
    let districts = await getDistrictOptions(provinceCode);

    let stateData = this.state.data;
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [districtsKey]: districts,
      [subDistrictsKey]: [],
      data: stateData,
      updatedData,
    }));
  };

  handleDistrictChange = async (e, data, addressKey = 'address') => {
    let updatedValues = {
      district: data.value,
      subdistrict: null,
    };
    let subDistrictsKey = 'sub_districts';
    let districtsKey = 'districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }
    let districts = this.state[districtsKey];
    let districtCode = districts.find((district) => district.districtname === data.value).districtcode;
    let subDistricts = await getSubDistrictOptions(districtCode);
    let stateData = this.state.data;

    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [subDistrictsKey]: subDistricts,
      data: stateData,
      updatedData,
    }));
  };

  handleSubDistrictChange = (e, data, addressKey = 'address') => {
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      subDistrictsKey = 'sub_districts_nid';
    }
    let sub_districts = this.state[subDistrictsKey];

    let updatedValues = {
      subdistrict: data.value,
      postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === data.value).zip,
    };
    let stateData = this.state.data;
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedData = this.state.updatedData;
    if (!updatedData[addressKey]) {
      updatedData[addressKey] = updatedValues;
    } else {
      updatedData[addressKey] = { ...updatedData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      data: stateData,
      updatedData,
    }));
  };

  updateProfilePic = () => {
    let FD = new FormData();
    FD.append('image', this.state.file);
    this.setState({ loading: true });
    api.user
      .profilePic(FD)
      .then((resp) => {
        this.setState({
          userProfile: { ...this.state.userProfile, profile_pic: resp.profile_pic_url },
          loading: false,
          file: null,
        });
        this.notify('Profile picture successfully updated', 'success');
      })
      .catch((error) => {
        errorHandler(error.response, true);
        this.setState({ loading: false });
      });
  };

  handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    this.setState({
      errors: { ...this.state.errors },
    });
    if (file.size / 1024 / 1024 > 2) {
      this.setState({
        errors: { ...this.state.errors, fileSize: 'File size cannot be bigger than 2MB' },
      });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  createSubagent = (params) => {
    params.has_broker_license = true;
    let messages = this.props.languageMap.messages;
    let affiliate = this.state.affiliate;
    const REGISTER_PARAMS = [
      'email',
      'fullname',
      'phone',
      'tags',
      'referral_code',
      'referrer',
      'has_broker_license',
      'broker_license_number',
      'national_id',
    ];
    const postParams = REGISTER_PARAMS.reduce((returnObj, key) => {
      if (params[key] !== undefined) {
        returnObj[key] = params[key];
        delete params[key];
      }
      return returnObj;
    }, {});
    postParams.return_affiliate = true;
    postParams.client_id = CLIENT_ID;
    postParams.master_affiliate_id = this.props.masterAgent.id;
    postParams.account_type = 'subagent';
    this.props
      .register(postParams)
      .then((resp) => {
        affiliate = resp;
        const queryParams = {
          with_commission_rates: true,
          for_subagent: true,
        };
        api.crm.patchAffiliate(affiliate.id, params, queryParams).then((res) => {
          affiliate = res.data;

          api.crm
            .postSubagentCredits({
              affiliate: affiliate.id,
              product: 'combined_credit',
              limit: parseFloat(this.state.data.credits),
              available: parseFloat(this.state.data.credits),
            })
            .then((response) => {
              affiliate.credit.push(response);
              this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
              this.setState({
                affiliate,
                updatedData: {},
                masterApiLoading: false,
              });
              if (this.props.updateAffiliate) {
                this.props.updateAffiliate(affiliate);
              }
              if (this.props.action) {
                this.props.action();
              }
              this.props.next();
            })
            .catch((err) => {
              this.setState({
                masterApiLoading: false,
              });
              errorHandler(err.response, true);
            });
        });
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.email
        ) {
          this.setState({
            errors: { ...this.state.errors, email: messages.emailAlreadyExists },
            masterApiLoading: false,
          });
        } else {
          this.setState({
            masterApiLoading: false,
          });
          errorHandler(err.response, true);
        }
      });
  };

  submit = () => {
    let data = { ...this.state.updatedData },
      id = this.props.affiliate?.id;

    let validationData = {
      ...this.state.data,
      ...this.state.updatedData,
      user: { ...this.state.data.user, ...this.state.updatedData.user },
    };
    let errors = this.validate(validationData);
    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    if (!Object.keys(data).length) {
      return;
    }

    this.setState({
      masterApiLoading: true,
    });

    if (data.user && data.user.dob) {
      data.user.dob = thaiToDefaultDate(data.user.dob);
    }
    let params = {};
    if (data.user) {
      params = { ...params, ...data.user };
    }
    if (data.national_id) {
      params.national_id = data.national_id;
    }
    if (data.address) {
      params.address = data.address;
    }
    if (data.address_nid) {
      params.address_nid = data.address_nid;
    }
    if (data.additional_info) {
      params = { ...params, ...data.additional_info };
    }
    if (!this.state.affiliate.id) {
      this.createSubagent(params);
    } else {
      let promises = [];
      promises.push(api.crm.patchAffiliate(id, params));
      if (this.state.updatedData.credits) {
        const credit = this.props.affiliate.credit.find((credit) => credit.product === 'combined_credit');
        if (credit) {
          promises.push(
            api.crm
              .patchSubagentCredits(credit.id, {
                affiliate: id,
                product: 'combined_credit',
                limit: parseFloat(this.state.updatedData.credits),
                available: parseFloat(this.state.updatedData.credits),
              })
              .catch((err) => {
                errorHandler(err.response, true);
              })
          );
        } else {
          promises.push(
            api.crm
              .postSubagentCredits({
                affiliate: id,
                product: 'combined_credit',
                limit: parseFloat(this.state.updatedData.credits),
                available: parseFloat(this.state.updatedData.credits),
              })
              .catch((err) => {
                errorHandler(err.response, true);
              })
          );
        }
      }
      Promise.all(promises)
        .then((response) => {
          let affiliate = response[0].data,
            data = { ...this.state.data };
          if (this.props.updateAffiliate) {
            this.props.updateAffiliate(affiliate);
          }
          if (response[1]) {
            data.credits = response[1].limit;
          }
          store.dispatch(setAffiliate(affiliate));
          this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
          this.setState({
            affiliate,
            data,
            updatedData: {},
            masterApiLoading: false,
          });
          if (this.props.action) {
            this.props.action();
          }
          this.props.next();
        })
        .catch((error) => {
          errorHandler(error.response, true);
          this.setState({
            masterApiLoading: false,
          });
        });
    }
  };

  renderSubagentFields = () => {
    const languageMap = this.props.languageMap.components.userAddress;
    const { data, errors } = this.state;
    const compulsoryCredits = this.state.affiliate?.credit?.find((credit) => credit.product === 'combined_credit');
    return (
      <>
        <div className="key-value">
          <span className="key">{languageMap.credits}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="text"
                onChange={this.handleChange('credits')}
                value={data.credits || ''}
                inputMode="numeric"
                pattern="[0-9]*"
              />
              {errors.credits && <InlineError text={errors.credits} />}
            </div>
          </div>
        </div>
      </>
    );
  };

  renderAddressFields = (is_nid = false) => {
    const { data, errors } = this.state;
    const languageMap = this.props.languageMap.components.userAddress;

    let addressKey = 'address';
    let districtsKey = 'districts';
    let subDistrictsKey = 'subDistricts';

    if (is_nid) {
      addressKey = 'address_nid';
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    const districts = this.state[districtsKey];
    const sub_districts = this.state[subDistrictsKey];

    return (
      <>
        {is_nid && <div className="section-title">{languageMap.address_nid}</div>}
        {is_nid && (
          <div className="key-value">
            <div className="key">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(data.copy_address)}
                    onChange={this.handleChange('copy_address')}
                    value="true"
                    color="primary"
                    className="checkbox"
                  />
                }
                label={languageMap.copy_address}
              />
            </div>
          </div>
        )}
        <div className="key-value">
          <span className="key">{languageMap.textThree}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="text"
                onChange={this.handleChange('first_line', addressKey)}
                value={data[addressKey].first_line || ''}
              />
              {errors.first_line && <InlineError text={errors.first_line} />}
            </div>
          </div>
        </div>
        <div className="key-value">
          <span className="key">{languageMap.textFive}</span>
          <div className="value">
            <div className="form-field">
              <Dropdown
                selection
                options={PROVINCES}
                onChange={(e, data) => this.handleProvinceChange(e, data, addressKey)}
                name="province"
                value={data[addressKey].province}
              />
            </div>
          </div>
        </div>

        {districts.length ? (
          <div className="key-value">
            <span className="key">{languageMap.textSix}</span>
            <div className="value">
              <div className="form-field">
                <Dropdown
                  selection
                  options={districts}
                  onChange={(e, data) => this.handleDistrictChange(e, data, addressKey)}
                  name="district"
                  value={data[addressKey].district}
                />
              </div>
            </div>
          </div>
        ) : null}
        {sub_districts && sub_districts.length ? (
          <div className="key-value">
            <span className="key">{languageMap.textSeven}</span>
            <div className="value">
              <div className="form-field">
                <Dropdown
                  selection
                  options={sub_districts}
                  onChange={(e, data) => this.handleSubDistrictChange(e, data, addressKey)}
                  name="subdistrict"
                  value={data[addressKey].subdistrict}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="key-value">
          <span className="key">{languageMap.textFour}</span>
          <div className="value">
            <div className="form-field">
              <input
                type="number"
                onChange={this.handleChange('postal_code', addressKey)}
                value={data[addressKey].postal_code || ''}
              />
              {errors.postal_code && <InlineError text={errors.postal_code} />}
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { data, errors, imagePreviewUrl, loading, file, masterApiLoading } = this.state;
    const languageMap = this.props.languageMap.components.userAddress,
      langMap = this.props.languageMap.components.subagentPortal.commissionsForm;

    return (
      <div className="inline-wrapper">
        <div>
          <div className="key-value">
            <span className="key">{languageMap.fullName}</span>
            <div className="value">
              <div className="form-field">
                <input type="text" onChange={this.handleChange('fullname')} value={data.user.fullname || ''} />
                {errors.fullname && <InlineError text={errors.fullname} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.phone}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onChange={this.handleChange('phone')}
                  value={data.user.phone || ''}
                />
                {errors.phone && <InlineError text={errors.phone} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.email}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder={'Email *'}
                  value={data.user.email}
                  onChange={this.handleChange('email')}
                  autoComplete="off"
                />
                {errors.email && <InlineError text={errors.email} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.nationalId}</span>
            <div className="value">
              <div className={'form-field ' + (errors.national_id ? 'error' : '')}>
                <input type="number" onChange={this.handleChange('national_id')} value={data.national_id || ''} />
                {errors.national_id && <InlineError text={errors.national_id} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textEleven}</span>
            <div className="value">
              <div className="form-field">
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDatePicker
                    clearable
                    animateYearScrolling={false}
                    id="dob"
                    // label={languageMap.textEleven}
                    className="datepicker"
                    value={data.user.dob || null}
                    onChange={this.handleChange('dob')}
                    maxDate={MOMENT.clone().subtract(18, 'year')}
                    minDate={MOMENT.clone().subtract(100, 'year')}
                    format="DD/MM/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    initialFocusedDate={MOMENT.clone().subtract(18, 'year')}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textTwelve}</span>
            <div className="value">
              <div className="form-field">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={data.user.gender}
                    onChange={this.handleChange('gender')}
                    row
                    className="radio-group"
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio color="primary" />}
                      label={languageMap.textThirteen}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio color="primary" />}
                      label={languageMap.textFourteen}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          {this.renderSubagentFields()}
          {this.renderAddressFields()}
        </div>
        <Space>
          <Button className="button medium" onClick={this.submit} disabled={masterApiLoading}>
            {masterApiLoading ? (
              <CircularProgress variant="indeterminate" disableShrink size={14} thickness={4} color="secondary" />
            ) : (
              'Next'
            )}
          </Button>
          <Button className="button medium" onClick={this.props.close} disabled={masterApiLoading}>
            {masterApiLoading ? (
              <CircularProgress variant="indeterminate" disableShrink size={14} thickness={4} color="secondary" />
            ) : (
              langMap.cancel
            )}
          </Button>
        </Space>
      </div>
    );
  }
}

SubAgentForm.propTypes = {};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { showNotification, register })(SubAgentForm));
