import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Radio } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler, getAffiliateAttributes, getParameterByName, isAdmin, isMember } from '../../utils/helper';
import { actions, events } from '../../utils/trackingEvents';

// data.insurance_class === '1'

class AccessoriesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      loading: false,
      data: {
        has_metal_structure: '',
        has_accessories: '',
        accessories_cost_min: '',
        accessories_cost_max: '',
        totalAccessories: null,
        affiliate_to_customer_discount_type: 'flat_rate',
        affiliate_to_customer_discount: '',
      },
      updatedData: {},
      inputList: [],
    };
  }

  componentDidMount() {
    let affiliate_to_customer_discount = null,
      affiliate_to_customer_discount_type = 'flat_rate';

    if (this.props.data.quotationQuery && this.props.data.quotationQuery.affiliate_to_customer_discount_type) {
      affiliate_to_customer_discount_type = this.props.data.quotationQuery.affiliate_to_customer_discount_type;
    }

    if (
      this.props.data.quotationQuery &&
      this.props.data.quotationQuery.affiliate_to_customer_discount_type === 'percentage' &&
      this.props.data.quotationQuery.affiliate_to_customer_discount
    ) {
      let gross_premium = parseFloat(
        this.props.data.gross_premium || this.props.data.gross_premium.quotationQuery.gross_premium,
        10
      );
      if (gross_premium) {
        affiliate_to_customer_discount = parseFloat(this.props.data.quotationQuery.affiliate_to_customer_discount, 10);
        affiliate_to_customer_discount = parseFloat(0.97 * gross_premium * affiliate_to_customer_discount).toFixed(4);
        affiliate_to_customer_discount_type = 'flat_rate';
      }
    } else if (this.props.data.quotationQuery && this.props.data.quotationQuery.affiliate_to_customer_discount) {
      affiliate_to_customer_discount = this.props.data.quotationQuery.affiliate_to_customer_discount;
    } else {
      affiliate_to_customer_discount = '';
    }
    this.setState(
      {
        data: { ...this.state.data, affiliate_to_customer_discount, affiliate_to_customer_discount_type },
      },
      () => {
        let errors = this.validateDiscount(this.state.data);
        this.setState({
          errors,
        });
      }
    );
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  validate = () => {
    let errors = {};
    let data = this.state.data;
    const languageMap = this.props.languageMap.components.accessoriesModal;
    let text = '';
    let showMetalstructure = false;
    const quotationQuery = this.props.data.quotationQuery || this.props.quotationQuery;
    if (
      quotationQuery &&
      quotationQuery.model_description &&
      quotationQuery.model_description.value?.toLowerCase().includes('2 doors')
    ) {
      showMetalstructure = true;
    }

    if (!this.state.data.has_metal_structure && showMetalstructure) {
      return null;
    }

    if (parseInt(this.state.data.accessories_cost_max) > 50000) {
      text = languageMap.textThirteen;
      this.notify(text, 'info');
      this.close();
      return null;
    }

    if (this.state.data.has_metal_structure === 'true') {
      if (!this.props.data.is_truck_with_structure) {
        text = languageMap.textFourteen;
        this.notify(text, 'info');
        this.close();
        return null;
      }
    } else {
      if (this.props.data.is_truck_with_structure) {
        text = languageMap.textTen;
        this.notify(text, 'info');
        this.close();
        return null;
      }
    }
    return true;
  };

  validateDiscount = (data) => {
    const languageMap = this.props.languageMap.messages;
    const propsData = this.props.data;
    let errors = {},
      discountInputValue = parseFloat(data.affiliate_to_customer_discount),
      maxDiscountValue = 0,
      discountSymbol;
    let totalCommission = parseFloat(propsData.affiliate_commission);

    if (propsData.quotationQuery && propsData.quotationQuery.needs_compulsory_insurance) {
      totalCommission += parseFloat(propsData.affiliate_commission_compulsory);
    }

    if (!discountInputValue) {
      return errors;
    }

    if (data.affiliate_to_customer_discount_type === 'percentage') {
      let gross_premium = parseFloat(propsData.gross_premium, 10);

      maxDiscountValue = ((0.97 * 100 * parseFloat(totalCommission)) / gross_premium).toFixed(4);
      discountSymbol = '%';
    } else if (data.affiliate_to_customer_discount_type === 'flat_rate') {
      maxDiscountValue = Math.floor(totalCommission * 0.97);
      discountSymbol = languageMap.baht;
    }

    if (discountInputValue > maxDiscountValue) {
      errors.affiliate_to_customer_discount = `${languageMap.maxDiscount} ${maxDiscountValue} ${discountSymbol}`;
    }

    return errors;
  };

  handleRadioChange = (e, value) => {
    let { data, inputList } = this.state;
    data[value.name] = value.value;
    if (value.name === 'has_accessories') {
      data.accessories_cost_min = value.min;
      if (value.max) {
        data.accessories_cost_max = value.max;
      } else {
        data.accessories_cost_max = null;
      }
    }
    if (data.accessories_cost_max && data.accessories_cost_max <= 50000) {
      inputList = [
        {
          name: '',
          value: '',
        },
      ];
    }
    if (!data.accessories_cost_max) {
      inputList = [];
    }
    this.setState(
      {
        data,
        updatedData: { ...this.state.updatedData, [value.name]: value.value },
        inputList,
        totalAccessories: null,
      },
      () => {}
    );
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continueFn = () => {
    this.close();
    this.props.action && this.props.action();
  };

  save = () => {
    let errors = this.validateDiscount(this.state.data);
    if (Object.keys(errors).length) {
      this.setState({
        errors,
      });
      return;
    }
    if (this.props.source === 'Get Quotes') {
      window.trackEvent &&
        window.trackEvent.track(actions.Updated, `${events.getQuotesDiscountModalNext} ${actions.Updated}`, {
          quotationQueryId: this.props.id,
          discount: this.state.data.affiliate_to_customer_discount,
          quotationType: this.props.quotationType === 'coa' ? 'COA' : 'Fresh Quote',
          ...getAffiliateAttributes(),
        });
    } else if (this.props.source === 'Form six') {
      window.trackEvent &&
        window.trackEvent.track(actions.Updated, `${events.wizardForm6AdjustDiscount} ${actions.Updated}`, {
          quotationQueryId: this.props.id,
          instalmentSelected: this.props.instalmentSelected,
          compulsaryInsurance: this.props.data.quotationQuery.needs_compulsory_insurance ? 'Yes' : 'No',
          quotationType: this.props.quotationType === 'coa' ? 'COA' : 'Fresh Quote',
          saleType: this.props.saleType,
          ...getAffiliateAttributes(),
        });
    }
    let params = {},
      data = { ...this.state.data };

    if (!Object.keys(this.state.updatedData).length) {
      this.continueFn();
      return;
    }

    if (data.affiliate_to_customer_discount !== '') {
      params.affiliate_to_customer_discount_type = data.affiliate_to_customer_discount_type;
      params.affiliate_to_customer_discount = parseFloat(data.affiliate_to_customer_discount);
      if (data.affiliate_to_customer_discount_type === 'percentage') {
        params.affiliate_to_customer_discount /= 100;
      }
      params.affiliate_to_customer_discount = params.affiliate_to_customer_discount.toFixed(4);
    }

    if (
      (this.props.data.insurance_class !== '1' || this.props.isAdminFlow) &&
      Object.keys(params).length &&
      'affiliate_to_customer_discount' in this.state.updatedData &&
      !isMember()
    ) {
      this.setState({
        loading: true,
      });
      let apiFn = api.utils.patchQuotationQuery;
      if (this.props.isAdminFlow) {
        apiFn = api.utils.patchFairdeeQuotation;
      }
      apiFn(this.props.id, params)
        .then((resp) => {
          this.setState({
            loading: false,
          });
          this.close();
          if (this.props.action) {
            this.continueFn();
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errors: { ...this.state.errors, ...errorHandler(error.response, true) },
          });
        });
      return;
    }

    if (!this.validate()) {
      return;
    }

    if (data.has_accessories && data.accessories_cost_min !== null) {
      params.has_accessories = true;
    }
    if (data.has_metal_structure) {
      params.has_metal_structure = data.has_metal_structure === 'true';
    }
    if (data.accessories_cost_min || data.accessories_cost_min === 0) {
      params.accessories_cost_min = data.accessories_cost_min;
    }
    if (data.accessories_cost_max) {
      params.accessories_cost_max = data.accessories_cost_max;
    }
    if (data.accessories_cost_max > 50000) {
      params.accessories_cost_min = params.accessories_cost_min;
      delete params.accessories_cost_max;
    }

    let errorCount = 0;
    let { inputList } = this.state;

    if (this.state.inputList.length && data.accessories_cost_max && data.accessories_cost_max <= 50000) {
      inputList.forEach((data) => {
        data.keyError = false;
        data.valueError = false;
        if (!data.name) {
          data.keyError = true;
          errorCount++;
        }
        if (!data.value) {
          data.valueError = true;
          errorCount++;
        }
      });
      this.setState({
        inputList,
      });
    }

    if (errorCount > 0) {
      return;
    } else if (inputList.length) {
      inputList.forEach((data) => {
        delete data.keyError;
        delete data.valueError;
        delete data.id;
        data.value = parseInt(data.value);
      });
      params.accessories_cost_breakup = inputList;
    }
    if (data.accessories_cost_max && data.accessories_cost_max <= 50000 && !this.state.inputList.length) {
      let errors = this.state.errors;
      errors.inputList = this.props.languageMap.components.accessoriesModal.textTwenty;
      this.setState({
        errors,
      });
      return;
    }
    if (!Object.keys(params).length) {
      this.continueFn();
      return;
    }
    this.setState({
      loading: true,
    });
    let t = getParameterByName('t'),
      promise;
    if (t) {
      promise = api.utils.patchTokenisedQuotationQuery(this.props.id, params, t);
    } else {
      promise = api.utils.patchQuotationQuery(this.props.id, params);
    }
    promise
      .then((resp) => {
        this.setState({
          loading: false,
        });
        this.close();
        if (this.props.action) {
          this.continueFn();
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errors: { ...this.state.errors, ...errorHandler(error.response, true) },
        });
      });
  };

  renderTwoDoorTruckDesc = () => {
    const languageMap = this.props.languageMap.components.accessoriesModal;
    let text = '';
    let data = this.props.data;

    if (!this.state.data.has_metal_structure) {
      return null;
    }
    if (this.state.data.has_metal_structure === 'true') {
      if (!data.is_truck_with_structure) {
        text = languageMap.textFourteen;
      } else {
        return null;
      }
    } else {
      if (data.is_truck_with_structure) {
        text = languageMap.textTen;
      } else {
        return null;
      }
    }
    return (
      <div className="desc-wrap">
        <p>{text}</p>
      </div>
    );
  };

  renderAccessoriesDesc = () => {
    const languageMap = this.props.languageMap.components.accessoriesModal;
    let text = '';

    if (!this.state.data.accessories_cost_max) {
      return null;
    }
    if (parseInt(this.state.data.accessories_cost_max) <= 20000) {
      return null;
    }
    if (
      parseInt(this.state.data.accessories_cost_max) > 20000 &&
      parseInt(this.state.data.accessories_cost_max) <= 50000
    ) {
      text = languageMap.textFifteen;
    }
    if (parseInt(this.state.data.accessories_cost_max) > 50000) {
      text = languageMap.textThirteen;
    }
    return (
      <div className="desc-wrap">
        <p>{text}</p>
      </div>
    );
  };

  handleChange = (index, e) => {
    let inputList = this.state.inputList;
    inputList[index][e.target.name] = e.target.value;
    let totalAccessories = null;
    inputList.forEach((data) => {
      if (data.value || data.value === 0) {
        totalAccessories += parseFloat(data.value, 10);
      }
    });
    this.setState({
      inputList,
      totalAccessories,
    });
  };

  addAccInput = () => {
    let inputList = this.state.inputList;
    inputList.push({
      name: '',
      value: '',
    });
    this.setState({
      inputList,
    });
  };

  deleteAccessory = (ind) => {
    let inputList = this.state.inputList;
    inputList.splice(ind, 1);
    this.setState({
      inputList,
    });
  };

  renderInputWrap = () => {
    let { inputList, totalAccessories, errors } = this.state;
    const languageMap = this.props.languageMap.components.accessoriesModal;

    return (
      <div className="inline-input-field">
        {inputList.length ? (
          <div className="row no-border">
            <div className="col">
              <p>{languageMap.textSixteen}</p>
            </div>
            <div className="col">
              <p>{languageMap.textSevenTeen}</p>
            </div>
          </div>
        ) : null}
        {inputList.map((data, i) => {
          return (
            <div className="row" key={i + 1}>
              <div className="col">
                <input
                  type="text"
                  value={data.name}
                  autoComplete="off"
                  onChange={this.handleChange.bind(null, i)}
                  name="name"
                />
                {data.keyError ? <InlineError text={this.props.languageMap.messages.incorrectInfo} /> : null}
              </div>
              <div className="col">
                <input
                  type="number"
                  value={data.value}
                  autoComplete="off"
                  onChange={this.handleChange.bind(null, i)}
                  name="value"
                />
                {data.valueError ? <InlineError text={this.props.languageMap.messages.incorrectInfo} /> : null}
              </div>
              {inputList.length > 1 ? <img src={cross} onClick={this.deleteAccessory.bind(null, i)} alt="" /> : null}
            </div>
          );
        })}
        {inputList.length && totalAccessories ? (
          <p className="total">
            {languageMap.textEighteen} {totalAccessories}
          </p>
        ) : null}
        {errors.inputList ? <InlineError text={errors.inputList} /> : null}
        <button className="button primary medium" onClick={this.addAccInput}>
          {languageMap.textNineteen}
        </button>
      </div>
    );
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      updatedData: { ...this.state.updatedData, [e.target.name]: e.target.value },
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState(
      {
        data: { ...this.state.data, [data.name]: data.value },
        updatedData: { ...this.state.updatedData, [data.name]: data.value },
      },
      () => {}
    );
  };

  render() {
    const { data, errors, loading } = this.state;
    const { sum_insured, isAdminFlow } = this.props;
    const languageMap = this.props.languageMap.components.accessoriesModal;
    let showMetalstructure = false;
    let isInstalment = this.props.quotationQuery
      ? this.props.quotationQuery.will_pay_in_instalments
      : getParameterByName('instalment') === 'true' && isAdmin();
    if (
      this.props.quotationQuery &&
      this.props.quotationQuery.model_description &&
      this.props.quotationQuery.model_description.value?.toLowerCase().includes('2 doors')
    ) {
      showMetalstructure = true;
    }
    let min = 0,
      max = 50000,
      range = 10000,
      options = [
        { text: 'ไม่มีอุปกรณ์เครื่องแต่ง', min: null, max: null },
        { text: '0 - 20,000 บาท', min, max: 20000 },
        { text: `20,000 - 50,000 บาท`, min: 20000, max },
        { text: `มากกว่า 50,000 บาท`, min: max, max: max + 1 },
      ],
      twentyPercent = 0.2 * parseFloat(sum_insured, 10),
      suminsuredBracket = 50000,
      steps = twentyPercent / range,
      optionLength = Math.round(steps);

    if (
      isInstalment &&
      this.props.data.insurance_class !== '1' &&
      this.renderAccessoriesDesc() === null &&
      (!data.accessories_cost_max || data.accessories_cost_max > 50000) &&
      !showMetalstructure
    ) {
      if (this.props.action) {
        this.continueFn();
      }
      return null;
    }

    if (twentyPercent < suminsuredBracket) {
      options = [{ text: 'ไม่มีอุปกรณ์เครื่องแต่ง', min: null, max: null }];
      min = 0;
      max = range;
      for (let i = 0; i < optionLength; i++) {
        let str = `${min} - ${max} บาท`,
          obj = {};
        obj.min = min;
        obj.max = max;
        if (i === 0) {
          str = `ไม่มี หรือ ต่ำกว่า ${max} บาท`;
        }
        if (i === optionLength - 1) {
          str = `มากกว่า ${min} บาท`;
          obj.max = undefined;
        }
        if (optionLength <= 1) {
          str = 'ไม่มี หรือ ต่ำกว่า 20000 บาท';
          obj.max = 20000;
        }
        obj.text = str;
        options.push(obj);

        min += range;
        max += range;
      }
    }

    const lg_code = getParameterByName('lg_code');
    if (
      (isMember() || isInstalment || lg_code) &&
      !showMetalstructure &&
      (!data.accessories_cost_max || data.accessories_cost_max >= 50000) &&
      (isAdminFlow || this.props.data.insurance_class !== '1')
    ) {
      if (this.props.action) {
        this.continueFn();
      }
      return null;
    }
    return (
      <div className="fairdee-modal-wrapper customer-info coa-form">
        <div className="modal-content">
          {!this.props.updateCompulsory && <img src={cross} className="close" alt="" onClick={this.close} />}
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">&nbsp;</span>
            </p>
          </div>
          {!this.props.updateCompulsory ? (
            <h1 className="title-text">{languageMap.textOne}</h1>
          ) : (
            <h1 className="title-text">{languageMap.updateDiscount}</h1>
          )}
          {(!isMember() && !isInstalment && !lg_code && !this.props.hideDiscount && !isAdmin()) ? (
            <div className=" ui form coa">
              <p className="label-text">{languageMap.textTwentyOne}</p>
              <div className="inline-fields">
                <div className="form-field">
                  <input
                    type="number"
                    id="affiliate_to_customer_discount"
                    name="affiliate_to_customer_discount"
                    placeholder={languageMap.textEighteen}
                    value={data.affiliate_to_customer_discount}
                    onChange={this.onChange}
                  />
                  {errors.affiliate_to_customer_discount && (
                    <InlineError text={errors.affiliate_to_customer_discount} />
                  )}
                  {/* <label className="form-label" htmlFor="affiliate_to_customer_discount">{languageMap.textEighteen}</label> */}
                </div>
                <div className="form-field">
                  <div className="label-text">บาท</div>
                </div>
              </div>
            </div>
          ) : null}

          {!isAdminFlow && (
            <div className="fairdee-form mt-12">
              {this.props.data.insurance_class === '1' ? (
                <div className="form-field">
                  <label htmlFor="" className="form-label open">
                    {languageMap.textTwo}
                  </label>
                  <div className="inline fields">
                    {options.map((opt, index) => {
                      return (
                        <div className="field" key={index}>
                          <div className="ui radio checkbox">
                            <Radio
                              name="has_accessories"
                              onChange={this.handleRadioChange}
                              checked={Boolean(data.has_accessories === opt.text)}
                              label={opt.text}
                              value={opt.text}
                              min={opt.min}
                              max={opt.max}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {this.renderAccessoriesDesc()}
              {data.accessories_cost_max && data.accessories_cost_max <= 50000 ? this.renderInputWrap() : null}
              {showMetalstructure ? (
                <div>
                  <div className="form-field">
                    <label htmlFor="" className="form-label open">
                      {languageMap.textSeven}
                    </label>
                    <div className="inline fields">
                      <div className="field">
                        <div className="ui radio checkbox">
                          <Radio
                            name="has_metal_structure"
                            onChange={this.handleRadioChange}
                            checked={data.has_metal_structure === 'true'}
                            label={languageMap.textEight}
                            value="true"
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="ui radio checkbox">
                          <Radio
                            name="has_metal_structure"
                            onChange={this.handleRadioChange}
                            checked={data.has_metal_structure === 'false'}
                            label={languageMap.textNine}
                            value="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderTwoDoorTruckDesc()}
                </div>
              ) : null}
              <div className="buttons-wrap">
                {!this.props.updateCompulsory && (
                  <button className="button orange-bordered-button" onClick={this.close}>
                    {languageMap.textEleven}
                  </button>
                )}

                {this.props.data.insurance_class === '1' ? (
                  <button
                    className={
                      'button primary ' +
                      (loading ||
                      !data.has_accessories ||
                      (data.has_metal_structure === '' && showMetalstructure) ||
                      (!this.props.data.is_truck_with_structure && data.has_metal_structure === 'true')
                        ? 'disabled'
                        : '')
                    }
                    onClick={this.save}
                  >
                    {languageMap.textTwelve}
                  </button>
                ) : (
                  <button className={'button primary ' + (loading ? 'disabled' : '')} onClick={this.save}>
                    {languageMap.textTwelve}
                  </button>
                )}
              </div>
            </div>
          )}
          {(!isMember() && !isInstalment && !lg_code && !this.props.hideDiscount && isAdminFlow)  && (
            <>
            <div className=" ui form coa">
              <p className="label-text">{languageMap.textTwentyOne}</p>
              <div className="inline-fields">
                <div className="form-field">
                  <input
                    type="number"
                    id="affiliate_to_customer_discount"
                    name="affiliate_to_customer_discount"
                    placeholder={languageMap.textEighteen}
                    value={data.affiliate_to_customer_discount}
                    onChange={this.onChange}
                  />
                  {errors.affiliate_to_customer_discount && (
                    <InlineError text={errors.affiliate_to_customer_discount} />
                  )}
                </div>
                <div className="form-field">
                  <div className="label-text">บาท</div>
                </div>
              </div>
            </div>
            <div className="buttons-wrap">
              {!this.props.updateCompulsory && (
                <button className="button orange-bordered-button" onClick={this.close}>
                  {languageMap.textEleven}
                </button>
              )}
              {
                <button className={'button primary ' + (loading ? 'disabled' : '')} onClick={this.save}>
                  {loading ? <CircularProgress size={14} color="white"></CircularProgress> : languageMap.textTwelve}
                </button>
              }
            </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

AccessoriesModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal, showNotification })(AccessoriesModal));
