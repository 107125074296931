import { CURRENT_LANGUAGE, CURRENT_LANGUAGE_MAP } from '../constants/types';

function languageMap(state = {}, action = {}) {
  switch (action.type) {
    case CURRENT_LANGUAGE_MAP:
      return action.language;
    default:
      return state;
  }
}

function language(state = '', action = {}) {
  switch (action.type) {
    case CURRENT_LANGUAGE:
      return action.currentLanguage;
    default:
      return state;
  }
}

export { languageMap, language };
