import { BREADCRUMB } from '../constants/types';

function breadcrumb(state = [], action = {}) {
  switch (action.type) {
    case BREADCRUMB:
      return action.breadcrumb;
    default:
      return state;
  }
}

export { breadcrumb };
