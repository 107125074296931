import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { commaSeperatedNumbers, errorHandler } from '../../utils/helper';

class UpdateCredit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data || {},
      errors: {},
      updatedData: {},
      actionType: null,
      amount: 0,
      updatedCredit: null,
      updatedCreditLimit: null,
      loading: false,
    };
    window.console.log(this.state.data);
  }

  componentDidMount() {}

  validate = (data) => {
    const errors = {};
    const messages = this.props.languageMap.messages;

    if (!data.amount) {
      errors.amount = messages.required;
    }
    return errors;
  };

  handleChange = (name) => (event) => {
    window.console.log(name, event.target.value);
    let updatedCredit = 0;
    let amount = event.target.value || 0;
    let updatedCreditLimit = 0;

    if (this.state.actionType === 'add') {
      if (parseInt(amount, 10) > parseInt(this.state.data.limit, 10) - parseInt(this.state.data.available, 10)) {
        return;
      } else {
        updatedCredit = parseInt(this.state.data.available, 10) + parseInt(amount, 10);
      }
    } else if (this.state.actionType === 'subtract') {
      if (parseInt(amount, 10) > parseInt(this.state.data.available, 10)) {
        return;
      } else {
        updatedCredit = parseInt(this.state.data.available, 10) - parseInt(amount, 10);
      }
    } else {
      updatedCreditLimit = parseInt(this.state.data.limit, 10) + parseInt(amount, 10);
      updatedCredit = parseInt(this.state.data.available, 10) + parseInt(amount, 10);
    }
    if (updatedCredit === parseInt(this.state.data.available, 10)) {
      updatedCredit = 0;
    }
    this.setState({
      [name]: event.target.value,
      updatedCredit,
      updatedCreditLimit,
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  save = () => {
    let errors = this.validate(this.state);
    window.console.log(this.state.data, errors);
    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    let params = {
      credit_id: this.state.data.id,
      amount: parseFloat((this.state.actionType === 'subtract' ? '-' : '') + this.state.amount, 10),
      action: this.state.actionType,
    };

    window.console.log(this.props.data.id, params);
    this.setState({ loading: true });
    api.crm
      .creditTransaction(params)
      .then((resp) => {
        window.console.log(resp);
        if (this.props.action) {
          this.props.action();
        }
        this.setState({ loading: false });
        this.close();
      })
      .catch((error) => {
        errorHandler(error.response);
        this.setState({ loading: false });
      });
  };

  action = (actionType) => {
    window.console.log(actionType);
    this.setState({
      actionType,
      amount: 0,
    });
  };

  renderUpdates = () => {
    const { actionType, updatedCredit, updatedCreditLimit } = this.state;
    if (actionType) {
      if (actionType === 'increaseCreditLimit') {
        return (
          <div>
            <p className="info-text">Updated Credit Limit: {commaSeperatedNumbers(updatedCreditLimit)}</p>
            <p className="info-text">Updated Credit: {commaSeperatedNumbers(updatedCredit)}</p>
          </div>
        );
      } else {
        return <p className="info-text">Updated Credit: {commaSeperatedNumbers(updatedCredit)}</p>;
      }
    }
  };

  render() {
    const { data, errors, actionType, amount, updatedCredit } = this.state;
    const languageMap = this.props.languageMap.components.coverNote;

    window.console.log(this.state);

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Update Credit</span>
            </p>
          </div>
          <h1 className="title-text">Update Credit</h1>
          <div className="aff-credit">
            <div>
              <p className="info-text">Credit Limit: {commaSeperatedNumbers(data.limit)}</p>
              <p className="info-text">Credit Available: {commaSeperatedNumbers(data.available)}</p>
              {this.renderUpdates()}
              <div className="button-wrap">
                { data.is_prepaid &&
                  <Button
                  className="credit-transaction-button"
                  variant="contained"
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={this.action.bind(null, 'add')}
                >
                  Add Credit
                </Button>
                }
                <Button
                  className="credit-transaction-button"
                  variant="contained"
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={this.action.bind(null, 'subtract')}
                >
                  Subtract Credit
                </Button>
              </div>
              <div className="button-wrap">
                <Button
                  className="credit-transaction-button"
                  variant="contained"
                  aria-label="add"
                  color="primary"
                  size="small"
                  onClick={this.action.bind(null, 'increaseCreditLimit')}
                >
                  Increase Credit Limit
                </Button>
              </div>
            </div>
            {actionType !== null && (
              <div>
                <p className="text">Amount to be {actionType === 'subtract' ? 'subtracted' : 'added'}</p>
                <TextField
                  required
                  type="text"
                  label="Amount"
                  value={amount || ''}
                  error={Boolean(errors.amount)}
                  helperText={errors.amount}
                  autoComplete="off"
                  className="full-width"
                  onChange={this.handleChange('amount')}
                ></TextField>
              </div>
            )}
            {amount ? (
              <div className="button-wrap">
                <Button
                  className={'credit-transaction-button ' + (this.state.loading ? 'disabled' : '')}
                  onClick={this.save}
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

UpdateCredit.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(UpdateCredit));
