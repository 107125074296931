export const CLIENT_ID = window.env.CLIENT_ID;
export const CLIENT_SECRET = window.env.CLIENT_SECRET;
export const FACEBOOK_ID = window.env.FACEBOOK_ID;
export const GOOGLE_ID = window.env.GOOGLE_ID;
export const RECAPTCHA_ID = window.env.RECAPTCHA_ID;
export const LINE_CLIENT_ID = window.env.LINE_CLIENT_ID;
export const FACEBOOK_ID_SHARE = window.env.FACEBOOK_ID_SHARE;
export const LINE_LIFF_ID = window.env.LINE_LIFF_ID;
export const S3_URL = window.env.S3_URL;
export const SENTRY_DSN = window.env.SENTRY_DSN;
export const TRACES_SAMPLE_RATE = window.env.TRACES_SAMPLE_RATE;
export const NEW_PORTAL = window.env.NEW_PORTAL;
export const OLD_PORTAL = window.env.OLD_PORTAL;
export const RECAPTCHA_SITEKEY = window.env.RECAPTCHA_SITEKEY;

let API_URL = window.env.API_URL;

if (!API_URL) {
  if (window.location.hostname === 'localhost') {
    API_URL = 'https://staging-api.fairdee.co.th/';
  } else {
    API_URL = window.location.protocol + '//' + window.location.hostname.replace('portal', 'api') + '/';
  }
}

export { API_URL };
