import { SYSTEM_SETTINGS } from '../constants/types';

function systemSettings(state = {}, action = {}) {
  switch (action.type) {
    case SYSTEM_SETTINGS:
      return action.systemSettings;
    default:
      return state;
  }
}

export { systemSettings };
