import 'animate.css/animate.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';
import { setLanguage, setLanguageMap } from './actions/language';
import App from './App';
import { validHostNames } from './components/fairdee-x-plus/utils/helper';
// import 'react-rangeslider/lib/index.css';
import './index.css';
import { languageMap } from './language-map/mapping';
import rootReducer from './rootReducer';
import { getLanguage, setAxiosHeaders, setLanguageToElement } from './utils/helper';

declare let window: any;

const HOST_NAME: string = window.location.hostname;

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

setAxiosHeaders();

if (window.Raven) {
  // Loaded successfully
  if (validHostNames[HOST_NAME]) {
    window.Raven.config('https://061fe35ac6314552b0f490c0123e0056@sentry.vouchinsurance.sg/12').install();
  } else if (window.location.hostname !== 'localhost') {
    window.Raven.config('https://77bafe5be55646a1a976c90d5d4b897a@sentry.vouchinsurance.sg/13').install();
  }
}

(function () {
  let currentLanguageMap: string = '';

  store.dispatch(setLanguage(getLanguage() || 'thai'));
  store.dispatch(setLanguageMap(languageMap[getLanguage() || 'thai']));
  store.subscribe(() => {
    let previousLanguageMap = currentLanguageMap;
    currentLanguageMap = store.getState().language;
    if (previousLanguageMap !== currentLanguageMap) {
      setLanguageToElement(currentLanguageMap);
    }
  });
})();

function silentErrorHandler() {
  return true;
}

if (validHostNames[window.location.hostname]) {
  window.console.log = function () {};
  window.console.error = function () {};
  window.console.info = function () {};
  window.console.warn = function () {};
  window.console.clear = function () {};
  window.console.debug = function () {};
  window.onerror = silentErrorHandler;
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store as any}>
      <Route component={App} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
