import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import qrCode from '../../images/qrcode.png';
import { getLineUrl, getParameterByName, isMobileDevice, setLanguageToElement } from '../../utils/helper';

class WizardThankyouModal extends React.Component {
  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continue = () => {
    if (window.liff.closeWindow) {
      window.liff.closeWindow();
    } else {
      let affiliate = getParameterByName('affiliate') || null;
      if (affiliate) {
        this.props.history.push('/crm/affiliate/' + affiliate);
      } else {
        this.props.history.push('/buy-insurance');
      }
      this.close();
    }
  };

  renderDesktop = () => {
    const languageMap = this.props.languageMap.components.wizard.modals.thankYou;
    return (
      <div>
        {this.props.hardSave ? (
          <div>
            <p className="title">{languageMap.textOne}</p>
            <p className="text">{languageMap.textSix}</p>
            <p className="text">{languageMap.textSeven}</p>
            <div className="qr-code">
              <img src={qrCode} alt="" />
            </div>
            <p className="text">{languageMap.textEight}</p>
            <div className="button-wrap">
              <button className="button medium" onClick={this.continue}>
                {languageMap.textThree}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="title">{languageMap.textFour}</p>
            <p className="text">{languageMap.textFive}</p>
          </div>
        )}
      </div>
    );
  };

  renderMobile = () => {
    const languageMap = this.props.languageMap.components.wizard.modals.thankYou;
    return (
      <div>
        {this.props.hardSave ? (
          <div>
            <p className="title">{languageMap.textOne}</p>
            <p className="text">{languageMap.textSix}</p>
            <p className="text">{languageMap.textSeven}</p>
            <a href={getLineUrl()} target="_blank" className="button medium primary mt-30" onClick={this.close}>
              {languageMap.textNine}
            </a>
          </div>
        ) : (
          <div>
            <p className="title">{languageMap.textFour}</p>
            <p className="text">{languageMap.textFive}</p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const languageMap = this.props.languageMap.components.wizard.modals.thankYou;

    return (
      <div className="fairdee-modal-wrapper small-medium message">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          {/* {
            this.props.hardSave ?
            <div>
              <p className="title">{languageMap.textOne}</p>
              <p className="text">{languageMap.textTwo}</p>
            </div>
            :
            <div>
              <p className="title">{languageMap.textFour}</p>
              <p className="text">{languageMap.textFive}</p>
            </div>
          }
          <div className="button-wrap">
            <button className="button medium" onClick={this.continue}>{languageMap.textThree}</button>
          </div> */}
          {isMobileDevice() ? this.renderMobile() : this.renderDesktop()}
        </div>
      </div>
    );
  }
}

WizardThankyouModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(WizardThankyouModal));
