import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';
import validator from 'validator';

class CovernoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        client_title: '',
        client_first_name: '',
        client_last_name: '',
        vehicle_number: '',
        chassis_number: '',
        cover_note_code: '',
        is_locked: null,
        ...props.data,
      },
      errors: {},
      loading: false,
      updatedData: {},
    };
    window.console.log(this.props.data);
  }

  componentDidMount() {
    setLanguageToElement();
    if (this.props.data && this.props.data.sale && this.props.data.car && this.props.data.car.chassis_number) {
      this.setState({
        data: { ...this.state.data, chassis_number: this.props.data.car.chassis_number },
      });
    }
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;

    if (name === 'chassis_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'chassis_number') {
      value = value ? value.toUpperCase() : value;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  validate = (data) => {
    let errors = {};

    // if (!data.client_title) {
    //   errors.client_title = 'Required Field';
    // }
    // if (!data.client_first_name) {
    //   errors.client_first_name = 'Required Field';
    // }
    // if (!data.client_last_name) {
    //   errors.client_last_name = 'Required Field';
    // }
    // if (!data.vehicle_number) {
    //   errors.vehicle_number = 'Required Field';
    // }
    // if (!data.cover_note_code) {
    //   errors.cover_note_code = 'Required Field';
    // }

    return errors;
  };

  save = () => {
    let errors = this.validate(this.state.updatedData);
    this.setState({
      errors,
    });
    window.console.log(this.state.data, errors);

    if (Object.keys(errors).length) {
      return;
    }

    let params = this.state.updatedData;

    if (!Object.keys(params).length) {
      this.close();
      return;
    }

    // if (params.is_locked) {
    //   params.is_locked = params.is_locked === 'true';
    // }
    params.is_locked = true;

    api.utils
      .patchFairdeeQuotation(this.props.data.id, params, this.props.affiliate)
      .then((resp) => {
        window.console.log(resp);
        if (this.props.action) {
          this.props.action(this.props.id);
        }
        this.close();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, errors, loading } = this.state;
    const languageMap = this.props.languageMap.components.coverNote;

    return (
      <div className="fairdee-modal-wrapper cover-note">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="cover-note-wrap">
            <div className="form-element-wrap">
              <TextField
                id="client_title"
                label={languageMap.form.textOne}
                value={data.client_title || ''}
                margin="normal"
                error={errors.client_title ? true : false}
                helperText={errors.client_title}
                autoComplete="off"
                name="client_title"
                onChange={this.handleChange('client_title')}
                className="full-width"
                disabled
              />
            </div>
            <div className="form-element-wrap">
              <TextField
                id="client_first_name"
                label={languageMap.form.textTwo}
                value={data.client_first_name || ''}
                margin="normal"
                error={errors.client_first_name ? true : false}
                helperText={errors.client_first_name}
                autoComplete="off"
                name="client_first_name"
                onChange={this.handleChange('client_first_name')}
                className="full-width"
                disabled
              />
            </div>
            <div className="form-element-wrap">
              <TextField
                id="client_last_name"
                label={languageMap.form.textThree}
                value={data.client_last_name || ''}
                margin="normal"
                error={errors.client_last_name ? true : false}
                helperText={errors.client_last_name}
                autoComplete="off"
                name="client_last_name"
                onChange={this.handleChange('client_last_name')}
                className="full-width"
                disabled
              />
            </div>
            <div className="form-element-wrap">
              <TextField
                id="vehicle_number"
                label={languageMap.form.textFour}
                value={data.vehicle_number || ''}
                margin="normal"
                error={errors.vehicle_number ? true : false}
                helperText={errors.vehicle_number}
                autoComplete="off"
                name="vehicle_number"
                onChange={this.handleChange('vehicle_number')}
                className="full-width"
                disabled
              />
            </div>
            <div className="form-element-wrap">
              <TextField
                id="chassis_number"
                label={languageMap.form.textFive}
                value={data.chassis_number || ''}
                margin="normal"
                error={errors.chassis_number ? true : false}
                helperText={errors.chassis_number}
                autoComplete="off"
                name="chassis_number"
                onChange={this.handleChange('chassis_number')}
                className="full-width"
              />
            </div>
            <div className="form-element-wrap">
              <TextField
                id="cover_note_code"
                label={languageMap.form.textSix}
                value={data.cover_note_code || ''}
                margin="normal"
                error={errors.cover_note_code ? true : false}
                helperText={errors.cover_note_code}
                autoComplete="off"
                name="cover_note_code"
                onChange={this.handleChange('cover_note_code')}
                className="full-width"
              />
            </div>
            {/* <div className="form-element-wrap">
              <label htmlFor="" className="form-label">{languageMap.form.textSeven}</label>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.is_locked === 'true'}
                      onChange={this.handleChange('is_locked')}
                      value="true"
                      name="is_locked"
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.is_locked === 'false'}
                      onChange={this.handleChange('is_locked')}
                      value="false"
                      name="is_locked"
                    />
                  }
                  label="No"
                />
              </FormGroup>
            </div> */}
            <div className="button-wrap">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={this.save}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CovernoteForm.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  window.console.log(state);
  return {
    languageMap: state.languageMap,
    insurers: state.insurers,
    filter: state.filter,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(CovernoteForm));
