import React from 'react';
import { connect } from 'react-redux';
import DownArrow from '../images/arrow-down-black.svg';
import { commaSeperatedNumbers, doScrolling, isMobileDevice } from '../utils/helper';
import InlineError from './InlineError';

const TOSHOW_ITEM = 6;
class SearchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: '',
      selectedData: {},
      popularOptions: [],
      seeAll: false,
      showOptions: props.isOpen || true,
      options: this.props.options,
      value: this.props.value,
      isFocussed: false,
    };
  }

  componentDidMount() {
    this.constructData();
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleDropdownToggle = () => {
    this.setState({
      showOptions: !this.state.showOptions,
    });
  };
  handleClick = (e) => {
    this.setState({
      isFocussed: false,
    });
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        showOptions: false,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.options !== nextProps.options ||
      this.props.value !== nextProps.value ||
      this.props.error !== nextProps.error ||
      this.props.helperText !== nextProps.helperText
    ) {
      this.setState(
        {
          options: nextProps.options,
          value: nextProps.value,
          error: nextProps.error,
          helperText: nextProps.helperText,
        },
        (_) => {
          this.constructData();
        }
      );
    }
    if (this.props.autoFocus !== nextProps.autoFocus && nextProps.autoFocus === true) {
      // let element = document.getElementById(this.props.name);
      // if(element) {
      //   element.focus();
      // }
      this.handleFocus(null, true);
    }
  }

  constructData = () => {
    if (this.state.options.length && this.state.options.length > TOSHOW_ITEM) {
      let popularOptions = this.state.options.filter((d, i) => i < TOSHOW_ITEM);
      this.setState({
        popularOptions,
        seeAll: false,
      });
    } else {
      this.setState({
        popularOptions: this.state.options,
        seeAll: true,
      });
    }
    this.setState({
      showOptions:
        this.props.isOpen === undefined || this.state.isFocussed
          ? this.state.options.length
            ? true
            : false
          : this.props.isOpen,
    });
    if (this.state.value) {
      let data = {};
      if (typeof this.props.value === 'object' && Object.keys(this.props.value).length) {
        data = this.props.value;
      } else {
        let arr = this.state.options.filter((data) => {
          if (data.id === this.props.value || data.value === this.props.value) {
            return data;
          }
        });
        data = arr[0] || {};
      }
      this.setState({
        selected: data.name || '',
        selectedData: data,
        showOptions: false,
      });
    } else {
      this.setState({
        selected: '',
        selectedData: {},
      });
    }
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
    if (event.target.value.length > 0) {
      event.target.value = event.target.value.toString().toUpperCase();
      let searchList = this.props.options.filter((data) => {
        data.name = data.name.toString().toUpperCase();
        event.target.value = event.target.value.toString().toUpperCase();
        if (data.name.indexOf(event.target.value) > -1 && event.target.value.length === data.name.length) {
          this.selectData(data);
        }
        if (data.name.indexOf(event.target.value) > -1) {
          return data;
        }
        // return data.name.match(event.target.value)
      });
      this.setState({
        popularOptions: searchList,
        seeAll: true,
      });
    } else {
      let searchList = this.props.options.filter((d, i) => i < TOSHOW_ITEM);
      this.setState({
        seeAll: false,
        popularOptions: searchList,
      });
      this.selectData({});
    }
    if (!this.state.showOptions) {
      this.setState({
        showOptions: true,
      });
    }
  };

  handleFocus = (e, scrollElement) => {
    this.setState({
      isFocussed: true,
    });
    if (scrollElement && isMobileDevice()) {
      setTimeout(() => {
        doScrolling(this.node, 500, 70);
      }, 500);
    }
    if (!this.state.selected) {
      this.setState({
        showOptions: true,
        seeAll: false,
      });
    }
    if (this.props.handleFocus && !this.state.selected) {
      this.props.handleFocus(true)(e);
    }
  };

  handleBlur = (e) => {
    if (this.props.handleFocus && !this.state.selected) {
      this.props.handleFocus(false)(e);
    }
  };

  seeAll = () => {
    this.setState({
      seeAll: !this.state.seeAll,
    });
    this.setState({
      popularOptions: this.props.options,
    });
  };

  selectData = (data) => {
    if (!Object.keys(data).length) {
      this.setState({
        selected: '',
        selectedData: data,
        showOptions: true,
      });
    } else {
      this.setState({
        selected: data.name,
        selectedData: data,
        seeAll: false,
        showOptions: false,
        popularOptions: this.props.options.filter((d, i) => i < TOSHOW_ITEM),
      });
      if (this.state.selectedData.value === data.value) {
        return;
      }
    }
    if (this.props.onChange && this.props.name) {
      this.props.onChange(this.props.name, data);
    }
  };

  render() {
    const { selected, popularOptions, seeAll, showOptions } = this.state;
    const { commaSeperated } = this.props;

    return (
      <div className={'search-list ' + (this.props.className || '')} ref={(node) => (this.node = node)}>
        {!this.props.search ? (
          <div className={'search-input ' + (this.props.error ? 'errors' : null)}>
            <input
              type="text"
              id={this.props.id}
              value={selected}
              index={this.props.index}
              onChange={this.handleChange('selected')}
              disabled
              autoComplete="off"
              placeholder={this.props.label}
              autoFocus={this.props.autoFocus}
            />
            <label
              htmlFor={this.props.id}
              className={selected ? 'selected' : ''}
              dangerouslySetInnerHTML={{ __html: this.props.label }}
            ></label>
            {this.props.error && <InlineError text={this.props.helpertext} />}
          </div>
        ) : (
          <div className={'search-input ' + (this.props.error ? 'errors' : null)}>
            <input
              type="text"
              id={this.props.id}
              value={selected}
              index={this.props.index}
              onChange={this.handleChange('selected')}
              disabled={this.props.disabled ? true : false}
              placeholder={this.props.label}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              autoComplete="off"
              autoFocus={this.props.autoFocus}
            />
            <span className="ddl-arrow" onClick={this.handleDropdownToggle}>
              <img src={DownArrow} />
            </span>
            <label
              htmlFor={this.props.id}
              className={selected ? 'selected' : ''}
              dangerouslySetInnerHTML={{ __html: this.props.label }}
            ></label>
            {this.props.error && <InlineError text={this.props.helpertext} />}
          </div>
        )}
        {showOptions && popularOptions.length ? (
          <div className="popular-list-wrap animated fadeIn">
            {popularOptions.length ? <p className="text">{this.props.listLabel}</p> : null}
            <div className="list-wrap">
              {popularOptions.map((data, index) => {
                return (
                  <div key={index} className="list  animated fadeIn" onClick={this.selectData.bind(null, data)}>
                    {commaSeperated ? commaSeperatedNumbers(data.name) : data.name}
                  </div>
                );
              })}
              {!seeAll && this.state.options.length > TOSHOW_ITEM && (
                <div key="others" className="list animated fadeIn" onClick={this.seeAll}>
                  {this.props.language === 'thai' ? 'ดูเพิ่มเติม' : 'See More'}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(SearchList);
