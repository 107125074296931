import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import validator from 'validator';
import { affiliateProfile } from '../../../actions/affiliate';
import { userProfile } from '../../../actions/auth';
import { clearModal, showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { CLIENT_ID, CLIENT_SECRET, RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import OtpBanner from '../../../images/fairdee-x-plus/auth/otp-banner.svg';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import { RootState } from '../../../index';
import {
  errorHandler,
  formatEmail,
  formatMobile,
  getParameterByName,
  getParametersBySubstring,
  getUrlparamsAsObject,
  isAdmin,
  isEmailOtp,
  isSuperAdmin,
  notify,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
  setUserData,
} from '../../../utils/helper';
import { Mixpanel } from '../../../utils/tracking';
import InlineError from '../../InlineError';
import InfoModal from '../common/InfoModal';
import RecaptchaButton from '../common/RecaptchaButton';
import CreatePassCode from './CreatePassCode';
import SetPhoneSuccess from './SetPhoneSuccess';
import UpdatePhoneNumber from './UpdatePhoneNumber';
import './_login-register.scss';

interface Props {
  close?: () => void;
  username: string;
  intent: string;
  data?: any;
  openUser?: any;
  phone?: string;
  emailOtp?: string;
  updatePhone?: boolean;
  resetPhone?: boolean;
  username_type?: string;
  email?: string;
  emailLogin?: boolean;
}

interface Errors {
  otp?: string;
}

interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

interface ErrorResponse {
  response: any;
}

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const OTP_LIMIT = 4,
  OTP_REQUEST_LIMIT = 20;

const Otp: React.FC<Props> = ({
  close,
  username,
  intent,
  openUser,
  phone,
  emailOtp,
  updatePhone,
  resetPhone,
  username_type,
  email,
  emailLogin,
}) => {
  const dispatch = useDispatch(),
    history = useHistory(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    [otp, setOtp] = useState(''),
    user = useSelector((state: RootState) => state.user),
    affiliate = useSelector((state: RootState) => state.affiliate),
    [loading, setLoading] = useState(false),
    [disableBtn, setDisableBtn] = useState(true),
    [userName, setUserName] = useState(emailOtp),
    [timerDate, setTimerDate] = useState(Date.now() + 600000),
    isIg = getParameterByName('ig') === 'true',
    isDirectAgent = getParameterByName('direct_agent') === 'true',
    isBusinessManager = getParameterByName('business_manager') === 'true',
    [errors, setErrors] = useState<Errors>({}),
    trackEvent = (window as { [key: string]: any })['trackEvent'] as any,
    is_email_otp = isEmailOtp(),
    [emailOtpFlag, setEmailOtp] = useState<any>(emailOtp),
    captchaSiteKey = RECAPTCHA_SITEKEY,
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    });

  let path = '/signup',
    params = window.location.search;

  if (params) {
    path = `${path}${params}`;
  }

  useEffect(() => {
    if (user.id && intent !== 'update_phone' && intent !== 'reset_pin') {
      trackEvent.register({
        Name: user.fullname || '',
        Identity: user.id,
      });
      if (user.affiliates.length > 1) {
        if (openUser && !openUser.is_pin_set) {
          dispatch(clearModal());
          openCreatePasscode();
        } else {
          if (resetPhone) {
            user.affiliates.forEach((affiliate: { account_type: string }) => {
              if (affiliate.account_type === 'mlm_agent') {
                setAffiliateProfile();
              }
            });
          } else redirectToWelcome();
        }
      } else {
        setAffiliateProfile();
      }
    }
  }, [user]);

  useEffect(() => {
    if (affiliate.id) {
      let userObj = {
        email: affiliate.user.email,
        id: affiliate.user.id,
        agent_code: affiliate.agent_code,
      };
      if (affiliate.is_signup_complete || (isEmailOtp() && affiliate.has_broker_license !== null)) {
        let userProperty = {};
        userProperty = {
          ...userObj,
          isAdmin: isAdmin() || isSuperAdmin() || '',
        };

        if (affiliate.account_type) {
          userProperty = { ...userProperty, accountType: affiliate.account_type };
        }
        trackEvent.userProperty(userProperty);
        history.push('/buy-insurance');
      } else {
        history.push(path);
      }
      if (
        openUser &&
        openUser.is_pin_set === false &&
        openUser.accounts &&
        openUser.accounts.length &&
        intent === 'login' &&
        (affiliate.is_signup_complete || (isEmailOtp() && affiliate.has_broker_license !== null))
      ) {
        dispatch(clearModal());
        openCreatePasscode();
      } else if (intent !== 'update_phone' && intent !== 'reset_pin') {
        dispatch(clearModal());
      }
      if (updatePhone) {
        openUpdatePhone();
      }
    }
  }, [affiliate]);

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

  function redirectToWelcome() {
    dispatch(clearModal());
    let params = window.location.search;
    history.push(`/welcome${params}`);
  }

  function openUpdateSuccessful() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <SetPhoneSuccess />,
        uid: 'UPDATE_SUCCESSFUL',
      })
    );
  }

  function openCreatePasscode() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <CreatePassCode intent={intent} username={username} otp={otp} emailLogin={emailLogin} />,
        uid: 'CREATE_PASSCODE',
      })
    );
  }

  function openInfoModal(title: string, text: string, action: any) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} action={action} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function openUpdatePhone() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <UpdatePhoneNumber />,
        uid: 'UPDATE_PHONE_NUMBER',
        className: 'full-screen',
      })
    );
  }

  function handleInputChange(value: string) {
    setOtp(value);
    if (value && value.length === OTP_LIMIT) {
      setDisableBtn(false);
    }
  }

  function validate() {
    let errors = {
      otp: '',
    };

    if (!otp || otp.length < OTP_LIMIT) {
      errors.otp = languageMap[`Invalid OTP`];
    }

    return errors;
  }

  function setAffiliateProfile() {
    dispatch(affiliateProfile());
  }

  function getUserProfile() {
    dispatch(userProfile(isIg || isDirectAgent || isBusinessManager));
  }

  function patchUser() {
    api.user.patchProfile({ phone }).catch((error) => {
      errorHandler(error);
    });
  }

  function handleOtpLogin(headers?: any) {
    let params: any = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      username, // user mobile number
      password: 'password', // dummy password, mandatory for the flow
      otp,
      is_mlm: false,
      is_inspection_garage: false,
      is_direct_agent: false,
      is_business_manager: false,
      username_type: emailLogin ? 'email' : username_type ? username_type : 'phone',
      is_email_otp: emailOtpFlag === true ? true : is_email_otp,
    };
    if (!(isIg || isDirectAgent || isBusinessManager)) {
      params.is_mlm = true;
    } else if (isIg) {
      params.is_inspection_garage = true;
    } else if (isBusinessManager) {
      params.is_business_manager = true;
    } else {
      params.is_direct_agent = true;
    }
    if (emailOtp && !validator.isNumeric(emailOtp)) {
      params.is_email_otp = true;
    }

    setLoading(true);
    api.user
      .login(params, headers)
      .then((response: LoginResponse) => {
        setAxiosHeaders(response.access_token);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setUserData({ accessToken: response.access_token });
        Mixpanel.track('Login/signup', {
          object: 'Login',
          objectContext: {
            parent: 'Otp Modal',
          },
          action: 'Successful',
        });
        getUserProfile();
        setLoading(false);
      })
      .catch((error: ErrorResponse) => {
        setLoading(false);
        setDisableBtn(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_otp' &&
          OTP_REQUEST_LIMIT - error.response.data.remaining_attempts <= 2
        ) {
          openInfoModal(languageMap[`Wrong OTP entered`], languageMap[`Please enter correct OTP.`], () => setOtp(''));
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_otp' &&
          OTP_REQUEST_LIMIT - error.response.data.remaining_attempts > 2
        ) {
          openInfoModal(
            languageMap[`New OTP generated`],
            languageMap[`For your account security, we have generated a new OTP.`],
            () => setOtp('')
          );
          requestOtp(headers, is_email_otp, true);
        } else if (error.response && error.response.data && error.response.data.detail) {
          openInfoModal(
            languageMap[``],
            languageMap[`Your account has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`],
            () => setOtp('')
          );
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function handleOtpSignup() {
    let params: any = {
        client_id: CLIENT_ID,
        username: is_email_otp && email ? email : username,
        otp,
        is_mlm: false,
        is_inspection_garage: false,
        is_direct_agent: false,
        is_business_manager: false,
        tags: [],
        username_type: emailLogin || is_email_otp ? 'email' : 'phone',
      },
      urlParams: any = getUrlparamsAsObject() || {},
      tag = urlParams['tag'],
      tags: any = getParametersBySubstring('utm_') || [];

    if (params.username_type === 'email') {
      params.phone = phone;
    }

    if (tag) {
      tag = tag.constructor === Array ? tag : [tag];
      tags = tags.concat(tag);
    }

    if (tags.length) {
      params.tags = tags;
    }

    if (params.username && params.username.length === 10 && params.username[0] === '0') {
      params.username = params.username.substring(1);
    }

    if (!(isIg || isDirectAgent || isBusinessManager)) {
      params.is_mlm = true;
    } else if (isIg) {
      params.is_inspection_garage = true;
    } else if (isBusinessManager) {
      params.is_business_manager = true;
    } else {
      params.is_direct_agent = true;
    }

    setLoading(true);
    api.user
      .otpSignup(params)
      .then((response: LoginResponse) => {
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setUserData({ accessToken: response.access_token });
        setAxiosHeaders(response.access_token);
        Mixpanel.track('Login/signup', {
          object: 'Signup',
          objectContext: {
            parent: 'Otp Modal',
          },
          action: 'Successful',
        });
        getUserProfile();
        setLoading(false);

        if (params.username_type === 'email') {
          patchUser();
        }
      })
      .catch((error: ErrorResponse) => {
        setLoading(false);
        setDisableBtn(false);
        if (error.response && error.response.data && error.response.data.phone) {
          notify('Phone number already exists', 'error');
        } else if (error.response && error.response.data && error.response.data.otp) {
          openInfoModal(languageMap[`Wrong OTP entered`], languageMap[`Please enter correct OTP.`], () => setOtp(''));
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function requestOtp(headers?: any, isEmailOtp: boolean = is_email_otp, resend: boolean = false) {
    console.log('is_email_otp', is_email_otp);
    let params: any = {
      username,
      username_type: emailLogin ? 'email' : 'phone',
      intent,
      is_email_otp: emailLogin ? 'true' : isEmailOtp,
      resend_new_otp: resend,
    };
    if ((!isIg && !isDirectAgent) || isBusinessManager) {
      params.is_mlm = true;
    }
    setLoading(true);
    api.user
      .requestOtp(params, headers)
      .then((response) => {
        setUserName(response.username);
        notify(
          isEmailOtp
            ? languageMap[`An OTP code has been sent to your email`]
            : languageMap[`An OTP code has been sent to your mobile number`],
          'success'
        );
        setTimerDate(Date.now() + 600000);
        setOtp('');
        setErrors({});
        setLoading(false);
      })
      .catch((error: ErrorResponse) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_pin' &&
          error.response.data.remaining_attempts > 0
        ) {
          openInfoModal(languageMap[`Incorrect PIN`], languageMap[`Please check and enter correct PIN.`], '');
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_pin' &&
          error.response.data.remaining_attempts === 0
        ) {
          openInfoModal(
            languageMap[`Multiple failed PIN attempts`],
            languageMap[`Your account has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`],
            ''
          );
        } else if (error.response && error.response.data && error.response.data.detail) {
          openInfoModal(
            languageMap[`Multiple failed OTP request`],
            languageMap[`Request OTP has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`],
            ''
          );
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function resetPin() {
    openCreatePasscode();
  }

  function verifyPhone() {
    let params = {
      username,
      otp,
    };
    api.user
      .verifyPhoneViaOtp(params)
      .then(() => {
        dispatch(clearModal());
        openUpdateSuccessful();
      })
      .catch((error: ErrorResponse) => {
        if (error.response && error.response.data && error.response.data.phone) {
          notify('Phone number already exists', 'error');
          return;
        } else if (error.response && error.response.data && error.response.data.otp) {
          setErrors({
            otp: languageMap[`Invalid OTP`],
          });
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function handleNext(headers?: any) {
    let tempErrors = validate();
    setErrors(tempErrors);
    console.log('calling');

    if (errors.otp) {
      return;
    }

    if (intent === 'update_phone') {
      verifyPhone();
      return;
    }

    if (intent === 'reset_pin') {
      resetPin();
      return;
    }

    if (intent === 'login') {
      handleOtpLogin(headers);
    } else {
      handleOtpSignup();
    }
  }

  async function createPinEmail(headers?: any) {
    await setEmailOtp(true);
    requestOtp(headers, true, false);
  }

  function renderer(data: any) {
    if (data.completed) {
      return <span>Your code has become invalid. Please click Resend OTP </span>;
    }
    return (
      <span>
        {languageMap[`OTP will be valid for`]} {data.minutes}:{data.seconds} {languageMap[`Minutes`]}.
      </span>
    );
  }

  function renderUserName() {
    let user_name = '';
    if (userName) {
      if (validator.isNumeric(userName)) {
        user_name = formatMobile(userName);
      } else {
        user_name = formatEmail(userName);
      }
    } else {
      if (validator.isNumeric(username)) {
        user_name = formatMobile(username);
      } else {
        user_name = formatEmail(username);
      }
    }
    if ((intent === 'signup' || intent === 'login') && is_email_otp && email) {
      user_name = formatEmail(email);
    }
    return <span className="bold">{user_name}</span>;
  }

  function resendOtp(headers?: any) {
    requestOtp(headers, is_email_otp, false);
    setEmailOtp(false);
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps: any) {
    let headers = {
      headers: {
        'G-Recaptcha-Response': captchaProps.token,
      },
    };

    if (captchaProps.btnName === 'input_otp') {
      handleNext(headers);
    } else if (captchaProps.btnName === 'resend_otp') {
      resendOtp(headers);
    } else if (captchaProps.btnName === 'create_pin_email') {
      createPinEmail(headers);
    }
  }

  function generateRecaptchaButton() {
    return (
      <>
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="button"
            name="input_otp"
            label={languageMap['Next']}
            loading={loading}
            disableBtn={disableBtn}
          />
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="text"
            name="resend_otp"
            preTxt={languageMap[`Resend OTP`]}
            label={languageMap[`Click here`]}
          />

          {openUser?.is_pin_set === false ? (
            <>
              <Divider>{languageMap[`or`]}</Divider>
              <RecaptchaButton
                action={getCaptchaToken}
                btnType="text"
                name="create_pin_email"
                label={languageMap[`Create PIN via email id`]}
              />
            </>
          ) : null}
        </GoogleReCaptchaProvider>
      </>
    );
  }
  function generateNormalButton() {
    return (
      <>
        <div className="buttons-wrapper">
          <Button className={'orange-button ' + (loading || disableBtn ? 'disabled' : '')} onClick={handleNext}>
            {languageMap[`Next`]} without captcha
          </Button>
        </div>
        <div className="link-wrap">
          <span className="link">
            {languageMap[`Didn't get the code?`]}
            <span
              onClick={() => {
                requestOtp(is_email_otp, false);
                setEmailOtp(false);
              }}
            >
              {' '}
              {languageMap[`Resend OTP`]}
            </span>
          </span>{' '}
        </div>
        {openUser?.is_pin_set === false ? (
          <>
            <Divider>{languageMap[`or`]}</Divider>
            <div className="link-wrap">
              <span className="link">
                <span onClick={createPinEmail}> {languageMap[`Create PIN via email id`]}</span>
              </span>{' '}
            </div>
          </>
        ) : null}
      </>
    );
  }

  function generateButton():any {
    if (!systemSettings.should_validate_recaptcha) {
      return generateNormalButton();
    } else {
      return generateRecaptchaButton();
    }
  }

  return (
    <div className="mobile-layout login-register otp">
      <div className="header">
        <div className="back" onClick={close}>
          <img src={BackArrow} alt="back_arrow" />
        </div>
        <p className="text">{languageMap[`Verification`]} </p>
      </div>
      <div className="content-wrapper">
        <div className="otp-img-wrap">
          <img src={OtpBanner} alt="otp-screen" />
        </div>
        <div className="text-wrapper">
          <h2 className="title-text">{languageMap[`Enter OTP`]}</h2>
          <p className="sub-text">
            {languageMap[`Enter OTP sent to`]} {renderUserName()}{' '}
          </p>
          <p className="sub-text">
            <Countdown date={timerDate} renderer={renderer} />
          </p>
        </div>
        <div className={'input-wrapper ' + (errors.otp ? 'error' : '')}>
          <OtpInput
            value={otp}
            onChange={handleInputChange}
            numInputs={OTP_LIMIT}
            shouldAutoFocus={true}
            isInputNum={true}
            inputStyle={{ padding: 0, width: '30px', borderRadius: '5px' }}
            isInputSecure={true}
          />
          {errors.otp ? <InlineError text={errors.otp} className="" /> : null}
        </div>
        {generateButton()}
      </div>
    </div>
  );
};

export default Otp;
