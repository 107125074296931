import React from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import OrderStatusUpdateForm from '../../components/non-motor-orders/OrderStatusUpdateForm';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

function NonMotorOrderStatusModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.eventForm);

  function close() {
    props.hideModal(null, { ...props });
  }

  return (
    <div className="fairdee-modal-wrapper customer-info remark">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">Edit Status</span>
          </p>
        </div>
        <h1 className="title-text">Edit Status</h1>
        <OrderStatusUpdateForm id={props.id} data={props.orderInfo} />
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showNotification })(NonMotorOrderStatusModal);
