import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

function CovidCancellationModal(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const cancellationLangMap = languageMap.components.coronaCancellationModal;
  const [fileList, setFileList] = useState({});
  const [errors, setErrors] = useState({});
  const [updatedData, setUpdatedData] = useState(false);
  const [fileListLoading, setFileListLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  // const languageMap = this.props.languageMap.components.remarksModal;
  function close() {
    props.hideModal(null, { ...props });
  }

  useEffect(() => {
    if (props.data) {
      if (props.data.documents && props.data.documents.length) {
        let files = {};
        props.data.documents.forEach((data) => {
          if (!files[data.document_type]) {
            files[data.document_type] = [];
          }
          data.file.name = data.file.original_file_name;
          files[data.document_type].push(data);
        });
        setFileList(files);
      }
      setMetadata(props.data.metadata || {});
    }
  }, [props]);

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function confirmDelete(data) {
    props.showModal(CONFIRMATION_MODAL, {
      action: deleteDocumentApiCall,
      data,
    });
  }

  function deleteDocumentApiCall(data) {
    const messages = languageMap.messages;
    api.utils
      .deleteCovidDocument(data.id)
      .then((resp) => {
        window.console.log(resp);
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        setFileList({ ...fileList });
        setUpdatedData(true);
        notify(messages.deletedSuccessfully, 'success');
      })
      .catch((error) => {
        notify('Something went wrong', 'error');
      });
  }

  function fileUploaded(file, name) {
    let promises = [];
    fileListLoading[name] = true;
    setFileListLoading(fileListLoading);

    if (Array.isArray(file)) {
      file.forEach((f) => {
        let params = new FormData();
        params.append('covid_form_id', parseInt(props.data.id, 10));
        params.append('document_type', name);
        params.append('file', f);
        promises.push(uploadFileApiCall(params));
      });
    } else {
      let params = new FormData();
      params.append('covid_form_id', parseInt(props.data.id, 10));
      params.append('document_type', name);
      params.append('file', file);
      promises.push(uploadFileApiCall(params));
    }

    Promise.all(promises)
      .then((response) => {
        fileListLoading[name] = false;
        response.forEach((resp, index) => {
          window.console.log(resp, file);
          if (Array.isArray(file)) {
            file[index].id = resp.message.id;
            file[index].document_type = name;
          } else {
            file.id = resp.message.id;
            file.document_type = name;
          }

          if (!fileList[name]) {
            fileList[name] = [file[index] || file];
          } else {
            fileList[name].push(file[index] || file);
          }
        });
        setFileListLoading({ ...fileListLoading });
        setFileList({ ...fileList });
        setUpdatedData(true);
        props.loadCovidForm();
      })
      .catch((error) => {
        window.console.log(error);
        setLoading(true);
      });
  }

  function deleteDocument(data) {
    confirmDelete(data);
  }

  function validate() {
    let messages = languageMap.messages;
    let errors = {};
    let requiredMetaFields = ['account_number', 'bank_name', 'cancellation_reason'];

    if (!props.is_dengue_pa) {
      if (!fileList || !fileList.national_id_with_signature || !fileList.national_id_with_signature.length) {
        errors.national_id_with_signature = messages.required;
      }
      if (!fileList || !fileList.previous_payment_evidence || !fileList.previous_payment_evidence.length) {
        errors.previous_payment_evidence = messages.required;
      }
    } else {
      requiredMetaFields = ['cancellation_reason'];
    }
    if (!fileList || !fileList.payment_fee_proof || !fileList.payment_fee_proof.length) {
      errors.payment_fee_proof = messages.required;
    }

    requiredMetaFields.forEach((field) => {
      if (!metadata[field]) {
        errors[field] = messages.required;
      }
    });
    setErrors(errors);
    return errors;
  }

  function uploadFileApiCall(params) {
    return api.utils.covidDocumentUpload(params);
  }

  function submit() {
    const errors = validate();
    if (Object.keys(errors).length) {
      return;
    }
    props.showModal(CONFIRMATION_MODAL, {
      action: () => {
        props.action && props.action(metadata);
        close();
      },
      text: `Do you want to ${
        props.type === 'cancellation_requested' ? 'Request for Cancellation' : 'Request for Endorsement'
      }?`,
    });
  }

  function handleChange(name, event) {
    const value = event.target.value;
    setMetadata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div className="fairdee-modal-wrapper customer-info remark">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">{cancellationLangMap.cancellationRequested}</span>
          </p>
        </div>
        <h1 className="title-text">{cancellationLangMap.cancellationRequested}</h1>
        <div className="form-field">
          <label htmlFor="cancellation_reason">{cancellationLangMap.cancellationReason}</label>
          <input
            type="text"
            id="cancellation_reason"
            name="cancellation_reason"
            onChange={handleChange.bind(null, 'cancellation_reason')}
            value={metadata.cancellation_reason}
            disabled={props.blockUpdate}
          />
          {errors.cancellation_reason && <InlineError text={errors.cancellation_reason} />}
        </div>
        {!props.is_dengue_pa && (
          <>
            <div className="form-field">
              <label htmlFor="account_number">{cancellationLangMap.accountNumber}</label>
              <input
                type="text"
                id="account_number"
                name="account_number"
                onChange={handleChange.bind(null, 'account_number')}
                value={metadata.account_number}
                disabled={props.blockUpdate}
              />
              {errors.account_number && <InlineError text={errors.account_number} />}
            </div>
            <div className="form-field">
              <label htmlFor="bank_name">{cancellationLangMap.bankName}</label>
              <input
                type="text"
                id="bank_name"
                name="bank_name"
                onChange={handleChange.bind(null, 'bank_name')}
                value={metadata.bank_name}
                disabled={props.blockUpdate}
              />
              {errors.bank_name && <InlineError text={errors.bank_name} />}
            </div>
            <div className="file-upload-wrap">
              <p className="sub-title">{cancellationLangMap.nationalIdDoc}</p>
              <FileUpload
                download={true}
                files={fileList['national_id_with_signature']}
                loading={fileListLoading['national_id_with_signature']}
                name="national_id_with_signature"
                onFileUpload={fileUploaded}
                onFileRemove={deleteDocument}
                hideUpload={props.blockUpdate}
                disableDelete={props.blockUpdate}
                downloadPath={apiEndpoints.covidDocument}
                limit={2}
                multiple={true}
                accept="image/jpeg,image/png,application/pdf"
              />
              {errors.national_id_with_signature && <InlineError text={errors.national_id_with_signature} />}
            </div>
            <div className="file-upload-wrap">
              <p className="sub-title">{cancellationLangMap.paymentEvidence}</p>
              <FileUpload
                download={true}
                files={fileList['previous_payment_evidence']}
                loading={fileListLoading['previous_payment_evidence']}
                name="previous_payment_evidence"
                onFileUpload={fileUploaded}
                onFileRemove={deleteDocument}
                downloadPath={apiEndpoints.covidDocument}
                limit={2}
                hideUpload={props.blockUpdate}
                disableDelete={props.blockUpdate}
                multiple={true}
                accept="image/jpeg,image/png,application/pdf"
              />
              {errors.previous_payment_evidence && <InlineError text={errors.previous_payment_evidence} />}
            </div>
          </>
        )}
        <div className="file-upload-wrap">
          <p className="sub-title">{cancellationLangMap.paymentFeeDocument}</p>
          <FileUpload
            download={true}
            files={fileList['payment_fee_proof']}
            loading={fileListLoading['payment_fee_proof']}
            name="payment_fee_proof"
            onFileUpload={fileUploaded}
            onFileRemove={deleteDocument}
            downloadPath={apiEndpoints.covidDocument}
            limit={2}
            multiple={true}
            hideUpload={props.blockUpdate}
            disableDelete={props.blockUpdate}
            accept="image/jpeg,image/png,application/pdf"
          />
          {errors.payment_fee_proof && <InlineError text={errors.payment_fee_proof} />}
        </div>
        <div className="buttons-wrap">
          <button className="button medium orange-bordered-button" onClick={close.bind(null, true)}>
            {cancellationLangMap.cancel}
          </button>
          {!props.blockUpdate && (
            <button className="button medium" onClick={submit}>
              {cancellationLangMap.confirm}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(null, { showModal, hideModal, showNotification })(CovidCancellationModal);
