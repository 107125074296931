import SkipNextIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousIcon from '@material-ui/icons/SkipPreviousRounded';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import CoronaCard from '../../components/corona/CoronaCard';
import CoronaCardMobile from '../../components/corona/CoronaCardMobile';
import CoronaFormCard from '../../components/CoronaFormCard';
import DropdownDatepicker from '../../components/datepicker/DropdownDatepicker';
import InlineError from '../../components/InlineError';
import { MODAL_TYPE_AFFILIATE_INFO, NOTIFICATION } from '../../constants/types';
import CoronaPrices from '../../images/corona/plans_comparison.jpeg';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import {
  errorHandler,
  getParameterByName,
  isAgent,
  isMobileDevice,
  thaiToDefaultDate,
  toThaiDate,
  validateThaiNationalId,
} from '../../utils/helper';

const steps = ['showFlow', 'showRenewal', 'showCards', 'showConfirmation'];

const documents = {
  smk: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/covid-form/smk-covid-application-new.pdf',
  tip: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/covid-form/tip-covid-application-new.pdf',
};

function CoronaRenewalCardsModal(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const messages = languageMap.messages;
  const coronaLangMap = languageMap.components.coronaModal;
  const [plans, setPlans] = useState([]);
  const [displayablePlans, setDisplayablePlans] = useState([]);
  const [step, setStep] = useState('showFlow');
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [covidForms, setCovidForms] = useState(null);
  const [selectedForm, setSelectedForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [renewalInsruers, setRenewalInsurers] = useState([]);

  useEffect(() => {
    if (step === 'showFlow') {
      getAndProcessCovidPlans();
    }
  }, [step]);

  useEffect(() => {
    let filteredPlans = plans.filter((plan) => {
      if (plan.insurer.code === selectedForm.plan.insurer.code) {
        if (plan.insurer.code === 'tip') {
          return plan.plan_name == selectedForm.plan.plan_name;
        }
        return plan.insurer.code === selectedForm.plan.insurer.code;
      }
    });
    setDisplayablePlans(filteredPlans);
  }, [selectedForm]);

  function close() {
    props.hideModal(null, { ...props });
  }

  function openAffiliateEditModal(affiliate, nextFn = null) {
    props.showModal(MODAL_TYPE_AFFILIATE_INFO, {
      // action: this.getAffiliate
      hideAffiliateForm: true,
      hidePasswordForm: true,
      currentTab: 'two',
      bankDetailAddress: true,
      data: affiliate,
      nextFn: nextFn,
    });
  }

  function hasValidData(affiliate) {
    if (!affiliate.bank_details || !affiliate.bank_details.length) {
      return false;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      let bankDetails = affiliate.bank_details[0];
      if (!bankDetails || !bankDetails.ac_number || !bankDetails.bank_name) {
        return false;
      }
    }
    if (!affiliate.documents || !affiliate.documents.length) {
      return false;
    }
    if (affiliate.documents && affiliate.documents.length) {
      let obj = {};
      affiliate.documents.forEach((data) => {
        obj[data.document_type] = true;
      });
      if (!obj.broker_license) {
        return false;
      }
    }
    if (!affiliate.address) {
      return false;
    }
    if (
      affiliate.address &&
      (!affiliate.address.first_line ||
        !affiliate.address.district ||
        !affiliate.address.province ||
        !affiliate.address.postal_code)
    ) {
      return false;
    }

    if (!affiliate.address_nid) {
      return false;
    }
    if (
      !affiliate.address_nid ||
      !affiliate.address_nid.first_line ||
      !affiliate.address_nid.district ||
      !affiliate.address_nid.province ||
      !affiliate.address_nid.postal_code
    ) {
      return false;
    }

    if (!affiliate.national_id || !affiliate.broker_license_number) {
      return false;
    }
    return true;
  }

  function checkAndOpenLicenseModal(nextFn, affiliate = undefined) {
    //ToDo check if the flag is right
    if (!affiliate) {
      affiliate = props.affiliate;
    }
    if (isAgent() && !hasValidData(affiliate)) {
      openAffiliateEditModal(affiliate, (aff = undefined) => checkAndOpenLicenseModal(nextFn, aff));
      return true;
    } else if (props.user.is_banned) {
      notify(messages.bannedUser, 'error');
      return true;
    } else {
      nextFn();
      return false;
    }
  }

  function getAndProcessCovidPlans() {
    api.masterData.getInsurers().then((res) => {
      const eligbleInsurers = res.results
        .filter((insurer) => ['tip'].includes(insurer.code))
        .map((insurer) => insurer.id);
      setRenewalInsurers(eligbleInsurers);
      api.masterData
        .getCovidPlans({
          insurer: eligbleInsurers,
          for_renewal: true,
        })
        .then((res) => {
          setPlans(res.sort((res1, res2) => res1.premium_after_tax - res2.premium_after_tax));
        })
        .catch((err) => {
          errorHandler(err);
        });
    });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message,
      type,
      autoClose: true,
    });
  }

  function downloadPdf(comparisionCard = false) {
    let link = document.createElement('a');
    let name = '';
    // link.href = generateUrl();
    if (comparisionCard) {
      link.href = CoronaPrices;
      name = 'plans_comparision';
    } else if (selectedPrice) {
      link.href = documents[selectedPrice.insurer.code];
      name = `${selectedPrice.insurer.code.toUpperCase()}.pdf`;
    }
    link.textContent = 'download';
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function gotoWizard() {
    let selectedAffiliate = getParameterByName('affiliate');
    let params = {
      is_disclaimer_accepted: true,
      plan: selectedPrice.id,
      cf_id: selectedForm.id,
    };
    let promise = null;

    api.utils
      .renewCovidForm(params, selectedAffiliate ? parseInt(selectedAffiliate) : null)
      .then((res) => {
        let search = `?id=${res.id}`;
        if (selectedAffiliate && !isNaN(selectedAffiliate)) {
          search += '&affiliate=' + selectedAffiliate;
        }
        let pathname = '/corona-wizard';
        close();
        props.history.push({ pathname, search });
      })
      .catch((err) => {});
  }

  function previous() {
    setStep(steps[steps.indexOf(step) - 1]);
  }

  function nextFunction(priceList) {
    if (priceList && priceList.insurer) {
      setSelectedPrice(priceList);
    }

    setStep(steps[steps.indexOf(step) + 1]);
  }

  function handleChange(name, event) {
    window.console.log(name);
    if (!event) {
      // Happening in case of date picker.
      return;
    }
    let value = null;
    value = event.target.value;
    window.console.log(value);
    setData({ ...data, nationalId: value });
  }

  function disablePrev() {
    return steps.indexOf(step) === 0;
  }

  function disableNext() {
    return (
      (step === 'showCards' && !selectedPrice) ||
      (step === 'showRenewal' && !selectedForm.id) ||
      steps.indexOf(step) === steps.length - 1
    );
  }

  function fetchCovidForms() {
    const validationErrors = {};
    let now = moment();
    setLoading(true);
    if (!data.nationalId) {
      validationErrors.nationalId = messages.required;
    } else if (!validateThaiNationalId(data.nationalId)) {
      validationErrors.nationalId = messages.invalidFormat;
    }

    if (!data.dob) {
      validationErrors.dob = {
        year: messages.required,
        month: messages.required,
        day: messages.required,
      };
    }

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    } else {
      setErrors({});
    }

    let params = {
      national_id: data.nationalId,
      dob: data.dob,
      payment_status: ['payment_proof_received', 'payment_verified', 'commission_transferred'],
      insurer: renewalInsruers,
      policy_start_date__lt: now.clone().subtract(10, 'months').format('YYYY-MM-DD'),
      policy_start_date__gt: now.clone().subtract(1, 'years').format('YYYY-MM-DD'),
    };

    if (getParameterByName('affiliate')) {
      params.affiliate = getParameterByName('affiliate');
    }

    api.utils
      .getCovidForms(undefined, params)
      .then((res) => {
        setLoading(false);
        //Only allow 10 month or older sales to be renewed
        let forms = res.results.filter((form) => now.diff(form.policy_start_date, 'days') > 300);
        setCovidForms(forms);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err.response, true);
      });
  }

  function onDobChange(date) {
    date = thaiToDefaultDate(date);
    if (date !== data.dob) {
      setData({
        ...data,
        dob: date,
      });
    }
  }

  function renew(row) {
    setSelectedForm(row);
    nextFunction();
  }

  function gotoCovid() {
    let pathname = '/buy-insurance';
    let search = '?type=corona-insurance';
    props.history.push({ pathname, search });
  }

  return (
    <div
      className={'fairdee-modal-wrapper customer-info corona-cards' + (step === 'showFlow' ? ' medium' : ' x-large')}
    >
      <div className={'modal-content'}>
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">
              {step === 'showFlow' ? coronaLangMap.processOfInsurance : coronaLangMap.buyCoronaInsurance}
            </span>
          </p>
        </div>
        <h1 className="title-text">
          {step === 'showFlow' ? coronaLangMap.processOfInsurance : coronaLangMap.buyCoronaInsurance}
        </h1>
        <div className={`conditional-content${step === 'showRenewal' ? ' flex' : ''}`}>
          {step === 'showFlow' && <div className="flow-steps flow-steps-covid-renewal"></div>}
          {step === 'showCards' && (
            <>
              <div className="download-button">
                <button
                  className="button medium"
                  onClick={() => checkAndOpenLicenseModal(downloadPdf.bind(null, true))}
                >
                  {coronaLangMap.downloadComparisonSheet}
                </button>
              </div>
              <div className="cards-holder">
                {displayablePlans.map((plan) =>
                  !isMobileDevice() ? (
                    <CoronaCard data={plan} next={nextFunction} />
                  ) : (
                    <CoronaCardMobile data={plan} next={nextFunction} />
                  )
                )}
              </div>
            </>
          )}
          {step === 'showRenewal' && (
            <div className="confirmation-step min-360-wide">
              <p className="text">{coronaLangMap.renewOldPolicy}</p>
              <div className="custom-input-container">
                <div className={'form-field' + (errors.nationalId ? ' errors' : ' ')}>
                  <label htmlFor="" className="form-label">
                    {coronaLangMap.nationalId}*
                  </label>
                  {errors.nationalId && <InlineError text={errors.nationalId} />}
                  <input
                    type="text"
                    placeholder={`${coronaLangMap.nationalId}*`}
                    onChange={(e) => handleChange('national_id', e)}
                    value={data.nationalId || ''}
                  />
                </div>
                <div className="custom-datepicker margin-bottom-24">
                  <div className="form-label">{coronaLangMap.dob}</div>
                  <DropdownDatepicker
                    selectedDate={data.dob ? toThaiDate(data.dob) : null}
                    order={['day', 'month', 'year']}
                    onDateChange={onDobChange}
                    format="YYYY-MM-DD"
                    isThaiDate={true}
                    errors={errors.dob}
                  />
                </div>
              </div>
              <div className="button-wrap">
                <button className="button medium orange-bordered-button" onClick={() => fetchCovidForms()}>
                  ค้นหาแผนประกันภัยโควิด-19 ที่ครบกำหนดการต่ออายุ
                </button>
              </div>
              {covidForms &&
                covidForms.map((row, index) => {
                  return (
                    <CoronaFormCard
                      key={index}
                      data={row}
                      disableDownloadBtn={() => true}
                      isRenewal={true}
                      action={() => renew(row)}
                    />
                  );
                })}
              {covidForms && covidForms.length === 0 && (
                <div className="button-wrap not-found">
                  <p>ข้อมูลไม่ถูกต้อง กรุณากรอกข้อมูลใหม่อีกครั้ง</p>
                  <p>Change Search Parameters.</p>
                  <p className="bold">or</p>
                  <div>
                    <button className="button medium primary" onClick={() => gotoCovid()}>
                      ซื้อแผนประกันภัยโควิด-19
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {step === 'showConfirmation' && (
            <div className="confirmation-step">
              {selectedForm && selectedForm.id ? (
                <p className="important-text">{coronaLangMap.proceedToRenewal}</p>
              ) : (
                <p className="important-text">{coronaLangMap.proceed}</p>
              )}
              <div className="button-wrap">
                <div>
                  <p className="text">{coronaLangMap.iHearbyDeclare}</p>
                  {selectedPrice &&
                    selectedPrice.metadata &&
                    selectedPrice.metadata.declarations &&
                    selectedPrice.metadata.declarations.map((declaration, index) => (
                      <div>
                        <p className="text">{`${index + 1}. ${declaration}`}</p>
                      </div>
                    ))}
                </div>
                <button className="button medium" onClick={() => checkAndOpenLicenseModal(gotoWizard)}>
                  {coronaLangMap.proceedToBuy}
                </button>
              </div>
              <div className="button-wrap not-found">
                <p className="text">{coronaLangMap.or}</p>
                <button
                  className="button medium orange-bordered-button"
                  onClick={() => checkAndOpenLicenseModal(downloadPdf.bind(null, false))}
                >
                  {coronaLangMap.downloadApplication}
                </button>
                {selectedPrice.insurer.code === 'tip' && (
                  <p className="text">
                    เปลี่ยนใบคำขอ (เพิ่มระยะรอคอย 14 วัน) กรุณาใช้ใบคำขอใหม่เท่านั้น
                    มิเช่นนั้นเราอาจจะยกเลิกกรมธรรม์ทุกกรณี
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="prev-next">
          <div>
            {!disablePrev() && (
              <button className="button medium orange-bordered-button prev" onClick={previous}>
                <SkipPreviousIcon className="vertical-align-bottom" />
                {coronaLangMap.prev}
              </button>
            )}
          </div>
          <div>
            {!disableNext() && (
              <button className="button medium orange-bordered-button next" onClick={() => nextFunction()}>
                {coronaLangMap.next}
                <SkipNextIcon className="vertical-align-bottom" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
    insurers: state.insurers,
  };
}

export default withRouter(
  connect(mapStateToProps, { showModal, hideModal, showNotification })(CoronaRenewalCardsModal)
);
