import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import { errorHandler, snakeToTitleCase, toFixedNumber } from '../../utils/helper';

class AddInvoiceDetailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      invoice_detail_amount: 0,
    };
  }

  componentDidMount() {
    if (this.props.invoice) {
      const invoice = this.props.invoice;
      if (invoice.details && invoice.details.length) {
        const detail = invoice.details.find((detail) => detail.detail_type === this.props.detailType);
        if (detail) {
          this.setState({
            invoice_detail_amount: parseFloat(detail.amount),
          });
        }
      }
    }
  }

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  validate = () => {
    let errors = {};
    let { invoice_detail_amount } = this.state;
    let messages = this.props.languageMap.messages;

    if (!invoice_detail_amount) {
      errors.invoice_detail_amount = messages.required;
    } else if (isNaN(invoice_detail_amount)) {
      errors.invoice_detail_amount = messages.invalidFormat;
    }

    this.setState({
      errors,
    });

    return errors;
  };

  addOrUpdateInvoiceDetail = () => {
    const { invoice_detail_amount } = this.state;
    let { invoice, detailType } = this.props;
    let apiCall = null;
    const messages = this.props.languageMap.messages;
    if (Object.keys(this.validate()).length > 0) {
      return;
    }

    let invoiceDetail = invoice.details.filter((detail) => {
      return detail.detail_type === detailType;
    })[0];

    let payload = {
      amount: invoice_detail_amount,
      tax: 0,
      duty: 0,
      discount: 0,
      commission: 0,
      withholding_tax: 0,
    };

    if (!invoiceDetail) {
      apiCall = api.billing.postInvoiceDetail({
        ...payload,
        amount: toFixedNumber(invoice_detail_amount, 2),
        invoice: invoice.id,
        detail_type: detailType,
        product: this.props.product || 'voluntary_insurance',
      });
    } else {
      apiCall = api.billing.patchInvoiceDetail(invoiceDetail.id, {
        ...payload,
        amount: toFixedNumber(parseFloat(invoice_detail_amount), 2),
      });
    }

    apiCall
      .then((res) => {
        this.notify(messages.updatedSuccessfully, 'success');
        this.props.action && this.props.action();
        this.close();
      })
      .catch((err) => {
        errorHandler(err.response);
      });
  };

  openConfirmationModal = () => {
    this.props.showModal(CONFIRMATION_MODAL, {
      action: () => this.addOrUpdateInvoiceDetail(),
      text: 'Are you sure to update?',
    });
  };

  handleInputChange = (e) => {
    this.setState({
      invoice_detail_amount: e.target.value,
    });
  };

  render() {
    const { invoice_detail_amount, errors } = this.state;

    return (
      <div className="fairdee-modal-wrapper file-viewer med">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <h1 className="title-text">Update {snakeToTitleCase(this.props.detailType)}</h1>
          {
            <div>
              <div>
                <div className="form-wrapper">
                  <div className="form-field">
                    <label htmlFor="amount_paid">Amount</label>
                    <Input
                      name="amount_paid"
                      onChange={(e) => this.handleInputChange(e)}
                      className={errors.invoice_detail_amount ? 'error' : ''}
                      value={invoice_detail_amount}
                    />
                    {errors.invoice_detail_amount && <InlineError text={errors.invoice_detail_amount} />}
                  </div>
                </div>
              </div>
              <div className="options-container">
                <div className="row">
                  <br />
                  <Button variant="contained" color="primary" onClick={() => this.openConfirmationModal()}>
                    Update Invoice
                  </Button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

AddInvoiceDetailModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, hideModal, showNotification })(AddInvoiceDetailModal));
