import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import validator from 'validator';
import api from '../commonjs/api';
import { PAYMENT_STATUSES, POLICY_STATUSES } from '../constants';
import { snakeToTitleCase, validateMobileNumber } from '../utils/helper';
import LoadingButton from './LoadingButton';

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: '24px',
  },
});

class SaleTrackForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        channel: '',
        broker_code: '',
      },
      errors: {},
      requestSuccess: null,
      loading: false,
      updatedData: {},
    };
  }

  componentDidMount() {
    window.console.log(this.props);
    if (this.props.saleTrack) {
      this.setState({
        data: { ...this.state.data, ...this.props.saleTrack },
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      data: { ...this.state.data, [name]: event.target.value },
      updatedData: { ...this.state.updatedData, [name]: event.target.value },
    });
  };

  validate = (data) => {
    let errors = {};

    if (!data.fullname) {
      errors.fullname = 'Required Field';
    }
    if (!data.phone) {
      errors.phone = 'Required Field';
    }
    if (data.email && !validator.isEmail(data.email)) {
      errors.email = 'Invalid Email';
    }
    if (data.phone && !validateMobileNumber(data.phone)) {
      errors.phone = 'Invalid phone number';
    }

    return errors;
  };

  patchSaletrack = () => {
    this.setState({
      loading: true,
    });
    let params = Object.assign({}, this.state.updatedData);

    api.crm
      .patchSaletrack(this.state.data.id, params)
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
      });
  };

  postSaletrack = () => {
    this.setState({
      loading: true,
    });
    let params = {
      quotation: this.props.quotation.id,
    };

    if (this.state.data.channel) {
      params.channel = this.state.data.channel;
    }

    if (this.state.data.broker_code) {
      params.broker_code = this.state.data.broker_code;
    }

    if (this.state.data.policy_status) {
      params.policy_status = this.state.data.policy_status;
    }

    if (this.state.data.payment_status) {
      params.payment_status = this.state.data.payment_status;
    }

    if (this.props.policyTypes.compulsary) {
      params.compulsory_policy = this.props.policyTypes.compulsary;
    }

    if (this.props.policyTypes.voluntary) {
      params.voluntary_policy = this.props.policyTypes.voluntary;
    }

    api.crm
      .postSaletrack(params)
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
      });
  };

  handleNext = () => {
    if (this.state.data.id) {
      if (!Object.keys(this.state.updatedData).length) {
        if (this.props.action) {
          this.props.action();
        }
        return;
      }
      this.patchSaletrack();
    } else {
      this.postSaletrack();
    }
  };

  render() {
    const { classes } = this.props;
    const { data, errors, loading, requestSuccess } = this.state;
    const languageMap = this.props.languageMap.components.saleTrackForm;
    const statusMapping = this.props.languageMap.statusMapping;

    return (
      <div className="user-form">
        <TextField
          id="channel"
          label={languageMap.textOne}
          className={classes.textField}
          value={data.channel || ''}
          error={Boolean(errors.channel)}
          helperText={errors.channel}
          autoComplete="off"
          required
          onChange={this.handleChange('channel')}
        />
        <TextField
          id="broker_code"
          label={languageMap.textTwo}
          className={classes.textField}
          value={data.broker_code || ''}
          error={Boolean(errors.broker_code)}
          helperText={errors.broker_code}
          autoComplete="off"
          required
          onChange={this.handleChange('broker_code')}
        />
        <TextField
          select
          id="policy_status"
          label={languageMap.textThree}
          value={data.policy_status || ''}
          margin="normal"
          error={errors.policy_status ? true : false}
          helperText={errors.policy_status}
          autoComplete="off"
          name="policy_status"
          onChange={this.handleChange('policy_status')}
          className="full-width"
        >
          {POLICY_STATUSES.map((status) => (
            <MenuItem key={status} value={status}>
              {snakeToTitleCase(statusMapping[status])}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          id="payment_status"
          label={languageMap.textFour}
          value={data.payment_status || ''}
          margin="normal"
          error={errors.payment_status ? true : false}
          helperText={errors.payment_status}
          autoComplete="off"
          name="payment_status"
          onChange={this.handleChange('payment_status')}
          className="full-width"
        >
          {PAYMENT_STATUSES.map((status) => (
            <MenuItem key={status} value={status}>
              {snakeToTitleCase(statusMapping[status])}
            </MenuItem>
          ))}
        </TextField>
        <div className="buttons-wrap">
          <div>
            <Button onClick={this.props.changeStep.bind(null, 3)}>{languageMap.textFive}</Button>
          </div>
          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={this.handleNext}
              is_failure={requestSuccess === false}
              is_success={requestSuccess === true}
              loading={loading}
              children={languageMap.textSix}
            />
          </div>
        </div>
      </div>
    );
  }
}

SaleTrackForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(SaleTrackForm)));
