import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, Input } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import {
  commaSeperatedNumbers,
  errorHandler,
  snakeToTitleCase,
  thaiToDefaultDateTime,
  toFixedNumber,
  toThaiDateTimeBase,
} from '../../utils/helper';

// import '../../scss/_unique-link.scss';

const MOMENT = moment().locale('th').add(543, 'years');

const tableColumns = [
  { title: 'Vehicle Number', key: 'vehicle_number', element_type: 'link' },
  { title: 'Agent Code', key: 'agent_code', element_type: 'link' },
  { title: 'Insurer', key: 'insurer_name' },
  { title: 'Due Date', key: 'due_date', element_type: 'datetime' },
  { title: 'Payable Amount', key: 'amount_payable', element_type: 'payment' },
  { title: 'Payment Status', key: 'payment_status', element_type: 'label' },
];

class FDToVendorIPModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paid_at: null,
      broker_bank_account_id: null,
      errors: {},
      bankAccounts: [],
      transactions: [],
      params: {},
      intermediatePayment: {},
      invoices: props.invoices,
      apiLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.bankAccounts.length) {
      this.formatBrokerBankAccounts(this.props.bankAccounts);
    }

    if (this.props.invoices && this.props.invoices.length) {
      this.setState({
        invoices: this.props.invoices,
      });
    }
  }

  loadIntermediatePayment = (id) => {
    this.setState({
      apiLoading: true,
    });
    api.billing
      .getIntermediatePayment(id, { with_invoices: true, invoices__with_fairdee_sales: true })
      .then((resp) => {
        const invoices = resp.invoices.map((invoice) => {
          const sale = invoice.fairdee_sales && invoice.fairdee_sales[0];
          sale.affiliate = sale.quotation.affiliate;
          sale.vehicle_number = sale.quotation.vehicle_number;
          invoice.insurer_name = sale.quotation.price_list.insurer.name;
          invoice.sale = sale;
          return invoice;
        });
        this.setState({
          intermediatePayment: resp,
          invoices: invoices,
          broker_bank_account_id: resp.broker_bank_account,
          amount_paid: resp.amount_paid,
          paid_at: resp.paid_at ? toThaiDateTimeBase(resp.paid_at) : null,
          apiLoading: false,
        });
      })
      .catch((err) => {});
  };

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  validate = () => {
    let errors = {};
    let { broker_bank_account_id, paid_at, amount_paid } = this.state;
    let messages = this.props.languageMap.messages;

    if (!broker_bank_account_id) {
      errors.broker_bank_account_id = messages.required;
    }

    if (!amount_paid) {
      errors.amount_paid = messages.required;
    } else if (isNaN(amount_paid)) {
      errors.amount_paid = messages.invalidFormat;
    } else {
      const amountToPay = toFixedNumber(this.calculateAmountToPay(), 2);
      if (toFixedNumber(amount_paid - amountToPay, 2) > 1) {
        errors.amount_paid = `${messages.lessValue} ${toFixedNumber(amountToPay + 1, 2)}`;
      } else if (toFixedNumber(amount_paid - amountToPay, 2) < -1) {
        errors.amount_paid = `${messages.moreThan} ${toFixedNumber(amountToPay - 1, 2)}`;
      }
    }

    if (!paid_at) {
      errors.paid_at = messages.required;
    }
    this.setState({
      errors,
    });
    return errors;
  };

  handleSelectChange = (e, data) => {
    window.console.log(data);

    let broker_bank_account_id = data.value;
    if (data.value === 'select') {
      broker_bank_account_id = data.value;
    }
    this.setState({
      broker_bank_account_id,
    });
  };

  handleChange = (name, event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    let paid_at = value;
    this.setState({
      paid_at,
    });
  };

  formatBrokerBankAccounts = (bankAccounts) => {
    const messages = this.props.languageMap.messages;
    bankAccounts = bankAccounts.map((data) => {
      data.text = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.name = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.value = data.id;
      return data;
    });
    bankAccounts.unshift({
      text: messages.select,
      name: messages.select,
      value: messages.select,
    });
    this.setState({
      bankAccounts,
    });
  };

  createIntermediatePayment = () => {
    const errors = this.validate();
    const messages = this.props.languageMap.messages;
    const { broker_bank_account_id, paid_at, amount_paid, invoices, intermediatePayment } = this.state;
    if (Object.keys(errors).length) {
      return;
    }

    const invoice_ids = invoices.map((invoice) => invoice.id),
      params = {
        broker_bank_account: broker_bank_account_id,
        paid_at: thaiToDefaultDateTime(paid_at),
        amount_paid,
        invoice_ids,
        payee: 'instalment_vendor',
        payer: 'fairdee',
      };

    api.billing
      .postIntermediatePayments(params)
      .then((resp) => {
        window.console.log(resp);
        this.props.action && this.props.action(resp, invoice_ids);
        this.notify(messages.createdSuccessfully, 'success');
        this.close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  };

  renderColumn = (row, column) => {
    const { item, key, element_type } = column;
    let value = '';

    if (row && row[key]) {
      value = row[key];
    }

    const renderLabel = (value, className) => <span className={`crm-label ${className}`}>{value}</span>;

    if (element_type === 'link') {
      if (key === 'agent_code' && row.agent_code) {
        return (
          <a target="_blank" href={`/crm/affiliate/${row.agent_id}`}>
            {row.agent_code}
          </a>
        );
      } else if (key === 'vehicle_number' && row.vehicle_number) {
        let pathname = '/fairdee-wizard';
        return (
          <a target="_blank" href={`/fairdee-wizard?id=${row.quotation_id}`}>
            {row.vehicle_number}
          </a>
        );
      }
    }

    if (value) {
      if (element_type === 'payment') {
        return commaSeperatedNumbers(value, 2);
      } else if (element_type === 'label') {
        let className = '';
        if (key === 'payment_status') {
          if (value === 'unpaid' || value === 'underpaid') {
            className = 'red';
          } else if (value === 'overpaid') {
            className = 'yellow';
          } else if (value === 'fully_paid') {
            className = 'green';
          }
          return renderLabel(snakeToTitleCase(value), className);
        }
      }

      return snakeToTitleCase(value);
    }

    return '-';
  };

  openConfirmationModal = () => {
    this.props.showModal(CONFIRMATION_MODAL, {
      action: () => this.createIntermediatePayment(),
      text: 'Are you sure to continue?',
    });
  };

  handleInputChange = (e) => {
    this.setState({
      amount_paid: e.target.value,
    });
  };

  calculateAmountToPay = () => {
    const { invoices } = this.state;
    return invoices.reduce((returnVal, invoice) => {
      if (invoice.payee === 'insurer' || invoice.payee === 'instalment_vendor') {
        return returnVal + parseFloat(invoice.amount_payable);
      } else {
        return returnVal - parseFloat(invoice.amount_payable);
      }
    }, 0);
  };

  renderTable = (rows) => {
    const { classes } = this.props;
    const columns = tableColumns;
    return (
      <div className="crm-table-wrapper gray-background">
        <div className="title-text">Invoices</div>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr>
                {columns.map((column, index) => (
                  <td key={index}>{this.renderColumn(row, column)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const {
      broker_bank_account_id,
      paid_at,
      errors,
      bankAccounts,
      invoices,
      intermediatePayment,
      amount_paid,
      apiLoading,
    } = this.state;
    const languageMap = this.props.languageMap.components.intermediatePaymentModal;

    return (
      <div className="fairdee-modal-wrapper file-viewer med">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <h1 className="title-text">Create Intermediate Payment</h1>
          {apiLoading && (
            <div className="crm-loading">
              <CircularProgress />
            </div>
          )}
          {!apiLoading && (
            <>
              {invoices && invoices.length > 0 && this.renderTable(invoices)}
              <div>
                <div className="form-wrapper">
                  <div className="options-container">
                    <div className="row margin-bottom-20">
                      <div className="header-text">{languageMap.totalAmount}</div>
                      <div className="text-center">
                        <span className="crm-label green">{toFixedNumber(this.calculateAmountToPay(), 2)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-field">
                    <label htmlFor="broker_bank_account_id">{languageMap.bankDetails}</label>
                    <Dropdown
                      fluid
                      search
                      selection
                      options={bankAccounts}
                      onChange={this.handleSelectChange}
                      value={broker_bank_account_id}
                      name="broker_bank_account_id"
                      disabled={intermediatePayment.amount_paid}
                    />
                    {errors.broker_bank_account_id && <InlineError text={errors.broker_bank_account_id} />}
                  </div>
                  <div className="form-field">
                    <label htmlFor="amount_paid">{languageMap.amountPaid}</label>
                    <Input
                      name="amount_paid"
                      onChange={(e) => this.handleInputChange(e)}
                      className={errors.amount_paid ? 'error' : ''}
                      value={amount_paid}
                      disabled={intermediatePayment.amount_paid}
                    />
                    {errors.amount_paid && <InlineError text={errors.amount_paid} />}
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                    <InlineDateTimePicker
                      clearable
                      animateYearScrolling={false}
                      id="paid_at"
                      name="paid_at"
                      label={languageMap.paymentDate}
                      value={paid_at}
                      onChange={this.handleChange.bind(null, 'paid_at')}
                      maxDate={MOMENT.clone()}
                      minDate={MOMENT.clone().subtract(1, 'years')}
                      format="DD/MM/YYYY HH:mm"
                      ampm={false}
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      className="form-field datepicker"
                      disabled={intermediatePayment.amount_paid}
                      initialFocusedDate={MOMENT.clone()}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.paid_at && <InlineError text={errors.paid_at} />}
                </div>
                {!intermediatePayment.amount_paid && (
                  <Button variant="contained" color="primary" onClick={() => this.openConfirmationModal()}>
                    Update Payment
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

FDToVendorIPModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, hideModal, showNotification })(FDToVendorIPModal));
