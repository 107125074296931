import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { clearModal } from '../../actions/modal';
import AuthenticatedHeader from '../../components/AuthenticatedHeader';
import AppUpdate from '../../components/fairdee-x-plus/common/AppUpdate';
import BottomNavigation from '../../components/fairdee-x-plus/utils/BottomNavigation';
import Header from '../../components/fairdee-x-plus/utils/Header';
import Sidebar from '../../components/home-screen/Sidebar';
import LatestHeader from '../../components/LatestHeader';
import MobileBottomNav from '../../components/MobileBottomNav';
import NewFooter from '../../components/NewFooter';
import {
  detectPlatform,
  getAccessToken,
  getParameterByName,
  getUserData,
  isAdmin,
  isAgent,
  isFDAgent,
  isInspectionGarage,
  isMember,
  isMlmAgent,
  isMobileDevice,
  isSubAgent,
  isSuperAdmin,
  logoutHelper,
  needsRedirection,
} from '../../utils/helper';
import ModalRoot from './ModalRoot';
import NotificationRoot from './NotificationRoot';
/* global mobileClient */

const staticRoutes = [
    '/road-tax-calculator',
    '/คำนวณภาษีรถยนต์',
    '/how-it-works',
    'วิธีการใช้งาน',
    '/about-us',
    '/terms-of-use',
    '/faq',
    '/คำถามที่พบบ่อย',
    '/privacy-policy',
    '/how-it-works',
    '/',
    '/คำถามที่พบบ่อย',
    '/คลังความรู้',
    '/วิธีการใช้งาน',
  ],
  IGNORE_SIDEBAR = [
    '/voluntary-insurance',
    '/get-quotes',
    '/fairdee-quote-affiliate',
    '/fairdee-wizard',
    '/bike-insurance',
    '/taxi-insurance',
    '/get-quotes-bike',
    '/get-quotes-taxi',
    '/fairdee-wizard-taxi',
    '/personal-accident',
    '/corona-wizard',
    '/dengue-wizard',
    '/pa-wizard',
    '/voluntary-insurance',
    '/tutorials',
    '/เกี่ยวกับเรา',
    '/road-tax-calculator',
    '/คำนวณภาษีรถยนต์',
    '/how-it-works',
    'วิธีการใช้งาน',
    '/about-us',
    '/terms-of-use',
    '/faq',
    '/คำถามที่พบบ่อย',
    '/privacy-policy',
    '/how-it-works',
    '/',
    '/คำถามที่พบบ่อย',
    '/คลังความรู้',
    '/วิธีการใช้งาน',
    '/line-landing',
    '/signup',
  ],
  IGNORE_BOTTOM_NAV = [
    '/voluntary-insurance',
    '/get-quotes',
    '/fairdee-quote-affiliate',
    '/fairdee-wizard',
    '/bike-insurance',
    '/taxi-insurance',
    '/get-quotes-bike',
    '/get-quotes-taxi',
    '/fairdee-wizard-taxi',
    '/personal-accident',
    '/corona-wizard',
    '/dengue-wizard',
    '/pa-wizard',
    '/voluntary-insurance',
    '/crm',
    '/signup',
    '/compulsory-insurance',
    '/welcome',
    '/downline',
  ],
  IGNORE_HEADER = ['/downline'];

const isIg = getParameterByName('ig') === 'true',
  isDirectAgent = getParameterByName('direct_agent') === 'true',
  isBusinessManager = getParameterByName('business_manager') === 'true';

class Layout extends React.Component {
  componentDidCatch(error, errorInfo) {
    let userData = getUserData();
    let extra = {};
    if (userData) {
      extra.data = userData;
    }
    extra = { ...extra, ...errorInfo };
    if (window.Raven) {
      window.Raven.captureException(error, { extra });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pathname !== nextProps.pathname) {
      let affiliate = this.props.affiliate;

      if (needsRedirection(affiliate)) {
        logoutHelper();
        this.props.clearModal();
      }
    }
  }

  renderBottomNav = () => {
    const props = this.props;

    if (!props.affiliate || !props.affiliate.id) {
      return null;
    }

    if (
      getAccessToken() &&
      (isAgent() || isMlmAgent()) &&
      !IGNORE_BOTTOM_NAV.includes(props.history.location.pathname) &&
      !isInspectionGarage() &&
      !isSubAgent() &&
      !isAdmin() &&
      !isSuperAdmin()
    ) {
      return <BottomNavigation />;
    } else if (
      getAccessToken() &&
      isMobileDevice() &&
      ((isMember() && !isMlmAgent()) || isInspectionGarage() || isSubAgent()) &&
      !IGNORE_BOTTOM_NAV.includes(props.history.location.pathname)
    ) {
      return <MobileBottomNav />;
    }

    return null;
  };

  renderHeader = () => {
    if (getAccessToken() && this.props.affiliate && this.props.affiliate.id) {
      if (IGNORE_HEADER.includes(window.location.pathname)) {
        return null;
      }
      if (!isAdmin() && !isSuperAdmin() && (isAgent() || isMlmAgent())) {
        return <Header />;
      }
      return <AuthenticatedHeader />;
    }
    if (!getAccessToken()) {
      return <LatestHeader isIg={isIg} isDirectAgent={isDirectAgent} isBusinessManager={isBusinessManager} />;
    }
    return null;
  };

  render() {
    const props = this.props,
      platform = detectPlatform(),
      { affiliate } = this.props;
    try {
      if (!mobileClient) {
        //Do nothing;
      } else {
        return <AppUpdate />;
      }
    } catch (e) {
      //Do nothing;
    }

    return (
      <>
        <div
          className={
            'root-wrapper ' +
            (getAccessToken() && this.props.history.location.pathname !== '/signup' ? 'logged-in ' : ' non-logged-in') +
            (props.history.location.pathname === '/' ? ' homepage ' : '') +
            (staticRoutes.includes(props.history.location.pathname) ? ' static ' : '') +
            platform +
            ((isIg || isDirectAgent || isBusinessManager) && props.history.location.pathname === '/' ? ' mlm' : '') +
            ((isAgent() || isMlmAgent() || isFDAgent()) &&
            !isInspectionGarage() &&
            !isSubAgent() &&
            !isAdmin() &&
            !isSuperAdmin()
              ? ' affiliate'
              : '') +
            (IGNORE_HEADER.includes(window.location.pathname) ? ' no-bottom-nav' : '')
          }
        >
          {this.renderHeader()}
          {getAccessToken() &&
          !isMobileDevice() &&
          this.props.affiliate.id &&
          ((!isAgent() && !isMlmAgent()) || isInspectionGarage() || isSubAgent() || isAdmin()) &&
          !IGNORE_SIDEBAR.includes(props.history.location.pathname) ? (
            <Sidebar />
          ) : null}
          <div
            className={
              'routes-wrap ' + (IGNORE_BOTTOM_NAV.includes(props.history.location.pathname) ? 'ignore-bottom-nav' : '')
            }
            id="routes-wrap"
          >
            {props.children}
            <ModalRoot />
          </div>
          {this.renderBottomNav()}
          <NotificationRoot />
        </div>
        {props.history.location &&
        props.history.location.pathname &&
        staticRoutes.includes(props.history.location.pathname) &&
        !isAgent() &&
        !isMlmAgent() ? (
          <NewFooter />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    firstload: state.firstload,
    location: state.location,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { clearModal })(Layout));
