import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal';
import NonMotorPolicyForm from '../../components/NonMotorPolicyForm';
import cross from '../../images/cross.svg';

function PolicyModalNonMotor(props) {
  const handleClose = () => {
    props.hideModal(null, { ...props });
  };
  console.log('props', props);
  return (
    <div className="fairdee-modal-wrapper sale-track">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={() => handleClose()} />
        <div className="sales-track-wrap policy-wrap">
          <div className="sale-track-stepper-content">
            <NonMotorPolicyForm
              suborder={props.suborder}
              action={() => {
                props.action && props.action();
                handleClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(PolicyModalNonMotor);
