import { PROVINCES } from '../constants/types';

function provinces(state = {}, action = {}) {
  switch (action.type) {
    case PROVINCES:
      return action.provinces;
    default:
      return state;
  }
}

export { provinces };
