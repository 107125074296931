import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { showModal } from '../../../actions/modal';
import { MOBILE_MODAL } from '../../../constants/types';
import LogoPlus from '../../../images/fairdee-x-plus/common/logo-plus.svg';
import Logo from '../../../images/fairdee-x-plus/common/logo.svg';
import Invite from '../../../images/fairdee-x-plus/icons/invite.svg';
import ProfileIcon from '../../../images/fairdee-x-plus/icons/profile.svg';
import { RootState } from '../../../index';
import { isAdmin, isEmailOtp, isMlmAgent, isSubAgent, isSuperAdmin } from '../../../utils/helper';
import VerifyPhoneNudge from '../auth/VerifyPhoneNudge';
import InviteFriends from '../teams/InviteFriends';
import './_header.scss';

interface Props {
  excudeReferFriend?: string[];
  excludeProfile?: string[];
}

const DEFAULT_EXCLUDE_ROUTES = ['/welcome'];

const Header: React.FC<Props> = ({
  excudeReferFriend = DEFAULT_EXCLUDE_ROUTES,
  excludeProfile = DEFAULT_EXCLUDE_ROUTES,
}) => {
  const history = useHistory(),
    affiliate = useSelector((state: RootState) => state.affiliate),
    user = useSelector((state: RootState) => state.user),
    dispatch = useDispatch(),
    trackEvent = (window as { [key: string]: any })['trackEvent'] as any;

  function goTo(page: string) {
    if (DEFAULT_EXCLUDE_ROUTES.includes(window.location.pathname)) {
      return;
    }
    history.push(page);
  }

  function openVerifyPhoneNudege() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <VerifyPhoneNudge />,
        uid: 'VERFIY_PHONE_NUDGE',
      })
    );
  }

  useEffect(() => {
    if (user.id) {
      trackEvent &&
        trackEvent.register({
          Name: user.fullname || '',
          Identity: user.id,
        });
    }
    if (user.id && !user.is_phone_e164_verified && !isEmailOtp() && !isSubAgent()) {
      openVerifyPhoneNudege();
    }
  }, [user]);

  useEffect(() => {
    if (affiliate.id) {
      let userProperty = {
        email: affiliate.user.email,
        id: affiliate.user.id,
        agent_code: affiliate.agent_code,
        isAdmin: isAdmin() || isSuperAdmin() || '',
        accountType: affiliate.account_type || '',
      };

      trackEvent && trackEvent.userProperty(userProperty);
    }
  }, [affiliate]);

  function handleInvite() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InviteFriends />,
        uid: 'INVITE_FRIENDS',
        className: 'no-background',
      })
    );
  }

  return (
    <div className="x-plus-header">
      <div>
        {!excludeProfile.includes(window.location.pathname) ? (
          <div className="user-icon" onClick={() => goTo('/profile')}>
            <img src={user.profile_pic || ProfileIcon} alt="profile" />
            <span>{affiliate.agent_code} </span>
          </div>
        ) : null}

        <div className="logo-wrap" onClick={() => goTo('/buy-insurance')}>
          <img src={isMlmAgent() ? LogoPlus : Logo} alt="logo" />
        </div>
        {!excudeReferFriend.includes(window.location.pathname) ? (
          <div className="actions-wrap" onClick={handleInvite}>
            <img src={Invite} alt="invite" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
