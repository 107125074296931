import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

class DownloadCovernoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        covernote_number: '',
        chassis_number: '',
      },
      errors: {},
    };
  }

  componentDidMount() {}

  validate = (data) => {
    const errors = {};

    return errors;
  };

  onChange = (e) => {
    let name = e.target.name,
      value = e.target.value;

    if (name === 'chassis_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'chassis_number') {
      value = value ? value.toUpperCase() : value;
    }
    if (name === 'engine_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'engine_number') {
      value = value ? value.toUpperCase() : value;
    }
    this.setState(
      {
        data: { ...this.state.data, [name]: value },
        updatedData: { ...this.state.updatedData, [name]: value },
      },
      () => {
        window.console.log(this.state);
      }
    );
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  submit = () => {
    let id = this.props.quotationId;
    let covernote_number = this.state.data.covernote_number;
    this.setState({
      errors: {},
    });

    if (!covernote_number) {
      this.setState({
        errors: { covernote_number: 'Required Field' },
      });
      return;
    }
    if (!id) {
      return;
    }
    api.utils
      .addRemark({ covernote_number, quotation: id, is_visible: true })
      .then((resp) => {
        window.console.log(resp.results);
        // this.props.action();
        this.close();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, errors } = this.state;

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Download covernote</span>
            </p>
          </div>
          <h1 className="title-text">Download covernote</h1>
          {/* <p className="text">{languageMap.textTwo}</p> */}
          <Form noValidate className="quotation-form">
            <Form.Field>
              <input
                type="text"
                id="covernote_number"
                name="covernote_number"
                placeholder="Covernote Number"
                value={data.covernote_number}
                onChange={this.onChange}
              />
              {errors.covernote_number && <InlineError text={errors.covernote_number} />}
              <label className="form-label" htmlFor="covernote_number">
                Covernote Number
              </label>
            </Form.Field>
            <Form.Field>
              <input
                type="text"
                id="chassis_number"
                name="chassis_number"
                placeholder="Chassis Number"
                value={data.chassis_number}
                onChange={this.onChange}
              />
              {errors.chassis_number && <InlineError text={errors.chassis_number} />}
              <label className="form-label" htmlFor="chassis_number">
                Chassis Number
              </label>
            </Form.Field>
            <div className="buttons-wrap single">
              <button className="button medium" onClick={this.submit}>
                Download
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

DownloadCovernoteModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(DownloadCovernoteModal));
