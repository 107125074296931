import { SELECTED_AFFILIATE } from '../constants/types';

function selectedAffiliate(state = {}, action = {}) {
  switch (action.type) {
    case SELECTED_AFFILIATE:
      return action.affiliate;
    default:
      return state;
  }
}

export { selectedAffiliate };
