import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import validator from 'validator';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { MOBILE_MODAL } from '../../../constants/types';
import CloseIcon from '../../../images/fairdee-x-plus/common/close-blue.svg';
import LogoPlus from '../../../images/fairdee-x-plus/common/fairdee-plus-logo.svg';
import { RootState } from '../../../index';
import { errorHandler, isEmailOtp, notify, validateMobileNumber } from '../../../utils/helper';
import { RECAPTCHA_SITEKEY } from '../../../constants/config';
import InlineError from '../../InlineError';
import InputPhoneNumber from '../common/InputPhoneNumber';
import AccountFoundModal from './AccountFoundModal';
import AccountNotFoundModal from './AccountNotFoundModal';
import Otp from './Otp';
import RecaptchaButton from '../common/RecaptchaButton';
import './_login-register.scss';

interface Props {
  close?: () => void;
  isIg?: boolean;
  phone?: string;
}

interface Errors {
  mobile: string;
  message?: string;
  email?: string;
}

interface ErrorResponse {
  response: any;
}

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const RegisterPhone: React.FC<Props> = ({ close, isIg, phone }) => {
  const dispatch = useDispatch(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    [mobile, setMobile] = useState(phone || ''),
    [email, setEmail] = useState(''),
    [username, setUserName] = useState(''),
    [errors, setErrors] = useState<Errors>({ mobile: '', email: '' }),
    [openUser, setOpenUser] = useState({}),
    [disableBtn, setDisableBtn] = useState(true),
    is_email_otp = isEmailOtp(),
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    }),
    captchaSiteKey = RECAPTCHA_SITEKEY;

  useEffect(() => {
    if (is_email_otp) {
      setUserName(email);
      setEmail(email);
    } else {
      setUserName(mobile);
    }
    setMobile(mobile);
  }, [email, mobile]);

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

  function openOtpScreen(intent: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={username} intent={intent} openUser={openUser} phone={mobile} />,
        uid: 'OTP_SCREEN',
      })
    );
  }

  function openAccountNotFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountNotFoundModal username={mobile} />,
        uid: 'ACCOUNT_NOT_FOUND',
      })
    );
  }

  function openAccountFound(openUser: any) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountFoundModal mobile={mobile} openUser={openUser} email={email} />,
        uid: 'ACCOUNT_FOUND',
      })
    );
  }

  function validate(mobile: string = '', email: string = '') {
    let errors = {
      mobile: '',
      email: '',
    };

    if (!mobile || !validateMobileNumber(mobile)) {
      errors.mobile = languageMap['Invalid Mobile Number'];
    }

    if (is_email_otp && !validator.isEmail(email)) {
      errors.email = languageMap['Invalid Email'];
    }
    return errors;
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let mobile = e.target.value;
    if (mobile.length > 10) {
      return;
    }
    setMobile(mobile);
    let errors = validate(mobile, email);
    if (!errors.mobile && !errors.email) {
      setDisableBtn(false);
    }
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    let email = e.target.value;
    setEmail(email);

    let errors = validate(mobile, email);
    if (!errors.mobile && !errors.email) {
      setDisableBtn(false);
    }
  }

  function requestOtp(intent: string = '', headers?: any) {
    let params: any = {
      username: is_email_otp ? email : mobile,
      username_type: is_email_otp ? 'email' : 'phone',
      intent,
      is_email_otp,
    };
    setDisableBtn(true);
    api.user
      .requestOtp(params, headers)
      .then(() => {
        openOtpScreen(intent);
        setDisableBtn(false);
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_does_not_exist') {
          openAccountNotFound();
        } else if (errors && errors.error === 'account_exists') {
          openAccountFound(openUser);
        } else if (errors && errors.mobile === 'Invalid Mobile Number') {
          setErrors({
            mobile: languageMap[`Invalid Mobile Number`],
          });
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          setErrors({
            mobile: languageMap[`Too many OTP requests! Please try again in 24 hours.`],
          });
        } else {
          errorHandler(error.response, true);
        }
        setDisableBtn(false);
      });
  }

  function handleNext(intent: string = '', headers?:any) {
    let tempErrors = validate(mobile, email);
    setErrors(tempErrors);

    if (tempErrors.mobile || tempErrors.email) {
      return;
    }
    api.user
      .getOpenUserDetails({ phone: mobile, email: email })
      .then((resp) => {
        setOpenUser(resp);
        if (resp.accounts && resp.accounts.length) {
          if (resp.is_medium_matching && resp.is_medium_matching.phone) {
            openAccountFound(resp);
          } else {
            requestOtp('login', headers);
          }
        } else if (resp === 'No Account Found') {
          requestOtp(intent, headers);
        }
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 400') {
          notify('More than one account exists with these credentials!!!', 'error');
        }
        errorHandler(error);
      });
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps: any) {
    let headers = {
      headers: {
        'G-Recaptcha-Response': captchaProps.token,
      },
    };
    if (captchaProps.btnName === 'signup') {
      handleNext('signup', headers);
    } 
  }

  function generateButton():any {
    if (!systemSettings.should_validate_recaptcha) {
      return (
        <>
          <div className="buttons-wrapper">
            <Button className={'orange-button ' + (disableBtn ? 'disabled' : '')} onClick={() => handleNext('signup')}>
              {languageMap[`Next`]}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
            <RecaptchaButton
              action={getCaptchaToken}
              btnType="button"
              name="signup"
              label={languageMap['Next']}
              disableBtn={disableBtn}
            />
          </GoogleReCaptchaProvider>
        </>
      );
    }
  }


  return (
    <div className="mobile-layout login-register login">
      <div className="header">
        {!isIg ? (
          <div className="back close" onClick={close}>
            <img src={CloseIcon} alt="back_arrow" />
          </div>
        ) : null}
        <p className="text">{languageMap[`Sign up`]} </p>
      </div>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={LogoPlus} alt="fairdee-logo" />
        </div>
        <h2 className="title-text">
          {is_email_otp ? languageMap[`Create an account on Fairdee`] : languageMap[`Sign up with your Mobile Number`]}
        </h2>
        <div className="mobile-form">
          <div className={'form-field ' + (errors.mobile ? 'error' : '')}>
            <InputPhoneNumber
              onChange={handleInputChange}
              name="mobile"
              value={mobile}
              placeholder={languageMap[`Enter Mobile Number`]}
              autoFocus={true}
            />
            {errors.mobile ? <InlineError text={errors.mobile} className="" /> : null}
          </div>
          {is_email_otp ? (
            <div className={'form-field ' + (errors.email ? 'error' : '')}>
              <label>{languageMap[`Enter your Email`]} *</label>
              <input
                type="email"
                placeholder={languageMap[`example@email.com`]}
                name="email"
                onChange={handleEmailChange}
                value={email}
              />
              {errors.email ? <InlineError text={errors.email} className="" /> : null}
            </div>
          ) : null}
        </div>
        {generateButton()}
      </div>
    </div>
  );
};

export default RegisterPhone;
