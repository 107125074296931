import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../actions/modal';
import { isMember, isMobileDevice, notify } from '../../utils/helper';

/*global mobileClient */

function Refer() {
  const [state, setState] = useState({ copied: false }),
    languageMap = useSelector((state) => state.languageMap.components.referAndEarnBar),
    messages = useSelector((state) => state.languageMap.messages),
    affiliate = useSelector((state) => state.affiliate),
    { copied } = state,
    dispatch = useDispatch();

  const openReferralModal = () => {
    dispatch(showModal('MODAL_TYPE_REFER_EARN', {}));
  };

  const handleCopy = (e) => {
    window.console.log(e);
    e.stopPropagation();
    let url = affiliate.referral_url;
    try {
      mobileClient.shareText(url);
    } catch (e) {
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (isMobileDevice() && !iOS) {
        if (navigator.share) {
          navigator
            .share({
              title: 'Share Referral Link',
              url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
      } else {
        copy(url);
        notify(messages.copied, 'info');
        setState({ ...state, copied: true });
      }
    }
  };

  if (isMember()) {
    return (
      <div className="refer-card" onClick={handleCopy}>
        <p className="text-one" dangerouslySetInnerHTML={{ __html: languageMap.memberRefer }}></p>
        <p className="link-text">{copied ? languageMap.textThree : languageMap.textTwo}</p>
      </div>
    );
  }

  if (affiliate && affiliate.hideReferEarnBanner) {
    return null;
  }

  return (
    <div className="refer-card" onClick={openReferralModal}>
      <p className="text-one" dangerouslySetInnerHTML={{ __html: languageMap.textOne }}></p>
      <p className="link-text">{copied ? languageMap.textThree : languageMap.textTwo}</p>
    </div>
  );
}

export default Refer;
