import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LinkIcon from '@material-ui/icons/Link';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PeopleIcon from '@material-ui/icons/People';
import PolicyIcon from '@material-ui/icons/Policy';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class NavList extends React.Component {
  state = {
    showCompulsoryInsurance: false,
  };

  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      this.getAffiliateUser();
    }
  }

  changeNav = (url) => {
    this.props.history.push(url);
    this.props.toggleNav && this.props.toggleNav();
  };

  getAffiliateUser = () => {
    let affiliate = this.props.affiliate;
    let showCompulsoryInsurance = false;

    if (affiliate && affiliate.credit && affiliate.credit.length) {
      affiliate.credit.forEach((data) => {
        if (data.product === 'combined_credit') {
          showCompulsoryInsurance = true;
        }
      });
    }
    this.setState({
      showCompulsoryInsurance,
      affiliate,
    });
  };

  render() {
    let { roles } = this.props;
    const languageMap = this.props.languageMap.components.navList;
    const { showCompulsoryInsurance } = this.state;
    if (!Array.isArray(roles)) {
      roles = [];
    }

    return (
      <>
        {/* 
          * Unused page, disabled for optimization 
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/voluntary-insurance-report' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/voluntary-insurance-report')}
          >
            <span className="icon">
              <AssessmentIcon />
            </span>
            <span className="text" title={languageMap.textFour}>
              {languageMap.textFour}
            </span>
          </div>
        )} */}
        {showCompulsoryInsurance ? (
          <div
            className={'list ' + (window.location.pathname === '/compulsory-insurance' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/compulsory-insurance')}
          >
            <span className="icon">
              <AssessmentIcon />
            </span>
            <span className="text" title={languageMap.textOne}>
              {languageMap.textOne}
            </span>
          </div>
        ) : null}
        <div
          className={'list ' + (window.location.pathname === '/compulsory-insurance-report' ? 'active' : '')}
          onClick={this.changeNav.bind(null, '/compulsory-insurance-report')}
        >
          <span className="icon">
            <AssessmentIcon />
          </span>
          <span className="text" title={languageMap.textTwo}>
            {languageMap.textTwo}
          </span>
        </div>
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/affiliates' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/affiliates')}
          >
            <span className="icon">
              <PeopleIcon />
            </span>
            <span className="text" title={languageMap.textFive}>
              {languageMap.textFive}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/mlm-affiliates' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/mlm-affiliates')}
          >
            <span className="icon">
              <PeopleIcon />
            </span>
            <span className="text" title={'MLM Affiliates'}>
              {'MLM Affiliates'}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/crm' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/crm')}
          >
            <span className="icon">
              <LocalActivityIcon />
            </span>
            <span className="text" title={languageMap.textNine}>
              {languageMap.textNine}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/crm/affiliates' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/crm/affiliates')}
          >
            <span className="icon">
              <PeopleIcon />
            </span>
            <span className="text" title={languageMap.textTen}>
              {languageMap.textTen}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/staff-for-tasks' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/staff-for-tasks')}
          >
            <span className="icon">
              <AssignmentIndIcon />
            </span>
            <span className="text" title={languageMap.textEleven}>
              {languageMap.textEleven}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/trackable-link' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/trackable-link')}
          >
            <span className="icon">
              <LinkIcon />
            </span>
            <span className="text" title={languageMap.cookieTrack}>
              {languageMap.cookieTrack}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/accounting' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/accounting')}
          >
            <span className="icon">
              <AccountBalanceIcon />
            </span>
            <span className="text" title={languageMap.accounting}>
              {languageMap.accounting}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/price-list-admin' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/price-list-admin')}
          >
            <span className="icon">
              <ListAltIcon />
            </span>
            <span className="text" title={languageMap.priceListAdmin}>
              {languageMap.priceListAdmin}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/policy-admin' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/policy-admin')}
          >
            <span className="icon">
              <PolicyIcon />
            </span>
            <span className="text" title={languageMap.policyAdmin}>
              {languageMap.policyAdmin}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/manage-staff' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/manage-staff')}
          >
            <span className="icon">
              <SupervisedUserCircleIcon />
            </span>
            <span className="text" title={languageMap.manageStaff}>
              {languageMap.manageStaff}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/lg' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/lg')}
          >
            <span className="icon">
              <SupervisedUserCircleIcon />
            </span>
            <span className="text" title={languageMap.letterOfGuarantee}>
              {languageMap.letterOfGuarantee}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <div
            className={'list ' + (window.location.pathname === '/insurer-settings' ? 'active' : '')}
            onClick={this.changeNav.bind(null, '/insurer-settings')}
          >
            <span className="icon">
              <SettingsIcon />
            </span>
            <span className="text" title={languageMap.insurerSettings}>
              {languageMap.insurerSettings}
            </span>
          </div>
        )}
        {roles.includes('admin') && (
          <>
            <div
              className={'list ' + (window.location.pathname === '/shortened-url' ? 'active' : '')}
              onClick={this.changeNav.bind(null, '/shortened-url')}
            >
              <span className="icon">
                <LinkIcon />
              </span>
              <span className="text" title={'Generate Shortened URL'}>
                {'Generate Shortened URL'}
              </span>
            </div>
            <div
              className={'list ' + (window.location.pathname === '/banner-upload' ? 'active' : '')}
              onClick={this.changeNav.bind(null, '/banner-upload')}
            >
              <span className="icon">
                <LinkIcon />
              </span>
              <span className="text" title={'Banner Upload'}>
                {'Banner Upload'}
              </span>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, {})(NavList));
