import { List, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';

class ConfirmationModal extends React.Component {
  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  action = () => {
    if (this.props.action) {
      this.props.action(this.props.data || {});
    }
    this.close();
  };

  otherAction = () => {
    if (this.props.otherAction) {
      this.props.otherAction(this.props.data || {});
    }
    this.close();
  };

  executeCancellationAction = () => {
    if (this.props.cancellationAction) {
      this.props.cancellationAction();
    }
    this.close();
  };

  renderListText = (textList) => {
    return (
      <List
        bordered
        dataSource={textList}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  };

  render() {
    const languageMap = this.props.languageMap.components.deleteConfirmationModal;
    const {
      text,
      subText,
      acceptText,
      rejectText,
      secondaryAcceptText,
      modalClass,
      infoText,
      warningText,
      cancellationAction,
      boldDescriptionText,
      lightDescriptionText,
      confirmText,
      acceptButtonClass,
      buttonClass,
      otherAction,
      showTextAsList,
      textList,
    } = this.props;

    return (
      <div className={`fairdee-modal-wrapper message ${modalClass ? modalClass : 'small-medium'}`}>
        <div className={`modal-content ${this.props.leftAlign ? 'left-align' : ''}`}>
          {!this.props.hideCancel && <img src={cross} className="close" alt="" onClick={this.close} />}
          <p className={`title ${this.props.leftAlign ? 'left-align' : ''}`}>{text || languageMap.textOne}</p>
          {subText ? <p className="sub-title">{subText}</p> : null}
          {infoText ? <p className="info-text process-info">{infoText}</p> : null}
          {warningText ? <p className="error-info">{warningText}</p> : null}
          {showTextAsList ? this.renderListText(textList) : null}
          {boldDescriptionText || lightDescriptionText ? (
            <div className="description-text-wrap">
              {boldDescriptionText ? <p className="bold-description-text">{boldDescriptionText}</p> : null}
              {lightDescriptionText ? <p className="light-description-text">{lightDescriptionText}</p> : null}
            </div>
          ) : null}
          {confirmText ? <p className="confirm-text">{confirmText}</p> : null}
          <div className="button-wrap">
            {!this.props.hideCancel && !this.props.hideCancelBtn && (
              <button className="button medium orange-bordered-button" onClick={this.executeCancellationAction}>
                {rejectText || languageMap.textTwo}
              </button>
            )}
            {this.props.action ? (
              <button className={`button medium ${acceptButtonClass || ''} ${buttonClass || ''}`} onClick={this.action}>
                {acceptText || languageMap.textThree}
              </button>
            ) : null}

            {otherAction && (
              <button className={`button medium ${buttonClass || ''}`} onClick={this.otherAction}>
                {secondaryAcceptText}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(ConfirmationModal));
