import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import { policyStatus } from '../../commonjs/commonData';
import { NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import empty from '../../images/empty.svg';
import { formatDateTime, snakeToTitleCase } from '../../utils/helper';

const styles = (theme) => ({
  root: {
    width: '100%',

    overflowX: 'auto',
    background: 'transparent',
    boxShadow: 'none',
  },
  table: {
    minWidth: 700,
  },
  pagination: {
    textAlign: 'right',
  },
  linkButton: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  actionCell: {
    minWidth: '250px',
  },
});

const CenteredTableCell = withStyles((theme) => ({
  root: {
    textAlign: 'center !important',
  },
}))(TableCell);

class InvoiceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salesList: null,
      totalPages: undefined,
      currentPage: undefined,
      nextPageUrl: undefined,
      previousPageUrl: undefined,
      loading: true,
      emptyList: false,
    };
  }

  componentDidMount() {
    this.getsalesList(null, { affiliate: this.props.id });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  getsalesList = (url, params) => {
    api.crm
      .getSaletrack(url, params)
      .then((response) => {
        if (!response.results.length) {
          this.setState({
            emptyList: true,
          });
        }
        response.results = response.results.map((row) => {
          if (!row.car) {
            row.disabled = true;
            row.disabledText = 'Needs Car to be added.';
          }
          if (row.invoice) {
            row.disabled = true;
            row.disabledText = 'Invoice already created.';
          }
          // if (!row.voluntary_policy && row.sale_type !== 'cbc_to_fairdee' && row.sale_type !== 'cbc_to_insurer') {
          //   row.disabled = true;
          //   row.disabledText = 'Needs Voluntary policy.';
          // }
          // if (row.voluntary_policy && row.quotation.needs_compulsory_insurance && !row.compulsory_policy && row.sale_type !== 'cbc_to_fairdee' && row.sale_type !== 'cbc_to_insurer') {
          //   row.disabled = true;
          //   row.disabledText = 'Needs compulsory policy.';
          // }
          if (row.quotation && row.quotation.price_list) {
            row.amount_payable = parseFloat(row.quotation.price_list.premium_after_tax);
            if (row.quotation.needs_compulsory_insurance) {
              row.amount_payable += parseFloat(row.quotation.cmi_price_list.premium_after_tax);
            }
            row.amount_payable = row.amount_payable.toFixed(2);
            row.affiliate_commission = row.quotation.price_list.affiliate_commission;
            if (row.quotation.needs_compulsory_insurance) {
              row.affiliate_commission += row.quotation.cmi_price_list.affiliate_commission;
            }
            row.affiliate_commission = row.affiliate_commission.toFixed(2);
          }

          return row;
        });

        let salesList = {};
        response.results.forEach((data) => {
          window.console.log(data);
          if (!salesList[data.sale_type]) {
            salesList[data.sale_type] = [];
          }
          salesList[data.sale_type].push(data);
        });

        this.setState({
          salesList,
          totalPages: response.total_pages,
          currentPage: response.current_page,
          nextPageUrl: response.next,
          previousPageUrl: response.previous,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (ind, type) => (event) => {
    let salesList = this.state.salesList;
    salesList[type][ind].checked = event.target.checked;
    this.setState({
      salesList,
    });
    window.console.log(salesList);
  };

  postInvoice = (params) => {
    return api.crm.postInvoice(params);
  };

  downloadPdf = (id) => {
    let url = apiEndpoints.invoice + '/' + id + '?resp_format=pdf';
    let win = window.open(url, '_blank');
    win.focus();
  };

  generateInvoice = () => {
    let selectedSales = {};
    let promises = [];
    Object.keys(this.state.salesList).forEach((key) => {
      selectedSales[key] = this.state.salesList[key].filter((data) => data.checked).map((data) => data.id);
      if (selectedSales[key].length) {
        let params = {
          invoice_date: moment().format('YYYY-MM-DD'),
          sale_ids: selectedSales[key],
        };
        promises.push(this.postInvoice(params));
      }
    });
    if (promises.length) {
      Promise.all(promises)
        .then((response) => {
          window.console.log(response);
          if (response && response.length) {
            response.forEach((data) => {
              this.downloadPdf(data.id);
            });
          }
          this.notify('สร้างใบแจ้งหนี้สำเร็จแล้ว', 'success');
          this.close();
        })
        .catch((error) => {
          window.console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.constructor === Object
          ) {
            window.console.log(error.response.data.errors);
            Object.keys(error.response.data.errors).forEach((key) => {
              window.console.log(error.response.data.errors[key]);
              error.response.data.errors[key].forEach((data, index) => {
                window.console.log(data);
                ((i) => {
                  setTimeout(() => {
                    this.notify(data, 'error');
                  }, 1000 + 2000 * i);
                })(index);
              });
            });
          }
        });
    }
    window.console.log(promises);
  };

  renderVehicleDetails = (row) => {
    let str = '-';
    if (row.quotation && row.quotation.quotation_query && row.quotation.quotation_query.model_description) {
      str = row.quotation.quotation_query.model_description.make
        ? row.quotation.quotation_query.model_description.make.name || ''
        : '';
      str += ' ' + row.quotation.quotation_query.model_description.name;
    }
    if (row.quotation.quotation_query.year) {
      str += ' - ' + row.quotation.quotation_query.year;
    }

    return str;
  };

  renderDesktopView = () => {
    const { classes } = this.props;
    const { salesList, loading } = this.state;
    const languageMap = this.props.languageMap.components.invoiceModal;
    const TABLE_HEADERS = languageMap.tableHeader || [
      'Select',
      'Quotation Number',
      'Date of Purchase',
      'Client Details',
      'Car Details',
      'License Number',
      'Policy Type',
      'Premium',
      'Commission',
      'Status',
      'Sale Type',
    ];

    return (
      <div className="custom-table-wrapper">
        <Paper className={classes.root}>
          {(loading || !salesList) && <CircularProgress />}
          {!loading &&
            salesList &&
            Object.keys(salesList).map((key, i) => {
              return (
                <div className="sale-type" key={i}>
                  <p className="title">
                    {languageMap.textTwo}: {snakeToTitleCase(key)}
                  </p>
                  <Table className={classes.table + ' custom-table'} key={i}>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEADERS.map((header, index) => (
                          <CenteredTableCell key={index}>{header}</CenteredTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesList[key].map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <CenteredTableCell>
                              {row.disabled ? (
                                <p className="helper-text">{row.disabledText}</p>
                              ) : (
                                <Checkbox checked={row.checked} onChange={this.handleChange(index, key)} />
                              )}
                            </CenteredTableCell>
                            <CenteredTableCell component="th" scope="row">
                              {/* <Link to={{ pathname: '/fairdee-wizard', search: `?price_list_id=${row.quotation.price_list.id}&quotation_query_id=${row.quotation_query.id}&id=${row.id}` }}>{row.id}</Link> */}
                              {row.quotation.id}
                            </CenteredTableCell>
                            <CenteredTableCell className={classes.noWrap}>
                              {formatDateTime(row.created_at)}
                            </CenteredTableCell>
                            <CenteredTableCell>
                              {row.quotation.client_first_name} {row.quotation.client_last_name}{' '}
                              {row.quotation.client_first_name && row.quotation.client_phone ? ', ' : ''}{' '}
                              {row.quotation.client_phone}
                            </CenteredTableCell>
                            <CenteredTableCell>{this.renderVehicleDetails(row)}</CenteredTableCell>
                            <CenteredTableCell>{row.car ? row.car.vehicle_number : '-'}</CenteredTableCell>
                            <CenteredTableCell>
                              {row.quotation.needs_compulsory_insurance ? 'Voluntary + Compulsory' : 'Voluntary'}
                            </CenteredTableCell>
                            <CenteredTableCell>{row.amount_payable}</CenteredTableCell>
                            <CenteredTableCell>{row.affiliate_commission || '-'}</CenteredTableCell>
                            <CenteredTableCell>
                              <span className={policyStatus[row.policy_status].className}>
                                {snakeToTitleCase(row.policy_status)}
                              </span>
                            </CenteredTableCell>
                            <CenteredTableCell>
                              {row.sale_type ? snakeToTitleCase(row.sale_type) : '-'}
                            </CenteredTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              );
            })}
        </Paper>
      </div>
    );
  };

  render() {
    const { emptyList } = this.state;
    const languageMap = this.props.languageMap.components.invoiceModal;

    return (
      <div className="fairdee-modal-wrapper full-screen">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          {/* <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title">Generate Invoices</span>
            </p>
          </div> */}
          <h1 className="title">
            {languageMap.textOne}
            {!emptyList && (
              <button className="button medium" onClick={this.generateInvoice}>
                {languageMap.textThree}
              </button>
            )}
          </h1>
          <div className="content-wrapper">
            {emptyList ? (
              <div className="empty-wrapper">
                <img src={empty} alt="" />
                <p className="title">{languageMap.textFour}</p>
                <p className="text">{languageMap.textFive}</p>
              </div>
            ) : (
              this.renderDesktopView()
            )}
          </div>
        </div>
      </div>
    );
  }
}

InvoiceModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showNotification })(withStyles(styles)(InvoiceModal)));
