import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { hideModal } from '../../actions/modal';
import { CURRENT_VENDOR } from '../../constants';
import cross from '../../images/cross.svg';
import { isAdmin } from '../../utils/helper';

function SelectVendorModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.vendorModal);
  const [vendor, setVendor] = useState('');
  const [vendorList, setVendorList] = useState('');
  useEffect(() => {
    if (props.vendors) {
      let defaultVendor = null;
      setVendorList(
        props.vendors.map((vendor, index) => {
          if (vendor.name.toLowerCase() === CURRENT_VENDOR.toLowerCase()) {
            defaultVendor = vendor.id;
          }
          return {
            text: languageMap[vendor.name],
            value: vendor.id,
            name: vendor.name,
          };
        })
      );
      setVendor(defaultVendor);
    }
  }, [props]);

  function close() {
    props.hideModal(null, { ...props });
  }

  function action() {
    props.action(vendor);
    close();
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <h1 className="title-text">{languageMap.chooseVendor}</h1>
        <div className="qr-details">
          <div className="form-wrapper">
            <div className="form-field">
              <label htmlFor="vendor">{languageMap.instalmentVendor}</label>
              <Dropdown
                disabled={!isAdmin()}
                fluid
                search
                selection
                options={vendorList}
                onChange={(e, data) => setVendor(data.value)}
                value={vendor}
                name="vendor"
              />
            </div>
            <div className="button-wrap">
              <button className="button medium orange-bordered-button" onClick={close}>
                {languageMap.close}
              </button>
              <button className="button medium" onClick={action}>
                {languageMap.continue}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(SelectVendorModal);
