import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import { CLIENT_ID } from '../constants/config';
import { NOTIFICATION } from '../constants/types';
import { validateMobileNumber } from '../utils/helper';
import LoadingButton from './LoadingButton';

const styles = (theme) => ({
  fullWidth: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: '24px',
  },
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        fullname: '',
        email: '',
        phone: '',
      },
      updatedData: {},
      errors: {},
      requestSuccess: null,
      loading: false,
    };
  }

  componentWillMount() {
    if (this.props.userData) {
      this.setState({
        data: { ...this.state.data, ...this.props.userData },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userData !== nextProps.userData) {
      this.setState({
        data: { ...this.state.data, ...nextProps.userData },
      });
    }
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      data: { ...this.state.data, [name]: event.target.value },
      updatedData: { ...this.state.updatedData, [name]: event.target.value },
    });
  };

  validate = (data) => {
    let errors = {};

    if (!data.fullname) {
      errors.fullname = 'Required Field';
    }
    if (!data.phone) {
      errors.phone = 'Required Field';
    }
    if (data.email && !validator.isEmail(data.email)) {
      errors.email = 'Invalid Email';
    }
    if (data.phone && !validateMobileNumber(data.phone)) {
      errors.phone = 'Invalid phone number';
    }

    return errors;
  };

  continue = () => {
    if (this.props.changeStep) {
      this.props.changeStep(1);
    }
  };

  postUser = (params) => {
    this.setState({
      loading: true,
    });

    api.dataEntry
      .user(params)
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        this.updateFairdeeQuotation(response.result.id);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.setState({
              errors: { ...this.state.errors, [key]: error.response.data.errors[key][0] },
            });
          });
        }
      });
  };

  patchUser = (params) => {
    this.setState({
      loading: true,
    });

    api.dataEntry
      .patchUser(this.state.data.id, params)
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          requestSuccess: true,
        });

        if (this.props.action) {
          this.props.action();
        }
        this.continue();
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.setState({
              errors: { ...this.state.errors, [key]: error.response.data.errors[key][0] },
            });
          });
        }
      });
  };

  updateFairdeeQuotation = (user) => {
    let params = {
      user,
    };
    window.console.log(params, this.props);

    api.utils
      .patchFairdeeQuotation(this.props.quotation, params)
      .then((resp) => {
        window.console.log(resp);
        if (this.props.action) {
          this.props.action();
        }
        this.continue();
      })
      .catch((error) => {
        window.console.log(error);
        this.notify('Something went wrong', 'error');
      });
  };

  handleNext = () => {
    window.console.log('handle Next');
    let errors = this.validate(this.state.data);
    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }
    if (this.state.data.id) {
      if (Object.keys(this.state.updatedData).length) {
        this.patchUser(this.state.updatedData);
        return;
      }
      this.continue();
      return;
    }
    let params = Object.assign({}, this.state.data);
    params.client_id = CLIENT_ID;

    if (!params.email) {
      params.email = 'fairdee+' + params.phone + '@fairdee.co.th';
      this.setState({
        data: { ...this.state.data, email: params.email },
      });
    }
    this.postUser(params);
  };

  render() {
    const { classes } = this.props;
    const { data, errors, loading, requestSuccess } = this.state;
    const languageMap = this.props.languageMap.components.userForm;

    return (
      <div className="user-form">
        <TextField
          id="fullname"
          label={languageMap.textOne}
          className={classes.textField}
          value={data.fullname || ''}
          error={Boolean(errors.fullname)}
          helperText={errors.fullname}
          autoComplete="off"
          required
          onChange={this.handleChange('fullname')}
        />
        <TextField
          id="email"
          label={languageMap.textTwo}
          className={classes.textField}
          value={data.email || ''}
          error={Boolean(errors.email)}
          helperText={errors.email}
          autoComplete="off"
          required
          onChange={this.handleChange('email')}
        />
        <TextField
          type="number"
          id="phone"
          label={languageMap.textThree}
          className={classes.textField}
          value={data.phone || ''}
          error={Boolean(errors.phone)}
          helperText={errors.phone}
          autoComplete="off"
          required
          onChange={this.handleChange('phone')}
        />
        <div className="buttons-wrap right">
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={this.handleNext}
            is_failure={requestSuccess === false}
            is_success={requestSuccess === true}
            loading={loading}
            children={languageMap.textFour}
          />
        </div>
      </div>
    );
  }
}

UserForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, { showNotification })(withStyles(styles)(UserForm));
