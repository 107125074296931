import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-lg.svg';
import cross from '../../images/cross.svg';

class EmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      errors: {},
    };
  }

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  save = () => {
    window.console.log(this.state);
    window.console.log(this.props);
    let { emails } = this.state;
    let cc_emails = [];
    if (emails.length) {
      cc_emails = emails.map((email) => email.value);
    } else {
      this.setState({
        errors: { ...this.state.errors, email: 'Required' },
      });
      return;
    }
    if (this.props.action) {
      let params = { ...this.props.data };
      if (cc_emails.length) {
        params.cc_emails = cc_emails;
      }
      this.props.action(params);
    }
    this.close();
  };

  handleAddition = (tag) => {
    this.setState((state) => ({ emails: [...state.emails, tag] }));
  };

  handleDelete = (i) => {
    const emails = this.state.emails.slice(0);
    emails.splice(i, 1);
    this.setState({ emails });
  };

  handleChange = (newValue, actionMeta) => {
    window.console.log(newValue, actionMeta);
    if (actionMeta.removedValue && actionMeta.removedValue.value) {
      let currEmail = this.state.emails.filter((data) => data.value !== actionMeta.removedValue.value);
      this.setState({
        emails: currEmail,
      });
    }
  };

  handleAddition = (tag) => {
    window.console.log(tag);
    const emails = [].concat(this.state.emails, tag);
    this.setState({ emails });
  };

  handleValidate = (tag) => {
    window.console.log(tag);
    let { errors } = this.state;
    if (tag && !validator.isEmail(tag)) {
      errors.email = 'Invalid Email';
      this.setState({
        errors,
      });
      return false;
    }
    delete errors.email;
    this.setState({
      errors,
    });
    return true;
  };

  createOPtion = (label) => ({
    label,
    value: label,
  });

  handleCreate = (inputValue) => {
    window.console.log(inputValue);
    if (this.handleValidate(inputValue)) {
      this.handleAddition(this.createOPtion(inputValue));
    }
  };

  render() {
    const { emails, errors } = this.state;

    window.console.log(this.props.data);

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Add Email</span>
            </p>
          </div>
          <h1 className="title-text">Add Email</h1>
          <div className="emails-form">
            <CreatableSelect
              isClearable
              value={emails}
              isMulti
              className="react-select"
              placeholder="Add Email"
              noOptionsMessage={() => null}
              onCreateOption={this.handleCreate}
              onChange={this.handleChange}
            />
            {errors.email ? (
              <p>
                <InlineError text={errors.email} />
              </p>
            ) : null}
            <div className="button-wrap">
              <Button onClick={this.save} color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(EmailModal));
