import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../constants/types';

const initialState = [];

function notification(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return [...state, { type: action.payload.type, uid: action.uid, props: action.payload.props }];
    case HIDE_NOTIFICATION:
      return state.filter((notification) => {
        return notification.uid !== action.uid;
      });
    default:
      return state;
  }
}

export default notification;
