import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
const DEFAULT_VALUE = 10000;
const DEFAULT_STEP = 10000;
const MIN = 10000;
const max = 1000000;

class IncrementDecrementWithInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show || true,
      value: props.value || '',
      minimum: props.mininum || MIN,
      maximum: props.maximum || max,
      step: props.step || DEFAULT_STEP,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      this.props.minimum !== nextProps.minimum ||
      this.props.maximum !== nextProps.maximum ||
      this.props.step !== nextProps.step
    ) {
      this.setState({
        value: nextProps.value || '',
        minimum: nextProps.mininum || MIN,
        maximum: nextProps.maximum || max,
        step: nextProps.step || DEFAULT_STEP,
      });
    }
  }

  updateOnchange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.value || null);
    }
  };

  incrementItem = () => {
    window.console.log(this.state.value, this.state.step, this.state.maximum);
    let value = parseInt(this.state.value, 10) + parseInt(this.state.step, 10);
    if (value > this.state.maximum) {
      return;
    }
    this.setState({ value: value || DEFAULT_VALUE }, () => {
      this.updateOnchange();
    });
  };

  decreaseItem = () => {
    let value = parseInt(this.state.value, 10) - parseInt(this.state.step, 10);
    if (value < this.state.minimum) {
      return;
    }
    this.setState({ value: value || DEFAULT_VALUE }, () => {
      this.updateOnchange();
    });
  };

  handleChange = (event) => {
    if (parseFloat(event.target.value || 0, 10) < 0) {
      return;
    }
    this.setState(
      {
        value: event.target.value ? parseInt(event.target.value) : '',
      },
      () => {
        this.updateOnchange();
      }
    );
  };

  handleNumberFormat = (data) => {
    if (parseFloat(data.value || 0) < 0) {
      return;
    }
    this.setState(
      {
        value: data.value ? parseInt(data.value) : '',
      },
      () => {
        this.updateOnchange();
      }
    );
  };

  render() {
    let { value } = this.state;
    return (
      <div className="increment-decrement">
        <div className="increment-decrement-wrapper">
          <button onClick={this.decreaseItem}>-</button>
          {/* {this.state.show ? <input type="number" className="input" onChange={this.handleChange} value={value} autoFocus={true} /> : ''} */}
          {this.state.show ? (
            <NumberFormat
              value={value}
              customInput={TextField}
              thousandSeparator={true}
              onValueChange={this.handleNumberFormat}
              autoComplete="off"
            />
          ) : null}
          <button onClick={this.incrementItem}>+</button>
        </div>
      </div>
    );
  }
}

export default IncrementDecrementWithInput;
