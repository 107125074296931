import PropTypes from 'prop-types';
import React from 'react';

const InlineError = ({ text, className, renderHtml }) => {
  if (renderHtml) {
    return <span className={'inline-error ' + className} dangerouslySetInnerHTML={{ __html: text }}></span>;
  }
  return <span className={'inline-error ' + className}>{text}</span>;
};

InlineError.propTypes = {
  text: PropTypes.string.isRequired,
  renderHtml: PropTypes.bool,
};

export default InlineError;
