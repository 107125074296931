import MomentUtils from '@date-io/moment';
import { Table, TableCell } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { TableBody, TableRow } from 'semantic-ui-react';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import AffiliateCompulsaryInsuranceTable from '../../components/AffiliateCompulsaryInsuranceTable';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import { snakeToTitleCase, thaiToDefaultDate, toThaiDate } from '../../utils/helper';

const MOMENT = moment().locale('th').add(543, 'years');

const TEXT_FIELDS_MAP = {
  Licenseplate: 'vehicle_number',
  'Insured Name': 'client_first_name',
  'Insured Surname': 'client_last_name',
};
const DATE_FIELDS_MAP = { 'Start Date': 'voluntary_start_date' };
const DROPDOWN_FIELDS_MAP = {
  Insurer: [],
  'Sale type': [
    { id: 'cbc_to_fairdee', label: 'Cbc To Fairdee', value: 'cbc_to_fairdee' },
    { id: 'cbc_to_insurer', label: 'Cbc To Insurer', value: 'cbc_to_insurer' },
    { id: 'credit', label: 'Credit', value: 'credit' },
  ],
};

class ImportedSaleDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'sales',
      updatedFields: {},
      data: this.getTableData(),
    };
  }

  componentDidMount() {
    this.getInsurers();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  getTableData = () => {
    let quotation = this.props.quotation,
      sale = quotation.sale,
      car = sale.car || {},
      deliveryAddress = quotation.policy_delivery_address,
      modelDesc = car.description,
      policy = sale.voluntary_policy,
      compulsory_policy = sale.compulsory_policy,
      main_driver = car.drivers && car.drivers.length ? car.drivers.find((driver) => driver.is_policy_holder) : {},
      address = main_driver.fairdee_address || {};

    let data = {
      'Type of Policy': policy.insurance_class,
      'Cashback?': policy.has_cashback ? 'Yes' : 'No',
      'Policy Status': sale.policy_status,
      'Payment Status': sale.payment_status,
      'Sale type': { id: sale.sale_type, label: snakeToTitleCase(sale.sale_type), value: sale.sale_type },
      'Buy Date': sale.sold_on, // Date
      'Start Date': toThaiDate(policy.policy_start_date), // Date
      Insurer: { id: policy.insurer.id, label: policy.insurer.name, value: policy.insurer.id },
      Licenseplate: car.vehicle_number,
      'Registration Province': car.registration_province,
      Compulsory: quotation.needs_compulsory_insurance ? 'Yes' : 'No',
      Title: quotation.client_title,
      'Insured Name': quotation.client_first_name,
      'Insured Surname': quotation.client_last_name,
      Contact: quotation.client_phone,
      'National ID': main_driver.id_number,
      Gender: main_driver.gender,
      Birthdate: main_driver.dob, // Date
      Email: quotation.client_email,
      'Insured First Line of Address': address ? address.first_line : '-',
      'Insured Subdistrict': address ? address.subdistrict : '-',
      'Insured District': address ? address.district : '-',
      'Insured Province': address ? address.province : '-',
      'Insured Postal Code': address ? address.postal_code : '-',
      'Delivery Address': deliveryAddress ? deliveryAddress.first_line : '-',
      'Delivery subdistrict': deliveryAddress ? deliveryAddress.subdistrict : '-',
      'Delivery district': deliveryAddress ? deliveryAddress.district : '-',
      'Delivery Province': deliveryAddress ? deliveryAddress.province : '-',
      'Delivery Postal Code': deliveryAddress ? deliveryAddress.postal_code : '-',
      'Use Type': policy.use_type,
      'Car Brand': modelDesc?.make.name,
      'Car Model': modelDesc?.make_model.name,
      cc: modelDesc?.cc.name,
      year: car.registration_year,
      'Repair Type': policy.garage_type,
      'Policy Number': policy.policy_number,
      'Cover Note No.': quotation.cover_note_code,
      'Postal Tracking No.': policy.postal_tracking_code,
      'Chassis number': car.chassis_number,
      'Engine Number': car.engine_number,
      'Total Voluntary Premium': policy.net_premium,
      Subsidy: policy.subsidy,
      "Agent/Affiliates's Voluntary Insurance Commission": policy.affiliate_commission,
      'Compulsory Insurance Premium': compulsory_policy ? compulsory_policy.net_premium : '-',
      "Agent/Affiliates's Compulsory Insurance Commission": compulsory_policy
        ? compulsory_policy.affiliate_commission
        : '-',
      'Sum Insured': policy.sum_insured,
      Deductible: policy.deductible,
      'Driver Discount': quotation.driver_discount,
      'CCTV Discount': quotation.camera_discount,
    };

    return data;
  };

  handleTabChange = (currentTab) => {
    this.setState({
      currentTab,
    });
  };

  handleInputChange = (key, e) => {
    let { data, updatedFields } = this.state;
    let value = null;
    if (moment.isMoment(e)) {
      value = moment(e).format('YYYY-MM-DD');
    } else {
      value = e.target.value;
    }

    data[key] = value;
    updatedFields[key] = value;
    this.setState({
      data,
      updatedFields,
    });
  };

  getInsurers = () => {
    api.masterData.getInsurers().then((resp) => {
      let insurers = resp.results.map((insurer, index) => {
        return {
          id: index + 1,
          label: insurer.name,
          value: insurer.id,
        };
      });
      insurers.unshift({
        id: 0,
        label: 'No Selection',
        value: null,
      });
      DROPDOWN_FIELDS_MAP['Insurer'] = insurers;
      this.setState({
        insurers,
      });
    });
  };

  handleSelectChange = (dropdownData, flag) => {
    let { data, updatedFields } = this.state;
    if (data[flag] && dropdownData.value === data[flag].value) {
      return;
    }
    data[flag] = updatedFields[flag] = dropdownData;
    this.setState({
      data,
      updatedFields,
    });
  };

  renderField = (key) => {
    const data = this.state.data || {};
    const value = data[key];

    if (TEXT_FIELDS_MAP[key]) {
      return (
        <p className="editable-field">
          <Input onChange={this.handleInputChange.bind(null, key)} value={value} />;
        </p>
      );
    }
    if (DATE_FIELDS_MAP[key]) {
      return (
        <p className="editable-field">
          <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
            <InlineDatePicker
              className="form-element mui-field"
              clearable
              keyboard
              animateYearScrolling={false}
              id={key}
              value={value}
              onChange={this.handleInputChange.bind(null, key)}
              maxDate={MOMENT.clone()}
              minDate={MOMENT.clone().subtract(150, 'year')}
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </p>
      );
    }
    if (DROPDOWN_FIELDS_MAP[key]) {
      return (
        <Select
          value={data[key]}
          onChange={(data) => this.handleSelectChange(data, key)}
          options={DROPDOWN_FIELDS_MAP[key]}
          placeholder="Select"
          className="react-select-custom"
        />
      );
    }

    return value;
  };

  saveDetails = () => {
    let promises = [],
      quotationParams = {},
      saleParams = {},
      policyParams = {},
      quotation = this.props.quotation,
      sale = quotation.sale,
      policy = sale.voluntary_policy,
      { updatedFields } = this.state;
    if (!Object.keys(updatedFields).length) {
      return;
    }
    this.setState({
      apiLoading: true,
    });
    if (updatedFields['Licenseplate']) {
      quotationParams.car = { vehicle_number: updatedFields['Licenseplate'] };
    }
    if (updatedFields['Insured Name']) {
      quotationParams.client_first_name = updatedFields['Insured Name'];
    }
    if (updatedFields['Insured Surname']) {
      quotationParams.client_last_name = updatedFields['Insured Surname'];
    }
    if (updatedFields['Start Date']) {
      policyParams.policy_start_date = thaiToDefaultDate(updatedFields['Start Date']);
    }
    if (updatedFields['Insurer']) {
      policyParams.insurer = updatedFields['Insurer'].value;
    }
    if (updatedFields['Sale type']) {
      saleParams.sale_type = updatedFields['Sale type'].value;
    }

    if (Object.keys(saleParams).length) {
      promises.push(api.crm.patchSaletrack(sale.id, saleParams));
    }
    if (Object.keys(policyParams).length) {
      promises.push(api.crm.patchFairdeePolicy(policy.id, policyParams));
    }
    if (Object.keys(quotationParams).length) {
      promises.push(api.utils.patchFairdeeQuotation(quotation.id, quotationParams));
    }

    Promise.all(promises)
      .then((res) => {
        this.setState({
          apiLoading: false,
        });
        this.close();
        if (this.props.onSave) {
          this.props.onSave(res);
        }
      })
      .catch((err) => {
        this.setState({
          apiLoading: true,
        });
      });
  };

  renderView = () => {
    const { currentTab, insurers } = this.state;
    switch (currentTab) {
      case 'sales':
        const { data } = this.state;
        if (insurers && insurers.length) {
          return (
            <div className="table-wrapper">
              <Table>
                <TableBody>
                  {Object.keys(data).map((key, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      <TableCell align="right">{this.renderField(key)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          );
        }
        return null;

      case 'compulsory-insurance':
        return (
          <AffiliateCompulsaryInsuranceTable
            affiliateId={parseInt(this.props.quotation.affiliate.id)}
            hideAccordian={true}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { currentTab } = this.state;

    return (
      <div className="fairdee-modal-wrapper customer-info medium">
        <div className="modal-content with-overflow">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">Reports</span>
            </p>
          </div>
          <div className="tab-wrapper">
            <h1
              className={'title-text ' + (currentTab === 'sales' ? 'active' : '')}
              onClick={this.handleTabChange.bind(null, 'sales')}
            >
              Imported Sale Details
            </h1>
            {/* <h1 className={"title-text " + (currentTab === 'compulsory-insurance' ? 'active' : '')} onClick={this.handleTabChange.bind(null, 'compulsory-insurance')}>Compulsory Insurance</h1> */}
          </div>
          {this.renderView()}
          <div className="buttons-wrap">
            <button className="button medium orange-bordered-button" onClick={this.close}>
              Cancel
            </button>
            <button className="button medium" onClick={this.saveDetails} disabled={this.state.apiLoading}>
              {this.state.apiLoading && <CircularProgress size={16} />}
              {!this.state.apiLoading && <>Update</>}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ImportedSaleDetailsModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  quotation: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(ImportedSaleDetailsModal));
