import PropTypes from 'prop-types';
import React from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { connect } from 'react-redux';
import { showModal } from '../actions/modal';
import { showNotification } from '../actions/notification';
import apiEndpoints from '../commonjs/apiEndpoints';
import { MODAL_TYPE_MESSAGE, NEW_MODAL, NOTIFICATION } from '../constants/types';
import cancel from '../images/ic-close.svg';
import { getParameterByName, isAdmin, isMobileDevice } from '../utils/helper';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePreviewUrl: null,
      files: this.props.files || [],
    };
    this.imgRef = React.createRef();
  }

  openMsgModal = (message) => {
    this.props.showModal(MODAL_TYPE_MESSAGE, {
      children: <div>{message}</div>,
      hideCancel: true,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  remove = (data, i) => (e) => {
    e.stopPropagation();
    if (data.id && this.props.onFileRemove) {
      this.props.onFileRemove(data);
    } else {
      this.setState({
        files: this.state.files.filter((data, index) => index !== i),
      });
      this.props.onFileRemove({ index: i });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.files !== nextProps.files) {
      this.setState({
        files: nextProps.files,
      });
    }
  }

  handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    let file = files[0] || {},
      invalidFile = null;
    e.target.value = null;

    files.forEach((file) => {
      if (this.props.accept && this.props.accept.search(file.type) < 0) {
        invalidFile = file.type;
      }
    });

    if (invalidFile) {
      this.notify(`Invalid file format ${invalidFile}`, 'error');
      return;
    }
    if (files && files.length && files.length > this.props.limit - this.state.files.length) {
      this.openMsgModal(`You can only upload a maximum of ${this.props.limit - this.state.files.length} file(s)`);
      return;
    }

    if (this.props.onFileUpload) {
      let toSend = this.props.multiple ? files : file;
      this.props.onFileUpload(toSend, this.props.name);
    }
  };

  generateViewLink = (data) => {
    let url = apiEndpoints.quotationDocumentUpload + '/' + data.id + '?inline=true';
    return url;
  };

  downloadDocument = (data) => {
    let renderType = 'attachment';
    if (this.props.inlineRender) {
      renderType = 'inline';
    }
    if (this.props.action && isAdmin()) {
      this.props.action(data);
      return;
    }
    if (!data || !data.id || (!this.props.download && !this.props.downloadPath)) {
      return;
    }
    let url =
        (this.props.downloadPath || apiEndpoints.quotationDocumentUpload) + '/' + data.id + '?' + renderType + '=true',
      t = getParameterByName('t');
    if (t) {
      url += `&t=${t}`;
    }
    const link = document.createElement('a');
    link.href = url;
    if (this.props.openInNewPage) {
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  checkAndDisableDeleteIfVerified = (data) => {
    if (!this.props.disableDeleteIfVerified) {
      return false;
    }
    if (data.is_rejected === null || data.is_rejected === true) {
      return false;
    }
    return true;
  };

  onUpdate = (props) => {
    const { current: img } = this.imgRef,
      x = props.x,
      y = props.y,
      scale = props.scale;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  };

  viewImage = (data) => {
    this.props.showModal(NEW_MODAL, {
      component: (
        <div className="image-viewer">
          <QuickPinchZoom onUpdate={this.onUpdate}>
            <img ref={this.imgRef} src={this.generateViewLink(data)} alt="inspection photo" />
          </QuickPinchZoom>
        </div>
      ),
      uid: 'IMAGE_VIEWER',
      size: 'large',
    });
  };

  render() {
    const { loading, name, limit, disableDelete, multiple, accept, rejectAction, hideUpload, disabled } = this.props;
    const { files } = this.state;
    const messages = this.props.languageMap.messages;
    let btnText = this.props.btnText || this.props.languageMap.messages.upload,
      uploadingText = this.props.uploadingText,
      downloadAllText = this.props.downloadAllText;
    let acceptTypes = accept;
    if (isMobileDevice()) {
      acceptTypes = 'image/*, application/pdf';
    }

    return (
      <div className={`file-upload ${this.props.display ? this.props.display : ''} `}>
        <div className={`${this.props.showGallery ? 'gallery-view' : ''}`}>
          {files && files.length
            ? files.map((data, i) => {
                let tagClassName = '',
                  tagMessage = '',
                  creatorType = '',
                  creatorTagClassName = '';
                if (rejectAction && isAdmin()) {
                  if (data.is_rejected === null) {
                    tagClassName = 'no-action';
                    tagMessage = messages.takeAction;
                  }
                  if (data.is_rejected === true) {
                    tagClassName = 'rejected';
                    tagMessage = messages.rejected;
                  }
                  if (data.is_rejected === false) {
                    tagClassName = 'accepted';
                    tagMessage = messages.accepted;
                  }
                  if (data.payment && data.payment.is_reconciled) {
                    tagClassName = 'accepted';
                    tagMessage = messages.reconciled;
                  }
                  if (data.payment?.creator_type === 'ai') {
                    creatorType = messages[`Added By AI`];
                    creatorTagClassName = 'blue top';
                  }
                }

                if (this.props.showGallery) {
                  return (
                    <div className="card">
                      <div className="card-header">
                        <div className="card-text">
                          &nbsp;
                          {creatorType ? <div className={'tag ' + creatorTagClassName}>{creatorType}</div> : null}
                          {data.name || data.file.name || data.file.original_file_name}
                        </div>
                        <div className="cancel-wrap" onClick={this.remove(data, i)}>
                          <img src={cancel} alt="" />
                        </div>
                      </div>
                      <div className="img-wrap" onClick={() => this.viewImage(data)}>
                        <img src={this.generateViewLink(data)} />
                      </div>
                    </div>
                  );
                }

                return (
                  <div
                    className={`uploaded-file ${tagClassName ? 'with-tag' : ''} ${creatorTagClassName}`}
                    onClick={this.downloadDocument.bind(null, data)}
                    key={i}
                  >
                    {creatorType ? <div className={'tag ' + creatorTagClassName}>{creatorType}</div> : null}
                    {data.name || data.file.name || data.file.original_file_name}
                    {!disableDelete && !this.checkAndDisableDeleteIfVerified(data) && (
                      <div className="img-wrap" onClick={this.remove(data, i)}>
                        <img src={cancel} alt="" />
                      </div>
                    )}
                    {tagClassName ? <div className={'tag ' + tagClassName}>{tagMessage}</div> : null}
                  </div>
                );
              })
            : ''}
        </div>

        {!hideUpload ? (
          <button
            className={
              'button primary orange-bordered-button ' +
              ((files && files.length >= (limit || 1)) || loading || disabled ? 'disabled' : '')
            }
          >
            {loading ? (uploadingText || btnText) + '...' : btnText}
            <input
              className="file-input"
              type="file"
              onChange={(e) => this.handleImageChange(e)}
              accept={acceptTypes || 'image/*, application/pdf'}
              name={name}
              multiple={multiple === true}
            />
          </button>
        ) : null}
        {this.props.downloadAll && files.length ? (
          <button
            className={'button primary orange-button medium ml-10 ' + (loading ? 'disabled' : '')}
            onClick={() => this.props.downloadAll()}
          >
            {this.props.downloadAllText}
          </button>
        ) : null}
      </div>
    );
  }
}

FileUpload.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  limit: PropTypes.number,
  disableDelete: PropTypes.bool,
  btnText: PropTypes.string,
  download: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  uploadingText: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, { showModal, showNotification })(FileUpload);
