import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import EditAffiliateForm from '../../components/EditAffiliateForm';
import EditBankdetailsForm from '../../components/EditBankdetailsForm';
import EditPasswordForm from '../../components/EditPasswordForm';
import { default as cross, default as crossLg } from '../../images/close.svg';
import { isMobileDevice } from '../../utils/helper';

class EditAffiliateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: props.currentTab || 'one',
    };
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (event, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.editAffiliateModal;
    const { currentTab } = this.state;

    function TabContainer(props) {
      return <div className="tab-container">{props.children}</div>;
    }

    return (
      <div className="fairdee-modal-wrapper small-medium edit-affiliate-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <div className="tabs-wrapper">
            <Tabs value={currentTab} onChange={this.handleChange} className="tabs">
              {!this.props.hideAffiliateForm && <Tab className="tab" value="one" label={languageMap.textTwo} />}
              {!this.props.hideBankdetailsForm && (
                <Tab
                  className="tab"
                  value="two"
                  label={isMobileDevice() ? languageMap.otherDetails : languageMap.textThree}
                />
              )}
              {!this.props.hidePasswordForm && <Tab className="tab" value="three" label={languageMap.textFour} />}
            </Tabs>
            {currentTab === 'one' && !this.props.hideAffiliateForm && (
              <TabContainer>
                <EditAffiliateForm
                  affiliate={this.props.affiliate}
                  action={this.props.action}
                  isSubagentFlow={this.props.isSubagentFlow}
                />
              </TabContainer>
            )}
            {currentTab === 'two' && !this.props.hideBankdetailsForm && (
              <TabContainer>
                <EditBankdetailsForm
                  affiliate={this.props.affiliate}
                  action={this.props.action}
                  isSubagentFlow={this.props.isSubagentFlow}
                />
              </TabContainer>
            )}
            {currentTab === 'three' && !this.props.hidePasswordForm && (
              <TabContainer>
                <EditPasswordForm
                  affiliate={this.props.affiliate}
                  action={this.props.action}
                  isSubagentFlow={this.props.isSubagentFlow}
                />
              </TabContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EditAffiliateModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(EditAffiliateModal));
