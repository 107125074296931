import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import Validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { MODAL_TYPE_LOGIN, NOTIFICATION } from '../../constants/types';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import successImg from '../../images/success-img.svg';
import { setLanguageToElement } from '../../utils/helper';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
      },
      errors: {},
      passwordSent: false,
    };
  }

  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  openLoginModal = () => {
    this.close();
    this.props.showModal(MODAL_TYPE_LOGIN, {});
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    const messages = this.props.languageMap.messages;
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const params = {
        email: this.state.data.email,
      };
      this.setState({ loading: true });
      api.crm
        .forgotPassword(params)
        .then(() => {
          this.setState({
            loading: false,
          });
          this.notify(messages.resetPasswordEmailSent, 'success');
          this.close();
        })
        .catch((error) => {
          window.console.log(error);
          this.setState({
            loading: false,
          });
          if (
            error.response &&
            error.response.status &&
            error.response.status === 403 &&
            error.response.data &&
            error.response.data.message
          ) {
            this.setState({
              emailNotVerified: false,
              errors: { global: error.response.data.message },
            });
          }
        });
    }
  };

  done = () => {
    this.close();
  };

  resendActivationMail = () => {
    const params = {
      email: this.state.data.email,
    };
    this.setState({
      loading: true,
    });
    api.user
      .resendActivationMail(params)
      .then((response) => {
        this.notify('A verification mail has been sent to your account.', 'info');
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;

    if (!data.email) {
      errors.email = languageMap.required;
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = languageMap.email;
    }
    return errors;
  };

  render() {
    const languageMap = this.props.languageMap.components.forgotPassword;
    const { data, errors, passwordSent } = this.state;

    return (
      <div className="fairdee-modal-wrapper login-signup small">
        {!passwordSent ? (
          <div className="modal-content forgot-password">
            <img src={cross} className="close" alt="" onClick={this.close} />
            <div className="mobile-header">
              <p className="current-page" onClick={this.close}>
                <img src={crossLg} alt="" />
                <span className="title-text">{languageMap.titleText.textOne}</span>
              </p>
            </div>
            <h1 className="title-text" onClick={this.openLoginSignupModal}>
              {languageMap.titleText.textOne}
            </h1>
            <p className="sub-title">{languageMap.subTitleText}</p>
            <Form noValidate onSubmit={this.onSubmit} autoComplete="off">
              <Form.Field className="mb-0">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={languageMap.inputOne}
                  value={data.email}
                  onChange={this.onChange}
                  autoComplete="off"
                  autoFocus
                />
                <label htmlFor="email">{languageMap.inputOne}</label>
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <div className="button-wrap">
                <button className="button medium" onClick={this.onSubmit}>
                  {languageMap.buttonText}
                </button>
              </div>
              {/* <p className="back-text" onClick={ this.openLoginModal }><img src={ arrowLeft } alt=""/>{ languageMap.actionTextOne.textOne } <a>{ languageMap.actionTextOne.textTwo }</a></p> */}
            </Form>
          </div>
        ) : (
          <div className="modal-content forgot-password">
            <img src={cross} className="close" alt="" onClick={this.close} />
            <div className="mobile-header">
              <p className="current-page" onClick={this.close}>
                <img src={crossLg} alt="" />
                <span className="title-text">{languageMap.titleText.textTwo}</span>
              </p>
            </div>
            <div className="with-padding">
              <p className="sub-title">{languageMap.passwordSent.textOne}</p>
              <div className="img-wrap">
                <img src={successImg} alt="" />
              </div>
              <div className="button-wrap">
                <button className="button medium" onClick={this.done}>
                  {languageMap.passwordSent.buttonText}
                </button>
              </div>
              <p className="back-text" onClick={this.onSubmit}>
                {languageMap.passwordSent.actionTextOne.textOne} <a>{languageMap.passwordSent.actionTextOne.textTwo}</a>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal, showNotification })(ForgotPassword));
