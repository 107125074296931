import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

class MakePaymentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        remark: '',
      },
      errors: {},
    };
  }

  componentDidMount() {}

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">MAke payment</span>
            </p>
          </div>
          <div className="mobile-content">
            <h1 className="title-text">MAke payment</h1>
            <p className="text">Please upload your payment proof by clicking on the button below.</p>
            <div className="content-details-wrap">
              <div className="key-value-wrap">
                <p className="key">Amount : </p>
                <p className="value">18,090 Baht</p>
              </div>
              <div className="key-value-wrap">
                <p className="key cust-width">Account Number :</p>
                <p className="value link">1217826767</p>
              </div>
            </div>
          </div>
          <div className="buttons-wrap single">
            <button className="button medium" onClick={this.close}>
              Upload Payment Proof
            </button>
          </div>
        </div>
      </div>
    );
  }
}

MakePaymentModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(MakePaymentModal));
