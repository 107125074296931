/* global mobileClient */
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { store } from '../index';
import { validHostNames } from './helper';

//Hardcoding for now. Will be changed to pick from a config file
mixpanel.init('184c9deb723214f5772e9320157cb5b9');
const timeObject = {};

let isRegistered = false;

const getDeviceType = () => {
  try {
    if (mobileClient) {
      return 'app';
    }
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'm-web';
    } else {
      return 'web';
    }
  }
};

const shouldLog = (affiliate) => {
  if (affiliate && affiliate.user && !affiliate.user.is_test && validHostNames[window.location.hostname]) {
    return true;
  }
  return false;
};

let actions = {
  register: (affiliate, user) => {
    return;
    affiliate = affiliate || store.getState().affiliate;
    user = store.getState().user || { roles: [] };
    if (isRegistered || !user || !affiliate || !shouldLog(affiliate)) {
      return;
    }
    let roleName = '';
    if (Object.keys(user).length) {
      if (user.roles.includes('admin')) {
        roleName = 'Admin';
      } else if (user.roles.includes('affiliate')) {
        roleName = 'Affiliate';
      }
    }

    if (shouldLog(affiliate)) {
      let masterData = {
        page: window.location.pathname.replace('/', ''),
        platform: getDeviceType(),
      };

      if (affiliate && Object.keys(affiliate).length) {
        mixpanel.people.set_once({
          user_id: affiliate.agent_code,
          affiliate_user_id: affiliate.user.id,
          broker_license_number: affiliate.broker_license_number,
          has_broker_license: affiliate.additional_info || affiliate.additional_info.has_broker_license,
          is_oic_verified: affiliate.is_oic_data_verified,
          platform: getDeviceType(),
          role_name: roleName,
        });
        mixpanel.identify(affiliate.user.id);

        masterData = { ...masterData, page: window.location.pathname.replace('/', ''), platform: getDeviceType() };
      }
      mixpanel.register(masterData);
      isRegistered = true;
    }
  },

  track: (name, event, withTime = false, affiliate = null) => {
    return;
    affiliate = affiliate || store.getState().affiliate;
    if (shouldLog(affiliate)) {
      name = `${name}: ${event.object} ${event.action}`;
      delete event.object;
      delete event.action;
      if (withTime && timeObject[name]) {
        const diff = moment.duration(moment().diff(timeObject[name]));
        event.timeTaken = [diff.hours(), diff.minutes(), diff.seconds()].join(':');
      }
      mixpanel.track(name, event);
    }
  },

  initTimeTrack: (name) => {
    return;
    timeObject[name] = moment();
  },

  resetMixpanelRegister: () => {
    return;
    isRegistered = false;
  },
};

export const Mixpanel = actions;
