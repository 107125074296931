import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';

class WizardStepOneModal extends React.Component {
  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continue = () => {
    this.props.action();
    this.close();
  };

  render() {
    const languageMap = this.props.languageMap.components.wizard.modals.stepOne;

    return (
      <div className="fairdee-modal-wrapper small-medium message">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <p className="title">{languageMap.textOne}</p>
          <p className="text">{languageMap.textTwo}</p>
          <p className="text">{languageMap.textThree}</p>
          <div className="button-wrap">
            <button className="button medium orange-bordered-button" onClick={this.close}>
              {languageMap.textFour}
            </button>
            <button className="button medium" onClick={this.continue}>
              {languageMap.textFive}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

WizardStepOneModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(WizardStepOneModal));
