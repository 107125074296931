import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import validator from 'validator';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { NOTIFICATION } from '../../constants/types';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import { setLanguageToElement } from '../../utils/helper';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        new_password: '',
      },
      errors: {},
      passwordStrength: '',
      showPasswordInfo: false,
    };
  }

  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangePassword = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

    // if (e.target.name === 'new_password') {
    //   this.validatePasswordOnchange(e.target.value);
    // }
  };

  validatePasswordOnchange = (password) => {
    window.console.log(password);
    const specialCharacter = /^[a-zA-Z0-9 ]*$/;

    if (validator.isNumeric(password)) {
      window.console.log(1);
      this.setState({
        passwordStrength: 'weak',
      });
    } else if (password.length < 8) {
      window.console.log(2);
      this.setState({
        passwordStrength: 'weak',
      });
    } else if (specialCharacter.test(password) === true && /[A-Z]/.test(password) === false) {
      window.console.log(3);
      this.setState({
        passwordStrength: 'weak',
      });
    } else if (!/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
      window.console.log(4);
      this.setState({
        passwordStrength: 'weak',
      });
    } else if (password.length >= 8 && password.length < 10) {
      this.setState({
        passwordStrength: 'average',
      });
    } else {
      this.setState({
        passwordStrength: 'strong',
      });
    }
  };

  validatePassword = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;
    // if (!data.old_password) {
    //   errors.old_password = languageMap.required;
    // }
    // if (this.state.passwordStrength === 'weak' || this.state.passwordStrength === '') {
    //   errors.new_password = languageMap.password;
    //   this.setState({
    //     passwordStrength: 'weak'
    //   });
    // }
    if (!data.new_password) {
      errors.new_password = languageMap.required;
    }
    // if (data.new_password_one && data.new_password && data.new_password !== data.new_password_one) {
    //   errors.new_password_one = languageMap.comparePassword;
    // }
    // if (data.old_password && data.new_password && data.new_password === data.old_password) {
    //   errors.new_password = languageMap.oldNewPassword;
    // }
    return errors;
  };

  submit = () => {
    let errors = this.validatePassword(this.state.data);
    const languageMap = this.props.languageMap.messages;
    this.setState({
      errors,
    });
    window.console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }
    const params = {
      new_password: this.state.data.new_password,
    };
    this.setState({
      loading: true,
    });
    api.user
      .changeAffiliatePassword(params)
      .then((resp) => {
        this.setState({
          loading: false,
        });
        this.notify('Password successfully updated', 'success');
        this.close();
      })
      .catch((error) => {
        console.log(error.response);
        // this.notify('Wrong password.', 'error');
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.password
        ) {
          let errors = {
            new_password: error.response.data.response.password[0],
          };
          this.setState({
            errors: errors,
          });
        } else {
          let errors = {
            old_password: languageMap.wrongPassword,
          };
          this.setState({
            errors: errors,
          });
        }
        this.setState({ loading: false });
      });
  };

  togglePasswordInfo = () => {
    this.setState({
      showPasswordInfo: !this.state.showPasswordInfo,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.changePassword;
    const { data, errors, loading } = this.state;

    return (
      <div className="fairdee-modal-wrapper small user-information">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.titleText}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.titleText}</h1>
          <Form noValidate className="quotation-form" autoComplete="off">
            <Form.Field className="mb-0">
              <input
                type="password"
                id="new_password"
                name="new_password"
                placeholder={languageMap.inputTwo}
                value={data.new_password}
                onChange={this.onChangePassword}
                autoComplete="off"
              />
              <label htmlFor="new_password">{languageMap.inputTwo}</label>
              {errors.new_password && <InlineError text={errors.new_password} />}
            </Form.Field>
            {/* <Form.Field>
              <input 
                type="password" 
                id="new_password_one" 
                name="new_password_one" 
                placeholder={ languageMap.inputThree }
                value={ data.new_password_one }
                onChange={ this.onChange }
                autoComplete="off"
              />
              { errors.new_password_one && <InlineError text={ errors.new_password_one } /> }
              <label htmlFor="new_password_one">{ languageMap.inputThree }</label>
            </Form.Field> */}

            <Form.Field>
              <button className={'button primary medium ' + (loading ? 'disabled' : '')} onClick={this.submit}>
                {languageMap.buttonText}
              </button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showNotification })(ChangePassword));
