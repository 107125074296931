import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { clearModal, showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import { RootState } from '../../../index';
import { errorHandler, formatEmail, formatMobile, handlecontactOps, isEmailOtp } from '../../../utils/helper';
import InfoModal from '../common/InfoModal';
import RecaptchaButton from '../common/RecaptchaButton';
import AccountExistsModal from './AccountFoundModal';
import Otp from './Otp';
import RegisterPhone from './RegisterPhone';

interface Props {
  close?: () => void;
  username?: any;
  emailLogin?: boolean;
}

interface ErrorResponse {
  response: any;
}

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const AccountNotFoundModal: React.FC<Props> = ({ close, username, emailLogin }) => {
  const dispatch = useDispatch(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    [disableBtn, setDisableBtn] = useState(false),
    is_email_otp = isEmailOtp(),
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    }),
    captchaSiteKey = RECAPTCHA_SITEKEY;

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

  function openInfoModal(title: string, text: string, btnText: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} btnText={btnText} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function openRegistrationModal() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <RegisterPhone phone={username} />,
        uid: 'REGISTER_PHONE',
        className: 'full-screen',
      })
    );
  }

  function requestOtp(intent: string = '', headers?: any) {
    let params: any = {
      username,
      intent,
      username_type: emailLogin ? 'email' : 'phone',
      is_email_otp: emailLogin ? true : is_email_otp,
    };

    setDisableBtn(true);
    api.user
      .requestOtp(params, headers)
      .then(() => {
        openOtpScreen(intent);
        setDisableBtn(false);
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_exists') {
          openAccountFound();
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          openInfoModal('', languageMap[`Too many OTP requests! Account blocked for 24 hours.`], '');
        } else if (errors && errors.non_field_errors) {
          handlecontactOps(errors.non_field_errors);
        }
        setDisableBtn(false);
      });
  }

  function handleSignup() {
   let headers = {
      headers: {
        'G-Recaptcha-Response': captchaProps.token,
      },
    }
    if (!is_email_otp && !emailLogin) {
      requestOtp('signup', headers);
    } else {
      dispatch(clearModal());
      openRegistrationModal();
    }
  }

  function openOtpScreen(intent: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={username} intent={intent} emailLogin={emailLogin} />,
        uid: 'OTP_MODAL',
      })
    );
  }

  function openAccountFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountExistsModal mobile={username} />,
        uid: 'ACCOUNT_FOUND',
      })
    );
  }
  function renderUsername() {
    let user_name = '';

    if (validator.isNumeric(username)) {
      user_name = formatMobile(username);
    } else {
      user_name = formatEmail(username);
    }

    return user_name;
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps?: any) {
    handleSignup();
  }

  function generateButton() {
    if (!systemSettings.should_validate_recaptcha) {
      return (
        <Button className={'orange-button medium-text ' + (disableBtn ? 'disabled' : '')} onClick={handleSignup}>
          {languageMap[`Sign Up Now`]}
        </Button>
      );
    } else {
      return (
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="button"
            name="sign_up"
            label={languageMap[`Sign Up Now`]}
            disableBtn={disableBtn}
          />
        </GoogleReCaptchaProvider>
      );
    }
  }


  return (
    <div className="fairdee-modal">
      <div className="modal-wrapper">
        <div className="header">
          <p className="title-text border">{languageMap[`Oops! No Account Found.`]} </p>
        </div>
        <div className="content">
          <p className="text">
            {emailLogin
              ? languageMap[`Would you like to Sign up for a Fairdee account using Mobile?`]
              : languageMap[`Would you like to Sign up for a Fairdee account using this mobile number?`]}
          </p>
          <p className="message">{emailLogin ? '' : renderUsername()}</p>
        </div>
        <div className="inline-buttons with-padding">
          <Button
            className="orange-bordered-button medium-text"
            onClick={() => {
              close && close();
            }}
          >
            {emailLogin ? languageMap[`Change Email`] : languageMap[`Change Mobile Number`]}
          </Button>
          {generateButton()}
        </div>
      </div>
    </div>
  );
};

export default AccountNotFoundModal;
