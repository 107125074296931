import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Icon } from 'semantic-ui-react';
import { commaSeperatedNumbers, isAdmin, toThaiDate } from '../../utils/helper';

function EndorsementsList(props) {
  const [activeIndex, setActiveIndex] = useState(props.activeIndex !== undefined ? props.activeIndex : 0);
  const languageMap = useSelector((state) => state.languageMap);
  const endorsementLangMap = languageMap.components.endorsement;
  const modalLangMap = endorsementLangMap.endorsementModal;

  function eachDetail(endorsement) {
    return (
      <div className="even-spaced-content">
        <div>
          <span className="medium-text-light">{modalLangMap.endorsementType}: </span>
          <span className="medium-text-light">
            {endorsementLangMap.typeOfEndorsement[endorsement.type_of_endorsement]}
          </span>
        </div>
        <div>
          <span className="medium-text-light">{endorsementLangMap['Is Endorsement Approved']}: </span>
          <span className="medium-text-light">
            {Boolean(endorsement.is_approved) === true ? modalLangMap.yes : modalLangMap.no}
          </span>
        </div>
        <div>
          <span className="medium-text-light">{modalLangMap.premiumChanged}: </span>
          <span className="medium-text-light">{commaSeperatedNumbers(endorsement.premium_after_tax)}</span>
        </div>
        <div>
          <span className="medium-text-light">{modalLangMap.commissionChanged}: </span>
          <span className="medium-text-light">{commaSeperatedNumbers(endorsement.commission_delta)}</span>
        </div>
        <div>
          <span className="medium-text-light">{modalLangMap.endorsementDetails}: </span>
          <span className="medium-text-light">{endorsement.endorsement_details}</span>
        </div>
        {
          isAdmin() && endorsement.created_by &&
          <div>
            <span className="medium-text-light">Created By: </span>
            <span className="medium-text-light">{`${endorsement.created_by.fullname} (${endorsement.created_by.email})`}</span>
          </div>
        }
        {props.viewMode && isAdmin() && (
          <button className="button medium orange-bordered-button" onClick={(e) => props.action(e, endorsement)}>
            {props.buttonText || 'Update Endorsement'}
          </button>
        )}

        {isAdmin() && (
          <button
            className="button medium orange-bordered-button ml-12"
            onClick={() => props.getHistoricalData('FairdeePolicyEndorsement', endorsement.id)}
          >
            {endorsementLangMap.history}
          </button>
        )}
      </div>
    );
  }

  function getTitleContent(endorsement) {
    let text = '';
    if (endorsement.type_of_endorsement !== 'policy_cancelled') {
      text = `${endorsement.is_compulsory ? endorsementLangMap.compulsory : endorsementLangMap.voluntary} `;
    }
    const labelText = `${text}${endorsementLangMap.typeOfEndorsement[endorsement.type_of_endorsement]} on ${toThaiDate(
      endorsement.endorsement_date
    )}`;

    if (props.onButtonClick) {
      return (
        <>
          <span className="margin-right-24">{labelText}</span>
          <button
            className="button small orange-bordered-button margin-right-24"
            onClick={(e) => props.onButtonClick(e, endorsement)}
          >
            {props.buttonText || 'Update'}
          </button>
          {props.selectedEndorsement.id === endorsement.id && <Icon name="check" color="green" size="large" />}
          <button
            className="button small orange-bordered-button margin-right-24"
            onClick={(e) => props.deleteEndorsement(e, endorsement)}
          >
            {props.deleteText || 'Delete'}
          </button>
        </>
      );
    }

    return labelText;
  }
  return (
    <Accordion vertical className="medium-text-base">
      {props.endorsements.map((endorsement, index) => (
        <>
          <Accordion.Title
            active={activeIndex === index}
            content={getTitleContent(endorsement, index)}
            index={index}
            onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}
            className="margin-0"
          />
          <Accordion.Content active={activeIndex === index} content={eachDetail(endorsement)} />
        </>
      ))}
    </Accordion>
  );
}

export default EndorsementsList;
