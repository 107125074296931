import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import moment from 'moment';
import 'moment/locale/th';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Input, TextArea } from 'semantic-ui-react';
import { userProfile } from '../../actions/auth';
import { setFilter } from '../../actions/filter';
import { setInsurers } from '../../actions/insurers';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { dropdownYears, insureresList } from '../../commonjs/commonData';
import CheckboxGroup from '../../components/CheckboxGroup';
import CoaForm from '../../components/coa/CoaForm';
import Header from '../../components/fairdee-x-plus/common/Header';
import FileUpload from '../../components/FileUpload';
import IncrementDecrementWithInput from '../../components/IncrementDecrementWithInput';
import { ReactBeforeUnload } from '../../components/ReactBeforeUnload';
import SearchList from '../../components/SearchList';
import { APPMAN_ERROR_CODES, DEDUCTION_REASONS, OCR_ALLOWED_FILE_EXT, OCR_FILE_LIMIT} from '../../constants';
import { CONFIRMATION_MODAL, NEW_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import headerBanner from '../../images/hand-time.svg';
import UploadIcon from '../../images/icons/grey-upload.svg';
import { store } from '../../index';
import {
  commaSeperatedNumbers,
  deleteLocalData,
  downloadDocument,
  errorHandler,
  getAffiliateAttributes,
  getFilterData,
  getLineUrl,
  getParameterByName,
  getQuotationQueryId,
  getUrlparamsAsObject,
  inRange,
  isAdmin,
  isLoggedIn,
  isMobileDevice,
  isSuperAdmin,
  objToUrlParams,
  setFilterData,
  setLanguageToElement,
  setLocalData,
  setQuotationQueryId,
  toThaiDateTime,
  getCmiCodeIfRequired,
} from '../../utils/helper';
import { Mixpanel } from '../../utils/tracking';
import { actions, events } from '../../utils/trackingEvents';
import SumInsuredFeedbackModal from './SumInsuredFeedbackModal';

let INSURANCE_CLASSES = [],
  INSURERS = [],
  GARAGE_TYPES = [
    { name: 0, value: 0, id: 0 },
    { name: 1, value: 1, id: 1 },
  ],
  isInstalment = false,
  VEHICLE_CATEGORIES = [];

const INSURERS_LIST = insureresList.map((key) => {
  return { name: key, value: key, id: key };
});

class MakeModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        make: '',
        make_id: '',
        model_description: '',
        model_description_id: '',
        cc: '',
        cc_id: '',
        make_model_id: '',
        manufacture_year: '',
        sum_insured: getFilterData().sum_insured || '',
        insurance_class: [],
        province: '',
        insurer: [],
        current_insurer: '',
        for_commercial_vehicle: '',
        chatwoot_link: '',
        remarks: '',
        vehicle_number: '',
        vmi_vehicle_category: '',
        make_model_vmi_vehicle_category: '',
      },
      suminsuredChanged: false,
      carModel: [],
      carBrand: [],
      carModels: [],
      carCapacity: [],
      carDescription: [],
      errors: {},
      brandAutoFocus: true,
      sumInsured: {
        labels: {
          0: '0',
          3000000: '3000000',
        },
        minimum: null,
        maximum: null,
        recommended: getFilterData().sum_insured || '',
        value: [],
        step: 10000,
      },
      sumInsuredRange: [],
      sum_insured_range_label: '',
      dataHasChanged: false,
      provinces: [],
      currentFocus: null,
      disableButton: true,
      updatedData: {},
      apiLoader: false,
      readMore: false,
      readMoreFqqDocument: false,
      hasOcrDoc: false,
      fileList: {},
      ocrData: {},
      quotationQueryId: null,
    };
    this.sumInsured = {
      minimum: 0,
      maximum: 0,
    };

    this.showCancelButton = true;
    this.imgUrl = getParameterByName('url');

    let openModal = getParameterByName('openmodal'),
      source = getParameterByName('source');

    if (openModal || source === 'line') {
      this.showCancelButton = false;
    }
  }

  componentDidMount() {
    isInstalment = getParameterByName('instalment') === 'true' && isAdmin();
    this.getMakeData();
    this.getProvinces();
    this.getInsurers();
    this.getInsuranceClasses();
    this.getVehicleCategories();
    setLanguageToElement();
    let filterData = getFilterData();
    let data = this.props.data;
    if (Boolean(filterData.is_sale_transfer_flow) === true && filterData.vehicleData) {
      data = filterData.vehicleData;
      // Reset filter data
      filterData.vehicleData = null;
      filterData.is_sale_transfer_flow = null;
      filterData.garage_type = null;
      setFilterData(filterData);
    }

    if (data) {
      this.setState({
        data: { ...this.state.data, ...data },
      });
      if (data.make_id) {
        this.getModelData(data.make_id);
      }
      if (data.make_model_id) {
        this.getCcData(data.make_model_id);
      }
      if (data.cc_id) {
        this.getDescData(data.cc_id);
      }
    }

    this.setState({
      currentFocus: 'make_id',
    });

    const source = getParameterByName('source');

    if (source === 'line') {
      Mixpanel.track('Voluntary Insurance', {
        object: 'Line Login',
        action: 'Landed',
      });
    }

    deleteLocalData('ocrData');

    if (this.imgUrl) {
      this.onUpload('', 'car_registration');
    }
  }

  setApiLoader = (loader) => {
    this.setState({
      apiLoader: loader,
    });
  };

  openSIFeedbackModal = (filterData) => {
    this.props.showModal(NEW_MODAL, {
      component: <SumInsuredFeedbackModal filterData={filterData} />,
      uid: 'SI_FEEDBACK_MODAL',
    });
  };

  getInsuranceClasses = () => {
    api.masterData.getInsuranceClasses().then((resp) => {
      //Reset array before pushing again
      INSURANCE_CLASSES.length = 0;
      resp = resp.results;
      resp = resp.map((data) => {
        INSURANCE_CLASSES.push(data.name);
      });
    });
  };

  getVehicleCategories = () => {
    api.masterData.getVehiclecategories({ product: 'voluntary_insurance' }).then((resp) => {
      VEHICLE_CATEGORIES.length = 0;
      resp = resp.map((data) => {
        VEHICLE_CATEGORIES.push({
          key: data.identifier,
          text: data.identifier,
          name: `${data.identifier}-${data.value}`,
          value: data.identifier,
        });
      });
    });
  };

  getInsurers = () => {
    const params = {
      code: 'th',
    };
    api.masterData
      .getInsurers(params)
      .then((resp) => {
        resp.results.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        let insurersList = resp.results;
        let insurers = {};
        insurersList.forEach((data) => {
          insurers[data.id] = data;
          INSURERS.push({
            ...data,
            value: data.name,
            name: this.props.languageMap.currentInsurerMap[data.name],
          });
        });
        store.dispatch(setInsurers(insurers));
      })
      .catch((error) => errorHandler(error.response, true));
  };

  userProfile = () => {
    this.props
      .userProfile()
      .then(() => {
        if (window.location.pathname === '/' || window.location.pathname === 'blank') {
          this.close();
          this.redirect('/get-quotes');
        }
      })
      .catch((error) => console.log(error));
  };

  getMakeData = () => {
    const params = {
      has_4W: true,
    };
    api.masterData
      .getVehicleMake(params)
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.name = data.value.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carBrand: resp.results,
        });
      })
      .catch((error) => console.log(error));
  };

  getModelData = (id) => {
    api.masterData
      .getVehicleModels(id)
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carModels: resp.results,
        });
      })
      .catch((error) => console.log(error));
  };

  getCcData = (id) => {
    api.masterData
      .getVehicleCapacity(id)
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carCapacity: resp.results,
        });
        if (resp.results.length === 1) {
          this.handleCapacityChange('cc_id', resp.results[0]);
        }
      })
      .catch((error) => console.log(error));
  };

  getDescData = (id = this.state.data.cc_id) => {
    if (!id) {
      return;
    }
    let params = { with_sum_insured: true, manufacture_year: this.state.data.manufacture_year || null };
    api.masterData
      .getVehicleDesc(id, params)
      .then((resp) => {
        if (resp.results.length) {
          resp.results = resp.results.map((data) => {
            data.text = data.name.toLowerCase();
            data.value = data.id;
            return data;
          });
          this.setState(
            {
              carDescription: resp.results,
            },
            () => {
              this.validate(this.state.data);
            }
          );
        }
      })
      .catch((error) => console.log(error));
  };

  getProvinces = () => {
    api.masterData
      .getProvinces()
      .then((provinces) => {
        provinces.map((data) => {
          data.text = data.name.toLowerCase();
          data.name = data.name.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          provinces,
        });
      })
      .catch((error) => console.log(error));
  };

  getSumInsuredRangeBrackets = () => {
    let step = 100000,
      min = Math.round(this.sumInsured.minimum / step),
      max = Math.round(this.sumInsured.maximum / step),
      brackets = [];

    let minMod = this.sumInsured.minimum % step,
      maxMod = this.sumInsured.maximum % step;

    if (minMod >= step / 2) {
      // Start one step ahead
      min++;
    }
    if (maxMod !== 0 && maxMod <= step / 2) {
      // Finish one step back
      max--;
    }

    for (let i = min; i < max; i++) {
      let startValue = i * step,
        endValue = (i + 1) * step;

      if (startValue < this.sumInsured.minimum) {
        startValue = this.sumInsured.minimum;
      }
      if (endValue > this.sumInsured.maximum) {
        endValue = this.sumInsured.maximum;
      }

      brackets.push({
        start: startValue,
        end: endValue,
      });
    }

    return brackets;
  };

  getSumInsured = (params = {}) => {
    if (!Object.keys(params).length) {
      let { cc_id, make_model_id, manufacture_year, insurance_class, model_description_id } = this.state.data;
      if (model_description_id) {
        params.model_desc_id = model_description_id;
      } else if (cc_id) {
        params.cc_id = cc_id;
      } else if (make_model_id) {
        params.make_model_id = make_model_id;
      }
      if (manufacture_year) {
        params.model_year = manufacture_year;
      }

      if (!insurance_class.length || (!params.model_desc_id && !params.cc_id)) {
        return;
      }

      params.insurance_class = insurance_class;
    }

    let { manufacture_year, model_description_id, insurance_class } = this.state.data;

    const selectedDesc = this.state.carDescription.find((desc) => desc.id === model_description_id);
    let has_comprehensive = insurance_class.includes('1') || insurance_class.includes('1 ราคาถูกพิเศษ');
    if (selectedDesc && selectedDesc.sum_insured) {
      const yearSumInsured = selectedDesc.sum_insured.find(
        (si) => si.model_year === manufacture_year && si.recommended != null
      );
      if (yearSumInsured) {
        const siParams = {
          minimum: has_comprehensive ? yearSumInsured.min_suminsured : null,
          maximum: has_comprehensive ? yearSumInsured.max_suminsured : null,
          // Don't populate recommended for sum insured.
          recommended: null,
        };

        const defaultSumInsuredObj = {
          minimum: yearSumInsured.min_suminsured,
          maximum: yearSumInsured.max_suminsured,
          // Don't populate recommended for sum insured.
          recommended: null,
        };

        this.setSumInsuredParams(siParams, defaultSumInsuredObj);

        return;
      }
    }

    api.masterData
      .sumInsured(params)
      .then((resp) => {
        this.setSumInsuredParams(resp.result);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  setSumInsuredParams = (sumInsuredObj, defaultSumInsuredObj) => {
    if (sumInsuredObj.recommended && sumInsuredObj.maximum) {
      sumInsuredObj.recommended = Math.min(sumInsuredObj.recommended, sumInsuredObj.maximum);
    }

    this.sumInsured = sumInsuredObj;
    let filterData = { ...getFilterData(), defaultSumInsured: defaultSumInsuredObj || sumInsuredObj };
    setFilterData(filterData);
    store.dispatch(setFilter(filterData));
    let sumInsuredRange = this.getSumInsuredRangeBrackets().map((row) => {
      let label = `${row.start} - ${row.end}`;

      return {
        name: label,
        value: row,
        id: label,
      };
    });
    this.setState(
      {
        sumInsured: {
          ...this.state.sumInsured,
          ...sumInsuredObj,
          labels: {
            [sumInsuredObj.minimum]: sumInsuredObj.minimum?.toString() || '',
            [sumInsuredObj.maximum]: sumInsuredObj.maximum?.toString() || '',
          },
          value: [sumInsuredObj.minimum, sumInsuredObj.maximum],
        },
        sumInsuredRange,
      },
      () => {
        Mixpanel.track('Voluntary Insurance', {
          object: 'Sum Insured Range',
          action: 'Set',
          actionContext: {
            sum_insured: this.state.sumInsured.value,
          },
        });
      }
    );
    if (this.state.data.insurance_class.includes('1')) {
      let sum_insured = sumInsuredObj.recommended || null;
      this.setState(
        {
          data: { ...this.state.data, sum_insured },
        },
        () => {
          Mixpanel.track('Voluntary Insurance', {
            object: 'Sum Insured',
            action: 'Set',
            actionContext: {
              sum_insured: sum_insured,
            },
          });
        }
      );
    }
  };

  uploadFileApiCall = (params) => {
    return api.utils.uploadDocumentManual(params);
  };

  saveDoc = (quotationQueryId, file, document_type) => {
    let promises = [];
    if (Array.isArray(file)) {
      file.forEach((f) => {
        let params = new FormData();
        params.append('fairdee_quotation_query_id', quotationQueryId);
        params.append('document_type', document_type);
        params.append('file', f);
        promises.push(this.uploadFileApiCall(params));
      });
    } else {
      let params = new FormData();
      params.append('fairdee_quotation_query_id', quotationQueryId);
      params.append('document_type', document_type);
      params.append('file', file);
      promises.push(this.uploadFileApiCall(params));
    }

    return Promise.all(promises);
  };

  postRemarks = (id, remark) => {
    let params = {
      remark: remark,
      is_visible: true,
      data_affected: 'lead_remarks',
      quotation_query: id,
      data_value: remark,
    };
    api.utils.quotationRemark(params).catch((error) => errorHandler(error.response, true));
  };

  quotationQuery = async (filterData, ocrData) => {
    let extra_filters = {},
      remarks;

    // filters added on makemodel popup should also be added here

    if (filterData.min_suminsured) {
      extra_filters.min_suminsured = filterData.min_suminsured;
    }

    if (filterData.max_suminsured) {
      extra_filters.max_suminsured = filterData.max_suminsured;
    }
    if (filterData.driver_discount_eligible) {
      extra_filters.avail_driver_discount = filterData.driver_discount_eligible[0] === 'true';
    }
    if (filterData.camera_discount_eligible) {
      extra_filters.avail_camera_discount = filterData.camera_discount_eligible[0] === 'true';
    }
    if (filterData.garage_type && filterData.garage_type.length) {
      extra_filters.garage_type = filterData.garage_type;
    }
    if (filterData.province) {
      extra_filters.province = filterData.province;
    }
    let params = {
      make_model: filterData.vehicleData.make_model_id,
      cc: filterData.vehicleData.cc_id,
      year: filterData.vehicleData.manufacture_year,
      extra_filters,
      price_list_ids: this.idList,
      cheap_price_ids: this.cheapIdList,
      model_description: filterData.vehicleData.model_description_id,
      for_commercial_vehicle: Boolean(filterData.vehicleData.for_commercial_vehicle),
      vmi_car_code: filterData.vehicleData.vmi_vehicle_category,
      extra_fields: {},
    };
    
    const vmiVehicleCategory = filterData?.vehicleData?.vmi_vehicle_category,
    defaultVmiVehicleCategory = filterData?.vehicleData?.make_model_vmi_vehicle_category;
    params.cmi_car_code = getCmiCodeIfRequired(vmiVehicleCategory,defaultVmiVehicleCategory);
    
    if (filterData.vehicleData.vehicle_number) {
      params.vehicle_number = filterData.vehicleData.vehicle_number;
    }
    if (filterData.sum_insured) {
      params.sum_insured = filterData.sum_insured;
    }
    if (filterData.insurance_class && filterData.insurance_class.length) {
      params.insurance_class = filterData.insurance_class.join(',');
    }
    if (filterData.insurer) {
      params.extra_fields['insurer'] = filterData.insurer?.[0];
    }
    if (filterData.chatwoot_link) {
      params.extra_fields['chatwoot_link'] = filterData.chatwoot_link;
    }
    params.selected_price_list_ids = [];

    params.selected_cheap_price_ids = [];
    if (filterData.driver_discount_eligible) {
      params.avail_driver_discount = filterData.driver_discount_eligible[0] === 'true';
    }
    if (filterData.camera_discount_eligible) {
      params.avail_camera_discount = filterData.camera_discount_eligible[0] === 'true';
    }
    if (filterData.current_insurer) {
      params.current_insurer = filterData.current_insurer;
    }
    if (filterData.remarks) {
      remarks = filterData.remarks;
      delete filterData.remarks;
    }

    if (isInstalment) {
      params.will_pay_in_instalments = true;

      let vendor = getParameterByName('vendor');
      if (vendor) {
        params.vendor = parseInt(vendor);
      }
    }

    if (ocrData) {
      params = { ...params, car_registration_ocr_data_id: ocrData.make_ocrId };
    }

    await api.utils
      .quotationQuery(params, getParameterByName('affiliate'))
      .then(async (resp) => {
        let params = {
          quotation_query_id: resp.message.id,
          deduction_reason: DEDUCTION_REASONS['Create Quotation'],
        };
        await api.utils
          .updateSelfServe(params)
          .then((res) => {
            if (remarks) {
              this.postRemarks(resp.message.id, remarks);
            }
            setQuotationQueryId(resp.message.id);
            this.setState({
              quotationQueryId: resp.message.id,
            });
            if (this.state.fileList && this.state.fileList['car']) {
              this.saveDoc(resp.message.id, this.state.fileList['car'], 'car');
            }
            let type = getParameterByName('type');
            if (type === 'coa') {
              this.openCoaForm(resp.message.id);
            }
          })
          .catch((error) => errorHandler(error.response, true));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.lead_gen_code
        ) {
          this.openLeadGenCodeModal();
        }
        errorHandler(error.response, true);
      });
  };

  handleMakeChange = (key, data) => {
    if (this.state.data.make_id && this.state.data.make_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '';
    let i = 0;
    for (i = 0; i < this.state.carBrand.length; i++) {
      if (this.state.carBrand[i].id === id) {
        text = this.state.carBrand[i].name;
      }
    }
    this.setState(
      {
        data: { ...this.state.data, make_id: id, make: text, make_model_id: '', cc_id: '', model_description_id: '' },
        updatedData: {
          ...this.state.updatedData,
          make_id: id,
          make: text,
          make_model_id: '',
          cc_id: '',
          model_description_id: '',
        },
        carModels: [],
        carCapacity: [],
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'make_model_id' : 'make_id',
      },
      () => {
        this.validate(this.state.data);
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiBrand + actions.Selected, {
            make: text,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );
    if (!data.value || !id) {
      return;
    }
    this.getModelData(id);
  };

  handleModelChange = (key, data) => {
    if (this.state.data.make_model_id && this.state.data.make_model_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '',
      vmiVehicleCategory = '';
    let i = 0;
    for (i = 0; i < this.state.carModels.length; i++) {
      if (this.state.carModels[i].id === id) {
        text = this.state.carModels[i].name;
        vmiVehicleCategory = this.state.carModels[i].vmi_vehicle_category;
      }
    }
    this.setState(
      {
        data: {
          ...this.state.data,
          make_model_id: id,
          make_model: text,
          cc_id: '',
          model_description_id: '',
          vmi_vehicle_category: vmiVehicleCategory,
          make_model_vmi_vehicle_category: vmiVehicleCategory,
        },
        updatedData: {
          ...this.state.updatedData,
          make_model_id: id,
          make_model: text,
          cc_id: '',
          model_description_id: '',
        },
        carCapacity: [],
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'cc_id' : 'make_model_id',
      },
      () => {
        this.validate(this.state.data);
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiModel + actions.Selected, {
            model: text,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );
    if (!data.value || !id) {
      return;
    }
    this.getCcData(id);
  };

  handleModelDescriptionChange = (key, data) => {
    if (this.state.data.model_description_id && this.state.data.model_description_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '';
    let i = 0;
    for (i = 0; i < this.state.carDescription.length; i++) {
      if (this.state.carDescription[i].id === id) {
        text = this.state.carDescription[i].name;
      }
    }
    this.setState(
      {
        data: { ...this.state.data, model_description: text, model_description_id: id },
        updatedData: { ...this.state.updatedData, model_description_id: id, model_description: text },
        dataHasChanged: true,
        currentFocus: id ? 'insurance_class' : 'model_description_id',
      },
      () => {
        this.validate(this.state.data);
        this.getSumInsured({}, true);
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiSubModel + actions.Selected, {
            subModel: text,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );

    if (!data.value) {
      return;
    }
  };

  handleCapacityChange = (key, data) => {
    if (this.state.data.cc_id && this.state.data.cc_id === data.value) {
      return;
    }

    const id = data.value;
    let text = '';
    let i = 0;
    for (i = 0; i < this.state.carCapacity.length; i++) {
      if (this.state.carCapacity[i].id === id) {
        text = this.state.carCapacity[i].name;
      }
    }
    this.setState(
      {
        data: { ...this.state.data, cc: text, cc_id: id, model_description_id: '' },
        updatedData: { ...this.state.updatedData, cc: text, cc_id: id, model_description_id: '' },
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'manufacture_year' : 'cc_id',
      },
      () => {
        this.validate(this.state.data);
        if (!data.value) {
          return;
        }
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiCC + actions.Selected, {
            cc: text,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
        if (this.state.data.manufacture_year) {
          this.getDescData();
        }
      }
    );
  };

  handleYearChange = (e, data) => {
    if (data.value && data.value < 2000) {
      INSURANCE_CLASSES = ['1', '3', '2+', '3+', '1 ราคาถูกพิเศษ', '2+ ราคาถูกพิเศษ', '3+ ราคาถูกพิเศษ'];
    } else {
      INSURANCE_CLASSES = ['1', '2', '3', '2+', '3+', '1 ราคาถูกพิเศษ', '2+ ราคาถูกพิเศษ', '3+ ราคาถูกพิเศษ'];
    }
    this.setState(
      {
        data: { ...this.state.data, manufacture_year: data.value },
        data: { ...this.state.data, manufacture_year: data.value },
        dataHasChanged: true,
        currentFocus: data.value ? 'model_description_id' : 'manufacture_year',
      },
      () => {
        this.validate(this.state.data);
        if (!data.value) {
          return;
        }
        this.getSumInsured();
        this.getDescData();
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiYear + actions.Selected, {
            year: data.value,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );
  };

  handleInsurerChange = (e, name, data) => {
    let value;
    if (name === 'insurer') {
      value = [data.value];
    } else {
      value = data.value;
    }
    this.setState(
      {
        data: { ...this.state.data, [name]: value },
        updatedData: { ...this.state.updatedData, [name]: value },
        dataHasChanged: true,
        currentFocus: name,
      },
      () => {
        this.validate(this.state.data);
        window.trackEvent &&
          name === 'current_insurer' &&
          window.trackEvent.track(actions.Selected, events.vmiPreviousInsurer + actions.Selected, {
            previousInsurer: data.value,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );
  };

  handleProvinceChange = (e, data) => {
    this.setState(
      {
        data: { ...this.state.data, province: data.value },
        updatedData: { ...this.state.updatedData, province: data.value },
        dataHasChanged: true,
        currentFocus: data.value ? 'current_insurer' : 'province',
      },
      () => {
        this.validate(this.state.data);
        window.trackEvent &&
          window.trackEvent.track(actions.Selected, events.vmiCarRegistrationProvince + actions.Selected, {
            carRegistrationProvince: data.value,
            quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
            ...getAffiliateAttributes(),
          });
      }
    );
  };

  handleGarageTypeChange = (e, data) => {
    window.trackEvent &&
      window.trackEvent.track(actions.Selected, events.vmiGarageType + actions.Selected, {
        garageType: data.value,
        quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
        ...getAffiliateAttributes(),
      });
    this.setState(
      {
        data: { ...this.state.data, garage_type: data.value },
        updatedData: { ...this.state.updatedData, garage_type: data.value },
        dataHasChanged: true,
        currentFocus: 'garage_type',
      },
      () => {
        this.validate(this.state.data);
      }
    );
  };

  handleVmiCarCodeChange = (key, data) => {
    if (this.state.data.vmi_vehicle_category && this.state.data.vmi_vehicle_category === data.value) {
      return;
    }
    this.setState(
      {
        data: { ...this.state.data, vmi_vehicle_category: data.value },
        updatedData: { ...this.state.updatedData, vmi_vehicle_category: data.value },
        dataHasChanged: true,
        currentFocus: 'vmi_vehicle_category',
      },
      () => {
        this.validate(this.state.data);
      }
    );
  };

  handleCheckboxChange = (data, existingClasses, elementData) => {
    let insurance_class = data;
    if (elementData) {
      if (
        insurance_class.indexOf('1') > -1 &&
        insurance_class.indexOf('1 ราคาถูกพิเศษ') === -1 &&
        existingClasses.indexOf('1 ราคาถูกพิเศษ') === -1 &&
        elementData.value === '1'
      ) {
        insurance_class.push('1 ราคาถูกพิเศษ');
      }

      if (
        insurance_class.indexOf('2+') > -1 &&
        insurance_class.indexOf('2+ ราคาถูกพิเศษ') === -1 &&
        existingClasses.indexOf('2+ ราคาถูกพิเศษ') === -1 &&
        elementData.value === '2+'
      ) {
        insurance_class.push('2+ ราคาถูกพิเศษ');
      }

      if (
        insurance_class.indexOf('3+') > -1 &&
        insurance_class.indexOf('3+ ราคาถูกพิเศษ') === -1 &&
        existingClasses.indexOf('3+ ราคาถูกพิเศษ') === -1 &&
        elementData.value === '3+'
      ) {
        insurance_class.push('3+ ราคาถูกพิเศษ');
      }
    }
    if (insurance_class.length > existingClasses.length) {
      let diffenerce = insurance_class.filter((ele) => !existingClasses.includes(ele));
      window.trackEvent &&
        window.trackEvent.track(actions.Selected, `${events.vmiInsuranceClass}  ${actions.Selected}`, {
          insuranceClass: diffenerce.join(','),
          quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
          ...getAffiliateAttributes(),
        });
    }
    if (existingClasses.length > insurance_class.length) {
      let diffenerce = existingClasses.filter((ele) => !insurance_class.includes(ele));
      window.trackEvent &&
        window.trackEvent.track(actions.Selected, `${events.vmiInsuranceClass} ${actions.Selected}`, {
          insuranceClass: diffenerce.join(','),
          quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
          ...getAffiliateAttributes(),
        });
    }
    this.setState(
      {
        data: { ...this.state.data, insurance_class },
        updatedData: { ...this.state.updatedData, insurance_class },
        currentFocus: 'province',
      },
      () => {
        this.validate(this.state.data);
        if (!this.state.data.insurance_class.includes('1')) {
          this.setState({
            data: { ...this.state.data, sum_insured: null },
            autoFocus: 'sum_insured',
          });
        }
        this.getSumInsured();
      }
    );
  };

  handleSuminsuredChange = (val) => {
    window.trackEvent &&
      window.trackEvent.track(actions.Selected, events.vmiSumInsured + actions.Selected, {
        sumInsured: val,
        quotation_type: getParameterByName('type') === 'coa' ? 'coa' : 'Fresh Quote',
        ...getAffiliateAttributes(),
      });
    this.setState(
      {
        data: { ...this.state.data, sum_insured: val },
        updatedData: { ...this.state.updatedData, sum_insured: val },
      },
      () => {
        this.validate(this.state.data);
      }
    );
  };

  handleChange = (name) => (event) => {
    if (!event) {
      // Happening in case of date picker.
      return;
    }
    let value = null;
    if (moment.isMoment(event)) {
      value = event;
    } else if (name === 'for_commercial_vehicle') {
      value = event.target.value === 'true';
    } else {
      value = event.target.value;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
      currentFocus: 'province',
    });
  };

  handleTextChange = (event) => {
    if (!event) {
      return;
    }
    const name = event.target.name,
      value = event.target.value;
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
      currentFocus: name,
    });
  };

  validate = (data, child = false) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;
    let disableButton = false;

    if (!data.make_id) {
      errors.make_id = languageMap.required;
    }
    if (!data.model_description_id) {
      errors.model_description_id = languageMap.required;
    }
    if (!data.manufacture_year) {
      errors.manufacture_year = languageMap.required;
    }
    if (!data.insurance_class.length && !this.props.showHeader) {
      errors.insurance_class = languageMap.required;
    }
    if (!data.vmi_vehicle_category) {
      errors.vmi_vehicle_category = languageMap.required;
    }
    disableButton = Object.keys(errors).length ? true : false;
    this.setState({
      disableButton,
    });
    if (child) {
      this.setState({
        errors,
      });
    }
    return errors;
  };

  checkIfSimilarQuotationExist = async () => {
    const params = this.state.data,
      affiliateId = getParameterByName('affiliate');

    if (affiliateId) {
      params.affiliate_id = affiliateId;
    }

    let similarQuotation;
    await api.utils
      .checkMultipleQuotation(params)
      .then((response) => {
        if (response.id) {
          similarQuotation = response;
        }
      })
      .catch((_error) => {});

    if (similarQuotation) {
      this.openSimilarQuotationFoundModal(similarQuotation);
    } else {
      this.getQuote();
    }
  };

  // data:-> Similar Fairdee Quotation existing in the system.
  openSimilarQuotationFoundModal = (data) => {
    const forBike = data.for_motorcycle,
      quotationQuery = data,
      getQuotesUrl = `/get-quotes?quotation_query_id=${data.id}&affiliate=${data.affiliate.id}`;
    let wizardUrl = '';

    if (data.quotation) {
      wizardUrl = `/fairdee-wizard?id=${data.quotation.id}${forBike ? '&=forBike=true' : ''}`;
    }

    this.props.showModal(CONFIRMATION_MODAL, {
      text: `Found quotation for same vehicle for ${data.affiliate ? `[${data.affiliate.agent_code}]` : null}`,
      subText: `System already has a quotation with below details are you sure you want to create new one?`,
      boldDescriptionText: `Vehicle Details: ${data.vehicle_number || ''}
        ${quotationQuery.model_description?.make?.name || ''} ${
        quotationQuery.model_description?.make_model?.name || ''
      }
        ${quotationQuery.model_description?.cc?.name + 'cc' || ''} ${quotationQuery?.year || ''}
        ${' '}
        ${quotationQuery.model_description?.value || ''}`,
      lightDescriptionText: `Created By: ${data.created_by?.fullname} Created on: ${toThaiDateTime(data.created_at)}`,
      acceptText: 'View Same Quotation',
      secondaryAcceptText: 'Create New Quotation',
      confirmText: `Are you sure you want to continue?`,
      action: () => this.props.history.push(wizardUrl || getQuotesUrl),
      otherAction: this.getQuote,
      hideCancel: true,
      leftAlign: true,
      acceptButtonClass: 'orange-bordered-button',
      buttonClass: 'full-width',
    });
  };

  getVehicleNumber = () => {
    if (this.state.data.vehicle_number) {
      return this.state.data.vehicle_number;
    } else if (this.state.ocrData.vehicle_number) {
      return this.state.ocrData.vehicle_license_number.replace(' ', '');
    } else if (this.state.ocrData?.vehicle_license_number) {
      return this.state.ocrData.vehicle_license_number;
    }
  };

  getQuote = async () => {
    const errors = this.validate(this.state.data);
    Mixpanel.track('Voluntary Insurance', {
      object: 'Get Quote Button',
      action: 'Clicked',
      actionContext: {
        errors: errors,
      },
    });
    window.trackEvent &&
      window.trackEvent.track(actions.Clicked, events.vmiGetQuotes + actions.Clicked, {
        ...getAffiliateAttributes(),
      });
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) {
      return;
    }
    this.setState({
      disableButton: true,
    });
    const make_model_vmi_vehicle_category = this.state.data.make_model_vmi_vehicle_category;
    setLocalData('make_ocr', { ...this.state.data, ...this.state.ocrData });

    let filterData = {
      ...getFilterData(),
      vehicleData: {
        ...this.state.data,
        vehicle_number: this.getVehicleNumber(),
      },
      insurance_class: this.state.data.insurance_class,
      province: this.state.data.province,
      current_insurer: this.state.data.current_insurer,
      insurer: this.state.data.insurer,
      chatwoot_link: this.state.data.chatwoot_link,
      remarks: this.state.data.remarks,
      make_model_vmi_vehicle_category: make_model_vmi_vehicle_category,
    };

    if (!this.props.showHeader) {
      if (this.state.data.sum_insured) {
        filterData.sum_insured = this.state.data.sum_insured;
      } else {
        delete filterData.sum_insured;
        filterData.min_suminsured = this.state.sumInsured.minimum || null;
        if (this.state.sumInsured.recommended && this.state.sumInsured.maximum) {
          filterData.max_suminsured = Math.min(this.state.sumInsured.maximum, this.state.sumInsured.recommended * 1.1);
        } else {
          filterData.max_suminsured = this.state.sumInsured.maximum || null;
        }
      }
    }
    setFilterData(filterData);
    store.dispatch(setFilter(filterData));
    await this.quotationQuery(filterData, this.state.ocrData);

    if (
      (isAdmin() || isSuperAdmin()) &&
      this.state.sumInsured.minimum &&
      this.state.sumInsured.maximum &&
      this.state.data.sum_insured
    ) {
      if (!inRange(this.state.data.sum_insured, this.state.sumInsured.minimum, this.state.sumInsured.maximum)) {
        this.openSIFeedbackModal(filterData);
      }
    }
    if (this.props.action) {
      this.props.action(this.state.dataHasChanged);
      if (isMobileDevice()) {
        this.close();
      }
    } else {
      this.close('/get-quotes');
    }

    Mixpanel.track('Voluntary Insurance', {
      object: 'Make Model Combined',
      objectContext: {
        'Make Id': this.state.data.make_id,
        'Model Id': this.state.data.make_model_id,
        'CC ID': this.state.data.cc_id,
        Year: this.state.data.manufacture_year,
      },
      action: 'Selected',
    });
  };

  redirect = (to) => {
    const quotationQueryId = this.state.quotationQueryId || getQuotationQueryId();
    let params = getUrlparamsAsObject();
    let passableParams = [
      'ref',
      'affiliate',
      'vendor',
      'instalment',
      'lg_code',
      'old_quotation_id',
      'quotation_query_id',
      'type',
      'vmi_vehicle_category',
    ];

    Object.keys(params).forEach((key) => {
      if (!passableParams.includes(key)) {
        delete params[key];
      }
    });
    if (params.instalment) {
      params.instalment = params.instalment === 'true';
    }
    if (quotationQueryId) {
      params.quotation_query_id = quotationQueryId;
    }

    if (!params.vmi_vehicle_category && this.state.data.vmi_vehicle_category) {
      params.vmi_vehicle_category = this.state.data.vmi_vehicle_category;
    }

    let pathname = to;
    let search = objToUrlParams(params);

    if (search) {
      this.props.history.push({
        pathname,
        search,
      });
    } else {
      this.props.history.push(to);
    }
  };

  close = (to) => {
    if (to) {
      this.redirect(to);
      this.props.hideModal(null, {
        ...this.props,
      });
      return;
    }
    if (isLoggedIn() && !this.props.data) {
      this.redirect('/policy-information');
    } else if (!this.props.data) {
      this.redirect('/');
    }
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleClose = () => {
    this.props.history.push('/buy-insurance');
  };

  renderHeader = () => {
    const languageMap = this.props.languageMap.components.makeModel,
      from = getParameterByName('from'),
      type = getParameterByName('type');

    if (from === 'dashboard' || type === 'coa') {
      return <Header text={languageMap.titleText} action={this.handleClose} className="make-model-header" />;
    }

    if (isMobileDevice()) {
      if (this.props.showHeader) {
        return (
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textFive}</span>
            </p>
          </div>
        );
      }
      return (
        <div className="header-wrap">
          <p className="text">{languageMap.textThree}</p>
        </div>
      );
    }
    return (
      <div className="header-wrap">
        <img src={headerBanner} alt="" />
        <div>
          <p className="text">{languageMap.textOne}</p>
          <p className="text">{languageMap.textFour}</p>
        </div>
      </div>
    );
  };

  renderSumInsuredRanage = () => {
    const { sumInsured } = this.state,
      languageMap = this.props.languageMap.components.makeModel;
    if (sumInsured.minimum && sumInsured.maximum) {
      return (
        <p className="title">
          {`${languageMap.modelText.textFive} (${commaSeperatedNumbers(sumInsured.minimum)} - ${commaSeperatedNumbers(
            sumInsured.maximum
          )}) `}
        </p>
      );
    } else {
      return (
        <div className="info-content-wrapper">
          <p className="header-text sum-insured-description">
            {languageMap['The system does not have a recommended SI for this vehicle. Please contact staff']}
            <a className="link-text" href={getLineUrl()} target="_blank">
              {languageMap['here']}
            </a>
          </p>
        </div>
      );
    }
  };

  renderUploadDocRequirement(readMore) {
    const languageSystem = store.getState().language;
    let enReq = [
        'Image consists of 2 pages within 1 image - the Car Registration page & the Car Owner page',
        'All text on the image must be clear and readable',
        'File types accepted - .jpg, .jpeg, .png, .bmp',
        'File size must not exceed 10 MB',
      ],
      thReq = [
        'รูปเล่มทะเบียนรถต้องประกอบด้วย 2 ส่วนคือ หน้ารายการจดทะเบียน และ หน้าเจ้าของรถ (ร่วมกันใน 1 รูป)',
        'ตัวอักษรในรูปต้องชัดเจนและสมบูรณ์',
        'สกุลไฟล์ต้องเป็น .jpg, .jpeg, .png, .bmp',
        'ขนาดไฟล์ห้ามเกิน 10 MB',
      ];

    if (!readMore) {
      return <li>{languageSystem === 'thai' ? thReq[0] : enReq[0]}</li>;
    } else {
      if (languageSystem === 'thai') {
        return thReq.map((item, index) => {
          return <li key={index}>{item}</li>;
        });
      } else {
        return enReq.map((item, index) => {
          return <li key={index}>{item}</li>;
        });
      }
    }
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  setReadmore = () => {
    this.setState({
      readMore: !this.state.readMore,
    });
  };

  setReadmoreFqqDocument = () => {
    this.setState({
      readMoreFqqDocument: !this.state.readMoreFqqDocument,
    });
  };

  uploadFile = (params, type, file) => {
    this.setState({
      apiLoader: true,
    });
    api.utils
      .uploadOcr(params)
      .then((resp) => {
        const languageMapError = this.props.languageMap.messages;
        if (Object.keys(resp.vendor_response).some((r) => APPMAN_ERROR_CODES.includes(r))) {
          this.notify(languageMapError[`Image failed to meet OCR requirements, please try again`], 'error');

          this.setState({
            apiLoader: false,
          });
        } else {
          // Hide the description after success upload
          let fileList = { ...this.state.fileList };
          if (file) {
            file.id = resp.file.id;
            file.make_ocrId = resp.id;
            file.file_url = resp.file_url;

            if (!fileList[type]) {
              fileList[type] = [file];
            } else {
              fileList[type].push(file);
            }
          }
          // This part needed for activate the download function

          let ocrData = { make_ocrId: resp.id, file_type: type, result: resp.vendor_response.result };
          this.setState({
            fileList,
            hasOcrDoc: true,
            ocrData,
            apiLoader: false,
          });
        }
      })
      .catch((error) => {
        // handling error from our server
        errorHandler(error.response, true);

        this.setState({
          apiLoader: false,
        });
      });
  };

  onUpload = (file, name) => {
    let fileSizeLimit = OCR_FILE_LIMIT;
    if (this.imgUrl || (file?.size < fileSizeLimit && OCR_ALLOWED_FILE_EXT.includes(file?.type))) {
      let params = new FormData();
      if (this.imgUrl) {
        params.append('url', this.imgUrl);
      } else {
        params.append('file', file);
      }

      params.append('file_type', name);
      params.append('affiliate_id', getParameterByName('affiliate'));
      this.uploadFile(params, name, file);
    } else {
      this.notify(this.props.languageMap.components.makeModel.OcrFailedReq, 'error');
    }
  };

  onRemove = () => {
    this.confirmDelete(this.state.ocrData);
  };

  storeFileInState = (file, name) => {
    const fileSizeLimit = OCR_FILE_LIMIT,
      fileList = this.state.fileList;
    if (file?.size < fileSizeLimit && OCR_ALLOWED_FILE_EXT.includes(file?.type)) {
      if (!fileList[name]) {
        fileList[name] = [file];
      } else {
        fileList[name].push(file);
      }

      this.setState({ fileList });
    } else {
      this.notify(this.props.languageMap.components.makeModel.documentUploadFailedReq, 'error');
    }
  };

  removeFileFromStore = (data, name) => {
    let fileListTemp = this.state.fileList;
    fileListTemp[name] = fileListTemp[name].filter((_file, index) => index !== data.index);
    this.setState({
      fileList: fileListTemp,
    });
  };

  confirmDelete = (data) => {
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteOCRFile,
      data,
    });
  };

  deleteOCRFile = (ocrData) => {
    api.utils.deleteOcr(ocrData.make_ocrId).then(() => {
      let fileList = this.state.fileList;
      fileList[ocrData.file_type] = fileList[ocrData.file_type].filter((d) => d.make_ocrId !== ocrData.make_ocrId);
      this.setState({
        fileList,
        hasOcrDoc: false,
        ocrData: {},
      });
    });
  };

  readOCR = async () => {
    if (this.state.hasOcrDoc) {
      const yearList = dropdownYears();

      await this.state.carBrand.forEach((item) => {
        if (item.name === this.state.ocrData.result.vehicle_brand?.toLowerCase() && !this.state.data.make_id) {
          this.handleMakeChange(null, item);
        }
      });
      await this.state.carModels.forEach((item) => {
        if (item.name === this.state.ocrData.result.vehicle_model?.toLowerCase() && !this.state.data.make_model_id) {
          this.handleModelChange(null, item);
        }
      });

      if (this.state.carCapacity.length > 0) {
        // This part will work (will prefilled) after user choose Model or model has a value

        const vehicle_displacement = Number(this.state.ocrData.result.vehicle_displacement);
        const closestCapacity = this.state.carCapacity.reduce(function (prev, curr) {
          return Math.abs(Number(curr.name) - vehicle_displacement) < Math.abs(Number(prev.name) - vehicle_displacement)
            ? curr
            : prev;
        });
        this.handleCapacityChange(null, closestCapacity);
      }

      if (!this.state.data.manufacture_year) {
        yearList.filter((item) => {
          if (item.name === Number(this.state.ocrData.result.vehicle_model_year)) {
            this.handleYearChange(null, item);
          }
        });
      }

      if (!this.state.data.province) {
        this.state.provinces.filter((item) => {
          if (item.name === this.state.ocrData.result.vehicle_license_province) {
            this.handleProvinceChange(null, item);
          }
        });
      }
    }
  };

  downloadOcrDocument = (data) => {
    if (data && data.file_url) {
      downloadDocument(data.file_url);
    }
  };

  renderOcrUpload = () => {
    const languageMap = this.props.languageMap.components.makeModel,
      { fileList } = this.state;

    return (
      <section className="ocr-form">
        <label>{languageMap.OcrLabel}</label>
        <div className="upload-wrapper">
          {!this.state.hasOcrDoc && !this.state.apiLoader && (
            <>
              <div className="icon">
                <img src={UploadIcon} />
              </div>

              <div className="description-wrap">
                <p className="title">{languageMap.OcrTitle}</p>
                <ol>
                  {' '}
                  {languageMap.OcrDescription}
                  {this.renderUploadDocRequirement(this.state.readMore)}
                  <p className="read-more" onClick={this.setReadmore}>
                    {this.state.readMore ? languageMap.OcrReadLess : languageMap.OcrReadMore}
                  </p>
                </ol>
              </div>
            </>
          )}
          <div className="upload-btn">
            <FileUpload
              download={true}
              files={fileList['car_registration']}
              loading={this.state.apiLoader}
              name="car_registration"
              onFileUpload={this.onUpload}
              onFileRemove={this.onRemove}
              limit={1}
              accept="image/jpg, image/jpeg,image/png, image/bmp"
              action={this.downloadOcrDocument}
            />
          </div>
        </div>
      </section>
    );
  };

  renderFqqDocumentUpload = () => {
    const languageMap = this.props.languageMap.components.makeModel,
      { fileList } = this.state;

    return (
      <section className="ocr-form">
        <label>{languageMap.rfScDocumentUpload}</label>
        <div className="upload-wrapper">
          {!this.state.apiLoader && (
            <>
              <div className="icon">
                <img src={UploadIcon} />
              </div>

              <div className="description-wrap">
                <p className="title">{languageMap.rfDocument}</p>
                <ol>
                  {' '}
                  {languageMap.OcrDescription}
                  {this.renderUploadDocRequirement(this.state.readMoreFqqDocument)}
                  <p className="read-more" onClick={this.setReadmoreFqqDocument}>
                    {this.state.readMoreFqqDocument ? languageMap.OcrReadLess : languageMap.OcrReadMore}
                  </p>
                </ol>
              </div>
            </>
          )}
          <div className="upload-btn">
            <FileUpload
              download={true}
              files={fileList['car']}
              loading={this.state.apiLoader}
              name="car"
              onFileUpload={this.storeFileInState}
              onFileRemove={(data) => this.removeFileFromStore(data, 'car')}
              limit={5}
              accept="image/jpg, image/jpeg,image/png, image/bmp"
              action={this.downloadOcrDocument}
              display="block" //Property for display example flex, block etc.
            />
          </div>
        </div>
      </section>
    );
  };

  render() {
    this.readOCR();
    const { errors, sumInsuredRange, sumInsured, data, provinces, currentFocus, disableButton, updatedData, fileList } =
      this.state;
    const languageMap = this.props.languageMap.components.makeModel;
    const messages = this.props.languageMap.messages;
    const existingClasses = this.state.data.insurance_class.slice();
    const garageTypeMap = this.props.languageMap.components.insurerCard.garageTypeMap;

    GARAGE_TYPES.map((type) => {
      type.name = garageTypeMap[type.value];
    });

    let type = getParameterByName('type');

    let sumInsuredListLabel = languageMap.searchTextObj.textFive;
    if (sumInsured.recommended) {
      if (sumInsured.recommended > sumInsured.maximum && sumInsuredRange.length) {
        // Recommend maximum range
        let recommendedRange = sumInsuredRange[sumInsuredRange.length - 1],
          { start, end } = recommendedRange.value;

        sumInsuredListLabel += ` (${languageMap.searchTextObj.textSeven} ${start} - ${end})`;
      } else if (sumInsured.recommended > sumInsured.minimum) {
        sumInsuredListLabel += ` (${languageMap.searchTextObj.textSix}: ${sumInsured.recommended})`;
      }
    }
    let step = 100000;

    if (data.insurance_class.includes('1')) {
      step = 10000;
    }

    return (
      <div className={'make-model ' + (this.props.openModal ? 'fairdee-modal-wrapper' : '')}>
        <ReactBeforeUnload
          onBeforeunload={() => (Object.keys(updatedData).length ? messages.loseData : null)}
          {...this.props}
        />
        {this.renderHeader()}
        <div className={'make-modal-wrap ' + (this.props.showHeader ? 'with-margin' : '')}>
          <Form noValidate className="quotation-form" autoComplete="off">
            {isAdmin() || isSuperAdmin() ? this.renderOcrUpload() : null}
            {isAdmin() || isSuperAdmin() ? this.renderFqqDocumentUpload() : null}
            <SearchList
              id="make_id"
              label={languageMap.modelText.textOne + '<span>*</span>'}
              onChange={this.handleMakeChange}
              options={this.state.carBrand}
              listLabel={languageMap.searchTextObj.textOne}
              value={this.state.data.make_id}
              name="make_id"
              search={true}
              isOpen={false}
              error={errors.make_id ? true : false}
              helpertext={errors.make_id}
              autoFocus={Boolean(currentFocus === 'make_id')}
            />

            <SearchList
              id="make_model_id"
              label={languageMap.modelText.textTwo + '<span>*</span>'}
              onChange={this.handleModelChange}
              options={this.state.carModels}
              listLabel={languageMap.searchTextObj.textTwo}
              value={this.state.data.make_model_id}
              name="make_model_id"
              search={true}
              isOpen={false}
              error={errors.make_model_id ? true : false}
              helpertext={errors.make_model_id}
              autoFocus={Boolean(currentFocus === 'make_model_id')}
            />

            <SearchList
              id="cc_id"
              label={languageMap.modelText.textThree + '<span>*</span>'}
              onChange={this.handleCapacityChange}
              options={this.state.carCapacity}
              listLabel={languageMap.searchTextObj.textThree}
              value={this.state.data.cc_id}
              name="cc_id"
              search={true}
              isOpen={false}
              error={errors.cc_id ? true : false}
              helpertext={errors.cc_id}
              autoFocus={Boolean(currentFocus === 'cc_id')}
            />
            <SearchList
              id="manufacture_year"
              label={languageMap.modelText.textFour + '<span>*</span>'}
              onChange={this.handleYearChange}
              options={dropdownYears()}
              listLabel={languageMap.searchTextObj.textFour}
              value={this.state.data.manufacture_year}
              name="manufacture_year"
              search={true}
              isOpen={false}
              error={errors.manufacture_year ? true : false}
              helpertext={errors.manufacture_year}
              autoFocus={Boolean(currentFocus === 'manufacture_year')}
            />
            <SearchList
              id="model_description_id"
              label={'Sub Model<span>*</span>'}
              onChange={this.handleModelDescriptionChange}
              options={this.state.carDescription}
              listLabel={languageMap.searchTextObj.textFour}
              value={this.state.data.model_description_id}
              name="model_description_id"
              search={true}
              isOpen={false}
              error={errors.model_description_id ? true : false}
              helpertext={errors.model_description_id}
              autoFocus={Boolean(currentFocus === 'model_description_id')}
            />
            {isAdmin() && (
              <div className="form-element-wrap">
                <label htmlFor="" className="form-label">
                  {languageMap.modelText.for_commercial_vehicle}
                </label>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.for_commercial_vehicle === true}
                        onChange={this.handleChange('for_commercial_vehicle')}
                        value={'true'}
                        name="for_commercial_vehicle"
                      />
                    }
                    label={languageMap.modelText.yes}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.for_commercial_vehicle === false}
                        onChange={this.handleChange('for_commercial_vehicle')}
                        value={'false'}
                        name="for_commercial_vehicle"
                      />
                    }
                    label={languageMap.modelText.no}
                  />
                </FormGroup>
                <br />
              </div>
            )}
            {!this.props.showHeader ? (
              <div>
                <CheckboxGroup
                  options={INSURANCE_CLASSES}
                  onChange={(e, data, elementData) => this.handleCheckboxChange(data, existingClasses, elementData)}
                  value={this.state.data.insurance_class}
                  label={languageMap.insuranceClass.name + '<span>*</span>'}
                  error={errors.insurance_class}
                  autoFocus={Boolean(currentFocus === 'insurance_class')}
                  name="insurance_class"
                />
                <SearchList
                  id="vmi_vehicle_category"
                  label={languageMap.modelText['Vehicle Car Code'] + '<span>*</span>'}
                  onChange={this.handleVmiCarCodeChange}
                  options={VEHICLE_CATEGORIES}
                  listLabel={languageMap.searchTextObj.textFour}
                  value={this.state.data.vmi_vehicle_category}
                  name="vmi_vehicle_category"
                  search={true}
                  isOpen={false}
                  error={errors.vmi_vehicle_category ? true : false}
                  helpertext={errors.vmi_vehicle_category}
                  autoFocus={Boolean(currentFocus === 'vmi_vehicle_category')}
                />
                {this.state.data.model_description_id &&
                this.state.data.insurance_class.length &&
                this.state.data.insurance_class.includes('1') ? (
                  <div className="sum-insured-wrap">
                    {this.renderSumInsuredRanage()}
                    <IncrementDecrementWithInput
                      value={sumInsured.recommended}
                      minimum={sumInsured.minimum}
                      maximum={sumInsured.maximum}
                      step={step}
                      onChange={this.handleSuminsuredChange}
                      autoFocus={Boolean(currentFocus === 'sum_insured')}
                    />
                    <div className="info-content-wrapper">
                      <p className="header-text sum-insured-description">
                        ทุนประกันเท่ากับ 80% ของราคาตลาดหรือ 90% ของทุนประกันปีที่แล้ว <br />
                        สำหรับรถมือสองอ้างอิงทุนแนะนำ 80% ของราคาซื้อขาย <br />
                        ในกรณีที่ไม่ต้องการใช้ทุนแนะนำ หรือไม่ทราบทุนที่เหมาะสม
                        กรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบทุนประกันภัย <br />
                        <span>หมายเหตุ:</span> ทุนแนะนำคือทุนกลางประกันภัยของรุ่นรถนั้น ๆ
                      </p>
                    </div>
                  </div>
                ) : null}
                <SearchList
                  id="province"
                  label={languageMap.modelText.textSix}
                  onChange={this.handleProvinceChange}
                  options={provinces}
                  listLabel={languageMap.searchTextObj.textSix}
                  value={this.state.data.province}
                  name="province"
                  search={true}
                  isOpen={false}
                  error={errors.province ? true : false}
                  helpertext={errors.province}
                  autoFocus={Boolean(currentFocus === 'province')}
                />
                {type !== 'coa' ? (
                  <SearchList
                    id="current_insurer"
                    label={languageMap.modelText.textTen}
                    onChange={(e, data) => this.handleInsurerChange(e, 'current_insurer', data)}
                    options={INSURERS_LIST}
                    listLabel={languageMap.searchTextObj.textTen}
                    value={this.state.data.current_insurer}
                    name="current_insurer"
                    search={true}
                    isOpen={false}
                    error={errors.current_insurer ? true : false}
                    helpertext={errors.current_insurer}
                    autoFocus={Boolean(currentFocus === 'current_insurer')}
                  />
                ) : null}
                <SearchList
                  id="garage_type"
                  label={languageMap.modelText.garage_type}
                  onChange={this.handleGarageTypeChange}
                  options={GARAGE_TYPES}
                  listLabel={languageMap.searchTextObj.garage_type}
                  value={this.state.data.garage_type}
                  name="garage_type"
                  search={true}
                  isOpen={false}
                  error={errors.garage_type ? true : false}
                  helpertext={errors.garage_type}
                  autoFocus={Boolean(currentFocus === 'garage_type')}
                />
              </div>
            ) : null}
            {isAdmin() ? (
              <div className="input-wrap">
                <label className="form-label" htmlFor="vehicle_number">
                  {languageMap.modelText['Vehicle Number']}
                </label>
                <Form.Field>
                  <Input
                    id="vehicle_number"
                    name="vehicle_number"
                    placeholder={languageMap.modelText['Vehicle Number']}
                    onChange={this.handleTextChange}
                    value={data.vehicle_number}
                    autoFocus={Boolean(currentFocus === 'vehicle_number')}
                  />
                </Form.Field>
              </div>
            ) : null}
            {isAdmin() ? (
              <div className="text-box">
                <label className="text-box-label" htmlFor="chatwoot_link">
                  {languageMap.modelText['Chatwoot Conversation Link']}
                </label>
                <Form.Field>
                  <TextArea
                    id="chatwoot_link"
                    name="chatwoot_link"
                    placeholder={languageMap.modelText['Chatwoot Conversation Link']}
                    onChange={this.handleTextChange}
                    value={data.chatwoot_link}
                    autoFocus={Boolean(currentFocus === 'chatwoot_link')}
                  />
                </Form.Field>
              </div>
            ) : null}
            {isAdmin() ? (
              <div className="text-box">
                <label className="text-box-label" htmlFor="remarks">
                  {languageMap.modelText['Remarks']}
                </label>
                <Form.Field>
                  <TextArea
                    id="remarks"
                    name="remarks"
                    placeholder={languageMap.modelText['Remarks']}
                    onChange={this.handleTextChange}
                    value={data.remarks}
                    autoFocus={Boolean(currentFocus === 'remarks')}
                  />
                </Form.Field>
              </div>
            ) : null}
            {type === 'coa' ? (
              <CoaForm
                data={this.state.data}
                validate={this.validate}
                setLoader={this.setApiLoader}
                currentFocus={this.state.currentFocus}
              />
            ) : (
              <Form.Field className="button-wrap">
                <button className="button primary" disabled={disableButton} onClick={this.checkIfSimilarQuotationExist}>
                  {this.props.data ? languageMap.buttonTextOne : languageMap.buttonText}
                </button>
              </Form.Field>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

MakeModel.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  openModal: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    filter: state.filter,
    insurers: state.insurers,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, userProfile, showModal, showNotification })(MakeModel));
