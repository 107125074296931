/*global mobileClient */

import { Button } from 'antd';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../actions/modal';
import { MOBILE_MODAL } from '../../../constants/types';
import Back from '../../../images/fairdee-x-plus/teams/back.svg';
import ReferImg from '../../../images/fairdee-x-plus/teams/refer-img.svg';
import { RootState } from '../../../index';
import { isMobileDevice, notify } from '../../../utils/helper';
import ReferralDetails from './ReferralDetails';
import './_invite-friends.scss';

interface Props {
  close?: () => void;
}

const InviteFriends: React.FC<Props> = ({ close }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    affiliate = useSelector((state: RootState) => state.affiliate),
    dispatch = useDispatch();

  function handleCopy(e: any) {
    e.stopPropagation();
    let url = affiliate.referral_url;
    try {
      mobileClient.shareText(url);
    } catch (e) {
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (isMobileDevice() && !iOS) {
        if (navigator.share) {
          navigator
            .share({
              title: 'Share Referral Link',
              url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
      } else {
        copy(url);
        notify(languageMap[`Referral Link copied!`], 'info');
      }
    }
  }

  function viewDetails() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <ReferralDetails />,
        uid: 'REFERRAL_DETAILS',
      })
    );
  }

  return (
    <div className="invite-friends">
      <div className="invite-friends">
        <div className="background-wrap">
          <p className="title-text">
            <img src={Back} alt="back" onClick={close} /> {languageMap[`Invite a Friend`]}
          </p>
          <div className="content-wrap">
            <p className="text-one">{languageMap[`FairDee Referral Program`]} </p>
            <p className="text-two">
              {
                languageMap[
                  `Invite a Friend to FairDee and if they use your link to sign up, you’ll earn 2% of their net premiumplus a Management Fee!`
                ]
              }
            </p>
            <p className="text-three" onClick={viewDetails}>
              {languageMap[`Learn More Now`]}
            </p>
            <div className="img-wrap">
              <img src={ReferImg} alt="refer-img" />
            </div>
            {affiliate.referral_url ? (
              <>
                <div className="link-wrap">{affiliate.referral_url} </div>
                <div className="button-wrap">
                  <Button className="orange-button" onClick={handleCopy}>
                    {languageMap[`Copy`]}
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
