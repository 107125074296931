import { FIRST_LOAD } from '../constants/types';

function firstload(state = false, action = {}) {
  switch (action.type) {
    case FIRST_LOAD:
      return action.firstload;
    default:
      return state;
  }
}

export { firstload };
