import MomentUtils from '@date-io/moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { NOTIFICATION } from '../../constants/types';
import { errorHandler, snakeToTitleCase, thaiToDefaultDateTime, toThaiDateTimeBase } from '../../utils/helper';
import AutoComplete from '../AutoComplete';

const QUOTATION_PREFERENCE = ['Portal', 'Line'],
  PREFERRED_TYPE_OF_QUOTATION = ['Instalment', 'Regular'],
  INSURANCE_CLASSES = ['1', '2', '2+', '3', '3+'],
  INTERACTIONS = ['Phone', 'Email', 'SMS', 'WhatsApp'],
  MOMENT = moment().locale('th').add(543, 'years');

function InteractionExtraInfoForm(props) {
  const propsData = props.data || {},
    fairdeeXplusTranslation = useSelector((state) => state.languageMap.fairdeeXplus);
  const yearsOfExperience = fairdeeXplusTranslation.yearsOfExperience;
  const occupations = fairdeeXplusTranslation.occupations;
  const YEARS_OF_EXPERIENCE = Object.keys(yearsOfExperience).map((key) => {
    return {
      key: yearsOfExperience[key],
      value: key,
    };
  });
  const OCCUPATIONS = Object.keys(occupations).map((key) => {
    return {
      key: occupations[key],
      value: key,
    };
  });
  let affInfo = propsData.affiliate.affInfo || {};
  if (affInfo.affiliate) {
    affInfo = affInfo.affiliate.additional_info;
  } else {
    affInfo = affInfo.additional_info;
  }
  if (Object.keys(affInfo).length) {
    if ('has_broker_license' in affInfo && affInfo.has_broker_license !== null) {
      affInfo.has_broker_license = affInfo.has_broker_license.toString();
    }
    if ('is_selling_for_other_brokers' in affInfo && affInfo.is_selling_for_other_brokers !== null) {
      affInfo.is_selling_for_other_brokers = affInfo.is_selling_for_other_brokers.toString();
    }
  }

  const { close, action, onSuccess, survey } = props;
  const currentUser = useSelector((state) => state.user);
  let tempData = {
    source: affInfo.source || '',
    type_of_lead: affInfo.type_of_lead || [],
    years_of_experience: affInfo.years_of_experience || '',
    quotation_preference: affInfo.quotation_preference || '',
    preferred_type_of_quotation: affInfo.preferred_type_of_quotation || '',
    selling_for_other_insurers: affInfo.selling_for_other_insurers || [],
    is_selling_for_other_brokers: affInfo.is_selling_for_other_brokers || null,
    occupation: affInfo.occupation || '',
    primary_insurance_classes: affInfo.primary_insurance_classes || [],
    has_broker_license: affInfo.has_broker_license || null,
    staff: propsData.staff || { label: currentUser.fullname, value: currentUser.fullname, id: currentUser.id } || {},
    interaction_type: propsData.interaction_type || 'Phone',
    remarks: propsData.remarks || '',
    additional_remarks: propsData.additional_remarks || '',
    affiliate: propsData.affiliate,
    task: propsData.task,
    interacted_at: propsData.interacted_at ? toThaiDateTimeBase(propsData.interacted_at) : MOMENT,
    followup_date: propsData.followup_date ? toThaiDateTimeBase(propsData.followup_date) : MOMENT.clone().add(2, 'day'),
    is_successful:
      'is_successful' in propsData && propsData.is_successful !== null ? propsData.is_successful.toString() : 'true',
    has_physical_location:
      'has_physical_location' in affInfo && affInfo.has_physical_location !== null
        ? affInfo.has_physical_location.toString()
        : null,
    manual_followup: false,
    want_to_do_mlm: affInfo.want_to_do_mlm?.toString() || null,
  };

  const [data, setData] = useState(tempData),
    [errors, setErrors] = useState({}),
    [updatedData, setUpdatedData] = useState({}),
    [staffs, setStaffs] = useState([]),
    [loading, setLoading] = useState(false),
    languageMap = useSelector((state) => state.languageMap.components.interactionForm),
    messages = useSelector((state) => state.languageMap.messages),
    [callStatusOptions, setCallStatusOptions] = useState(languageMap.CALL_STATUS_SUCCESS),
    dispatch = useDispatch();

  useEffect(() => {
    const propsData = props.data || {};
    let affInfo = propsData.affiliate.affInfo || {};
    if (affInfo.affiliate) {
      affInfo = affInfo.affiliate.additional_info;
    } else {
      affInfo = affInfo.additional_info;
    }
    if (!Object.keys(affInfo).length) {
      getAffiliate(propsData.affiliate.affInfo.aff_id);
    }
    rolesWithStaff();
  }, []);

  useEffect(() => {
    if (data.is_successful === 'true') {
      setCallStatusOptions(languageMap.CALL_STATUS_SUCCESS);
    } else {
      setCallStatusOptions(languageMap.CALL_STATUS_FAILURE);
    }
  }, [data.is_successful]);

  function notify(message, type) {
    dispatch(
      showNotification(NOTIFICATION, {
        message: message,
        type: type,
        autoClose: true,
      })
    );
  }

  function rolesWithStaff() {
    api.crm
      .rolesWithStaff()
      .then((response) => {
        let staffs = [];

        response.forEach((data) => {
          if (data.name === 'admin') {
            data.staffs.forEach((data) => {
              data.label = data.fullname + ' (admin)';
              data.id = data.id;
              data.value = data.label;
              data.role = 'admin';
            });
            staffs = staffs.concat(data.staffs);
          }
          if (data.name === 'data_entry') {
            data.staffs.forEach((data) => {
              data.label = data.fullname + ' (data entry)';
              data.id = data.id;
              data.value = data.label;
              data.role = 'data_entry';
            });
            staffs = staffs.concat(data.staffs);
          }
          if (data.name === 'sales') {
            data.staffs.forEach((data) => {
              data.label = data.fullname + ' (sales)';
              data.id = data.id;
              data.value = data.label;
              data.role = 'sales';
            });
            staffs = staffs.concat(data.staffs);
          }
          if (data.name === 'affiliate') {
            data.staffs.forEach((data) => {
              data.label = data.fullname + ' (affiliate)';
              data.id = data.id;
              data.value = data.label;
              data.role = 'affiliate';
            });
            staffs = staffs.concat(data.staffs);
          }
        });
        setStaffs(staffs);
      })
      .catch((error) => {});
  }

  function handleChange(name, event) {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    if (name === 'primary_insurance_classes') {
      let tempData = { ...data },
        tempUpdatedData = { ...updatedData };
      if (tempData.primary_insurance_classes.includes(value)) {
        tempData.primary_insurance_classes = tempData.primary_insurance_classes.filter((d) => d !== value);
        tempUpdatedData.primary_insurance_classes = tempData.primary_insurance_classes.filter((d) => d !== value);
      } else {
        if (!tempUpdatedData.primary_insurance_classes) {
          tempUpdatedData.primary_insurance_classes = [];
        }
        tempData.primary_insurance_classes.push(value);
        tempUpdatedData.primary_insurance_classes.push(value);
      }
      setData({ ...tempData });
      setUpdatedData({ ...tempUpdatedData });
      return;
    }
    if (event.target && event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    let tempData = { ...data };
    if (name === 'is_successful') {
      tempData.remarks = '';
    }
    setData({ ...tempData, [name]: value });
    setUpdatedData({ ...updatedData, [name]: value });
  }

  function autoCompleteChange(data) {}

  function autocompleteSelect(name, d) {
    setData({ ...data, [name]: d });
    setUpdatedData({ ...updatedData, [name]: d });
  }

  function validate() {
    let errors = {};
    if (!data.staff.id) {
      errors.staff = messages.required;
    }
    if (!data.interaction_type) {
      errors.interaction_type = messages.required;
    }
    if (!data.remarks && data.is_successful === 'true') {
      errors.remarks = messages.required;
    }
    return errors;
  }

  function createInteraction(params) {
    return api.crm.createInteraction(params);
  }

  function patchAffiliate(id, params) {
    return api.crm.patchAffiliate(id, params);
  }

  function getAffiliate(id) {
    api.crm
      .affiliate(id)
      .then((resp) => {
        let affInfo = resp.message.additional_info;
        setData({
          ...data,
          source: affInfo.source || '',
          type_of_lead: affInfo.type_of_lead || [],
          years_of_experience: affInfo.years_of_experience || '',
          quotation_preference: affInfo.quotation_preference || '',
          preferred_type_of_quotation: affInfo.preferred_type_of_quotation || '',
          selling_for_other_insurers: affInfo.selling_for_other_insurers || [],
          is_selling_for_other_brokers:
            affInfo.is_selling_for_other_brokers === true || affInfo.is_selling_for_other_brokers === false
              ? affInfo.is_selling_for_other_brokers.toString()
              : null,
          occupation: affInfo.occupation || '',
          primary_insurance_classes: affInfo.primary_insurance_classes || [],
          has_physical_location:
            affInfo.has_physical_location === true || affInfo.has_physical_location === false
              ? affInfo.has_physical_location.toString()
              : null,
          has_broker_license:
            affInfo.has_broker_license === true || affInfo.has_broker_license === false
              ? affInfo.has_broker_license.toString()
              : null,
        });
        setTimeout(() => {}, 100);
      })
      .catch((error) => {});
  }

  function patchInteraction(id, params) {
    return api.crm.patchInteraction(id, params);
  }

  const saveFollowupTask = () => {
    let dateTime = moment(thaiToDefaultDateTime(data.followup_date)).format('YYYY-MM-DDTHH:mm:ss'),
      params = {
        staff: data.staff ? data.staff.id : '',
        eta: dateTime,
        task: 'Manual Followup',
      };
    if (props.data.affiliate) {
      params.affiliate = parseInt(props.data.affiliate.id);
    }
    if (props.data.affiliate && props.data.affiliate.user) {
      params.user = parseInt(props.data.affiliate.user.id);
    }
    api.crm.createTask(params).catch((error) => {
      console.log(error);
      errorHandler(error.response);
    });
  };

  function save() {
    let promises = [];
    let errors = validate(data);
    setErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }
    if (!Object.keys(updatedData).length && close) {
      close();
      return;
    }
    let params = {
      affiliate: data.affiliate.id,
      user: data.affiliate.user.id,
      staff: data.staff.id,
      interaction_type: data.interaction_type,
      interacted_at: moment(thaiToDefaultDateTime(data.interacted_at)).format('YYYY-MM-DDTHH:mm:ss'),
      is_successful: data.is_successful === 'true',
    };

    if (data.manual_followup === true) {
      saveFollowupTask();
    }

    if (data.remarks) {
      params.remarks = data.remarks;
    }

    if (data.additional_remarks) {
      params.additional_remarks = data.additional_remarks;
    }
    if (data.task) {
      params.task = data.task.id;
    }
    if (updatedData.followup_date) {
      params.followup_date = moment(thaiToDefaultDateTime(updatedData.followup_date)).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (Object.keys(params).length && !props.data.id) {
      promises.push(createInteraction(params));
    }
    if (props.data.id) {
      params = {};
      Object.keys(updatedData).forEach((key) => {
        if (updatedData[key]) {
          params[key] = updatedData[key];
          if (key === 'staff') {
            params[key] = updatedData[key].id;
          }
          if (key === 'interacted_at') {
            params[key] = moment(thaiToDefaultDateTime(updatedData[key])).format('YYYY-MM-DDTHH:mm:ss');
          }
          if (key === 'is_successful') {
            params[key] = updatedData[key] === 'true';
          }
        }
      });
      promises.push(patchInteraction(props.data.id, params));
    }
    params = {};
    if (updatedData.type_of_lead) {
      params.type_of_lead = [updatedData.type_of_lead];
    }
    if (updatedData.years_of_experience || updatedData.years_of_experience === '0') {
      params.years_of_experience = updatedData.years_of_experience;
    }
    if (updatedData.quotation_preference) {
      params.quotation_preference = updatedData.quotation_preference;
    }
    if (updatedData.preferred_type_of_quotation) {
      params.preferred_type_of_quotation = updatedData.preferred_type_of_quotation;
    }
    if (updatedData.selling_for_other_insurers) {
      let text = updatedData.selling_for_other_insurers.split(/[ ,]+/).filter((value) => value != '');
      params.selling_for_other_insurers = text;
    }
    if ('is_selling_for_other_brokers' in updatedData) {
      params.is_selling_for_other_brokers = updatedData.is_selling_for_other_brokers === 'true';
    }
    if ('has_broker_license' in updatedData) {
      params.has_broker_license = updatedData.has_broker_license === 'true';
    }
    if ('has_physical_location' in updatedData) {
      params.has_physical_location = updatedData.has_physical_location === 'true';
    }
    if (updatedData.occupation) {
      params.occupation = updatedData.occupation;
    }
    if (updatedData.primary_insurance_classes) {
      params.primary_insurance_classes = updatedData.primary_insurance_classes;
    }

    if (updatedData.line_id) {
      params.line_id = updatedData.line_id;
    }
    if (updatedData.want_to_do_mlm) {
      params.want_to_do_mlm = updatedData.want_to_do_mlm;
    }
    if (Object.keys(params).length && !propsData.hideAffiliateFields) {
      promises.push(patchAffiliate(data.affiliate.id, params));
    }
    setLoading(true);
    Promise.all(promises)
      .then((response) => {
        response.forEach((resp, index) => {
          if (resp.data && resp.data.id && action) {
            getAffiliate(resp.data.id);
          }
          if (resp.id && action) {
            action();
          }
        });
        setLoading(false);
        notify('Successfully Created.', 'success');
        if (onSuccess) {
          onSuccess();
        }
        if (close && !survey) {
          close();
        }
      })
      .catch((error) => {
        console.error(error, error.response);
        let errors = { ...errors, ...errorHandler(error.response, true) };
        setLoading(false);
        setErrors(errors);
      });
  }

  return (
    <div className="crm-form">
      <AutoComplete
        items={staffs}
        onChange={autoCompleteChange}
        onSelect={autocompleteSelect.bind(null, 'staff')}
        name="staff"
        label={languageMap.textTwo}
        error={errors.staff}
        value={data.staff.value || ''}
        className="form-element"
      />
      <TextField
        select
        id="interaction_type"
        label={languageMap.textThree}
        value={data.interaction_type || ''}
        margin="normal"
        error={errors.interaction_type ? true : false}
        helperText={errors.interaction_type}
        autoComplete="off"
        name="interaction_type"
        onChange={handleChange.bind(null, 'interaction_type')}
        className="form-element"
      >
        {INTERACTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
        <InlineDateTimePicker
          clearable
          animateYearScrolling={false}
          id="interacted_at"
          label={languageMap.textSix}
          placeholder={languageMap.textSix}
          value={data.interacted_at}
          onChange={handleChange.bind(null, 'interacted_at')}
          maxDate={MOMENT.clone().add(1, 'days')}
          minDate={MOMENT.clone().subtract(1, 'years')}
          format="DD/MM/YYYY hh:mm A"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          className="form-element datepicker"
        />
      </MuiPickersUtilsProvider>
      <FormControl component="div" className="form-element checkbox-group">
        <FormLabel component="label">{languageMap.textNine}</FormLabel>
        <RadioGroup
          className="radio-group"
          aria-label="position"
          name="position"
          value={data.is_successful}
          onChange={handleChange.bind(null, 'is_successful')}
          row
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
        </RadioGroup>
      </FormControl>
      <TextField
        select
        id="remarks"
        label={languageMap.textFour}
        value={data.remarks || ''}
        margin="normal"
        error={errors.remarks ? true : false}
        helperText={errors.remarks}
        autoComplete="off"
        name="remarks"
        onChange={handleChange.bind(null, 'remarks')}
        className="form-element"
      >
        {callStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="additional_remarks"
        label={languageMap.additionalRemarks}
        className="form-element"
        value={data.additional_remarks || ''}
        autoComplete="off"
        onChange={handleChange.bind(null, 'additional_remarks')}
        error={errors.additional_remarks ? true : false}
        helperText={errors.additional_remarks}
      />
      {props.data.isFollowUp ? (
        <>
          <FormControl component="div" className="form-element checkbox-group">
            <Checkbox checked={data.manual_followup} name="" onChange={handleChange.bind(null, 'manual_followup')} />
            <FormLabel component="label">{props.data.label || languageMap.manualFollowup}</FormLabel>
          </FormControl>
          {data.manual_followup ? (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
              <InlineDateTimePicker
                clearable
                animateYearScrolling={false}
                id="followup_date"
                label={languageMap.followupDate}
                placeholder={languageMap.followupDate}
                value={data.followup_date}
                onChange={handleChange.bind(null, 'followup_date')}
                maxDate={MOMENT.clone().add(1, 'year')}
                minDate={MOMENT.clone().subtract(1, 'day')}
                format="DD/MM/YYYY hh:mm A"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                className="form-element datepicker"
              />
            </MuiPickersUtilsProvider>
          ) : null}
        </>
      ) : null}
      {!propsData.hideAffiliateFields && (
        <>
          <TextField
            select
            id="type_of_lead"
            label={languageMap.textEight}
            value={data.type_of_lead ? data.type_of_lead.toString() : ''}
            error={Boolean(errors.type_of_lead)}
            helperText={errors.type_of_lead}
            autoComplete="off"
            name="type_of_lead"
            onChange={handleChange.bind(null, 'type_of_lead')}
            className="form-element"
          >
            <MenuItem key="lead" value="">
              Select
            </MenuItem>
            {languageMap.LEADS.map((lead) => (
              <MenuItem key={lead.value} value={lead.value}>
                {snakeToTitleCase(lead.key)}
              </MenuItem>
            ))}
          </TextField>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.textEleven}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.has_physical_location}
              onChange={handleChange.bind(null, 'has_physical_location')}
              row
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.textTwelve}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.has_broker_license}
              onChange={handleChange.bind(null, 'has_broker_license')}
              row
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.textThirteen}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.quotation_preference}
              onChange={handleChange.bind(null, 'quotation_preference')}
              row
            >
              {QUOTATION_PREFERENCE.map((key) => {
                return (
                  <FormControlLabel
                    value={key}
                    control={<Radio color="primary" />}
                    label={key}
                    labelPlacement="end"
                    key={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.preferredTypeOfQuotation}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.preferred_type_of_quotation}
              onChange={handleChange.bind(null, 'preferred_type_of_quotation')}
              row
            >
              {PREFERRED_TYPE_OF_QUOTATION.map((key) => {
                return (
                  <FormControlLabel
                    value={key}
                    control={<Radio color="primary" />}
                    label={key}
                    labelPlacement="end"
                    key={key}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.wantToDoMLM}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.want_to_do_mlm}
              onChange={handleChange.bind(null, 'want_to_do_mlm')}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label={languageMap.wantMLM}
                labelPlacement="end"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label={languageMap.dontWantMLM}
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="line_id"
            label={languageMap.lineId}
            value={data.line_id || ''}
            error={Boolean(errors.line_id)}
            helperText={errors.line_id}
            autoComplete="off"
            name="line_id"
            onChange={handleChange.bind(null, 'line_id')}
            className="form-element"
          />
          <TextField
            id="selling_for_other_insurers"
            label={languageMap.textFourteen}
            value={data.selling_for_other_insurers || ''}
            error={Boolean(errors.selling_for_other_insurers)}
            helperText={errors.selling_for_other_insurers}
            autoComplete="off"
            name="selling_for_other_insurers"
            onChange={handleChange.bind(null, 'selling_for_other_insurers')}
            className="form-element"
          />
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.textFifteen}</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="position"
              name="position"
              value={data.is_selling_for_other_brokers}
              onChange={handleChange.bind(null, 'is_selling_for_other_brokers')}
              row
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
              <FormControlLabel value="false" control={<Radio color="primary" />} label="No" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
          <FormControl component="div" className="form-element checkbox-group">
            <FormLabel component="label">{languageMap.textSixteen}</FormLabel>
            <FormGroup row>
              {INSURANCE_CLASSES.map((key) => {
                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={Boolean(data.primary_insurance_classes.includes(key))}
                        onChange={handleChange.bind(null, 'primary_insurance_classes')}
                        value={key}
                      />
                    }
                    label={key}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <TextField
            select
            id="years_of_experience"
            label={languageMap.textSevenTeen}
            value={data.years_of_experience || ''}
            error={Boolean(errors.years_of_experience)}
            helperText={errors.years_of_experience}
            autoComplete="off"
            name="years_of_experience"
            onChange={handleChange.bind(null, 'years_of_experience')}
            className="form-element"
          >
            <MenuItem key="lead" value="">
              Select
            </MenuItem>
            {YEARS_OF_EXPERIENCE.map((exp) => (
              <MenuItem key={exp.value} value={exp.value}>
                {snakeToTitleCase(exp.key)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            id="occupation"
            label={languageMap.textEighteen}
            value={data.occupation || ''}
            error={Boolean(errors.occupation)}
            helperText={errors.occupation}
            autoComplete="off"
            name="occupation"
            onChange={handleChange.bind(null, 'occupation')}
            className="form-element"
          >
            <MenuItem key="lead" value="">
              Select
            </MenuItem>
            {OCCUPATIONS.map((occupation) => (
              <MenuItem key={occupation.value} value={occupation.value}>
                {snakeToTitleCase(occupation.key)}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      <div className="form-element">
        <button className="button primary medium" onClick={save}>
          {languageMap.textNineteen}
        </button>
      </div>
    </div>
  );
}

export default InteractionExtraInfoForm;
