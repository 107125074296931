import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { Space } from 'antd';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import validator from 'validator';
// import 'moment/locale/th';
import api from '../commonjs/api';
import apiEndpoints from '../commonjs/apiEndpoints';
import FileUpload from '../components/FileUpload';
import InlineError from '../components/InlineError';
import { errorHandler, notify } from '../utils/helper';

const THAI_TODAY = moment(); // .locale('th').add(543, "years");
const MIN_DATE = THAI_TODAY.clone().subtract(3, 'year').toDate();
const MAX_DATE = THAI_TODAY.clone().add(3, 'year').toDate();
const USE_TYPES = [
  {
    key: 'MULTIPURPOSE_LESS_THAN_7',
    value: '110',
  },
  {
    key: 'PRIVATE_BUS_LESS_THAN_15',
    value: '220',
  },
  {
    key: 'PICKUP_TRUCK_LESS_THAN_3_TON',
    value: '320',
  },
];

const DEFAULT_DATA = {
  insurer: '',
  gross_premium: '',
  affiliate_commission: '',
  subsidy: '',
  net_premium: '',
  // sum_insured: '',
  // deductible: '',
  delivered_date: null,
  collection_date: null,
  postal_tracking_code: '',
  use_type: '',
  garage_type: '',
  insurance_class: '',
  policy_start_date: null,
  policy_end_date: null,
  documents: [],
  policy_number: '',
  postal_tracking_code: '',
  dispatch_date: null,
  waiting_for_physical_policy: false,
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '20px',
    width: '100%',
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  formLabel: {
    margin: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  fullWidth: {
    width: '100%',
  },
  datePicker: {
    width: '100%',
    marginBottom: '24px',
  },
});

class NonMotorPolicyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: DEFAULT_DATA,
      insurers: [],
      requestSuccess: null,
      errors: {},
      updatedData: {},
    };
  }

  init = (props) => {
    const { suborder } = props;
    let data = {
      insurer: suborder.insurer,
      gross_premium: suborder.premium_before_tax,
      tax: JSON.parse(suborder.taxes).tax,
      duty: JSON.parse(suborder.taxes).duty,
      net_premium: suborder.premium_after_tax,
      affiliate_commission: suborder.premium_invoices[0].commission,
    };
    if (suborder.policy && suborder.policy.id) {
      this.setState({
        data: { ...this.state.data, ...suborder.policy },
      });
    } else {
      this.setState({
        data: { ...this.state.data, ...data },
      });
    }
  };

  componentDidMount() {
    this.getInsurers();
    this.init(this.props);
    // if (this.props.carId) {
    //   this.getPolicy(this.props.carId);
    // }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      if (this.props.policyData !== nextProps.policyData) {
        this.init(nextProps);
      }
      if (this.props.carId) {
        this.getPolicy(this.props.carId);
      }
    }
  };

  getPolicy = (id) => {
    api.crm
      .getFairdeePolicy({ car: id })
      .then((response) => {
        if (response.results.length) {
          response.results.forEach((data) => {
            if (data.is_compulsory === this.props.is_compulsory) {
              data.documents = data.documents.map((d) => {
                d.name = d.file.original_file_name;
                delete d.file;
                return d;
              });
              this.init({
                policyData: data,
              });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  getInsurers = () => {
    api.masterData
      .getInsurers()
      .then((resp) => {
        let insurersList = resp.results;

        resp.results.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        this.setState({
          insurers: insurersList,
        });
      })
      .catch((error) => errorHandler(error.response, true));
  };

  handleChange = (name) => (event) => {
    let value = null;
    if (moment.isMoment(event)) {
      if (name === 'dispatch_date' || name === 'delivered_date' || name === 'collection_date') {
        value = event.format('YYYY-MM-DD HH:mm:ss');
      } else {
        value = event;
      }
      if (name === 'policy_start_date') {
        this.setState({
          data: { ...this.state.data, [name]: value, policy_end_date: event.clone().add('years', 1) },
          updatedData: { ...this.state.updatedData, [name]: value, policy_end_date: event.clone().add('years', 1) },
        });
        return;
      }
    } else {
      value = event.target.value;

      if (event.target.type === 'number') {
        let decimalCount = (event.target.value + '.').split('.')[1].length;
        if (decimalCount > 2) {
          return;
        }
      }
    }
    let obj = {};
    if (name === 'gross_premium') {
      let gross_premium = parseFloat(event.target.value || 0, 10);
      // let netPremium = gross_premium * 0.9309;
      let netPremium = gross_premium;
      let duty = netPremium * 0.004;
      let tax = (netPremium + duty) * 0.07;
      let premium_after_tax = gross_premium + duty + tax;

      obj.gross_premium = gross_premium || '';
      obj.tax = tax ? tax.toFixed(2) : '';
      obj.duty = duty ? duty.toFixed(2) : '';
      obj.premium_after_tax = premium_after_tax ? premium_after_tax.toFixed(2) : '';
    }
    this.setState({
      data: { ...this.state.data, [name]: value, ...obj },
      updatedData: { ...this.state.updatedData, [name]: value, ...obj },
    });
  };

  handleSwitchToggle = (name) => (event) => {
    const data = {
      ...this.state.data,
      [name]: event.target.checked,
    };
    this.setState({
      data: data,
      updatedData: { ...this.state.updatedData, [name]: event.target.checked },
    });
  };

  fileUploaded = (obj) => {
    // obj.new = true;
    let data = this.state.data;
    let updatedData = this.state.updatedData;
    data.documents = [...this.state.data.documents, obj];
    if (!updatedData.documents) {
      updatedData.documents = [];
    }
    updatedData.documents = [...this.state.updatedData.documents, obj];
    this.setState(
      {
        data,
        updatedData,
      },
      () => {}
    );
  };

  deleteDocumentApi = (id) => {
    api.crm
      .deleteDocument(id)
      .then((response) => {
        notify('Deleted successfully', 'success');
      })
      .catch((error) => {
        errorHandler(error.response);
      });
  };

  deleteDocument = (obj) => {
    let data = this.state.data;
    let updatedData = this.state.updatedData;
    data.documents = data.documents.filter((data, index) => index !== obj.index);
    if (!updatedData.documents) {
      updatedData.documents = [];
    }
    updatedData.documents = updatedData.documents.filter((data, index) => index !== obj.index);

    if (obj.id) {
      this.deleteDocumentApi(obj.id);
      data.documents = data.documents.filter((data) => data.id !== obj.id);
      if (!updatedData.documents) {
        updatedData.documents = [];
      }
      updatedData.documents = updatedData.documents.filter((data) => data.id !== obj.id);
    }
    this.setState({
      data,
      updatedData,
    });
  };

  validatePolicyDetails = (data) => {
    let errors = {},
      messages = this.props.languageMap.messages;

    if ('gross_premium' in data && data.gross_premium && !validator.isDecimal(data.gross_premium.toString())) {
      errors.gross_premium = 'Value should be numeric';
    }

    if (
      'affiliate_commission' in data &&
      data.affiliate_commission &&
      !validator.isDecimal(data.affiliate_commission.toString())
    ) {
      errors.affiliate_commission = 'Value should be numeric';
    }

    if ('subsidy' in data && data.subsidy && !validator.isDecimal(data.subsidy.toString())) {
      errors.subsidy = 'Value should be numeric';
    }

    if ('net_premium' in data && data.net_premium && !validator.isDecimal(data.net_premium.toString())) {
      errors.net_premium = 'Value should be numeric';
    }

    if (
      (this.props.saleStatus === 'policy_sent_to_user' || this.props.saleStatus === 'policy_uploaded') &&
      (!this.state.data.documents || !this.state.data.documents.length)
    ) {
      errors.policy = messages.uploadPolicy;
    }

    if ('policy_number' in data && !data.policy_number) {
      errors.policy_number = 'Required Field';
    }

    if (this.props.statusUpdate && !this.props.is_compulsory) {
      if (!this.state.data.postal_tracking_code && this.props.policyStatus === 'policy_sent_to_user') {
        errors.postal_tracking_code = 'Required Field';
      }
      if (!this.state.data.dispatch_date && !this.props.adminAction) {
        errors.dispatch_date = 'Required Field';
      }
    }

    return errors;
  };

  generatePolicyParameters = (data) => {
    let params = {};

    if (data.policy_start_date) {
      params.policy_start_date = moment(data.policy_start_date).format('YYYY-MM-DD');
    }
    if (data.policy_end_date) {
      params.policy_end_date = moment(data.policy_end_date).format('YYYY-MM-DD');
    }
    if (data.dispatch_date) {
      params.dispatch_date = moment(data.policy_end_date).format('YYYY-MM-DD HH:mm:ss');
    }

    if (data.insurer) {
      params.insurer = data.insurer;
    }
    if (data.gross_premium) {
      params.gross_premium = parseFloat(data.gross_premium, 10).toFixed(2);
    }
    if (data.is_compulsory) {
      params.is_compulsory = parseFloat(data.is_compulsory, 10).toFixed(2);
    }
    if (data.affiliate_commission) {
      params.affiliate_commission = parseFloat(data.affiliate_commission, 10).toFixed(2);
    }
    if (data.subsidy) {
      params.subsidy = parseFloat(data.subsidy, 10).toFixed(2);
    }
    if (data.net_premium) {
      params.net_premium = parseFloat(data.net_premium, 10).toFixed(2);
    }
    if (data.policy_number) {
      params.policy_number = data.policy_number;
    }
    if (data.duty) {
      params.duty = parseFloat(data.duty).toFixed(2);
    }
    if (data.tax) {
      params.tax = parseFloat(data.tax).toFixed(2);
    }
    if (data.waiting_for_physical_policy !== undefined) {
      params.waiting_for_physical_policy = data.waiting_for_physical_policy;
    }

    if (data.postal_tracking_code) {
      params.postal_tracking_code = data.postal_tracking_code;
    }
    // if (this.props.is_compulsory) {
    //   return params;
    // }
    if (data.use_type) {
      params.use_type = data.use_type;
    }
    if (data.garage_type) {
      params.garage_type = data.garage_type;
    }
    if (data.insurance_class) {
      params.insurance_class = data.insurance_class;
    }
    params.order_detail = this.props.suborder.id;
    return params;
  };

  patchPolicyData = () => {
    let errors = this.validatePolicyDetails(this.state.updatedData);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    let data = Object.assign({}, this.state.updatedData);
    let params = this.generatePolicyParameters(data);
    if (!Object.keys(params).length) {
      if (data.documents && data.documents.length) {
        this.patchPolicyFile(this.props.suborder.policy.id, data.documents[0]);
      } else {
        this.props.action();
      }
      return;
    }
    this.setState({
      loading: true,
    });
    api.crm
      .patchFairdeePolicy(this.props.suborder.policy.id, params)
      .then((response) => {
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.state.updatedData.documents && this.state.updatedData.documents.length) {
          this.patchPolicyFile(this.state.data.id, this.state.updatedData.documents[0]);
        } else {
          this.props.action();
        }
        notify('Update successfully', 'success');
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        let errors = errorHandler(error.response);
        this.setState({
          errors,
        });
      });
  };

  patchPolicyFile = (id, file) => {
    let params = {
      file,
      policy_id: parseInt(id, 10),
      product: 'fairdeepolicy',
    };
    let FD = new FormData();
    Object.keys(params).forEach((key) => {
      FD.append(key, params[key]);
    });
    api.crm
      .policyDocument(FD)
      .then((response) => {
        this.setState({
          updatedData: { ...this.state.updatedData, documents: [] },
        });
        this.props.action();
      })
      .catch((error) => {
        errorHandler(error.response);
      });
  };

  savePolicyData = () => {
    console.log('Inside save policy data');
    let errors = this.validatePolicyDetails(this.state.data);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    let data = Object.assign({}, this.state.data);
    let params = this.generatePolicyParameters(data);
    // params.is_compulsory = this.props.is_compulsory;
    // params.car = this.props.carId || null;
    if (!this.props.is_compulsory) {
      params.total_premium = params.net_premium;
    }

    // if (!params.car) {
    //   return;
    // }
    this.setState({
      loading: true,
    });
    api.crm
      .fairdeePolicy(params)
      .then((response) => {
        // if (this.props.setPolicies) {
        //   this.props.setPolicies(this.props.is_compulsory ? 'compulsary' : 'voluntary', response.id);
        // }
        // let fieldName = this.props.is_compulsory ? 'compulsory_policy' : 'voluntary_policy';

        if (this.state.updatedData.documents && this.state.updatedData.documents.length) {
          this.patchPolicyFile(response.id, this.state.updatedData.documents[0]);
        }

        // return api.crm.patchSaletrack(this.props.saleTrack.id, { [fieldName]: response.id });
      })
      .then((response) => {
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.props.statusUpdate) {
          this.props.statusUpdate(response);
        }
        this.props.action && this.props.action(true);
        notify('Added successfully', 'success');
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        let errors = errorHandler(error.response, true);
        this.setState({
          errors,
        });
      });
  };

  saveData = () => {
    if (this.props?.suborder?.policy?.id) {
      //   if (!Object.keys(this.state.updatedData).length && this.state.data.documents.length && !this.props.statusUpdate) {
      //     if (this.props.quotation.needs_compulsory_insurance && !this.props.is_compulsory) {
      //       this.changeStep(1);
      //     } else {
      //       this.props.action();
      //     }
      //     return;
      //   }
      this.patchPolicyData();
    } else {
      this.savePolicyData();
    }
  };

  changeStep = (step) => {
    if (this.props.changeStep) {
      this.props.changeStep(step);
    }
  };

  render() {
    let { data, errors, loading, insurers } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.policyForm;

    return (
      <form className={classes.container} noValidate autoComplete="nope">
        <Space direction="vertical">
          {insurers.length && (
            <TextField
              select
              id="insurer"
              label={languageMap.textOne}
              className={classes.textField}
              value={data.insurer ? data.insurer.id || data.insurer : ''}
              margin="normal"
              error={errors.insurer ? true : false}
              helperText={errors.insurer}
              autoComplete="off"
              name="insurer"
              onChange={this.handleChange('insurer')}
            >
              {insurers.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <div className="full-width mb-12">
            <FileUpload
              download={true}
              files={data.documents || []}
              name="policy"
              onFileUpload={this.fileUploaded}
              onFileRemove={this.deleteDocument}
              downloadPath={apiEndpoints.policyDocument}
              limit={1}
              btnText={languageMap.textTwentyFour}
            />
            {errors.policy ? <InlineError text={errors.policy} /> : null}
          </div>

          <>
            <TextField
              type="number"
              id="gross_premium"
              label={languageMap.textTwo}
              className={classes.textField}
              onChange={this.handleChange('gross_premium')}
              name="gross_premium"
              error={errors.gross_premium ? true : false}
              helperText={errors.gross_premium}
              autoComplete="off"
              value={data.gross_premium || ''}
            />
            <TextField
              type="number"
              id="affiliate_commission"
              label={languageMap.textThree}
              className={classes.textField}
              value={data.affiliate_commission || ''}
              margin="normal"
              error={errors.affiliate_commission ? true : false}
              helperText={errors.affiliate_commission}
              autoComplete="off"
              onChange={this.handleChange('affiliate_commission')}
            />

            {!this.props.is_compulsory && (
              <div>
                <TextField
                  type="number"
                  id="tax"
                  label={languageMap.textTwentyOne}
                  value={data.tax || ''}
                  margin="normal"
                  error={errors.tax ? true : false}
                  helperText={errors.tax}
                  autoComplete="off"
                  name="tax"
                  onChange={this.handleChange('tax')}
                  className="full-width"
                />
                <TextField
                  type="number"
                  id="duty"
                  label={languageMap.textTwentyTwo}
                  value={data.duty || ''}
                  margin="normal"
                  error={errors.duty ? true : false}
                  helperText={errors.duty}
                  autoComplete="off"
                  name="duty"
                  onChange={this.handleChange('duty')}
                  className="full-width"
                />
                <TextField
                  type="number"
                  id="net_premium"
                  label={languageMap.textFive}
                  className={classes.textField}
                  value={data.net_premium || ''}
                  margin="normal"
                  error={errors.net_premium ? true : false}
                  helperText={errors.net_premium}
                  autoComplete="off"
                  onChange={this.handleChange('net_premium')}
                />
              </div>
            )}
          </>

          <TextField
            type="text"
            id="policy_number"
            label={languageMap.textTwentyThree}
            className={classes.textField}
            value={data.policy_number || ''}
            margin="normal"
            error={errors.policy_number ? true : false}
            helperText={errors.policy_number}
            autoComplete="off"
            onChange={this.handleChange('policy_number')}
          />
          <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
            <InlineDatePicker
              // keyboard
              id="policy_start_date"
              label={languageMap.textEight}
              className={classes.datePicker}
              value={data.policy_start_date}
              onChange={this.handleChange('policy_start_date')}
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
            <InlineDatePicker
              // keyboard
              id="policy_end_date"
              label={languageMap.textNine}
              // initialFocusedDate={moment().add(1, 'year')}
              className={classes.datePicker}
              value={data.policy_end_date}
              onChange={this.handleChange('policy_end_date')}
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
          <div>
            <TextField
              id="postal_tracking_code"
              label={languageMap.textThirteen}
              className={classes.textField}
              value={data.postal_tracking_code || ''}
              margin="normal"
              error={errors.postal_tracking_code ? true : false}
              helperText={errors.postal_tracking_code}
              autoComplete="off"
              onChange={this.handleChange('postal_tracking_code')}
            />
          </div>
          <div>
            <p className="label-text">{languageMap['Are you waiting for Physical Policy?']}</p>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.data.waiting_for_physical_policy}
                  onChange={this.handleSwitchToggle('waiting_for_physical_policy')}
                  value="checkedB"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={this.state.data.waiting_for_physical_policy ? languageMap['Yes'] : languageMap['No']}
            />
          </div>
        </Space>
        <div className="buttons-wrap">
          {/* <div>
            <Button onClick={this.changeStep.bind(null, 0)}>{languageMap.textNineteen}</Button>
          </div> */}
          <div>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button variant="contained" size="small" color="primary" onClick={this.saveData}>
                {languageMap.textTwenty}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

NonMotorPolicyForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    insurers: state.insurers,
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(NonMotorPolicyForm)));
