import React from 'react';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import './_header.scss';

interface Props {
  action?: () => void;
  text: string;
  className?: string | undefined;
}

const Header: React.FC<Props> = ({ action, text, className }) => {
  return (
    <div className={'header-section ' + className}>
      <div className="back" onClick={action}>
        <img src={BackArrow} alt="back_arrow" />
      </div>
      <p className="text">{text} </p>
    </div>
  );
};

export default Header;
