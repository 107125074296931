import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modal';
import Close from '../../images/close.svg';

const Component = ({ close, children }) => {
  let component = React.cloneElement(children, { close });
  return <>{component}</>;
};

function Modal(props) {
  const dispatch = useDispatch();

  function close() {
    if (props.close) {
      props.close();
    }
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  }

  useEffect(() => {
    if (props.hideModal) {
      close();
    }
  }, [props.hideModal]);

  return (
    <div className="fairdee-modal-wrapper grey" onClick={(e) => e.stopPropagation()}>
      <div className={'modal-content hollow-container ' + (props.size || '')} id="modal">
        {!props.backgroundClick && !props.hideClose ? (
          <div className="close-wrap" onClick={close}>
            <img src={Close} alt="" />
          </div>
        ) : null}

        <Component close={close}>{props.component}</Component>
      </div>
    </div>
  );
}

export default Modal;
