import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';

class DeleteActionModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  delete = () => {
    if (this.props.id && this.props.action) {
      this.props.action(this.props.id);
      this.close();
    } else {
      window.console.log('action and id props is required');
    }
  };

  render() {
    return (
      <div className="fairdee-modal-wrapper delete-modal">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="message">
            <p className="title-text">Delete Remark</p>
            <p className="text">
              Are you sure you want to delete this remark? <br />
              This cannot be undone.
            </p>
            <div className="buttons-wrap">
              <button className="button medium orange-bordered-button" onClick={this.close}>
                Cancel
              </button>
              <button className="button medium" onClick={this.delete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteActionModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(DeleteActionModal));
