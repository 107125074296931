import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { showNotification } from '../../actions/notification';
import { NOTIFICATION } from '../../constants/types';
import infoIcon from '../../images/yellow-info.png';
import { commaSeperatedNumbers, isMember, numberWithCommas, setLanguageToElement } from '../../utils/helper';

class InsurerCardMini extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardData: this.props.data,
    };
    window.console.log(props);
  }

  componentDidMount() {
    setLanguageToElement();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      cardData: nextProps.data,
    });
  }

  continue = (cardData) => {
    this.props.next && this.props.next(cardData);
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  renderTags = () => {
    const cardData = this.state.cardData;
    let tags = [];
    if (cardData.meta_data && cardData.meta_data.selling_points) {
      let selling_points = cardData.meta_data.selling_points.filter((data) => data != '');
      selling_points.forEach((spoint, index) => {
        tags.push(
          <span key={index + 3} className="tags">
            {spoint.replace('-', '')}
          </span>
        );
      });
    }
    return tags;
  };

  render() {
    const languageMap = this.props.languageMap.components.insurerCard;
    const { cardData } = this.state;

    return (
      <div className={'insurer-card-new animated fadeIn ' + this.props.className}>
        <div className="header">
          <div>
            <div className="img-wrap">
              <img src={this.state.cardData.insurer.logo} alt="" />
            </div>
            <div className="details-wrap">
              <div>
                <p className="text-one">
                  {this.state.cardData.insurer.name} {this.state.cardData.plan_name}
                </p>
                <p className="text-two">
                  {numberWithCommas(commaSeperatedNumbers(this.state.cardData.premium_after_tax))}{' '}
                  {languageMap.bahtPerYear.textTwo}
                </p>
              </div>
              {cardData.is_vaccine_insurance && <div className="tag">Vaccine Insurance</div>}
            </div>
          </div>
        </div>
        <div className="column-wrap">
          <div className="col">
            <p className="key">{languageMap.coronaDetails.deathCoverage}</p>
            <p className="value">
              <span>{numberWithCommas(cardData.death_coverage)}</span>
              <Popup
                className="corona-popups"
                trigger={<img src={infoIcon} alt="" className="info-icon" />}
                position="bottom center"
                hoverable
              >
                <p className="text">
                  การเจ็บป่วยด้วยภาวะโคม่า หรือ ภาวะสมองตายและระบบประสาทล้มเหลว หรือ
                  การเจ็บป่วยระยะสุดท้ายซึ่งเป็นการเจ็บป่วยรุนแรงที่แพทย์ลงความเห็นว่าไม่มีวีธีการรักษาให้หายได้
                  และ/หรือ เป็นสาเหตุทำให้เสียชีวิตโดยมีสาเหตุหลักมาจากติดเชื่อไวรัสโคโรนา
                </p>
              </Popup>
            </p>
          </div>
          <div className="col">
            <p className="key">{languageMap.coronaDetails.medicalCoverage}</p>
            <p className="value">
              <span>
                {numberWithCommas(cardData.medical_coverage)} {languageMap.baht}
              </span>
              <Popup
                className="corona-popups"
                trigger={<img src={infoIcon} alt="" className="info-icon" />}
                position="bottom center"
                hoverable
              >
                <p className="text">
                  ค่ารักษาพยาบาลกรณีเจ็บป่วยจากการติดเชื้อไวรัสโคโรน่า ทั้งกรณีผู้ป่วยในและผู้ป่วยนอก
                </p>
              </Popup>
            </p>
          </div>

          <div className="col">
            <p className="key">{languageMap.coronaDetails.diagnosedCoverage}</p>
            <p className="value">
              {numberWithCommas(cardData.diagnosed_coverage)} {languageMap.baht}
              <Popup
                className="corona-popups"
                trigger={<img src={infoIcon} alt="" className="info-icon" />}
                position="bottom center"
                hoverable
              >
                <p className="text">
                  การได้รับการวินิฉัยโรคจากแพทย์เป็นครั้งแรกว่าติดเชื้อไวรัสโคโรนา (Coronavirus (COVID-19))
                </p>
              </Popup>
            </p>
          </div>
        </div>
        <div className="column-wrap">
          <div className="col">
            <p className="key">{languageMap.coronaDetails.hospitalCash}</p>
            <p className="value">
              {' '}
              {numberWithCommas(cardData.hospital_cash)} {languageMap.baht}
              <Popup
                className="corona-popups"
                trigger={<img src={infoIcon} alt="" className="info-icon" />}
                position="bottom center"
                hoverable
              >
                <p className="text">{cardData.metadata.hospital_cash_message}</p>
              </Popup>
            </p>
          </div>
          <div className="col">
            <p className="key">{languageMap.coronaDetails.pa}</p>
            <p className="value">
              {' '}
              {numberWithCommas(cardData.pa)} {languageMap.baht}
            </p>
          </div>
        </div>
        <div className="discount-wrap">{this.renderTags()}</div>
        {!this.props.hideAction ? (
          <div className="buttons-wrap-mobile-card">
            {!isMember() && this.props.showCheckbox ? <div></div> : null}
            <div onClick={this.continue.bind(null, this.state.cardData)}>
              <button className="button medium">
                {!isMember() ? languageMap.buttonText : languageMap.buttonTextOne}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

InsurerCardMini.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showNotification })(InsurerCardMini));
