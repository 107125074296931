import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import api from '../commonjs/api';
import crossLg from '../images/cross-lg.svg';
import cross from '../images/cross.svg';
import {
  commaSeperatedNumbers,
  errorHandler,
  getParameterByName,
  isMobileDevice,
  setLanguageToElement,
} from '../utils/helper';
import InsurerCardMini from './InsurerCardMini';
import InsurerCard from './InsurerCardNew';

const token = getParameterByName('t');

class QuickViewInsurer extends React.Component {
  constructor(props) {
    super(props);
    this.insurerData = this.props.data;
    this.state = {
      data: this.insurerData,
      cardClass: '',
    };

    this.unlisten = this.props.history.listen(() => {
      let isOpen = getParameterByName('quickview') === 'true' ? true : false;
      if (!isOpen) {
        this.close();
      }
    });
  }

  componentDidMount() {
    setLanguageToElement();
    this.priceListInstallmentType({ price_list_id: this.props.data.id, with_breakup_amount: true, quotation_query_id: this.props.data?.quotation_query_id });
    this._bindScroll();
  }

  componentWillUnmount() {
    this._unbindScroll();
  }

  _bindScroll = () => {
    // Use passive event listener if available
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true;
        },
      });
      window.addEventListener('test', null, opts);
    } catch (e) {} // eslint-disable-line no-empty
    window.addEventListener('scroll', this._handleScroll, supportsPassive ? { passive: true } : false);
  };

  _unbindScroll = () => {
    window.removeEventListener('scroll', this._handleScroll);
  };

  _handleScroll = () => {
    // Ugly cross-browser compatibility
    const top = document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;

    if (top > 60) {
      this.setState({
        cardClass: 'with-shadow',
      });
    } else {
      this.setState({
        cardClass: '',
      });
    }
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
    // changeUrlParams('/get-quotes', 'quickview', '');
  };

  priceListInstallmentType = (params = {}) => {
    let promise = api.utils.priceListInstallmentType(params);

    promise
      .then((resp) => {
        let planA = [],
          planB = [],
          instalmentOption = {
            planA: planA,
            planB: planB,
          };
        if (Object.keys(resp)[0] && resp[Object.keys(resp)[0]]) {
          resp[Object.keys(resp)[0]].forEach((data) => {
            if (data.instalment_plan === 'Plan A') {
              planA.push(data);
            } else {
              planB.push(data);
            }
          });
        }

        this.setState({
          data: { ...this.state.data, instalmentOption },
          showDetailsLoader: !this.state.showDetailsLoader,
        });
      })
      .catch((error) => {
        this.setState({
          showDetailsLoader: !this.state.showDetailsLoader,
        });
        errorHandler(error.response, true);
      });
  };

  renderBreakup = (data, comission = false) => {
    const languageMapDetails = this.props.languageMap.components.insurerDetails;
    if (data.breakup?.length < 2) {
      return '-';
    }

    if (comission) {
      return <span>{languageMapDetails.instalmentOptions[data.commission_payment_schedule]}</span>;
    }

    if (data.instalment_plan === 'Plan A') {
      return (
        <span>
          {data.breakup.length === 2
            ? `( ${commaSeperatedNumbers(Math.round(parseFloat(data.breakup[0].breakup_amount)), 0)} * 2 ) `
            : `(${commaSeperatedNumbers(Math.round(parseFloat(data.equal_instalment_breakup_value)), 0)} * ${
                data.breakup.length
              })`}
        </span>
      );
    }

    return (
      <span>
        {data.breakup?.length === 2
          ? `( ${commaSeperatedNumbers(Math.round(parseFloat(data.breakup[0].breakup_amount)), 0)} * 2 ) `
          : `( ${commaSeperatedNumbers(
              Math.round(parseFloat(data.breakup?.[0].breakup_amount)),
              0
            )} + (${commaSeperatedNumbers(Math.round(parseFloat(data.breakup?.[1].breakup_amount)), 0)} * ${
              data.breakup?.length - 1
            } ) )`}
      </span>
    );
  };
  renderInstalmentTable = (type, ele = '', plan) => {
    const languageMapDetails = this.props.languageMap.components.insurerDetails,
      { instalmentOption } = this.state.data;

    if (!instalmentOption) {
      return;
    }
    let planA = {},
      planB = {};

    instalmentOption &&
      instalmentOption.planA &&
      instalmentOption.planA.forEach((data) => {
        if (data.name.indexOf(ele) > -1) {
          planA = data;
        }
      });
    instalmentOption &&
      instalmentOption.planB &&
      instalmentOption.planB.forEach((data) => {
        if (data.name.indexOf(ele) > -1) {
          planB = data;
        }
      });
    if (type === 'tableHeader') {
      return (
        <>
          <div className="col">
            <p className="text-one bold">{languageMapDetails.instalmentOptions.instalmentsNo}</p>
          </div>
          <div className="col">
            <p className="text-one bold">{languageMapDetails.instalmentOptions.paymentPlan}</p>
          </div>
          <div className="col">
            <p className="text-one bold">{languageMapDetails.instalmentOptions.comissionTransferred}</p>
          </div>
        </>
      );
    }
    if (type === 'row') {
      if (ele === '3' || ele === '4') {
        if (plan === 'plan A') {
          return (
            <>
              <div className="col">
                <p className="text-one ">{ele}</p>
              </div>
              <div className="col">
                <p className="text-one ">&#3647;{this.renderBreakup(planA)}</p>
              </div>
              <div className="col">
                <p className="text-one ">{this.renderBreakup(planA, true)}</p>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="col">
                <p className="text-one ">{ele}</p>
              </div>
              <div className="col">
                <p className="text-one ">&#3647;{this.renderBreakup(planB)}</p>
              </div>
              <div className="col">
                <p className="text-one ">{this.renderBreakup(planB, true)}</p>
              </div>
            </>
          );
        }
      } else {
        if (plan === 'plan A') {
          return (
            <>
              <div className="col">
                <p className="text-one bold">{ele}</p>
              </div>
              <div className="col">
                <p className="text-one ">&#3647;{this.renderBreakup(planA)}</p>
              </div>
              <div className="col">
                <p className="text-one ">{this.renderBreakup(planA, true)}</p>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="col">
                <p className="text-one bold">{ele}</p>
              </div>
              <div className="col">
                <p className="text-one ">&#3647;{this.renderBreakup(planB)}</p>
              </div>
              <div className="col">
                <p className="text-one ">{this.renderBreakup(planB, true)}</p>
              </div>
            </>
          );
        }
      }
    }
  };

  render() {
    const data = { ...this.state.data };
    const metaData = data.meta_data;
    const languageMap = this.props.languageMap.components.insurerDetails;
    const languageMapDetails = this.props.languageMap.components.insurerDetails;
    let selling_points = [];
    let cars_excluded_from_package = [];
    if (metaData && metaData.selling_points) {
      selling_points = metaData.selling_points.split('\n').filter((d) => d != '');
    }
    if (metaData && metaData.cars_excluded_from_package) {
      cars_excluded_from_package = metaData.cars_excluded_from_package.split('\n').filter((d) => d != '');
    }

    return (
      <div className={'quick-view-wrapper ' + (token ? 'tokenised' : '')}>
        <div className="mobile-header show">
          <p className="current-page">
            <img src={cross} alt="" onClick={this.close} />
            {data.insurer.name}
          </p>
        </div>
        <div className="quick-view-content container">
          {isMobileDevice() ? (
            <InsurerCardMini
              data={this.props.data}
              className={this.state.cardClass}
              handleChange={this.props.handleChange}
              showBasic={true}
              updateSuminsured={this.props.updateSuminsured}
              closeParent={this.close}
              hideAction={this.props.hideAction}
              hideCommission={this.props.hideCommission}
              checkAndOpenLicenseModal={this.props.checkAndOpenLicenseModal}
            />
          ) : (
            <InsurerCard
              data={this.props.data}
              className={this.state.cardClass}
              handleChange={this.props.handleChange}
              showBasic={true}
              updateSuminsured={this.props.updateSuminsured}
              closeParent={this.close}
              hideAction={this.props.hideAction}
              hideCommission={this.props.hideCommission}
              checkAndOpenLicenseModal={this.props.checkAndOpenLicenseModal}
            />
          )}

          <div className={this.props.hideAction ? 'insurer-details-wrap reduced-padding-top' : 'insurer-details-wrap'}>
            <p className="title-text with-margin">{languageMap.titleText}</p>
            <img src={crossLg} onClick={this.close} alt="" className="close" />
            {selling_points.length ? (
              <div className="extra-info each-wrap">
                <p className="title-text">{languageMap.textOne}</p>
                <div className="list-wrap">
                  {selling_points.map((list, index) => {
                    return (
                      <p className="list" key={index}>
                        <span>&#9679;</span> {list.replace('-', '')}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {this.props.quotationAffiliate &&
            this.props.quotationAffiliate.instalment_allowed &&
            !this.props.data.disable_instalment &&
            this.props.data.insurer.is_instalment_supported ? (
              <div className="ecah-wrap ui grid">
                <div className="section">
                  <div className="title-wrap">
                    <p className="text-one">{languageMapDetails.instalmentOptionAvailable}</p>
                  </div>
                  <div className="content tabular">
                    <div className="row">
                      <p className="text-one bold">{languageMapDetails.instalmentOptions.planA}</p>
                    </div>
                    <div className="row">{this.renderInstalmentTable('tableHeader')}</div>
                    <div className="row">{this.renderInstalmentTable('row', '3', 'plan A')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '4', 'plan A')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '6', 'plan A')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '8', 'plan A')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '10', 'plan A')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '12', 'plan A')} </div>
                    <div className="row">
                      <p className="text-one bold">{languageMapDetails.instalmentOptions.planB}</p>
                    </div>
                    <div className="row">{this.renderInstalmentTable('tableHeader')}</div>
                    <div className="row">{this.renderInstalmentTable('row', '3', 'plan B')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '4', 'plan B')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '6', 'plan B')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '8', 'plan B')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '10', 'plan B')} </div>
                    <div className="row">{this.renderInstalmentTable('row', '12', 'plan B')} </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="each-wrap ui grid">
              <div className="sixteen wide column">
                <p className="title-text">{languageMap.sectionOne.title}</p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionOne.textSeven}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {data.sum_insured || '-'} <span> {languageMap.baht}</span>
                </p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionOne.textFive}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {commaSeperatedNumbers(data.fire_theft) || '-'} <span>{languageMap.baht}</span>
                </p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionOne.textFour}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {commaSeperatedNumbers(data.flood_protection) || '-'} <span>{languageMap.baht}</span>
                </p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionOne.textThree}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {commaSeperatedNumbers(data.deductible)} <span>{languageMap.baht}</span>
                </p>
              </div>
              {data.theft ? (
                <>
                  <div className="ten wide column">
                    <p className="text">{languageMap.sectionOne.theft}</p>
                  </div>
                  <div className="six wide column">
                    <p className="text val">
                      {commaSeperatedNumbers(data.theft)} <span>{languageMap.baht}</span>
                    </p>
                  </div>
                </>
              ) : null}
              {data.terrorism ? (
                <>
                  <div className="ten wide column">
                    <p className="text">{languageMap.sectionOne.terrorism}</p>
                  </div>
                  <div className="six wide column">
                    <p className="text val">
                      {commaSeperatedNumbers(data.terrorism)} <span>{languageMap.baht}</span>
                    </p>
                  </div>
                </>
              ) : null}
              <div className="ten wide column no-margin">
                <p className="text">{languageMap.sectionOne.textSix}</p>
              </div>
              <div className="six wide column no-margin">
                <p className="text val">{languageMap.textMap[data.garage_type]}</p>
              </div>
            </div>
            <div className="each-wrap ui grid">
              <div className="sixteen wide column">
                <p className="title-text">{languageMap.sectionTwo.title}</p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionTwo.textSix}</p>
              </div>
              <div className="six wide column">
                <p className="text with-margin val">
                  {commaSeperatedNumbers(data.bi)} <span>{languageMap.person}</span>
                </p>
                <p className="text val">
                  {commaSeperatedNumbers(data.bt)} <span>{languageMap.incident}</span>
                </p>
              </div>
              <div className="ten wide column no-margin">
                <p className="text">{languageMap.sectionTwo.textThree}</p>
              </div>
              <div className="six wide column no-margin">
                <p className="text val">
                  {commaSeperatedNumbers(data.third_party)} <span>{languageMap.baht}</span>
                </p>
              </div>
            </div>
            <div className="each-wrap ui grid">
              <div className="sixteen wide column">
                <p className="title-text with-margin">{languageMap.sectionThree.title}</p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionThree.textOne}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {commaSeperatedNumbers(data.pa)} <span>{languageMap.baht}</span>
                </p>
              </div>
              <div className="ten wide column">
                <p className="text">{languageMap.sectionThree.textFive}</p>
              </div>
              <div className="six wide column">
                <p className="text val">
                  {commaSeperatedNumbers(data.bail_bond)} <span>{languageMap.baht}</span>
                </p>
              </div>
              <div className="ten wide column no-margin">
                <p className="text">{languageMap.sectionThree.textSix}</p>
              </div>
              <div className="six wide column no-margin">
                <p className="text val">
                  {commaSeperatedNumbers(data.hospitalization)} <span>{languageMap.baht}</span>
                </p>
              </div>
            </div>
            {data.garage_list && (
              <div className="each-wrap ui grid">
                <div className="sixteen wide column no-margin">
                  <p className="title-text">{languageMap.sectionFour.title}</p>
                </div>
                <div className="ten wide column"></div>
                <div className="six wide column no-margin">
                  <p className="text with-margin val">
                    {data.number_of_garages} {languageMap.garagesPerDealer}
                  </p>
                  <p className="text">
                    <a href={data.garage_list} target="_blank">
                      {languageMap.fullList}
                    </a>
                  </p>
                </div>
              </div>
            )}
            {data.branches_list && (
              <div className="each-wrap ui grid">
                <div className="sixteen wide column no-margin">
                  <p className="title-text">{languageMap.sectionFive.title}</p>
                </div>
                <div className="ten wide column"></div>
                <div className="six wide column no-margin">
                  <p className="text with-margin val">
                    {data.number_of_branches} {languageMap.branches}
                  </p>
                  <p className="text">
                    <a href={data.branches_list} target="_blank">
                      {languageMap.fullList}
                    </a>
                  </p>
                </div>
              </div>
            )}
            {data.hospitals_list && (
              <div className="each-wrap ui grid">
                <div className="sixteen wide column">
                  <p className="title-text with-margin">{languageMap.sectionSix.title}</p>
                </div>
                <div className="ten wide column"></div>
                <div className="six wide column no-margin">
                  <p className="text with-margin val">
                    {data.number_of_hospitals} {languageMap.hospitals}
                  </p>
                  <p className="text">
                    <a href={data.hospitals_list} target="_blank">
                      {languageMap.fullList}
                    </a>
                  </p>
                </div>
              </div>
            )}

            {metaData ? (
              <div className="each-wrap ui grid">
                <div className="sixteen wide column">
                  <p className="title-text">{languageMap.textTwo}</p>
                </div>
                <div className="ten wide column">
                  <p className="text bold">{languageMap.textThree}</p>
                </div>
                <div className="six wide column">
                  <p className="text with-margin val">{metaData.method_of_car_inspection || '-'}</p>
                </div>
                {cars_excluded_from_package.length ? (
                  <div className="ten wide column">
                    <p className="text bold">{languageMap.textFour}</p>
                  </div>
                ) : null}
                {cars_excluded_from_package.length ? (
                  <div className="six wide column">
                    {cars_excluded_from_package.map((car, index) => {
                      return (
                        <p className="text val" key={index}>
                          {car}
                        </p>
                      );
                    })}
                  </div>
                ) : null}
                <div className="ten wide column">
                  <p className="text bold">{languageMap.textFive}</p>
                </div>
                <div className="six wide column">
                  <p className="text with-margin val">{metaData.road_side_assistance || '-'}</p>
                </div>
                <div className="ten wide column">
                  <p className="text bold">{languageMap.textSix}</p>
                </div>
                <div className="six wide column">
                  <p className="text with-margin val">{metaData.free_car_during_repair || '-'}</p>
                </div>
              </div>
            ) : null}
            {metaData && metaData.other_details ? (
              <div className="each-wrap ui grid with-line">
                <div>
                  <p className="title-text">{languageMap.textSeven}</p>
                </div>
                <div>
                  <p className="text pre-wrap">{metaData.other_details}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

QuickViewInsurer.propTypes = {
  languageMap: PropTypes.any.isRequired,
  updateSuminsured: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(QuickViewInsurer));
