import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

class QuotationConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  action = () => {
    this.props.action();
    this.close();
  };

  render() {
    const { data } = this.props;
    const languageMap = this.props.languageMap.components.conditionsPopup;

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <div className="mobile-content">
            <h1 className="title-text">{languageMap.textOne}</h1>
            <p className="text">{languageMap.textTwo}</p>
            <div className="content-details-wrap conditions-wrap">
              {data.map((d) => {
                return (
                  <div className="row" key={d.id}>
                    <div className="col img-wrap">
                      <img src={d.insurer.logo} alt="" />
                    </div>
                    <div className="col">
                      <p className="text">{d.is_cashback_supported ? languageMap.textFive : languageMap.textSix}</p>
                      <p className="text" title={d.plan_name}>
                        <span>{d.plan_name}</span>
                      </p>
                    </div>
                    {
                      <div className="col">
                        {d.restriction_text && <p className="text">{d.restriction_text}</p>}
                        {d.insurer_restrictions &&
                          d.insurer_restrictions.length > 0 &&
                          d.insurer_restrictions.map((restriction) => (
                            <p className="text padded-text">{restriction}</p>
                          ))}
                      </div>
                    }
                  </div>
                );
              })}
            </div>
          </div>
          <div className="buttons-wrap single">
            <button className="button medium" onClick={this.action}>
              {languageMap.textFour}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

QuotationConfirmationModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(QuotationConfirmationModal));
