import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import Validator from 'validator';
import { setAffiliate } from '../../actions/affiliate';
import { login, register, socialLogin, userProfile } from '../../actions/auth';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { CLIENT_ID, CLIENT_SECRET, FACEBOOK_ID } from '../../constants/config';
import {
  CONFIRMATION_MODAL,
  MODAL_TYPE_LOGIN,
  MODAL_TYPE_LOGIN_SIGNUP,
  MODAL_TYPE_MAKE_MODEL,
  NOTIFICATION,
  USER_REGISTERED,
} from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross-white.svg';
import facebookImgThai from '../../images/onboarding/facebook-thai.png';
import facebookImg from '../../images/onboarding/facebook.png';
import { store } from '../../index';
// import Checkbox from '@material-ui/core/Checkbox';
/* global mobileClient */
import {
  errorHandler,
  getParameterByName,
  getParametersBySubstring,
  getUrlparamsAsObject,
  getUserData,
  gtagReport,
  isMobileDevice,
  isMobileNumber,
  notifyList,
  pixelEvent,
  scrollInnerElements,
  setAccessToken,
  setAxiosHeaders,
  setLanguageToElement,
  setLocalData,
  setUserData,
  validateThaiNationalId,
} from '../../utils/helper';
import { Mixpanel } from '../../utils/tracking';

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        fullname: '',
        phone: '',
        referral_code: getParameterByName('ref') || '',
        client_id: CLIENT_ID,
        has_broker_license: null,
        broker_license_number: '',
        national_id: '',
        terms_and_conditions: false,
      },
      loading: false,
      errors: {},
      name: '',
      photo: '',
      hideReferralCode: Boolean(getParameterByName('offline')) || false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    setLanguageToElement();
    let signupFlow = '';

    if (getParameterByName('ref')) {
      signupFlow = 'Signup Referral';
      window.hj && window.hj('trigger', 'signup_referral');
    }
    if (getParameterByName('utm_medium')) {
      signupFlow = 'Signup Facebook';
      window.hj && window.hj('trigger', 'signup_facebook');
    }
    if (!getParameterByName('utm_medium') && !getParameterByName('ref')) {
      signupFlow = 'Signup Basic';
      window.hj && window.hj('trigger', 'signup_basic');
    }
    // if (this.state.data.referral_code) {
    //   this.getReferrerName();
    // }
    Mixpanel.track('Login/signup', {
      object: signupFlow,
      objectContext: {
        parent: 'Signup Modal',
      },
      action: 'Viewed',
    });
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  generateRedirectionPath = (affiliate) => {
    if (!affiliate) {
      return null;
    }
    let redirectionPath = null;
    if (affiliate.has_broker_license === true) {
      if (!affiliate.broker_license_number || !affiliate.national_id) {
        redirectionPath = '/aff-additional-info';
        if (!affiliate.user.phone) {
          redirectionPath += '?type=extra-info&field=phone';
        }
      } else if (affiliate.is_oic_data_verified === false) {
        redirectionPath = '/aff-additional-info-oic';
      }
    }
    window.console.log('redirection path', redirectionPath);
    return redirectionPath;
  };

  openLoginModal = () => {
    this.close();
    Mixpanel.track('Login/signup', {
      object: 'Login Link',
      objectContext: {
        parent: 'Signup Modal',
      },
      action: 'Clicked',
    });
    this.props.showModal(MODAL_TYPE_LOGIN, {});
  };

  openBrokerLicenseConfirmationModal = () => {
    const languageMap = this.props.languageMap.components.signup;

    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.updateHasbrokerLicense,
      text: languageMap.confirmationTitle,
      subText: languageMap.noBrokerMessage,
      acceptText: languageMap.agree,
      rejectText: languageMap.disagree,
    });
  };

  openLoginSignupModal = () => {
    this.close();
    this.props.showModal(MODAL_TYPE_LOGIN_SIGNUP, {});
  };

  openMakeModelModal = () => {
    this.props.showModal(MODAL_TYPE_MAKE_MODEL, {});
  };

  userProfile = () => {
    this.props.userProfile();
  };

  updateHasbrokerLicense = () => {
    this.setState({
      data: { ...this.state.data, has_broker_license: 'false' },
    });
  };

  onChange = (e) => {
    if (e.target.name === 'has_broker_license' && e.target.value === 'false') {
      this.openBrokerLicenseConfirmationModal();
      return;
    }
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onCheckboxChange = (e, data) => {
    window.console.log(data.checked);
    this.setState({
      data: { ...this.state.data, [data.name]: data.checked },
    });
  };

  handleFocus = (e) => {
    let cuurntElement = e.target.parentElement,
      mobileDeviceTrue = isMobileDevice(),
      parentElement = mobileDeviceTrue
        ? this.myRef.current
        : this.myRef.current.getElementsByClassName('signup-container')[0],
      offset = mobileDeviceTrue ? 80 : 120;
    scrollInnerElements(parentElement, cuurntElement, offset);
  };

  onRadioChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
    });
  };

  onMonthChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, current_policy_end_date: data.value },
    });
  };

  validate = (data, key) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;

    if (key) {
      if (!data[key]) {
        errors[key] = languageMap.required;
      }
      if (key === 'email' && data[key] && !Validator.isEmail(data[key])) {
        errors[key] = languageMap[key];
      }
      if (key === 'phone' && data[key] && !isMobileNumber(data[key])) {
        errors[key] = languageMap[key];
      }
      this.setState({
        errors,
      });
      return;
    }

    window.console.log(data);
    if (!data.fullname) {
      errors.fullname = languageMap.required;
    }
    if (!data.email) {
      errors.email = languageMap.required;
    }
    if (!data.phone) {
      errors.phone = languageMap.required;
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = languageMap.email;
    }
    if (data.phone && !isMobileNumber(data.phone)) {
      errors.phone = languageMap.phone;
    }
    if (data.has_broker_license === null) {
      errors.has_broker_license = languageMap.required;
    }
    if (data.has_broker_license === 'true') {
      // if (!data.broker_license_number) {
      //   errors.broker_license_number = languageMap.required;
      // }

      // if (!data.national_id) {
      //   errors.national_id = languageMap.required;
      // }

      if (data.national_id && !validateThaiNationalId(data.national_id)) {
        errors.national_id = languageMap.invalidFormat;
      }
    }
    // if (!data.terms_and_conditions) {
    //   errors.terms_and_conditions = languageMap.required;
    // }
    return errors;
  };

  login = (_) => {
    const params = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      username: this.state.data.email,
      password: this.state.data.phone,
    };
    const languageMap = this.props.languageMap.messages;
    window.console.log(params);
    this.setState({
      loading: true,
    });
    this.props
      .login(params)
      .then(() => {
        window.console.log(getUserData());
        // this.notify(languageMap.successfulSignup, 'success');
      })
      .catch((error) => {
        let languageMap = this.props.languageMap.components.login;
        let messages = this.props.languageMap.messages;
        this.setState({
          loading: false,
        });
        window.console.log(error.response);
        if (error.response && error.response.status && error.response.status === 401) {
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error === 'invalid_grant') {
              this.setState({
                emailNotVerified: false,
                errors: {
                  global:
                    languageMap.textFour + ' ' + error.response.data.remaining_attempts + ' ' + languageMap.textFive,
                },
              });
            }
            if (error.response.data.error === 'inactive_user') {
              this.setState({
                emailNotVerified: true,
                errors: { global: 'Not verified' },
              });
            }
            if (error.response.data.error === 'invalid_email') {
              this.setState({
                emailNotVerified: true,
                errors: { global: messages.unregisteredEmail },
              });
            }

            if (error.response.data.error === 'banned_user') {
              this.setState({
                emailNotVerified: true,
                errors: { global: messages.bannedUser },
              });
            }
          }
        }
        if (
          error.response &&
          error.response.status &&
          error.response.status === 403 &&
          error.response.data &&
          error.response.data.message
        ) {
          this.setState({
            emailNotVerified: false,
            errors: { global: error.response.data.message },
          });
        }
      });
  };

  userRegistered = (user) => ({
    type: USER_REGISTERED,
    user,
  });

  updateUserData = (data) => {
    window.console.log(data);
    setAxiosHeaders(data.access_token);
    setUserData({ accessToken: data.access_token });
    this.props.userProfile();
    store.dispatch(this.userRegistered(data));
  };

  onResolved = () => {
    const languageMap = this.props.languageMap.messages;
    let params = { ...this.state.data },
      tag = getUrlparamsAsObject()['tag'],
      tags = getParametersBySubstring('utm_') || [];
    if (!params.referral_code) {
      delete params.referral_code;
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    });

    if (tag) {
      tag = tag.constructor === Array ? tag : [tag];
      tags = tags.concat(tag);
    }

    if (tags.length) {
      params.tags = tags;
    }

    if ('has_broker_license' in params) {
      params.has_broker_license = params.has_broker_license === 'true';
    }
    window.console.log(params);
    this.setState({
      loading: true,
    });
    this.props
      .register(params)
      .then((resp) => {
        window.console.log(resp);
        pixelEvent('account_created');
        let regResp = resp;
        this.updateUserData(resp);
        gtagReport('AW-796624154/9Nz9COuo1rQBEJqK7vsC');
        Mixpanel.track('Login/signup', {
          object: 'Signup',
          objectContext: {
            parent: 'Signup Modal',
          },
          action: 'Succeeded',
        });
        // this.notify(languageMap.successfulSignup, 'success');
        if (resp.errors) {
          notifyList(resp.errors);
          // this.userProfile('oic');
          this.setState({
            loading: false,
          });
          setAccessToken(resp.access_token);
          let path = this.generateRedirectionPath(resp.message) || '/buy-insurance';
          this.props.history.push(path);
          this.close();
          return;
        }
        if ('has_broker_license' in params && params.has_broker_license === false) {
          setAccessToken(resp.access_token);

          this.setState({
            loading: false,
          });
          this.props.history.push('/buy-insurance');
          this.close();
          return;
        }
        setLocalData('refreshToken', resp.refresh_token);
        api.crm
          .getAffiliateProfile(
            { with_commission_rates: true, with_instalment_credits: true, instalment_credits_with_vendor: true },
            resp.access_token
          )
          .then((resp) => {
            if (resp.message.is_oic_data_verified) {
              pixelEvent('oic_verified');
              gtagReport('AW-796624154/ap8FCLyTyLQBEJqK7vsC');
            }
            this.setState({
              loading: false,
            });
            Mixpanel.register(resp.message);
            store.dispatch(setAffiliate(resp.message));
            setAccessToken(regResp.access_token);
            let path = '/buy-insurance';
            this.props.history.push(path);
            setAccessToken(regResp.access_token);
            this.close();
          });
      })
      .catch((error) => {
        console.log(error.response);
        let messages = this.props.languageMap.messages;
        this.setState({
          loading: false,
        });
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.email &&
          error.response.data.errors.email.length
        ) {
          this.setState({
            errors: {
              email: error.response.data.errors.email[0],
              subText: languageMap.alreadyRegistered,
            },
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          typeof error.response.data.errors === 'object'
        ) {
          window.console.log(error.response.data.errors, Object.keys(error.response.data.errors));
          if (Object.keys(error.response.data.errors).length) {
            Object.keys(error.response.data.errors).map((key) => {
              this.setState({
                errors: {
                  ...this.state.errors,
                  [key]: messages[error.response.data.errors[key][0]] || error.response.data.errors[key][0],
                },
              });
            });
          }
        } else {
          this.setState({
            errors: { ...this.state.errors, ...errorHandler(error.response) },
          });
        }
        Mixpanel.track('Login/signup', {
          object: 'Signup',
          objectContext: {
            parent: 'Signup Modal',
          },
          action: 'Failed',
          actionContext: {
            errors: error.response,
          },
        });
      });
    window.console.log('executed');
  };

  getReferrerName = () => {
    let params = {
      ref: this.state.data.referral_code,
    };

    api.user
      .getReferrerName(params)
      .then((resp) => {
        window.console.log(resp);
        if (resp.photo || resp.name) {
          this.setState({
            name: resp.name,
            photo: resp.photo,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    Mixpanel.track('Login/signup', {
      object: 'Signup Submit',
      objectContext: {
        parent: 'Signup Modal',
      },
      action: 'Clicked',
      actionContext: { errors },
    });
    this.setState({ errors }, () => {
      window.console.log(this.myRef);
      if (Object.keys(errors).length || !this.myRef.current) {
        return;
      }
      let errorElement = this.myRef.current.getElementsByClassName('errors')[0],
        mobileDeviceTrue = isMobileDevice(),
        parentElement = mobileDeviceTrue
          ? this.myRef.current
          : this.myRef.current.getElementsByClassName('signup-container')[0],
        offset = mobileDeviceTrue ? 80 : 120;
      scrollInnerElements(parentElement, errorElement, offset);
    });

    if (Object.keys(errors).length) {
      Mixpanel.track('Login/signup', {
        object: 'Signup Submit',
        objectContext: {
          parent: 'Signup Modal',
        },
        action: 'Failed',
        actionContext: { errors },
      });
      return;
    }

    this.onResolved();
  };

  socialLogin = (credentials) => {
    const tagMap = {
      'facebook-fairdee': 'fb_signup',
    };
    const params = {
      ...credentials,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'convert_token',
      affiliate_signup: true,
    };
    let tag = getUrlparamsAsObject()['tag'],
      tags = getParametersBySubstring('utm_') || [];
    const referral_code = getParameterByName('ref');
    if (referral_code) {
      params.affiliate_data = {
        referral_code,
      };
    }

    if (tag) {
      tag = tag.constructor === Array ? tag : [tag];
      tags = tags.concat(tag);
    }
    tags.push(tagMap[params.backend]);

    if (tags.length) {
      params.affiliate_data = { ...params.affiliate_data, tags };
    }
    this.setState({
      loading: true,
    });
    this.props
      .socialLogin(params)
      .then((resp) => {
        // this.notify(this.props.languageMap.messages.successfulSignup, 'success');
        this.userProfile();
        this.close();
        gtagReport('AW-796624154/9Nz9COuo1rQBEJqK7vsC');
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response && error.response.data && error.response.data.error_description) {
          this.notify(error.response && error.response.data && error.response.data.error_description, 'error');
        }
        if (error.response && error.response.data && error.response.data.email) {
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: referral_code
              ? `?type=extra-info&field=email&ref=${referral_code}`
              : '?type=extra-info&field=email',
            state: { ...params },
          });
          this.close();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.affiliate_data &&
          error.response.data.affiliate_data.phone
        ) {
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: referral_code
              ? `?type=extra-info&field=phone&ref=${referral_code}`
              : '?type=extra-info&field=phone',
            state: { ...params },
          });
          this.close();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.affiliate_data &&
          error.response.data.affiliate_data.has_broker_license
        ) {
          const search = `?type=extra-info&field=has_broker_license&ref=${referral_code}`;
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: referral_code
              ? `?type=extra-info&field=has_broker_license&ref=${referral_code}`
              : '?type=extra-info&field=has_broker_license',
            state: { ...params },
          });
          this.close();
        }
        console.log(error);
      });
  };

  responseFacebook = (response) => {
    console.log('fb response ', response);
    if (!response.accessToken) {
      return;
    }
    const credentials = {
      backend: 'facebook-fairdee',
      token: response.accessToken,
    };

    this.socialLogin(credentials);
  };

  trackFBLoginClick = () => {
    Mixpanel.track('Login/signup', {
      object: 'Facebook Login',
      objectContext: {
        parent: 'Signup Modal',
      },
      action: 'Clicked',
    });
  };

  renderFacebookSignup = () => {
    if (isMobileDevice()) {
      try {
        if (!mobileClient) {
          return (
            <FacebookLogin
              version="3.3"
              appId={FACEBOOK_ID}
              autoLoad={false}
              fields="name,email"
              disableMobileRedirect={true}
              onClick={this.trackFBLoginClick}
              callback={this.responseFacebook}
              scope="email,public_profile"
              render={(renderProps) => (
                <img
                  src={this.props.language === 'english' ? facebookImg : facebookImgThai}
                  onClick={renderProps.onClick}
                  alt=""
                />
              )}
            />
          );
        } else {
          return (
            <FacebookLogin
              version="3.3"
              appId={FACEBOOK_ID}
              autoLoad={false}
              fields="name,email"
              onClick={this.trackFBLoginClick}
              disableMobileRedirect={false}
              isMobile={true}
              callback={this.responseFacebook}
              scope="email,public_profile"
              responseType="token"
              redirectUri={window.location.origin}
              render={(renderProps) => (
                <img
                  src={this.props.language === 'english' ? facebookImg : facebookImgThai}
                  onClick={renderProps.onClick}
                  alt=""
                />
              )}
            />
          );
        }
      } catch (e) {
        // window.console.log(e);
      }
    }

    return (
      <FacebookLogin
        version="3.3"
        appId={FACEBOOK_ID}
        autoLoad={false}
        fields="name,email"
        disableMobileRedirect={true}
        callback={this.responseFacebook}
        scope="email,public_profile"
        onClick={this.trackFBLoginClick}
        render={(renderProps) => (
          <img
            src={this.props.language === 'english' ? facebookImg : facebookImgThai}
            onClick={renderProps.onClick}
            alt=""
          />
        )}
      />
    );
  };

  termsAndConditions = (languageMap, data, errors) => {
    let url = 'https://fairdee-contract.s3-ap-southeast-1.amazonaws.com/Agency+Contract_070819.pdf',
      downloadPdf = null;
    try {
      if (!mobileClient) {
        downloadPdf = false;
      } else {
        downloadPdf = true;
      }
    } catch (e) {
      window.console.log(e);
    }
    return (
      <Form.Field
        className={errors.terms_and_conditions ? 'errors mb-0 normal-input-label' : 'mb-0 normal-input-label'}
      >
        <label>
          {data.has_broker_license === 'true' ? (
            <span>
              {`${languageMap.iAccept} `}
              {!downloadPdf ? (
                <a href={url} target="_blank">
                  {languageMap.termsAndConditions}
                </a>
              ) : (
                <a href={url} download="TermsNConditions">
                  {languageMap.termsAndConditions}
                </a>
              )}
            </span>
          ) : (
            <span>
              {languageMap.iAccept} {languageMap.termsAndConditions}
            </span>
          )}
        </label>
      </Form.Field>
    );
  };

  render() {
    const languageMap = this.props.languageMap.components.signup;
    const { data, errors, loading, hideReferralCode } = this.state;

    if (loading) {
      return (
        <div className="fairdee-modal-wrapper small login-signup">
          <div className="modal-content signup">
            <img src={cross} className="close" alt="" onClick={this.close} />

            <div className="mobile-header">
              <p className="current-page" onClick={this.close}>
                <img src={crossLg} alt="" />
              </p>
              <ul>
                <li>
                  <span onClick={this.openLoginModal}>{languageMap.linkText.textOne}</span>
                </li>
                <li className="active">
                  <span>{languageMap.linkText.textTwo}</span>
                </li>
              </ul>
            </div>
            <div className="signup-container loading">
              <CircularProgress />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="fairdee-modal-wrapper small login-signup" ref={this.myRef}>
        <div className="modal-content signup">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="welcome-header">
            <p className="text-one">Welcome to Fairdee!</p>
            {/* <p className="text-two">Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros eget libero posuere vulputate. </p> */}
          </div>
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
            </p>
            <ul>
              <li>
                <span onClick={this.openLoginModal}>{languageMap.linkText.textOne}</span>
              </li>
              <li className="active">
                <span>{languageMap.linkText.textTwo}</span>
              </li>
            </ul>
          </div>
          <div className="signup-container">
            <div className="section">
              <h1 className="title-text">
                {this.renderFacebookSignup()}
                {/* <LineLogin /> */}
              </h1>
              <p className="or">
                <span>{languageMap.subTitleText}</span>
              </p>
            </div>
            <Form noValidate autoComplete="off">
              <div className="section">
                <Form.Field className={errors.fullname ? 'errors' : ''}>
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    placeholder={languageMap.inputTwo + '*'}
                    value={data.fullname}
                    onChange={this.onChange}
                    autoComplete="off"
                    onBlur={this.validate.bind(null, this.state.data, 'fullname')}
                    onFocus={this.handleFocus}
                  />
                  {errors.fullname && <InlineError text={errors.fullname} />}
                  <label htmlFor="fullname">{languageMap.inputTwo}*</label>
                </Form.Field>

                <Form.Field className={errors.email ? 'errors' : ''}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={languageMap.inputOne + '*'}
                    value={data.email}
                    onChange={this.onChange}
                    autoComplete="off"
                    onBlur={this.validate.bind(null, this.state.data, 'email')}
                    onFocus={this.handleFocus}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                  <label htmlFor="email">{languageMap.inputOne}*</label>
                </Form.Field>

                <Form.Field className={errors.phone ? 'errors' : ''}>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    placeholder={languageMap.inputThree + '*'}
                    value={data.phone}
                    onChange={this.onChange}
                    autoComplete="off"
                    onBlur={this.validate.bind(null, this.state.data, 'phone')}
                    onFocus={this.handleFocus}
                  />
                  {errors.phone && <InlineError text={errors.phone} />}
                  <label htmlFor="phone">{languageMap.inputThree}*</label>
                  {/* <p className="blue-messag">{languageMap.numberText}</p> */}
                </Form.Field>

                <Form.Field className={errors.has_broker_license ? 'errors' : ''}>
                  <RadioGroup
                    className="radio-group"
                    aria-label="has_broker_license"
                    name="has_broker_license"
                    value={data.has_broker_license}
                    onChange={this.onChange}
                    row
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                  <label htmlFor="has_broker_license">{languageMap.inputTextFive}*</label>
                  {errors.has_broker_license && <InlineError text={errors.has_broker_license} />}
                </Form.Field>

                {/* {
                  data.has_broker_license === 'true' ? <>
                    <Form.Field className={errors.broker_license_number ? 'errors' : ''}>
                      <input
                        type="text"
                        id="broker_license_number"
                        name="broker_license_number"
                        placeholder={languageMap.inputTextSix}
                        value={data.broker_license_number}
                        onChange={this.onChange}
                        autoComplete="off"
                        // onBlur={this.validate.bind(null, this.state.data, 'broker_license_number')}
                        onFocus={this.handleFocus}
                      />
                      {errors.broker_license_number && <InlineError text={errors.broker_license_number} />}
                      <label htmlFor="broker_license_number">{languageMap.inputTextSix}</label>
                    </Form.Field>
                    <Form.Field className={errors.national_id ? 'errors' : ''}>
                      <input
                        type="text"
                        id="national_id"
                        name="national_id"
                        placeholder={languageMap.inputTextSeven}
                        value={data.national_id}
                        onChange={this.onChange}
                        autoComplete="off"
                        // onBlur={this.validate.bind(null, this.state.data, 'national_id')}
                        onFocus={this.handleFocus}
                      />
                      {errors.national_id && <InlineError text={errors.national_id} />}
                      <label htmlFor="national_id">{languageMap.inputTextSeven}</label>
                    </Form.Field>
                  </> : null
                } */}
                {!hideReferralCode && (
                  <Form.Field>
                    <input
                      type="text"
                      id="referral_code"
                      name="referral_code"
                      placeholder={languageMap.inputTextFour + ' (FD-XXXX)'}
                      value={data.referral_code}
                      onChange={this.onChange}
                      onFocus={this.handleFocus}
                      autoComplete="off"
                    />
                    {errors.referral_code && <InlineError text={errors.referral_code} />}
                    <label htmlFor="referral_code" className="">
                      {languageMap.inputTextFour + ' (FD-XXXX)'}
                    </label>
                  </Form.Field>
                )}

                {/* <Form.Field className={errors.terms_and_conditions ? 'errors mb-0 normal-input-label' : 'mb-0 normal-input-label'}>
                  <Checkbox label={<label>
                    <span>{languageMap.iAccept} <a href="https://fairdee-contract.s3-ap-southeast-1.amazonaws.com/Agency+Contract_070819.pdf" target="_blank" >
                    {languageMap.termsAndConditions}
                    </a>
                    </span>
                  </label>
                  } name="terms_and_conditions" value={data.terms_and_conditions} checked={data.terms_and_conditions} onChange={ (e, data) => this.onCheckboxChange(e,data) } />
                  {errors.terms_and_conditions && <InlineError text={errors.terms_and_conditions} />}
                </Form.Field> */}
                {this.termsAndConditions(languageMap, data, errors)}
              </div>
            </Form>
          </div>
          <div className="bottom-content">
            <div className="button-wrap">
              <button className="button medium" onClick={this.onSubmit}>
                {languageMap.buttonText}
              </button>
            </div>
            {!isMobileDevice() ? (
              <div>
                <p className="link-text text mb-0 small">{languageMap.actionTextOne.textOne}</p>
                <p className="link-text no-margin small block mt-8">
                  {' '}
                  <NavLink to="/terms-of-use" target="_blank" activeClassName="active-menu">
                    {languageMap.actionTextOne.textTwo}
                  </NavLink>{' '}
                  {languageMap.actionTextOne.textThree}
                </p>
              </div>
            ) : null}
            <p className="link-text text mb-0 desktop">
              {languageMap.actionTextTwo.textOne}{' '}
              <a className="bold" onClick={this.openLoginModal}>
                {languageMap.actionTextTwo.textTwo}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    language: state.language,
  };
}

export default withRouter(
  connect(mapStateToProps, { hideModal, showModal, showNotification, register, userProfile, socialLogin, login })(
    Signup
  )
);
