import React, { useEffect, useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { setAffiliate } from '../../actions/affiliate';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import cross from '../../images/cross.svg';
import { store } from '../../index';
import { errorHandler, validateThaiNationalId } from '../../utils/helper';

export default function LicenseAndIDUpdate(props) {
  let { affiliate } = props;
  const [componentData, setComponentData] = useState({
    national_id: affiliate.national_id || '',
    broker_license_number: affiliate.broker_license_number || '',
    terms_and_conditions: false,
    loading: false,
    updatedData: {},
  });
  const [componentErrors, setComponentErrors] = useState({});
  const languageMap = props.languageMap.components.affiliateAdditionalInfo;

  useEffect(() => {
    if (props.onUpdate) {
      props.onUpdate(componentData.updatedData);
    }
  }, [componentData.updatedData]);

  useEffect(() => {
    if (Object.keys(componentData.updatedData).length) {
      validate();
    }
  }, [componentData]);

  function validate(field) {
    const messages = props.languageMap.messages;
    const errors = { ...componentErrors };
    const mandatoryFields = ['national_id', 'broker_license_number', 'terms_and_conditions'];
    // window.console.log(data.has_broker_license);

    if (field) {
      if (!componentData[field]) {
        errors[field] = messages.required;
      } else {
        delete errors[field];
      }
    } else {
      mandatoryFields.forEach((field) => {
        if (!componentData[field]) {
          errors[field] = messages.required;
        } else {
          delete errors[field];
        }
      });
    }

    if (componentData.national_id) {
      if (!validateThaiNationalId(componentData.national_id)) {
        errors.national_id = messages.invalidFormat;
      } else {
        delete errors[field];
      }
    }

    window.console.log(errors);
    setComponentErrors(errors);
    return errors;
  }

  function handleChange(e, target = undefined) {
    let name = typeof target === 'object' ? target.name : e.target.name,
      value = typeof target === 'object' ? target.value : e.target.value;

    setComponentData({
      ...componentData,
      [name]: value,
      updatedData: {
        ...componentData.updatedData,
        [name]: value,
      },
    });
  }

  function onCheckboxChange(e, data) {
    setComponentData({ ...componentData, [data.name]: data.checked });
  }

  function updateAffiliateDetails() {
    let params = { ...componentData.updatedData };
    setComponentData({ ...componentData, loading: true });

    window.console.log(params);

    api.crm
      .patchAffiliate(affiliate.id, params)
      .then((resp) => {
        setComponentData({ ...componentData, loading: false });
        window.console.log(resp);
        // notify(props.languageMap.messages.updatedSuccessfully, 'success');
        // window.console.log(state.currentStep);s

        if (resp.data.has_broker_license === false) {
          store.dispatch(setAffiliate(resp.data));
          return;
        }
        let affiliate = resp.data;
        window.console.log(affiliate);
        store.dispatch(setAffiliate(affiliate));
        if (affiliate.is_oic_data_verified !== false && !affiliate.user.is_banned) {
          props.next(true);
          close();
        } else {
          if (affiliate.is_oic_data_verified === false) {
            props.notify(languageMap.oicFailed, 'error');
          } else if (affiliate.user.is_banned) {
            props.notify(props.languageMap.messages.bannedUser, 'error');
          }
        }
      })
      .catch((error) => {
        setComponentData({
          ...componentData,
          loading: false,
        });
        console.log(error);
        const apiErrors = errorHandler(error.response, true);
        setComponentErrors({ ...componentErrors, ...apiErrors });
      });
  }

  function save() {
    let errors = validate();

    window.console.log(errors);

    if (Object.keys(errors).length || !Object.keys(componentData.updatedData).length) {
      if (props.affiliate.is_oic_data_verified === false) {
        props.notify(languageMap.oicFailed, 'error');
      }
      return;
    }

    updateAffiliateDetails();
  }

  function close() {
    props.hideModal(null, {
      ...props,
    });
  }

  return (
    <div className="fairdee-modal-wrapper affiliate-info license-modal">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <h1 className="title-text">{props.languageMap.components.licenseAndIdUpdate.title}</h1>
        <p className="help-text">
          {props.genQuoteHelpText
            ? props.languageMap.components.licenseAndIdUpdate.genQuoteHelpText
            : props.languageMap.components.licenseAndIdUpdate.commissionHelpText}
        </p>
        <p className="step-text extra-margin">
          {' '}
          <span>
            (<span>*</span> {props.languageMap.messages.required} ){' '}
          </span>
        </p>
        <Form className={props.formClass}>
          <Form.Field className={componentErrors.broker_license_number ? 'errors' : ''}>
            <input
              type="text"
              id="broker_license_number"
              name="broker_license_number"
              placeholder={languageMap.textTwentyThree + '*'}
              value={componentData.broker_license_number}
              onChange={handleChange}
              autoComplete="off"
              onBlur={validate.bind(null, 'broker_license_number')}
            />
            {componentErrors.broker_license_number && <InlineError text={componentErrors.broker_license_number} />}
            <label htmlFor="broker_license_number">
              {languageMap.textTwentyThree} <span>*</span>
            </label>
          </Form.Field>
          <Form.Field className={componentErrors.national_id ? 'errors' : ''}>
            <input
              type="text"
              id="national_id"
              name="national_id"
              placeholder={languageMap.textTwentyTwo + '*'}
              value={componentData.national_id}
              onChange={handleChange}
              autoComplete="off"
              onBlur={validate.bind(null, 'national_id')}
            />
            {componentErrors.national_id && <InlineError text={componentErrors.national_id} />}
            <label htmlFor="national_id">
              {languageMap.textTwentyTwo} <span>*</span>
            </label>
          </Form.Field>
          <Form.Field className={componentErrors.terms_and_conditions ? 'errors mb-0' : 'mb-0'}>
            <Checkbox
              className="normal-input-checkbox"
              label={
                <label>
                  <span>
                    {languageMap.iAccept}{' '}
                    <a
                      href="https://fairdee-contract.s3-ap-southeast-1.amazonaws.com/Agency+Contract_070819.pdf"
                      target="_blank"
                    >
                      {languageMap.termsAndConditions}
                    </a>
                  </span>
                </label>
              }
              name="terms_and_conditions"
              value={componentData.terms_and_conditions}
              checked={componentData.terms_and_conditions}
              onChange={(e, data) => onCheckboxChange(e, data)}
            />
            {componentErrors.terms_and_conditions && <InlineError text={componentErrors.terms_and_conditions} />}
          </Form.Field>
          <div className="button-wrap">
            <button
              className={
                'button primary ' + (componentData.loading || !componentData.terms_and_conditions ? 'disabled' : '')
              }
              onClick={save}
            >
              {languageMap.textFifteen}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
