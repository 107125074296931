import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { hideModal, showModal } from '../actions/modal';
import { YOUTUBE_MODAL } from '../constants/types';
import Cancel from '../images/after-signup-carousel/cancel.svg';
import SlideTwoImageMobile from '../images/after-signup-carousel/image-three-mobile.png';
import SlideTwoImage from '../images/after-signup-carousel/image-three.png';
import SlideThreeImage from '../images/after-signup-carousel/img-two.png';
import LineMobile from '../images/after-signup-carousel/line-icon-mobile.png';
import Line from '../images/after-signup-carousel/line-icon.png';
import LogoMobileImage from '../images/after-signup-carousel/logos-mobile.png';
import LogoImage from '../images/after-signup-carousel/logos.png';
import Qrcode from '../images/after-signup-carousel/qr-code.png';
import TitleFour from '../images/after-signup-carousel/slide-four-title.png';
import TitleOne from '../images/after-signup-carousel/slide-one-title.png';
import TitleThree from '../images/after-signup-carousel/slide-three-title.png';
import TitleTwoMobile from '../images/after-signup-carousel/slide-two-title-mobile.png';
import TitleTwo from '../images/after-signup-carousel/slide-two-title.png';
import { getLineUrl, isMobileDevice, isSubAgent, isTelesales, setLocalData } from '../utils/helper';

function NextArrow(props) {
  function handleClick() {
    let closeSlide = isTelesales() || isSubAgent() ? 2 : 3;
    if (props.currentSlide === closeSlide) {
      setLocalData('hideCarousel', true);
      props.close();
    } else if (props.onClick) {
      props.onClick();
    }
  }
  return (
    <button className={'slick-arrow slick-next ' + props.cname} onClick={handleClick}>
      {props.text}
    </button>
  );
}

function PrevArrow(props) {
  function handleClick() {
    props.onClick && props.onClick();
  }
  return (
    <button className={'slick-arrow slick-prev ' + props.cname} onClick={handleClick}>
      {props.text}
    </button>
  );
}

function AfterSignupCarousel(props) {
  const languageMap = useSelector((state) => state.languageMap.components.afterLoginCarousel),
    affiliate = useSelector((state) => state.affiliate),
    [currentSlide, setCurrentSlide] = useState(0),
    dispatch = useDispatch();

  let settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    afterChange: (slide) => {
      console.log(slide);
      setCurrentSlide(slide);
    },
  };

  let prevText = languageMap.btnTextOne,
    nextText = languageMap.btnTextTwo,
    activeClass = '',
    hideClass = '';

  if (currentSlide === 3) {
    nextText = languageMap.btnTextThree;
    activeClass = 'orange';

    if (isMobileDevice()) {
      hideClass = 'hidden';
      settings.dotsClass = 'slick-dots final-slide';
    } else {
      settings.dotsClass = 'slick-dots';
    }
  }

  const close = (action) => {
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  };

  function playVideo() {
    setLocalData('hideCarousel', true);
    dispatch(
      showModal(YOUTUBE_MODAL, {
        url: 'https://www.youtube.com/watch?v=2yXUXYPWswY',
      })
    );
  }

  return (
    <div className="after-signup-carousel">
      <div className="content">
        <img src={Cancel} className="cancel" alt="" onClick={close} />
        <Slider
          {...settings}
          nextArrow={<NextArrow text={nextText} cname={activeClass} currentSlide={currentSlide} close={close} />}
          prevArrow={<PrevArrow text={prevText} cname={hideClass} />}
        >
          <div className="slide">
            <div className="bg"></div>
            <img src={TitleOne} alt="" className="title" />
            <p className="text-one">{languageMap.slideOne.textOne}</p>
            <button>{affiliate.agent_code}</button>
            <p className="text-one" dangerouslySetInnerHTML={{ __html: languageMap.slideOne.textThree }}></p>
            <div className="img-wrapper">
              {!isMobileDevice() ? (
                <div className="text-content">
                  <p className="text" dangerouslySetInnerHTML={{ __html: languageMap.slideOne.textFour }}></p>
                </div>
              ) : null}
            </div>
            {isMobileDevice() ? (
              <div className="text-content">
                <p className="text" dangerouslySetInnerHTML={{ __html: languageMap.slideOne.textFour }}></p>
              </div>
            ) : null}
          </div>
          {!isTelesales() && !isSubAgent() ? (
            <div className="slide two">
              <div className="bg"></div>
              <img src={isMobileDevice() ? TitleTwoMobile : TitleTwo} alt="" className="title" />
              <p className="text-one" dangerouslySetInnerHTML={{ __html: languageMap.slideTwo.textOne }}></p>
              <div className="middle-image">
                <img src={isMobileDevice() ? SlideTwoImageMobile : SlideTwoImage} alt="" />
              </div>
              <div className="logo-image">
                <img src={isMobileDevice() ? LogoMobileImage : LogoImage} alt="" />
              </div>
            </div>
          ) : null}

          <div className="slide three">
            <div className="bg"></div>
            <img src={TitleThree} alt="" className="title" />
            <div className="flex-content">
              <div className="content-wrap">
                <p className="text margin" dangerouslySetInnerHTML={{ __html: languageMap.slideThree.textOne }}></p>
                <p className="text margin">
                  <span>{languageMap.slideThree.textTwo}</span>
                </p>
                <p className="text">{languageMap.slideThree.textThree}</p>
                <p className="text">{languageMap.slideThree.textFour}</p>
                <p className="text">{languageMap.slideThree.textFive}</p>
              </div>
              <div className="img-wrap">
                <img src={SlideThreeImage} alt="" />
              </div>
            </div>
            <div className="social-wrap">
              <div className="qr-code">
                <img src={Qrcode} alt="" />
              </div>
              <div>
                <p className="line-text">
                  {languageMap.slideThree.textSix}{' '}
                  <a href={getLineUrl()} target="_blank" className="link-text">
                    <img src={isMobileDevice() ? LineMobile : Line} alt="" />
                  </a>
                </p>
                <div className="flex-text">
                  <p>{languageMap.slideThree.textSeven}</p>
                  <p dangerouslySetInnerHTML={{ __html: languageMap.slideThree.textEight }}></p>
                </div>
              </div>
            </div>
          </div>
          <div className="slide four">
            <div className="bg"></div>
            <img src={TitleFour} alt="" className="title" />
            <div className="video-player" onClick={playVideo}></div>
            <div className="desc-wrap">
              <p className="title-text">{languageMap.slideFour.textFour}</p>
              <p className="text">{languageMap.slideFour.textFive}</p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default AfterSignupCarousel;
