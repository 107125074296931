import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import CarForm from '../../components/CarForm';
import DriverForm from '../../components/DriverForm';
import PolicyForm from '../../components/PolicyForm';
import SaleTrackForm from '../../components/SaleTrackForm';
import SaleTrackOtherDetails from '../../components/SaleTrackOtherDetails';
import UserForm from '../../components/UserForm';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';

// const STEPS = ['USER', 'CAR', 'DRIVER', 'POLICY', 'SALE TRACK'];
const STEPS = ['USER', 'CAR', 'DRIVER', 'OTHER DETAILS'];
const styles = (theme) => ({
  stepper: {
    padding: '0',
  },
  buttonsWrap: {
    position: 'relative',
    marginTop: '24px',
    paddingBottom: '24px',
  },
  absBtnRight: {
    position: 'absolute',
    right: 0,
  },
  heading: {
    position: 'relative',
    width: '100%',
  },
  tabsWrap: {
    marginBottom: '12px',
  },
});

class SaleTrackModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      carData: null,
      driverList: [],
      expanded: null,
      userData: {},
      policyData: null,
      policyDataCompulsary: null,
      carId: props,
      saleTrack: props.data.sale,
      showCompulsaryInsurance: false,
      quotation: null,
      policyTypes: {},
      tab: 0,
    };

    this.driverFormRefs = {};
  }

  componentDidMount() {
    window.console.log(this.props);
    setLanguageToElement();
    // this.getSaletrack();
    this.getFairdeeQuotation();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleExpansionChange = (panel) => (event, expanded) => {
    window.console.log(panel);
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  getDriver = (id) => {
    this.setState({
      loading: true,
    });
    api.dataEntry
      .getDriver(id)
      .then((response) => {
        window.console.log('driver profile ', response);
        let driverList = [];
        if (response.results && response.results.length) {
          response.results.forEach((data, index) => {
            data.refId = 'id' + Math.random();
            data.mobile = data.mobile || '';
            data.id_number = data.id_number || '';
            data.email = data.email || '';
            data.postal_code = data.postal_code || '';
            data.address = data.address || '';
            data.address_1 = data.address_1 || '';
            data.address_2 = data.address_2 || '';
            data.house_number = data.house_number || '';
            data.dob = data.dob;
            data.refId = index;
            data.fairdee_address = data.fairdee_address || {};
            driverList.push({ data: data, errors: {} });
          });
          window.console.log('Driver list recreated ', this.state);
        } else {
          let obj = {
            data: {
              refId: 0,
              fullname: this.state.userData.fullname,
              mobile: this.state.userData.phone,
            },
            errors: {},
          };
          driverList.push(obj);
          this.setState({
            expanded: 'driverPanel0',
          });
        }
        this.setState({
          driverList: driverList,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  getSaletrack = () => {
    window.console.log(this.props.data.id);
    api.crm
      .getSaletrack(null, { quotation: this.props.data.id })
      .then((response) => {
        window.console.log(response);
        if (response.results.length) {
          this.setState({
            saleTrack: response.results[0],
          });
        }
      })
      .catch((_) => {
        this.setState({
          loading: false,
        });
      });
  };

  getFairdeeQuotation = () => {
    api.utils
      .getFairdeeQuotation(this.props.data.id)
      .then((resp) => {
        window.console.log('Quotation data => ', resp.message);
        // if (resp.message.price_list && resp.message.price_list.insurer && resp.message.price_list.insurer.id) {
        //   resp.message.price_list.insurer = resp.message.price_list.insurer.id;
        // }
        let policyData = {
          gross_premium: parseInt(resp.message.price_list.gross_premium, 10),
          affiliate_commission: resp.message.price_list.affiliate_commission,
          subsidy: resp.message.price_list.affiliate_discount || '0',
          deductible: resp.message.price_list.deductible,
          garage_type: resp.message.price_list.garage_type,
          insurance_class: resp.message.price_list.insurance_class,
          insurer: resp.message.price_list.insurer ? resp.message.price_list.insurer.id : null,
          sum_insured: resp.message.quotation_query.sum_insured,
        };
        let netPremium = policyData.gross_premium * 0.9309;
        let duty = netPremium * 0.004;
        let tax = (netPremium + duty) * 0.07;
        let net_premium = policyData.gross_premium + duty + tax;
        policyData.net_premium = net_premium;

        let policyDataCompulsary = {};
        if (resp.message.needs_compulsory_insurance) {
          policyDataCompulsary.affiliate_commission = resp.message.cmi_price_list.affiliate_commission;
          policyDataCompulsary.gross_premium = parseInt(resp.message.cmi_price_list.gross_premium, 10);
          policyDataCompulsary.insurer = resp.message.price_list.insurer ? resp.message.price_list.insurer.id : null;
          policyDataCompulsary.subsidy = '0';
          let netPremium = policyData.gross_premium * 0.9309;
          let duty = netPremium * 0.004;
          let tax = (netPremium + duty) * 0.07;
          let net_premium = policyData.gross_premium + duty + tax;
          policyDataCompulsary.net_premium = net_premium.toFixed(2);
          // policyDataCompulsary.net_premium = (parseInt(policyDataCompulsary.gross_premium, 10) * 0.9309).toFixed(2);
        }
        window.console.log(policyData, policyDataCompulsary);

        this.setState({
          userData: resp.message.user || {
            fullname:
              (resp.message.client_first_name || '') +
              (resp.message.client_first_name ? ' ' : '') +
              (resp.message.client_last_name || ''),
            email: resp.message.client_email || '',
            phone: resp.message.client_phone || '',
          },
          policyData,
          quotation: resp.message,
          policyDataCompulsary,
        });
      })
      .catch((error) => {
        window.console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  setCarId = (id) => {
    window.console.log(id);
    this.setState({
      carId: id,
    });
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  changeStep = async (step) => {
    window.console.log(step);
    if (step === 2) {
      this.getDriver(this.state.carId);
    }
    if (step === 3) {
      let errors = [];
      await this.asyncForEach(Object.keys(this.driverFormRefs), async (key) => {
        let resp = await this.driverFormRefs[key].save();
        window.console.log(resp, key);
        if (resp) {
          errors.push(key);
        }
      });
      window.console.log(this.driverFormRefs, errors.length);
      if (errors.length > 0) {
        this.setState({
          expanded: 'driverPanel' + errors[0],
        });
        return;
      } else {
        // this.endFlowAction();
        this.getDriver(this.state.carId);
      }
    }
    if (step === 4) {
      this.setState({
        showCompulsaryInsurance: false,
      });
    }
    this.setState({
      activeStep: step,
    });
  };

  showCompulsaryPolicy = () => {
    this.setState({
      showCompulsaryInsurance: true,
    });
    this.handleTabChange(null, 1);
  };

  setPolicies = (type, id) => {
    let policyTypes = this.state.policyTypes;
    policyTypes[type] = id;
    this.setState({
      policyTypes,
    });
  };

  endFlowAction = () => {
    if (this.props.action) {
      this.props.action();
    }
    this.close();
  };

  addDriver = () => {
    let { driverList } = this.state;
    window.console.log(driverList);
    if (driverList.length < 3) {
      let obj = {
        data: {
          refId: driverList.length,
        },
        errors: {},
      };
      driverList.push(obj);
      this.setState(
        {
          driverList,
          expanded: 'driverPanel' + (driverList.length - 1),
        },
        () => {
          window.console.log(this.state.expanded);
        }
      );
    }
  };

  deleteDriver = (index) => {
    window.console.log(index);
    let driverList = this.state.driverList;
    driverList.splice(index, 1);
    delete this.driverFormRefs[index];
    this.setState({
      driverList,
      expanded: 'driverPanel' + (driverList.length - 1),
    });
  };

  handleTabChange = (e, tab) => {
    window.console.log(tab);
    this.setState({ tab });
  };

  renderPolicyForm = () => {
    const { saleTrack, quotation, showCompulsaryInsurance, policyData, tab, policyDataCompulsary } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.saleTrackModal;

    if (quotation.needs_compulsory_insurance) {
      return (
        <div>
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabsWrap}
          >
            <Tab label={languageMap.textOne} />
            <Tab className={!showCompulsaryInsurance ? 'disabled' : ''} label={languageMap.textTwo} />
          </Tabs>
          {tab === 0 ? (
            <PolicyForm
              quotation={quotation}
              saleTrack={saleTrack}
              is_compulsory={false}
              changeStep={this.changeStep}
              setPolicies={this.setPolicies}
              carId={this.state.carId}
              policyData={policyData}
              showCompulsaryPolicy={this.showCompulsaryPolicy}
            />
          ) : (
            <PolicyForm
              quotation={quotation}
              saleTrack={saleTrack}
              is_compulsory={true}
              changeStep={this.changeStep}
              setPolicies={this.setPolicies}
              carId={this.state.carId}
              policyData={policyDataCompulsary}
            />
          )}
          {/* {
            !showCompulsaryInsurance &&
            <PolicyForm quotation={quotation} saleTrack={saleTrack} is_compulsory={false} showCompulsaryPolicy={this.showCompulsaryPolicy} setPolicies={this.setPolicies} carId={this.props.carId} changeStep={this.changeStep} policyData={policyData}/>

          }
          {
            showCompulsaryInsurance &&
            <PolicyForm quotation={quotation} saleTrack={saleTrack} is_compulsory={true} changeStep={this.changeStep} setPolicies={this.setPolicies}  carId={this.props.carId} policyData={policyData}/>
          } */}
        </div>
      );
    } else {
      return (
        <PolicyForm
          quotation={quotation}
          saleTrack={saleTrack}
          is_compulsory={false}
          changeStep={this.changeStep}
          setPolicies={this.setPolicies}
          carId={this.state.carId}
          policyData={policyData}
        />
      );
    }
  };

  renderDrivername = (d, index) => {
    if (index === 0) {
      return <span>Policy Holder</span>;
    }
    return <span>{d.data.fullname || 'Driver ' + index}</span>;
  };

  renderContent = () => {
    const { driverList, loading, expanded, userData, carId, policyTypes, saleTrack, quotation } = this.state;
    const { classes, data } = this.props;

    switch (this.state.activeStep) {
      case 0:
        return (
          <UserForm
            quotation={data.id}
            userData={userData}
            changeStep={this.changeStep}
            action={this.getFairdeeQuotation}
          />
        );
      case 1:
        return (
          <CarForm
            quotation={quotation}
            userId={userData.id}
            changeStep={this.changeStep}
            setCarId={this.setCarId}
            action={this.getFairdeeQuotation}
          />
        );
      case 2:
        return (
          <div>
            {driverList.map((d, index) => {
              return (
                <ExpansionPanel
                  expanded={expanded === 'driverPanel' + index}
                  onChange={this.handleExpansionChange('driverPanel' + index)}
                  key={index}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h2 className={classes.heading}>
                      {/* {d.data.fullname || ('Driver ' + (index))} */}
                      {this.renderDrivername(d, index)}
                    </h2>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <DriverForm
                      onRef={(ref) => (this.driverFormRefs[d.data.refId] = ref)}
                      driver={d}
                      index={index}
                      carId={carId}
                      userId={userData.id}
                      action={this.getDriver}
                      deleteDriver={this.deleteDriver}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
            {/* {
              (!driverList.length && !loading) &&
              <DriverForm onRef={ref => (this.driverFormRefs[0] = ref)} driver={this.driverObj} index={0} carId={carId} userId={userData.id} action={this.getDriver} />
            } */}
            <div className="buttons-wrap">
              <div>
                <Button className="mr-24" onClick={this.changeStep.bind(null, 1)}>
                  Prev
                </Button>
                {driverList.length < 3 && !loading && (
                  <Button variant="contained" color="secondary" className={classes.button} onClick={this.addDriver}>
                    Add Named Driver
                  </Button>
                )}
              </div>
              <div>
                <Button variant="contained" color="primary" onClick={this.changeStep.bind(null, 3)}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      case 3:
        return <SaleTrackOtherDetails quotation={quotation} changeStep={this.changeStep} action={this.endFlowAction} />;
      case 4:
        return (
          <SaleTrackForm
            quotation={quotation}
            policyTypes={policyTypes}
            action={this.endFlowAction}
            saleTrack={saleTrack}
            changeStep={this.changeStep}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <div className="fairdee-modal-wrapper sale-track">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="sales-track-wrap">
            <Stepper activeStep={activeStep} alternativeLabel nonLinear className={classes.stepper}>
              {STEPS.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className="sale-track-stepper-content">{this.renderContent()}</div>
          </div>
        </div>
      </div>
    );
  }
}

SaleTrackModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    insurers: state.insurers,
    filter: state.filter,
  };
}

export default connect(mapStateToProps, { hideModal })(withStyles(styles)(SaleTrackModal));
