import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

interface Props {
  close?: () => void;
  title?: string;
  text?: any;
  component?: any;
  action?: () => void;
  btnText?: string;
}

const InfoModal: React.FC<Props> = ({ close, title, text, component, action, btnText }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);

  function handleAction() {
    action && action();
    close && close();
  }

  function renderContent() {
    if (component) {
      return component;
    }
    return <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>;
  }

  return (
    <div className="fairdee-modal">
      <div className="modal-wrapper">
        <div className="header">
          <p className="title-text border">{title}</p>
        </div>
        <div className="content">{renderContent()}</div>
        <div className="button-wrapper">
          <Button className="orange-button" onClick={handleAction}>
            {btnText || languageMap[`Got it!`]}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
