import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Checkbox, Form } from 'semantic-ui-react';
import { showModal } from '../actions/modal';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import {
  MODAL_TYPE_ACCESSORIES,
  MODAL_TYPE_AFFILIATE_CONDITION,
  MODAL_TYPE_QUICKVIEWINSURER,
  MODAL_TYPE_QUOTATION_CONFIRMATION,
  MODAL_TYPE_SHARE_DETAILS,
  NOTIFICATION,
  SUM_INSURED_MODAL,
} from '../constants/types';
import {
  changeUrlParams,
  commaSeperatedNumbers,
  getAffiliateAttributes,
  getFilterData,
  getParameterByName,
  isLoggedIn,
  isMember,
  setLanguageToElement,
} from '../utils/helper';
import { Mixpanel } from '../utils/tracking';
import { actions, events } from '../utils/trackingEvents';

class InsurerCardMini extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardData: this.props.data,
      reportSaleId: null,
      affiliate: props.quotationAffiliate || props.affiliate,
    };
  }

  componentDidMount() {
    setLanguageToElement();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      cardData: nextProps.data,
    });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  openInsurerQuickView = (data) => {
    changeUrlParams('/get-quotes', 'quickview', 'true');
    // this.props.history.push({
    //   pathname: '/get-quotes',
    //   search: '&quickview=true'
    // });
    window.trackEvent &&
      window.trackEvent.track(actions.Clicked, events.getQuotesSeeDetails + actions.Clicked, {
        quotationQueryId: this.props.data.quotationQueryId,
        quotationtype: this.props.type === 'coa' ? 'COA' : 'Fresh Quote',
        action: 'Selected',
        ...getAffiliateAttributes(),
      });
    Mixpanel.track('Get Quotes', {
      object: 'Quick View Insurer',
      action: 'Clicked',
    });
    data.quotation_query_id = this.props.quotationQuery;
    this.props.showModal(MODAL_TYPE_QUICKVIEWINSURER, {
      data: data,
      handleChange: this.props.handleChange,
      updateSuminsured: this.props.updateSuminsured,
      checkAndOpenLicenseModal: this.props.checkAndOpenLicenseModal,
      quotationAffiliate: this.props.quotationAffiliate,
    });
  };

  openShareDetailsModal = (data) => {
    let descriptionId = getFilterData().vehicleData ? getFilterData().vehicleData.description : '';
    let year = getFilterData().vehicleData ? getFilterData().vehicleData.manufacture_year : '';
    if (isLoggedIn()) {
      let params = {};
      let id = data.insurer.id;
      let ref = getParameterByName('ref');
      params.price_list_id = data.id;
      params.model_description_id = descriptionId;
      params.year = year;
      if (ref) {
        params.ref = ref;
      }
      api.policy
        .policyLead(params, id)
        .then((resp) => {
          if (resp.redirect_url && resp.post_body) {
            this.openFairdeeLoader();
            this.crossDomainPost(resp.redirect_url, JSON.parse(resp.post_body));
          } else {
            this.openThankYouLoader();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.showModal(MODAL_TYPE_SHARE_DETAILS, {
        id: data.id,
        insurer: data.insurer.name,
        insurer_id: data.insurer.id,
      });
    }
  };

  onCheckboxChange = (e, data) => {
    Mixpanel.track('Get Quotes', {
      object: 'Insurer Card Checkbox',
      action: data.checked ? 'Selected' : 'Deselected',
    });
    window.console.log(data, this.state.cardData);
    let allowedComparison = 6;
    if (this.state.cardData.compareCount >= allowedComparison && data.checked) {
      return;
    } else if (this.state.cardData.compareCount >= allowedComparison) {
      let count = this.state.cardData.compareCount;

      this.setState({
        cardData: { ...this.state.cardData, compareCount: count-- },
      });
    }
    this.setState(
      {
        cardData: { ...this.state.cardData, addToCompare: data.checked },
      },
      () => {
        this.props.handleChange(this.state.cardData);
      }
    );
  };

  updateSuminsured = (data, ignoreSuminsuredFilter = false) => {
    window.console.log(this.props.data, data);
    let filterData = getFilterData();
    this.props.updateSuminsured && this.props.updateSuminsured(data, ignoreSuminsuredFilter);
    const isIgOrSubagent =
      this.state.affiliate.account_type === 'inspection_garage' || this.state.affiliate.account_type === 'subagent';
    const isSaleTransferFlow = Boolean(getParameterByName('old_quotation_id'));

    if ((this.state.cardData.insurance_class !== '1' && isSaleTransferFlow) || isMember() || isIgOrSubagent) {
      this.nextPage();
    } else {
      this.props.showModal(MODAL_TYPE_ACCESSORIES, {
        sum_insured: filterData.sum_insured,
        id: this.props.data.quotationQueryId,
        action: this.nextPage,
        data: this.props.data,
        quotationQuery: this.props.data.quotationQuery,
        quotationType: getParameterByName('type'),
        source: 'Get Quotes',
        hideDiscount: Boolean(getParameterByName('old_quotation_id')),
      });
    }
  };

  openQuotationConfirmationModal = (data) => {
    Mixpanel.track('Get Quotes', {
      object: 'Quotation Confirmation Modal',
      action: 'Viewed',
    });
    this.props.showModal(MODAL_TYPE_QUOTATION_CONFIRMATION, {
      action: this.gotoWizard,
      data,
    });
  };

  reportSale = () => {
    let data = this.state.cardData;
    window.trackEvent &&
      window.trackEvent.track(actions.Clicked, events.getQuotesReportSale + actions.Clicked, {
        quotationQueryId: this.props.data.quotationQueryId,
        quotationType: this.props.type === 'coa' ? 'COA' : 'Fresh Quote',
        isManual: data.is_manual,
        ...getAffiliateAttributes(),
      });
    this.setState(
      {
        reportSaleId: data.id,
      },
      () => {
        if (data.restriction_text || (data.insurer_restrictions && data.insurer_restrictions.length)) {
          this.openQuotationConfirmationModal([data]);
        } else {
          this.gotoWizard(true);
        }
      }
    );
    Mixpanel.track('Get Quotes', {
      object: 'Report Sale Button',
      action: 'Clicked',
    });
  };

  continue = () => {
    let hasBrokerLicense = false;
    let hasNationalId = false;
    let isAdmin = this.props.user.roles && this.props.user.roles.includes('admin') ? true : false;
    let shouldOpenLicenseModal = this.props.checkAndOpenLicenseModal(this.continue);
    if (shouldOpenLicenseModal) {
      return;
    }
    if (this.state.affiliate && this.state.affiliate.documents && this.state.affiliate.documents.length) {
      this.state.affiliate.documents.forEach((data) => {
        if (data.document_type === 'broker_license') {
          hasBrokerLicense = true;
        }
        if (data.document_type === 'national_id') {
          hasNationalId = true;
        }
      });
    }
    if (isMember()) {
      this.props.showModal(MODAL_TYPE_AFFILIATE_CONDITION, {
        action: this.reportSale,
        type: 'no-license',
      });
      return;
    }

    // if (!isAdmin && this.state.affiliate && this.state.affiliate.additional_info && this.state.affiliate.additional_info.has_broker_license && (!hasBrokerLicense || !hasNationalId)) {
    //   this.props.showModal(MODAL_TYPE_AFFILIATE_CONDITION, {
    //     type: 'no-doc'
    //   });
    //   return;
    // }

    // if (!isAdmin && this.state.affiliate.additional_info && !this.state.affiliate.additional_info.has_broker_license) {
    //   this.props.showModal(MODAL_TYPE_AFFILIATE_CONDITION, {
    //     action: this.reportSale,
    //     type: 'no-license'
    //   });
    //   return;
    // }
    this.reportSale();
  };

  nextPage = () => {
    let t = getParameterByName('t'),
      quotationQueryId = this.props.data.quotationQueryId,
      priceListId = this.state.reportSaleId || this.props.data.id,
      search,
      pathname;
    if (t) {
      pathname = '/fairdee-form';
      search = '?t=' + t + '&qq_id=' + quotationQueryId + '&price_list_id=' + priceListId;
    } else {
      pathname = '/fairdee-wizard';
      if (this.props.type && this.props.type === 'bike') {
        search += '&forBike=true';
      } else if (this.props.type && this.props.type === 'taxi') {
        pathname = '/fairdee-wizard-taxi';
      }

      if (getParameterByName('old_quotation_id')) {
        search += '&old_quotation_id=' + getParameterByName('old_quotation_id') + '&price_list_id=' + priceListId;
      } else {
        search = '?quotation_query_id=' + quotationQueryId + '&price_list_id=' + priceListId;
      }
      if (getParameterByName('affiliate')) {
        search += '&affiliate=' + getParameterByName('affiliate');
      }

      // if (this.props.data.quotationQuery && this.props.data.quotationQuery.will_pay_in_instalments) {
      //   search += '&instalment=true';
      // }
    }
    this.setState(
      {
        reportSaleId: null,
      },
      () => {
        this.props.history.push({ pathname, search });
        if (this.props.closeParent) {
          this.props.closeParent();
        }
      }
    );
  };

  gotoWizard = (ignoreSuminsuredFilter = false) => {
    window.console.log(this.props.data);
    let data = this.state.cardData;
    let filterData = getFilterData();
    let sum_insured = data.min_suminsured === data.max_suminsured ? data.min_suminsured : null;
    const isIgOrSubagent =
      this.state.affiliate.account_type === 'inspection_garage' || this.state.affiliate.account_type === 'subagent';
    const isSaleTransferFlow = Boolean(getParameterByName('old_quotation_id'));

    if (!this.props.data.quotationQueryId && !getParameterByName('old_quotation_id')) {
      return;
    }

    if (
      this.props.type !== 'bike' &&
      this.props.type !== 'taxi' &&
      filterData.insurance_class &&
      //Adding loop check to support future cases
      filterData.insurance_class.some((i) => ['1', '1 ราคาถูกพิเศษ'].includes(i))
    ) {
      if (data.is_cheapest_price) {
        this.updateSuminsured(
          { sum_insured: Math.min(parseInt(data.max_suminsured), parseInt(filterData.defaultSumInsured.maximum)) },
          ignoreSuminsuredFilter
        );
        return;
      } else if (!filterData.sum_insured) {
        this.props.showModal(SUM_INSURED_MODAL, {
          data: { ...this.props.data, sum_insured },
          action: (returnData) => this.updateSuminsured(returnData, ignoreSuminsuredFilter),
        });
        return;
      }
    }

    if (this.props.type === 'bike') {
      if (data.sum_insured || sum_insured) {
        this.updateSuminsured({ sum_insured: parseInt(sum_insured, 10) }, ignoreSuminsuredFilter);
        return;
      }
      // this.props.updateSuminsured({ sum_insured: data.sum_insured || data.min_suminsured });
    } else {
      if (!filterData.sum_insured && sum_insured) {
        this.updateSuminsured({ sum_insured: parseInt(sum_insured, 10) }, ignoreSuminsuredFilter);
        return;
      }
    }

    if ((this.state.cardData.insurance_class !== '1' && isSaleTransferFlow) || isMember() || isIgOrSubagent) {
      Mixpanel.track('Get Quotes', {
        object: 'Fairdee Wizard',
        action: 'Redirected',
      });
      this.nextPage();
    } else {
      Mixpanel.track('Get Quotes', {
        object: 'Accessory Modal',
        action: 'Viewed',
      });
      this.props.showModal(MODAL_TYPE_ACCESSORIES, {
        sum_insured: filterData.sum_insured,
        id: this.props.data.quotationQueryId,
        action: this.nextPage,
        data: this.props.data,
        quotationQuery: this.props.data.quotationQuery,
        quotationType: getParameterByName('type'),
        source: 'Get Quotes',
        hideDiscount: Boolean(getParameterByName('old_quotation_id')),
      });
    }
    // this.props.showModal(MODAL_TYPE_ACCESSORIES, { sum_insured: filterData.sum_insured, id: this.props.data.quotationQueryId, action: this.nextPage, data: this.props.data });
    // if(data.insurance_class === '1') {
    //   this.props.showModal(MODAL_TYPE_ACCESSORIES, { sum_insured: filterData.sum_insured, id: this.props.data.quotationQueryId, action: this.nextPage, data: this.props.data });
    // } else {
    //   this.nextPage();
    // }
  };

  shouldApplyManagementFee = (cardData) => {
    return !cardData.apply_management_fee || !cardData.apply_referral || !cardData.apply_level_commission;
  };

  renderDiscount = () => {
    const languageMap = this.props.languageMap.components.insurerCard;
    if (this.state.cardData.camera_discount_eligible && this.state.cardData.driver_discount_eligible) {
      return <span className="tags">{languageMap.discountTextThree}</span>;
    }
    if (this.state.cardData.camera_discount_eligible) {
      return <span className="tags">{languageMap.discountTextOne}</span>;
    }
    if (this.state.cardData.driver_discount_eligible) {
      return <span className="tags">{languageMap.discountTextTwo}</span>;
    }
  };

  renderSuminsured = (cardData) => {
    let si = cardData.sum_insured;
    const filterData = getFilterData();
    if (cardData.is_cheapest_price) {
      let yearSumInsured = filterData.defaultSumInsured;
      if (!yearSumInsured) {
        yearSumInsured = { maximum: 1000000 };
      }
      si = Math.min(parseInt(cardData.max_suminsured), parseInt(yearSumInsured.maximum));
    }
    return si;
  };

  renderTags = () => {
    const cardData = this.state.cardData;
    const languageMap = this.props.languageMap.components.insurerCard;
    let tags = [];
    // if (cardData.camera_discount_eligible) {
    //   tags.push(<span key="2" className="tags">{languageMap.discountTextOne}</span>)
    // }
    // if (cardData.driver_discount_eligible) {
    //   tags.push(<span key="3" className="tags">{languageMap.discountTextTwo}</span>)
    // }
    if (cardData.meta_data && cardData.meta_data.selling_points) {
      let selling_points = cardData.meta_data.selling_points.split('\n').filter((data) => data != '');
      selling_points.forEach((spoint, index) => {
        tags.push(
          <span key={index + 3} className="tags">
            {spoint.replace('-', '')}
          </span>
        );
      });
    }
    return tags;
  };

  copyText = (text) => {
    copy(text);
    this.notify(this.props.languageMap.messages.copied, 'info');
  };

  render() {
    const languageMap = this.props.languageMap.components.insurerCard;
    const { affiliate } = this.props;
    const { showExtraInfo, cardData } = this.state;
    const isRenewalCard = cardData.for_renewal && +getParameterByName('renewal_pl_id') === cardData.id;
    return (
      <div
        className={
          'insurer-card-new animated fadeIn ' + this.props.className + (isRenewalCard ? ' renewal-price-list' : '')
        }
      >
        <div className="header" onClick={this.openInsurerQuickView.bind(null, this.state.cardData)}>
          <div>
            <div className="img-wrap">
              <img src={this.state.cardData.insurer.logo} alt="" />
            </div>
            <div className="details-wrap">
              <div>
                <p className="text-one">
                  {this.state.cardData.insurer.name} Type {this.state.cardData.insurance_class} {languageMap.insurance}
                </p>
                <p className="text-one">{cardData.plan_name}</p>
                <p className="text-two">
                  {commaSeperatedNumbers(this.state.cardData.premium_after_tax)} {languageMap.bahtPerYear.textTwo}
                </p>
              </div>
              <div>
                {(cardData.insurer_restrictions && cardData.insurer_restrictions.length) ||
                cardData.restriction_text ? (
                  <p className="see-details mr-12" onClick={() => this.openQuotationConfirmationModal([cardData])}>
                    {languageMap.seeRestrictions}
                  </p>
                ) : null}
                <span className="see-details">{languageMap.seeDetails}</span>
                {this.state.cardData.restriction_text ? (
                  <p className="text-one">{this.state.cardData.restriction_text}</p>
                ) : null}
                {isRenewalCard && <div className="restriction-text green">{languageMap.renewalRecommended}</div>}
                {cardData.showCheapestTag ? <div className="restriction-text green">Cheapest Price</div> : null}
                {affiliate.instalment_allowed &&
                !cardData.disable_instalment &&
                cardData.insurer.is_instalment_supported ? (
                  <div className="restriction-text yellow">{languageMap.instalmentAvailable}</div>
                ) : null}
                {cardData.can_issue_policy_online ? (
                  <div className="restriction-text green">{languageMap.instantPolicy}</div>
                ) : null}
                {this.shouldApplyManagementFee(cardData) ? (
                  <div className="restriction-text green">
                    {!cardData.apply_management_fee ? languageMap.managementFee : ''}
                    {!cardData.apply_referral ? languageMap.referralFee : ''}
                    {!cardData.apply_level_commission ? languageMap.levelCommission : ''}
                    {languageMap.notApplicable}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="column-wrap">
          <div className="col">
            <p className="key">{languageMap.details.textThree}</p>
            <p className="value">{languageMap.garageTypeMap[this.state.cardData.garage_type]}</p>
          </div>
          {this.renderSuminsured(this.state.cardData) ? (
            <div className="col">
              <p className="key">{languageMap.details.textOne}</p>
              <p className="value">
                {this.renderSuminsured(this.state.cardData)}
                {languageMap.baht}
              </p>
            </div>
          ) : (
            ''
          )}
          <div className="col">
            <p className="key">
              {languageMap.details.textFour}:
              <p className="value">
                {cardData.deductible
                  ? `${commaSeperatedNumbers(cardData.deductible)} ${languageMap.baht}`
                  : `No Deductible`}
              </p>
            </p>
          </div>
        </div>
        {cardData.car_type || cardData.car_age || cardData.add_ons ? (
          <div className="column-wrap single-column-wrap">
            <div className="col">
              <p className="value">{`${cardData.car_type || ''} ${cardData.car_age || ''} ${
                cardData.add_ons || ''
              }`}</p>
            </div>
          </div>
        ) : null}
        <div className="discount-wrap">{this.renderTags()}</div>
        {!this.props.hideAction ? (
          <div className="buttons-wrap">
            {!isMember() && this.props.showCheckbox ? (
              <div>
                <Form noValidate>
                  <Form.Field>
                    <Checkbox
                      label={languageMap.checkboxText}
                      name="addToCompare"
                      value={this.state.cardData.insurer.name}
                      checked={this.state.cardData.addToCompare ? true : false}
                      onChange={this.onCheckboxChange}
                    />
                  </Form.Field>
                </Form>
              </div>
            ) : null}
            <div onClick={this.continue.bind(null, this.state.cardData)}>
              <button className="button orange-bordered-button medium">
                {!isMember() ? languageMap.buttonText : languageMap.buttonTextOne}
              </button>
            </div>
          </div>
        ) : null}
        {cardData.meta_data && cardData.meta_data.sales_technique ? (
          <div className="extra-info-wrap">
            <p
              onClick={(e) => {
                this.setState({ showExtraInfo: !this.state.showExtraInfo });
              }}
            >
              {languageMap.textOne} <span>{languageMap.textTwo}</span>
            </p>
            <div className={'content ' + (showExtraInfo ? 'show' : '')}>
              <div>
                {cardData.meta_data.sales_technique ? (
                  <p>
                    {cardData.meta_data.sales_technique}{' '}
                    <span onClick={this.copyText.bind(null, cardData.meta_data.sales_technique)}>
                      {languageMap.textThree}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

InsurerCardMini.propTypes = {
  languageMap: PropTypes.any.isRequired,
  updateSuminsured: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, showNotification })(InsurerCardMini));
