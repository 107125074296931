import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal';
import crossLg from '../../images/ic-close.svg';
import CrmModalFormLayout from './CrmModalFormLayout';

function DrawerView(props) {
  function close() {
    props.hideModal(null, {
      ...props,
    });
  }

  return (
    <div className="crm-action-sheet show" id="drawer-view">
      <img src={crossLg} className="close" alt="" onClick={close} />
      <CrmModalFormLayout
        data={props.data || {}}
        close={close}
        action={props.action}
        getAffiliate={props.getAffiliate}
      />
    </div>
  );
}

export default connect(null, { hideModal })(DrawerView);
