import $ from 'jquery';
import { CLEAR_MODAL, HIDE_MODAL, SHOW_MODAL } from '../constants/types';

const initialState = [];

function modal(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL:
      $('html').addClass('fixed');
      return [
        ...state,
        {
          type: action.payload.type,
          uid: action.uid,
          props: action.payload.props,
        },
      ];
    case HIDE_MODAL:
      $('html').removeClass('fixed');
      return state.filter((modal) => {
        return modal.uid !== action.uid;
      });
    case CLEAR_MODAL:
      $('html').removeClass('fixed');
      return [];
    default:
      return state;
  }
}

export default modal;
