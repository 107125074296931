import { AFFILIATE } from '../constants/types';

function affiliate(state = {}, action = {}) {
  switch (action.type) {
    case AFFILIATE:
      return action.affiliate;
    default:
      return state;
  }
}

export { affiliate };
