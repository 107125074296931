import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
};

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      showList: false,
      items: props.items,
    };
  }

  componentWillMount() {
    this.timer = null;
    this.componentMounted = true;
    document.addEventListener('mousedown', this.handleClick, false);

    document.getElementsByTagName('body')[0].addEventListener('click', (e) => {
      if (e.target.id !== 'text' && e.target.tagName !== 'P') {
        if (this.componentMounted) {
          this.setState({
            showList: false,
          });
        }
      }
    });
    this.setState({
      text: this.props.value,
    });
    if (this.props.selected && this.props.selected.id) {
      this.select(this.props.selected);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    this.componentMounted = false;
    clearTimeout(this.timer);
    this.timer = null;
  }

  handleClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        showList: false,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.componentMounted) {
      if (nextProps.value !== this.props.value) {
        this.setState({
          text: nextProps.value,
        });
      }
      if (nextProps.items !== this.props.items) {
        this.setState({
          items: nextProps.items,
        });
      }
      if (nextProps.selected !== this.props.selected) {
        this.select(nextProps.selected);
      }
    }
  }

  onChange = (event) => {
    if (this.props.inlineSearch) {
      let value = event.target.value.toString().toUpperCase();
      let searchList = this.props.items.filter((data) => {
        data.label = data.label.toString().toUpperCase();
        if (data.label.includes(value) && value.length === data.label.length) {
          this.select(data);
        }
        if (data.label.includes(value)) {
          return data;
        }
      });

      this.setState({
        text: event.target.value,
        items: searchList,
      });
      return;
    }
    clearTimeout(this.timer);
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      text: event.target.value,
    });
    let that = this;
    this.timer = setTimeout(() => {
      that.props.onChange({ name, value });
    }, 500);
  };

  select = (data) => {
    if (!data) {
      this.setState({
        showList: false,
      });
      this.props.onSelect({ name: this.props.name, value: null, id: null });
      return;
    }
    this.setState({
      text: data.label,
      showList: false,
    });
    this.props.onSelect({ name: this.props.name, value: data.label, ...data });
  };

  onFocus = () => {
    this.setState({
      showList: true,
    });
    if (this.state.text) {
      this.props.onChange({ name: this.props.name, value: this.state.text });
    }
  };

  render() {
    const { showList, items, text } = this.state;
    const { label, error, name, placeholder, className } = this.props;

    return (
      <div className="custom-auto-complete" ref={(node) => (this.node = node)}>
        <TextField
          id="text"
          margin="normal"
          name={name}
          className={className}
          autoComplete="off"
          label={label}
          value={text || ''}
          error={error ? true : false}
          helperText={error}
          onChange={this.onChange}
          onFocus={this.onFocus}
          disabled={this.props.disabled}
        />
        {items && items.length && showList ? (
          <div className="list-wrap">
            <p name="select-list" onClick={this.select.bind(null, undefined)}>
              {placeholder || '---Select---'}
            </p>
            {items.map((data, ind) => {
              return (
                <p name="select-list" onClick={this.select.bind(null, data)} key={ind}>
                  {data.label} {data.email ? ' - ' + data.email : ''}
                </p>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, {})(AutoComplete));
