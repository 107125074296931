import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler, isSuperAdmin } from '../../utils/helper';

class AddCredit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        product: 'combined_credit',
        is_prepaid: false,
      },
      errors: {},
      loading: false,
      updatedData: {},
    };
  }

  validate = (data) => {
    const errors = {};
    const messages = this.props.languageMap.messages;

    if (!data.limit) {
      errors.limit = messages.required;
    }

    if (!data.available) {
      errors.available = messages.required;
    }

    if (!data.product) {
      errors.product = messages.required;
    }

    if (data.is_prepaid === undefined) {
      errors.is_prepaid = messages.required;
    }

    if (data.limit < data.available) {
      errors.available = 'Available credits should be less than limit';
    }

    window.console.log(data);

    return errors;
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    if (name === 'is_prepaid') {
      value = value === 'true';
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  save = () => {
    let errors = this.validate(this.state.data);

    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    let params = { ...this.state.data };
    params.affiliate = this.props.data.id;

    api.crm
      .credit(params)
      .then(() => {
        if (this.props.action) {
          this.props.action();
        }
        this.close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.setState(
              {
                errors: { ...this.state.errors, [key]: error.response.data.errors[key][0] },
              },
              () => {}
            );
          });
        }
      });
  };

  render() {
    const { data, errors, loading } = this.state;

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Add Credit</span>
            </p>
          </div>
          <h1 className="title-text">Add Credit</h1>
          <div className="aff-credit">
            <TextField
              required
              type="text"
              label="Credit Limit"
              value={data.limit || ''}
              error={Boolean(errors.limit)}
              helperText={errors.limit}
              autoComplete="off"
              className="full-width"
              onChange={this.handleChange('limit')}
            ></TextField>
            <TextField
              required
              type="text"
              label="Credit Available"
              value={data.available || ''}
              error={Boolean(errors.available)}
              helperText={errors.available}
              autoComplete="off"
              className="full-width"
              onChange={this.handleChange('available')}
            ></TextField>
            {data.product === 'combined_credit' && isSuperAdmin() ? (
              <FormControl component="fieldset" error={Boolean(errors.is_prepaid)} required>
                <FormLabel component="legend">Is Prepaid</FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={data.is_prepaid}
                  onChange={this.handleChange('is_prepaid')}
                  row
                >
                  <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" labelPlacement="end" />
                  <FormControlLabel value={false} control={<Radio color="primary" />} label="No" labelPlacement="end" />
                </RadioGroup>
              </FormControl>
            ) : null}

            <div className="button-wrap">
              <Button onClick={this.save} color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddCredit.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(AddCredit));
