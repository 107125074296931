import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LaunchIcon from '@material-ui/icons/Launch';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import api from '../commonjs/api';
import apiEndpoints from '../commonjs/apiEndpoints';
import { formatDateTime } from '../utils/helper';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none',
  },
  expansionPanel: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    minWidth: 700,
  },
  pagination: {
    textAlign: 'right',
  },
  linkButton: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
});

const CenteredTableCell = withStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
}))(TableCell);

class CompulsoryInsuranceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compulsaryInsuranceReport: [],
      totalPages: undefined,
      currentPage: undefined,
      nextPageUrl: undefined,
      previousPageUrl: undefined,
      loaded: false,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.hideAccordian) {
      this.compulsaryInsurance();
    }
  }

  compulsaryInsurance = (url = undefined) => {
    this.setState({
      loading: true,
    });
    api.utils
      .compulsaryInsurance(url, { affiliate: this.props.affiliate })
      .then((response) => {
        window.console.log(response);
        let data = response,
          compulsaryInsuranceReport = data.results.map((row) => {
            let preview_pdf_url,
              policy_pdf_url,
              total_premium = '-';

            if (row.preview_pdf_s3_key) {
              preview_pdf_url = `${apiEndpoints.compulsoryInsurance}/${row.id}?show_preview=true`;
            }

            if (row.policy_pdf_s3_key) {
              policy_pdf_url = `${apiEndpoints.compulsoryInsurance}/${row.id}?show_policy=true`;
            }

            if (row.premium) {
              total_premium = parseFloat(row.premium) + parseFloat(row.tax) + parseFloat(row.duty);
            }

            return {
              ...row,
              name: `${row.salutation} ${row.first_name} ${row.last_name}`,
              vehicle: `${row.model_name}, ${row.make_name}`,
              preview_pdf_url: preview_pdf_url,
              policy_pdf_url: policy_pdf_url,
              total_premium: total_premium,
            };
          });
        this.setState({
          compulsaryInsuranceReport: compulsaryInsuranceReport,
          totalPages: data.total_pages,
          currentPage: data.current_page,
          nextPageUrl: data.next,
          previousPageUrl: data.previous,
          loaded: true,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  openPdfUrl = (compulsory_insurance_id, policy_pdf = false) => {
    let params = {};
    if (policy_pdf) {
      params['with_policy_url'] = true;
    } else {
      params['with_preview_url'] = true;
    }
    api.utils.getCompulsoryInsurance(compulsory_insurance_id, params).then((response) => {
      let uri;
      if (policy_pdf) {
        uri = params['policy_pdf_url'];
      } else {
        uri = params['preview_pdf_url'];
      }
      window.open(uri, '_blank');
    });
  };

  handlePanelChange = (event, expanded) => {
    if (expanded && !this.state.loaded) {
      this.compulsaryInsurance();
    }
  };

  render() {
    const { classes } = this.props;
    const { nextPageUrl, previousPageUrl, currentPage, totalPages, compulsaryInsuranceReport, loading } = this.state;
    const languageMap = this.props.languageMap.components.affiliateCompulsoryInsuranceTable;

    const headers = [
      'Created At',
      'Customer Name',
      'Mobile',
      'Vehicle Number',
      'Vehicle',
      'Premium',
      'Preview PDF',
      'Policy PDF',
    ];

    if (this.props.hideAccordian) {
      return (
        <div className="table-wrapper">
          {loading ? (
            <div className="loader-wrap">
              <CircularProgress />
            </div>
          ) : (
            <Paper className={classes.root}>
              {!compulsaryInsuranceReport.length ? (
                <p>No data</p>
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {languageMap.headers.map((header, index) => (
                        <CenteredTableCell key={index}>{header}</CenteredTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compulsaryInsuranceReport.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <CenteredTableCell>{formatDateTime(row.created_at)}</CenteredTableCell>
                          <CenteredTableCell component="th" scope="row">
                            {row.name}
                          </CenteredTableCell>
                          <CenteredTableCell>{row.mobile}</CenteredTableCell>
                          <CenteredTableCell>{row.vehicle_number}</CenteredTableCell>
                          <CenteredTableCell>{row.vehicle}</CenteredTableCell>
                          <CenteredTableCell>{row.total_premium}</CenteredTableCell>
                          <CenteredTableCell>
                            {!row.preview_pdf_url ? (
                              '-'
                            ) : (
                              <Button
                                className={classes.linkButton}
                                href={row.preview_pdf_url}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                              >
                                เปิด <LaunchIcon />
                              </Button>
                            )}
                          </CenteredTableCell>
                          <CenteredTableCell>
                            {!row.policy_pdf_url ? (
                              '-'
                            ) : (
                              <Button
                                href={row.policy_pdf_url}
                                target="_blank"
                                className={classes.linkButton}
                                variant="outlined"
                                color="primary"
                              >
                                เปิด <LaunchIcon />
                              </Button>
                            )}
                          </CenteredTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={headers.length} className={classes.pagination}>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>
                        <IconButton
                          onClick={this.compulsaryInsurance.bind(null, previousPageUrl)}
                          disabled={!Boolean(previousPageUrl)}
                          aria-label="Previous Page"
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                          onClick={this.compulsaryInsurance.bind(null, nextPageUrl)}
                          disabled={!Boolean(nextPageUrl)}
                          aria-label="Next Page"
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </Paper>
          )}
        </div>
      );
    }

    return (
      <ExpansionPanel className={'cmi-table ' + classes.expansionPanel} onChange={this.handlePanelChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <p>{languageMap.textOne}</p>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {loading ? (
            <div className="loader-wrap">
              <CircularProgress />
            </div>
          ) : (
            <Paper className={classes.root}>
              {!compulsaryInsuranceReport.length ? (
                <p>No data</p>
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {languageMap.headers.map((header, index) => (
                        <CenteredTableCell key={index}>{header}</CenteredTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compulsaryInsuranceReport.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <CenteredTableCell>{formatDateTime(row.created_at)}</CenteredTableCell>
                          <CenteredTableCell component="th" scope="row">
                            {row.name}
                          </CenteredTableCell>
                          <CenteredTableCell>{row.mobile}</CenteredTableCell>
                          <CenteredTableCell>{row.vehicle_number}</CenteredTableCell>
                          <CenteredTableCell>{row.vehicle}</CenteredTableCell>
                          <CenteredTableCell>{row.total_premium}</CenteredTableCell>
                          <CenteredTableCell>
                            {!row.preview_pdf_url ? (
                              '-'
                            ) : (
                              <Button
                                className={classes.linkButton}
                                href={row.preview_pdf_url}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                              >
                                เปิด <LaunchIcon />
                              </Button>
                            )}
                          </CenteredTableCell>
                          <CenteredTableCell>
                            {!row.policy_pdf_url ? (
                              '-'
                            ) : (
                              <Button
                                href={row.policy_pdf_url}
                                target="_blank"
                                className={classes.linkButton}
                                variant="outlined"
                                color="primary"
                              >
                                เปิด <LaunchIcon />
                              </Button>
                            )}
                          </CenteredTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={headers.length} className={classes.pagination}>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>
                        <IconButton
                          onClick={this.compulsaryInsurance.bind(null, previousPageUrl)}
                          disabled={!Boolean(previousPageUrl)}
                          aria-label="Previous Page"
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                          onClick={this.compulsaryInsurance.bind(null, nextPageUrl)}
                          disabled={!Boolean(nextPageUrl)}
                          aria-label="Next Page"
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </Paper>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

CompulsoryInsuranceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  hideAccordian: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, {})(withStyles(styles)(CompulsoryInsuranceTable));
