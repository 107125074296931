import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAffiliate } from '../../actions/affiliate';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import photoWithNationalId from '../../images/photo-with-id.png';
import { store } from '../../index';

class UploadDocumentsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      errors: {},
      fileList: {},
      fileListLoading: {},
      affiliate: {},
      instalment_allowed: false,
      docTypes: {},
    };
    this.affiliate = null;
    window.console.log(props);
  }

  componentDidMount() {
    if (this.props.doc_verification) {
      this.getAffiliateApiCall(this.props.id);
    } else {
      this.getAffiliateProfile();
    }
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  getAffiliateApiCall = (id) => {
    api.crm
      .affiliate(id)
      .then((data) => {
        let fileList = {};
        let affiliate = data.message;
        let docTypes = this.state.docTypes;
        window.console.log(affiliate);

        if (affiliate && affiliate.documents && affiliate.documents.length) {
          affiliate.documents.forEach((data) => {
            docTypes[data.document_type] = true;
            if (!fileList[data.document_type]) {
              fileList[data.document_type] = [];
            }
            data.file.name = data.file.original_file_name;
            fileList[data.document_type].push(data);
          });
          window.console.log(fileList, docTypes);
        }
        this.setState({
          affiliate,
          loading: false,
          fileList,
          loading: false,
          docTypes,
        });
        this.affiliate = data.message;
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  validateDocs = () => {
    const messages = this.props.languageMap.messages,
      docTypes = this.state.docTypes;
    let errors = {};
    if (!docTypes['broker_license']) {
      errors.broker_license = messages.required;
    }
    return errors;
  };

  continue = () => {
    let errors = this.validateDocs();
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    this.props.action(this.affiliate);
    this.close();
  };

  getAffiliateProfileApiCall = () => {
    let id = this.props.id;
    if (id) {
      this.getAffiliateApiCall(id);
      return;
    }
    return api.crm
      .getAffiliateProfile({ with_commission_rates: true })
      .then((data) => {
        window.console.log(data);
        store.dispatch(setAffiliate(data.message));
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  getAffiliateProfile = () => {
    let fileList = {};
    let affiliate = JSON.parse(JSON.stringify(this.props.affiliate));
    let docTypes = this.state.docTypes;
    window.console.log(affiliate);

    if (affiliate && affiliate.documents && affiliate.documents.length) {
      affiliate.documents.forEach((data) => {
        docTypes[data.document_type] = true;
        if (!fileList[data.document_type]) {
          fileList[data.document_type] = [];
        }
        data.file.name = data.file.original_file_name;
        fileList[data.document_type].push(data);
      });
      window.console.log(fileList, docTypes);
    }
    this.setState({
      affiliate,
      loading: false,
      fileList,
      loading: false,
      docTypes,
    });
    this.id = affiliate.id;
  };

  deleteAffiliateDocument = (data) => {
    window.console.log(data);
    api.user
      .deleteAffiliateDocument(data.id)
      .then((resp) => {
        window.console.log(resp);
        let fileList = this.state.fileList;
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        this.setState({
          fileList,
          updatedData: true,
        });
        this.getAffiliateProfileApiCall();
      })
      .catch((error) => {
        this.notify('Something went wrong', 'error');
      });
  };

  uploadFile = (params, type, file, affiliate = undefined) => {
    let fileListLoading = this.state.fileListLoading;
    fileListLoading[type] = true;
    this.setState({ fileListLoading });
    api.user
      .affiliateDocument(params, affiliate)
      .then((resp) => {
        fileListLoading[type] = false;
        window.console.log(this.state, params, type, fileListLoading);
        let fileList = this.state.fileList,
          docTypes = this.state.docTypes;
        file.id = resp.message.id;
        file.document_type = type;

        if (!fileList[type]) {
          fileList[type] = [file];
        } else {
          fileList[type].push(file);
        }
        docTypes[type] = true;
        this.setState({ fileListLoading, fileList, docTypes });
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
        this.getAffiliateProfileApiCall();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.file &&
          error.response.data.response.file.file
        ) {
          this.notify(
            this.props.languageMap.messages[error.response.data.response.file.file] ||
              error.response.data.response.file.file,
            'error'
          );
        } else {
          this.notify('Something went wrong', 'error');
        }
        fileListLoading[type] = false;
        this.setState({ fileListLoading });
      });
  };

  fileUploaded = (file, name) => {
    window.console.log(file, name);
    let params = new FormData(),
      affId = undefined;
    params.append('file', file);
    params.append('document_type', name);
    if (this.props.type === 'instalment') {
      affId = this.props.id || this.props.affiliate.id;
    }
    this.uploadFile(params, name, file, affId);
  };

  deleteDocument = (data) => {
    window.console.log(data);
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteAffiliateDocument,
      data,
    });
  };

  renderForm = () => {
    const {
      fileListLoading,
      errors,
      fileList,
      affiliate,
      loading,
      instalment_allowed,
      docTypes,
      docs_verification_status,
    } = this.state;
    const languageMap = this.props.languageMap.components.uploadDocuments;
    window.console.log(fileList);
    if (this.props.type === 'instalment') {
      return (
        <div>
          <div className="upload-documents-form">
            {Object.keys(fileList).includes('photo_with_national_id') ? (
              <div className="key-value">
                <span className="key">{languageMap.textFive}</span>
                <div className="img-wrapper">
                  <img src={photoWithNationalId} alt="" />
                </div>
                <div className="value">
                  <FileUpload
                    files={fileList['photo_with_national_id']}
                    loading={fileListLoading['photo_with_national_id']}
                    name="photo_with_national_id"
                    onFileUpload={this.fileUploaded}
                    onFileRemove={this.deleteDocument}
                    limit={2}
                    btnText={languageMap.textFour}
                    download={true}
                    downloadPath={apiEndpoints.affiliateDocument}
                  />
                  {errors.photo_with_national_id && <InlineError text={errors.photo_with_national_id} />}
                </div>
              </div>
            ) : null}
            {Object.keys(fileList).includes('national_id') ? (
              <div className="key-value">
                <span className="key">{languageMap.textTwo}</span>
                <div className="value">
                  <FileUpload
                    files={fileList['national_id']}
                    loading={fileListLoading['national_id']}
                    name="national_id"
                    onFileUpload={this.fileUploaded}
                    onFileRemove={this.deleteDocument}
                    limit={2}
                    btnText={languageMap.textFour}
                    download={true}
                    downloadPath={apiEndpoints.affiliateDocument}
                  />
                  {errors.national_id && <InlineError text={errors.national_id} />}
                </div>
              </div>
            ) : null}
            <div className="key-value">
              <span className="key">{languageMap.textThree}</span>
              <div className="value">
                <FileUpload
                  files={fileList['broker_license']}
                  loading={fileListLoading['broker_license']}
                  name="broker_license"
                  onFileUpload={this.fileUploaded}
                  onFileRemove={this.deleteDocument}
                  limit={2}
                  btnText={languageMap.textFour}
                  download={true}
                  downloadPath={apiEndpoints.affiliateDocument}
                />
                {errors.broker_license && <InlineError text={errors.broker_license} />}
              </div>
            </div>
            <div className="button-wrap mt-24">
              <button className="button medium" onClick={this.continue}>
                {languageMap.textSix}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return affiliate.additional_info.has_broker_license ? (
      <div className="upload-documents-form">
        <div className="key-value">
          <span className="key">{languageMap.textThree}</span>
          <div className="value">
            <FileUpload
              files={fileList['broker_license']}
              loading={fileListLoading['broker_license']}
              name="broker_license"
              onFileUpload={this.fileUploaded}
              onFileRemove={this.deleteDocument}
              limit={2}
              btnText={languageMap.textFour}
              download={true}
              downloadPath={apiEndpoints.affiliateDocument}
            />
            {errors.broker_license && <InlineError text={errors.broker_license} />}
          </div>
        </div>
      </div>
    ) : null;
  };

  render() {
    const languageMap = this.props.languageMap.components.uploadDocuments;
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <p className="title-text">{languageMap.textOne}</p>
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

UploadDocumentsModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showNotification, showModal })(UploadDocumentsModal));
