import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { default as PROVINCES } from '../../constants/provinces.json';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { getDistrictOptions, getSubDistrictOptions, setLanguageToElement } from '../../utils/helper';

class UserAddressModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        first_line: '',
        postal_code: '',
        province: null,
        subdistrict: null,
        district: null,
        ...props.data,
      },
      updatedData: {},
      errors: {},
      loading: false,
      insurers: [],
      districts: [],
      sub_districts: [],
    };

    window.console.log(props);
  }

  componentDidMount() {
    setLanguageToElement();
  }

  validate = (data) => {
    const errors = {};
    return errors;
  };

  handleChange = (name) => (e) => {
    this.setState({
      data: { ...this.state.data, [name]: e.target.value },
      updatedData: { ...this.state.updatedData, [name]: e.target.value },
    });
  };

  handleProvinceChange = async (e, data) => {
    let updatedValues = {
      district: null,
      subdistrict: null,
      province: data.value,
    };

    let provinceCode = PROVINCES.find((province) => province.provincename === data.value).provincecode;
    let districts = await getDistrictOptions(provinceCode);

    this.setState((state) => ({
      districts: districts,
      sub_districts: [],
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  handleDistrictChange = async (e, data) => {
    let { districts } = this.state;

    let updatedValues = {
      district: data.value,
      subdistrict: null,
    };

    let districtCode = districts.find((district) => district.districtname === data.value).districtcode;
    let subDistricts = await getSubDistrictOptions(districtCode);

    this.setState((state) => ({
      sub_districts: subDistricts,
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  handleSubDistrictChange = (e, data) => {
    let { sub_districts } = this.state;

    let updatedValues = {
      subdistrict: data.value,
      postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === data.value).zip,
    };

    this.setState((state) => ({
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  submit = () => {
    let id = this.props.id;
    let data = Object.assign({}, this.state.updatedData);
    window.console.log(data, id);
    let errors = this.validate(data);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    if (!Object.keys(data).length) {
      this.close();
      return;
    }
    if (!id) {
      return;
    }
    window.console.log(data);
    api.crm
      .patchAffiliate(id, { address: data })
      .then((resp) => {
        window.console.log(resp.data.address);
        this.props.action(resp.data.address);
        this.close();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, districts, sub_districts, errors } = this.state;
    const languageMap = this.props.languageMap.components.userAddress;

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <p className="text">{languageMap.textTwo}</p>
          <Form noValidate className="quotation-form">
            <div className="form-field">
              <input
                type="text"
                placeholder={languageMap.textThree}
                onChange={this.handleChange('first_line')}
                value={data.first_line || ''}
              />
              {/* <label htmlFor="" className="form-label">{languageMap.textThree}</label> */}
            </div>
            <div className="form-field with-width">
              <Dropdown
                placeholder={languageMap.textFive}
                selection
                options={PROVINCES}
                onChange={this.handleProvinceChange}
                name="province"
                value={data.province}
              />
            </div>
            <div className={'form-field with-width ' + (!districts.length ? 'disabled' : '')}>
              <Dropdown
                placeholder={languageMap.textSix}
                selection
                options={districts}
                onChange={this.handleDistrictChange}
                name="district"
                value={data.district}
              />
            </div>
            <div className={'form-field with-width ' + (!sub_districts.length ? 'disabled' : '')}>
              <Dropdown
                placeholder={languageMap.textSeven}
                selection
                options={sub_districts}
                onChange={this.handleSubDistrictChange}
                name="subdistrict"
                value={data.subdistrict}
              />
            </div>
            <div className="form-field with-width">
              <input
                type="number"
                placeholder={languageMap.textFour}
                onChange={this.handleChange('postal_code')}
                value={data.postal_code || ''}
              />
              {/* <label htmlFor="" className="form-label">{languageMap.textFour}</label> */}
              {errors.postal_code && <InlineError text={errors.postal_code} />}
            </div>
            <div className="buttons-wrap">
              <button className="button medium" onClick={this.submit}>
                {languageMap.textEight}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

UserAddressModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  id: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(UserAddressModal));
