import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import Input from '@material-ui/core/Input';
import { Button } from 'antd';

import { hideModal } from '../../actions/modal';
import apiEndpoints from '../../commonjs/apiEndpoints';
import '../../scss/consumer/_qr-payment.scss';
import { errorHandler, getLocalData, setLocalData, commaSeperatedNumbers } from '../../utils/helper';
import InlineError from '../../components/InlineError';
import api from '../../commonjs/api';
import validator from 'validator';

function RecordCifModal(props) {
  const languageMap = useSelector((state) => state.languageMap),
    cifLanguageMap = languageMap.components.recordCifModal,
    [data, setData] = useState({}),
    [errors, setErrors] = useState({}),
    requiredFields = ['policy_number', 'tax', 'duty', 'premium'];

  useEffect(() => {
    if (props.cifData) {
      setData({
        policy_number: props.cifData.policy_number,
        tax: props.cifData.tax,
        duty: props.cifData.duty,
        premium: props.cifData.premium,
      })
    }
  }, []);

  function close() {
    props.close();
  }

  function validate() {
    const messages = languageMap.messages;
    let validationErrors = {};

    requiredFields.forEach((field) => {
      if (!data[field]) {
        validationErrors[field] = messages.required;
      } else if (!['policy_number'].includes(field) && !validator.isDecimal(data[field])) {
        validationErrors[field] = messages.invalidFormat;
      }
    })

    return validationErrors;
  }

  function action() {
    const validationErrors = validate();
    if (Object.keys(errors).length) {
      setErrors(validationErrors);
      return;
    }

    const params = {
      ...data,
    };

    if (props.action) {
      props.action(props.cifData?.id, params);
      close();
    }
  }

  function handleChange(e) {
    let tempData = data,
      name = e.target.name,
      value = e.target.value;

    setData({ ...tempData, [name]: value });
  }

  return (
    <div className="cif-modal fairdee-modal-wrapper file-viewer">
      <div className="modal-content">
        <h1 className="title-text">{cifLanguageMap.addDetails}</h1>
        <div className="form-wrapper">
          <div className="form-field">
            <label htmlFor="policy_number">{cifLanguageMap.policyNumber}</label>
            <Input
              name="policy_number"
              placeholder={`NCMI**********`}
              onChange={handleChange}
              value={data.policy_number}
            />
            {errors.policy_number && <InlineError text={errors.policy_number} />}
          </div>
          <div className="form-field">
            <label htmlFor="premium">{cifLanguageMap.premium}</label>
            <Input
              name="premium"
              placeholder={cifLanguageMap.premium}
              onChange={handleChange}
              className={errors.premium ? 'error' : ''}
              value={data.premium}
              disabled={false}
            />
            {errors.premium && <InlineError text={errors.premium} />}
          </div>
          <div className="form-field">
            <label htmlFor="tax">{cifLanguageMap.tax}</label>
            <Input
              name="tax"
              placeholder={cifLanguageMap.tax}
              onChange={handleChange}
              className={errors.tax ? 'error' : ''}
              value={data.tax}
            />
            {errors.tax && <InlineError text={errors.tax} />}
          </div>
          <div className="form-field">
            <label htmlFor="duty">{cifLanguageMap.duty}</label>
            <Input
              name="duty"
              placeholder={cifLanguageMap.duty}
              onChange={handleChange}
              className={errors.duty ? 'error' : ''}
              value={data.duty}
            />
            {errors.duty && <InlineError text={errors.duty} />}
          </div>
        </div>
        <div className="form-field">
          <Button type="default with-right-margin" onClick={close} >{cifLanguageMap.cancel}</Button>
          <Button type="default" onClick={action} >{cifLanguageMap.confirm}</Button>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(RecordCifModal);
