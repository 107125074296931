import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InlineDatePicker, InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-lg.svg';
import cross from '../../images/cross.svg';
import {
  commaSeperatedNumbers,
  errorHandler,
  getLanguage,
  snakeToTitleCase,
  thaiToDefaultDate,
  thaiToDefaultDateTime,
  toThaiDateTimeBase,
} from '../../utils/helper';

// import '../../scss/_unique-link.scss';

const MOMENT = moment().locale('th').add(543, 'years');

const tableHeader = [
  { title: 'Bank Transaction Date time', type: 'transaction', key: 'transaction_time', element_type: 'datetime' },
  { title: 'Bank Transaction Amount', type: 'transaction', key: 'amount', element_type: 'payment' },
  { title: 'Vehicle Number', type: 'quotation', key: 'vehicle_number', element_type: 'link' },
  { title: 'Agent FD Code', type: 'affiliate', key: 'agent_code', element_type: 'link' },
  { title: 'Date time on Payment', type: 'payment', key: 'paid_at', element_type: 'datetime' },
  { title: 'Amount on Payment', type: 'payment', key: 'amount_paid', element_type: 'payment' },
  { title: 'Creator Type', type: 'payment', key: 'creator_type' },
  { title: 'Will be Reconciled after Dry Run', key: 'is_valid' },
];

const paymentStamentTableHeaders = [
  { title: 'Receiver Account Number', type: 'transaction', key: 'receiver_account_number' },
  { title: 'Agent Code', type: 'affiliate', key: 'agent_code', element_type: 'link' },
  { title: 'Amount', type: 'transaction', key: 'amount', element_type: 'payment' },
  { title: 'Will be Reconciled after Dry Run', key: 'is_valid' },
];

class BankStatementUploadModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statement_date: MOMENT,
      paid_at: MOMENT,
      broker_bank_account_id: null,
      fileData: {
        file: null,
        formData: null,
      },
      errors: {},
      bankAccounts: [],
      transactions: [],
      params: {},
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.bankAccounts.length) {
      this.formatBrokerBankAccounts(this.props.bankAccounts);
    }
  }

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  validate = () => {
    let errors = {};
    let { broker_bank_account_id, fileData, statement_date, paid_at } = this.state;
    let messages = this.props.languageMap.messages;

    if (!broker_bank_account_id) {
      errors.broker_bank_account_id = messages.required;
    }

    if (!fileData || !fileData.files || !fileData.files.length) {
      errors.files = messages.required;
    }

    if (!this.props.paymentStatementFlow && !statement_date) {
      errors.statement_date = messages.required;
    }

    if (this.props.paymentStatementFlow && !paid_at) {
      errors.paid_at = messages.required;
    }

    this.setState({
      errors,
    });
    return errors;
  };

  handleSelectChange = (e, data) => {
    let broker_bank_account_id = data.value;
    if (data.value === 'select') {
      broker_bank_account_id = data.value;
    }
    this.setState({
      broker_bank_account_id,
    });
  };

  handleChange = (name, event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    let statement_date = value;
    this.setState({
      [name]: value,
    });
  };

  fileUploaded = (file, name) => {
    let params = new FormData();
    params.append('file', file);
    params.append('document_type', name);
    this.setState({
      fileData: {
        files: [file],
      },
    });
  };

  deleteUploadedFile = (data) => {
    this.setState({
      fileData: {
        files: null,
      },
    });
  };

  formatBrokerBankAccounts = (bankAccounts) => {
    const messages = this.props.languageMap.messages;
    bankAccounts = bankAccounts.map((data) => {
      data.text = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.name = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.value = data.id;
      return data;
    });
    bankAccounts.unshift({
      text: messages.select,
      name: messages.select,
      value: messages.select,
    });
    this.setState({
      bankAccounts,
    });
  };

  deleteDocument = (data) => {
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteAffiliateDocument,
      data,
    });
  };

  setBankStatement = ({ response, params, done }) => {
    let { transactions } = this.state;
    //Bad code. Clean up
    if (done) {
      transactions = [];
      params = {};
    } else {
      transactions = response.transactions || [];
    }
    this.setState({
      transactions,
      params,
    });
  };

  uploadBankStatement = (is_dry_run = false) => {
    const errors = this.validate();
    const messages = this.props.languageMap.messages;
    const { broker_bank_account_id, statement_date, paid_at, fileData } = this.state;
    if (Object.keys(errors).length) {
      return;
    }
    let params = new FormData();
    this.setState({
      apiLoading: true,
    });
    params.append('file', fileData.files[0]);
    params.append('bank_account', broker_bank_account_id);
    params.append('is_dry_run', is_dry_run);
    let promise;
    if (this.props.paymentStatementFlow) {
      params.append('transaction_type', this.props.transaction_type);
      params.append('paid_at', thaiToDefaultDateTime(paid_at));
      params.append('statement_date', thaiToDefaultDate(moment(paid_at).format('YYYY-MM-DD')));
      promise = api.billing.postPaymentStatementFile(params);
    } else {
      params.append('statement_date', thaiToDefaultDate(moment(statement_date).format('YYYY-MM-DD')));
      if (this.props.type && this.props.type === 'batch') {
        promise = api.billing.postBatchBankStatementFile(params);
      } else {
        promise = api.billing.postBankStatementFile(params);
      }
    }

    promise
      .then((resp) => {
        this.setState({
          transactions: resp.transactions,
          params,
          apiLoading: false,
        });
        this.notify(messages.updatedSuccessfully, 'success');
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  };

  verifyBankStatement = (params) => {
    if (params) {
      params.set('is_dry_run', false);
    }
    this.setState({
      loading: true,
    });
    let promise;
    if (this.props.paymentStatementFlow) {
      promise = api.billing.postPaymentStatementFile(params);
    } else if (this.props.type && this.props.type === 'batch') {
      promise = api.billing.postBatchBankStatementFile(params);
    } else {
      promise = api.billing.postBankStatementFile(params);
    }
    promise
      .then((response) => {
        if (this.props.type && this.props.type === 'batch') {
          this.notify('Bank Statement Uploaded successfully. Please check slack channel for details', 'success');
        }
        this.setState({
          loading: false,
        });
        this.close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  };

  renderColumn = (row, column) => {
    let value = '';
    const { title, type, key, element_type, nested_key, sub_type } = column;
    if (row[type]) {
      value = nested_key ? row[type][nested_key][key] : row[type][key];
    }
    if (value) {
      if (element_type === 'link') {
        if (key === 'agent_code') {
          return (
            <a target="_blank" href={`/crm/affiliate/${row.affiliate.id}`}>
              {value}
            </a>
          );
        } else if (key === 'vehicle_number' && row.quotation) {
          let pathname = '/fairdee-wizard';
          return (
            <a target="_blank" href={`/fairdee-wizard?id=${row.quotation.id}`}>
              {value}
            </a>
          );
        }
      } else if (element_type === 'datetime' && moment(value).isValid()) {
        return toThaiDateTimeBase(value);
      }

      if (element_type === 'payment') {
        return commaSeperatedNumbers(value);
      }

      if (key === 'creator_type') {
        if (row?.payment?.creator_type === 'ai') {
          return <span className={'crm-label blue'}>Created By AI</span>;
        }
        return '-';
      }
      return value;
    }
    if (key === 'is_valid') {
      if (this.props.paymentStatementFlow) {
        if (row.ip && row.ip.paid_at) {
          return <span className={'crm-label yellow'}>Already Reconciled</span>;
        } else if (row.ip) {
          return <span className={'crm-label green'}>Yes</span>;
        }
      } else if (row.payment) {
        if (row.payment.is_reconciled) {
          return <span className={'crm-label yellow'}>Already Reconciled</span>;
        }
        return <span className={'crm-label green'}>Yes</span>;
      }
      return <span className={'crm-label red'}>No</span>;
    }
    if (sub_type === 'bank_account') {
      return row[type].broker_bank_account && this.state.bankAccounts.length
        ? this.state.bankAccounts[row[type].broker_bank_account - 1][key]
        : '-';
    }
    if (type === 'change_status' && row.payment) {
      if (!row.payment.is_reconciled) {
        if ([1, 2].indexOf(row.payment.broker_bank_account) > -1) {
          return (
            <button
              className="button medium"
              onClick={(e) => {
                this.handleButtonClick(row);
              }}
            >
              Reconciliate
            </button>
          );
        } else {
          return <span className={'crm-label'}>{"Can't reconcile"}</span>;
        }
      }

      return <span className={'crm-label green'}>Reconciled</span>;
    }

    if (type === 'payment_diff' && row.payment && row.payment.invoice) {
      if (row.payment.invoice.payment_status === 'unpaid' || row.payment.invoice.payment_status === 'underpaid') {
        return <span className={'crm-label red'}>{snakeToTitleCase(row.payment.invoice.payment_status)}</span>;
      }
      if (row.payment.invoice.payment_status === 'overpaid') {
        return <span className={'crm-label yellow'}>OverPaid</span>;
      }
      return <span className={'crm-label green'}>Fully Paid</span>;
    }

    return '-';
  };

  openConfirmationModal = (confirmation) => {
    if (confirmation) {
      this.props.showModal(CONFIRMATION_MODAL, {
        action: () => this.verifyBankStatement(this.state.params),
        text: 'Are you sure to continue?',
      });
    } else {
      this.props.showModal(CONFIRMATION_MODAL, {
        action: () => this.setBankStatement({ response: {}, params: {}, done: true }),
        text: 'Are you sure want to cancel?',
      });
    }
  };

  renderTable = (rows) => {
    const { classes } = this.props;
    let columns = tableHeader;
    if (this.props.paymentStatementFlow) {
      columns = paymentStamentTableHeaders;
    }
    return (
      <div className="crm-table-wrapper">
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr>
                  {columns.map((column, index) => {
                    return <td key={index}>{this.renderColumn(row, column)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const {
      broker_bank_account_id,
      statement_date,
      errors,
      bankAccounts,
      fileData,
      fileLoading,
      transactions,
      loading,
      apiLoading,
      paid_at,
    } = this.state;
    const languageMap = this.props.languageMap.components.fileViewerModal;

    const storedLang = getLanguage();

    return (
      <div className="fairdee-modal-wrapper file-viewer med">
        {transactions && transactions.length > 0 && (
          <div className="modal-content gray-background">
            <img src={cross} className="close" alt="" onClick={this.close} />
            {this.renderTable(transactions)}
            <div className="options-container">
              <div className="row">
                <br />
                <br />
                <form noValidate autoComplete="off" className="flex-item">
                  <Button variant="outlined" className="secondary" onClick={() => this.openConfirmationModal(false)}>
                    Cancel Verification
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="primary"
                    onClick={() => this.openConfirmationModal(true)}
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={20} />}
                    {!loading && <>Click to verify statement</>}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}
        {(!transactions || transactions.length === 0) && !loading && (
          <div className="modal-content">
            <img src={cross} className="close" alt="" onClick={this.close} />
            <div className="mobile-header">
              <p className="current-page" onClick={this.close}>
                <img src={crossLg} alt="" />
                <span className="title-text">{languageMap.textOne}</span>
              </p>
            </div>
            <h1 className="title-text">{languageMap.textOne}</h1>
            {this.props.total && (
              <h1 className="title-text">Expected Transfer Amount: {this.props.total.expectedPayable}</h1>
            )}
            <div>
              <div className="form-wrapper">
                <div className="form-field">
                  <label htmlFor="broker_bank_account_id">{languageMap.textEight}</label>
                  <Dropdown
                    // placeholder={ languageMap.textEight }
                    fluid
                    search
                    selection
                    options={bankAccounts}
                    onChange={this.handleSelectChange}
                    value={broker_bank_account_id}
                    name="broker_bank_account_id"
                  />
                  {errors.broker_bank_account_id && <InlineError text={errors.broker_bank_account_id} />}
                </div>
                {!this.props.paymentStatementFlow && (
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={storedLang === 'thai' ? 'th' : 'en'}
                    moment={moment}
                  >
                    <InlineDatePicker
                      clearable
                      animateYearScrolling={false}
                      id="statement_date"
                      name="statement_date"
                      label={languageMap.textFive}
                      value={statement_date}
                      onChange={this.handleChange.bind(null, 'statement_date')}
                      maxDate={MOMENT.clone()}
                      minDate={MOMENT.clone().subtract(1, 'years')}
                      format="DD/MM/YYYY"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      className="form-field datepicker"
                    />
                  </MuiPickersUtilsProvider>
                )}
                {this.props.paymentStatementFlow && (
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={storedLang === 'thai' ? 'th' : 'en'}
                    moment={moment}
                  >
                    <InlineDateTimePicker
                      clearable
                      animateYearScrolling={false}
                      id="paid_at"
                      name="paid_at"
                      label={languageMap.paymentDate}
                      value={paid_at}
                      onChange={this.handleChange.bind(null, 'paid_at')}
                      maxDate={MOMENT.clone()}
                      minDate={MOMENT.clone().subtract(1, 'years')}
                      format="DD/MM/YYYY HH:mm"
                      ampm={false}
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      className="form-field datepicker"
                    />
                  </MuiPickersUtilsProvider>
                )}
                {errors.paid_at && <InlineError text={errors.statement_date} />}
                <div className="file-upload">
                  <FileUpload
                    files={fileData.files}
                    loading={fileLoading}
                    name="bank_stmt"
                    onFileUpload={this.fileUploaded}
                    onFileRemove={this.deleteDocument}
                    limit={1}
                    btnText={languageMap.textFourteen}
                    accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  {errors.files && <InlineError text={errors.files} />}
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.uploadBankStatement(true)}
              disabled={apiLoading}
            >
              {apiLoading && <CircularProgress size={20} />}
              {!apiLoading && <>Upload Statement</>}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

BankStatementUploadModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  transaction_type: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { showModal, hideModal, showNotification })(BankStatementUploadModal)
);
