import React from 'react';
import { connect, useSelector } from 'react-redux';
import apiEndpoints from '../commonjs/apiEndpoints';
import { policyStatus } from '../commonjs/commonData';
import { commaSeperatedNumbers, isAdmin, snakeToTitleCase, toThaiDate } from '../utils/helper';
import FileUpload from './FileUpload';

function CoronaFormCard(props) {
  const lang = useSelector((state) => state.languageMap);
  const statusMapping = lang.statusMappingCorona;
  const coronaLanguageMap = lang.components.coronaTable;
  const cardData = props.data;
  return (
    <div className="policy-card coa-card">
      <div className="card-header">
        <div className="row">
          <div className="col">
            {cardData && cardData.plan && cardData.plan.insurer ? (
              <img src={cardData.plan.insurer.logo} alt="" />
            ) : null}
            {cardData.client_first_name ? (
              <div>
                <p className="text-one">{cardData.client_first_name}</p>
                <p className="text">{cardData.client_phone}</p>
              </div>
            ) : (
              <div>
                <p className="text-one">-</p>
              </div>
            )}
          </div>
          <div className="col">
            {policyStatus[cardData.policy_status] ? (
              <p className={'status-label ' + policyStatus[cardData.policy_status].className}>
                {snakeToTitleCase(statusMapping[cardData.policy_status])}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="card-content">
        <p className="line"></p>
        <div className="row">
          <p className="text col">{cardData.id}</p>
          <p className="text col">{toThaiDate(cardData.created_at)}</p>
        </div>
        {cardData && cardData.plan && Object.keys(cardData.plan).length ? (
          <div className="inline-key-value">
            <p className="key">
              {coronaLanguageMap.policyDate}
              <span className="value">{cardData.policy_start_date ? toThaiDate(cardData.policy_start_date) : '-'}</span>
            </p>
            <p className="key">
              {coronaLanguageMap.premiumAfterTax}
              <span className="value">
                {cardData.plan ? commaSeperatedNumbers(cardData.plan.premium_after_tax) : '-'}
              </span>
            </p>
          </div>
        ) : null}
        {isAdmin() ? (
          <>
            {cardData.files && (
              <div className="inline-key-value">
                <div className="key">
                  <p className="mb-10">{coronaLanguageMap.applicationForm}</p>
                  {cardData.files['application_form'] && cardData.files['application_form'].length > 0 ? (
                    <FileUpload
                      download={true}
                      files={cardData.files['application_form']}
                      name="application_form"
                      downloadPath={apiEndpoints.covidDocument}
                      hideUpload={true}
                      action={props.openFileViewer}
                      rejectAction={true}
                      limit={2}
                      disableDelete={true}
                      multiple={true}
                      accept="image/jpeg,image/png,application/pdf"
                    />
                  ) : (
                    '-'
                  )}
                </div>
                <div className="key">
                  <p className="mb-10">{coronaLanguageMap.paymentProof}</p>
                  {cardData.files['payment_proof'] && cardData.files['payment_proof'].length > 0 ? (
                    <FileUpload
                      download={true}
                      files={cardData.files['payment_proof']}
                      name="payment_proof"
                      downloadPath={apiEndpoints.covidDocument}
                      hideUpload={true}
                      action={props.validateDocsAndOpenViewer}
                      rejectAction={true}
                      limit={2}
                      disableDelete={true}
                      multiple={true}
                      accept="image/jpeg,image/png,application/pdf"
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>

      {!props.isRenewal && (
        <div className="card-footer">
          <div
            onClick={props.downloadDocument}
            disabled={props.disableDownloadBtn()}
            className={props.disableDownloadBtn() ? 'disabled' : ''}
          >
            {coronaLanguageMap.downloadDocument}
          </div>
          <div className="btn" onClick={props.goToWizard}>
            {coronaLanguageMap.viewDetails}
          </div>
        </div>
      )}
      {props.isRenewal && (
        <div className="card-footer">
          <div className="btn" onClick={props.action}>
            {coronaLanguageMap.renew || 'Renew'}
          </div>
        </div>
      )}
    </div>
  );
}
export default connect(null, {})(CoronaFormCard);
