import MomentUtils from '@date-io/moment';
import { Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, Input } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import {
  commaSeperatedNumbers,
  errorHandler,
  getAccessToken,
  getParameterByName,
  openFile,
  toFixedNumber,
  toUTCTime,
} from '../../utils/helper';

// import '../../scss/_unique-link.scss';

const MOMENT = moment().locale('th');

const tableColumns = [
  { title: 'Vehicle Number', key: 'vehicle_number', element_type: 'link' },
  { title: 'Agent Code', key: 'agent_code', element_type: 'link' },
  { title: 'Insurer', key: 'insurer_name' },
  { title: 'Type', key: 'type' },
  { title: 'Invoice Date', key: 'invoice_date', element_type: 'datetime' },
  { title: 'Due Date', key: 'due_date', element_type: 'datetime' },
  { title: 'Amount Paid', key: 'amount', element_type: 'payment' },
  { title: 'Payable Amount', key: 'amount_payable', element_type: 'payment' },
  { title: 'Payment Status', key: 'payment_status', element_type: 'label' },
];

class RecordPaymentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paid_at: MOMENT,
      broker_bank_account_id: null,
      agent_code: null,
      errors: {},
      bankAccounts: [],
      bank_reference_number: null,
      amount_paid: null,
      transactions: [],
      params: {},
      intermediatePayment: {},
      invoices: [],
      affiliates: this.props.affiliates || [],
      apiLoading: false,
      fileData: {
        file: null,
        formData: null,
      },
      payment_proofs: null,
      currentTab: 'one',
      isEdit: false,
      currentPaymentId: '',
      searchLoading: false,
    };
  }

  componentDidMount() {
    this.formatBrokerBankAccounts(this.props.bankAccounts);
    this.formatAffiliate();

    if (this.props.data) {
      const payment = this.props.data;

      let payment_proofs = payment.files && payment.files.length && payment.files;
      if (payment_proofs) {
        payment_proofs = payment_proofs.map((proof) => {
          proof.file.name = proof.file.original_file_name;
          return proof;
        });
      }

      this.setState({
        paid_at: toUTCTime(payment.paid_at),
        broker_bank_account_id: payment.broker_bank_account,
        bank_reference_number: payment.bank_reference_number,
        amount_paid: payment.amount_paid,
        payment_proofs,
      });
    } else if (this.props.disableUnderPayment) {
      this.setState({
        amount_paid: this.calculateAmountToPay(),
      });
    }
  }

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  validate = () => {
    let errors = {};
    let { broker_bank_account_id, paid_at, amount_paid, bank_reference_number, agent_code, affiliates } = this.state;
    let messages = this.props.languageMap.messages;

    if (!broker_bank_account_id) {
      errors.broker_bank_account_id = messages.required;
    }

    if (!amount_paid) {
      errors.amount_paid = messages.required;
    } else if (isNaN(amount_paid)) {
      errors.amount_paid = messages.invalidFormat;
    }

    if (!bank_reference_number) {
      errors.bank_reference_number = messages.required;
    }

    // else {
    //   const amountToPay = toFixedNumber(this.calculateAmountToPay(), 2);
    //   if (toFixedNumber(amount_paid - amountToPay, 2) > 1) {
    //     errors.amount_paid = `${messages.lessValue} ${toFixedNumber(amountToPay + 1, 2)}`;
    //   } else if (toFixedNumber(amount_paid - amountToPay, 2) < -1) {
    //     errors.amount_paid = `${messages.moreThan} ${toFixedNumber(amountToPay - 1 , 2)}`;
    //   }
    // }
    if (agent_code && this.props.selected_agent && this.props.selected_agent.replace(' ', '') !== agent_code) {
      // set error message
      errors.agent_code = 'Agent code is not valid';
    }

    if (!this.state.fileData || !this.state.fileData.files || !this.state.fileData.files.length) {
      errors.files = messages.required;
    }

    if (this.props.shouldCheckAgent && !agent_code) {
      errors.agent_code = messages.required;
    }
    if (!paid_at) {
      errors.paid_at = messages.required;
    }

    this.setState({
      errors,
    });
    return errors;
  };

  handleSelectChange = (e, data) => {
    let broker_bank_account_id = data.value;

    if (data.value === 'select') {
      broker_bank_account_id = data.value;
    }
    this.setState({
      broker_bank_account_id,
    });
  };

  handleSelectAgentCode = (e, data) => {
    let agent_code = data.value;
    if (this.props.selected_agent && this.props.selected_agent.replace(' ', '') !== agent_code.split(' - ')[0]) {
      // set error message
      this.setState({
        errors: {
          agent_code: 'Agent code is not valid',
        },
      });
    } else {
      // reset error message
      this.setState({
        agent_code,
        errors: {},
      });
    }

    this.setState({
      agent_code,
    });
  };

  handleChange = (name, event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    let paid_at = value;
    this.setState({
      paid_at,
    });
  };

  formatBrokerBankAccounts = (bankAccounts) => {
    const messages = this.props.languageMap.messages;
    bankAccounts = bankAccounts.map((data) => {
      data.text = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.name = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
      data.value = data.id;
      return data;
    });
    bankAccounts.unshift({
      text: messages.select,
      name: messages.select,
      value: messages.select,
    });
    this.setState({
      bankAccounts,
    });
  };

  formatAffiliate = () => {
    const { affiliates } = this.props;
    let affiliate = null;
    if (affiliates && affiliates.length > 0) {
      affiliate = affiliates.map((affiliate) => {
        let data = {};
        data.text = `${affiliate.label} - ${affiliate.email}`;
        data.name = `${affiliate.label} - ${affiliate.email}`;
        data.value = affiliate.label;
        return data;
      });
    }

    this.setState({
      affiliates: affiliate,
    });
  };

  handleTabChange = (event, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  createPayment = () => {
    const errors = { ...this.state.errors, ...this.validate() };
    const messages = this.props.languageMap.messages;
    const { broker_bank_account_id, paid_at, amount_paid, fileData, payment_proof, bank_reference_number } = this.state;
    if (Object.keys(errors).length) {
      return;
    }
    const params = {
      broker_bank_account: broker_bank_account_id,
      paid_at: toUTCTime(paid_at),
      amount_paid: amount_paid,
      bank_reference_number: bank_reference_number,
      invoice_ids: [this.props.invoice.id],
      payer: this.props.invoice.payer,
      payee: this.props.invoice.payee,
    };

    if (this.props.createReconciledPayment) {
      params.is_reconciled = true;
    }
    var form_data = new FormData();

    for (var key in params) {
      form_data.append(key, params[key]);
    }

    if (fileData && fileData.files && fileData.files.length) {
      form_data.append('file', fileData.files[0]);
    }

    api.billing
      .postBillingpayment(form_data)
      .then((resp) => {
        this.props.action && this.props.action(resp);
        this.notify(messages.updatedSuccessfully, 'success');
        this.close();
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.errors?.bank_reference_number) {
          this.setState({
            errors: {
              duplicateBankRefNo: true,
              messages: data.errors.bank_reference_number,
            },
          });
        } else {
          errorHandler(error.response, true);
        }
      });
  };

  updatePayment = () => {
    const errors = this.validate();
    const messages = this.props.languageMap.messages;
    const { broker_bank_account_id, paid_at, amount_paid, bank_reference_number, fileData } = this.state;
    if (Object.keys(errors).length) {
      return;
    }
    const params = {
      broker_bank_account: broker_bank_account_id,
      paid_at: toUTCTime(paid_at),
      amount_paid,
      bank_reference_number,
    };
    if (this.props.createReconciledPayment) {
      params.is_reconciled = true;
    }
    var form_data = new FormData();

    for (var key in params) {
      form_data.append(key, params[key]);
    }

    if (fileData && fileData.files && fileData.files.length) {
      form_data.append('file', fileData.files[0]);
    }
    api.crm
      .patchPayment(this.state.currentPaymentId, form_data)
      .then((resp) => {
        window.console.log(resp);
        this.props.action && this.props.action(resp);
        this.setState({
          isEdit: false,
          currentTab: 'two',
        });
        this.notify(messages.updatedSuccessfully, 'success');
        this.close();
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.errors?.bank_reference_number) {
          this.setState({
            errors: {
              duplicateBankRefNo: true,
              messages: data.errors.bank_reference_number,
            },
          });
        } else {
          errorHandler(error.response, true);
        }
      });
  };

  deletePayment = (id) => {
    const messages = this.props.languageMap.messages;
    api.billing
      .deleteBillingPayment(id ? id : this.props.payment[0].id)
      .then((_resp) => {
        this.notify(messages.deletedSuccessfully, 'success');
        this.close();
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => errorHandler(error.response, true));
  };

  openConfirmationModal = (id, deletePayment = false) => {
    const { isEdit } = this.state;
    this.props.showModal(CONFIRMATION_MODAL, {
      action: () => {
        if (!isEdit && this.state.currentTab === 'one') {
          this.createPayment();
        } else {
          if (deletePayment) {
            this.deletePayment(id);
          } else {
            this.updatePayment();
          }
        }
      },
      text: 'Are you sure to continue?',
    });
  };

  loadAffiliates = (p = {}) => {
    this.setState({
      searchLoading: true,
    });
    p = { ...p, is_oic_data_verified: true };

    api.crm
      .getAffiliates(p)
      .then((res) => {
        res = res.results.map((affiliate) => {
          let data = {};
          data.text = `${affiliate.agent_code + ' - ' + affiliate.user.fullname} - ${affiliate.user.email}`;
          data.name = `${affiliate.agent_code + ' - ' + affiliate.user.fullname} - ${affiliate.user.email}`;
          data.value = affiliate.agent_code + ' - ' + affiliate.user.fullname;
          return data;
        });
        this.setState({
          affiliates: res,
        });
      })
      .finally(() =>
        this.setState({
          searchLoading: false,
        })
      );
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.loadAffiliates({ search: searchQuery });
  };

  handleInputChange = (name, e) => {
    let value = null;

    value = name === 'amount_paid' ? Number(e.target.value) : e.target.value;

    this.setState({
      [name]: value,
    });
  };

  calculateAmountToPay = () => {
    const { invoice } = this.props;
    return parseFloat(invoice.amount_payable) - (parseFloat(invoice.amount_paid) || 0);
  };

  fileUploaded = (file, name) => {
    let params = new FormData();
    params.append('file', file);
    params.append('document_type', name);
    this.setState({
      fileData: {
        files: [file],
      },
    });
  };

  deleteUploadedFile = (data) => {
    this.setState({
      fileData: {
        files: null,
      },
    });
  };

  fileOpener = (id) => {
    if (!id) {
      return;
    }
    let url = apiEndpoints.billingPaymentFile + '/' + id + `?attachment=true&token=${getAccessToken()}`,
      t = getParameterByName('t');
    if (t) {
      url += `&t=${t}`;
    }
    openFile(url);
  };

  render() {
    let { data, disableUnderPayment } = this.props || {};
    const payment = this.props.payment && this.props.payment.length ? this.props.payment[0] : {};
    const {
      broker_bank_account_id,
      agent_code,
      paid_at,
      errors,
      bankAccounts,
      invoices,
      intermediatePayment,
      amount_paid,
      bank_reference_number,
      apiLoading,
      fileData,
      affiliates,
      payment_proofs,
      currentTab,
    } = this.state;
    const languageMap = this.props.languageMap.components.intermediatePaymentModal;
    const invoice_data = {
      amount_payable: this.props.invoice.amount_payable,
      due_date: this.props.invoice.due_date,
    };
    let payments = [];
    if (Object.keys(payment).length) {
      payments = this.props.payment.map((item) => {
        return { ...item, invoice_data };
      });
    }

    return (
      <div className="fairdee-modal-wrapper file-viewer medium-large">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <h1 className="title-text">{'Payment Details'}</h1>
          <div className="tabs-wrapper">
            <Tabs value={currentTab} onChange={this.handleTabChange} className="tabs">
              <Tab value="one" className="tab" label={languageMap.addPayment}></Tab>
              <Tab value="two" className="tab" label={'Payment List'}></Tab>
            </Tabs>
          </div>
          {currentTab === 'one' ? (
            <div>
              <div className="form-wrapper">
                <div className="options-container">
                  <div className="row margin-bottom-20">
                    <div className="header-text">{languageMap.totalAmount}</div>
                    <div className="text-center">
                      <span className="crm-label green">{toFixedNumber(this.calculateAmountToPay(), 2)}</span>
                    </div>
                  </div>
                </div>
                <div className="form-field">
                  {this.props.minAmountPayable ? (
                    <> {`${languageMap.minAmountPayable}: ${commaSeperatedNumbers(this.props.minAmountPayable)}`}</>
                  ) : null}
                </div>
                {this.state.errors.duplicateBankRefNo && (
                  <div className="errorBankRefNo">
                    <a href={this.state.errors.messages[1]} className="qoutationLink" target="_blank">
                      {this.state.errors.messages[0]}
                    </a>
                  </div>
                )}
                <div className="form-field">
                  <label htmlFor="broker_bank_account_id">{languageMap.bankDetails}</label>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={bankAccounts}
                    data
                    onChange={this.handleSelectChange}
                    value={broker_bank_account_id}
                    name="broker_bank_account_id"
                    disabled={Boolean(data?.is_reconciled)}
                  />
                  {errors.broker_bank_account_id && <InlineError text={errors.broker_bank_account_id} />}
                </div>
                <div className="form-field">
                  <label htmlFor="bank_reference_number">{languageMap.bankReferenceNumber}</label>
                  <Input
                    name="bank_reference_number"
                    onChange={(e) => this.handleInputChange('bank_reference_number', e)}
                    className={errors.bank_reference_number ? 'error' : ''}
                    value={bank_reference_number}
                    disabled={Boolean(data?.is_reconciled)}
                  />
                  {errors.bank_reference_number && <InlineError text={errors.bank_reference_number} />}
                </div>
                <div className="form-field">
                  <label htmlFor="amount_paid">{languageMap.amountPaid}</label>
                  <Input
                    name="amount_paid"
                    onChange={(e) => this.handleInputChange('amount_paid', e)}
                    className={errors.amount_paid ? 'error' : ''}
                    value={amount_paid}
                    disabled={Boolean(data?.is_reconciled || disableUnderPayment)}
                    type="number"
                  />
                  {errors.amount_paid && <InlineError text={errors.amount_paid} />}
                </div>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDateTimePicker
                    clearable
                    animateYearScrolling={false}
                    id="paid_at"
                    name="paid_at"
                    label={languageMap.paymentDate}
                    value={paid_at}
                    onChange={this.handleChange.bind(null, 'paid_at')}
                    maxDate={MOMENT.clone()}
                    minDate={MOMENT.clone().subtract(2, 'years')}
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    className="form-field datepicker"
                    disabled={Boolean(data?.is_reconciled)}
                  />
                </MuiPickersUtilsProvider>
                {errors.paid_at && <InlineError text={errors.paid_at} />}

                {this.props.shouldCheckAgent ? (
                  <div className="form-field">
                    <label htmlFor="agent_code">{languageMap.agentCode}</label>
                    <Input
                      name="agent_code"
                      onChange={(e) => this.handleInputChange('agent_code', e)}
                      className={errors.agent_code ? 'error' : ''}
                      value={agent_code}
                      disabled={Boolean(data?.is_reconciled)}
                      type="text"
                    />
                    {errors.agent_code && <InlineError text={errors.agent_code} />}
                  </div>
                ) : null}

                <div className="file-upload-wrap">
                  <div className="form-field">
                    <label htmlFor="amount_paid">Payment Proof</label>
                    <div className="file-upload">
                      {payment_proofs ? (
                        <FileUpload
                          download={true}
                          files={payment_proofs}
                          loading={false}
                          name="payment_proofs"
                          onFileRemove={this.deleteDocument}
                          limit={1}
                          btnText={languageMap.textFourteen}
                          accept="image/jpeg,image/png"
                          disableDelete={Boolean(data?.is_reconciled)}
                          downloadPath={apiEndpoints.billingPaymentFile}
                        />
                      ) : (
                        <FileUpload
                          files={fileData.files}
                          loading={false}
                          name="payment_proof"
                          onFileUpload={this.fileUploaded}
                          onFileRemove={this.deleteDocument}
                          limit={1}
                          btnText={languageMap.textFourteen}
                          accept="image/jpeg,image/png"
                          disabled={Boolean(data?.is_reconciled)}
                        />
                      )}
                      {errors.files && <InlineError text={errors.files} />}
                    </div>
                  </div>

                  {errors.payment_fee_proof && <InlineError text={errors.payment_fee_proof} />}
                </div>
              </div>
              <div className="inline-buttons record-payments">
                {!data?.is_reconciled && (
                  <Button variant="contained" color="primary" onClick={() => this.openConfirmationModal()}>
                    {languageMap.confirm}
                  </Button>
                )}
                {payment && payment.id ? (
                  <Button variant="contained" color="primary" onClick={() => this.openConfirmationModal(true)}>
                    {languageMap.deletePayment}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : null}
          {currentTab === 'two' ? (
            <>
              {payments.length > 0 ? (
                payments.map((item, index) => {
                  return (
                    <div className="payment-items">
                      <div className="payment-item">
                        <div className="payment-header">
                          <p className="payment-title"> Payment #{index + 1}</p>
                        </div>
                        <div className="data">
                          <p>Reconciled</p>
                          <p>{item.is_reconciled ? 'Yes' : 'No'}</p>
                        </div>
                        <div className="data">
                          <p>Amount Paid</p>
                          <p>฿{item.amount_paid}</p>
                        </div>
                        <div className="data">
                          <p>Due date</p>
                          <p>{item.invoice_data.due_date}</p>
                        </div>
                        <div className="data">
                          <p>Paid on</p>
                          <p>{toUTCTime(item.paid_at)}</p>
                        </div>
                        <div className="data">
                          <p>Created on</p>
                          <p>{toUTCTime(item.created_at)}</p>
                        </div>
                        <div className="data">
                          <p>Payment Proof</p>
                          {item.files && item.files.length && item.files[0].id ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.fileOpener(item.files[0]?.id)}
                            >
                              Download payment proof
                            </Button>
                          ) : item.vendor_name === 'thai_qr' && this.props.creditInvoice ? (
                            <p>Qr 1 Credit Invoice</p>
                          ) : item.vendor_name === 'thai_qr' && !this.props.creditInvoice ? (
                            <p>Qr Bill Payment</p>
                          ) : item.vendor_name === 'bill_payment' ? (
                            <p>Bill Payment</p>
                          ) : (
                            <p>Added by System</p>
                          )}
                        </div>
                        {item.files &&
                        item.files.length === 0 &&
                        !['thai_qr', 'bill_payment'].includes(item.vendor_name) ? (
                          <>
                            <div className="data">
                              <p>Payment from Overpayment</p>
                              <p>
                                {item.vendor_name === 'billing.Payment' &&
                                item.vendor_transaction_id.includes('rebalance')
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                            </div>
                            <div className="data">
                              <p>{item.amount_paid < 0 ? 'Paid to invoice' : 'Overpaid credit invoice #'}</p>
                              <p>
                                {item.vendor_name === 'billing.Payment' &&
                                item.vendor_transaction_id.includes('rebalance')
                                  ? item.vendor_transaction_id.split('-')[3]
                                  : '-'}
                              </p>
                            </div>
                          </>
                        ) : null}
                        {item.vendor_name === 'thai_qr' && this.props.creditInvoice ? (
                          <div className="data">
                            <p>Transaction Id</p>
                            <p>{item.bill_payment_request_transaction_id}</p>
                          </div>
                        ) : null}
                        <div className="data">
                          <p>Delete Payment</p>
                          {item.files && item.files.length && item.files[0].id ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.openConfirmationModal(item.id, true)}
                            >
                              Delete Payment
                            </Button>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <p> No payments available</p>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

RecordPaymentModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  createReconciledPayment: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, hideModal, showNotification })(RecordPaymentModal));
