import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { userProfile } from '../actions/auth';
import { setFilter } from '../actions/filter';
import { hideModal } from '../actions/modal';
import api from '../commonjs/api';
import { dropdownYears } from '../commonjs/commonData';
import { ReactBeforeUnload } from '../components/ReactBeforeUnload';
import SearchList from '../components/SearchList';
import headerBanner from '../images/hand-time.svg';
import { store } from '../index';
import {
  getFilterData,
  getParameterByName,
  getUrlparamsAsObject,
  isMobileDevice,
  notify,
  objToUrlParams,
  setFilterData,
} from '../utils/helper';

let INSURANCE_CLASSES = ['1', '2', '3', '2+', '3+'];

class MakeModelBike extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        make: '',
        make_id: '',
        description_name: '',
        description: '',
        cc: '',
        cc_id: '',
        make_model_id: '',
        manufacture_year: '',
        // insurance_class: ['2+'],
        is_commercial: false,
        vmi_vehicle_category: '',
      },
      carModel: [],
      carBrand: [],
      carModels: [],
      carCapacity: [],
      carDescription: [],
      errors: {},
      dataHasChanged: false,
      currentFocus: null,
      disableButton: true,
      emptyResult: {
        makeModel: false,
      },
      updatedData: {},
    };

    this.showCancelButton = true;

    let openModal = getParameterByName('openmodal');
    let source = getParameterByName('source');

    if (openModal || source === 'line') {
      this.showCancelButton = false;
    }
  }

  componentDidMount() {
    this.getMakeData();
    const languageMap = this.props.languageMap

    let data = this.props.data,
      filterData = getFilterData();
    if (Boolean(filterData.is_sale_transfer_flow) === true && filterData.vehicleData) {
      data = filterData.vehicleData;
      // Reset filter data
      filterData.vehicleData = null;
      filterData.is_sale_transfer_flow = null;
      filterData.garage_type = null;
      setFilterData(filterData);
    }
    if (data) {
      this.setState({
        data: { ...this.state.data, ...data },
      });
      if (data.make_id) {
        this.getModelData(data.make_id);
      }
      if (data.make_model_id) {
        this.getCcData(data.make_model_id);
      }
      if (data.cc_id) {
        this.getDescData(data.cc_id);
      }
    }

    this.setState({
      currentFocus: 'make_id',
    });
  }

  getMakeData = () => {
    const params = {
      has_2W: true,
    };
    api.masterData
      .getVehicleMake(params)
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.name = data.value.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carBrand: resp.results,
        });
      })
      .catch((error) => console.log(error));
  };

  getModelData = (id) => {
    api.masterData
      .getVehicleModels(id, { is_motorcycle: true })
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carModels: resp.results,
        });
        // if (resp.results.length) {
        //   let id = resp.results[0].id;
        //   this.getCcData(id);
        //   this.setState({
        //     data: { ...this.state.data, make_model_id: id },
        //   }, () => {
        //     this.validate(this.state.data);
        //   });
        // }
      })
      .catch((error) => console.log(error));
  };

  getCcData = (id) => {
    api.masterData
      .getVehicleCapacity(id)
      .then((resp) => {
        resp.results = resp.results.map((data) => {
          data.text = data.name.toLowerCase();
          data.value = data.id;
          return data;
        });
        this.setState({
          carCapacity: resp.results,
        });
        if (resp.results.length === 1) {
          this.handleCapacityChange('cc_id', resp.results[0]);
        }
      })
      .catch((error) => console.log(error));
  };

  getDescData = (id) => {
    const languageMap = this.props.languageMap;

    api.masterData
      .getVehicleDesc(id)
      .then((resp) => {
        if (resp.results.length > 0) {
          this.setState(
            {
              data: { ...this.state.data, description: resp.results[0].id, description_name: resp.results[0].name },
            },
            () => {
              this.validate(this.state.data);
            }
          );
        } else {
          this.setState({
            emptyResult: {
              makeModel: true,
            },
          });
          notify(languageMap.messages.failedGetDescData, 'error');
        }
      })
      .catch((error) => console.log(error));
  };

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;
    let disableButton = false;

    if (!data.make_id) {
      errors.make_id = languageMap.required;
    }
    if (!data.description) {
      errors.description = languageMap.required;
    }
    if (!data.manufacture_year) {
      errors.manufacture_year = languageMap.required;
    }
    // if (!data.insurance_class.length) {
    //   errors.insurance_class = languageMap.required;
    // }
    disableButton = Object.keys(errors).length ? true : false;
    this.setState({
      disableButton,
    });
    return errors;
  };

  handleMakeChange = (key, data) => {
    if (this.state.data.make_id && this.state.data.make_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '';
    let i = 0;
    for (i = 0; i < this.state.carBrand.length; i++) {
      if (this.state.carBrand[i].id === id) {
        text = this.state.carBrand[i].name;
      }
    }
    this.setState(
      {
        data: { ...this.state.data, make_id: id, make: text, make_model_id: '', cc_id: '', description: '' },
        updatedData: {
          ...this.state.updatedData,
          make_id: id,
          make: text,
          make_model_id: '',
          cc_id: '',
          description: '',
        },
        carModels: [],
        carCapacity: [],
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'make_model_id' : 'make_id',
      },
      () => {
        this.validate(this.state.data);
      }
    );
    if (!data.value) {
      return;
    }
    this.getModelData(id);
  };

  handleModelChange = (key, data) => {
    if (this.state.data.make_model_id && this.state.data.make_model_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '',
      vmiVehicleCategory = '';
    let i = 0;
    for (i = 0; i < this.state.carModels.length; i++) {
      if (this.state.carModels[i].id === id) {
        text = this.state.carModels[i].name;
        vmiVehicleCategory = this.state.carModels[i].vmi_vehicle_category
      }
    }
    this.setState(
      {
        data: { ...this.state.data, make_model_id: id, make_model: text, cc_id: '', description: '', vmi_vehicle_category: vmiVehicleCategory },
        updatedData: { ...this.state.updatedData, make_model_id: id, make_model: text, cc_id: '', description: '' },
        carCapacity: [],
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'cc_id' : 'make_model_id',
      },
      () => {
        this.validate(this.state.data);
      }
    );
    if (!data.value) {
      return;
    }
    this.getCcData(id);
  };

  handleCapacityChange = (key, data) => {
    if (this.state.data.cc_id && this.state.data.cc_id === data.value) {
      return;
    }
    const id = data.value;
    let text = '';
    let i = 0;
    for (i = 0; i < this.state.carCapacity.length; i++) {
      if (this.state.carCapacity[i].id === id) {
        text = this.state.carCapacity[i].name;
      }
    }
    this.setState(
      {
        data: { ...this.state.data, cc: text, cc_id: id, description: '' },
        updatedData: { ...this.state.updatedData, cc: text, cc_id: id, description: '' },
        carDescription: [],
        dataHasChanged: true,
        currentFocus: id ? 'manufacture_year' : 'cc_id',
      },
      () => {
        this.validate(this.state.data);
        if (!data.value) {
          return;
        }
        this.getDescData(id);
      }
    );
  };

  handleYearChange = (e, data) => {
    this.setState(
      {
        data: { ...this.state.data, manufacture_year: data.value },
        updatedData: { ...this.state.updatedData, manufacture_year: data.value },
        dataHasChanged: true,
        currentFocus: 'manufacture_year',
      },
      () => {
        this.validate(this.state.data);
      }
    );
  };

  handleCheckboxChange = (event, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.checked },
      updatedData: { ...this.state.updatedData, [data.name]: data.checked },
    });
  };

  getQuote = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) {
      return;
    }
    let tempFilterData = getFilterData() || {};
    tempFilterData.bikeFilter = tempFilterData.bikeFilter || {};
    tempFilterData.bikeFilter.is_commercial = [this.state.data.is_commercial.toString()];

    let filterData = {
      ...tempFilterData,
      bikeData: this.state.data,
    };
    setFilterData(filterData);
    store.dispatch(setFilter(filterData));
    if (this.props.action) {
      this.props.action(this.state.dataHasChanged);
      if (isMobileDevice()) {
        this.close();
      }
    } else {
      this.close('/get-quotes-bike');
    }
  };

  redirect = (to) => {
    let params = getUrlparamsAsObject(),
      passableParams = [
        'ref',
        'affiliate',
        'vendor',
        'instalment',
        'lg_code',
        'old_quotation_id',
        'quotation_query_id',
        'type',
        'vmi_vehicle_category',
      ];

    Object.keys(params).forEach((key) => {
      if (!passableParams.includes(key)) {
        delete params[key];
      }
    });
    if (params.instalment) {
      params.instalment = params.instalment === 'true';
    }
    if (!params.vmi_vehicle_category && this.state.data.vmi_vehicle_category) {
      params.vmi_vehicle_category = this.state.data.vmi_vehicle_category;
    }

    let pathname = to,
      search = objToUrlParams(params);

    if (search) {
      this.props.history.push({
        pathname,
        search,
      });
    } else {
      this.props.history.push(to);
    }
  };

  close = (to) => {
    if (to) {
      this.redirect(to);
      this.props.hideModal(null, {
        ...this.props,
      });
      return;
    }
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  renderHeader = () => {
    const languageMap = this.props.languageMap.components.makeModel;

    if (isMobileDevice()) {
      return (
        <div className="header-wrap">
          <p className="text">{languageMap.textThree}</p>
        </div>
      );
    }
    return (
      <div className="header-wrap">
        <img src={headerBanner} alt="" />
        <div>
          <p className="text">{languageMap.textOne}</p>
          <p className="text">{languageMap.textFour}</p>
        </div>
      </div>
    );
  };

  render() {
    const { errors, currentFocus, disableButton, updatedData } = this.state;
    const languageMap = this.props.languageMap.components.makeModel;
    const messages = this.props.languageMap.messages;

    return (
      <div className={'make-model ' + (this.props.openModal ? 'fairdee-modal-wrapper' : '')}>
        <ReactBeforeUnload
          onBeforeunload={() => (Object.keys(updatedData).length ? messages.loseData : null)}
          {...this.props}
        />
        {this.renderHeader()}
        <div className="make-modal-wrap">
          <Form noValidate className="quotation-form">
            <SearchList
              id="make_id"
              label={languageMap.modelText.textOne}
              onChange={this.handleMakeChange}
              options={this.state.carBrand}
              listLabel={languageMap.searchTextObj.textOne}
              value={this.state.data.make_id}
              name="make_id"
              search={true}
              isOpen={false}
              error={errors.make_id ? true : false}
              helpertext={errors.make_id}
              autoFocus={Boolean(currentFocus === 'make_id')}
            />

            <SearchList
              id="make_model_id"
              label={languageMap.modelText.textTwo}
              onChange={this.handleModelChange}
              options={this.state.carModels}
              listLabel={languageMap.searchTextObj.textTwo}
              value={this.state.data.make_model_id}
              name="make_model_id"
              search={true}
              isOpen={false}
              error={errors.make_model_id ? true : false}
              helpertext={errors.make_model_id}
              autoFocus={Boolean(currentFocus === 'make_model_id')}
            />

            <SearchList
              id="cc_id"
              label={languageMap.modelText.textThree}
              onChange={this.handleCapacityChange}
              options={!this.state.emptyResult.makeModel ? this.state.carCapacity : []}
              listLabel={languageMap.searchTextObj.textThree}
              value={this.state.data.cc_id}
              name="cc_id"
              search={true}
              isOpen={false}
              error={errors.cc_id ? true : false}
              helpertext={errors.cc_id}
              autoFocus={Boolean(currentFocus === 'cc_id')}
              disabled={this.state.emptyResult.makeModel}
            />

            <SearchList
              id="manufacture_year"
              label={languageMap.modelText.textFour}
              onChange={this.handleYearChange}
              options={!this.state.emptyResult.makeModel ? dropdownYears() : []}
              listLabel={languageMap.searchTextObj.textFour}
              value={this.state.data.manufacture_year}
              name="manufacture_year"
              search={true}
              isOpen={false}
              error={errors.manufacture_year ? true : false}
              helpertext={errors.manufacture_year}
              autoFocus={Boolean(currentFocus === 'manufacture_year')}
              disabled={this.state.emptyResult.makeModel}
            />

            {/* <CheckboxGroup
              options={INSURANCE_CLASSES}
              onChange={this.handleCheckboxChange}
              value={this.state.data.insurance_class}
              label={languageMap.insuranceClass.name}
              error={errors.insurance_class}
              autoFocus={Boolean(currentFocus === 'insurance_class')}
              name="insurance_class"
            /> */}

            {/* <Form.Field>
              <Checkbox label={languageMap.modelText.textEleven} name="is_commercial" value={this.state.data.is_commercial.toString()} text="selected" onChange={this.handleCheckboxChange} checked={this.state.data.is_commercial}  />
            </Form.Field> */}

            <Form.Field className="button-wrap">
              <button className="button primary" disabled={disableButton} onClick={this.getQuote}>
                {this.props.data ? languageMap.buttonTextOne : languageMap.buttonText}
              </button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

MakeModelBike.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    filter: state.filter,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, userProfile })(MakeModelBike));
