import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import InlineError from '../../components/InlineError';
import SearchList from '../../components/SearchList';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

class SuminsuredModal extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('SuminsuredModal => ', props);

    this.state = {
      data: {
        sum_insured: this.props.data.sum_insured || '',
      },
      errors: {},
      loading: false,
      sumInsuredRange: [],
    };
  }

  componentDidMount() {
    if (
      this.props.data.min_suminsured &&
      this.props.data.max_suminsured &&
      this.props.data.min_suminsured !== this.props.data.max_suminsured
    ) {
      let i = this.props.data.min_suminsured;
      let sumInsuredRange = [];
      while (i <= this.props.data.max_suminsured) {
        sumInsuredRange.push({ key: i, value: i, name: i });
        i = i + 10000;
      }
      this.setState({
        sumInsuredRange,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  validateSumInsured = (value) => {
    window.console.log(this.props, value);
    if (!value) {
      this.setState({
        errors: { ...this.state.errors, sum_insured: 'Value cannot be empty' },
      });
      return false;
    }
    if (parseInt(value, 10) === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          sum_insured:
            'Value should be between ' + this.props.data.min_suminsured + ' and ' + this.props.data.max_suminsured,
        },
      });
      return false;
    }
    if (parseInt(value, 10) < this.props.data.min_suminsured || parseInt(value, 10) > this.props.data.max_suminsured) {
      this.setState({
        errors: {
          ...this.state.errors,
          sum_insured:
            'Value should be between ' + this.props.data.min_suminsured + ' and ' + this.props.data.max_suminsured,
        },
      });
      return false;
    }

    if (this.props.data.min_suminsured === this.props.data.max_suminsured) {
      return true;
    }
    this.setState({
      errors: { ...this.state.errors, sum_insured: null },
    });
    return true;
  };

  onSuminsuredChange = (key, data) => {
    window.console.log(key, data);
    this.setState({
      data: { ...this.state.data, [key]: data.value },
    });
  };

  submit = () => {
    if (!this.validateSumInsured(this.state.data.sum_insured)) {
      return;
    }

    this.props.action({ sum_insured: parseInt(this.state.data.sum_insured, 10) });
    this.close();
  };

  render() {
    const { data, errors, sumInsuredRange } = this.state;
    const languageMap = this.props.languageMap.components.sumInsuredModal;
    return (
      <div className="fairdee-modal-wrapper small">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.titleText}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.titleText}</h1>
          <Form noValidate className="quotation-form">
            {!sumInsuredRange.length ? (
              <p className="label-text">
                {languageMap.range}: {this.props.data.min_suminsured} - {this.props.data.max_suminsured}
              </p>
            ) : (
              ''
            )}
            {sumInsuredRange.length ? (
              <SearchList
                id="sum_insured"
                onChange={this.onSuminsuredChange}
                options={sumInsuredRange}
                value={data.sum_insured}
                name="sum_insured"
                search={true}
                error={errors.sum_insured ? true : false}
                helpertext={errors.sum_insured}
                commaSeperated={true}
              />
            ) : (
              <Form.Field>
                <input
                  type="number"
                  id="sum_insured"
                  name="sum_insured"
                  placeholder={languageMap.inputText}
                  value={data.sum_insured}
                  onChange={this.onChange}
                />
                {errors.sum_insured && <InlineError text={errors.sum_insured} />}
                <label htmlFor="sum_insured">{languageMap.inputText}</label>
              </Form.Field>
            )}

            <div className="button-wrap">
              <button className="button medium" onClick={this.submit}>
                {languageMap.buttonText}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

SuminsuredModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(SuminsuredModal));
