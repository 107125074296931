import MomentUtils from '@date-io/moment';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import validator from 'validator';
import { setAffiliate } from '../actions/affiliate';
import { showModal } from '../actions/modal';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import apiEndpoints from '../commonjs/apiEndpoints';
import FileUpload from '../components/FileUpload';
import InlineError from '../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../constants/types';
import { store } from '../index';
import { errorHandler, isDealership, isMobileDevice, notify, thaiToDefaultDate, toThaiDate } from '../utils/helper';

const MOMENT = moment().locale('th').add(543, 'years');
let BANKS = [];

class EditBankdetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      affiliate: {
        user: {},
        additional_info: {},
        line_users: [],
        commission_rates: [],
        broker_license_expiry_date: null,
        broker_license_number: null,
      },
      bankDetails: {
        ac_holder_name: '',
        ac_number: '',
        bank_name: '',
      },
      loading: true,
      editable: true,
      errors: {},
      fileList: {},
      fileListLoading: {},
      updatedBankData: {},
      updatedAffiliateData: {},
    };
  }

  componentDidMount() {
    window.console.log(this.props.affiliate);
    this.id = this.props.affiliate.id || null;

    BANKS = Object.keys(this.props.languageMap.banks).map((key) => {
      return {
        key,
        value: key,
        text: this.props.languageMap.banks[key],
      };
    });

    this.getAffiliateProfile();
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  getAffiliateProfile = () => {
    let bankDetails = null;
    let fileList = {};
    let obj = {},
      affiliate = JSON.parse(JSON.stringify(this.props.affiliate));

    if (affiliate.broker_license_expiry_date) {
      affiliate.broker_license_expiry_date = toThaiDate(affiliate.broker_license_expiry_date);
    }

    if (affiliate.address) {
      obj = affiliate.address;
    }
    if (affiliate.user) {
      obj.fullname = affiliate.user.fullname;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      bankDetails = affiliate.bank_details[0];
    }
    if (affiliate && affiliate.documents && affiliate.documents.length) {
      affiliate.documents.forEach((data) => {
        if (!fileList[data.document_type]) {
          fileList[data.document_type] = [];
        }
        data.file.name = data.file.original_file_name;
        fileList[data.document_type].push(data);
      });
      this.setState({
        fileList,
      });
      window.console.log(fileList);
    }
    this.setState({
      affiliate,
      loading: false,
      data: obj,
      fileList,
      bankDetails: bankDetails || this.state.bankDetails,
      disableLicenceUpdate: !!affiliate.broker_license_number && affiliate.is_oic_data_verified !== false,
    });
    this.id = affiliate.id;
    // if (this.props.action) {
    //   this.props.action(affiliate.id);
    // }
  };

  getAffiliateProfileApiCall = () => {
    return api.crm
      .getAffiliateProfile({ with_commission_rates: true })
      .then((data) => {
        window.console.log(data);
        store.dispatch(setAffiliate(data.message));
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (name) => (e) => {
    if (!e) {
      // Happening in case of date picker.
      return;
    }
    let value = null;
    if (moment.isMoment(e)) {
      value = e;
    } else {
      value = e.target.value;
    }
    if (name === 'ac_number') {
      let pattern = new RegExp(/^[\d - .]+$/);
      if (!pattern.test(value ? parseInt(value) : value) && value) return false;
    }
    if (name === 'ac_number' || name === 'ac_holder_name') {
      this.setState({
        bankDetails: { ...this.state.bankDetails, [name]: value },
        updatedBankData: { ...this.state.updatedBankData, [name]: value },
      });
      return;
    }

    if (name === 'vat_enabled') {
      value = value === 'true' ? true : false;
    }
    this.setState({
      affiliate: { ...this.state.affiliate, [name]: value },
      updatedAffiliateData: { ...this.state.updatedAffiliateData, [name]: value },
    });
  };

  handleBankChange = (evt, data) => {
    window.console.log(data);
    this.setState({
      bankDetails: { ...this.state.bankDetails, [data.name]: data.value },
      updatedBankData: { ...this.state.updatedBankData, [data.name]: data.value },
    });
  };

  deleteAffiliateDocument = (data) => {
    window.console.log(data);
    api.user
      .deleteAffiliateDocument(data.id)
      .then((resp) => {
        window.console.log(resp);
        let fileList = this.state.fileList;
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        this.setState({
          fileList,
          updatedData: true,
        });
        // this.getAffiliateProfileApiCall();

        if (this.props.action) {
          this.props.action(this.id);
        }
      })
      .catch((error) => {
        this.notify('Something went wrong', 'error');
      });
  };

  uploadFile = (params, type, file) => {
    let fileListLoading = this.state.fileListLoading;
    fileListLoading[type] = true;
    this.setState({ fileListLoading });
    api.user
      .affiliateDocument(params)
      .then((resp) => {
        window.console.log(this.state, params);
        fileListLoading[type] = false;
        let fileList = this.state.fileList;
        file.id = resp.message.id;
        file.document_type = type;

        if (!fileList[type]) {
          fileList[type] = [file];
        } else {
          fileList[type].push(file);
        }
        this.setState({ fileListLoading, fileList });
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');

        if (this.props.action) {
          this.props.action(this.id);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.file &&
          error.response.data.response.file.file
        ) {
          this.notify(
            this.props.languageMap.messages[error.response.data.response.file.file] ||
              error.response.data.response.file.file,
            'error'
          );
        } else {
          this.notify('Something went wrong', 'error');
        }
        fileListLoading[type] = false;
        this.setState({ fileListLoading });
      });
  };

  fileUploaded = (file, name) => {
    let params = new FormData();
    params.append('file', file);
    params.append('document_type', name);
    this.uploadFile(params, name, file);
  };

  deleteDocument = (data) => {
    window.console.log(data);
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteAffiliateDocument,
      data,
    });
  };

  patchAffiliate = () => {
    let params = { ...this.state.updatedAffiliateData };
    this.setState({
      loading: true,
    });
    if (params.broker_license_expiry_date) {
      params.broker_license_expiry_date = thaiToDefaultDate(params.broker_license_expiry_date);
    }

    api.crm
      .patchAffiliate(this.state.affiliate.id, params)
      .then((resp) => {
        window.console.log(resp);
        this.setState({
          loading: false,
          updatedAffiliateData: {},
        });
        this.getAffiliateProfileApiCall();
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
      })
      .catch((error) => console.log(error));
  };

  validateBankDetails = (data) => {
    window.console.log(data);
    const messages = this.props.languageMap.messages;
    let errors = {};
    if (
      this.state.affiliate.additional_info.has_broker_license &&
      (!this.state.fileList['broker_license'] || !this.state.fileList['broker_license'].length)
    ) {
      errors.broker_license = messages.required;
    }
    if (
      this.state.affiliate.broker_license_number &&
      !validator.isNumeric(this.state.affiliate.broker_license_number)
    ) {
      errors.broker_license_number = messages.invalidFormat;
    }
    return errors;
  };

  saveBankDetails = () => {
    if (Object.keys(this.state.updatedAffiliateData).length) {
      this.patchAffiliate();
    }
    let errors = this.validateBankDetails(this.state.bankDetails);
    window.console.log(errors);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    const { affiliate } = this.state;
    const { ac_number, ac_holder_name, bank_name } = this.state.bankDetails;
    let params = {
      ac_number: ac_number.replace(/-/g, ''),
      ac_holder_name,
      bank_name,
    };
    if (!Object.keys(params).length) {
      return;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      let params = { ...this.state.updatedBankData };
      if (params.ac_number) {
        params.ac_number = params.ac_number.replace(/-/g, '');
      }
      if (!Object.keys(params).length) {
        return;
      }
      this.setState({
        loading: true,
      });
      api.user
        .patchBankDetails(params)
        .then((resp) => {
          window.console.log(resp);
          this.setState({
            loading: false,
            updatedBankData: {},
          });
          this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
          this.getAffiliateProfileApiCall();
        })
        .catch((error) => {
          const languageMap = this.props.languageMap.components.bankDetails;
          this.setState({
            loading: false,
          });
          let errors = errorHandler(error.response);
          if (errors && errors.ac_number) {
            notify(languageMap.acNumberError, 'error');
          } else {
            errorHandler(error.response, true);
          }
        });
      return;
    }

    this.setState({
      loading: true,
    });
    api.user
      .bankDetails(params)
      .then((resp) => {
        this.getAffiliateProfile();
        this.setState({
          loading: false,
          editable: !this.state.editable,
          updatedBankData: {},
        });
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  gotoProfile = () => {
    if (isMobileDevice()) {
      this.props.history.push('/buy-insurance?type=profile');
    } else {
      this.props.history.push('/profile');
    }
  };

  downloadDocument = (data) => {
    window.console.log(data);
    let url = apiEndpoints.affiliateDocument + '/' + data.id;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  renderEditForm = () => {
    const { errors, fileList, fileListLoading, affiliate, bankDetails, loading, disableLicenceUpdate } = this.state;
    const languageMap = this.props.languageMap.components.bankDetails;

    window.console.log(languageMap);

    return (
      <div className="inline-wrapper">
        <div>
          <div className="key-value">
            <span className="key">{languageMap.textOne}</span>
            <div className="value">
              <div className="form-field">
                <Dropdown
                  placeholder={languageMap.textOne}
                  selection
                  options={BANKS}
                  onChange={this.handleBankChange}
                  name="bank_name"
                  value={bankDetails.bank_name}
                  search
                />
                {errors.bank_name && <InlineError text={errors.bank_name} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textTwo}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="text"
                  placeholder={languageMap.textTwo}
                  onChange={this.handleChange('ac_number')}
                  value={bankDetails.ac_number || ''}
                />
                {errors.ac_number && <InlineError text={errors.ac_number} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textThree}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="text"
                  placeholder={languageMap.textThree}
                  onChange={this.handleChange('ac_holder_name')}
                  value={bankDetails.ac_holder_name || ''}
                />
                {errors.ac_holder_name && <InlineError text={errors.ac_holder_name} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textNine}</span>
            <div className="value">
              <div className="form-field">
                <input
                  type="number"
                  placeholder={languageMap.textNine}
                  onChange={this.handleChange('broker_license_number')}
                  value={affiliate.broker_license_number || ''}
                  disabled={disableLicenceUpdate}
                />
                {errors.broker_license_number && <InlineError text={errors.broker_license_number} />}
              </div>
            </div>
          </div>
          <div className="key-value">
            <span className="key">{languageMap.textTen}</span>
            <div className="value">
              <div className="form-field">
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDatePicker
                    clearable
                    keyboard
                    animateYearScrolling={false}
                    id="broker_license_expiry_date"
                    label=""
                    className="datepicker"
                    value={affiliate.broker_license_expiry_date}
                    onChange={this.handleChange('broker_license_expiry_date')}
                    format="DD/MM/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    maxDate={MOMENT.clone().add(100, 'year')}
                    minDate={MOMENT.clone().subtract(100, 'year')}
                    disabled={Boolean(affiliate.broker_license_expiry_date)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          {Object.keys(fileList).includes('national_id') ? (
            <div className="key-value">
              <span className="key">{languageMap.textFour}</span>
              <div className="value">
                <FileUpload
                  files={fileList['national_id']}
                  loading={fileListLoading['national_id']}
                  name="national_id"
                  onFileUpload={this.fileUploaded}
                  onFileRemove={this.deleteDocument}
                  limit={5}
                  btnText={languageMap.textSeven}
                  download={true}
                  downloadPath={apiEndpoints.affiliateDocument}
                />
                {errors.national_id && <InlineError text={errors.national_id} />}
              </div>
            </div>
          ) : null}
          {affiliate.additional_info.has_broker_license ? (
            <div className="key-value">
              <span className="key">{languageMap.textFive}</span>
              <div className="value">
                <FileUpload
                  files={fileList['broker_license']}
                  loading={fileListLoading['broker_license']}
                  name="broker_license"
                  onFileUpload={this.fileUploaded}
                  onFileRemove={this.deleteDocument}
                  limit={5}
                  btnText={languageMap.textSeven}
                  download={true}
                  downloadPath={apiEndpoints.affiliateDocument}
                />
                {errors.broker_license && <InlineError text={errors.broker_license} />}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="key-value">
            <span className="key">{languageMap.companyLogo}</span>
            <div className="value">
              <FileUpload
                files={fileList['company_logo']}
                loading={fileListLoading['company_logo']}
                name="company_logo"
                onFileUpload={this.fileUploaded}
                onFileRemove={this.deleteDocument}
                limit={1}
                btnText={languageMap.textFourteen}
              />
            </div>
          </div>
          {isDealership() && (
            <>
              <div className="key-value">
                <span className="key">{languageMap.companyName}</span>
                <div className="value">
                  <div className="form-field">
                    <input
                      type="text"
                      placeholder={languageMap.companyName}
                      onChange={this.handleChange('company_name')}
                      value={affiliate.company_name || ''}
                    />
                    {errors.company_name && <InlineError text={errors.company_name} />}
                  </div>
                </div>
              </div>
              <div className="key-value">
                <span className="key">{languageMap.financeEmail}</span>
                <div className="value">
                  <div className="form-field">
                    <input
                      type="text"
                      placeholder={languageMap.financeEmail}
                      onChange={this.handleChange('finance_email')}
                      value={affiliate.finance_email || ''}
                    />
                    {errors.finance_email && <InlineError text={errors.finance_email} />}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="key-value">
            <span className="key">&nbsp;</span>
            <div className="value">
              <div className="buttons-wrap">
                <button className={'button medium ' + (loading ? 'disabled' : '')} onClick={this.saveBankDetails}>
                  {languageMap.textSix}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="affiliate-profile">
        <div className="affiliate-details">
          <div>{this.renderEditForm()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, showNotification, setAffiliate })(EditBankdetailsForm));
