import moment from 'moment';
import React, { useEffect, useState } from 'react';

const AnimatedCard = ({ animation, digit }) => {
  return (
    <div className={`flip-card ${animation}`}>
      <span>{digit}</span>
    </div>
  );
};

const StaticCard = ({ position, digit }) => {
  return (
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
};

const FlipUnitContainer = ({ digit, shuffle, unit }) => {
  // assign digit values
  let currentDigit = digit;
  let previousDigit = digit + 1;

  // to prevent a negative value
  if (unit !== 'hours') {
    previousDigit = previousDigit === 61 ? 0 : previousDigit;
  } else {
    previousDigit = previousDigit === -1 ? 23 : previousDigit;
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  // shuffle animations
  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return (
    <div className={'flip-unit-container'}>
      <StaticCard position={'upper-card'} digit={currentDigit} />
      <StaticCard position={'lower-card'} digit={previousDigit} />
      <AnimatedCard digit={digit1} animation={animation1} />
      <AnimatedCard digit={digit2} animation={animation2} />
    </div>
  );
};

function CountdownTimer(props) {
  // Setting Initial value of counter as difference of current time and the time at which it will expire in seconds.
  let value = moment(props.validTill).diff(moment(), 'seconds');
  const [counter, setCounter] = useState(value > 0 ? value : 0),
    [minutes, setMinutes] = useState(null),
    [seconds, setSeconds] = useState(null),
    [minutesShuffle, setMinuteShuffle] = useState(true),
    [secondsShuffle, setSecondsShuffle] = useState(true);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
      const currentMinutes = Math.floor(counter / 60);
      const currentSeconds = counter % 60;
      if (currentMinutes != minutes) {
        setMinuteShuffle(!minutesShuffle);
      }
      if (currentSeconds != seconds) {
        setSecondsShuffle(!secondsShuffle);
      }
      setMinutes(currentMinutes);
      setSeconds(currentSeconds);
    } else if (counter === 0) {
      action();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  useEffect(() => {
    value = moment(props.validTill).diff(moment(), 'seconds');
    if (value > 0) {
      setCounter(value);
    }
  }, [props.validTill]);

  function action() {
    if (props.action) {
      props.action();
    }
  }

  if (counter < 1) {
    return null;
  }

  return (
    <div className="countdown-timer">
      <FlipUnitContainer unit={'minutes'} digit={minutes} shuffle={minutesShuffle} />
      <FlipUnitContainer unit={'seconds'} digit={seconds} shuffle={secondsShuffle} />
    </div>
  );
}

export default CountdownTimer;
