import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/cross.svg';

class YoutubeModal extends React.Component {
  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  state = {
    loading: true,
  };

  onReady = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { loading } = this.state;
    const { url } = this.props;

    return (
      <div className="fairdee-modal-wrapper youtube-player">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          {loading ? (
            <div className="youtube-loading">
              <CircularProgress />
            </div>
          ) : null}
          <div className="youtube-player">
            <ReactPlayer url={url} playing width="100%" controls={true} onReady={this.onReady} />
          </div>
        </div>
      </div>
    );
  }
}

YoutubeModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(YoutubeModal));
