import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';

class SelectInsurerModal extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('SelectInsurerModal => ', props);

    this.state = {
      data: {
        insurer: '',
      },
      errors: {},
    };
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (name) => (e) => {
    this.setState({
      data: { ...this.state.data, [name]: e.target.value },
    });
  };

  validate = (data) => {
    let errors = {};
    const messages = this.props.languageMap.messages;

    if (!data.insurer) {
      errors.insurer = messages.required;
    }
    return errors;
  };

  submit = () => {
    this.setState({
      errors: {},
    });
    let data = { ...this.state.data };
    let errors = this.validate(data);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    window.console.log(this.state.data);
    if (this.props.data) {
      let data = { ...this.props.data, ...this.state.data };
      this.props.action(data);
    }
    this.close();
  };

  render() {
    const { errors, data } = this.state;
    const { insurers } = this.props;
    const insurersLangMap = this.props.languageMap.currentInsurerMap;
    const languageMap = this.props.languageMap.components.selectInsurerModal;

    return (
      <div className="fairdee-modal-wrapper medium province-modal">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">&nbsp;</span>
            </p>
          </div>
          {/* <h1 className="title-text">{languageMap.textOne}</h1> */}
          <Form noValidate className="quotation-form full-width">
            <FormControl className="full-width">
              {insurers ? (
                <div className="select-wrap">
                  <InputLabel htmlFor="select-multiple">{languageMap.textOne}</InputLabel>
                  <Select
                    value={data.insurer}
                    onChange={this.handleChange('insurer')}
                    name="insurer"
                    input={<Input id="select-multiple" />}
                    className="full-width"
                  >
                    {Object.keys(insurers).map((id) => (
                      <MenuItem key={insurers[id].id} value={insurers[id].id}>
                        {insurersLangMap[insurers[id].name]}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.insurer && <InlineError text={errors.insurer} />}
                </div>
              ) : null}
            </FormControl>
            <Form.Field className="button-wrap">
              <button className="button primary medium" onClick={this.submit}>
                {languageMap.textTwo}
              </button>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

SelectInsurerModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    insurers: state.insurers,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(SelectInsurerModal));
