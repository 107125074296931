import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InteractionExtraInfoForm from './InteractionExtraInfoForm';

function TabContainer(props) {
  return <div className="tab-container">{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function CrmModalFormLayout(props) {
  const [value, setValue] = useState(0);
  const languageMap = useSelector((state) => state.languageMap.components.crmModalFormLayout);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className="crm-form-wrapper">
      <div className="tabular-content">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          className="tabs-wrapper"
        >
          <Tab label={languageMap.textOne} className="tab" />
        </Tabs>

        {value === 0 && (
          <TabContainer>
            <InteractionExtraInfoForm
              data={props.data}
              close={props.close}
              action={props.action}
              getAffiliate={props.getAffiliate}
            />
          </TabContainer>
        )}
      </div>
    </div>
  );
}

export default CrmModalFormLayout;
