/*
  Prev date and next day of the date range of holidays
  To block 01-01-2020, use range 31-12-2020 and 02-01-2021
  If there are days in between, again follow the same logic: day before and day after the range.
  Note: Specific for Dropdown Year Month Day datepicker.
*/

import moment from 'moment';
import { THAI_COUNTRY_ID } from '.';
import api from '../commonjs/api';
import { toThaiDateTimeBase } from '../utils/helper';

export const THAI_HOLIDAYS = async (params) => {
  const holidays = [],
    currentYear = moment().year();
  if (!params) {
    params = {
      applicable_from__gte: moment(`${currentYear}-01-01 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      order_by: "applicable_from",
    };
  }
  params = { ...params, country: THAI_COUNTRY_ID };
  let response = await api.masterData.getHolidays(params);
  response.forEach((res) => {
    holidays.push([toThaiDateTimeBase(res.applicable_from), toThaiDateTimeBase(res.applicable_till)]);
  });
  return holidays;
};
