import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler } from '../../utils/helper';

class RemarkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data || {
        remark: '',
      },
      errors: {},
    };
  }

  componentDidMount() {}

  validate = (data) => {
    const errors = {};

    return errors;
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  patchRemark = () => {
    let params = {};
    if (this.props.data_affected) {
      params.remark = this.state.data.remark;
      params.is_visible = true;
      params.data_affected = this.props.data_affected;
      params.data_value = this.props.data_value;
      params.quotation_query = this.props.objectId;
    }
    let promise = '';
    if (this.props.patchCall) {
      promise = this.props.patchCall(
        this.state.data.id,
        this.props.data_affected ? params : { remark: this.state.data.remark, is_visible: true }
      );
    } else {
      promise = api.utils.patchRemark(
        this.state.data.id,
        this.props.data_affected ? params : { remark: this.state.data.remark, is_visible: true }
      );
    }
    promise
      .then((resp) => {
        if (this.props.action && this.props.data_affected) {
          this.props.action(resp.message.remark);
        } else {
          this.props.action();
        }
        this.close();
      })
      .catch((error) => errorHandler(error.response, true));
  };

  submit = () => {
    let entity = this.props.entity;
    if ((this.props.data_affected && this.props.data?.remark) || this.state.data.id) {
      this.patchRemark();
      return;
    }
    let id = this.props.objectId || this.props.quotationId;
    let remark = this.state.data.remark;
    this.setState({
      errors: {},
    });

    if (!remark) {
      this.setState({
        errors: { remark: 'Required Field' },
      });
      return;
    }
    if (!id) {
      return;
    }
    let params = {};
    if (this.props.data_affected) {
      params.remark = this.state.data.remark;
      params.is_visible = true;
      params.data_affected = this.props.data_affected;
      params.data_value = this.props.data_value;
      params.quotation_query = id;
    }
    let promise = '';
    if (this.props.postCall) {
      promise = this.props.postCall(this.props.data_affected ? params : { remark, [entity]: id, is_visible: true });
    } else {
      promise = api.utils.addRemark(this.props.data_affected ? params : { remark, quotation: id, is_visible: true });
    }

    promise
      .then((resp) => {
        if (this.props.action && this.props.data_affected) {
          this.props.action(resp.message.remark);
        } else {
          this.props.action();
        }
        this.close();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, errors } = this.state;
    const languageMap = this.props.languageMap.components.remarksModal;

    return (
      <div className="fairdee-modal-wrapper customer-info remark">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">
                {['rejected by insurer', 'docs missing'].includes(this.props?.data_value)
                  ? languageMap[this.props?.data_value]
                  : this.state.data.id || (this.props.data_affected && this.props.data && this.props.data.length)
                  ? `${languageMap.textThree} ${languageMap.textFive}`
                  : `${languageMap.textSeven} ${languageMap.textFive}`}
              </span>
            </p>
          </div>
          <h1 className="title-text">
            {['rejected by insurer', 'docs missing'].includes(this.props?.data_value)
              ? languageMap[this.props?.data_value]
              : this.state.data.id || (this.props.data_affected && this.props.data?.remark)
              ? `${languageMap.textThree} ${languageMap.textFive}`
              : `${languageMap.textSeven} ${languageMap.textFive}`}
          </h1>
          {/* <p className="text">{languageMap.textTwo}</p> */}
          <Form noValidate className="quotation-form">
            <Form.Field>
              <textarea
                type="text"
                id="remark"
                name="remark"
                placeholder={languageMap.textFive}
                value={data.remark}
                onChange={this.onChange}
                className="remark-box"
              ></textarea>
              {errors.remark && <InlineError text={errors.remark} />}
              {/* <label className="form-label" htmlFor="remark">Remark</label> */}
            </Form.Field>
            <div className="buttons-wrap single">
              <button className="button medium" onClick={this.submit}>
                {languageMap.textSix}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

RemarkModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(RemarkModal));
