import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import { setLanguageToElement } from '../../utils/helper';

class MessageModal extends React.Component {
  componentDidMount() {
    setLanguageToElement();
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continue = () => {
    if (this.props.action) {
      this.props.action();
    }
    this.close();
  };

  render() {
    const { children, hideCancel, buttonText, showCross } = this.props;

    return (
      <div className="fairdee-modal-wrapper small-medium message">
        <div className="modal-content">
          {!hideCancel || showCross ? <img src={cross} className="close" alt="" onClick={this.close} /> : ''}

          {children}

          {hideCancel ? (
            <button className="button medium primary mt-24" onClick={this.continue}>
              {buttonText || 'กลับ'}
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

MessageModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  hideCancel: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(MessageModal));
