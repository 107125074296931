import React from 'react';

const LineShare = ({ link }) => {
  return (
    <div className="line-share">
      <div
        className="line-it-button"
        data-lang="en"
        data-type="share-b"
        data-ver="3"
        data-url={link}
        data-color="default"
        data-size="small"
        data-count="false"
        style={{ display: 'none' }}
      ></div>
    </div>
  );
};
export default LineShare;
