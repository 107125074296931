import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { MOBILE_MODAL } from '../../../constants/types';
import VerifyPhoneBanner from '../../../images/fairdee-x-plus/auth/security-banner.svg';
import CloseIcon from '../../../images/fairdee-x-plus/common/close.svg';
import { RootState } from '../../../index';
import { errorHandler, isEmailOtp } from '../../../utils/helper';
import InfoModal from '../common/InfoModal';
import Otp from './Otp';

interface Props {
  close?: () => void;
}

interface ErrorResponse {
  response: any;
}

const VerifyPhoneNudge: React.FC<Props> = ({ close }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    affiliate = useSelector((state: RootState) => state.affiliate),
    dispatch = useDispatch();

  function openOtpScreen(intent: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={affiliate.user.phone} intent={intent} />,
        uid: 'OTP_SCREEN',
      })
    );
  }

  function openInfoModal(title: string, text: string, btnText: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} btnText={btnText} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function requestOtp(intent: string) {
    let params = {
      username: affiliate.user.phone,
      intent: 'update_phone',
      username_type: 'phone',
      is_email_otp: isEmailOtp(),
    };
    api.user
      .requestOtp(params)
      .then(() => {
        openOtpScreen(intent);
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          openInfoModal('', languageMap[`Too many OTP requests! Account blocked for 24 hours.`], '');
        }
      });
  }

  return (
    <div className="mobile-layout login-register welcome-modal">
      <div className="content-wrapper">
        <img src={CloseIcon} alt="close" onClick={close} className="close" />
        <div className="img-wrap">
          <img src={VerifyPhoneBanner} alt="security-banner" />
        </div>
        <p className="title-text">{languageMap[`You need to verify the phone number`]}</p>
        <p className="text">{languageMap[`For your account's security, please verify your phone number`]} </p>
        <Button className="orange-button" onClick={() => requestOtp('update_phone')}>
          {languageMap[`Verify phone`]}
        </Button>
      </div>
    </div>
  );
};

export default VerifyPhoneNudge;
