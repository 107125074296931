import { API_URL } from '../constants/config';

const apiEndpoints = {
  getConversationImageDescription: API_URL + 'utils/conversation-image-description',
  authenticate: API_URL + 'profile/login',
  logout: API_URL + 'auth/revoke-token',
  socialLogin: API_URL + 'auth/convert-token',
  userProfile: API_URL + 'profile/user',
  resetPassword: API_URL + 'profile/user/reset-password',
  bankDetails: API_URL + 'profile/bank-details',
  setPasswordEmail: API_URL + 'crm/affiliate-set-password-email/',
  affiliateDocument: API_URL + 'profile/affiliate-document',
  rolesWithStaff: API_URL + 'crm/roles-with-staff',
  assignRole: API_URL + 'crm/assign-role',
  revokeRole: API_URL + 'crm/revoke-role',
  defaultRole: API_URL + 'crm/set-default-assignee',
  searchUser: API_URL + 'crm/search-user',
  compulsoryInsurance: API_URL + 'utils/compulsory-insurance',
  compulsoryInsuranceList: API_URL + 'utils/compulsory-insurance-list',
  affiliates: API_URL + 'crm/affiliates',
  createUser: API_URL + 'crm/create-user',
  affliateDiscounts: API_URL + 'crm/affiliate-discounts',
  affiliateCommission: API_URL + 'crm/affiliate-commissions',
  lineUsers: API_URL + 'crm/line-users',
  vehicleMake: API_URL + 'master-data/make',
  vehicleModels: API_URL + 'master-data/make-model/',
  vehicleDescription: API_URL + 'master-data/desc-by-make/',
  vehicleDesc: API_URL + 'master-data/desc/',
  vehicleCc: API_URL + 'master-data/cc/',
  insurers: API_URL + 'master-data/insurers',
  getPriceList: API_URL + 'master-data/price-list',
  sumInsured: API_URL + 'master-data/get-sum-insured',
  getCarData: API_URL + 'master-data/desc-detail/',
  quotationQuery: API_URL + 'utils/fairdee-quotation-query',
  generatePdf: API_URL + 'utils/fairdee-comparison-pdf',
  policyLead: API_URL + 'policy/quote-lead',
  policyUpload: API_URL + 'policy/existing-policy-upload',
  policyDetails: API_URL + 'policy/policy',
  initialLead: API_URL + 'utils/initial-lead',
  forgotPassword: API_URL + 'crm/affiliate-reset-password-email',
  lineLogin: API_URL + 'crm/line-login',
  fairdeeQuotation: API_URL + 'utils/fairdee-quotation',
  quotationDocumentUpload: API_URL + 'utils/fairdee-document',
  lineAffiliateSignup: API_URL + 'fairline/affiliate-signup',
  lineGroupName: API_URL + 'fairline/get-line-group-name',
  fairdeeQuotationReport: API_URL + 'utils/fairdee-quotation-report',
  affiliate: API_URL + 'crm/affiliates',
  quotationReport: API_URL + 'utils/fairdee-quotation-report',
  compulsaryInsurance: API_URL + 'utils/compulsory-insurance-list',
  manualPriceList: API_URL + 'crm/price-list',
  downloadCoverNote: API_URL + 'utils/fairdee-cover-note',
  user: API_URL + 'data-entry/add_user',
  getUser: API_URL + 'data-entry/user/',
  car: API_URL + 'data-entry/car/',
  driver: API_URL + 'data-entry/driver/',
  policy: API_URL + 'data-entry/policy',
  makeModel: API_URL + '/data-entry/make-model',
  insurerPolicies: API_URL + 'data-entry/insurer-policy-list/',
  linkPolicy: API_URL + 'data-entry/link-policy',
  manualUserEmail: API_URL + 'data-entry/send-manual-user-email/',
  getPolicy: API_URL + 'data-entry/policy/',
  referralCodeUpdate: API_URL + 'data-entry/promo-code/',
  refetchQuotes: API_URL + 'data-entry/refetch-quotes/',
  addQuotation: API_URL + 'data-entry/create-quotation/',
  addAddons: API_URL + 'data-entry/create-addon/',
  getFormUrl: API_URL + 'data-entry/form-url',
  sendQuotesEmail: API_URL + 'policy/send-quote-email',
  downloadPdf: API_URL + 'policy/download-quote-pdf',
  motoristLeads: API_URL + 'external/get-all-leads',
  quotation: API_URL + 'data-entry/quotation/',
  addOn: API_URL + 'data-entry/addon/',
  saleTrack: API_URL + 'crm/fairdee-sale',
  fairdeePolicy: API_URL + 'crm/fairdee-policy',
  patchUser: API_URL + 'crm/users/',
  remark: API_URL + 'utils/fairdee-quotation-remark',
  getAffiliates: API_URL + 'crm/affiliates',
  changeAffiliatePassword: API_URL + 'profile/affiliate-change-password',
  tokenLogin: API_URL + 'profile/token-login',
  provinces: API_URL + 'master-data/thai-province',
  generateFormLink: API_URL + 'crm/fairdee-quotation-form-link',
  quotationWithToken: API_URL + 'utils/token/fairdee-quotation',
  documents: API_URL + 'utils/token/fairdee-document',
  carWithToken: API_URL + 'utils/token/car/',
  driverWithToken: API_URL + 'utils/token/driver/',
  invoice: API_URL + 'crm/fairdee-invoice',
  invoices: API_URL + 'crm/fairdee-invoice-list',
  credit: API_URL + 'crm/credit',
  creditTransaction: API_URL + 'crm/credit-transaction',
  fairdeeAffiliateEmail: API_URL + 'crm/fairdee-email-affiliate',
  fairdeeInsurereEmail: API_URL + 'crm/fairdee-email-insurer',
  makeTestUser: API_URL + 'profile/mark-user-test',
  affiliateProfile: API_URL + 'crm/affiliate-profile',
  fairdeeSaleDashboard: API_URL + 'crm/fairdee-sale-dashboard',
  affiliateIncentive: API_URL + 'crm/affiliate-incentive',
  fairdeeSaleIncentive: API_URL + 'crm/fairdee-sale-incentive',
  referralIncentives: API_URL + 'crm/affiliate-referral-incentive',
  affiliateReferrals: API_URL + 'crm/affiliate-referral',
  saleEconomics: API_URL + 'crm/fairdee-sale-economics',
  compulsorySaleEconomics: API_URL + 'utils/compulsory-insurance-economics',
  coaDocument: API_URL + 'utils/fairdee-coa-document',
  affiliateSignup: API_URL + 'crm/affiliate-signup',
  signupV2: API_URL + 'crm/affiliate-signup-v2',
  getReferrerName: API_URL + 'profile/user/get-referrer-name',
  tasks: API_URL + 'crm/tasks',
  taskSummary: API_URL + 'crm/tasks-summary',
  taskBulkUpdate: API_URL + 'crm/tasks-bulk-complete',
  affiliateClassifiaction: API_URL + 'crm/affiliate-classification/',
  interactions: API_URL + 'crm/interactions',
  bulkTaskCreate: API_URL + 'crm/tasks-bulk-create',
  tags: API_URL + 'crm/user-tag',
  tagsGet: API_URL + 'crm/get-tags',
  policyDocument: API_URL + 'crm/policy-document',
  affiliateSaleSummary: API_URL + 'crm/affiliate-sale-summary',
  staffForTasks: API_URL + 'crm/staff-for-task',
  quotationShareLink: API_URL + 'crm/fairdee-quotation-share-link',
  quotationQueryWithToken: API_URL + 'utils/token/fairdee-quotation-query/',
  shortUrl: API_URL + 'utils/short-url',
  payment: API_URL + 'crm/fairdee-payment',
  brokerBankAccounts: API_URL + 'master-data/broker-bank-accounts',
  bankStatementFile: API_URL + 'billing/bank-statement-file',
  billingPayment: API_URL + 'billing/payment',
  refreshToken: API_URL + 'auth/token',
  intermediatePayments: API_URL + 'billing/intermediate-payment',
  instalmentTypes: API_URL + 'utils/fairdee-quotation-instalment-types',
  billingPaymentFile: API_URL + 'billing/payment-file',
  billingInvoices: API_URL + 'billing/invoice',
  billingInstalmentReceipt: API_URL + 'billing/instalment-receipt',
  intermediatePayments: API_URL + 'billing/intermediate-payment',
  invoiceDetail: API_URL + 'billing/invoice-detail',
  fairdeeIncentive: API_URL + 'crm/fairdee-manual-incentive-invoice',
  generateContract: API_URL + 'utils/generate-fairdee-contract',
  monitorySummary: API_URL + 'crm/affiliate-monetary-summary',
  referralIncentiveMonthly: API_URL + 'crm/affiliate-referral-incentive-monthly',
  affiliateGroupedCommission: API_URL + 'crm/affiliate-grouped-commission',
  generateSaleDocuments: API_URL + 'utils/fairdee-sale-documents',
  modifyPriceList: API_URL + 'master-data/modify-price-list',
  renewQuote: API_URL + 'utils/fairdee-renewal-quotation',
  affiliateGroupedCombined: API_URL + 'crm/affiliate-grouped-combined-payment',
  ip: API_URL + 'billing/intermediate-payment',
  paymentStatementFile: API_URL + 'billing/bulk-payment-statement-file',
  renewalPriceList: API_URL + 'master-data/upload-renewal-price-list',
  affiliateGroupedIncentive: API_URL + 'crm/affiliate-grouped-incentive',
  thaiQRRequest: API_URL + 'billing/thai-qr-request',
  tokenThaiQRRequest: API_URL + 'utils/token/thai-qr-request',
  soaDocument: API_URL + 'policy/soa-file',
  insurerCredits: API_URL + 'policy/insurer-credit',
  insurerCreditTxn: API_URL + 'policy/insurer-credit-transaction',
  policyPurchase: API_URL + 'crm/fairdee-policy-purchase',
  covidPlan: API_URL + 'master-data/covid-plan',
  covidForm: API_URL + 'utils/covid-form',
  covidDocument: API_URL + 'utils/covid-document',
  bankTransactions: API_URL + 'billing/bank-transactions',
  covidSaleDocuments: API_URL + 'utils/fairdee-covid-sale-documents',
  uploadMTICovidPolicies: API_URL + 'utils/upload-mti-covid-policies',
  uploadVIBCovidPolicies: API_URL + 'utils/upload-vib-covid-policies',
  uploadVMITrackingIds: API_URL + 'master-data/update-vmi-policy-postal-tracking-id/',
  fcmToken: API_URL + 'communicator/firebase-registration-token',
  nestedAffiliate: API_URL + 'profile/nested-affiliate',
  leadGenCode: API_URL + 'profile/lead-gen-code',
  vendors: API_URL + 'master-data/vendor',
  instalmentInvoices: API_URL + 'crm/instalment-invoice',
  vendorKyc: API_URL + 'policy/vendor-kyc',
  instalmentIPs: API_URL + 'crm/instalment-intermediate-payment',
  vendorKycDocument: API_URL + 'policy/vendor-kyc-document',
  denguePlan: API_URL + 'master-data/dengue-plan',
  dengueForm: API_URL + 'utils/dengue-form',
  dengueDocument: API_URL + 'utils/dengue-document',
  dengueSaleDocuments: API_URL + 'utils/fairdee-dengue-sale-documents',
  microsite: API_URL + 'profile/microsite',
  paPlan: API_URL + 'master-data/pa-plan',
  paForm: API_URL + 'utils/pa-form',
  paDocument: API_URL + 'utils/pa-document',
  paSaleDocuments: API_URL + 'utils/fairdee-pa-sale-documents',
  paOccupation: API_URL + 'master-data/pa-occupation',
  userProfilePic: API_URL + 'profile/profile-pic',
  recreateCreditInvoice: API_URL + 'crm/recreate-credit-invoice',
  downloadInvoice: API_URL + 'crm/fairdee-invoice',
  endorsement: API_URL + 'policy/policy-endorsement',
  endorsementDocument: API_URL + 'policy/endorsement-document',
  downloadCombinedWhtCert: API_URL + 'utils/combined-wht-certificate',
  survey: API_URL + 'surveys/survey-form',
  surveyResponse: API_URL + 'surveys/survey-response',
  duplicateQuotation: API_URL + 'utils/duplicate-quotation',
  retryCMIPolicies: API_URL + 'utils/retry-cmi-policy-pdf',
  createCmiCombinedInvoice: API_URL + 'crm/create-cmi-combined-invoice',
  downloadCmiInvoiceReport: API_URL + 'billing/download-cmi-invoice-report',
  letterOfGuarantee: API_URL + 'master-data/letter-of-guarantee',
  renewCovidForm: API_URL + 'utils/renew-covid-form',
  affiliateNetPayable: API_URL + 'crm/affiliate-net-payable',
  combinedInvoice: API_URL + 'billing/combined-invoice',
  getCheapestPrice: API_URL + 'master-data/cheapest-price',
  covidFormRemark: API_URL + 'utils/covid-form-remark',
  subagentCredits: API_URL + 'crm/subagent-credit',
  subagentCommission: API_URL + 'crm/subagent-commission',
  subagents: API_URL + 'crm/subagent',
  subagentReconcile: API_URL + 'crm/subagent-reconcile',
  staffLeave: API_URL + 'profile/staff-leave-schedule',
  thaiQrInquiry: API_URL + 'billing/thai-qr-inquiry',
  priceListInstallmentType: API_URL + 'utils/fairdee-pricelist-instalment-types',
  tokenisedPriceListInstallmentType: API_URL + 'utils/token/fairdee-pricelist-instalment-types',
  bulkUpdateAffiliateStaffMap: API_URL + 'profile/bulk-update-affiliate-staff',
  syncOicData: API_URL + 'crm/sync-oic-data',
  resetOtpAttempt: API_URL + 'profile/request-otp',
  selectedDeletedPrice: API_URL + 'master-data/selected-deleted-price',
  refundInvoiceDryRun: API_URL + 'billing/refund-invoice-dry-run',
  uploadCsv: API_URL + 'communicator/call',
  staffTiming: API_URL + 'crm/staff-timing',
  staffTeam: API_URL + 'crm/staff-team',
  staffTeamMapping: API_URL + 'crm/staff-team-mapping',
  requestOtp: API_URL + 'profile/request-otp',
  otpSignup: API_URL + 'profile/signup-via-otp',
  updatePhoneNumber: API_URL + 'profile/user-phone-update-email',
  verifyPhoneViaOtp: API_URL + 'profile/update-phone',
  emailVerification: API_URL + 'profile/email-verification',
  fetchInstantPolicyPdf: API_URL + 'utils/fetch-instant-policy-pdf',
  cmiPriceList: API_URL + 'master-data/cmi-price-list',
  entityHistoricalData: API_URL + 'utils/entity-historical-data',
  grade: API_URL + 'profile/grade',
  gradeSettings: API_URL + 'master-data/grade-settings',
  mlmTeam: API_URL + 'profile/mlm-team',
  affiliateDownlines: API_URL + 'profile/downline-affiliates',
  levelUpgradeRequest: API_URL + 'profile/level-upgrade-request',
  referrerAffiliate: API_URL + 'profile/referrer-affiliate',
  creditTopup: API_URL + 'crm/credit-topup',
  documentsBulkDownload: API_URL + 'utils/documents-bulk-download',
  affiliateCommissionReport: API_URL + 'profile/download-affiliate-commission-report',
  teamCommissionReport: API_URL + 'profile/download-affiliate-team-report',
  signupEmail: API_URL + 'profile/signup-via-email',
  endorsementCombinedInvoice: API_URL + 'crm/endorsement-combined-invoice',
  downloadAddress: API_URL + 'utils/fairdee-address-download',
  openUserDetails: API_URL + 'profile/open-user',
  systemSettings: API_URL + 'system-settings',
  instalmentTransferRefundDryRun: API_URL + 'billing/instalment-transfer-refund-dry-run',
  otpPinReset: API_URL + 'profile/resetpin-via-otp',
  uploadFile: API_URL + 'utils/upload-file',
  uploadOptions: API_URL + 'utils/upload-options',
  downloadSumInsured: API_URL + 'utils/download-suminsured',
  checkMultipleQuotation: API_URL + 'utils/check-multiple-quotation',
  affiliateTags: API_URL + 'crm/affiliate-tags',
  quotationRemark: API_URL + 'utils/fairdee-quotation-query-remark',
  uploadInsurerRequest: API_URL + 'utils/insurer-request-document',
  sendInsurerEmail: API_URL + 'utils/send-request-email-insurer',
  removeUploadedDocument: API_URL + 'utils/remove-failed-docs-request-to-insurer',
  invoiceRemark: API_URL + 'billing/invoice-remark',
  downloadCreditInvoice: API_URL + 'utils/download-credit-combined-invoice-csv',
  combinedCreditAccountingActions: API_URL + 'utils/combined-credit-accounting-actions',
  manualEkycTransfer: API_URL + 'policy/manual-ekyc-transfer',
  nonStandardProductList: API_URL + 'master-data/non-standard-insurance-product',
  nonStandardPolicy: API_URL + 'policy/non-standard-sale-data',
  uploadGenericDocument: API_URL + 'utils/generic-document',
  genericDocument: API_URL + 'utils/generic-document',
  manualBillingInvoice: API_URL + 'billing/manual-billing-invoice',
  vehicleCategory: API_URL + 'master-data/vehicle-category',
  sendCreditCombinedInvoice: API_URL + 'utils/send-credit-combined-invoice',
  createTask: API_URL + 'utils/create-async-task',
  cmiInsurerIg: API_URL + 'profile/affiliate-insurers',
  affiliateVehicleCategories: API_URL + 'master-data/affiliate-vehicle-category',
  massTaskReassignment: API_URL + 'crm/mass-task-reassignment',
  insuranceClass: API_URL + 'master-data/insurance-class',
  affiliateRelatedAccounts: API_URL + 'profile/get-affiliate-related-accounts',
  paymentStatementUpload: API_URL + 'billing/bank-transfer-statement-file',
  downloadFile: API_URL + 'utils/file-download',
  taskResults: API_URL + 'utils/task-results',
  supportStaff: API_URL + 'crm/staff',
  paymentStatementUpload: API_URL + 'billing/bank-transfer-statement-file',
  downloadFile: API_URL + 'utils/file-download',
  taskResults: API_URL + 'utils/task-results',
  deletePenaltyInvoice: API_URL + 'billing/remove-wrong-penalty-invoices',
  holidays: API_URL + 'master-data/holidays',
  changeReferrer: API_URL + 'utils/change-referrer',
  getCmiPremium: API_URL + 'master-data/get-cmi-premium-data',
  ocr: API_URL + 'utils/image-ocr',
  promotionBanner: API_URL + 'utils/upload-promotion-banner',
  getPromotionBanner: API_URL + 'utils/get-promotion-banner',
  changeStatusManualLead: API_URL + 'utils/manual-quotation/change-status',
  manualLeadRemark: API_URL + 'utils/manual-quotation/add-remarks',
  sendManualQuoteToInsurer: API_URL + 'utils/manual-quotations-email-to-insurer',
  updateEmail: API_URL + 'profile/update-user-email',
  billPaymentRequest: API_URL + 'billing/bill-payment-request',
  billPaymentRequests: API_URL + 'billing/bill-payment-requests',
  uploadDocumentManual: API_URL + 'utils/insurer-request-document',
  getVehicleCategoryManual: API_URL + 'master-data/get-vehicle-category',
  crmCredit: API_URL + 'crm/credit',
  processChatwootConversation: API_URL + 'utils/process-chatwoot-conversation',
  uploadDocumentManual: API_URL + 'utils/insurer-request-document',
  fairdeeQuotationReportGQL: API_URL + 'graphql',
  vehicleNumberSearch: API_URL + 'utils/vehicle-number-search',
  batchBankStatement: API_URL + 'billing/batch-bank-statement-file',
  uploadWHT: API_URL + 'billing/wht-certificate',
  eventCalendar: API_URL + 'utils/event-calendar',
  kbankReconcilation: API_URL + 'billing/reconcile-kbank',
  getBanners: API_URL + 'utils/get-banners',
  proxyRoute: API_URL + 'utils/proxy-route',
  formIOGetForm: 'https://api-staging.qoala.app/form-service/v1/forms',
  qoalaOrder: API_URL + 'utils/qoala-order',
  fileStorage: API_URL + 'utils/file-storage',
  updateSelfServe: API_URL + 'utils/update-self-serve',
  verifyClientPhone: API_URL + 'utils/self-inspection-number-check',
  falconStatus: API_URL + 'utils/refresh-falcon-inspection-case',
};

export default apiEndpoints;
