import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../actions/modal';

interface ComponentProps {
  close?: () => void;
  children: any;
}

const Component: React.FC<ComponentProps> = ({ close, children }) => {
  let component = React.cloneElement(children, { close });
  return <>{component}</>;
};

interface Props {
  close?: () => void;
  component: React.Component;
  className?: string;
  backgroundClick?: boolean | undefined;
  centerAlign: boolean | undefined;
}

const MobileModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  function handleBackgroundClick(e: any) {
    e.stopPropagation();
    if (props.backgroundClick) {
      close();
    }
  }

  function close() {
    if (props.close) {
      props.close();
    }
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  }

  return (
    <div
      className={'mobile-modal-wrapper ' + props.className + (props.centerAlign ? ' center-align' : '')}
      onClick={(e) => handleBackgroundClick(e)}
    >
      <div className="modal-content" id="modal" onClick={(e) => e.stopPropagation()}>
        <Component close={close}>{props.component}</Component>
      </div>
    </div>
  );
};

export default MobileModal;
