import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler } from '../../utils/helper';

function UploadSubagentReconciliationFile(props) {
  const [reconciliationFiles, setReconciliationFiles] = useState([]);
  const [flow, setFlow] = useState('topup');
  const [errors, setErrors] = useState({});
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [apiParams, setApiParams] = useState({});
  const [masterCreditsDeducted, setMasterCreditsDeducted] = useState(0);
  const [subAgentCreditsAdded, setSubAgentCreditsAdded] = useState(0);
  const [totalSalesReconciled, setTotalSalesReconciled] = useState(0);
  const [totalSalesLeftToReconcile, setTotalSalesLeftToReconcile] = useState(0);

  const languageMap = useSelector((state) => state.languageMap);
  const messages = languageMap.messages;
  const textMap = languageMap.components.subagentReconcile;

  function close() {
    props.hideModal(null, { ...props });
  }

  function handlePaymentChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    setPaymentAmount(e.target.value);
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function validate() {
    let validationErrors = {};

    if (!paymentAmount || paymentAmount < 0) {
      validationErrors.paymentAmount = messages.required;
    }

    setErrors(validationErrors);
    return validationErrors;
  }

  function topup() {
    const errors = validate();
    if (Object.keys(errors).length) {
      return;
    }

    setApiLoading(true);
    api.crm
      .subagentTopup({
        sub_affiliate: props.sub_affiliate,
        amount: paymentAmount,
      })
      .then((res) => {
        setApiLoading(false);
        setFlow('summary');
        setMasterCreditsDeducted(res.master_credits_deducted);
        setSubAgentCreditsAdded(res.sub_affiliate_credits_added);
        setTotalSalesReconciled(res.total_sales_reconciled);
        setTotalSalesLeftToReconcile(res.total_sales_left_to_reconcile);
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      {
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={close} />
          <div className="mobile-header">
            <p className="current-page" onClick={close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{textMap.uploadFile}</span>
            </p>
          </div>
          {flow === 'summary' && (
            <>
              <h1 className="title-text">
                {totalSalesReconciled !== 0 ? (
                  <div className="padded-title-text">
                    {textMap.successfulCredit}
                    <div className="center closeButton">
                      <button className="button medium" onClick={close}>
                        {textMap.closeButton}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>{textMap.noSalesLeftToReconcile}</>
                )}
              </h1>
            </>
          )}
          {flow === 'topup' && (
            <>
              <h1 className="title-text">{textMap.topup}</h1>
              <div className="form-wrapper">
                <div className="form-field">
                  <label htmlFor="leadgen_code">{textMap.enterTopupAmount}</label>
                  <Input
                    name="leadgen_code"
                    onChange={handlePaymentChange}
                    className={errors.paymentAmount ? 'error' : ''}
                    value={paymentAmount}
                  />
                  {errors.paymentAmount && <InlineError text={errors.paymentAmount} />}
                </div>
              </div>
              <div className="button-wrap">
                <button
                  className="button medium orange-bordered-button"
                  onClick={() => setFlow('')}
                  disabled={apiLoading}
                >
                  Cancel
                </button>
                <button className="button medium" onClick={topup} disabled={apiLoading}>
                  {apiLoading ? <CircularProgress size={16} color="inherit" /> : <>{textMap.topup}</>}
                </button>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
}

export default connect(null, { hideModal, showNotification, showModal })(UploadSubagentReconciliationFile);
