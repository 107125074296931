import React from 'react';
import cross from '../../images/cross.svg';
import { formatDateTime, toFixedNumber } from '../../utils/helper';

function DeleteIPModal(props) {
  function close() {
    props.close(null, { ...props });
  }

  function accept() {
    close();
    props.action();
  }

  return (
    <div className="fairdee-modal-wrapper small-medium message">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <h1 className="title-text">Delete Intermediate Payments?</h1>
        <div>
          <p>Some of the agents already have associated IPs. Click accept to delete them and proceed</p>
        </div>
        <div className="crm-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Agent Code</th>
                <th>Agent Full Name</th>
                <th>Created At</th>
                <th>Amount Paid</th>
              </tr>
            </thead>
            <tbody>
              {props.already_created_ips.map((ipObj, i) =>
                ipObj.ips.map((ip, j) => (
                  <tr key={`${i}_${j}`}>
                    <td>{ipObj.affiliate.agent_code}</td>
                    <td>{ipObj.affiliate.user.fullname}</td>
                    <td>{formatDateTime(ip.created_at)}</td>
                    <td>{toFixedNumber(ip.amount_paid, 2)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="button-wrap">
          <button className="button medium orange-bordered-button" onClick={close}>
            No
          </button>
          <button className="button medium" onClick={accept}>
            Accept And Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteIPModal;
