import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Checkbox, Dropdown, Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import { driverAgeGroup } from '../../commonjs/commonData';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler, isMobileNumber, setLanguageToElement, validateVehicleNumber } from '../../utils/helper';
import { Mixpanel } from '../../utils/tracking';

const DISCOUNT_TYPES = [
  {
    text: 'บาท',
    key: 'flat_rate',
    value: 'flat_rate',
  },
  {
    text: '%',
    key: 'percentage',
    value: 'percentage',
  },
];
let isInstalment = false;

class CustomerInfoModal extends React.Component {
  constructor(props) {
    super(props);
    console.log('props', props);
    this.state = {
      data: {
        client_first_name: props.customerMeta.first_name || '',
        client_last_name: props.customerMeta.last_name || '',
        client_phone: props.customerMeta.phone || '',
        youngest_driver_age_range: null,
        needs_compulsory_insurancee: false,
        avail_camera_discount: false,
        avail_driver_discount: false,
        affiliate_to_customer_discount_type: 'flat_rate',
        affiliate_to_customer_discount: '',
        for_commercial_vehicle: '',
        vehicle_number: this.fetchVehicleNumber(props),
      },
      updatedData: {
        affiliate_to_customer_discount_type: 'flat_rate',
        vehicle_number: this.fetchVehicleNumber(props),
      },
      errors: {},
      loading: false,
      insurers: [],
    };
  }

  componentDidMount() {
    isInstalment = this.props.isInstalment;
    setLanguageToElement();
    let id = this.props.quotationQueryId;
    if (id) {
      this.getQuotationQuery(id);
    }
  }

  fetchVehicleNumber = (data) => {
    if (data.customerMeta?.vehicle_number) {
      return data.customerMeta.vehicle_number;
    }
    if (data.hasOcrData?.vehicle_license_number) {
      return data.hasOcrData.vehicle_license_number.replace(' ', '');
    }
    if (data.vehicle_number) {
      return data.vehicle_number;
    }
    return '';
  }

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;
    if (data.client_phone && !isMobileNumber(data.client_phone)) {
      errors.client_phone = languageMap.phone;
    }

    let discountInputValue = parseFloat(data.affiliate_to_customer_discount),
      maxDiscountValue = Infinity,
      discountSymbol;

    if (discountInputValue) {
      this.props.compareCards.forEach((element) => {
        let grossPremium = parseFloat(element.gross_premium),
          affiliateCommission = parseFloat(element.affiliate_commission);

        if (data.affiliate_to_customer_discount_type === 'percentage') {
          maxDiscountValue = Math.min(maxDiscountValue, ((0.97 * 100 * affiliateCommission) / grossPremium).toFixed(2));
          discountSymbol = '%';
        } else if (data.affiliate_to_customer_discount_type === 'flat_rate') {
          maxDiscountValue = Math.min(maxDiscountValue, (0.97 * affiliateCommission).toFixed(4));
          maxDiscountValue = Math.floor(maxDiscountValue);
          discountSymbol = languageMap.baht;
        }
      });

      if (discountInputValue > maxDiscountValue) {
        errors.affiliate_to_customer_discount = `${languageMap.maxDiscount} ${maxDiscountValue} ${discountSymbol}`;
      }
    }
    if (!data.vehicle_number && !this.state.data.vehicle_number) {
      errors.vehicle_number = languageMap.required;
    }

    if (
      data.vehicle_number &&
      data.vehicle_number !== 'ป้ายแดง'
    ) {
      const validationResult = validateVehicleNumber(data.vehicle_number);
      if (validationResult !== true) {
        errors.vehicle_number = languageMap[validationResult];
      }
    }

    return errors;
  };

  onChange = (e) => {
    window.console.log(e.target.name, e.target.value);
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      updatedData: { ...this.state.updatedData, [e.target.name]: e.target.value },
    });
  };

  onCheckboxChange = (e, data) => {
    window.console.log(data.name, data.checked);
    this.setState(
      {
        data: { ...this.state.data, [data.name]: data.checked },
        updatedData: { ...this.state.updatedData, [data.name]: data.checked },
      },
      (_) => {
        window.console.log(this.state.updatedData);
      }
    );
    if (data.name === 'avail_driver_discount' && !data.checked) {
      this.setState({
        data: { ...this.state.data, youngest_driver_age_range: null },
        updatedData: { ...this.state.updatedData, youngest_driver_age_range: null },
      });
    }
  };

  handleDropdownChange = (e, data) => {
    window.console.log(data);
    this.setState(
      {
        data: { ...this.state.data, [data.name]: data.value },
        updatedData: { ...this.state.updatedData, [data.name]: data.value },
      },
      () => {
        window.console.log(this.state);
      }
    );
  };

  close = (redirect) => {
    Mixpanel.track('Get Quotes', {
      object: 'Customer Details Skipped',
      action: 'Clicked',
    });

    if (redirect) {
      this.props.patchCustomerInfo({ is_lead_generated: true });
    }
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  getQuotationQuery = (id) => {
    api.utils
      .getQuotationQuery(id)
      .then((resp) => {
        window.console.log(resp.message);
        let obj = {};
        if (resp.message.client_first_name) {
          obj.client_first_name = resp.message.client_first_name || '';
        }
        if (resp.message.client_last_name) {
          obj.client_last_name = resp.message.client_last_name || '';
        }
        if (resp.message.client_phone) {
          obj.client_phone = resp.message.client_phone || '';
        }
        if (resp.message.vehicle_number) {
          obj.vehicle_number = resp.message.vehicle_number || '';
        }
        obj.avail_camera_discount = resp.message.avail_camera_discount || false;
        obj.avail_driver_discount = resp.message.avail_driver_discount || false;
        obj.needs_compulsory_insurance = resp.message.needs_compulsory_insurance || false;
        obj.affiliate_to_customer_discount_type = resp.message.affiliate_to_customer_discount_type || 'flat_rate';

        if (obj.affiliate_to_customer_discount_type == 'percentage') {
          obj.affiliate_to_customer_discount = (parseFloat(resp.message.affiliate_to_customer_discount) * 100).toFixed(
            4
          );
        } else {
          obj.affiliate_to_customer_discount = resp.message.affiliate_to_customer_discount || '';
        }

        this.setState({
          data: { ...this.state.data, ...obj },
          lg_code: resp.message.lead_gen_code,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  updateParent = (params) => {
    this.props.patchCustomerInfo(params);
    this.close();
  };

  patchQuotationQuery = (params) => {
    api.utils
      .patchQuotationQuery(this.props.quotationQueryId, params)
      .then((resp) => {
        window.console.log(resp);
        this.updateParent(params);
      })
      .catch((error) => {
        this.setState({
          errors: { ...this.state.errors, ...errorHandler(error.response, true) },
        });
      });
  };

  handleChange = (name) => (event) => {
    let value = null;
    if (name === 'for_commercial_vehicle') {
      value = event.target.value === 'true';
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  submit = () => {
    let id = this.props.quotationQueryId;
    let data = Object.assign({}, this.state.updatedData);
    let errors = this.validate(data);
    this.setState({
      errors,
    });
    window.console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }
    if (!Object.keys(data).length) {
      this.props.patchCustomerInfo({ is_lead_generated: true });
      this.close();
      return;
    }
    if (!id) {
      return;
    }
    if (data.youngest_driver_age_range === null) {
      delete data.youngest_driver_age_range;
    }
    data.is_lead_generated = true;
    if (data.affiliate_to_customer_discount && data.affiliate_to_customer_discount_type === 'percentage') {
      data.affiliate_to_customer_discount = (parseFloat(data.affiliate_to_customer_discount) / 100).toFixed(4);
    }
    if (!data.affiliate_to_customer_discount) {
      delete data.affiliate_to_customer_discount;
      delete data.affiliate_to_customer_discount_type;
    }
    this.patchQuotationQuery(data);
    Mixpanel.track('Get Quotes', {
      object: 'Customer Details Added',
      action: 'Clicked',
    });

    // this.props.patchCustomerInfo(data);
    // this.close();
  };

  render() {
    const { data, errors } = this.state;
    const languageMap = this.props.languageMap.components.customerInfo;
    const { driverDiscountEligible, cameraDiscountEligible, compulsaryInsuranceCost } = this.props;
    console.log('>>>', data);
    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <p className="text">{languageMap.textTwo}</p>
          <Form noValidate className="quotation-form">
            <Form.Field>
              <input
                type="text"
                id="client_first_name"
                name="client_first_name"
                placeholder={languageMap.textThree}
                value={data.client_first_name}
                onChange={this.onChange}
              />
              {errors.client_first_name && <InlineError text={errors.client_first_name} />}
              <label className="form-label" htmlFor="client_first_name">
                {languageMap.textThree}
              </label>
            </Form.Field>
            <Form.Field>
              <input
                type="text"
                id="client_last_name"
                name="client_last_name"
                placeholder={languageMap.textFifteen}
                value={data.client_last_name}
                onChange={this.onChange}
              />
              {errors.client_last_name && <InlineError text={errors.client_last_name} />}
              <label className="form-label" htmlFor="client_last_name">
                {languageMap.textFifteen}
              </label>
            </Form.Field>
            <Form.Field>
              <input
                type="number"
                id="phone"
                name="client_phone"
                placeholder={languageMap.textFour}
                value={data.client_phone}
                onChange={this.onChange}
              />
              {errors.client_phone && <InlineError text={errors.client_phone} />}
              <label className="form-label" htmlFor="phone">
                {languageMap.textFour}
              </label>
            </Form.Field>
            <Form.Field>
              <input
                type="text"
                id="vehicle_number"
                name="vehicle_number"
                placeholder={`${languageMap.carLicense}*`}
                value={data.vehicle_number}
                onChange={this.onChange}
                disabled={this.props.isRenewalFlow}
              />
              {errors.vehicle_number && <InlineError text={errors.vehicle_number} />}
              <label className="form-label" htmlFor="vehicle_number">
                {languageMap.carLicense}
              </label>
            </Form.Field>
            {!isInstalment && !this.state.lg_code ? (
              <div className="inline-fields">
                <Form.Field>
                  <input
                    type="number"
                    id="affiliate_to_customer_discount"
                    name="affiliate_to_customer_discount"
                    placeholder={languageMap.textEighteen}
                    value={data.affiliate_to_customer_discount}
                    onChange={this.onChange}
                  />
                  {errors.affiliate_to_customer_discount && (
                    <InlineError text={errors.affiliate_to_customer_discount} />
                  )}
                  <label className="form-label" htmlFor="affiliate_to_customer_discount">
                    {languageMap.textEighteen}
                  </label>
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    placeholder={languageMap.textSeventeen}
                    name="affiliate_to_customer_discount_type"
                    options={DISCOUNT_TYPES}
                    onChange={this.handleDropdownChange}
                    selection
                    value={data.affiliate_to_customer_discount_type}
                  />
                </Form.Field>
              </div>
            ) : null}
            {cameraDiscountEligible || driverDiscountEligible ? (
              <p className="blue-label">{languageMap.textSix}</p>
            ) : null}
            <div className="form-element-wrap">
              <label htmlFor="for_commercial_vehicle" className="form-label">
                {languageMap.for_commercial_vehicle}
              </label>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.for_commercial_vehicle === true}
                      onChange={this.handleChange('for_commercial_vehicle')}
                      value={true}
                      name="for_commercial_vehicle"
                    />
                  }
                  label={languageMap.yes}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.for_commercial_vehicle === false}
                      onChange={this.handleChange('for_commercial_vehicle')}
                      value={false}
                      name="for_commercial_vehicle"
                    />
                  }
                  label={languageMap.no}
                />
              </FormGroup>
              <br />
            </div>
            <div className="checkbox-wrap">
              {/* {
                this.props.type === 'bike' ? null : <Form.Field>
                  <Checkbox label={languageMap.textSeven} name="needs_compulsory_insurance" checked={data.needs_compulsory_insurance} onChange={this.onCheckboxChange} />
                  <span className="helper-text">{compulsaryInsuranceCost} {languageMap.textTen}</span>
                </Form.Field>
              } */}
              {cameraDiscountEligible && (
                <Form.Field>
                  <Checkbox
                    label={languageMap.textEight}
                    name="avail_camera_discount"
                    checked={data.avail_camera_discount}
                    onChange={this.onCheckboxChange}
                  />
                  <span className="helper-text">(-5%)</span>
                </Form.Field>
              )}
              {driverDiscountEligible && (
                <Form.Field>
                  <Checkbox
                    label={languageMap.textNine}
                    name="avail_driver_discount"
                    checked={data.avail_driver_discount}
                    onChange={this.onCheckboxChange}
                  />
                  <span className="helper-text">(5% - 20%)</span>
                </Form.Field>
              )}
              {data.avail_driver_discount && (
                <div className="form-field mt-8">
                  <p>{languageMap.textSixteen}</p>
                  <Dropdown
                    placeholder="Select age group"
                    selection
                    options={driverAgeGroup}
                    onChange={this.handleDropdownChange}
                    value={data.youngest_driver_age_range}
                    name="youngest_driver_age_range"
                  />
                </div>
              )}
            </div>
            <div className="buttons-wrap center-aligned">
              <button className="button medium" onClick={this.submit}>
                {languageMap.textThirteen}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

CustomerInfoModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  quotationQueryId: PropTypes.number.isRequired,
  patchCustomerInfo: PropTypes.func.isRequired,
  compulsaryInsuranceCost: PropTypes.number,
  driverDiscountEligible: PropTypes.bool.isRequired,
  cameraDiscountEligible: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(CustomerInfoModal));
