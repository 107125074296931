import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { notify } from '../../../utils/helper';

interface Props {
  action?: (name: string, val: any) => void;
  btnType?: 'button' | 'text' | 'outlined';
  name: string;
  label: string;
  preTxt?: string;
  loading?: boolean;
  disableBtn?: boolean;
  className?: string;
  txtBtnClass?: string;
}

const RecaptchaButton: React.FC<Props> = ({ action, btnType, name, label, preTxt, loading, disableBtn, className, txtBtnClass }) => {
  const { executeRecaptcha } = useGoogleReCaptcha(),
    handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      // doVerify is custom action
      const token = await executeRecaptcha('doVerify');
      // Do whatever you want with the token

      if (token) {
        action && action(name, token);
        // handleNext(headers);
      } else {
        notify("You don't have permission", 'error');
      }
    }, [executeRecaptcha]);

  function outlinedBtn() {
    return (
      <Button className="orange-bordered-button medium-text button-two" onClick={handleReCaptchaVerify}>
        {label}
      </Button>
    );
  }
  function generalBtn() {
    return (
      <div className="buttons-wrapper">
        <Button
          className={
            (className ? `orange-button ${className}` : 'orange-button ') + (loading || disableBtn ? 'disabled' : '')
          }
          onClick={handleReCaptchaVerify}
        >
          {label}
        </Button>
      </div>
    );
  }
  function textBtn() {
    return (
      <div className={`link-wrap ${txtBtnClass ? txtBtnClass : ''}`}>
        <span className={className ? `text-link ${className}` : 'text-link'}>
          {preTxt}
          <span className="clicked-txt" onClick={handleReCaptchaVerify}> {label}</span>
        </span>{' '}
      </div>
    );
  }
  function renderedBtn() {
    if (btnType === 'button') {
      return generalBtn();
    } else if (btnType === 'outlined') {
      return outlinedBtn();
    } else {
      return textBtn();
    }
  }
  return <section className="recaptcha">{renderedBtn()}</section>;
};
export default RecaptchaButton;
