import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AfterSignupCarousel from '../../components/AfterSignupCarousel';
import DrawerView from '../../components/crm/DrawerView';
import Modal from '../../components/crm/Modal';
import NewModal from '../../components/crm/NewModal';
import MobileModal from '../../components/fairdee-x-plus/common/MobileModal';
import FairdeeLoader from '../../components/FairdeeLoader';
import MakeModelBike from '../../components/MakeModelBike';
import QuickViewInsurer from '../../components/QuickViewInsurer';
import RedirectionLoader from '../../components/RedirectionLoader';
import ActionSheet from '../../components/utils/ActionSheet';
import {
  ACTION_SHEET,
  CONFIRMATION_MODAL,
  MOBILE_MODAL,
  MODAL_TYPE_ACCESSORIES,
  MODAL_TYPE_ADD_CREDIT,
  MODAL_TYPE_ADD_EMAIL,
  MODAL_TYPE_AFFILIATE_ADDITIONAL_INFO,
  MODAL_TYPE_AFFILIATE_CONDITION,
  MODAL_TYPE_AFFILIATE_COVERNOTE,
  MODAL_TYPE_AFFILIATE_EDIT,
  MODAL_TYPE_AFFILIATE_EXTRA_INFO,
  MODAL_TYPE_AFFILIATE_INFO,
  MODAL_TYPE_AFFILIATE_LEADGEN_GENERATE,
  MODAL_TYPE_AFTER_SIGNUP_CAROUSEL,
  MODAL_TYPE_ATTACH_LEADGEN,
  MODAL_TYPE_BANK_STATEMENT_UPLOAD,
  MODAL_TYPE_CHANGE_PASSWORD,
  MODAL_TYPE_COA_FORM,
  MODAL_TYPE_COMPULSORY_INVOICE,
  MODAL_TYPE_CORONA_BANNER,
  MODAL_TYPE_CORONA_CARDS,
  MODAL_TYPE_CORONA_RENEWAL_CARDS,
  MODAL_TYPE_COVERNOTE_DOWNLOAD,
  MODAL_TYPE_COVERNOTE_FORM,
  MODAL_TYPE_COVID_CANCELLATION,
  MODAL_TYPE_CRM_MODAL,
  MODAL_TYPE_CUSTOMER_INFO,
  MODAL_TYPE_DELETE,
  MODAL_TYPE_DELETE_IP,
  MODAL_TYPE_DENGUE_BANNER,
  MODAL_TYPE_DENGUE_CARDS,
  MODAL_TYPE_DOCUMENTS,
  MODAL_TYPE_DOCUMENTS_UPLOAD,
  MODAL_TYPE_DOWNLOAD_DOCUMENTS,
  MODAL_TYPE_DRAWER_VIEW,
  MODAL_TYPE_EDIT_CREDIT,
  MODAL_TYPE_EVENT_FORM,
  MODAL_TYPE_FAIRDEE_LOADER,
  MODAL_TYPE_FAIRDEE_SALE_INCENTIVES,
  MODAL_TYPE_FD_TO_VENDOR_IP,
  MODAL_TYPE_FILE_VIEWER,
  MODAL_TYPE_FORGOT_PASSWORD,
  MODAL_TYPE_IMPORTED_SALE_DETAILS,
  MODAL_TYPE_INCENTIVE_INVOICE,
  MODAL_TYPE_INTERMEDIATE_PAYMENT,
  MODAL_TYPE_INVOICE,
  MODAL_TYPE_INVOICE_DETAIL,
  MODAL_TYPE_LEADGEN_CODE,
  MODAL_TYPE_LICENSEANDID_UPDATE,
  MODAL_TYPE_LOGIN,
  MODAL_TYPE_MAKE_MODEL,
  MODAL_TYPE_MAKE_MODEL_BIKE,
  MODAL_TYPE_MANUAL_QUOTE,
  MODAL_TYPE_MESSAGE,
  MODAL_TYPE_NON_MOTOR_ORDER_STATUS,
  MODAL_TYPE_PAYMENT,
  MODAL_TYPE_PA_BANNER,
  MODAL_TYPE_PA_CARDS,
  MODAL_TYPE_POLICY,
  MODAL_TYPE_POLICY_NON_MOTOR,
  MODAL_TYPE_PROVINCE,
  MODAL_TYPE_QR_PAYMENT,
  MODAL_TYPE_QUICKVIEWINSURER,
  MODAL_TYPE_QUOTATION_CONFIRMATION,
  MODAL_TYPE_QUOTATION_DOCUMENT,
  MODAL_TYPE_RECORD_CIF_SALE,
  MODAL_TYPE_RECORD_ENDORSEMENT,
  MODAL_TYPE_RECORD_PAYMENT,
  MODAL_TYPE_REDIRECTION_LOADER,
  MODAL_TYPE_REFER_EARN,
  MODAL_TYPE_REMARK,
  MODAL_TYPE_REMARKS,
  MODAL_TYPE_RENEWED_QUOTATION,
  MODAL_TYPE_RESET_PASSWORD,
  MODAL_TYPE_SALE_DOCUMENTS,
  MODAL_TYPE_SALE_TRACK,
  MODAL_TYPE_SELECT_INSURER,
  MODAL_TYPE_SELECT_VENDOR,
  MODAL_TYPE_SHARE_DETAILS,
  MODAL_TYPE_SIGNUP,
  MODAL_TYPE_SUBAGENT,
  MODAL_TYPE_THANKYOU,
  MODAL_TYPE_TOPUP_PREPAID_CREDIT,
  MODAL_TYPE_TRACKING,
  MODAL_TYPE_UPDATE_INSURER_CREDITS,
  MODAL_TYPE_UPLOAD_SOA,
  MODAL_TYPE_UPLOAD_SUBAGENT_RECONCILIATION,
  MODAL_TYPE_USER_ADDRESS,
  MODAL_TYPE_VIDEO_PLAYER,
  MODAL_TYPE_WIZARD_THANKYOU,
  NEW_MODAL,
  SUM_INSURED_MODAL,
  WIZARD_STEP_ONE_MODAL,
  YOUTUBE_MODAL,
} from '../../constants/types';
import AccessoriesModal from '../modals/AccessoriesModal';
import AddCredit from '../modals/AddCredit';
import AffiliateAdditionalInfoModal from '../modals/AffiliateAdditionalInfoModal';
import AffiliateCodeGenerateModal from '../modals/AffiliateCodeGenerateModal';
import AffiliateConditionsModal from '../modals/AffiliateConditionsModal';
import AffiliateCoverNote from '../modals/AffiliateCoverNote';
import AffiliateExtraInfoModal from '../modals/AffiliateExtraInfoModal';
import AffiliateInfoModal from '../modals/AffiliateInfoModal';
import AttachLeadgenAccount from '../modals/AttachLeadgenAccount';
import BankStatementUploadModal from '../modals/BankStatementUploadModal';
import ChangePassword from '../modals/ChangePassword';
import CoaForm from '../modals/CoaForm';
import CompulsoryInvoiceModal from '../modals/CompulsoryInvoiceModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import CoronaBannerModal from '../modals/CoronaBannerModal';
import CoronaCardsModal from '../modals/CoronaCardsModal';
import CoronaRenewalCardsModal from '../modals/CoronaRenewalCardsModal';
import CovernoteForm from '../modals/CovernoteForm';
import CovidCancellationModal from '../modals/CovidCancellationModal';
import CustomerInfoModal from '../modals/CustomerInfoModal';
import DeleteActionModal from '../modals/DeleteActionModal';
import DeleteIPModal from '../modals/DeleteIPModal';
import DengueBannerModal from '../modals/DengueBannerModal';
import DengueCardsModal from '../modals/DengueCardsModal';
import DocumentsModal from '../modals/DocumentsModal';
import DownloadCovernoteModal from '../modals/DownloadCovernoteModal';
import DownloadDocumentsModal from '../modals/DownloadDocumentsModal';
import EditAffiliateModal from '../modals/EditAffiliateModal';
import EmailModal from '../modals/EmailModal';
import EndorsementModal from '../modals/EndorsementModal';
import EventFormModal from '../modals/EventFormModal';
import FDToVendorIPModal from '../modals/FDToVendorIPModal';
import FileViewerModal from '../modals/FileViewerModal';
import ForgotPassword from '../modals/ForgotPassword';
import GenerateSaleDocModal from '../modals/GenerateSaleDocModal';
import ImportedSaleDetailsModal from '../modals/ImportedSaleDetailsModal';
import IncentiveInvoiceUpdateModal from '../modals/IncentiveInvoiceUpdateModal';
import IncentivesModal from '../modals/IncentivesModal';
import IntermediatePaymentModal from '../modals/IntermediatePaymentModal';
import InvoiceDeatailModal from '../modals/InvoiceDeatailModal';
import InvoiceModal from '../modals/InvoiceModal';
import LeadGenCodeModal from '../modals/LeadGenCodeModal';
import LicenseAndIDUpdate from '../modals/LicenseAndIDUpdate.js';
import Login from '../modals/Login';
import MakeModel from '../modals/MakeModel';
import MakePaymentModal from '../modals/MakePaymentModal';
import ManualQuoteForm from '../modals/ManualQuoteForm';
import MessageModal from '../modals/MessageModal';
import NonMotorOrderStatusModal from '../modals/NonMotorOrderStatusModal';
import PABannerModal from '../modals/PABannerModal';
import PACardsModal from '../modals/PACardsModal';
import PolicyModal from '../modals/PolicyModal';
import PolicyModalNonMotor from '../modals/PolicyModalNonMotor';
import ProvinceModal from '../modals/ProvinceModal';
import QRPaymentModal from '../modals/QRPaymentModal';
import QuotationConfirmationModal from '../modals/QuotationConfirmationModal';
import QuotationDocumentModal from '../modals/QuotationDocumentModal';
import RecordCifModal from '../modals/RecordCifModal';
import RecordPaymentsModal from '../modals/RecordPaymentsModal';
import ReferEarnModal from '../modals/ReferEarnModal';
import RemarkModal from '../modals/RemarkModal';
import RemarksModal from '../modals/RemarksModal';
import RenewedQuotationModal from '../modals/RenewedQuotationModal';
import ResetPassword from '../modals/ResetPassword';
import SaleTrackModal from '../modals/SaleTrackModal';
import SelectInsurerModal from '../modals/SelectInsurerModal';
import SelectVendorModal from '../modals/SelectVendorModal';
import ShareDetails from '../modals/ShareDetails';
import Signup from '../modals/Signup';
import SubagentModal from '../modals/SubagentModal';
import SuminsuredModal from '../modals/SuminsuredModal';
import ThankYouModal from '../modals/ThankYouModal';
import TopUpPrepaidCreditModal from '../modals/TopUpPrepaidCreditModal';
import TrackingModal from '../modals/TrackingModal';
import UpdateCredit from '../modals/UpdateCredit';
import UpdateInsurerCreditsModal from '../modals/UpdateInsurerCreditsModal';
import UploadDocumentsModal from '../modals/UploadDocumentsModal';
import UploadSOAModal from '../modals/UploadSOAModal';
import UploadSubagentReconciliationFile from '../modals/UploadSubagentReconciliationFile';
import UserAddressModal from '../modals/UserAddressModal';
import VideoPlayerModal from '../modals/VideoPlayerModal';
import WizardStepOneModal from '../modals/WizardStepOneModal';
import WizardThankyouModal from '../modals/WizardThankyouModal';
import YoutubeModal from '../modals/YoutubeModal';

const modalComponents = {
  [MODAL_TYPE_QUICKVIEWINSURER]: QuickViewInsurer,
  [MODAL_TYPE_SHARE_DETAILS]: ShareDetails,
  [MODAL_TYPE_FAIRDEE_LOADER]: FairdeeLoader,
  [MODAL_TYPE_REDIRECTION_LOADER]: RedirectionLoader,
  [MODAL_TYPE_MAKE_MODEL]: MakeModel,
  [WIZARD_STEP_ONE_MODAL]: WizardStepOneModal,
  [SUM_INSURED_MODAL]: SuminsuredModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [MODAL_TYPE_AFFILIATE_EXTRA_INFO]: AffiliateExtraInfoModal,
  [MODAL_TYPE_QUOTATION_DOCUMENT]: QuotationDocumentModal,
  [MODAL_TYPE_WIZARD_THANKYOU]: WizardThankyouModal,
  [MODAL_TYPE_MANUAL_QUOTE]: ManualQuoteForm,
  [MODAL_TYPE_COVERNOTE_FORM]: CovernoteForm,
  [MODAL_TYPE_SALE_TRACK]: SaleTrackModal,
  [MODAL_TYPE_CUSTOMER_INFO]: CustomerInfoModal,
  [MODAL_TYPE_USER_ADDRESS]: UserAddressModal,
  [MODAL_TYPE_REMARK]: RemarkModal,
  [MODAL_TYPE_TRACKING]: TrackingModal,
  [MODAL_TYPE_PAYMENT]: MakePaymentModal,
  [MODAL_TYPE_COVERNOTE_DOWNLOAD]: DownloadCovernoteModal,
  [MODAL_TYPE_REMARKS]: RemarksModal,
  [MODAL_TYPE_AFFILIATE_COVERNOTE]: AffiliateCoverNote,
  [MODAL_TYPE_DELETE]: DeleteActionModal,
  [MODAL_TYPE_POLICY]: PolicyModal,
  [MODAL_TYPE_POLICY_NON_MOTOR]: PolicyModalNonMotor,
  [MODAL_TYPE_CHANGE_PASSWORD]: ChangePassword,
  [MODAL_TYPE_FORGOT_PASSWORD]: ForgotPassword,
  [MODAL_TYPE_RESET_PASSWORD]: ResetPassword,
  [MODAL_TYPE_QUOTATION_CONFIRMATION]: QuotationConfirmationModal,
  [MODAL_TYPE_PROVINCE]: ProvinceModal,
  [MODAL_TYPE_INVOICE]: InvoiceModal,
  [MODAL_TYPE_ADD_CREDIT]: AddCredit,
  [MODAL_TYPE_EDIT_CREDIT]: UpdateCredit,
  [MODAL_TYPE_IMPORTED_SALE_DETAILS]: ImportedSaleDetailsModal,
  [MODAL_TYPE_MESSAGE]: MessageModal,
  [MODAL_TYPE_AFFILIATE_CONDITION]: AffiliateConditionsModal,
  [MODAL_TYPE_COMPULSORY_INVOICE]: CompulsoryInvoiceModal,
  [MODAL_TYPE_ADD_EMAIL]: EmailModal,
  [MODAL_TYPE_REFER_EARN]: ReferEarnModal,
  [MODAL_TYPE_FAIRDEE_SALE_INCENTIVES]: IncentivesModal,
  [MODAL_TYPE_MAKE_MODEL_BIKE]: MakeModelBike,
  [MODAL_TYPE_COA_FORM]: CoaForm,
  [MODAL_TYPE_ACCESSORIES]: AccessoriesModal,
  [MODAL_TYPE_THANKYOU]: ThankYouModal,
  [MODAL_TYPE_LOGIN]: Login,
  [MODAL_TYPE_SIGNUP]: Signup,
  [MODAL_TYPE_AFFILIATE_ADDITIONAL_INFO]: AffiliateAdditionalInfoModal,
  [MODAL_TYPE_DOCUMENTS]: DocumentsModal,
  [MODAL_TYPE_SELECT_INSURER]: SelectInsurerModal,
  [MODAL_TYPE_AFFILIATE_EDIT]: EditAffiliateModal,
  [MODAL_TYPE_DRAWER_VIEW]: DrawerView,
  [MODAL_TYPE_CRM_MODAL]: Modal,
  [MODAL_TYPE_VIDEO_PLAYER]: VideoPlayerModal,
  [MODAL_TYPE_DOCUMENTS_UPLOAD]: UploadDocumentsModal,
  [MODAL_TYPE_FILE_VIEWER]: FileViewerModal,
  [MODAL_TYPE_AFFILIATE_INFO]: AffiliateInfoModal,
  [YOUTUBE_MODAL]: YoutubeModal,
  [MODAL_TYPE_LICENSEANDID_UPDATE]: LicenseAndIDUpdate,
  [MODAL_TYPE_BANK_STATEMENT_UPLOAD]: BankStatementUploadModal,
  [MODAL_TYPE_INTERMEDIATE_PAYMENT]: IntermediatePaymentModal,
  [MODAL_TYPE_INCENTIVE_INVOICE]: IncentiveInvoiceUpdateModal,
  [MODAL_TYPE_SALE_DOCUMENTS]: GenerateSaleDocModal,
  [MODAL_TYPE_AFTER_SIGNUP_CAROUSEL]: AfterSignupCarousel,
  [MODAL_TYPE_RENEWED_QUOTATION]: RenewedQuotationModal,
  [MODAL_TYPE_QR_PAYMENT]: QRPaymentModal,
  [MODAL_TYPE_DELETE_IP]: DeleteIPModal,
  [MODAL_TYPE_DOWNLOAD_DOCUMENTS]: DownloadDocumentsModal,
  [MODAL_TYPE_UPLOAD_SOA]: UploadSOAModal,
  [MODAL_TYPE_UPDATE_INSURER_CREDITS]: UpdateInsurerCreditsModal,
  [MODAL_TYPE_CORONA_CARDS]: CoronaCardsModal,
  [MODAL_TYPE_CORONA_BANNER]: CoronaBannerModal,
  [MODAL_TYPE_COVID_CANCELLATION]: CovidCancellationModal,
  [MODAL_TYPE_LEADGEN_CODE]: LeadGenCodeModal,
  [MODAL_TYPE_AFFILIATE_LEADGEN_GENERATE]: AffiliateCodeGenerateModal,
  [MODAL_TYPE_RECORD_PAYMENT]: RecordPaymentsModal,
  [MODAL_TYPE_SELECT_VENDOR]: SelectVendorModal,
  [MODAL_TYPE_FD_TO_VENDOR_IP]: FDToVendorIPModal,
  [MODAL_TYPE_DENGUE_CARDS]: DengueCardsModal,
  [MODAL_TYPE_DENGUE_BANNER]: DengueBannerModal,
  [NEW_MODAL]: NewModal,
  [MODAL_TYPE_PA_CARDS]: PACardsModal,
  [MODAL_TYPE_PA_BANNER]: PABannerModal,
  [ACTION_SHEET]: ActionSheet,
  [MODAL_TYPE_INVOICE_DETAIL]: InvoiceDeatailModal,
  [MODAL_TYPE_ATTACH_LEADGEN]: AttachLeadgenAccount,
  [MODAL_TYPE_RECORD_ENDORSEMENT]: EndorsementModal,
  [MODAL_TYPE_CORONA_RENEWAL_CARDS]: CoronaRenewalCardsModal,
  [MODAL_TYPE_TOPUP_PREPAID_CREDIT]: TopUpPrepaidCreditModal,
  [MODAL_TYPE_SUBAGENT]: SubagentModal,
  [MODAL_TYPE_UPLOAD_SUBAGENT_RECONCILIATION]: UploadSubagentReconciliationFile,
  [MOBILE_MODAL]: MobileModal,
  [MODAL_TYPE_RECORD_CIF_SALE]: RecordCifModal,
  [MODAL_TYPE_EVENT_FORM]: EventFormModal,
  [MODAL_TYPE_NON_MOTOR_ORDER_STATUS]: NonMotorOrderStatusModal,
};

const ModalRoot = ({ modal }) => {
  return modal.map((data) => {
    if (!data.type) {
      return null;
    }

    data.props.uid = data.uid;

    const ModalComponent = modalComponents[data.type];
    return <ModalComponent key={data.uid} {...data.props} />;
  });
};
ModalRoot.propTypes = {
  modal: PropTypes.any.isRequired,
};

const mapState = (state) => {
  return {
    modal: state.modal,
  };
};

export default withRouter(connect(mapState)(ModalRoot));
