import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideNotification } from '../actions/notification';
import close from '../images/close.svg';
import error from '../images/notification/error.svg';
import info from '../images/notification/info.svg';
import success from '../images/notification/success.svg';
import warning from '../images/notification/warning.svg';
import { setLanguageToElement } from '../utils/helper';

class Notification extends React.Component {
  state = {
    hideClass: '',
  };

  timeout = '';

  componentDidMount() {
    if (this.props.time && this.props.autoClose) {
      this.timeout = setTimeout(() => {
        this.setState({
          hideClass: 'slideOutUp',
        });
      }, this.props.time - 450);
    }
    setLanguageToElement();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  close = () => {
    this.props.hideNotification(null, {
      ...this.props,
    });
  };

  render() {
    return (
      <div className={'notification-wrap slideInDown ' + this.props.type + ' ' + this.state.hideClass}>
        <img src={close} onClick={this.close} alt="" className="close" />
        <div className="message-wrap">
          <img src={success} alt="" className="success" />
          <img src={error} alt="" className="error" />
          <img src={warning} alt="" className="warning" />
          <img src={info} alt="" className="info" />
          <p className="text">{this.props.message}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, { hideNotification })(Notification));
