import { combineReducers } from 'redux';
import { affiliate } from './reducers/affiliate';
import { breadcrumb } from './reducers/breadcrumb';
import { filter } from './reducers/filter';
import { firstload } from './reducers/firstload';
import { insurers } from './reducers/insurers';
import { language, languageMap } from './reducers/language';
import { location } from './reducers/location';
import modal from './reducers/modal';
import notification from './reducers/notification';
import { provinces } from './reducers/provinces';
import { selectedAffiliate } from './reducers/selectedAffiliate';
import { systemSettings } from './reducers/systemSettings';
import { holidays } from './reducers/holidays';
import user from './reducers/user';

export default combineReducers({
  user,
  modal,
  notification,
  language,
  languageMap,
  filter,
  insurers,
  provinces,
  affiliate,
  firstload,
  location,
  breadcrumb,
  systemSettings,
  selectedAffiliate,
  holidays,
});
