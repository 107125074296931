import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import CloseIcon from '../../../images/fairdee-x-plus/common/close-blue.svg';
import LogoPlus from '../../../images/fairdee-x-plus/common/fairdee-plus-logo.svg';
import { RootState } from '../../../index';
import {
  errorHandler,
  getParameterByName,
  handlecontactOps,
  isEmailOtp,
  notify,
  validateMobileNumber,
} from '../../../utils/helper';
import InlineError from '../../InlineError';
import InputPhoneNumber from '../common/InputPhoneNumber';
import RecaptchaButton from '../common/RecaptchaButton';
import AccountFoundModal from './AccountFoundModal';
import AccountNotFoundModal from './AccountNotFoundModal';
import Otp from './Otp';
import PasscodeVerification from './PasscodeVerification';
import ResetPhoneNumber from './ResetPhoneNumber';
import './_login-register.scss';

interface Props {
  close?: () => void;
  isIg?: boolean;
}

interface Errors {
  mobile: string;
  message?: string;
  email: string;
}

interface ErrorResponse {
  response: any;
}

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const LoginPhone: React.FC<Props> = ({ close, isIg }) => {
  const dispatch = useDispatch(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    [mobile, setMobile] = useState(''),
    [username, setUserName] = useState(''),
    [errors, setErrors] = useState<Errors>({ mobile: '', email: '' }),
    [openUser, setOpenUser] = useState<any>(null),
    [loading, setLoading] = useState(false),
    [disableBtn, setDisableBtn] = useState(true),
    [emailLogin, setEmailLogin] = useState(false),
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    }),
    captchaSiteKey = RECAPTCHA_SITEKEY;

  useEffect(() => {
    const isLoginMethodEmail = getParameterByName('via') === 'email';
    if (isLoginMethodEmail) {
      setEmailLogin(true);
    }
  }, []);

  useEffect(() => {
    let errors = validate();
    setUserName(mobile);
    if (!errors.mobile) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [mobile]);

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

    useEffect(() => {
    if (openUser !== null) {
      let params:any = null
      if (captchaProps.token) {
        params = {
          headers: {
            'G-Recaptcha-Response': captchaProps.token,
          },
        };
      }

      if (openUser === 'No Account Found') {
        requestOtp('login', params);
      } else if (!openUser.is_pin_set && openUser.accounts && openUser.accounts.length) {
        requestOtp('login', params);
      } else {
        openPasscodeAuthentication();
      }

      setDisableBtn(false);
    }
  }, [openUser]);


  function openOtpScreen(intent: string, userName: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: (
          <Otp username={username} intent={intent} openUser={openUser} emailOtp={userName} emailLogin={emailLogin} />
        ),
        uid: 'OTP_SCREEN',
      })
    );
  }

  function openPasscodeAuthentication() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <PasscodeVerification username={mobile} emailLogin={emailLogin} />,
        uid: 'PASSCODE_VERIFICATION',
      })
    );
  }

  function openAccountNotFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountNotFoundModal username={username} emailLogin={emailLogin} />,
        uid: 'ACCOUNT_NOT_FOUND',
      })
    );
  }

  function openAccountFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountFoundModal mobile={mobile} />,
        uid: 'ACCOUNT_FOUND',
      })
    );
  }

  function getOpenAccount(headers?: any) {
    let params: any = {
      phone: mobile,
      email: mobile,
    };
    if (emailLogin) {
      params = { ...params, phone: '', is_email_login: emailLogin };
    }
    setDisableBtn(true);

    setOpenUser(null);
    api.user
      .getOpenUserDetails(params)
      .then((resp) => {
        setOpenUser(resp);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  function handlePhoneReset() {
    if (isEmailOtp()) {
      notify(' กรุณาติดต่อเจ้าหน้าที่ทางไลน์แอดแฟร์ดี หรือ ติดต่อที่เบอร์ 02-114-7920 ต่อ 2 ');
    } else
      dispatch(
        showModal(MOBILE_MODAL, {
          component: <ResetPhoneNumber />,
          uid: 'PHONE_NUMBER_RESET',
        })
      );
  }

  function handleEmailLogin() {
    setEmailLogin(!emailLogin);
  }

  function validate() {
    let errors = {
      mobile: '',
      email: '',
    };

    if (!emailLogin && !validateMobileNumber(mobile)) {
      errors.mobile = languageMap['Invalid Mobile Number'];
    }

    if (emailLogin && !validator.isEmail(mobile)) {
      errors.email = languageMap[`Please add valid email`];
    }
    return errors;
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>, type: string = '') {
    let mobile = e.target.value;
    if (type === 'mobile') {
      if (mobile.length > 10) {
        return;
      }
    }
    setMobile(mobile);
  }

  function requestOtp(intent: string = '', headers?: any) {
    let params: any = {
      username_type: emailLogin ? 'email' : 'phone',
      username: mobile,
      intent,
      is_email_otp: emailLogin ? true : isEmailOtp() ? true : false,
    };

    setDisableBtn(true);
    setLoading(true);
    api.user
      .requestOtp(params, headers)
      .then((response) => {
        openOtpScreen(intent, response.username);
        setDisableBtn(false);
        setLoading(false);
      })
      .catch((error: ErrorResponse) => {
        setLoading(false);
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_does_not_exist') {
          openAccountNotFound();
        } else if (errors && errors.error === 'account_exists') {
          openAccountFound();
        } else if (errors && errors.mobile === 'Invalid Mobile Number') {
          setErrors({ ...errors, mobile: languageMap[`Invalid Mobile Number`] });
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          setErrors({ ...errors, mobile: languageMap[`Too many OTP requests! Please try again in 24 hours`] });
        } else if (errors && errors.non_field_errors) {
          handlecontactOps(errors.non_field_errors);
        } else {
          errorHandler(error.response, true);
        }
        setDisableBtn(false);
      });
  }

  function handleNext() {
    let tempErrors = validate();
    setErrors(tempErrors);

    if (tempErrors.mobile) {
      return;
    }
    getOpenAccount();
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps: any) {
    if (captchaProps.btnName === 'next_button') {
      handleNext();
    }
  }

  function generateButton() {
    if (systemSettings.should_validate_recaptcha) {
      return (
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="button"
            name="next_button"
            label={languageMap['Next']}
            loading={loading}
            disableBtn={disableBtn}
          />
        </GoogleReCaptchaProvider>
      );
    } else {
      return (
        <div className="buttons-wrapper">
          <Button className={'orange-button ' + (disableBtn || loading ? 'disabled' : '')} onClick={() => handleNext()}>
            {languageMap[`Next`]}
          </Button>
        </div>
      );
    }
  }

  return (
    <div className="mobile-layout login-register login">
      <div className="header">
        {!isIg ? (
          <div className="back close" onClick={close}>
            <img src={CloseIcon} alt="back_arrow" />
          </div>
        ) : null}
        <p className="text">{languageMap[`Login`]} </p>
      </div>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={LogoPlus} alt="fairdee-logo" />
        </div>
        <h2 className="title-text">
          {emailLogin ? languageMap['Login with your Email Address'] : languageMap[`Login with your Mobile Number`]}
        </h2>
        {!emailLogin ? (
          <div className="mobile-form">
            <div className={'form-field ' + (errors.mobile ? 'error' : '')}>
              <InputPhoneNumber
                onChange={(e) => handleInputChange(e, 'mobile')}
                name="mobile"
                value={mobile}
                placeholder={languageMap[`Enter Mobile Number`]}
                autoFocus={true}
              />
              {errors.mobile ? <InlineError text={errors.mobile} className="" /> : null}
            </div>
          </div>
        ) : (
          <div className="mobile-form">
            <div className={'form-field ' + (errors.email ? 'error' : '')}>
              <input
                type="email"
                placeholder={languageMap[`example@email.com`]}
                name="email"
                onChange={(e) => handleInputChange(e, 'email')}
                value={mobile}
              />
              {errors.email ? <InlineError text={errors.email} className="" /> : null}
            </div>
          </div>
        )}
        {generateButton()}
        <div className="link-wrap ">
          <p className="center">
            {emailLogin ? languageMap[`Login with your Mobile Number`] : languageMap['Login with your Email Address']}{' '}
            <span className="link" onClick={handleEmailLogin}>
              {languageMap[`Click here`]}
            </span>
          </p>
        </div>
        <div className="link-wrap second-message">
          <p className="center">
            {languageMap[`Need to change your mobile number?`]}{' '}
            <span className="link" onClick={handlePhoneReset}>
              {languageMap[`Click here`]}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPhone;
