import { INSURERS } from '../constants/types';

function insurers(state = {}, action = {}) {
  switch (action.type) {
    case INSURERS:
      return action.insurers;
    default:
      return state;
  }
}

export { insurers };
