import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import {
  getFilterData,
  getParameterByName,
  googleEvent,
  isLoggedIn,
  isMobileNumber,
  pixelEvent,
  setAccessToken,
  setAxiosHeaders,
  setLanguageToElement,
  setUserData,
} from '../../utils/helper';

class ShareDetails extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('ShareDetails => ', props);

    this.state = {
      data: {
        name: '',
        email: '',
        phone: '',
        insurer_id: this.props.insurer || '',
      },
      errors: {},
      loading: false,
      insurers: [],
    };
  }

  componentDidMount() {
    setLanguageToElement();
    if (this.props.user && this.props.user.email) {
      this.setState({
        data: {
          ...this.state.data,
          name: this.props.user.fullname || '',
          email: this.props.user.email || '',
          phone: this.props.user.phone || '',
        },
      });
    }
    if (this.props.insurers) {
      let insurers = [];
      this.props.insurers.forEach((data) => {
        let obj = {
          key: data.insurer.id,
          value: data.insurer.id,
          text: data.insurer.name,
        };
        insurers.push(obj);
      });
      this.setState({
        insurers,
      });
    }
  }

  crossDomainPost = (url, data) => {
    let form = document.createElement('form');
    form.action = url;
    form.method = 'POST';
    form.id = 'sompo-payment-form';

    Object.keys(data).forEach((key) => {
      let input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    const elem = document.getElementById('sompo-payment-form');
    elem.parentNode.removeChild(elem);
  };

  openThankYouLoader = (data) => {
    // this.props.showModal(MODAL_TYPE_AUTHENTICATION_THANKYOU, {
    //   textMap: 'thankYou'
    // });
  };

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;

    // if (!data.name) {
    //   errors.name = languageMap.required;
    // }
    // if (!Validator.isEmail(data.email)) {
    //   errors.email = languageMap.email;
    // }
    if (!isMobileNumber(data.phone)) {
      errors.phone = languageMap.phone;
    }
    if (this.state.insurers.length && !data.insurer_id) {
      errors.insurer_id = languageMap.required;
    }
    return errors;
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onInsurerChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, insurer_id: data.value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  submit = () => {
    let id = null;
    let descriptionId = getFilterData().vehicleData ? getFilterData().vehicleData.description : '';
    let year = getFilterData().vehicleData ? getFilterData().vehicleData.manufacture_year : '';
    let ref = getParameterByName('ref');
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    let params = {};
    if (Object.keys(errors).length) {
      return;
    }
    params = {
      // email: this.state.data.email,
      // fullname: this.state.data.name,
      phone: this.state.data.phone,
      code: 'th',
    };

    if (this.props.id) {
      params.price_list_id = this.props.id;
    }

    if (isLoggedIn()) {
      id = this.props.insurer_id;
      params.price_list_id = this.props.id;
      if (this.props.insurers && this.props.insurers.length) {
        params.insurer_id = this.state.data.insurer_id;
      }
    } else if (this.props.insurers && this.props.insurers.length) {
      params.insurer_id = this.state.data.insurer_id;
    } else {
      params.insurer_id = this.props.insurer_id;
      params.price_list_id = this.props.id;
    }
    window.console.log(params, id);
    params.model_description_id = descriptionId;
    params.year = year;
    if (ref) {
      params.ref = ref;
    }
    // return;
    api.utils
      .initialLead(params, id)
      .then((resp) => {
        if (this.props.corporate && this.props.corporate.name === 'Maxi') {
          googleEvent(
            {
              hitType: 'event',
              eventCategory: 'Button',
              eventAction: 'registered',
              eventLabel: 'Registered',
            },
            'Maxi'
          );
        } else {
          googleEvent({
            hitType: 'event',
            eventCategory: 'click',
            eventAction: 'user_info_saved',
            eventLabel: 'user_info_saved',
          });
        }
        if (this.props.insurers && this.props.insurers.length) {
          googleEvent({
            hitType: 'event',
            eventCategory: 'click',
            eventAction: 'lead_to_insurer_without_quotes',
            eventLabel: 'lead_to_insurer_without_quotes',
          });
          pixelEvent('lead_to_insurer_without_quotes');
        } else {
          googleEvent({
            hitType: 'event',
            eventCategory: 'click',
            eventAction: 'lead_to_insurer',
            eventLabel: 'lead_to_insurer',
          });
          pixelEvent('lead_to_insurer');
        }
        if (resp.user) {
          setUserData(resp.user);
          if (resp.user.access_token) {
            setAccessToken(resp.user.access_token);
            setAxiosHeaders();
          }
        }
        if (resp.redirect_url && resp.post_body && Object.keys(resp.post_body).length) {
          this.crossDomainPost(resp.redirect_url, JSON.parse(resp.post_body));
          this.close();
        } else {
          this.close();
          this.openThankYouLoader();
        }
        // window.location.href = resp.redirect_url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { data, errors } = this.state;
    const languageMap = this.props.languageMap.components.shareDetailsModal;
    return (
      <div className="fairdee-modal-wrapper small share-details">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.titleText}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.titleText}</h1>
          <Form noValidate className="quotation-form">
            {/*
                <Form.Field>
                    <input 
                      type="text"
                      id="name" 
                      name="name" 
                      placeholder={ languageMap.keyOne }
                      value={ data.name }
                      onChange={ this.onChange }
                    />
                    {errors.name && <InlineError text={errors.name} />}
                    <label htmlFor="name">{ languageMap.keyOne }</label>
                  </Form.Field>

                  <Form.Field>
                    <input 
                      type="email" 
                      id="email" 
                      name="email" 
                      placeholder={ languageMap.keyThree }
                      value={ data.email }
                      onChange={ this.onChange }
                    />
                    {errors.email && <InlineError text={errors.email} />}
                    <label htmlFor="email">{ languageMap.keyThree }</label>
                  </Form.Field>
              */}
            <Form.Field>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder={languageMap.keyFour}
                value={data.phone}
                onChange={this.onChange}
              />
              {errors.phone && <InlineError text={errors.phone} />}
              <label htmlFor="phone">{languageMap.keyFour}</label>
            </Form.Field>

            {this.state.insurers.length && !this.props.insurer ? (
              <Form.Field>
                <Dropdown
                  placeholder={languageMap.keyFive}
                  fluid
                  selection
                  options={this.state.insurers}
                  onChange={this.onInsurerChange}
                  value={data.insurer_id}
                />
                {errors.insurer_id && <InlineError text={errors.insurer_id} />}
              </Form.Field>
            ) : (
              ''
            )}
            <div className="button-wrap">
              <button className="button medium" onClick={this.submit}>
                {languageMap.buttonText}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

ShareDetails.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(ShareDetails));
