import MomentUtils from '@date-io/moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { InlineDatePicker, InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Checkbox, Dropdown, Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-lg.svg';
import cross from '../../images/cross.svg';
import { commaSeperatedNumbers, errorHandler, getParameterByName, toThaiDate, toUTCTime } from '../../utils/helper';

const MOMENT = moment().locale('th');

const REJECTION_REASONS = [
    'unclear_images',
    'invalid_insurer_application',
    'invalid_citizen_id',
    'invalid_policy_date',
    'invalid_signature',
    'invalid_signature_date',
    'missed_t_and_c',
    'package_not_selected',
    'old_document',
  ],
  WITH_BANK_REF = ['instalment', 'cbc_to_fairdee'];

class FileViewerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: {
        amount_paid: '',
        paid_at: null,
        payment_id: null,
        broker_bank_account_id: null,
        bank_reference_number: null,
        invoice_id: props.invoice_id,
      },
      physical_copy_received: props.data.physical_copy_received,
      courier_provider: props.data.courier_provider,
      courier_tracking_number: props.data.courier_tracking_number,
      loading: true,
      errors: {},
      updatedData: {},
      apiLoading: false,
      bankAccounts: [],
      zoom: false,
      rejectionReasons: {},
      invoices: [],
      aiResult: {},
    };
  }

  componentDidMount() {
    this.setState({
      aiResult: this.props.data?.ai_result,
    });
    if (!this.props.is_corona && !this.props.is_dengue && !this.props.is_pa) {
      this.getDocumentDetails();
    } else {
      let file = [];
      if (this.props.is_corona) {
        if (this.props.covidForm.documents && this.props.covidForm.documents.length) {
          file = this.props.covidForm.documents.filter((data) => data.id === this.props.data.id);
        }
      } else if (this.props.is_dengue) {
        if (this.props.dengueForm.documents && this.props.dengueForm.documents.length) {
          file = this.props.dengueForm.documents.filter((data) => data.id === this.props.data.id);
        }
      } else if (this.props.is_pa) {
        if (this.props.paForm.documents && this.props.paForm.documents.length) {
          file = this.props.paForm.documents.filter((data) => data.id === this.props.data.id);
        }
      }
      this.setFileData(file);
    }

    this.getBrokerBankAccounts();

    this.updateInvoices();

    if (this.props.data.rejection_reasons) {
      let rejectionReasons = {};
      this.props.data.rejection_reasons.forEach((reason) => (rejectionReasons[reason] = true));
      this.setState({
        rejectionReasons,
      });
    }
  }

  updateInvoices = () => {
    let invoices = [],
      invoice_id = this.props.invoice_id;
    const messages = this.props.languageMap.messages;
    if (this.props.hasClawbackInvoice) {
      invoices = this.props.invoicesList;

      invoices.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (b.id > a.id) {
          return 1;
        }
        return 0;
      });
      invoices = invoices.map((invoice, index) => {
        const entry = {};
        let dueDate = moment(toThaiDate(invoice.due_date)).format('DD MMM YYYY');
        let additionalText = '';
        if (
          invoice.details &&
          invoice.details[0] &&
          (invoice.details[0].detail_type === 'refund' || invoice.details[0].detail_type === 'refund_endorsement')
        ) {
          additionalText = 'Clawback';
          if (invoice.payment_status === 'unpaid' || invoice.payment_status === 'underpaid') {
            invoice_id = invoice.id;
          }
        }
        entry.text = (
          <span>
            {`Invoice${index + 1}: ${invoice.amount_payable} - ${dueDate}`}
            {additionalText ? <span className="crm-tag">{additionalText}</span> : null}
          </span>
        );
        entry.name = (
          <span>
            {`Invoice${index + 1}: ${invoice.amount_payable} - ${dueDate}`}
            {additionalText ? <span className="crm-tag">{additionalText}</span> : null}
          </span>
        );
        entry.value = invoice.id;
        return entry;
      });

      invoices.unshift({
        text: messages.select,
        name: messages.select,
        value: messages.select,
      });
    }

    this.setState({
      invoices,
      payment: { ...this.state.payment, invoice_id: invoice_id },
    });
  };

  close = () => {
    let props = { ...this.props };
    this.props.hideModal(null, { ...props });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  handleSwitchChange = (name) => (e) => {
    let checked = e.target.checked;
    // if (!checked) {
    //   return;
    // }
    this.props.showModal(CONFIRMATION_MODAL, {
      text: this.props.languageMap.messages.confirmationText,
      action: this.physicalCopyStatusChange,
      data: { physical_copy_received: checked },
    });
  };

  physicalCopyStatusChange = (data) => {
    this.props.action({ ...this.props.data, ...data, type: 'physical_copy' });
  };

  setFileData = (file) => {
    if (file.length && file[0].payment) {
      this.setState({
        payment: {
          amount_paid: parseFloat(file[0].payment.amount_paid),
          paid_at: moment(file[0].payment.paid_at).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
          payment_id: file[0].payment.id,
          broker_bank_account_id: file[0].payment.broker_bank_account_id,
          bank_reference_number: this.props.data.payment.bank_reference_number,
          invoice_id: this.props.invoice_id,
        },
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getDocumentDetails = () => {
    api.utils
      .getFairdeeQuotation(this.props.quotation_id, { with_payment_in_document: true })
      .then((resp) => {
        let file = [];
        if (resp.message.documents && resp.message.documents.length) {
          file = resp.message.documents.filter((data) => data.id === this.props.data.id);
        }
        this.setFileData(file);
      })
      .catch((error) => console.log(error));
  };

  getBrokerBankAccounts = () => {
    const messages = this.props.languageMap.messages;
    api.masterData
      .brokerBankAccounts()
      .then((resp) => {
        resp = resp.map((data) => {
          data.text = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
          data.name = data.bank_name + ' - ' + data.account_holder + ' - ' + data.account_number;
          data.value = data.id;
          return data;
        });
        resp.unshift({
          text: messages.select,
          name: messages.select,
          value: messages.select,
        });
        this.setState({
          bankAccounts: resp,
        });
      })
      .catch((error) => console.log(error));
  };

  validate = () => {
    let errors = {};
    let data = this.state.payment;
    let messages = this.props.languageMap.messages;

    if (
      (!this.props.saleType || WITH_BANK_REF.includes(this.props.saleType) || this.props.isInstalment) &&
      !data.bank_reference_number
    ) {
      errors.bank_reference_number = messages.required;
    }

    if (!data.amount_paid) {
      errors.amount_paid = messages.required;
    }

    if (!this.props.is_credit && (!data.broker_bank_account_id || data.broker_bank_account_id === messages.select)) {
      errors.broker_bank_account_id = messages.required;
    }

    if (!data.paid_at) {
      errors.paid_at = messages.required;
    }

    if ((this.props.isInstalment || this.props.hasClawbackInvoice) && !data.invoice_id) {
      errors.invoice_id = messages.required;
    }
    return errors;
  };

  validateCourierFields = () => {
    let errors = {};
    let { courier_provider, courier_tracking_number } = this.state;
    let messages = this.props.languageMap.messages;

    if (this.props.requireCourierFields) {
      if (!courier_provider) {
        errors.courier_provider = messages.required;
      }
      if (!courier_tracking_number) {
        errors.courier_tracking_number = messages.required;
      }
    }
    return errors;
  };

  createPayment = (params = {}) => {
    const messages = this.props.languageMap.messages,
      bankReferenceNumberRequired = !this.props.saleType || WITH_BANK_REF.includes(this.props.saleType);
    api.crm
      .payment(params)
      .then((resp) => {
        this.setState({
          apiLoading: false,
          payment: { ...this.state.payment, payment_id: resp.id },
        });
        this.notify(messages.savedSuccessfully, 'success');
        this.props.action({ ...this.props.data, type: 'accept' });
        this.close();
      })
      .catch((error) => {
        if (bankReferenceNumberRequired) {
          const { data } = error.response;
          if (!data.errors.bank_reference_number) {
            errorHandler(error.response, true);
          } else {
            this.setState({
              errors: {
                CbcToFairdeeErr: true,
                messages: data.errors.bank_reference_number,
              },
            });
          }
        } else {
          errorHandler(error.response, true);
        }
        this.setState({
          apiLoading: false,
        });
      });
  };

  patchPayment = (id, params = {}) => {
    const messages = this.props.languageMap.messages,
      bankReferenceNumberRequired = !this.props.saleType || WITH_BANK_REF.includes(this.props.saleType);

    this.setState({
      apiLoading: true,
    });
    api.crm
      .patchPayment(id, params)
      .then((resp) => {
        this.setState({
          apiLoading: false,
        });
        this.notify(messages.updatedSuccessfully, 'success');
        this.props.action({ ...this.props.data, type: 'accept' });
        this.close();
      })
      .catch((error) => {
        if (
          bankReferenceNumberRequired &&
          error.response &&
          error.response.errors &&
          error.response.data.errors &&
          error.response.data.errors.bank_reference_number
        ) {
          const { data } = error.response;
          this.setState({
            errors: {
              CbcToFairdeeErr: true,
              messages: data.errors.bank_reference_number,
            },
          });
        } else {
          errorHandler(error.response, true);
        }
        this.setState({
          apiLoading: false,
        });
      });
  };

  accept = () => {
    let data = this.props.data;

    if (
      data.document_type === 'photo_with_national_id' ||
      data.document_type === 'cancellation_contract' ||
      data.document_type === 'loan_contract' ||
      data.document_type === 'postal_tracking_receipt'
    ) {
      let errors = this.validateCourierFields();
      this.setState({ errors });
      if (Object.keys(errors).length) {
        this.setState({
          apiLoading: false,
        });
        return;
      }

      this.props.action({
        ...this.props.data,
        courier_provider: this.state.courier_provider,
        courier_tracking_number: this.state.courier_tracking_number,
        type: 'accept',
      });
      this.close();
      return;
    }
    if (data.document_type === 'application_form') {
      if (Object.keys(this.state.rejectionReasons).length) {
        this.notify('Cannot accept with Rejection Reasons', 'error');
        return;
      }
      this.props.action({ ...this.props.data, type: 'accept' });
      this.close();
      return;
    }

    let errors = this.validate();
    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    this.setState({
      apiLoading: true,
    });
    let payment = JSON.parse(JSON.stringify(this.state.payment));
    payment.paid_at = toUTCTime(payment.paid_at);
    const bankRef =
      !this.props.saleType || WITH_BANK_REF.includes(this.props.saleType) || this.props.isInstalment
        ? payment.bank_reference_number
        : '';
    let params = {
      amount_paid: parseFloat(payment.amount_paid),
      paid_at: moment(payment.paid_at).format('YYYY-MM-DD') + 'T' + moment(payment.paid_at).format('HH:mm:ss') + 'Z',
      invoice_id: payment.invoice_id || this.props.invoice_id,
      broker_bank_account_id: payment.broker_bank_account_id,
      bank_reference_number: bankRef,
    };
    if (this.props.isInstalment) {
      params.invoice_id = this.props.invoice_id;
    }
    if (this.props.is_corona) {
      params.covid_document_id = this.props.data.id;
    } else if (this.props.is_dengue) {
      params.dengue_document_id = this.props.data.id;
    } else if (this.props.is_pa) {
      params.pa_document_id = this.props.data.id;
    } else {
      params.fairdee_document_id = this.props.data.id;
    }

    if (this.props.is_credit && !this.isClawbackSelected()) {
      delete params.broker_bank_account_id;
    }
    if (this.props.saleType === 'cbc_to_insurer') {
      params.is_credit_card_payment = true;
      delete params.bank_reference_number;
    }

    if (!payment.payment_id) {
      this.createPayment(params);
    } else {
      if (Object.keys(this.state.updatedData).length) {
        params = this.state.updatedData;
        if (params.amount_paid) {
          params.amount_paid = parseFloat(params.amount_paid);
        }
        if (params.paid_at) {
          params.paid_at = toUTCTime(params.paid_at);
          params.paid_at =
            moment(params.paid_at).format('YYYY-MM-DD') + 'T' + moment(params.paid_at).format('HH:mm:ss') + 'Z';
        }
        this.patchPayment(payment.payment_id, params);
      }
    }

    // this.props.action({ ...this.props.data, 'type': 'reject' });
    // this.close();
  };

  reject = () => {
    if (this.props.data.document_type === 'application_form' && !Object.keys(this.state.rejectionReasons).length) {
      this.notify('Please mention rejection reason', 'error');
      return;
    }
    let rejectionReasons = Object.keys(this.state.rejectionReasons).map((key) => key);

    this.props.action({ ...this.props.data, rejectionReasons, type: 'reject' });
    this.close();
  };

  handleSelectChange = (e, data) => {
    let payment = this.state.payment,
      updatedData = this.state.updatedData;

    payment[data.name] = data.value;
    updatedData[data.name] = data.value;
    if (data.value === 'select') {
      payment[data.name] = null;
      updatedData[data.name] = null;
    }
    this.setState({
      payment,
      updatedData,
    });
  };

  handleChange = (name, resetTime = false, event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    if (resetTime) {
      value = moment(value).set({ hour: 7, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss');
    }

    let payment = this.state.payment;
    payment[name] = value;
    this.setState({
      payment,
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  handleCourierFieldChange = (name, event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  toggleZoom = () => {
    this.setState({
      zoom: !this.state.zoom,
    });
  };

  downloadDocument = (data) => {
    let url = (this.props.downloadPath || apiEndpoints.quotationDocumentUpload) + '/' + data.id,
      t = getParameterByName('t');
    if (t) {
      url += `?t=${t}`;
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onCheckboxChange = (e, data) => {
    const { rejectionReasons } = this.state;

    if (data.checked) {
      rejectionReasons[data.name] = true;
    } else {
      delete rejectionReasons[data.name];
    }

    this.setState({
      rejectionReasons,
    });
  };

  handleNumberFormat = (name) => (data) => {
    let payment = this.state.payment;
    payment[name] = data.value;
    this.setState({
      payment,
      updatedData: { ...this.state.updatedData, [name]: data.value },
    });
  };

  isClawbackSelected = () => {
    if (!this.props.hasClawbackInvoice) {
      return false;
    }

    if (
      this.state.payment &&
      this.state.payment.invoice_id &&
      this.props.clawbackInvoices.find((invoice) => invoice.id === this.state.payment.invoice_id)
    ) {
      return true;
    }
    return false;
  };

  renderAIResultText = () => {
    const languageMap = this.props.languageMap.components.fileViewerModal;
    if (
      this.state.aiResult &&
      this.state.aiResult.status === 'FAILED' &&
      Object.keys(this.state.aiResult.error_description).length
    ) {
      if (Array.isArray(this.state.aiResult.error_description[Object.keys(this.state.aiResult.error_description)[0]])) {
        return <>{this.state.aiResult.error_description[Object.keys(this.state.aiResult.error_description)[0]]}</>;
      } else if (this.state.aiResult.error_description === 'Cannot find matching receiver bank account') {
        return <>{languageMap.aiBankReferenceNumberErrorText}</>;
      }
      return <>{this.state.aiResult.error_description}</>;
    }
    return <></>;
  };

  renderView = () => {
    const { data, is_credit, requireCourierFields, is_credit_card, isInstalment, hasClawbackInvoice } = this.props;
    const { payment, errors, bankAccounts, zoom, physical_copy_received, courier_provider, courier_tracking_number } =
      this.state;
    const languageMap = this.props.languageMap.components.fileViewerModal;
    let url = apiEndpoints.quotationDocumentUpload + '/' + data.id + '?inline=true';
    const messages = this.props.languageMap.messages;
    if (this.props.is_corona) {
      url = apiEndpoints.covidDocument + '/' + data.id + '?inline=true';
    } else if (this.props.is_dengue) {
      url = apiEndpoints.dengueDocument + '/' + data.id + '?inline=true';
    } else if (this.props.is_pa) {
      url = apiEndpoints.paDocument + '/' + data.id + '?inline=true';
    }

    if (
      data.document_type === 'photo_with_national_id' ||
      data.document_type === 'cancellation_contract' ||
      data.document_type === 'loan_contract' ||
      data.document_type === 'postal_tracking_receipt' ||
      data.document_type === 'application_form'
    ) {
      return (
        <div className="fairdee-modal-wrapper file-viewer med">
          <div className="modal-content">
            <img src={cross} className="close" alt="" onClick={this.close} />
            <div className="mobile-header">
              <p className="current-page" onClick={this.close}>
                <img src={crossLg} alt="" />
                <span className="title-text">{languageMap.textTen}</span>
              </p>
            </div>
            {data.document_type === 'application_form' && (
              <h1 className="title-text">{languageMap.applicationFormHeader}</h1>
            )}
            {data.document_type !== 'application_form' && <h1 className="title-text">{languageMap.textTen}</h1>}
            <button className="download-btn" onClick={this.downloadDocument.bind(null, data)}>
              {languageMap.textThirteen}
            </button>
            <button className="zoom-btn" onClick={this.toggleZoom}>
              {languageMap.textNine}
            </button>
            {data.file && data.file.name.includes('.pdf') && <iframe src={url} width="100%" height="600px" />}
            {!data.file ||
              (!data.file.name.includes('.pdf') && (
                <div className={'file-view ' + (zoom ? 'zoom' : '')}>
                  <img src={url} alt="" id="fileImage" />
                </div>
              ))}
            <div>
              <div className="button-wrap">
                <button
                  className={
                    'button medium orange-bordered-button ' +
                    (data.is_rejected || data.is_rejected === false ? 'disabled' : '')
                  }
                  onClick={this.reject}
                >
                  {languageMap.textTwelve}
                </button>
                <button onClick={this.accept} className="button medium">
                  {languageMap.textEleven}
                </button>
              </div>
              {data.is_rejected === false ? (
                <div className="form-label">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={physical_copy_received}
                        onChange={this.handleSwitchChange('physical_copy_received')}
                        value="true"
                        color="primary"
                      />
                    }
                    label="Physical Copy Received"
                    labelPlacement="start"
                  />
                </div>
              ) : null}
              {data.is_rejected !== false && data.document_type === 'application_form' && (
                <div className="form-wrapper">
                  <div className="form-field">{languageMap.rejectWithReason}</div>
                  {REJECTION_REASONS.map((reason, index) => (
                    <Form.Field key={index}>
                      <Checkbox
                        label={languageMap.rejectionReasons[reason]}
                        name={reason}
                        checked={Boolean(this.state.rejectionReasons[reason])}
                        onChange={this.onCheckboxChange}
                      />
                    </Form.Field>
                  ))}
                </div>
              )}
              {requireCourierFields && (
                <div className="form-wrapper">
                  <div className="form-field">
                    <input
                      type="text"
                      id="courier_provider"
                      name="courier_provider"
                      onChange={this.handleCourierFieldChange.bind(null, 'courier_provider')}
                      value={courier_provider}
                    />
                    <label htmlFor="courier_provider">{languageMap.courierProvider}</label>
                    {errors.courier_provider && <InlineError text={errors.courier_provider} />}
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      id="courier_tracking_number"
                      name="courier_tracking_number"
                      onChange={this.handleCourierFieldChange.bind(null, 'courier_tracking_number')}
                      value={courier_tracking_number}
                    />
                    <label htmlFor="courier_tracking_number">{languageMap.courierTrackingNumber}</label>
                    {errors.courier_tracking_number && <InlineError text={errors.courier_tracking_number} />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="fairdee-modal-wrapper file-viewer med">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <div className="ai-result-text">{this.renderAIResultText()}</div>
          <button className="download-btn" onClick={this.downloadDocument.bind(null, data)}>
            {languageMap.textThirteen}
          </button>
          <button className="zoom-btn" onClick={this.toggleZoom}>
            {languageMap.textNine}
          </button>
          {data.file && data.file.name.includes('.pdf') && <iframe src={url} width="100%" height="100%" />}
          {!data.file ||
            (!data.file.name.includes('.pdf') && (
              <div className={'file-view ' + (zoom ? 'zoom' : '')}>
                <img src={url} alt="" id="fileImage" />
              </div>
            ))}
          <div>
            <div className="button-wrap">
              <button
                className={
                  'button medium orange-bordered-button ' +
                  (data.is_rejected || data.is_rejected === false ? 'disabled' : '')
                }
                onClick={this.reject}
              >
                {languageMap.textTwo}
              </button>
              <button onClick={this.accept} className="button medium" disabled={this.state.apiLoading}>
                {payment.payment_id ? languageMap.textSeven : languageMap.textThree}
              </button>
            </div>
            {errors.amount_paid && errors.amount_paid.indexOf(messages.moreThanOrEqual) > -1 && (
              <div className="inline-error">
                {`${languageMap.amountWarning} ${commaSeperatedNumbers(this.props.finalAmount)}. ${
                  languageMap.proceedFurther
                }`}
              </div>
            )}
            <div className="form-wrapper">
              {this.state.errors.CbcToFairdeeErr &&
                this.state.errors.messages &&
                this.state.errors.messages.length > 0 && (
                  <div className="errorBankRefNo">
                    <a
                      href={this.state.errors.messages.length && this.state.errors.messages[1]}
                      className="qoutationLink"
                      target="_blank"
                    >
                      {this.state.errors.messages[0]}
                    </a>
                  </div>
                )}
              {is_credit ? (
                <div className="form-field">
                  {languageMap.minAmount}: {commaSeperatedNumbers(this.props.finalAmount)}
                </div>
              ) : (
                this.props.cardData &&
                this.props.cardData.minTransferAmount &&
                !isInstalment &&
                !hasClawbackInvoice && (
                  <div className="form-field">
                    {languageMap.minAmount}: {commaSeperatedNumbers(this.props.cardData.minTransferAmount)}
                  </div>
                )
              )}
              {hasClawbackInvoice && (
                <div className="form-field">
                  <label htmlFor="broker_bank_account_id">Invoice</label>
                  <Dropdown
                    // placeholder={ languageMap.textEight }
                    fluid
                    search
                    selection
                    options={this.state.invoices}
                    onChange={this.handleSelectChange}
                    value={payment.invoice_id}
                    name="invoice_id"
                  />
                  {errors.invoice_id && <InlineError text={errors.invoice_id} />}
                </div>
              )}
              {(this.isClawbackSelected() || !is_credit) && (
                <div className="form-field">
                  <label htmlFor="broker_bank_account_id">{languageMap.textEight}</label>
                  <Dropdown
                    // placeholder={ languageMap.textEight }
                    fluid
                    search
                    selection
                    options={bankAccounts}
                    onChange={this.handleSelectChange}
                    value={payment.broker_bank_account_id}
                    name="broker_bank_account_id"
                  />
                  {errors.broker_bank_account_id && <InlineError text={errors.broker_bank_account_id} />}
                </div>
              )}

              {(!this.props.saleType ||
                WITH_BANK_REF.includes(this.props.saleType) ||
                this.props.isInstalment ||
                this.isClawbackSelected()) && (
                <div className="form-field">
                  <TextField
                    name="bank_reference_number"
                    className="form-field"
                    label="Bank Reference Number"
                    value={payment.bank_reference_number || ''}
                    onChange={(e) => this.handleChange('bank_reference_number', false, e)}
                    data-test-id="fileviewer-modal-bank-reference-number"
                  />
                  {errors.bank_reference_number && <InlineError text={errors.bank_reference_number} />}
                </div>
              )}

              <div className="form-field">
                <NumberFormat
                  id="amount_paid"
                  customInput={TextField}
                  label={languageMap.textFour}
                  value={payment.amount_paid || ''}
                  margin="normal"
                  autoComplete="off"
                  name="amount_paid"
                  className="number-format"
                  thousandSeparator={true}
                  onValueChange={this.handleNumberFormat('amount_paid')}
                />
                {errors.amount_paid && <InlineError text={errors.amount_paid} />}
              </div>
              {(!is_credit || is_credit_card) && (
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDateTimePicker
                    clearable
                    animateYearScrolling={false}
                    id="paid_at"
                    name="paid_at"
                    label={languageMap.textFive}
                    value={payment.paid_at}
                    onChange={this.handleChange.bind(null, 'paid_at', false)}
                    maxDate={MOMENT.clone().add(1, 'years')}
                    minDate={MOMENT.clone().subtract(2, 'years')}
                    initialFocusedDate={MOMENT.clone()}
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    className="form-field datepicker"
                    error={Boolean(errors.paid_at)}
                    helperText={errors.paid_at}
                  />
                </MuiPickersUtilsProvider>
              )}
              {is_credit && !is_credit_card && (
                <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                  <InlineDatePicker
                    clearable
                    animateYearScrolling={false}
                    id="paid_at"
                    name="paid_at"
                    label={languageMap.textFive}
                    value={payment.paid_at}
                    onChange={this.handleChange.bind(null, 'paid_at', true)}
                    maxDate={MOMENT.clone().add(1, 'years')}
                    minDate={MOMENT.clone().subtract(2, 'years')}
                    initialFocusedDate={MOMENT.clone()}
                    format="DD/MM/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    className="form-field datepicker"
                    error={Boolean(errors.paid_at)}
                    helperText={errors.paid_at}
                  />
                </MuiPickersUtilsProvider>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return null;
    }

    return this.renderView();
  }
}

FileViewerModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showNotification, showModal })(FileViewerModal));
