import React from 'react';
import ThaiFlag from '../../../images/fairdee-x-plus/icons/thailand-flag.svg';

interface Props {
  name: string;
  value: string | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean | undefined;
  readOnly?: boolean | undefined;
  placeholder?: string;
  autoFocus?: boolean;
}

const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

const InputPhoneNumber: React.FC<Props> = ({ value, onChange, name, disabled, readOnly, placeholder, autoFocus }) => {
  return (
    <div className="phone-number-input">
      <span>
        <img src={ThaiFlag} alt="thai_flag" />
        +66
      </span>
      <input
        type="number"
        value={value}
        name={name}
        onChange={onChange}
        className={disabled ? 'disabled' : ''}
        readOnly={readOnly}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onKeyDown={blockInvalidChar}
      />
    </div>
  );
};

export default InputPhoneNumber;
