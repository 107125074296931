import Popover from '@material-ui/core/Popover';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import LineShare from '../../components/LineShare';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import imgOne from '../../images/hand-shake.svg';
import { isMobileDevice, notify, setReferEarnModalFlag } from '../../utils/helper';

/* global mobileClient */

class ReferEarnModal extends React.Component {
  state = {
    showLine: false,
    popoverOpen: false,
    anchorEl: null,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showLine: true,
      });
    }, 1000);
  }
  close = () => {
    setReferEarnModalFlag(true);
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continue = () => {
    if (this.props.action) {
      this.props.action();
    }
    this.close();
  };

  state = {
    copied: false,
    showDesc: false,
  };

  copy = () => {
    let url = this.props.affiliate.referral_url;
    // let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    // try {
    //   mobileClient.shareText(url)
    // }
    // catch (e) {
    //   if (isMobileDevice() && !iOS) {
    //     if (navigator.share) {
    //       navigator.share({
    //         title: 'Share Referral Link',
    //         url,
    //       })
    //         .then(() => console.log('Successful share'))
    //         .catch((error) => console.log('Error sharing', error));
    //     }
    //   } else {
    //     copy(url);
    //     this.setState({
    //       copied: true
    //     });
    //   }
    // }
    // // this.continue();
    copy(url);
    this.setState({
      copied: true,
    });
    notify(this.props.languageMap.messages.copied, 'info');
  };

  copyCode = (e) => {
    let code = this.props.affiliate.referral_url;
    copy(code);
    this.setState({
      anchorEl: e.currentTarget,
    });
    setTimeout(() => {
      this.setState({
        anchorEl: null,
      });
    }, 3000);
  };

  toggleDesc = () => {
    this.setState({
      showDesc: !this.state.showDesc,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.referEarnModal;
    const { copied, showDesc, showLine, anchorEl } = this.state;
    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? 'simple-popover' : null;

    return (
      <div className="fairdee-modal-wrapper small-medium customer-info refer-earn-modal">
        <div className="modal-content">
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text"></span>
            </p>
          </div>
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="message-wrapper">
            <img src={imgOne} alt="" className="banner-img" />
            {isMobileDevice() ? (
              <p className="text-one with-margin">{languageMap.textFourteen}</p>
            ) : (
              <div>
                <p className="text-one">{languageMap.textOne}</p>
                <p className="text-one with-margin">{languageMap.textTwo}</p>
              </div>
            )}
            <p className="text-two">
              {languageMap.textThree} <span onClick={this.toggleDesc}>{languageMap.textEleven}</span>
            </p>
            {showDesc ? (
              <div className="desc-wrap">
                <p className="header-text">{languageMap.textFour}</p>
                <p className="text">{languageMap.textFive}</p>
                <p className="text">{languageMap.textSix}</p>
                <p className="text">{languageMap.textSeven}</p>
                <p className="text">{languageMap.textEight}</p>
              </div>
            ) : null}
            <p className="text-two">{languageMap.textNine}</p>
            <p className="text-two">{languageMap.textTen}</p>
            <p className="with-link" onClick={this.copyCode} aria-describedby={id} variant="contained">
              {languageMap.textFifteen}: <span>{this.props.affiliate.referral_url}</span>
              <Popover
                id={id}
                className="message-popover"
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <span>{languageMap.textThirteen}</span>
              </Popover>
            </p>
          </div>
          <div className="button-wrap">
            <button className="button medium primary" onClick={this.copy}>
              {!copied ? languageMap.textTwelve : languageMap.textThirteen}
            </button>
          </div>

          {isMobileDevice() ? (
            <div className="social-action-wrap">
              {showLine ? <LineShare link={this.props.affiliate.referral_url} /> : null}

              {/* <span>
                <img src={line} alt="" />
              </span><span>
                <img src={messenger} alt="" />
              </span> */}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ReferEarnModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  hideCancel: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(ReferEarnModal));
