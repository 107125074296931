import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { setAffiliate } from '../../actions/affiliate';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { default as PROVINCES } from '../../constants/provinces.json';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import ArrowDown from '../../images/affiliate-dashboard/arrow-down.svg';
import ArrowUp from '../../images/affiliate-dashboard/arrow-up.svg';
import { default as cross, default as crossLg } from '../../images/close.svg';
import {
  errorHandler,
  getDistrictOptions,
  getSubDistrictOptions,
  isAdmin,
  isInspectionGarage,
  isMember,
  isMobileDevice,
  thaiToDefaultDate,
  toThaiDate,
} from '../../utils/helper';

let BANKS = [];
let isMounted = null;

class AffiliateInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      affiliate: {
        user: {},
        additional_info: {},
        line_users: [],
        commission_rates: [],
        broker_license_expiry_date: null,
        broker_license_number: null,
        address: {},
        address_nid: {},
        docs_verification_status: null,
      },
      bankDetails: {
        ac_holder_name: '',
        ac_number: '',
        bank_name: '',
      },
      loading: true,
      editable: true,
      errors: {},
      fileList: {},
      fileListLoading: {},
      updatedBankData: {},
      updatedAffiliateData: {},
      districts: [],
      sub_districts: [],
      districts_nid: [],
      sub_districts_nid: [],
      showModal: false,
      downArrow: true,
    };

    PROVINCES.map((data) => {
      data.key = data.provincecode;
      data.text = data.provincename;
      data.value = data.provincename;

      return data;
    });
    this.saveButton = React.createRef();
    this.titleElement = React.createRef();
  }

  componentDidMount() {
    window.console.log(this.props.affiliate);
    this.id = null;
    isMounted = true;

    BANKS = Object.keys(this.props.languageMap.banks).map((key) => {
      return {
        key,
        value: key,
        text: this.props.languageMap.banks[key],
      };
    });

    this.getAffiliateProfile(this.props.data.id);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  close = (data = undefined) => {
    this.props.hideModal(null, {
      ...this.props,
    });
    this.props.nextFn && this.props.nextFn(data);
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  affinfoCheck = (affiliate) => {
    if (isMember()) {
      this.close();
      return;
    }
    let showModal = false;
    let disableClose = false;

    window.console.log(affiliate);

    if (!affiliate.bank_details || !affiliate.bank_details.length) {
      showModal = true;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      let bankDetails = affiliate.bank_details[0];
      if (!bankDetails || !bankDetails.ac_number || !bankDetails.bank_name) {
        showModal = true;
      }
    }
    if (!affiliate.documents || !affiliate.documents.length) {
      showModal = true;
    }
    if (affiliate.documents && affiliate.documents.length) {
      let obj = {};
      affiliate.documents.forEach((data) => {
        obj[data.document_type] = true;
      });
      if (!obj.broker_license) {
        showModal = true;
      }
    }
    if (!affiliate.address) {
      showModal = true;
    }
    if (
      affiliate.address &&
      (!affiliate.address.first_line ||
        !affiliate.address.district ||
        !affiliate.address.province ||
        !affiliate.address.subdistrict ||
        !affiliate.address.postal_code)
    ) {
      showModal = true;
    }

    if (!affiliate.address_nid) {
      showModal = true;
    }
    if (
      !affiliate.address_nid ||
      !affiliate.address_nid.first_line ||
      !affiliate.address_nid.district ||
      !affiliate.address_nid.province ||
      !affiliate.address_nid.subdistrict ||
      !affiliate.address_nid.postal_code
    ) {
      showModal = true;
    }

    if (['not_yet', 'rejected'].indexOf(affiliate.docs_verification_status) > -1) {
      showModal = true;
      disableClose = true;
    }
    if (!isInspectionGarage(affiliate) && (!affiliate.national_id || !affiliate.broker_license_number)) {
      showModal = true;
      disableClose = true;
    }
    window.console.log(showModal);
    if (showModal) {
      this.setState({
        showModal: true,
        disableClose: disableClose,
      });
    } else {
      this.close();
    }
  };

  getAffiliateProfile = (id) => {
    let bankDetails = null;
    let fileList = {};
    api.crm
      .affiliate(id)
      .then((data) => {
        let obj = {},
          affiliate = data.message;

        if (affiliate.broker_license_expiry_date) {
          affiliate.broker_license_expiry_date = toThaiDate(affiliate.broker_license_expiry_date);
        }
        if (!affiliate.address) {
          affiliate.address = {};
        }

        if (affiliate.address) {
          obj = affiliate.address;
        }

        if (!affiliate.address_nid) {
          affiliate.address_nid = {};
        }

        if (affiliate.address_nid) {
          obj = { ...obj, address_nid: affiliate.address_nid };
        }
        if (affiliate.user) {
          obj.fullname = affiliate.user.fullname;
        }
        if (affiliate.bank_details && affiliate.bank_details.length) {
          bankDetails = affiliate.bank_details[0];
        }
        if (affiliate && affiliate.documents && affiliate.documents.length) {
          affiliate.documents.forEach((data) => {
            if (!fileList[data.document_type]) {
              fileList[data.document_type] = [];
            }
            data.file.name = data.file.original_file_name;
            fileList[data.document_type].push(data);
          });
          this.setState({
            fileList,
          });
          window.console.log(fileList);
        }
        this.setState(
          {
            affiliate,
            loading: false,
            data: obj,
            fileList,
            bankDetails: bankDetails || this.state.bankDetails,
          },
          () => {
            this.affinfoCheck(affiliate);
          }
        );
        this.id = affiliate.id;
        this.populateDropDowns(affiliate);
        this.populateDropDowns(affiliate, 'address_nid');
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  getAffiliateProfileApiCall = () => {
    return api.crm
      .getAffiliateProfile({ with_commission_rates: true })
      .then((data) => {
        window.console.log(data);
        // store.dispatch(setAffiliate(data.message));
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleChange =
    (name, addressKey = 'address') =>
    (e) => {
      if (!e) {
        // Happening in case of date picker.
        return;
      }
      let updatedAffiliateData = this.state.updatedAffiliateData,
        affiliate = this.state.affiliate;
      let value = null;
      if (moment.isMoment(e)) {
        value = e;
      } else {
        value = e.target.value;
      }
      if (name === 'ac_number') {
        let pattern = new RegExp(/^[\d - .]+$/);
        if (!pattern.test(value ? parseInt(value) : value) && value) return false;
      }
      if (name === 'ac_number' || name === 'ac_holder_name') {
        this.setState({
          bankDetails: { ...this.state.bankDetails, [name]: value },
          updatedBankData: { ...this.state.updatedBankData, [name]: value },
        });
        return;
      }
      if (name === 'first_line' || name === 'postal_code') {
        let { updatedAffiliateData, affiliate } = this.state;
        affiliate[addressKey][name] = value;
        if (!updatedAffiliateData[addressKey]) {
          updatedAffiliateData[addressKey] = {};
        }
        updatedAffiliateData[addressKey][name] = value;
        this.setState({
          affiliate,
          updatedAffiliateData,
        });
      } else if (name === 'copy_address') {
        let { updatedAffiliateData, affiliate, districts, sub_districts, copy_address } = this.state;
        let districts_nid = [],
          sub_districts_nid = [];
        copy_address = e.target.checked;
        if (e.target.checked) {
          let copyAddress = (({ first_line, subdistrict, district, province, postal_code }) => ({
            first_line,
            subdistrict,
            district,
            province,
            postal_code,
          }))(affiliate.address);
          affiliate.address_nid = copyAddress;
          updatedAffiliateData.address_nid = copyAddress;
          districts_nid = districts;
          sub_districts_nid = sub_districts;
        } else {
          affiliate.address_nid = {
            first_line: '',
            subdistrict: '',
            district: '',
            province: '',
            postal_code: '',
          };
          delete updatedAffiliateData.address_nid;
        }

        this.setState({
          affiliate,
          updatedAffiliateData,
          districts_nid,
          sub_districts_nid,
          copy_address,
        });
        return;
      }
      this.setState({
        affiliate: { ...this.state.affiliate, [name]: value },
        updatedAffiliateData: { ...this.state.updatedAffiliateData, [name]: value },
      });
    };

  handleBankChange = (evt, data) => {
    window.console.log(data);
    this.setState({
      bankDetails: { ...this.state.bankDetails, [data.name]: data.value },
      updatedBankData: { ...this.state.updatedBankData, [data.name]: data.value },
    });
  };

  populateDropDowns = async (affiliate, addressKey = 'address') => {
    if (!affiliate[addressKey]) {
      return;
    }
    let districtsKey = 'districts';
    let subDistrictsKey = 'sub_districts';
    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }
    if (affiliate[addressKey].subdistrict) {
      let provinceName = affiliate[addressKey].province,
        districtName = affiliate[addressKey].district,
        province = PROVINCES.find((province) => province.provincename === provinceName);

      let districts = [],
        subDistricts = [];

      if (province) {
        // Populate list of districts if province selected
        districts = await getDistrictOptions(province.provincecode);
      }
      if (districtName) {
        // Populate list of sub-districts if district selected
        let district = districts.find((district) => district.districtname === districtName);
        if (district) {
          subDistricts = await getSubDistrictOptions(district.districtcode);
        }
      }
      if (!isMounted) {
        return;
      }
      this.setState({
        [districtsKey]: districts,
        [subDistrictsKey]: subDistricts,
      });
    }
  };

  handleProvinceChange = async (e, data, addressKey = 'address') => {
    let updatedValues = {
        district: null,
        subdistrict: null,
        province: data.value,
      },
      districtsKey = 'districts',
      subDistrictsKey = 'sub_districts';

    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    let provinceCode = PROVINCES.find((province) => province.provincename === data.value).provincecode;
    let districts = await getDistrictOptions(provinceCode);

    let stateData = this.state.affiliate;
    window.console.log(this.state.affiliate);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedAffiliateData = this.state.updatedAffiliateData;
    if (!updatedAffiliateData[addressKey]) {
      updatedAffiliateData[addressKey] = updatedValues;
    } else {
      updatedAffiliateData[addressKey] = { ...updatedAffiliateData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [districtsKey]: districts,
      [subDistrictsKey]: [],
      affiliate: stateData,
      updatedAffiliateData,
    }));
  };

  handleDistrictChange = async (e, data, addressKey = 'address') => {
    let districtsKey = 'districts',
      subDistrictsKey = 'sub_districts';

    if (addressKey === 'address_nid') {
      districtsKey = 'districts_nid';
      subDistrictsKey = 'sub_districts_nid';
    }

    let districts = this.state[districtsKey];

    let updatedValues = {
      district: data.value,
      subdistrict: null,
    };

    let districtCode = districts.find((district) => district.districtname === data.value).districtcode;
    let subDistricts = await getSubDistrictOptions(districtCode);
    let stateData = this.state.affiliate;
    window.console.log(this.state.affiliate);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedAffiliateData = this.state.updatedAffiliateData;
    if (!updatedAffiliateData[addressKey]) {
      updatedAffiliateData[addressKey] = updatedValues;
    } else {
      updatedAffiliateData[addressKey] = { ...updatedAffiliateData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      [subDistrictsKey]: subDistricts,
      affiliate: stateData,
      updatedAffiliateData,
    }));
  };

  handleSubDistrictChange = (e, data, addressKey = 'address') => {
    let subDistrictsKey = 'sub_districts';

    if (addressKey === 'address_nid') {
      subDistrictsKey = 'sub_districts_nid';
    }

    let sub_districts = this.state[subDistrictsKey];

    let updatedValues = {
      subdistrict: data.value,
      postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === data.value).zip,
    };
    let stateData = this.state.affiliate;
    window.console.log(this.state.affiliate);
    stateData[addressKey] = { ...stateData[addressKey], ...updatedValues };
    let updatedAffiliateData = this.state.updatedAffiliateData;
    if (!updatedAffiliateData[addressKey]) {
      updatedAffiliateData[addressKey] = updatedValues;
    } else {
      updatedAffiliateData[addressKey] = { ...updatedAffiliateData[addressKey], ...updatedValues };
    }

    this.setState((state) => ({
      affiliate: stateData,
      updatedAffiliateData,
    }));
  };

  deleteAffiliateDocument = (data) => {
    window.console.log(data);
    api.user
      .deleteAffiliateDocument(data.id)
      .then((resp) => {
        window.console.log(resp);
        let fileList = this.state.fileList;
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        this.setState({
          fileList,
          updatedAffiliateData: true,
        });
        this.getAffiliateProfileApiCall();

        if (this.props.action) {
          this.props.action(this.id);
        }
      })
      .catch((error) => {
        // this.notify('Something went wrong', 'error');
        errorHandler(error.response, true);
      });
  };

  uploadFile = (params, type, file) => {
    let fileListLoading = this.state.fileListLoading;
    fileListLoading[type] = true;
    this.setState({ fileListLoading });
    api.user
      .affiliateDocument(params, this.state.affiliate.id)
      .then((resp) => {
        window.console.log(this.state, params);
        fileListLoading[type] = false;
        let fileList = this.state.fileList;
        file.id = resp.message.id;
        file.document_type = type;

        if (!fileList[type]) {
          fileList[type] = [file];
        } else {
          fileList[type].push(file);
        }
        this.setState({ fileListLoading, fileList });
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');

        if (this.props.action) {
          this.props.action(this.id);
        }
        // this.getAffiliateProfileApiCall();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.file &&
          error.response.data.response.file.file
        ) {
          this.notify(
            this.props.languageMap.messages[error.response.data.response.file.file] ||
              error.response.data.response.file.file,
            'error'
          );
        } else {
          this.notify('Something went wrong', 'error');
        }
        fileListLoading[type] = false;
        this.setState({ fileListLoading });
      });
  };

  fileUploaded = (file, name) => {
    let params = new FormData();
    params.append('file', file);
    params.append('document_type', name);
    this.uploadFile(params, name, file);
  };

  deleteDocument = (data) => {
    window.console.log(data);
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteAffiliateDocument,
      data,
    });
  };

  patchAffiliate = (p = {}) => {
    let params = { ...this.state.updatedAffiliateData, ...p };
    this.setState({
      loading: true,
    });
    if (params.broker_license_expiry_date) {
      params.broker_license_expiry_date = thaiToDefaultDate(params.broker_license_expiry_date);
    }

    api.crm
      .patchAffiliate(this.state.affiliate.id, params)
      .then((resp) => {
        window.console.log(resp);
        this.setState({
          loading: false,
          updatedAffiliateData: {},
        });
        // this.getAffiliateProfileApiCall();
        this.notify(this.props.languageMap.messages.savedSuccessfully, 'success');
        this.close(resp.data);
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  };

  validateBankDetails = (data) => {
    window.console.log(data);
    const messages = this.props.languageMap.messages;
    let errors = {};
    let affiliate = this.state.affiliate;
    if (!data.ac_number) {
      errors.ac_number = messages.required;
    }
    if (!data.ac_holder_name) {
      errors.ac_holder_name = messages.required;
    }
    if (!data.bank_name) {
      errors.bank_name = messages.required;
    }
    if (!affiliate.national_id) {
      errors.national_id_entry = messages.required;
    }
    if (!affiliate.broker_license_number) {
      errors.broker_license_number_entry = messages.required;
    }

    if (
      affiliate.has_broker_license &&
      (!this.state.fileList['broker_license'] || !this.state.fileList['broker_license'].length)
    ) {
      errors.broker_license = messages.required;
    }
    let mandatoryAddressFields = ['first_line', 'province', 'district', 'subdistrict', 'postal_code'];
    mandatoryAddressFields.forEach((field) => {
      if (!affiliate.address || !affiliate.address[field]) {
        if (!errors.address) {
          errors.address = {};
        }
        errors.address[field] = messages.required;
      }
      if (!affiliate.address_nid || !affiliate.address_nid[field]) {
        if (!errors.address_nid) {
          errors.address_nid = {};
        }
        errors.address_nid[field] = messages.required;
      }
    });
    return errors;
  };

  saveBankDetails = () => {
    let errors = this.validateBankDetails(this.state.bankDetails);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    if (Object.keys(this.state.updatedAffiliateData).length || Object.keys(this.state.updatedBankData).length) {
      if (Object.keys(this.state.updatedBankData).length) {
        let params = { ...this.state.updatedBankData };
        if (params.ac_number) {
          params.ac_number = params.ac_number.replace(/-/g, '');
        }
        this.patchAffiliate({ bank_details: params });
        return;
      }
      this.patchAffiliate();
      return;
    }
    this.close();
  };

  gotoProfile = () => {
    if (isMobileDevice()) {
      this.props.history.push('/buy-insurance?type=profile');
    } else {
      this.props.history.push('/profile');
    }
  };

  downloadDocument = (data) => {
    window.console.log(data);
    let url = apiEndpoints.affiliateDocument + '/' + data.id;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  updateDocsDropdown = (e, data) => {
    this.setState({
      affiliate: { ...this.state.affiliate, docs_verification_status: data.value },
      updatedAffiliateData: { ...this.state.updatedAffiliateData, docs_verification_status: data.value },
    });
  };

  handleArrowClick = (e) => {
    if (this.state.downArrow) {
      if (this.saveButton.current) {
        this.saveButton.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    } else {
      if (this.titleElement.current) {
        this.titleElement.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }

    this.setState({
      downArrow: !this.state.downArrow,
    });
  };

  renderAffiliateAddress = (addressKey = 'address') => {
    let { affiliate, districts, sub_districts, errors, copy_address } = this.state;
    const languageMapAddress = this.props.languageMap.components.userAddress;

    if (addressKey === 'address_nid') {
      districts = this.state.districts_nid;
      sub_districts = this.state.sub_districts_nid;
    }

    return (
      <>
        {addressKey === 'address_nid' && <div className="section-title">{languageMapAddress.address_nid}</div>}
        {addressKey === 'address_nid' && (
          <div className="key-value">
            <div className="key">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(copy_address)}
                    onChange={this.handleChange('copy_address', addressKey)}
                    value="true"
                    color="primary"
                    className="checkbox"
                  />
                }
                label={languageMapAddress.copy_address}
              />
            </div>
          </div>
        )}
        <div>
          <div className="display-row">
            <div className="key-value display-col">
              <span className="key">{languageMapAddress.textThree}</span>
              <div className="value">
                <div className="form-field">
                  <input
                    type="text"
                    onChange={this.handleChange('first_line', addressKey)}
                    value={affiliate[addressKey].first_line || ''}
                  />
                  {/* <label htmlFor="" className="form-label">{languageMapAddress.textFour}</label> */}
                  {errors[addressKey] && errors[addressKey].postal_code && (
                    <InlineError text={errors[addressKey].postal_code} />
                  )}
                </div>
              </div>
            </div>
            <div className="key-value display-col">
              <span className="key">{languageMapAddress.textFive}</span>
              <div className="value">
                <div className="form-field">
                  <Dropdown
                    // placeholder={languageMapAddress.textSeven}
                    selection
                    options={PROVINCES}
                    onChange={(e, data) => this.handleProvinceChange(e, data, addressKey)}
                    name="province"
                    value={affiliate[addressKey].province}
                  />
                </div>
                {errors[addressKey] && errors[addressKey].province && (
                  <InlineError text={errors[addressKey].province} />
                )}
              </div>
            </div>
          </div>
          <div className="display-row">
            {districts.length ? (
              <div className="key-value display-col">
                <span className="key">{languageMapAddress.textSix}</span>
                <div className="value">
                  <div className="form-field">
                    <Dropdown
                      // placeholder={languageMapAddress.textSix}
                      selection
                      options={districts}
                      onChange={(e, data) => this.handleDistrictChange(e, data, addressKey)}
                      name="district"
                      value={affiliate[addressKey].district}
                    />
                  </div>
                  {errors[addressKey] && errors[addressKey].district && (
                    <InlineError text={errors[addressKey].district} />
                  )}
                </div>
              </div>
            ) : null}
            {sub_districts.length ? (
              <div className="key-value display-col">
                <span className="key">{languageMapAddress.textSeven}</span>
                <div className="value">
                  <div className="form-field">
                    <Dropdown
                      // placeholder={languageMapAddress.textFive}
                      selection
                      options={sub_districts}
                      onChange={(e, data) => this.handleSubDistrictChange(e, data, addressKey)}
                      name="subdistrict"
                      value={affiliate[addressKey].subdistrict}
                    />
                  </div>
                  {errors[addressKey] && errors[addressKey].subdistrict && (
                    <InlineError text={errors[addressKey].subdistrict} />
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="display-row">
            <div className="key-value display-col">
              <span className="key">{languageMapAddress.textFour}</span>
              <div className="value">
                <div className="form-field">
                  <input
                    type="number"
                    onChange={this.handleChange('postal_code', addressKey)}
                    value={affiliate[addressKey].postal_code || ''}
                  />
                  {/* <label htmlFor="" className="form-label">{languageMapAddress.textFour}</label> */}
                  {errors[addressKey] && errors[addressKey].postal_code && (
                    <InlineError text={errors[addressKey].postal_code} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderEditForm = () => {
    const {
      errors,
      fileList,
      fileListLoading,
      affiliate,
      bankDetails,
      loading,
      districts,
      sub_districts,
      disableClose,
      downArrow,
    } = this.state;
    const languageMap = this.props.languageMap.components.bankDetails;
    const languageMapAddress = this.props.languageMap.components.userAddress;

    window.console.log(affiliate);

    return (
      <div className={'fairdee-modal-wrapper edit-affiliate-info' + (isMobileDevice() ? ' small-medium' : ' large')}>
        <div className="modal-content">
          {!disableClose && <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />}
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textFourteen}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textFourteen}</h1>
          <p className="sub-title-text" ref={this.titleElement}>
            {languageMap.textFifteen}
          </p>
          <p className="sub-title-text mb-24">{languageMap.textSixteen}</p>
          <div className="inline-wrapper">
            <div>
              <div className="display-row">
                <div className="key-value display-col">
                  <span className="key">{languageMap.textNine}</span>
                  <div className="value">
                    <div className="form-field">
                      <input
                        type="text"
                        placeholder={languageMap.textNine}
                        onChange={this.handleChange('broker_license_number')}
                        value={affiliate.broker_license_number || ''}
                      />
                      {errors.broker_license_number_entry && <InlineError text={errors.broker_license_number_entry} />}
                    </div>
                  </div>
                </div>
                <div className="key-value display-col">
                  <span className="key">{languageMap.textFour}</span>
                  <div className="value">
                    <div className="form-field">
                      <input
                        type="text"
                        placeholder={languageMap.textFour}
                        onChange={this.handleChange('national_id')}
                        value={affiliate.national_id || ''}
                      />
                      {errors.national_id_entry && <InlineError text={errors.national_id_entry} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="display-row">
                {Object.keys(fileList).includes('national_id') ? (
                  <div className="key-value display-col">
                    <span className="key">{languageMap.textFour}</span>
                    <div className="value">
                      <FileUpload
                        files={fileList['national_id']}
                        loading={fileListLoading['national_id']}
                        name="national_id"
                        onFileUpload={this.fileUploaded}
                        onFileRemove={this.deleteDocument}
                        limit={5}
                        btnText={languageMap.textSeven}
                        download={true}
                        downloadPath={apiEndpoints.affiliateDocument}
                      />
                      {errors.national_id && <InlineError text={errors.national_id} />}
                    </div>
                  </div>
                ) : null}
                {affiliate.has_broker_license ? (
                  <div className="key-value display-col">
                    <span className="key">{languageMap.textFive}</span>
                    <div className="value">
                      <FileUpload
                        files={fileList['broker_license']}
                        loading={fileListLoading['broker_license']}
                        name="broker_license"
                        onFileUpload={this.fileUploaded}
                        onFileRemove={this.deleteDocument}
                        limit={5}
                        btnText={languageMap.textSeven}
                        download={true}
                        downloadPath={apiEndpoints.affiliateDocument}
                      />
                      {errors.broker_license && <InlineError text={errors.broker_license} />}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="display-row">
                <div className="key-value display-col">
                  <span className="key">{languageMap.textOne}</span>
                  <div className="value">
                    <div className="form-field">
                      <Dropdown
                        placeholder={languageMap.textOne}
                        selection
                        options={BANKS}
                        onChange={this.handleBankChange}
                        name="bank_name"
                        value={bankDetails.bank_name}
                        search
                      />
                      {errors.bank_name && <InlineError text={errors.bank_name} />}
                    </div>
                  </div>
                </div>
                <div className="key-value display-col">
                  <span className="key">{languageMap.textTwo}</span>
                  <div className="value">
                    <div className="form-field">
                      <input
                        type="text"
                        placeholder={languageMap.textTwo}
                        onChange={this.handleChange('ac_number')}
                        value={bankDetails.ac_number || ''}
                      />
                      {errors.ac_number && <InlineError text={errors.ac_number} />}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="display-row">
                  <div className="key-value display-col">
                    <span className="key">{languageMap.textThree}</span>
                    <div className="value">
                      <div className="form-field">
                        <input
                          type="text"
                          placeholder={languageMap.textThree}
                          onChange={this.handleChange('ac_holder_name')}
                          value={bankDetails.ac_holder_name || ''}
                        />
                        {errors.ac_holder_name && <InlineError text={errors.ac_holder_name} />}
                      </div>
                    </div>
                  </div>
                </div>
                {affiliate.has_broker_license &&
                  isAdmin() &&
                  fileList['broker_license'] &&
                  fileList['broker_license'].length > 0 && (
                    <div className="key-value display-col">
                      <span className="key">{languageMap.docsVerification}</span>
                      <div className="value">
                        <div className="form-field">
                          <Dropdown
                            options={[
                              { text: languageMap.verified, value: 'verified' },
                              { text: languageMap.rejected, value: 'rejected' },
                              { text: languageMap.notYet, value: 'not_yet' },
                            ]}
                            value={affiliate.docs_verification_status}
                            onChange={this.updateDocsDropdown}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              <p className="line"></p>
              {affiliate.address ? this.renderAffiliateAddress() : null}
              {affiliate.address_nid ? this.renderAffiliateAddress('address_nid') : null}

              <div className="key-value" ref={this.saveButton}>
                <span className="key">&nbsp;</span>
                <div className="value">
                  <div className="buttons-wrap">
                    <button className={'button medium ' + (loading ? 'disabled' : '')} onClick={this.saveBankDetails}>
                      {languageMap.textSix}
                    </button>
                  </div>
                </div>
              </div>
              <div className={'arrow-down' + (!downArrow ? ' green' : ' orange')} onClick={this.handleArrowClick}>
                <img src={!downArrow ? ArrowUp : ArrowDown} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, showModal } = this.state;
    if (loading || !showModal) {
      return null;
    }
    return (
      <div className="affiliate-profile">
        <div className="affiliate-details">
          <div>{this.renderEditForm()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    languageMap: state.languageMap,
    affiliate: state.affiliate,
  };
}

export default withRouter(
  connect(mapStateToProps, { showModal, showNotification, setAffiliate, hideModal })(AffiliateInfoModal)
);
