import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InfoModal from '../../components/fairdee-x-plus/common/InfoModal';
import { API_LIMIT } from '../../constants';
import { MOBILE_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { debounce, errorHandler } from '../../utils/helper';

function AttachLeadgenAccount(props) {
  const languageMap = useSelector((state) => state.languageMap),
    leadgenModalLang = languageMap.components.leadGen.modal,
    fairdeeXplusLang = languageMap.fairdeeXplus,
    [affiliates, setAffiliates] = useState([]),
    [affiliate, setAffiliate] = useState(''),
    [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    loadAffiliates();
  }, [props]);

  function loadAffiliates(p = {}) {
    setSearchLoading(true);
    if (props.agent) {
      p = { ...p, broker_license_number: props.agent.broker_license_number };
    } else if (props.isBuyInsurance) {
      p = { ...p, is_oic_data_verified: true };
    }
    if (props.agent_type) {
      p = { ...p, account_type: props.agent_type };
    }
    if (props.levelOneAllowed) {
      delete p.is_oic_data_verified;
    }
    p.limit = API_LIMIT;
    api.crm
      .getAffiliates(p)
      .then((res) =>
        setAffiliates(
          res.results
            .filter((aff) => !aff.bb_account)
            .map((affiliate) => {
              return {
                text: `${affiliate.agent_code}-${affiliate.user.fullname}`,
                value: affiliate.id,
                key: affiliate.id,
                name: affiliate.user.fullname,
                is_blocked: affiliate.is_blocked,
                agent_code: affiliate.agent_code,
              };
            })
        )
      )
      .finally(() => setSearchLoading(false));
  }

  function handleSearchChange(e, { searchQuery }) {
    loadAffiliates({ search: searchQuery });
  }

  function openBlockInfoModal(fdCode) {
    props.showModal(MOBILE_MODAL, {
      component: (
        <InfoModal
          title={fairdeeXplusLang[`Unable to Proceed`]}
          text={`${fairdeeXplusLang[`This agent is affiliated with account`]} ${fdCode} ${
            fairdeeXplusLang[
              `which has been temporarily blocked. Please do not proceed with any further actions. You will be penalised for doing so.`
            ]
          }`}
        />
      ),
      uid: 'INFO_MODAL',
      className: 'full-screen',
    });
  }

  function close() {
    props.hideModal(null, { ...props });
  }

  function action() {
    let aff = affiliates.find((aff) => aff.value === affiliate);
    if (aff.is_blocked) {
      openBlockInfoModal(aff.agent_code);
      return;
    }
    if (props.isBuyInsurance) {
      props.action(affiliate);
      close();
      return;
    }
    api.crm
      .patchAffiliate(props.agent.id, { lg_account_id: affiliate })
      .then(() => {
        notify(languageMap.messages.updatedSuccessfully, 'success');
        props.action && props.action();
        close();
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">{leadgenModalLang.chooseAffiliate}</span>
          </p>
        </div>
        <h1 className="title-text">{leadgenModalLang.chooseAffiliate}</h1>
        <div className="qr-details">
          <div className="form-wrapper">
            <div className="form-field">
              <label htmlFor="lg_account">
                {!props.action ? leadgenModalLang.leadGenAccount : leadgenModalLang.affiliate}
              </label>
              <Dropdown
                fluid
                search={() => affiliates}
                selection
                options={affiliates}
                onChange={(e, data) => setAffiliate(data.value)}
                value={affiliate}
                name="lg_account"
                onSearchChange={debounce(handleSearchChange, 500)}
                loading={searchLoading}
              />
            </div>
            <div className="button-wrap">
              <button className="button medium orange-bordered-button" onClick={close}>
                {leadgenModalLang.close}
              </button>
              <button
                className={`button medium ${!affiliate ? ' disabled' : ''}`}
                onClick={action}
                disabled={!affiliate}
              >
                {leadgenModalLang.continue}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showModal, showNotification })(AttachLeadgenAccount);
