import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import { MODAL_TYPE_DELETE, MODAL_TYPE_REMARK } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { formattedDate } from '../../utils/helper';

class RemarskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remarks: [],
      shouldCallAction: false,
    };
  }

  componentDidMount() {
    this.getRemarks(false);
  }

  close = () => {
    if (this.state.shouldCallAction && this.props.action) {
      this.props.action();
    }
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  openRemarkModal = (data, isEdit = false) => {
    if (this.props.data_affected && !isEdit) {
      this.props.showModal(MODAL_TYPE_REMARK, {
        data_affected: 'manual_quotation_status',
        data_value: this.props.data_value,
        objectId: this.props.objectId,
        data: this.props.data,
        postCall: this.props.postCall,
        patchCall: this.props.patchCall,
        entity: 'quotation_query',
        action: () => this.getRemarks(),
      });
    } else if (this.props.data_affected && !isEdit) {
      this.props.showModal(MODAL_TYPE_REMARK, {
        data_affected: 'manual_quotation_status',
        data_value: this.props.data_value,
        objectId: this.props.objectId,
        data: data,
        postCall: this.props.postCall,
        patchCall: this.props.patchCall,
        entity: 'quotation_query',
        action: () => this.getRemarks(),
      });
    } else {
      this.props.showModal(MODAL_TYPE_REMARK, {
        objectId: this.props.objectId || this.props.quotationId,
        action: this.getRemarks,
        data: data || null,
        patchCall: this.props.patchCall,
        postCall: this.props.postCall,
        entity: this.props.entity,
      });
    }
  };

  openDeleteModal = (id) => {
    this.props.showModal(MODAL_TYPE_DELETE, {
      id,
      action: this.deleteRemark,
    });
  };

  editRemark = (data) => {
    this.openRemarkModal(data, true);
  };

  deleteRemark = (id) => {
    let promise = this.props.deleteCall ? this.props.deleteCall(id) : api.utils.deleteRemark(id);
    promise
      .then((resp) => {
        this.getRemarks();
      })
      .catch((error) => console.log(error));
  };

  getRemarks = (shouldCallAction = true) => {
    let entity = this.props.entity || 'quotation',
      id = this.props.objectId || this.props.quotationId || null;
    if (!id) {
      return;
    }

    let promise = this.props.getCall
      ? this.props.getCall(null, { [entity]: id })
      : api.utils.getRemarks(null, { [entity]: id });

    promise
      .then((resp) => {
        this.setState({
          remarks: resp.results ? resp.results : resp,
          shouldCallAction,
        });
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { remarks } = this.state;
    const { user } = this.props;
    let isAdmin = user.roles.includes('admin');
    const languageMap = this.props.languageMap.components.remarksModal;

    return (
      <div className="fairdee-modal-wrapper customer-info remarks">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textOne}</h1>
          <div className="remarks-wrapper">
            {remarks.length
              ? remarks.map((data, index) => {
                  let isCreator = data.created_by == user.id;

                  return (
                    <div className="remark-card" key={index}>
                      <div className="content">
                        <p className="text">{data.remark}</p>
                        <p className="date">{formattedDate(data.created_at)}</p>
                      </div>
                      <div className="remark-footer">
                        <p className="date">{formattedDate(data.created_at)}</p>
                        {(isCreator || isAdmin) && (
                          <div onClick={this.openDeleteModal.bind(null, data.id)}>{languageMap.textTwo}</div>
                        )}
                        {isCreator && <div onClick={this.editRemark.bind(null, data)}>{languageMap.textThree}</div>}
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
          {this.props.user.roles && this.props.user.roles.includes('admin') ? (
            <div className="buttons-wrap single">
              <button className="button medium  orange-bordered-button" onClick={this.openRemarkModal}>
                {languageMap.textFour}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

RemarskModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(RemarskModal));
