import api from '../commonjs/api';
import { AFFILIATE } from '../constants/types';

export const setAffiliate = (affiliate) => ({
  type: AFFILIATE,
  affiliate,
});

export const affiliateProfile = () => (dispatch) =>
  api.crm
    .getAffiliateProfile({
      with_commission_rates: true,
      with_instalment_credits: true,
      instalment_credits_with_vendor: true,
    })
    .then((affiliate) => {
      dispatch(setAffiliate(affiliate.message));
    });
