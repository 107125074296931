import Input from '@material-ui/core/Input';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler } from '../../utils/helper';

function LeadGenCodeModal(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const leadgenModalLang = languageMap.components.leadGen.modal;
  const [leadGenCode, setLeadGenCode] = useState('');
  const [errors, setErrors] = useState({});

  function close() {
    props.hideModal(null, { ...props });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function action() {
    if (props.checkLeadgen) {
      api.user
        .getLeadGenCodes(
          null,
          {
            is_leadgen: true,
            order: '-id',
          },
          props.affiliate
        )
        .then((res) => {
          let found = res.results.find((lgCode) => lgCode.code === leadGenCode);
          if (!found) {
            notify('Invalid LG Code', 'error');
            return;
          }
          close();
          props.action && props.action(leadGenCode);
        })
        .catch((err) => {
          close();
          errorHandler(err.response, true);
        });
    } else {
      props.action && props.action(leadGenCode);
      close();
    }
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        {!props.hideClose && <img src={cross} className="close" alt="" onClick={close} />}
        <div className="mobile-header">
          <p
            className="current-page"
            onClick={() => {
              !props.hideClose && close();
            }}
          >
            {!props.hideClose && <img src={crossLg} alt="" />}
            <span className="title-text">{leadgenModalLang.enterLeadgenCode}</span>
          </p>
        </div>
        <h1 className="title-text">{leadgenModalLang.enterLeadgenCode}</h1>
        <div className="qr-details">
          <div className="form-wrapper">
            <div className="form-field">
              <label htmlFor="leadgen_code">{leadgenModalLang.code}</label>
              <Input
                name="leadgen_code"
                onChange={(e) => setLeadGenCode(e.target.value)}
                className={errors.incentive_amount ? 'error' : ''}
                value={leadGenCode}
              />
              {errors.leadGenCode && <InlineError text={errors.leadGenCode} />}
            </div>
          </div>
        </div>
        <div className="button-wrap">
          {!props.hideClose && (
            <button className="button medium orange-bordered-button" onClick={close}>
              {leadgenModalLang.close}
            </button>
          )}
          <button
            className={`button medium ${!leadGenCode ? ' disabled' : ''}`}
            onClick={action}
            disabled={!leadGenCode}
          >
            {leadgenModalLang.continue}
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showNotification })(LeadGenCodeModal);
