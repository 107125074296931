import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';

function Modal(props) {
  const { component } = props;
  const dispatch = useDispatch();
  function close() {
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  }

  const Component = component;

  return (
    <div className="fairdee-modal-wrapper crm-modal">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={cross} alt="" />
            <span className="title-text">&nbsp;</span>
          </p>
        </div>
        <Component close={close} {...props} />
      </div>
    </div>
  );
}

Modal.propTypes = {
  component: PropTypes.any.isRequired,
};

export default Modal;
