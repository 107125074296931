import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Form, Label, Message, Radio, TextArea } from 'semantic-ui-react';
import { TYPES_OF_ENDORSEMENT, TYPE_TO_SUBTYPE_MAP } from '../../constants/endorsements';
import InlineError from '../InlineError';

function BasicDetails(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const endorsementLangMap = languageMap.components.endorsement;
  const modalLangMap = endorsementLangMap.endorsementModal;
  const REJECTION_REASONS = languageMap.components.fairdeeWizard.docsRejection.docsRejectionReason;

  const rejectionReasonsMap = Object.keys(REJECTION_REASONS).map((rejectionReason) => ({
    text: REJECTION_REASONS[rejectionReason],
    value: rejectionReason,
  }));

  const isMapped = (rejectionReason) => {
    if (rejectionReason) {
      let isPresent = false;
      rejectionReasonsMap.map((reason) => {
        if (reason.value === rejectionReason) {
          isPresent = true;
          return;
        }
      });

      return isPresent;
    }
    return true;
  };

  const isAdditionalReasonMapped = (rejectionReason, additionalReason) => {
    if (!isMapped(rejectionReason) && additionalReason) {
      return rejectionReason + ' ' + additionalReason;
    } else if (!isMapped(rejectionReason)) {
      return rejectionReason;
    } else if (additionalReason) {
      return additionalReason;
    }
    return '';
  };

  return (
    <Form className="no-frills-form">
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.typeOfPolicy}
      </Label>
      <Form.Field>
        <Checkbox
          label={modalLangMap.isCompulsory}
          name="is_compulsory"
          checked={Boolean(props.data.is_compulsory)}
          onChange={(e, data) => props.onCheckboxChange(e, data, 'is_compulsory')}
          disabled={props.blockDataUpdate}
        />
      </Form.Field>
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.typeOfEndorsement}
      </Label>
      <Form.Group unstackable widths={2}>
        {TYPES_OF_ENDORSEMENT.map((type, index) => (
          <Form.Field key={index}>
            <Radio
              key={index}
              label={endorsementLangMap.typeOfEndorsement[type]}
              name={type}
              value={type}
              checked={Boolean(props.data.type_of_endorsement === type)}
              onChange={(e, data) => props.onRadioChange(e, data, 'type_of_endorsement')}
              disabled={props.blockDataUpdate}
            />
            {props.errors.type_of_endorsement && <InlineError text={props.errors.type_of_endorsement} />}
          </Form.Field>
        ))}
      </Form.Group>
      {props.data.type_of_endorsement &&
        props.data.type_of_endorsement !== 'policy_cancelled' &&
        TYPE_TO_SUBTYPE_MAP[props.data.type_of_endorsement].length && (
          <>
            <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
              {modalLangMap.subtypeOfEndorsement}
            </Label>
            <Form.Group unstackable widths={2}>
              {TYPE_TO_SUBTYPE_MAP[props.data.type_of_endorsement].map((type, index) => (
                <Form.Field key={index}>
                  <Checkbox
                    key={index}
                    label={endorsementLangMap.typeOfEndorsement[type]}
                    name={type}
                    checked={props.data.subtype_of_endorsement.hasOwnProperty(type)}
                    onChange={(e, data) => props.onCheckboxChange(e, data, 'subtype_of_endorsement')}
                    disabled={props.blockDataUpdate}
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </>
        )}
      {props.data.type_of_endorsement &&
        ['premium_addition', 'premium_reduction'].includes(props.data.type_of_endorsement) && (
          <>
            <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
              {modalLangMap.additionalSubtypes}
            </Label>
            <Form.Group unstackable widths={2}>
              {TYPE_TO_SUBTYPE_MAP.premium_unaffected.map((type, index) => (
                <Form.Field key={index}>
                  <Checkbox
                    key={index}
                    label={endorsementLangMap.typeOfEndorsement[type]}
                    name={type}
                    checked={props.data.subtype_of_endorsement.hasOwnProperty(type)}
                    onChange={(e, data) => props.onCheckboxChange(e, data, 'subtype_of_endorsement')}
                    disabled={props.blockDataUpdate}
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </>
        )}

      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.monetoryDetails}
      </Label>
      <Form.Group widths={3} unstackable>
        <Form.Field disabled={props.data.type_of_endorsement === 'policy_cancelled' || props.blockDataUpdate}>
          <label htmlFor="premium_after_tax">{modalLangMap.premiumAfterTax}</label>
          <input
            type="text"
            id="premium_after_tax"
            name="premium_after_tax"
            onChange={props.handleChange.bind(null, 'premium_after_tax')}
            value={props.data.premium_after_tax}
            disabled={props.blockDataUpdates}
          />
          {props.errors.premium_after_tax && <InlineError text={props.errors.premium_after_tax} />}
        </Form.Field>
        <Form.Field disabled={props.data.type_of_endorsement === 'policy_cancelled' || props.blockDataUpdate}>
          <label htmlFor="commission_delta">{modalLangMap.commission}</label>
          <input
            type="text"
            id="commission_delta"
            name="commission_delta"
            onChange={props.handleChange.bind(null, 'commission_delta')}
            value={props.data.commission_delta}
            disabled={props.blockDataUpdates}
          />
          {props.errors.commission_delta && <InlineError text={props.errors.commission_delta} />}
        </Form.Field>
        <Form.Field disabled={props.data.type_of_endorsement === 'policy_cancelled' || props.blockDataUpdate}>
          <label htmlFor="incentive_delta">{modalLangMap.incentive}</label>
          <input
            type="text"
            id="incentive_delta"
            name="incentive_delta"
            onChange={props.handleChange.bind(null, 'incentive_delta')}
            value={props.data.incentive_delta}
            disabled={props.blockDataUpdates}
          />
          {props.errors.incentive_delta && <InlineError text={props.errors.incentive_delta} />}
        </Form.Field>

        <Message compact size={'mini'} color="yellow">
          *{modalLangMap.monetoryMessage}
        </Message>
      </Form.Group>
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.cancellationReason}
      </Label>
      <Form.Field disabled={props.blockDataUpdate}>
        <label htmlFor="rejection_reason">{modalLangMap.cancellationReason}</label>
        <Dropdown
          placeholder={modalLangMap.reasonForCancellation}
          options={rejectionReasonsMap}
          value={
            isMapped(props.data?.rejection_reason?.rejection_reason)
              ? props.data?.rejection_reason?.rejection_reason
              : "Don't select this option"
          }
          onChange={(e, data) => props.onDropdownChange(e, data, 'rejection_reason')}
          search
          selection
        />
        {props.errors.rejection_reason?.rejection_reason && (
          <InlineError text={props.errors.rejection_reason?.rejection_reason} />
        )}
      </Form.Field>
      <Form.Field disabled={props.blockDataUpdate}>
        <TextArea
          id="additional_rejection_reason"
          name="additional_rejection_reason"
          placeholder={modalLangMap.additionalReasonForCancellation}
          onChange={props.handleChange.bind(null, 'additional_rejection_reason')}
          style={{ minHeight: 100 }}
          value={isAdditionalReasonMapped(
            props.data?.rejection_reason?.rejection_reason,
            props.data?.rejection_reason?.additional_rejection_reason
          )}
        />
        {props.errors.rejection_reason?.additional_rejection_reason && (
          <InlineError text={props.errors.rejection_reason?.additional_rejection_reason} />
        )}
      </Form.Field>
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {modalLangMap.additionalDetails}
      </Label>
      <Form.Field>
        <label htmlFor="endorsement_details">{modalLangMap.endorsementDetails}</label>
        <TextArea
          id="endorsement_details"
          name="endorsement_details"
          placeholder={modalLangMap.tellUsMore}
          onChange={props.handleChange.bind(null, 'endorsement_details')}
          style={{ minHeight: 100 }}
          value={props.data.endorsement_details}
        />
        {props.errors.endorsement_details && <InlineError text={props.errors.endorsement_details} />}
      </Form.Field>
    </Form>
  );
}

export default BasicDetails;
