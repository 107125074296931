import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { login, userProfile } from '../../actions/auth';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { MODAL_TYPE_LOGIN, NOTIFICATION } from '../../constants/types';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import {
  getParameterByName,
  getUserData,
  isLoggedIn,
  setAccessToken,
  setAxiosHeaders,
  setLanguageToElement,
  setUserData,
} from '../../utils/helper';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: '',
        passwordOne: '',
      },
      loading: false,
      errors: {},
      passwordStrength: '',
      showPasswordInfo: false,
    };
  }

  componentDidMount() {
    setLanguageToElement();
  }

  openSigninModal = () => {
    this.props.showModal(MODAL_TYPE_LOGIN, {});
  };

  close = () => {
    if (getUserData() && getUserData().email) {
      this.props.history.push('/policy-information');
    } else {
      this.props.history.push('/');
    }
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  userProfile = () => {
    this.props.userProfile();
  };

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;

    if (data.passwordOne && data.password && data.password !== data.passwordOne) {
      errors.passwordOne = languageMap.comparePassword;
    }
    if (!data.password) {
      errors.password = languageMap.required;
    }
    if (!data.passwordOne) {
      errors.passwordOne = languageMap.required;
    }
    return errors;
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const uid = getParameterByName('uid');
      const token = getParameterByName('token');

      const params = {
        password: this.state.data.password,
        uid,
        token,
      };
      this.setState({ loading: true });
      api.user
        .resetPassword(params)
        .then((resp) => {
          this.setState({ loading: false });
          if (!isLoggedIn()) {
            if (window.history.pushState) {
              let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
              window.history.pushState({ path: newurl }, '', newurl);
            }
            if (resp.token && resp.token.access_token) {
              // localStorage.accessToken = resp.token.access_token;
              setAccessToken(resp.token.access_token);
              setAxiosHeaders();
              this.userProfile();
              this.close();
              return;
            }
            this.props.history.push('/');
          } else {
            const userProfile = getUserData();
            if (resp.token && resp.token.access_token) {
              // localStorage.accessToken = resp.token.access_token;
              setAccessToken(resp.token.access_token);
              userProfile.accessToken = resp.token.access_token;
            }
            userProfile.is_email_verified = true;
            setUserData(userProfile);
            if (userProfile && userProfile && userProfile.state < 4) {
              this.props.history.push('/get-quotes');
            } else {
              this.props.history.push('/policy-information');
            }
            this.close();
          }
          this.notify('Password has been successfully updated.', 'success');
        })
        .catch((error) => {
          if (!error.response) {
            return;
          }
          this.setState({ loading: false });
          // this.close();
          if (error && error.response && error.response.data && error.response.data.response) {
            Object.keys(error.response.data.response).forEach(function (key, index) {
              if (key === 'token') {
                this.notify(error.response.data.response[key][0], 'error');
              }
              if (key === 'uid') {
                this.notify(error.response.data.response[key][0], 'error');
              }
              if (key === 'password') {
                let errors = {
                  passwordOne: error.response.data.response[key][0],
                };
                this.setState({
                  errors,
                });
              }
            }, this);
          } else if (error && error.response && error.response.data && error.response.data.error === 'banned_user') {
            this.notify(this.props.languageMap.messages.bannedUser, 'error');
          } else {
            this.notify('Error in resetting password', 'error');
          }
        });
    }
  };

  togglePasswordInfo = () => {
    this.setState({
      showPasswordInfo: !this.state.showPasswordInfo,
    });
  };

  render() {
    let languageMap = this.props.languageMap.components.resetPassword;
    if (this.props.type === 'set-password') {
      languageMap = this.props.languageMap.components.setPassword;
    }
    const { data, errors } = this.state;

    return (
      <div className="fairdee-modal-wrapper small">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.titleText}</span>
            </p>
          </div>
          <p className="title-text">{languageMap.titleText}</p>
          <Form noValidate onSubmit={this.onSubmit}>
            <Form.Field>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={languageMap.inputOne}
                value={data.password}
                onChange={this.onChange}
              />
              <label htmlFor="password" className="form-label">
                {languageMap.inputOne}
              </label>
            </Form.Field>
            <Form.Field className="mb-10">
              <input
                type="password"
                id="passwordOne"
                name="passwordOne"
                placeholder={languageMap.inputTwo}
                value={data.passwordOne}
                onChange={this.onChange}
              />
              <label htmlFor="passwordOne" className="form-label">
                {languageMap.inputTwo}
              </label>
              {errors.passwordOne && <InlineError text={errors.passwordOne} />}
            </Form.Field>
            {errors.global && <InlineError text={errors.global} />}
            <div className="button-wrap">
              <button className="button medium" onClick={this.submit}>
                {languageMap.buttonText}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(
  connect(mapStateToProps, { hideModal, showModal, showNotification, login, userProfile })(ResetPassword)
);
