import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import api from '../commonjs/api';
import { dropdownYears } from '../commonjs/commonData';
import SearchList from '../components/SearchList';
import { default as PROVINCES } from '../constants/thai-provinces.json';
import InlineError from './InlineError';
import validator from 'validator';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '20px',
    width: '100%',
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  formLabel: {
    margin: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  datepicker: {
    width: '100%',
    marginBottom: '24px',
  },
  fullWidth: {
    width: '100%',
    display: 'block',
  },
  label: {
    marginBottom: '12px',
  },
});

const DEFAULT_DATA = {
  make: '',
  make_id: '',
  make_model: '',
  make_model_id: '',
  cc: '',
  cc_id: '',
  description: '',
  description_name: '',
  vehicle_number: '',
  chassis_number: '',
  registration_province: '',
};

class CarForm extends React.Component {
  constructor(props) {
    super(props);
    const { quotation } = this.props;

    this.state = {
      data: null,
      carData: {},
      errors: {},
      inputState: 0,
      updatedData: {},
      vehicleModels: [],
      vehicleCapacity: [],
      vehicleDescription: [],
      vehicleMakers: [],
      firstLoad: true,
      loading: false,
    };

    PROVINCES.map((data) => (data.label = data.text));

    if (!quotation.car) {
      this.state.data = {
        ...DEFAULT_DATA,
        ...quotation,
      };
    }
    window.console.log(this.state);
  }

  componentDidMount() {
    window.console.log(this.state.data);
  }

  componentWillMount() {
    moment.locale('th');
    window.console.log(this.props.quotation.price_list);
    this.getCarMakeModelData(this.props.quotation.quotation_query.model_description.id);
    if (this.props.quotation.car) {
      this.setCarFromSale(this.props.quotation.car);
    } else if (this.props.userId) {
      this.getCar(this.props.userId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({
        data: { ...this.state.data, ...nextProps.data },
      });
    }
    if (this.props.userId !== nextProps.userId && nextProps.userId) {
      this.getCar(nextProps.userId);
    }
  }

  setCarFromSale = (car) => {
    window.console.log(car);
    if (typeof car.registration_province !== 'object') {
      if (car.registration_province) {
        car.registration_province = {
          key: car.registration_province,
          value: car.registration_province,
          text: car.registration_province,
          label: car.registration_province,
        };
      }
    }

    this.setState({
      data: car,
    });
    if (this.props.setCarId) {
      this.props.setCarId(car.id);
    }
  };

  handleChange = (name) => (event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.toDate();
    } else {
      value = event.target.value;
    }

    if (name === 'chassis_number' && value && !validator.isAlphanumeric(value)) {
      return;
    } else if (name === 'chassis_number' && value) {
      value = value.toUpperCase();
    } 
    
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  handleSelectChange = (name) => (data) => {
    window.console.log(name, data);
    this.setState({
      data: { ...this.state.data, [name]: data },
      updatedData: { ...this.state.updatedData, [name]: data },
    });
  };

  onYearChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, registration_year: data.value },
      updatedData: { ...this.state.updatedData, registration_year: data.value },
    });
  };

  validate = (data) => {
    window.console.log(data);
    const errors = {};
    if (!data.vehicle_number) {
      errors.vehicle_number = 'Required Field';
    }
    if (!data.chassis_number) {
      errors.chassis_number = 'Required Field';
    }
    if (!data.registration_province) {
      errors.registration_province = 'Required Field';
    }
    return errors;
  };

  getCarMakeModelData = (id) => {
    api.masterData
      .getCarMakeModelData(id)
      .then((response) => {
        window.console.log(response);
        this.setState({
          carData: response.result,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  getCar = (id) => {
    api.dataEntry
      .getCar(id)
      .then((response) => {
        window.console.log(response);
        if (response.results.length) {
          response.results = response.results.map((data) => {
            if (data.registration_province) {
              data.registration_province = {
                key: data.registration_province,
                value: data.registration_province,
                text: data.registration_province,
                label: data.registration_province,
              };
            }
            return data;
          });
          this.setState({
            data: response.results[0],
          });
          if (this.props.setCarId) {
            this.props.setCarId(response.results[0].id);
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  saveCarData = () => {
    const errors = this.validate(this.state.data);
    window.console.log(errors);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    window.console.log(this.state.data);
    if (!this.state.data.make_id) {
      this.saveCarDetails();
      return;
    }
    this.editCarDetails();
  };

  editCarDetails = () => {
    if (!Object.keys(this.state.updatedData).length) {
      window.console.log('You did not change any data...');
      if (this.props.changeStep) {
        this.props.changeStep(2);
      }
      return;
    }
    window.console.log(this.state.updatedData);
    let updatedData = this.state.updatedData;
    if (updatedData.policy_start_date) {
      updatedData.policy_start_date = moment(updatedData.policy_start_date).format('YYYY-MM-DD');
    }
    if (updatedData.policy_end_date) {
      updatedData.policy_end_date = moment(updatedData.policy_end_date).format('YYYY-MM-DD');
    }
    const params = {
      ...updatedData,
      id: this.state.data.id,
    };
    this.setState({
      loading: true,
    });
    if (params.registration_province) {
      params.registration_province = params.registration_province.value;
    }
    window.console.log('update data params ', params);
    api.dataEntry
      .patchCar(this.state.data.id, params)
      .then((response) => {
        window.console.log('Edit car profile ', response);
        this.setState({
          loading: false,
          updatedData: {},
        });

        if (this.props.changeStep) {
          this.props.changeStep(2);
        }
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
    window.console.log('Params ', params);
  };

  saveCarDetails = () => {
    this.setState({
      loading: true,
    });
    let data = this.state.data;
    const carData = this.state.carData;
    let params = {
      make: carData.make.name,
      make_id: carData.make.id,
      make_model: carData.make_model.name,
      make_model_id: carData.make_model.id,
      cc: carData.cc.name,
      cc_id: carData.cc.id,
      description_name: carData.name,
      description: carData.id,
      user_id: this.props.userId,
      fairdee_sale_id: this.props.quotation.sale.id,
    };
    if (data.vehicle_number) {
      params.vehicle_number = data.vehicle_number;
    }
    if (data.chassis_number) {
      params.chassis_number = data.chassis_number;
    }
    if (data.engine_number) {
      params.engine_number = data.engine_number;
    }
    if (data.registration_province) {
      params.registration_province = data.registration_province.value;
    }
    if (data.registration_year) {
      params.registration_year = data.registration_year;
    }
    api.dataEntry
      .car(this.props.userId, params)
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          updatedData: {},
        });
        if (this.props.setCarId) {
          this.props.setCarId(response.id);
        }
        if (this.props.changeStep) {
          this.props.changeStep(2);
        }
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  scrollDown = () => {
    setTimeout(() => {
      var div = document.getElementsByClassName('sale-track-stepper-content')[0];
      if (!div) {
        return;
      }
      div.scrollTop = div.scrollHeight - div.clientHeight;
    });
  };

  render() {
    let { data, errors, loading, carData } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.carForm;

    window.console.log(PROVINCES, data.registration_province);

    return (
      <form className={classes.container} noValidate autoComplete="nope">
        {Object.keys(carData).length && carData.make && (
          <p className="text">
            <span>{carData.make.name}</span> {carData.value}
          </p>
        )}
        <TextField
          id="vehicle_number"
          label={languageMap.textOne}
          className={classes.textField}
          value={data.vehicle_number || ''}
          error={Boolean(errors.vehicle_number)}
          helperText={errors.vehicle_number}
          autoComplete="off"
          onChange={this.handleChange('vehicle_number')}
          required
        />
        <TextField
          id="chassis_number"
          label={languageMap.textTwo}
          className={classes.textField}
          value={data.chassis_number || ''}
          error={Boolean(errors.chassis_number)}
          helperText={errors.chassis_number}
          autoComplete="off"
          onChange={this.handleChange('chassis_number')}
          required
        />
        <TextField
          id="engine_number"
          label={languageMap.textFive}
          className={classes.textField}
          value={data.engine_number || ''}
          error={Boolean(errors.engine_number)}
          helperText={errors.engine_number}
          autoComplete="off"
          onChange={this.handleChange('engine_number')}
        />
        <div
          className={'react-select ' + classes.fullWidth + (errors.registration_province ? ' error' : '')}
          onClick={this.scrollDown}
        >
          <FormLabel component="legend" className={classes.label}>
            {languageMap.textThree}*
          </FormLabel>
          <Select
            value={data.registration_province}
            onChange={this.handleSelectChange('registration_province')}
            options={PROVINCES}
            className={classes.textField}
            required
          />
          {errors.registration_province && <InlineError text={errors.registration_province} />}
        </div>
        <div className={classes.fullWidth + ' mt-20'}>
          <SearchList
            id="registration_year"
            label={languageMap.textSix}
            onChange={this.onYearChange}
            options={dropdownYears()}
            listLabel=""
            value={this.state.data.registration_year}
            name="registration_year"
            search={true}
            error={errors.registration_year ? true : false}
            helpertext={errors.registration_year}
          />
        </div>
        <div className="buttons-wrap">
          <div>
            <Button onClick={this.props.changeStep.bind(null, 0)}>Prev</Button>
          </div>
          <div>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button variant="contained" color="primary" className={classes.button} onClick={this.saveCarData}>
                {languageMap.textFour}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

CarForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, {})(withStyles(styles)(CarForm));
