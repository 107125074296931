import { LOCATION } from '../constants/types';

function location(state = {}, action = {}) {
  switch (action.type) {
    case LOCATION:
      return action.location;
    default:
      return state;
  }
}

export { location };
