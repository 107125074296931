/* global mobileClient */
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import Validator from 'validator';
import { setAffiliate } from '../../actions/affiliate';
import { login, socialLogin, userProfile } from '../../actions/auth';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { CLIENT_ID, CLIENT_SECRET, FACEBOOK_ID } from '../../constants/config';
import {
  MODAL_TYPE_FORGOT_PASSWORD,
  MODAL_TYPE_LOGIN_SIGNUP,
  MODAL_TYPE_SIGNUP,
  NOTIFICATION,
} from '../../constants/types';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';
import facebookImgThai from '../../images/onboarding/facebook-thai.png';
import facebookImg from '../../images/onboarding/facebook.png';
import { store } from '../../index';
import {
  errorHandler,
  getParameterByName,
  getParametersBySubstring,
  getUrlparamsAsObject,
  isMobileDevice,
  setLanguageToElement,
} from '../../utils/helper';
import { Mixpanel } from '../../utils/tracking';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: '',
        openedTime: null,
      },
      errors: {},
    };
  }

  componentDidMount() {
    setLanguageToElement();
    this.startTime = moment();
    window.console.log(this.state);
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  openLoginSignupModal = () => {
    this.close();
    this.props.showModal(MODAL_TYPE_LOGIN_SIGNUP, {});
  };

  openSignupModal = () => {
    Mixpanel.track('Login/signup', {
      object: 'Signup Link',
      objectContext: {
        parent: 'Login Modal',
      },
      action: 'Clicked',
    });
    this.close();
    this.props.showModal(MODAL_TYPE_SIGNUP, {});
  };

  openForgotPasswordModal = () => {
    Mixpanel.track('Login/signup', {
      object: 'Forgot Password Link',
      objectContext: {
        parent: 'Login Modal',
      },
      action: 'Clicked',
    });
    this.close();
    this.props.showModal(MODAL_TYPE_FORGOT_PASSWORD, {});
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  affiliateProfile = () => {
    api.crm
      .getAffiliateProfile({
        with_commission_rates: true,
        with_instalment_credits: true,
        instalment_credits_with_vendor: true,
      })
      .then((response) => {
        store.dispatch(setAffiliate(response.message));
        this.props.history.replace('/buy-insurance');
      })
      .catch((error) => errorHandler(error.response));
  };

  userProfile = () => {
    this.props.userProfile();
  };

  onSubmit = () => {
    const errors = this.validate(this.state.data);
    const languageMap = this.props.languageMap.messages;
    const diff = moment.duration(moment().diff(this.startTime));
    Mixpanel.track('Login/signup', {
      object: 'Login Submit',
      objectContext: {
        parent: 'Login Modal',
      },
      action: 'Clicked',
      actionContext: { errors, time_taken: [diff.hours(), diff.minutes(), diff.seconds()].join(':') },
    });
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const params = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'password',
        username: this.state.data.email,
        password: this.state.data.password,
      };
      this.setState({
        loading: true,
      });
      this.props
        .login(params)
        .then(() => {
          Mixpanel.track('Login/signup', {
            object: 'Login',
            objectContext: {
              parent: 'Login Modal',
            },
            action: 'Successful',
          });
          // this.notify(languageMap.successfulSignup, 'success');
          this.affiliateProfile();
          this.userProfile();
          this.close();
          if (this.props.closeParent) {
            this.props.closeParent();
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
          });
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error === 'invalid_grant') {
              this.setState({
                emailNotVerified: false,
                errors: {
                  global: languageMap[error.response.data.error_description] || error.response.data.error_description,
                },
              });
            }
            if (error.response.data.error === 'inactive_user') {
              this.setState({
                emailNotVerified: true,
                errors: { global: languageMap.emailNotVerified },
              });
            }
            if (error.response.data.error === 'banned_user') {
              this.setState({
                emailNotVerified: true,
                errors: { global: languageMap.bannedUser },
              });
            }
            window.console.log('error => ', this.state.errors);
          }
          if (error.response && error.response.data && error.response.data.response_code) {
            this.setState({
              emailNotVerified: false,
              errors: { global: error.response.data.response_code },
            });
            window.console.log('error => ', this.state.errors);
          }
          if (error.response) {
            Mixpanel.track('Login/signup', {
              object: 'Login',
              objectContext: {
                parent: 'Login Modal',
              },
              action: 'Failed',
              actionContext: { errors: error.response.data },
            });
          }
        });
    } else {
      Mixpanel.track('Login/signup', {
        object: 'Login Submit',
        objectContext: {
          parent: 'Login Modal',
        },
        action: 'Failed',
        actionContext: { errors },
      });
    }
  };

  validate = (data) => {
    const errors = {};
    const languageMap = this.props.languageMap.messages;

    if (!data.email) {
      errors.email = languageMap.required;
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = languageMap.email;
    }
    if (!data.password) {
      errors.password = languageMap.required;
    }
    return errors;
  };

  socialLogin = (credentials) => {
    const tagMap = {
      'facebook-fairdee': 'fb_signup',
    };
    const params = {
      ...credentials,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'convert_token',
      affiliate_signup: true,
    };
    const referral_code = getParameterByName('ref');
    let tag = getUrlparamsAsObject()['tag'],
      tags = getParametersBySubstring('utm_') || [];
    if (referral_code) {
      params.affiliate_data = {
        referral_code,
      };
    }
    if (tag) {
      tag = tag.constructor === Array ? tag : [tag];
      tags = tags.concat(tag);
    }
    tags.push(tagMap[params.backend]);

    if (tags.length) {
      params.affiliate_data = { ...params.affiliate_data, tags };
    }
    this.setState({
      loading: true,
    });
    this.props
      .socialLogin(params)
      .then((resp) => {
        // this.notify(this.props.languageMap.messages.successfulSignup, 'success');
        Mixpanel.track('Login/signup', {
          object: 'Facebook Login',
          objectContext: {
            parent: 'Login Modal',
          },
          action: 'Succeeded',
        });
        this.affiliateProfile();
        this.userProfile();
        this.close();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.console.log(error.response);
        if (error.response && error.response.data && error.response.data.error_description) {
          this.notify(error.response && error.response.data && error.response.data.error_description, 'error');
        }
        if (error.response && error.response.data && error.response.data.email) {
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: '?type=extra-info&field=email',
            state: { ...params },
          });
          this.close();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.affiliate_data &&
          error.response.data.affiliate_data.phone
        ) {
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: '?type=extra-info&field=phone',
            state: { ...params },
          });
          this.close();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.affiliate_data &&
          error.response.data.affiliate_data.has_broker_license
        ) {
          this.props.history.push({
            pathname: '/aff-additional-info',
            search: '?type=extra-info&field=has_broker_license',
            state: { ...params },
          });
          this.close();
        }
      });
  };

  responseFacebook = (response) => {
    console.log('fb response ', response);
    if (!response.accessToken) {
      return;
    }
    const credentials = {
      backend: 'facebook-fairdee',
      token: response.accessToken,
    };

    this.socialLogin(credentials);
  };

  trackFBLoginClick = () => {
    Mixpanel.track('Login/signup', {
      object: 'Facebook Login',
      objectContext: {
        parent: 'Login Modal',
      },
      action: 'Clicked',
    });
  };

  renderFacebookLogin = () => {
    if (isMobileDevice()) {
      try {
        if (!mobileClient) {
          return (
            <FacebookLogin
              version="3.3"
              appId={FACEBOOK_ID}
              autoLoad={false}
              fields="name,email"
              disableMobileRedirect={true}
              onClick={this.trackFBLoginClick}
              callback={this.responseFacebook}
              scope="email,public_profile"
              render={(renderProps) => (
                <img
                  src={this.props.language === 'english' ? facebookImg : facebookImgThai}
                  onClick={renderProps.onClick}
                  alt=""
                />
              )}
            />
          );
        } else {
          return (
            <FacebookLogin
              version="3.3"
              appId={FACEBOOK_ID}
              autoLoad={false}
              fields="name,email"
              disableMobileRedirect={false}
              onClick={this.trackFBLoginClick}
              isMobile={true}
              callback={this.responseFacebook}
              scope="email,public_profile"
              responseType="token"
              redirectUri={window.location.origin}
              render={(renderProps) => (
                <img
                  src={this.props.language === 'english' ? facebookImg : facebookImgThai}
                  onClick={renderProps.onClick}
                  alt=""
                />
              )}
            />
          );
        }
      } catch (e) {
        // window.console.log(e);
      }
    }

    return (
      <FacebookLogin
        version="3.3"
        appId={FACEBOOK_ID}
        autoLoad={false}
        fields="name,email"
        disableMobileRedirect={true}
        onClick={this.trackFBLoginClick}
        callback={this.responseFacebook}
        scope="email,public_profile"
        render={(renderProps) => (
          <img
            src={this.props.language === 'english' ? facebookImg : facebookImgThai}
            onClick={renderProps.onClick}
            alt=""
          />
        )}
      />
    );
  };

  render() {
    const languageMap = this.props.languageMap.components.login;
    const { data, errors, loading } = this.state;

    return (
      <div className="fairdee-modal-wrapper small login-signup">
        <div className="modal-content login">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <h1 className="title-text">
            {/* { languageMap.titleText.textOne } &nbsp; */}
            {this.renderFacebookLogin()}

            {/* <LineLogin /> */}
            {/* <img src={facebookImg} onClick={this.socialLogin.bind(null, {
              backend: 'facebook-fairdee',
              token: "EAAEIvhLzdTUBAPYgksIj1cItaj1UQZCOmo7O0DppPfzQQCgiTgpZCrXgnzKJFdZAtMkDfciGb5tCDw6k40Rg980GtGJtD5kEEQfWe024aATAv775ybFy2Dd1M8wqhvQP8CfZCP1sY9FoKtsKgF3mMZBHMgu5V07MaWg8czZCvnkDANZAVk7APnuRAExIgNEZA9wZD"
            })} alt="" /> */}
          </h1>
          {/* <p className="line"></p> */}
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
            </p>
            <ul>
              <li className="active">
                <span>{languageMap.buttonText}</span>
              </li>
              <li onClick={this.openSignupModal}>
                <span>{languageMap.textThree}</span>
              </li>
            </ul>
          </div>
          <p className="title-text text">{languageMap.subTitleText}</p>
          <Form noValidate onSubmit={this.onSubmit} autoComplete="off" id="login">
            <Form.Field className={errors.email ? 'errors' : ''}>
              <input
                type="email"
                id="email-one"
                name="email"
                placeholder={languageMap.inputOne}
                value={data.email}
                onChange={this.onChange}
                autoComplete="off"
                autoFocus
              />
              {errors.email && <InlineError text={errors.email} />}
              <label htmlFor="email-one">{languageMap.inputOne}</label>
            </Form.Field>
            <Form.Field className={errors.password ? 'errors' : ''}>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={languageMap.inputTwo}
                value={data.password}
                onChange={this.onChange}
                autoComplete="off"
              />
              {errors.password && <InlineError text={errors.password} />}
              <label htmlFor="password" className="with-offset">
                {languageMap.inputTwo}
              </label>
              {errors.global && <InlineError text={errors.global} />}
            </Form.Field>
            <div className="button-wrap">
              <button className="button medium" disabled={loading} onClick={this.submit}>
                {loading ? <CircularProgress size={20} color="inherit" /> : languageMap.buttonText}
              </button>
            </div>
            <p className="link-text block">
              <a onClick={this.openForgotPasswordModal}>{languageMap.textOne}</a>
            </p>
            <p className="link-text text mb-0">
              {languageMap.textTwo}{' '}
              <a className="bold" onClick={this.openSignupModal}>
                {languageMap.textThree}
              </a>
            </p>
          </Form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    language: state.language,
  };
}

export default withRouter(
  connect(mapStateToProps, { hideModal, showModal, showNotification, login, userProfile, socialLogin })(Login)
);
