import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AccountActive from '../../../images/fairdee-x-plus/bottom-navigation/account-active.svg';
import Account from '../../../images/fairdee-x-plus/bottom-navigation/account.svg';
import HomeActive from '../../../images/fairdee-x-plus/bottom-navigation/home-active.svg';
import Home from '../../../images/fairdee-x-plus/bottom-navigation/home.svg';
import LearningActive from '../../../images/fairdee-x-plus/bottom-navigation/learning-active.svg';
import Learning from '../../../images/fairdee-x-plus/bottom-navigation/learning.svg';
import PolicyActive from '../../../images/fairdee-x-plus/bottom-navigation/policy-active.svg';
import Policy from '../../../images/fairdee-x-plus/bottom-navigation/policy.svg';
import ReferralsActive from '../../../images/fairdee-x-plus/bottom-navigation/referrals-active.svg';
import Referrals from '../../../images/fairdee-x-plus/bottom-navigation/referrals.svg';
import { RootState } from '../../../index';
import { isMlmAgent } from '../../../utils/helper';
import './_bottom-navigation.scss';

const DEFAULT_PATH_ICONS = {
  home: Home,
  policy: Policy,
  account: Account,
  learning: Learning,
  referrals: Referrals,
};

const BottomNavigation: React.FC = () => {
  const history = useHistory(),
    location = useLocation(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    [pathIcons, setPathIcons] = useState(DEFAULT_PATH_ICONS);

  function handleNavigation(path: string) {
    history.push(path);
  }

  useEffect(() => {
    let tempPathIcons = { ...DEFAULT_PATH_ICONS };

    switch (location.pathname) {
      case '/buy-insurance':
        tempPathIcons.home = HomeActive;
        break;
      case '/policies-landing':
        tempPathIcons.policy = PolicyActive;
        break;
      case '/account':
        tempPathIcons.account = AccountActive;
        break;
      case '/tutorials':
        tempPathIcons.learning = LearningActive;
        break;
      case '/referrals':
        tempPathIcons.referrals = ReferralsActive;
        break;
      case '/teams':
        tempPathIcons.referrals = ReferralsActive;
        break;
      default:
        break;
    }
    setPathIcons(tempPathIcons);
  }, [location]);

  return (
    <div className="bottom-navigation">
      <div
        className={location.pathname === '/buy-insurance' ? 'active' : ''}
        onClick={() => handleNavigation('/buy-insurance')}
      >
        <img src={pathIcons.home} alt="home" />
        <p className="text">{languageMap[`Home`]}</p>
      </div>
      <div
        className={location.pathname === '/policies-landing' ? 'active' : ''}
        onClick={() => handleNavigation('/policies-landing')}
      >
        <img src={pathIcons.policy} alt="policy" />
        <p className="text">{languageMap[`Policy`]}</p>
      </div>
      <div className={location.pathname === '/account' ? 'active' : ''} onClick={() => handleNavigation('/account')}>
        <img src={pathIcons.account} alt="account" />
        <p className="text">{languageMap[`Transfers`]}</p>
      </div>
      <div
        className={location.pathname === '/tutorials' ? 'active' : ''}
        onClick={() => handleNavigation('/tutorials')}
      >
        <img src={pathIcons.learning} alt="learning" />
        <p className="text">{languageMap[`Learning`]}</p>
      </div>
      {isMlmAgent() ? (
        <div className={location.pathname === '/teams' ? 'active' : ''} onClick={() => handleNavigation('/teams')}>
          <img src={pathIcons.referrals} alt="/teams" />
          <p className="text"> {languageMap[`Teams`]} </p>
        </div>
      ) : (
        <div
          className={location.pathname === '/referrals' ? 'active' : ''}
          onClick={() => handleNavigation('/referrals')}
        >
          <img src={pathIcons.referrals} alt="/referrals" />
          <p className="text"> {languageMap[`Referrals`]} </p>
        </div>
      )}
    </div>
  );
};

export default BottomNavigation;
