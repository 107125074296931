import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import tick from '../../images/gree-tick-round.svg';

class ThankYouModal extends React.Component {
  close = () => {
    this.props.history.push('/buy-insurance?type=policies');
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.coaThankyouModal;

    return (
      <div className="fairdee-modal-wrapper med message">
        <div className="modal-content">
          <img src={tick} alt="" className="tick" />
          <p className="title">{languageMap.textOne}</p>
          <p className="text">{languageMap.textTwo}</p>
          <div className="button-wrap">
            <button className="button medium" onClick={this.close}>
              {languageMap.textThree}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ThankYouModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(ThankYouModal));
