import MomentUtils from '@date-io/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler, toThaiDate } from '../../utils/helper';

const tableHeaderMap = {
  index: '#',
  insurer_id: 'Insurer',
  policy_number: 'Policy Number',
  policy_start_date: 'Policy Start Date',
  vehicle_number: 'Vehicle Number',
  chassis_number: 'Chassis Number',
  policy_holder_name: 'Policy Holder Name',
  gross_premium: 'Gross Premium',
  premium_after_tax: 'Premim After Tax',
  commission_received: 'Commission Recieved',
  incentive_received: 'Incentive Received',
  is_compulsory: 'Is Compulsory',
  is_valid: 'Is Valid',
};

function UploadSOAModal(props) {
  const [statementDate, setStatementDate] = useState(null);
  const [soaFiles, setSoaFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [apiParams, setApiParams] = useState({});
  const messages = useSelector((state) => state.languageMap.messages);

  function close() {
    props.hideModal(null, { ...props });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function validate() {
    let validationErrors = {};

    if (!soaFiles || !soaFiles.length) {
      validationErrors.soaFiles = messages.required;
    }

    if (!statementDate) {
      validationErrors.statementDate = messages.required;
    }

    setErrors(validationErrors);
    return validationErrors;
  }

  function handleChange(event) {
    let value = null;
    if (moment.isMoment(event)) {
      value = event.format('YYYY-MM-DD HH:mm:ss');
    } else {
      value = event.target.value;
    }
    setStatementDate(value);
  }

  function fileUploaded(file) {
    setSoaFiles([file]);
  }

  function deleteUploadedFile() {
    setSoaFiles([]);
  }

  function uploadDocument(is_dry_run = false) {
    const errors = validate();

    if (Object.keys(errors).length) {
      return;
    }
    let params = new FormData();
    setApiLoading(true);
    params.append('file', soaFiles[0]);
    params.append('is_dry_run', is_dry_run);
    params.append('statement_date', moment(statementDate).format('YYYY-MM-DD'));

    api.policy
      .postSOADocument(params)
      .then((resp) => {
        window.console.log(resp);
        const txns = resp.transactions;
        let sortedTxns = txns.filter((txn) => !Boolean(txn.sale));
        sortedTxns = sortedTxns.concat(txns.filter((txn) => Boolean(txn.sale)));
        setTransactions(sortedTxns);
        setApiLoading(false);
        setApiParams(params);
        notify(messages.updatedSuccessfully, 'success');
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  }

  function renderColumn(row, key, index) {
    let returnData = row.transaction[key];
    const sales = row.sales;
    let sale = null;
    if (sales.length) {
      sale = sales[0];
    }
    if (returnData) {
      if (key === 'policy_start_date') {
        if (sale) {
          let salePolicyDate = sale.quotation.policy_start_date;
          if (row.is_compulsory) {
            salePolicyDate = sale.quotation.compulsory_start_date;
          }
          if (returnData != salePolicyDate) {
            return (
              <div>
                <p>{toThaiDate(returnData, 'DD-MM-YYYY')}</p>
                <p className="crm-label red">*{toThaiDate(salePolicyDate, 'DD-MM-YYYY')}</p>
              </div>
            );
          }
        }
        return toThaiDate(returnData, 'DD-MM-YYYY');
      }
      if (key === 'insurer_id') {
        return props.insurers.find((insurer) => insurer.value == returnData).label;
      }
    }
    if (key === 'is_compulsory') {
      return returnData ? <span className="crm-label green">Yes</span> : <span className="crm-label red">No</span>;
    }

    if (key === 'index') {
      return index + 1;
    }

    if (key === 'is_valid') {
      return sale ? <span className={'crm-label green'}>Yes</span> : <span className={'crm-label red'}>No</span>;
    }

    if (sale) {
      if (key === 'vehicle_number' && returnData != sale.quotation.vehicle_number) {
        return (
          <div>
            <a href={`/fairdee-wizard?id=${sale.quotation.id}`} target="_blank">
              {returnData}
            </a>
            <p className="crm-label red">*{sale.quotation.vehicle_number}</p>
          </div>
        );
      }
      if (key === 'commission_payable') {
        let grossPremium = 0;
        if (row.transaction.is_compulsory) {
          grossPremium = sale.quotation.cmi_price_list.grossPremium;
        } else {
          grossPremium = sale.quotation.price_list.gross_premium;
        }
        if (grossPremium != returnData) {
          return (
            <div>
              <p>{returnData}</p>
              <p className="crm-label red">*{parseFloat(grossPremium).toFixed(2)}</p>
            </div>
          );
        }
      }
      if (key === 'premium_after_tax') {
        let taxedPremium = 0;
        if (row.transaction.is_compulsory) {
          taxedPremium = sale.quotation.cmi_price_list.premium_after_tax;
        } else {
          taxedPremium = sale.quotation.price_list.premium_after_tax;
        }
        if (returnData != taxedPremium) {
          return (
            <div>
              <p>{returnData}</p>
              <p className="crm-label red">*{parseFloat(taxedPremium).toFixed(2)}</p>
            </div>
          );
        }
      }
    }

    if (key === 'vehicle_number' && sale) {
      return (
        <a href={`/fairdee-wizard?id=${sale.quotation.id}`} target="_blank">
          {returnData}
        </a>
      );
    }

    return returnData;
  }

  function renderTable() {
    return (
      <div className="crm-table-wrapper">
        <table>
          <thead>
            <tr>
              {Object.keys(tableHeaderMap).map((key, index) => (
                <th key={index}>{tableHeaderMap[key]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transactions.map((row, index) => (
              <tr key={index}>
                {Object.keys(tableHeaderMap).map((key) => (
                  <td key={key + index}>{renderColumn(row, key, index)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function openConfirmationModal(confirmation) {
    if (confirmation) {
      props.showModal(CONFIRMATION_MODAL, {
        action: () => verifySOAStatement(),
        text: 'Are you sure to continue?',
      });
    } else {
      props.showModal(CONFIRMATION_MODAL, {
        action: () => resetSOAModal(),
        text: 'Are you sure want to cancel?',
      });
    }
  }

  function resetSOAModal() {
    setTransactions([]);
  }

  function verifySOAStatement() {
    if (apiParams) {
      apiParams.set('is_dry_run', false);
    }
    setApiLoading(true);

    api.policy
      .postSOADocument(apiParams)
      .then(() => {
        setApiLoading(false);
        notify(messages.updatedSuccessfully, 'success');
        close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer full-width">
      {transactions && transactions.length > 0 && (
        <div className="modal-content gray-background">
          <img src={cross} className="close" alt="" onClick={close} />
          {renderTable(transactions)}
          <div className="options-container">
            <div className="row">
              <br />
              <br />
              <button className="button medium orange-bordered-button" onClick={() => openConfirmationModal(false)}>
                Cancel Verification
              </button>
              <button className="button medium" onClick={() => openConfirmationModal(true)} disabled={apiLoading}>
                {apiLoading && <CircularProgress size={14} color="inherit" />}
                {!apiLoading && <>Click to verify statement</>}
              </button>
            </div>
          </div>
          <div>*Fairdee Sale Values</div>
        </div>
      )}
      {(!transactions || transactions.length === 0) && !apiLoading && (
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={close} />
          <div className="mobile-header">
            <p className="current-page" onClick={close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Upload SOA Document</span>
            </p>
          </div>
          <h1 className="title-text">Upload SOA Document</h1>
          <div>
            <div className="form-wrapper">
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <InlineDatePicker
                  clearable
                  animateYearScrolling={false}
                  id="statement_date"
                  name="statement_date"
                  label="Statement Date"
                  value={statementDate}
                  onChange={handleChange}
                  maxDate={moment().clone()}
                  minDate={moment().clone().subtract(1, 'years')}
                  format="DD/MM/YYYY"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  className="form-field datepicker"
                />
              </MuiPickersUtilsProvider>
              {errors && errors.statementDate && <InlineError text={errors.statementDate} />}
              <div className="file-upload">
                <FileUpload
                  files={soaFiles}
                  loading={false}
                  name="file"
                  onFileUpload={fileUploaded}
                  onFileRemove={deleteUploadedFile}
                  limit={1}
                  btnText="SOA File"
                  accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                {errors && errors.soaFiles && <InlineError text={errors.soaFiles} />}
              </div>
            </div>
          </div>
          <button className="button medium" onClick={() => uploadDocument(true)} disabled={apiLoading}>
            {apiLoading && <CircularProgress size={16} color="inherit" />}
            {!apiLoading && <>Upload Statement</>}
          </button>
        </div>
      )}
    </div>
  );
}

export default connect(null, { hideModal, showNotification, showModal })(UploadSOAModal);
