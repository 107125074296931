const PREMIUM_ADDITION_SUBTYPES = ['sum_insured_increase', 'garage_to_dealer', 'others'];
const PREMIUM_REDUTION_SUBTYPES = ['sum_insured_decrease', 'dealer_to_garage', 'cctv', 'others'];
const PREMIUM_UNAFFECTED_SUBTYPES = [
  'insured_person',
  'insured_name',
  'chassis_number',
  'make_model',
  'insured_address',
  'vehicle_number',
  'vehicle_year',
  'vehicle_cc',
  'registration_province',
  'invoice_details',
  'insured_dob',
  'others',
];
const POLICY_CANCELLED_SUBTYPES = [];

export const TYPES_OF_ENDORSEMENT = ['premium_addition', 'premium_reduction', 'policy_cancelled', 'premium_unaffected'];
export const TYPE_TO_SUBTYPE_MAP = {
  premium_addition: PREMIUM_ADDITION_SUBTYPES,
  premium_reduction: PREMIUM_REDUTION_SUBTYPES,
  policy_cancelled: POLICY_CANCELLED_SUBTYPES,
  premium_unaffected: PREMIUM_UNAFFECTED_SUBTYPES,
};
export const ENDORSEMENT_REQUEST_SENT_TO_INSURER = 'endorsement_request_sent_to_insurer';
