import Input from '@material-ui/core/Input';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import CountdownTimer from '../../components/utils/CountdownTimer';
import { API_URL } from '../../constants/config';
import cross from '../../images/cross.svg';
import greyQr from '../../images/grey-qr.png';
import promptPayQR from '../../images/icons/prompt-pay-qr.png';
import qrBanner from '../../images/icons/thai-qr-banner.png';
import '../../scss/consumer/_qr-payment.scss';
import { commaSeperatedNumbers, errorHandler, getAccessToken, getLocalData, setLocalData } from '../../utils/helper';

function TopUpPrepaidCreditModal(props) {
  let languageMap = useSelector((state) => state.languageMap);
  const messages = languageMap.messages;
  languageMap = languageMap.components.qrPayment;

  const [qr, setQr] = useState(null),
    [paymentAmount, setPaymentAmount] = useState(null),
    [errors, setErrors] = useState({}),
    [regenerateQR, setRegenerateQR] = useState(false);

  useEffect(() => {
    let qrData = getLocalData('prepaid_qr_data');
    if (qrData && qrData.id) {
      getQr(qrData.id);
    }
  }, [props]);

  function close() {
    props.hideModal(null, { ...props });
  }

  function getQr(id) {
    api.billing.getBillPaymentRequest(id, { response_type: 'bill_payment_request' }).then((resp) => {
      setQr({ ...resp });
    });
  }

  function action() {
    const params = {
      affiliate: props.affiliate.id,
      amount: paymentAmount,
      is_prepaid: true,
    };

    const validationErrors = {};

    if (!paymentAmount) {
      validationErrors.paymentAmount = messages.required;
    } else if (parseInt(paymentAmount) > 200000) {
      validationErrors.paymentAmount = `${messages.lessValue} 200,000`;
    }

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }
    api.crm
      .creditTopup(params)
      .then((resp) => {
        let prepaid_qr_data = null;
        const invoice = resp.invoice;
        api.billing
          .billPaymentRequest({ invoice_id: invoice, affiliate_id: props.affiliate.id })
          .then((response) => {
            prepaid_qr_data = {
              id: resp.id,
              valid_till: resp.valid_till,
            };
            setQr({ ...response.qr_bill_payment_request });
          })
          .catch((error) => errorHandler(error.response, true));
        setLocalData('prepaid_qr_data', prepaid_qr_data);
      })
      .catch((error) => errorHandler(error.response, true));
  }

  function handlePaymentChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    setPaymentAmount(e.target.value);
  }

  function renderRegenerateQR() {
    return (
      <div className="regenerate-qr">
        <p className="red-text">{languageMap.qrExpired}!</p>
        <button className="button primary orange-bordered-button" onClick={action}>
          {languageMap.regenerate}
        </button>
      </div>
    );
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        {qr && qr.id && !qr.amount_paid && (
          <>
            <h1 className="title-text">{languageMap.payViaQr}</h1>
            <div className="qr-details">
              <p className="bold-text flex-item">
                {languageMap.amountToBePaid}: {commaSeperatedNumbers(qr.amount)}
              </p>
              <p className="pay-via-qr">Scan QR to Pay</p>
              <div className="flex-item">
                <div className="qr-image-wrapper">
                  <div className="qr-banner">
                    <img src={qrBanner} alt="" />
                  </div>
                  <div className="prompt-pay">
                    <img src={promptPayQR} alt="" />
                  </div>
                  <div className="img-wrap">
                    <img
                      className="qr-image"
                      alt=""
                      src={
                        regenerateQR
                          ? greyQr
                          : `${API_URL}billing/bill-payment-request/${qr.id}?&download=true&token=${getAccessToken()}`
                      }
                    ></img>
                  </div>
                </div>
              </div>
              {qr && !qr.is_paid && !qr.amount_paid && qr.partner_txn_uid ? (
                <div>
                  <CountdownTimer validTill={qr.valid_till} action={() => setRegenerateQR(true)} />
                  {regenerateQR ? renderRegenerateQR() : null}
                </div>
              ) : null}
            </div>
            <div className="form-wrapper">
              <div className="form-field">
                <label htmlFor="leadgen_code">{languageMap.enterNewTopupAmount}?</label>
                <Input
                  name="leadgen_code"
                  onChange={handlePaymentChange}
                  className={errors.paymentAmount ? 'error' : ''}
                  value={paymentAmount}
                />
                {errors.paymentAmount && <InlineError text={errors.paymentAmount} />}
              </div>
            </div>
            <div className="button-wrap">
              <button className={`button medium`} onClick={action}>
                {languageMap.getQr}
              </button>
            </div>
          </>
        )}
        {!qr || (qr && qr.amount_paid) ? (
          <>
            <h1 className="title-text">{languageMap.topup}</h1>
            <div className="qr-details">
              <div className="form-wrapper">
                <div className="form-field">
                  <label htmlFor="leadgen_code">{languageMap.enterTopupAmount}</label>
                  <Input
                    name="leadgen_code"
                    onChange={handlePaymentChange}
                    className={errors.paymentAmount ? 'error' : ''}
                    value={paymentAmount}
                  />
                  {errors.paymentAmount && <InlineError text={errors.paymentAmount} />}
                </div>
              </div>
            </div>
            <div className="button-wrap">
              <button className={`button medium`} onClick={action}>
                {languageMap.getQr}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(TopUpPrepaidCreditModal);
