import api from '../commonjs/api';
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_PROFILE, USER_REGISTERED } from '../constants/types';
import PushNotification from '../PushNotification';
import {
  clearLocalData,
  getLocalData,
  removeAxiosHeaders,
  setAccessToken,
  setAffiliateIdHeader,
  setAxiosHeaders,
  setLocalData,
  setRefreshToken,
  setUserData,
} from '../utils/helper';

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const userData = (user) => ({
  type: USER_PROFILE,
  user,
});

export const userRegistered = (user) => ({
  type: USER_REGISTERED,
  user,
});

export const login = (credentials) => (dispatch) =>
  api.user.login(credentials).then((user) => {
    setAccessToken(user.data.access_token);
    setRefreshToken(user.data.refresh_token);
    setAxiosHeaders();
    setUserData({ accessToken: user.data.access_token });
    dispatch(userLoggedIn(user.data));
  });

export const otpLogin = (credentials) => (dispatch) =>
  api.user.login(credentials).then((response) => {
    response = response.data;
    setAccessToken(response.access_token);
    setRefreshToken(response.refresh_token);
    setAxiosHeaders();
    setUserData({ accessToken: response.access_token });
    dispatch(userLoggedIn(response));
  });

export const socialLogin = (credentials) => (dispatch) =>
  api.user.socialLogin(credentials).then((user) => {
    window.console.log(user.data);
    localStorage.accessToken = user.data.access_token;
    setUserData({ accessToken: user.data.access_token });
    setRefreshToken(user.data.refresh_token);
    setAxiosHeaders();
    dispatch(userLoggedIn(user.data));
  });

export const logout = (credentials) => (dispatch) =>
  api.user.logout(credentials).then((user) => {
    removeAxiosHeaders();
    clearLocalData();
    window.previousPath = '';
    dispatch(userLoggedOut());
    PushNotification && PushNotification.unregister();
  });

export const userProfile =
  (mlmAgent = false) =>
  () => {
    let isMlmAgent = mlmAgent;
    return api.user.profile().then((user) => {
      if (user.data.affiliates && user.data.affiliates.length) {
        let currentAffiliate = getLocalData('currentAffiliate');
        if (currentAffiliate) {
          setAffiliateIdHeader(currentAffiliate);
        } else if (user.data.affiliates.length > 1) {
          let id = user.data.affiliates.find((aff) => aff.account_type === 'mlm_agent')?.id;
          if (id) {
            isMlmAgent = true;
          }
          if (!isMlmAgent) {
            id = user.data.affiliates.find((aff) => aff.account_type !== 'mlm_agent')?.id;
          }
          setAffiliateIdHeader(id);
          setLocalData('currentAffiliate', id);
        } else {
          let id = user.data.affiliates[0].id;
          setAffiliateIdHeader(id);
          setLocalData('currentAffiliate', id);
        }
      }
      if (user.data.roles && user.data.roles.includes('admin') && PushNotification) {
        PushNotification.init();
      }
      setUserData(user.data);
      return user.data;
    });
  };

export const tokenLogin = (t) => (dispatch) =>
  api.user.tokenLogin({ t: t }).then((data) => {
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setAxiosHeaders();
    setUserData({ accessToken: data.access_token });
    dispatch(userLoggedIn(data));
  });

export const register = (credentials) => (dispatch) =>
  api.crm.signupV2(credentials).then((user) => {
    window.console.log(user.data);
    return user;
  });
