import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingImg from '../../../images/fairdee-x-plus/common/loading.svg';
import { RootState } from '../../../index';
import './_loading.scss';

interface Props {
  loading: boolean;
  type?: string;
}

const Loading: React.FC<Props> = ({ type }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);

  useEffect(() => {
    let element = document.getElementById('animationContainer');
    if (element && type === 'ailoader') {
      window.lottie.loadAnimation({
        container: element, // the dom element
        renderer: 'svg', // Render type: 'canvas', 'html'
        loop: true, // If set to true, the animation will loop
        autoplay: true, // If set to true, the animation will start automatically
        path: 'https://public-meta.s3.ap-southeast-1.amazonaws.com/experimental/Animation+-+1713945932202.json', // the path to the animation json
      });
    }
  }, [type]);

  if (type && type === 'ailoader') {
    return (
      <div className="ai-loader">
        <div className="animation" id="animationContainer"></div>
      </div>
    );
  }

  return (
    <div className="loading-screen">
      <div className="content">
        <div className="img-wrap">
          <img src={LoadingImg} alt="loading" />
        </div>
        <p className="text-one">{languageMap[`Hold on tight!`]} </p>
        <p className="text-two">{languageMap[`This is taking longer than expected!`]}</p>
      </div>
    </div>
  );
};

export default Loading;
