import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import { NOTIFICATION } from '../../constants/types';
import infoIcon from '../../images/yellow-info.png';
import { commaSeperatedNumbers, isMember, numberWithCommas, setLanguageToElement } from '../../utils/helper';

class DengueCard extends React.Component {
  constructor(props) {
    super(props);
    this.contextRef = createRef();
    this.state = {
      cardData: this.props.data,
    };
  }

  componentDidMount() {
    setLanguageToElement();
  }

  componentWillReceiveProps(nextProps) {
    window.console.log('********************************', nextProps.data, '***************************');
    this.setState({
      cardData: nextProps.data,
    });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  continue = (e, cardData) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.next && this.props.next(cardData);
  };

  renderTags = () => {
    const cardData = this.state.cardData;
    let tags = [];
    if (cardData.metadata && cardData.metadata.selling_points) {
      let selling_points = cardData.metadata.selling_points.filter((data) => data != '');
      selling_points.forEach((spoint, index) => {
        tags.push(
          <span key={index + 3} className="tags">
            {spoint.replace('-', '')}
          </span>
        );
      });
    }
    return tags;
  };

  render() {
    const languageMap = this.props.languageMap.components.insurerCard;
    const { cardData } = this.state;
    let ageRange = '';
    if (cardData.metadata) {
      let minAge = cardData.metadata.min_age;
      let maxAge = cardData.metadata.max_age;
      ageRange = `${minAge.value} ${languageMap.dengueDetails[minAge.type]}-${maxAge.value} ${
        languageMap.dengueDetails[maxAge.type]
      }`;
    }
    return (
      <div className={'list-card'}>
        <div className="card-wrapper">
          <div className="section one">
            <div className="row">
              <div className="img-wrap">
                <img src={cardData.insurer.logo} alt="" />
              </div>
              <div className="line"></div>
              <div className="text-one">{cardData.plan_name}</div>
            </div>
          </div>
          <div className="section two">
            <div className="row">
              <div className="col">
                <div className="row no-border">
                  <div className="col">
                    <p className="text-one">
                      {languageMap.dengueDetails.hospitalizationCompensation}:{' '}
                      <span>
                        {' '}
                        {numberWithCommas(cardData.hospitalization_compensation)} {languageMap.baht}
                      </span>
                      <Popup
                        className="corona-popups"
                        trigger={<img src={infoIcon} alt="" />}
                        position="bottom center"
                        hoverable
                      >
                        <p className="text">ชดเชยปลอบขวัญ กรณีเป็นผู้ป่วยในติดต่อกันไม่น้อยกว่า 5 วัน</p>
                      </Popup>
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-one">
                      {languageMap.dengueDetails.hospitalCash}:{' '}
                      <span>
                        {' '}
                        {numberWithCommas(cardData.hospital_cash)} {languageMap.baht}
                      </span>
                      <Popup
                        className="corona-popups"
                        trigger={<img src={infoIcon} alt="" />}
                        position="bottom center"
                        hoverable
                      >
                        <p className="text">{cardData.metadata.hospital_cash_message}</p>
                      </Popup>
                    </p>
                  </div>
                </div>
                <div className="row no-border">
                  <div className="col">
                    <div className="text-one">
                      {languageMap.dengueDetails.diseasesCovered}:
                      {cardData.metadata.diseases_covered.map((disease) => (
                        <p>-{disease}</p>
                      ))}
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-one">
                      {languageMap.dengueDetails.remarks}:
                      {cardData.metadata.notes.map((note) => (
                        <p>-{note}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col align-right">
                <div className="large-text">
                  {`${languageMap.dengueDetails.premiumAfterTax}:${numberWithCommas(
                    commaSeperatedNumbers(cardData.premium_after_tax)
                  )}`}
                  {languageMap.baht}
                  {cardData.affiliate_commission && !this.props.hideCommission ? (
                    <Popup
                      className="effective-price corona-popups"
                      trigger={<img src={infoIcon} alt="" />}
                      position="bottom center"
                      hoverable
                    >
                      <div className="content-wrap">
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textOne}</p>
                          <p className="text">{numberWithCommas(cardData.netPremium)}</p>
                        </div>
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textFive}</p>
                          <p className="text">+{numberWithCommas(cardData.duty)}</p>
                        </div>
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textFour}</p>
                          <p className="text">+{numberWithCommas(cardData.tax)}</p>
                        </div>
                        <hr />
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textSeven}</p>
                          <p className="text">{numberWithCommas(cardData.totalPremium)}</p>
                        </div>
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textTwo}</p>
                          <p className="text">-{numberWithCommas(cardData.affiliate_commission)}</p>
                        </div>
                        <hr />
                        <div className="row">
                          <p className="text">{languageMap.popupDetails.textSix}</p>
                          <p className="text">{numberWithCommas(cardData.netTransferAmount)}</p>
                        </div>
                      </div>
                    </Popup>
                  ) : (
                    ''
                  )}
                </div>
                <button className="button primary medium" onClick={(e) => this.continue(e, cardData)}>
                  {!isMember() ? languageMap.buttonText : languageMap.buttonTextOne}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section three">
          <div className="row">
            {cardData.metadata && cardData.metadata.selling_points ? (
              <>
                <div className="tags-wrapper">
                  {this.renderTags()}
                  {ageRange && (
                    <span className="tags">
                      {languageMap.dengueDetails.ageRange}: {ageRange}
                    </span>
                  )}
                  {cardData.metadata.waiting_period_days ? (
                    <span className="tags">
                      {languageMap.dengueDetails.waitingPeriod}: {cardData.metadata.waiting_period_days}{' '}
                      {languageMap.dengueDetails.days}
                    </span>
                  ) : (
                    <span className="tags">{languageMap.dengueDetails.waitingPeriod}: ไม่มี</span>
                  )}
                </div>
              </>
            ) : null}
            {cardData.metadata && cardData.metadata.sales_technique && cardData.metadata.selling_points ? (
              <div className="line"></div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

DengueCard.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, showNotification })(DengueCard));
