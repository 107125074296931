import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import cross from '../../images/cross.svg';
import PABanner from '../../images/dengue/dengue-banner.png';
import { isMobileDevice } from '../../utils/helper';

function PABannerModal(props) {
  function close(redirect) {
    window.localStorage.setItem('hidePABanner', true);
    props.hideModal(null, {
      ...props,
    });
  }

  return (
    <div className="fairdee-modal-wrapper video-player corona-banner">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close.bind(null, false)} />
        <img height={isMobileDevice() ? '60%' : '100%'} src={PABanner} />
      </div>
    </div>
  );
}

PABannerModal.propTypes = {};

export default withRouter(connect(null, { hideModal, showModal })(PABannerModal));
