import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import React from 'react';
import { SENTRY_DSN, TRACES_SAMPLE_RATE } from './constants/config';
import Router from './router/Router';
import Layout from './views/layout/Layout';
import { useLocation } from 'react-router-dom';

if (SENTRY_DSN) {
  Sentry.init({
    ignoreErrors: [
      'Breaking for redirection. Nevermind',
    ],
    dsn: SENTRY_DSN,
    tracesSampleRate: parseFloat(TRACES_SAMPLE_RATE),
  });
}

const theme = (() => {
  const palette = {
    primary: {
      main: '#ff901d',
    },
  };

  const typography = {
    fontFamily: '"sukh-regular"',
    useNextVariants: true,
  };

  return createMuiTheme({
    palette: palette,
    typography: typography,
  });
})();

const App = (props) => {
  const { pathname } = useLocation();
  return (
  <MuiThemeProvider theme={theme}>
    <Layout {...props} pathname={pathname}>
      <Router />
    </Layout>
  </MuiThemeProvider>);
};

export default App;
