import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import ReferImg from '../../../images/fairdee-x-plus/teams/invite-friend.jpeg';
import { RootState } from '../../../index';
import './_referral-details.scss';

interface Props {
  close?: any;
}

const ReferralDetails: React.FC<Props> = ({ close }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);

  return (
    <div className="referral-details">
      <div className="top-section">{languageMap[`FairDee Referral Program`]} </div>
      <div className="content">
        <img src={ReferImg} alt="refer" />
      </div>
      <div className="button-section">
        <Button className="orange-button" onClick={close}>
          {languageMap[`Got it!`]}
        </Button>
      </div>
    </div>
  );
};

export default ReferralDetails;
