import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import validator from 'validator';
import { setAffiliate } from '../../actions/affiliate';
import { socialLogin, userProfile } from '../../actions/auth';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import FileUpload from '../../components/FileUpload';
import InlineError from '../../components/InlineError';
import SearchList from '../../components/SearchList';
import { CLIENT_ID, CLIENT_SECRET } from '../../constants/config';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import cross from '../../images/cross.svg';
import headerBanner from '../../images/onboarding/flag.svg';
import InfoIcon from '../../images/orange-info.svg';
import { store } from '../../index';
import {
  changeUrlParams,
  doScrolling,
  errorHandler,
  getAccessToken,
  getParameterByName,
  getParametersBySubstring,
  getUrlparamsAsObject,
  gtagReport,
  isMobileDevice,
  isMobileNumber,
  pixelEvent,
  setAccessToken,
  validateThaiNationalId,
} from '../../utils/helper';

let BANKS = [];

const WAY_TO_SELL = [
  {
    key: 'เปิดร้านขายประกัน',
    value: 'เปิดร้านขายประกัน',
    text: 'เปิดร้านขายประกัน',
    name: 'เปิดร้านขายประกัน',
  },
  {
    key: 'เปิดร้านไปรษณีย์',
    value: 'เปิดร้านไปรษณีย์',
    text: 'เปิดร้านไปรษณีย์',
    name: 'เปิดร้านไปรษณีย์',
  },
  {
    key: 'อู่ซ่อมรถ',
    value: 'อู่ซ่อมรถ',
    text: 'อู่ซ่อมรถ',
    name: 'อู่ซ่อมรถ',
  },
  {
    key: 'เซลล์ขายรถ',
    value: 'เซลล์ขายรถ',
    text: 'เซลล์ขายรถ',
    name: 'เซลล์ขายรถ',
  },
  {
    key: 'ขายอิสระ ช่องทางอื่นๆ',
    value: 'ขายอิสระ ช่องทางอื่นๆ',
    text: 'ขายอิสระ ช่องทางอื่นๆ',
    name: 'ขายอิสระ ช่องทางอื่นๆ',
  },
  {
    key: 'ตัวแทนประกันชีวิต',
    value: 'ตัวแทนประกันชีวิต',
    text: 'ตัวแทนประกันชีวิต',
    name: 'ตัวแทนประกันชีวิต',
  },
];

class AffiliateAdditionalInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        has_broker_license: null,
        city: '',
        way_to_sell: '',
        national_id_files: [],
        broker_license_files: [],
        bank_details: {
          ac_number: '',
          ac_holder_name: '',
          bank_name: '',
        },
        line_id: '',
        national_id: '',
        broker_license_number: '',
      },
      currentFocus: null,
      errors: {},
      loading: false,
      updatedData: {},
      fileListLoading: {},
      fileList: {},
      currentStep: getParameterByName('step') ? parseInt(getParameterByName('step')) : 1,
      email: '',
      phone: '',
      referral_code: getParameterByName('ref') || '',
      isElementFocussed: false,
      terms_and_conditions: false,
    };
    this.affiliate = null;
    window.console.log(this.state.data, props);
    const type = getParameterByName('type');
    if (type !== 'extra-info' && !getAccessToken()) {
      props.history.push({
        pathname: '/',
        search: '',
        state: '',
      });
      return;
    }
  }

  componentDidMount() {
    this.affiliate = this.props.affiliate;
    let data = this.state.data;
    window.console.log(this.props.affiliate);
    if (this.props.affiliate && this.props.affiliate.id) {
      data.way_to_sell = this.props.affiliate.additional_info.way_to_sell || '';
      data.city = this.props.affiliate.additional_info.city || '';
      data.line_id = this.props.affiliate.line_id || '';
      data.broker_license_number = this.props.affiliate.broker_license_number || '';
      data.national_id = this.props.affiliate.national_id || '';
      data.has_broker_license =
        this.props.affiliate.has_broker_license !== null ? this.props.affiliate.has_broker_license.toString() : null;
      if (this.props.affiliate.bank_details.length) {
        data.bank_details = this.props.affiliate.bank_details[0];
      }
    }
    this.setState(
      {
        data,
      },
      () => {
        window.console.log(data);
      }
    );
    let fileList = {};
    if (this.props.affiliate && this.props.affiliate.documents && this.props.affiliate.documents.length) {
      this.props.affiliate.documents.forEach((data) => {
        if (!fileList[data.document_type]) {
          fileList[data.document_type] = [];
        }
        data.file.name = data.file.original_file_name;
        fileList[data.document_type].push(data);
      });
      this.setState({
        fileList,
      });
      window.console.log(fileList);
    }
    BANKS = Object.keys(this.props.languageMap.banks).map((key) => {
      return {
        key: this.props.languageMap.banks[key],
        value: key,
        text: this.props.languageMap.banks[key],
        name: this.props.languageMap.banks[key],
      };
    });
    window.console.log(this.state.currentStep);
  }

  componentWillReceiveProps(nextProps) {
    window.console.log(nextProps, this.props);
    if (nextProps.affiliate && nextProps.affiliate.id) {
      this.affiliate = nextProps.affiliate;
    }
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  getAffiliateProfile = (update = false) => {
    api.crm
      .getAffiliateProfile({ with_commission_rates: true })
      .then((data) => {
        if (!this.props.affiliate || (!this.props.affiliate.id && data.message.is_oic_data_verified)) {
          pixelEvent('oic_verified');
          gtagReport('AW-796624154/ap8FCLyTyLQBEJqK7vsC');
        }
        window.console.log(data);
        let affiliate = data.message;
        this.affiliate = affiliate;
        store.dispatch(setAffiliate(affiliate));
        if (update) {
          this.updateAffiliateDetails();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  socialLogin = (credentials) => {
    const tagMap = {
      'facebook-fairdee': 'fb_signup',
    };
    const params = {
      ...credentials,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'convert_token',
      affiliate_signup: true,
    };
    let tag = getUrlparamsAsObject()['tag'],
      tags = getParametersBySubstring('utm_') || [];

    if (tag) {
      tag = tag.constructor === Array ? tag : [tag];
      tags = tags.concat(tag);
    }
    tags.push(tagMap[params.backend]);

    if (tags.length) {
      params.affiliate_data = { ...params.affiliate_data, tags };
      Object.keys(params.affiliate_data).forEach((key) => {
        if (params.affiliate_data[key] === '' || params.affiliate_data[key] === null) {
          delete params.affiliate_data[key];
        }
      });
    }
    this.setState({
      loading: true,
    });
    window.console.log(params);
    if (!params.email) {
      delete params.email;
    }
    api.user
      .socialLogin(params)
      .then((user) => {
        window.console.log(user);
        setAccessToken(user.data.access_token);
        this.notify('ลงชื่อเข้าใช้สำเร็จ', 'success');
        if (this.state.data.has_broker_license === 'false') {
          this.props.history.push('/buy-insurance');
          // this.getAffiliateProfile();
          return;
        }
        this.getAffiliateProfile(true);
        // setUserData({ accessToken: user.data.access_token });
        // setAxiosHeaders();
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errors: { ...this.state.errors, ...errorHandler(error.response) },
        });
        window.console.log(error.response, error.response.data);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.email &&
          Array.isArray(error.response.data.errors.email) &&
          error.response.data.errors.email.length
        ) {
          this.setState({
            errors: {
              email: error.response.data.errors.email[0],
            },
          });
        } else if (error.response && error.response.data && error.response.data.email) {
          this.setState({
            errors: {
              email: error.response.data.email,
            },
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          typeof error.response.data.errors === 'object'
        ) {
          window.console.log(error.response.data.errors, Object.keys(error.response.data.errors));
          if (Object.keys(error.response.data.errors).length) {
            Object.keys(error.response.data.errors).map((key) => {
              this.setState({
                errors: { ...this.state.errors, [key]: error.response.data.errors[key][0] },
              });
            });
          }
        } else if (error.response && error.response.data && error.response.data.error) {
          this.notify(error.response.data.error_description, 'error');
        }
      });
  };

  responseSocial = (response) => {
    console.log('fb response ', response);
    const credentials = {
      backend: response.backend || 'facebook-fairdee',
      token: response.token,
      email: response.email,
      affiliate_data: response.affiliate_data,
    };
    this.socialLogin(credentials);
  };

  changeStep = (currentStep) => {
    this.setState(
      {
        currentStep,
      },
      () => {
        window.console.log(this.state.currentStep);
      }
    );
    changeUrlParams('/aff-additional-info', 'step', currentStep);
    // this.props.history.push({
    //   pathname: '/aff-additional-info',
    //   search: '?step=' + currentStep
    // });
  };

  openBrokerLicenseConfirmationModal = () => {
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.updateHasbrokerLicense,
      text: this.props.languageMap.messages.confirmationQuestion,
      subText: this.props.languageMap.messages.noBrokerMessage,
    });
  };

  validate = (data) => {
    const messages = this.props.languageMap.messages;
    const type = getParameterByName('type');
    const field = getParameterByName('field');
    const errors = {};
    // if (!data.city) {
    //   errors.city = messages.required;
    // }
    // if (!data.way_to_sell) {
    //   errors.way_to_sell = messages.required;
    // }
    window.console.log(data.has_broker_license);

    if (type === 'extra-info' && field !== 'phone' && field !== 'has_broker_license' && !this.state.email) {
      errors.email = messages.required;
    }
    if (type === 'extra-info' && this.state.email && !validator.isEmail(this.state.email)) {
      errors.email = messages.email;
    }

    if (type === 'extra-info' && !this.state.phone && field === 'phone') {
      errors.phone = messages.required;
    }

    if (type === 'extra-info' && this.state.phone && !isMobileNumber(this.state.phone)) {
      errors.phone = messages.phone;
    }

    if (data.has_broker_license === null) {
      errors.has_broker_license = messages.required;
    }

    if (data.has_broker_license === 'true') {
      if (data.broker_license_number && !validator.isNumeric(data.broker_license_number)) {
        errors.broker_license_number = messages.invalidFormat;
      }

      // if (!data.national_id) {
      //   errors.national_id = messages.required;
      // }

      if (data.national_id && !validateThaiNationalId(data.national_id)) {
        errors.national_id = messages.invalidFormat;
      }

      // if (!data.terms_and_conditions) {
      //   errors.terms_and_conditions = messages.required;
      // }
    }
    // if (!data.terms_and_conditions) {
    //   errors.terms_and_conditions = messages.required;
    // }

    if (type === 'extra-info' && field === 'has_broker_license') {
      if (data.has_broker_license === 'true') {
        if (!data.broker_license_number) {
          errors.broker_license_number = messages.required;
        }

        if (!data.national_id) {
          errors.national_id = messages.required;
        }
      }
    }

    window.console.log(errors);
    return errors;
  };

  validateStepTwo = (data) => {
    const messages = this.props.languageMap.messages;
    const errors = {};
    if (!data.bank_details.bank_name) {
      errors.bank_name = messages.required;
    }
    if (!data.bank_details.ac_holder_name) {
      errors.ac_holder_name = messages.required;
    }
    if (!data.bank_details.ac_number) {
      errors.ac_number = messages.required;
    }
    if (!data.national_id) {
      errors.national_id_code = messages.required;
    }
    if (data.national_id && !validateThaiNationalId(data.national_id)) {
      errors.national_id_code = messages.invalidFormat;
    }
    if (!this.state.fileList['national_id'] || !this.state.fileList['national_id'].length) {
      errors.national_id = messages.required;
    }
    if (
      data.has_broker_license === 'true' &&
      (!this.state.fileList['broker_license'] || !this.state.fileList['broker_license'].length)
    ) {
      errors.broker_license = messages.required;
    }
    if (data.has_broker_license === 'true' && !data.broker_license_number) {
      errors.broker_license_number = messages.required;
    }
    return errors;
  };

  validateStepThree = (data) => {
    const messages = this.props.languageMap.messages;
    const errors = {};
    if (!this.state.data.way_to_sell) {
      errors.way_to_sell = messages.required;
    }
    if (!this.state.fileList['national_id'] || !this.state.fileList['national_id'].length) {
      errors.national_id = messages.required;
    }
    if (!this.state.fileList['broker_license'] || !this.state.fileList['broker_license'].length) {
      errors.broker_license = messages.required;
    }
    window.console.log(errors);
    return errors;
  };

  handleSearchChange = (key, data) => {
    window.console.log(key, data, this.state.data);
    if (key === 'bank_name') {
      let d = this.state.data;
      let updatedData = this.state.updatedData;
      d.bank_details[key] = data.value;
      if (!updatedData.bank_details) {
        updatedData.bank_details = {};
      }
      updatedData.bank_details[key] = data.value;
      this.setState({
        data: d,
        updatedData,
      });
      return;
    }
    this.setState(
      {
        data: { ...this.state.data, [key]: data.value },
        updatedData: { ...this.state.updatedData, [key]: data.value },
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.updatedData);
        }
      }
    );
  };

  updateHasbrokerLicense = () => {
    this.setState({
      data: { ...this.state.data, has_broker_license: 'false', broker_license_number: '', national_id: '' },
      updatedData: {
        ...this.state.updatedData,
        has_broker_license: 'false',
        broker_license_number: '',
        national_id: '',
      },
    });
  };

  handleChange = (e, target = undefined) => {
    let name = typeof target === 'object' ? target.name : e.target.name,
      value = typeof target === 'object' ? target.value : e.target.value;
    window.console.log(e.target.name, e.target.value, target, name, value);

    if (name === 'has_broker_license' && value === 'false') {
      this.openBrokerLicenseConfirmationModal();
      return;
    }

    if (name === 'ac_number' || name === 'ac_holder_name') {
      let data = this.state.data;
      let updatedData = this.state.updatedData;
      data.bank_details[name] = value;
      if (!updatedData.bank_details) {
        updatedData.bank_details = {};
      }
      updatedData.bank_details[name] = value;
      this.setState(
        {
          data,
          updatedData,
        },
        () => {
          if (this.props.onUpdate) {
            this.props.onUpdate(this.state.updatedData);
          }
        }
      );
      return;
    }
    if (name === 'email' || name === 'phone' || name === 'referral_code') {
      this.setState(
        {
          [name]: value,
        },
        () => {
          if (this.props.onUpdate) {
            this.props.onUpdate(this.state.updatedData);
          }
        }
      );
      return;
    }

    this.setState(
      {
        data: { ...this.state.data, [name]: value },
        updatedData: { ...this.state.updatedData, [name]: value },
      },
      () => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.updatedData);
        }
      }
    );
  };

  onCheckboxChange = (e, data) => {
    window.console.log(data.checked);
    this.setState({
      data: { ...this.state.data, [data.name]: data.checked },
    });
  };

  handleBankChange = (event, d) => {
    let data = this.state.data;
    data.bank_details.bank_name = d.value;
    this.setState({
      data,
    });
  };

  handleFocus = (isElementFocussed) => (e) => {
    window.console.log(e.target, isElementFocussed);
    if (isElementFocussed && e.target) {
      doScrolling(e.target, 500, 80);
    }
  };

  handleSearchFocus = (isElementFocussed) => (e) => {
    window.console.log(e.target, isElementFocussed);
    let element = e.target;
    this.setState({
      isElementFocussed,
    });
    if (isElementFocussed && element) {
      setTimeout(() => {
        doScrolling(element, 500, 80);
      }, 100);
    }
  };

  register = () => {
    const state = this.props.history.location.state,
      email = this.state.email,
      phone = this.state.phone,
      referral_code = this.state.referral_code,
      data = this.state.data,
      affiliate_data = {},
      field = getParameterByName('field');

    window.console.log(this.state.email, this.props.history);
    if (email && !validator.isEmail(email)) {
      return;
    }
    if (phone && !isMobileNumber(phone)) {
      return;
    }
    if (field && !phone && !email && data.has_broker_license === null) {
      return;
    }
    if (phone) {
      affiliate_data.phone = phone;
    }
    if (referral_code) {
      affiliate_data.referral_code = referral_code;
    }
    if (data.broker_license_number) {
      affiliate_data.broker_license_number = data.broker_license_number;
    }
    if (data.national_id) {
      affiliate_data.national_id = data.national_id;
    }
    if (data.has_broker_license !== null) {
      affiliate_data.has_broker_license = data.has_broker_license === 'true';
    }
    let params = { ...state };

    Object.keys(affiliate_data).forEach((key) => {
      if (affiliate_data[key] === '' || affiliate_data[key] === null) {
        delete affiliate_data[key];
      }
    });
    if (Object.keys(affiliate_data).length) {
      params.affiliate_data = affiliate_data;
    }
    if (email) {
      params.email = email;
    }
    window.console.log(params);
    this.responseSocial(params);
  };

  deleteAffiliateDocument = (data) => {
    window.console.log(data);
    api.user
      .deleteAffiliateDocument(data.id)
      .then((resp) => {
        window.console.log(resp);
        let fileList = this.state.fileList;
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        this.setState({
          fileList,
          updatedData: true,
        });
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        this.notify('Something went wrong', 'errors');
      });
  };

  uploadFile = (params, type, file) => {
    let fileListLoading = this.state.fileListLoading;
    fileListLoading[type] = true;
    this.setState({ fileListLoading });
    api.user
      .affiliateDocument(params, this.props.affiliate.id)
      .then((resp) => {
        window.console.log(this.state, params);
        fileListLoading[type] = false;
        let fileList = this.state.fileList;
        file.id = resp.message.id;
        file.document_type = type;

        if (!fileList[type]) {
          fileList[type] = [file];
        } else {
          fileList[type].push(file);
        }
        this.setState({ fileListLoading, fileList });
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.file &&
          error.response.data.response.file.file
        ) {
          this.notify(
            this.props.languageMap.messages[error.response.data.response.file.file] ||
              error.response.data.response.file.file,
            'errors'
          );
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.response &&
          error.response.data.response.file &&
          error.response.data.response.file[0]
        ) {
          this.notify(
            this.props.languageMap.messages[error.response.data.response.file[0]] ||
              error.response.data.response.file[0],
            'errors'
          );
        } else {
          this.notify('Something went wrong', 'errors');
        }
        fileListLoading[type] = false;
        window.console.log(error);
        this.setState({ fileListLoading });
      });
  };

  fileUploaded = (file, name) => {
    let params = new FormData();
    params.append('file', file);
    params.append('document_type', name);
    this.uploadFile(params, name, file);
  };

  deleteDocument = (data) => {
    window.console.log(data);
    this.props.showModal(CONFIRMATION_MODAL, {
      action: this.deleteAffiliateDocument,
      data,
    });
  };

  updateAffiliateDetails = () => {
    let params = { ...this.state.updatedData };
    window.console.log(params);
    if (params.bank_details && params.bank_details.ac_number) {
      params.bank_details.ac_number = params.bank_details.ac_number.replace(/-/g, '');
    }
    // params.bank_details = JSON.stringify(params.bank_details);
    if (!Object.keys(params).length) {
      let path = '/buy-insurance';
      this.props.history.push(path);
      return;
    }
    if (this.state.currentStep > 1) {
      params.is_signup_complete = true;
      // pixelEvent('oic_verified');
    }
    if ('has_broker_license' in params) {
      params.has_broker_license = params.has_broker_license === 'true';
      Object.keys(params).forEach((key) => {
        if (params[key] === '' || params[key] === null) {
          delete params[key];
        }
      });
    }

    window.console.log(params);
    if (!this.affiliate.id) {
      return;
    }
    this.setState({
      loading: true,
    });
    api.crm
      .patchAffiliate(this.affiliate.id, params)
      .then((resp) => {
        this.setState({
          loading: false,
        });
        window.console.log(resp);
        this.notify(this.props.languageMap.messages.updatedSuccessfully, 'success');
        window.console.log(this.state.currentStep);

        if (resp.data.has_broker_license === false) {
          store.dispatch(setAffiliate(resp.data));
          this.props.history.push('/buy-insurance');
          return;
        }
        let affiliate = resp.data;
        this.affiliate = affiliate;
        window.console.log(affiliate);
        store.dispatch(setAffiliate(affiliate));
        this.props.history.push('/buy-insurance');

        // if (this.state.currentStep > 1) {
        //   let path = '/buy-insurance';
        //   if (resp.data.has_broker_license === true && !resp.data.has_agreed_tnc) {
        //     path = '/aff-additional-info-tnc'
        //   }
        //   this.getAffiliateProfile();
        //   this.props.history.push(path);
        //   return;
        // } else {
        //   let affiliate = resp.data;
        //   this.affiliate = affiliate;
        //   window.console.log(affiliate);
        //   store.dispatch(setAffiliate(affiliate));
        // }
        // this.changeStep(2);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
        this.setState({
          errors: { ...this.state.errors, ...errorHandler(error.response, true) },
        });
      });
  };

  saveStepThree = () => {
    let errors = this.validateStepThree();
    this.setState(
      {
        errors,
      },
      () => {
        if (Object.keys(errors).length) {
          let element = document.getElementsByClassName('errors')[0];
          window.console.log(element);
          if (element) {
            doScrolling(element, 500, 80);
          }
        }
      }
    );
    if (Object.keys(errors).length) {
      return;
    }
    this.updateAffiliateDetails();
  };

  save = () => {
    let errors = this.validate(this.state.data);
    let field = getParameterByName('field');
    const pageType = getParameterByName('type');

    if (this.state.currentStep === 2) {
      let newErrors = this.validateStepThree(this.state.data);
      errors = { ...errors, ...newErrors };
    }
    window.console.log(errors);
    this.setState(
      {
        errors,
      },
      () => {
        if (Object.keys(errors).length) {
          let element = document.getElementsByClassName('errors')[0];
          window.console.log(element);
          if (element) {
            doScrolling(element, 500, 80);
          }
        }
      }
    );
    if (Object.keys(errors).length) {
      return;
    }
    if (!getAccessToken() && pageType === 'extra-info' && field) {
      this.register();
    } else {
      this.updateAffiliateDetails();
    }
  };

  renderStepOne = () => {
    const { errors, data, email, phone, referral_code, isElementFocussed, loading } = this.state;
    const languageMap = this.props.languageMap.components.affiliateAdditionalInfo;
    const { affiliate } = this.props;
    const pageType = getParameterByName('type');
    const field = getParameterByName('field');

    return (
      <div className={'make-modal-wrap ' + (this.props.showHeader ? 'with-margin' : '')}>
        <p className="step-text extra-margin">
          {' '}
          <span>
            (<span>*</span> {this.props.languageMap.messages.required} ){' '}
          </span>
        </p>
        <Form noValidate className="quotation-form">
          {pageType === 'extra-info' && field !== 'phone' && field !== 'has_broker_license' ? (
            <Form.Field className={errors.email ? 'errors' : ''}>
              <input
                type="text"
                id="email"
                name="email"
                placeholder={languageMap.textNineteen + ' <span>*</span>'}
                value={email}
                onChange={this.handleChange}
                autoComplete="off"
                onFocus={this.handleFocus(true)}
                onBlur={this.handleFocus(false)}
                autoFocus
              />
              <label htmlFor="email">
                {languageMap.textNineteen} <span>*</span>{' '}
              </label>
              {errors.email && <InlineError text={errors.email} />}
            </Form.Field>
          ) : null}
          {pageType === 'extra-info' && (field === 'phone' || !field) ? (
            <Form.Field className={errors.phone ? 'errors' : ''}>
              <input
                type="number"
                id="phone"
                name="phone"
                placeholder={languageMap.textTwentyOne + ' <span>*</span>'}
                value={phone}
                onChange={this.handleChange}
                autoComplete="off"
                onFocus={this.handleFocus(true)}
                onBlur={this.handleFocus(false)}
              />
              <label htmlFor="phone">
                {languageMap.textTwentyOne} <span>*</span>{' '}
              </label>
              {errors.phone && <InlineError text={errors.phone} />}
            </Form.Field>
          ) : null}
          {/* <SearchList
            id="city"
            label={languageMap.textTwo + ' <span>*</span>'}
            onChange={this.handleSearchChange}
            options={PROVINCES}
            value={data.city || ''}
            name="city"
            search={true}
            isOpen={false}
            error={errors.city ? true : false}
            helpertext={errors.city}
            autoFocus={Boolean(currentFocus === 'city')}
            handleFocus={this.handleSearchFocus}
          /> */}
          {affiliate && affiliate.is_oic_data_verified !== true ? (
            <FormControl component="div" className={'field mt-0 ' + (errors.has_broker_license ? 'errors' : '')}>
              <FormLabel component="label">
                {languageMap.textThree} <span>*</span>{' '}
              </FormLabel>
              <RadioGroup
                row
                aria-label={languageMap.textThree}
                name="has_broker_license"
                value={data.has_broker_license}
                onChange={this.handleChange}
                className="radio-group"
              >
                <FormControlLabel value="true" control={<Radio />} label={languageMap.textFour} />
                <FormControlLabel value="false" control={<Radio />} label={languageMap.textFive} />
              </RadioGroup>
              {errors.has_broker_license ? <InlineError text={errors.has_broker_license} /> : null}
            </FormControl>
          ) : null}
          {pageType === 'extra-info' && field === 'has_broker_license' && data.has_broker_license === 'true' ? (
            <div>
              <Form.Field className={errors.broker_license_number ? 'errors' : ''}>
                <input
                  type="number"
                  id="broker_license_number"
                  name="broker_license_number"
                  placeholder={languageMap.textTwentyThree + '*'}
                  value={data.broker_license_number}
                  onChange={this.handleChange}
                  autoComplete="off"
                  onBlur={this.validate.bind(null, this.state.data, 'broker_license_number')}
                />
                {errors.broker_license_number && <InlineError text={errors.broker_license_number} />}
                <label htmlFor="broker_license_number">
                  {languageMap.textTwentyThree} <span>*</span>
                </label>
              </Form.Field>
              <Form.Field className={errors.national_id ? 'errors' : ''}>
                <input
                  type="number"
                  id="national_id"
                  name="national_id"
                  placeholder={languageMap.textTwentyTwo + '*'}
                  value={data.national_id}
                  onChange={this.handleChange}
                  autoComplete="off"
                  onBlur={this.validate.bind(null, this.state.data, 'national_id')}
                />
                {errors.national_id && <InlineError text={errors.national_id} />}
                <label htmlFor="national_id">
                  {languageMap.textTwentyTwo} <span>*</span>
                </label>
              </Form.Field>
            </div>
          ) : null}
          {/* <SearchList
            id="way_to_sell"
            label={languageMap.textSix + ' <span>*</span>'}
            onChange={this.handleSearchChange}
            options={WAY_TO_SELL}
            value={data.way_to_sell || ''}
            name="way_to_sell"
            search={true}
            isOpen={false}
            error={errors.way_to_sell ? true : false}
            helpertext={errors.way_to_sell}
            autoFocus={Boolean(currentFocus === 'way_to_sell')}
            handleFocus={this.handleSearchFocus}
          /> */}
          {/* <Form.Field>
            <input
              type="text"
              id="line_id"
              name="line_id"
              placeholder={languageMap.textFourteen}
              value={data.line_id || ''}
              onChange={this.handleChange}
              onFocus={this.handleFocus(true)}
              onBlur={this.handleFocus(false)}
            />
            <label htmlFor="line_id">{languageMap.textFourteen}</label>
            {errors.line_id && <InlineError text={errors.line_id} />}
          </Form.Field> */}
          {!getAccessToken() && getParameterByName('type') === 'extra-info' ? (
            <Form.Field>
              <input
                type="text"
                id="referral_code"
                name="referral_code"
                placeholder={languageMap.textTwenty}
                value={referral_code || ''}
                onChange={this.handleChange}
                autoComplete="off"
                onFocus={this.handleFocus(true)}
                onBlur={this.handleFocus(false)}
              />
              {errors.referral_code && <InlineError text={errors.referral_code} />}
              <label htmlFor="referral_code">{languageMap.textTwenty}</label>
            </Form.Field>
          ) : null}
          {/* {
            // data.has_broker_license === 'true' && (
            <Form.Field className={errors.terms_and_conditions ? 'errors mb-0' : 'mb-0'}>
              <Checkbox className="normal-input-checkbox" label={<label>
                <span>{languageMap.iAccept} <a href="https://fairdee-contract.s3-ap-southeast-1.amazonaws.com/Agency+Contract_070819.pdf" target="_blank" >
                {languageMap.termsAndConditions}
                </a>
                </span>
              </label>
              } name="terms_and_conditions" value={data.terms_and_conditions} checked={data.terms_and_conditions} onChange={ (e, data) => this.onCheckboxChange(e,data) } />
              {errors.terms_and_conditions && <InlineError text={errors.terms_and_conditions} />}
            </Form.Field>
          } */}
          {
            <Form.Field
              className={errors.terms_and_conditions ? 'errors mb-0 normal-input-label' : 'mb-0 normal-input-label'}
            >
              <label>
                {data.has_broker_license === 'true' ? (
                  <span>
                    {`${languageMap.iAccept} `}
                    <a
                      href="https://fairdee-contract.s3-ap-southeast-1.amazonaws.com/Agency+Contract_070819.pdf"
                      target="_blank"
                    >
                      {languageMap.termsAndConditions}
                    </a>
                  </span>
                ) : (
                  <span>
                    {languageMap.iAccept} {languageMap.termsAndConditions}
                  </span>
                )}
              </label>
            </Form.Field>
          }
          {isElementFocussed && isMobileDevice() ? null : (
            <div className="button-wrap">
              <button className={'button primary ' + (loading ? 'disabled' : '')} onClick={this.save}>
                {languageMap.textFifteen}
              </button>
            </div>
          )}
        </Form>
      </div>
    );
  };

  renderStepTwo = () => {
    const { errors, data, currentFocus, fileListLoading, fileList, isElementFocussed } = this.state;
    const languageMap = this.props.languageMap.components.affiliateAdditionalInfo;
    const { affiliate } = this.props;
    if (!affiliate || !affiliate.user) {
      return null;
    }

    return (
      <div className={'make-modal-wrap ' + (this.props.showHeader ? 'with-margin' : '')}>
        <p className="step-text">
          {languageMap.textSeventeen} <span className="htext">{languageMap.textTwentyEight}</span>{' '}
          <span>
            (<span>*</span> {this.props.languageMap.messages.required} ){' '}
          </span>
        </p>
        <Form noValidate className="quotation-form">
          <Form.Field className={'file-upload-wrap relative ' + (errors.national_id ? 'errors' : '')}>
            <label htmlFor="national_id" className={'block ' + (errors.national_id ? 'error' : '')}>
              {languageMap.textEight} <span>*</span>{' '}
            </label>
            <FileUpload
              files={fileList['national_id']}
              name="national_id"
              onFileUpload={this.fileUploaded}
              onFileRemove={this.deleteDocument}
              limit={2}
              btnText={languageMap.uploadBtn}
              loading={fileListLoading['national_id']}
              accept="image/jpeg,image/png,application/pdf"
            />
            <p className="info-text">{languageMap.textTen}</p>
            {errors.national_id && <InlineError text={errors.national_id} />}
          </Form.Field>
          <Form.Field className={'file-upload-wrap relative ' + (errors.broker_license ? 'errors' : '')}>
            <label htmlFor="broker_license" className={'block ' + (errors.broker_license ? 'error' : '')}>
              {languageMap.textNine} <span>*</span>{' '}
            </label>
            <FileUpload
              files={fileList['broker_license']}
              name="broker_license"
              onFileUpload={this.fileUploaded}
              onFileRemove={this.deleteDocument}
              limit={2}
              btnText={languageMap.uploadBtn}
              loading={fileListLoading['broker_license']}
              accept="image/jpeg,image/png,application/pdf"
            />
            <p className="info-text">{languageMap.textTen}</p>
            {errors.broker_license && <InlineError text={errors.broker_license} />}
          </Form.Field>
          <SearchList
            id="way_to_sell"
            label={languageMap.textSix + ' <span>*</span>'}
            onChange={this.handleSearchChange}
            options={WAY_TO_SELL}
            value={data.way_to_sell || ''}
            name="way_to_sell"
            search={true}
            isOpen={false}
            error={errors.way_to_sell ? true : false}
            helpertext={errors.way_to_sell}
            autoFocus={Boolean(currentFocus === 'way_to_sell')}
            handleFocus={this.handleSearchFocus}
          />
          <Form.Field>
            <input
              type="text"
              id="line_id"
              name="line_id"
              placeholder={languageMap.textFourteen}
              value={data.line_id || ''}
              onChange={this.handleChange}
              onFocus={this.handleFocus(true)}
              onBlur={this.handleFocus(false)}
            />
            <label htmlFor="line_id">{languageMap.textFourteen}</label>
            <p className="htext">{languageMap.textTwentyNine}</p>
            {errors.line_id && <InlineError text={errors.line_id} />}
          </Form.Field>
          {affiliate ? (
            <div className="retrieved-info">
              <div className="info-bar">
                <img src={InfoIcon} alt="" />
                <p className="text-one">{languageMap.textThirty}</p>
              </div>
              <div className="key-value">
                <p className="key">{languageMap.textThirtyOne}</p>
                <p className="value">{affiliate.oic_fullname}</p>
              </div>
              <div className="key-value">
                <p className="key">{languageMap.textTwentyThree}</p>
                <p className="value">{affiliate.broker_license_number}</p>
              </div>
              <div className="key-value">
                <p className="key">{languageMap.textThirtyTwo}</p>
                <p className="value">{affiliate.broker_license_start_date}</p>
              </div>
              <div className="key-value">
                <p className="key">{languageMap.textThirtyThree}</p>
                <p className="value">{affiliate.broker_license_expiry_date}</p>
              </div>
            </div>
          ) : null}
          {isElementFocussed && isMobileDevice() ? null : (
            <div className="button-wrap">
              <button className="button primary" onClick={this.saveStepThree}>
                {languageMap.textSixteen}
              </button>
            </div>
          )}
        </Form>
      </div>
    );
  };

  renderHeader = () => {
    const languageMap = this.props.languageMap.components.affiliateAdditionalInfo;
    return (
      <div className="header-wrap">
        <img src={headerBanner} alt="" />
        <div>
          <p className="text">{languageMap.textOne}</p>
        </div>
      </div>
    );
  };

  renderSteps = () => {
    switch (this.state.currentStep) {
      case 1:
        return this.renderStepOne();
        break;
      case 2:
        return this.renderStepTwo();
        break;
      case 3:
        return this.renderStepThree();
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="fairdee-modal-wrapper affiliate-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          {this.renderHeader()}
          {this.renderSteps()}
        </div>
      </div>
    );
  }
}

AffiliateAdditionalInfoModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
  };
}

export default withRouter(
  connect(mapStateToProps, { hideModal, showModal, showNotification, userProfile, socialLogin })(
    AffiliateAdditionalInfoModal
  )
);
