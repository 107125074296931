import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Checkbox, Form, Popup } from 'semantic-ui-react';
import { showModal } from '../actions/modal';
import { showNotification } from '../actions/notification';
import api from '../commonjs/api';
import {
  MODAL_TYPE_ACCESSORIES,
  MODAL_TYPE_AFFILIATE_CONDITION,
  MODAL_TYPE_QUICKVIEWINSURER,
  MODAL_TYPE_QUOTATION_CONFIRMATION,
  MODAL_TYPE_SHARE_DETAILS,
  NOTIFICATION,
  SUM_INSURED_MODAL,
} from '../constants/types';
import tooltip from '../images/icon-warning.svg';
import {
  commaSeperatedNumbers,
  getFilterData,
  getParameterByName,
  isLoggedIn,
  isMember,
  setLanguageToElement,
} from '../utils/helper';
import { Mixpanel } from '../utils/tracking';

class InsurerCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardData: this.props.data,
    };
  }

  componentDidMount() {
    setLanguageToElement();
    if (this.state.cardData.meta_data && this.state.cardData.meta_data.selling_points) {
      window.console.log(this.state.cardData.meta_data.selling_points.split('\n'));
    }
  }

  componentWillReceiveProps(nextProps) {
    window.console.log('********************************', nextProps.data, '***************************');
    this.setState({
      cardData: nextProps.data,
    });
  }

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  openInsurerQuickView = (data) => {
    this.props.showModal(MODAL_TYPE_QUICKVIEWINSURER, {
      data: data,
      handleChange: this.props.handleChange,
      updateSuminsured: this.props.updateSuminsured,
    });
  };

  openShareDetailsModal = (data) => {
    let descriptionId = getFilterData().vehicleData ? getFilterData().vehicleData.description : '';
    let year = getFilterData().vehicleData ? getFilterData().vehicleData.manufacture_year : '';
    if (isLoggedIn()) {
      let params = {};
      let id = data.insurer.id;
      let ref = getParameterByName('ref');
      params.price_list_id = data.id;
      params.model_description_id = descriptionId;
      params.year = year;
      if (ref) {
        params.ref = ref;
      }
      api.policy
        .policyLead(params, id)
        .then((resp) => {
          window.console.log(resp);
          if (resp.redirect_url && resp.post_body) {
            this.openFairdeeLoader();
            this.crossDomainPost(resp.redirect_url, JSON.parse(resp.post_body));
          } else {
            this.openThankYouLoader();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props.showModal(MODAL_TYPE_SHARE_DETAILS, {
        id: data.id,
        insurer: data.insurer.name,
        insurer_id: data.insurer.id,
      });
    }
  };

  onCheckboxChange = (e, data) => {
    Mixpanel.track('Get Quotes', {
      object: 'Insurer Card Checkbox',
      action: data.checked ? 'Selected' : 'Deselected',
    });
    let allowedComparison = 3;
    if (this.state.cardData.compareCount >= allowedComparison && data.checked) {
      return;
    }
    let compareCount = this.state.cardData.compareCount;
    if (this.state.cardData.compareCount >= allowedComparison) {
      compareCount--;
    }
    this.setState(
      {
        cardData: { ...this.state.cardData, addToCompare: data.checked, compareCount },
      },
      () => {
        window.console.log(this.state.cardData);
        this.props.handleChange(this.state.cardData);
      }
    );
  };

  updateSuminsured = (data) => {
    let filterData = getFilterData();
    this.props.updateSuminsured(data);
    if (this.state.cardData.insurance_class === '1') {
      this.props.showModal(MODAL_TYPE_ACCESSORIES, {
        sum_insured: filterData.sum_insured,
        id: this.props.data.quotationQueryId,
        action: this.nextPage,
        data: this.props.data,
      });
    } else {
      this.nextPage();
    }
  };

  openQuotationConfirmationModal = (data) => {
    this.props.showModal(MODAL_TYPE_QUOTATION_CONFIRMATION, {
      action: this.gotoWizard,
      data,
    });
  };

  reportSale = () => {
    Mixpanel.track('Get Quotes', {
      object: 'Report Sale Button',
      action: 'Clicked',
    });
    let data = this.state.cardData;
    if (data.restriction_text) {
      this.openQuotationConfirmationModal([data]);
    } else {
      this.gotoWizard();
    }
  };

  continue = () => {
    let hasBrokerLicense = false;
    let hasNationalId = false;
    let isAdmin = this.props.user.roles && this.props.user.roles.includes('admin') ? true : false;

    if (this.props.affiliate && this.props.affiliate.documents && this.props.affiliate.documents.length) {
      this.props.affiliate.documents.forEach((data) => {
        if (data.document_type === 'broker_license') {
          hasBrokerLicense = true;
        }
        if (data.document_type === 'national_id') {
          hasNationalId = true;
        }
      });
    }

    if (
      !isAdmin &&
      this.props.affiliate &&
      this.props.affiliate.additional_info &&
      this.props.affiliate.additional_info.has_broker_license &&
      (!hasBrokerLicense || !hasNationalId)
    ) {
      this.props.showModal(MODAL_TYPE_AFFILIATE_CONDITION, {
        type: 'no-doc',
      });
      return;
    }

    if (!isAdmin && this.props.affiliate.additional_info && !this.props.affiliate.additional_info.has_broker_license) {
      this.props.showModal(MODAL_TYPE_AFFILIATE_CONDITION, {
        action: this.reportSale,
        type: 'no-license',
      });
      return;
    }
    this.reportSale();
  };

  nextPage = () => {
    let search = '?quotation_query_id=' + this.props.data.quotationQueryId + '&price_list_id=' + this.props.data.id;
    if (getParameterByName('affiliate')) {
      search += '&affiliate=' + getParameterByName('affiliate');
    }
    let pathname = '/fairdee-wizard';
    if (this.props.type && this.props.type === 'bike') {
      search += '&forBike=true'
    }

    if (this.props.type && this.props.type === 'taxi') {
      pathname = '/fairdee-wizard-taxi';
    }
    this.props.history.push({ pathname, search });
    if (this.props.closeParent) {
      this.props.closeParent();
    }
  };

  gotoWizard = () => {
    window.console.log(this.props.data);
    let data = this.state.cardData;
    let filterData = getFilterData();
    let sum_insured = data.min_suminsured === data.max_suminsured ? data.min_suminsured : null;
    if (!this.props.data.quotationQueryId) {
      return;
    }
    if (
      this.props.type !== 'bike' &&
      !filterData.sum_insured &&
      filterData.insurance_class &&
      //Adding loop check to support future cases
      filterData.insurance_class.some((i) => ['1', '1 ราคาถูกพิเศษ'].includes(i))
    ) {
      this.props.showModal(SUM_INSURED_MODAL, {
        data: { ...this.props.data, sum_insured },
        action: this.updateSuminsured,
      });
      return;
    }
    if (this.props.type === 'bike') {
      if (data.sum_insured || sum_insured) {
        this.updateSuminsured({ sum_insured: parseInt(sum_insured, 10) });
        return;
      }
      // this.props.updateSuminsured({ sum_insured: data.sum_insured || data.min_suminsured });
    } else {
      if (!filterData.sum_insured && sum_insured) {
        this.updateSuminsured({ sum_insured: parseInt(sum_insured, 10) });
        return;
      }
    }
    if (data.insurance_class === '1') {
      this.props.showModal(MODAL_TYPE_ACCESSORIES, {
        sum_insured: filterData.sum_insured,
        id: this.props.data.quotationQueryId,
        action: this.nextPage,
        data: this.props.data,
      });
      Mixpanel.track('Get Quotes', {
        object: 'Accesory Modal',
        action: 'Viewed',
      });
    } else {
      Mixpanel.track('Get Quotes', {
        object: 'Quotation Page',
        action: 'Redirected',
      });
      this.nextPage();
    }
  };

  copyText = (text) => {
    copy(text);
    this.notify(this.props.languageMap.messages.copied, 'info');
  };

  renderTags = () => {
    const cardData = this.state.cardData;
    const languageMap = this.props.languageMap.components.insurerCard;
    let tags = [];
    // if (cardData.camera_discount_eligible) {
    //   tags.push(<span key="2" className="tags">{languageMap.discountTextOne}</span>)
    // }
    // if (cardData.driver_discount_eligible) {
    //   tags.push(<span key="3" className="tags">{languageMap.discountTextTwo}</span>)
    // }
    if (cardData.meta_data && cardData.meta_data.selling_points) {
      let selling_points = cardData.meta_data.selling_points.split('\n').filter((data) => data != '');
      selling_points.forEach((spoint, index) => {
        tags.push(
          <span key={index + 3} className="tags">
            {spoint.replace('-', '')}
          </span>
        );
      });
    }
    return tags;
  };

  render() {
    const languageMap = this.props.languageMap.components.insurerCard;
    const { showBasic, affiliate } = this.props;
    const { cardData } = this.state;

    return (
      <div className={'insurer-card-new animated fadeIn ' + (this.props.className || '')}>
        <div className="header">
          {cardData.restriction_text ? <p className="restriction-text">{cardData.restriction_text}</p> : ''}
          <div>
            <div className="img-wrap">
              <img src={cardData.insurer.logo} alt="" />
            </div>
            <div className="details-wrap">
              <p className="text">
                {cardData.is_cashback_supported ? languageMap.cashbackPlan : languageMap.nonCashbackPlan}{' '}
                <span title={cardData.plan_name}>{cardData.plan_name}</span>
              </p>
            </div>
          </div>
        </div>

        <p className="insurance-price">
          <span>{languageMap.bahtPerYear.textOne}</span>
          <span className="number">{commaSeperatedNumbers(cardData.premium_after_tax)}</span>
          <span className="dtext">{languageMap.bahtPerYear.textTwo}</span>
          {cardData.affiliate_commission && !this.props.hideCommission ? (
            <Popup
              className="effective-price"
              trigger={<img src={tooltip} alt="" />}
              position="bottom center"
              hoverable
            >
              <div className="content-wrap">
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textOne}</p>
                  <p className="text">{commaSeperatedNumbers(cardData.netPremium)}</p>
                </div>
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textFive}</p>
                  <p className="text">+{commaSeperatedNumbers(cardData.duty)}</p>
                </div>
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textFour}</p>
                  <p className="text">+{commaSeperatedNumbers(cardData.tax)}</p>
                </div>
                <hr />
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textSeven}</p>
                  <p className="text">{commaSeperatedNumbers(cardData.totalPremium)}</p>
                </div>
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textTwo}</p>
                  <p className="text">-{commaSeperatedNumbers(cardData.affiliate_commission)}</p>
                </div>
                <hr />
                <div className="row">
                  <p className="text">{languageMap.popupDetails.textSix}</p>
                  <p className="text">{commaSeperatedNumbers(cardData.netTransferAmount)}</p>
                </div>
              </div>
            </Popup>
          ) : (
            ''
          )}
        </p>
        <p className="insurance-type">
          Type {cardData.insurance_class} {languageMap.insurance}
        </p>
        <p className="line"></p>
        <ul className="insurance-description">
          {cardData.sum_insured ? (
            <li>
              {languageMap.details.textOne}:<span>{commaSeperatedNumbers(cardData.sum_insured)}</span>
              <span> {languageMap.baht}</span>
            </li>
          ) : (
            ''
          )}
          <li>
            {languageMap.details.textThree}:{' '}
            <span className="lang">{languageMap.garageTypeMap[cardData.garage_type]}</span>
          </li>
        </ul>
        <div className="discount-wrap">{this.renderTags()}</div>
        {!showBasic ? (
          <p className="see-details" onClick={this.openInsurerQuickView.bind(null, cardData)}>
            {languageMap.seeDetails}
          </p>
        ) : null}
        {!this.props.hideAction && (
          <div className="buttons-wrap">
            <div>
              <Form noValidate>
                <Form.Field>
                  <Checkbox
                    label={languageMap.checkboxText}
                    name="addToCompare"
                    value={cardData.insurer.name}
                    checked={cardData.addToCompare ? true : false}
                    onChange={this.onCheckboxChange}
                  />
                </Form.Field>
              </Form>
            </div>
            <div>
              <button className="button orange-bordered-button medium" onClick={this.continue}>
                {!isMember() ? languageMap.buttonText : languageMap.buttonTextOne}
              </button>
            </div>
          </div>
        )}
        {cardData.meta_data && cardData.meta_data.sales_technique ? (
          <div className="extra-info-wrap">
            <Popup
              className="popover-el"
              trigger={
                <p onClick={this.handlePopoverClick}>
                  {' '}
                  {languageMap.textOne} <span>{languageMap.textTwo}</span>
                </p>
              }
              position="bottom center"
              hoverable
            >
              <div>
                {cardData.meta_data.sales_technique ? (
                  <p>
                    {cardData.meta_data.sales_technique}{' '}
                    <span onClick={this.copyText.bind(null, cardData.meta_data.sales_technique)}>
                      {languageMap.textThree}
                    </span>
                  </p>
                ) : null}
              </div>
            </Popup>
          </div>
        ) : null}
      </div>
    );
  }
}

InsurerCard.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    affiliate: state.affiliate,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, showNotification })(InsurerCard));
