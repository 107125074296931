import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import loading from '../images/loading.png';
import logo from '../images/logo.svg';
import { languageMap } from '../language-map/mapping';
import { getLanguage } from '../utils/helper';

class RedirectionLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languageMap: languageMap[getLanguage()].components.redirectionLoader,
    };

    window.console.log(this.props);
  }

  render() {
    const languageMap = this.state.languageMap;

    return (
      <div className="redirection-loader">
        <div className="logo-wrapper">
          <img src={logo} alt="" />
        </div>
        <div className="loader-wrap">
          <img src={loading} alt="" />
        </div>
        <p className="text">
          {languageMap.textOne} {this.props.insurer}{' '}
        </p>
        <p className="sub-text">{languageMap.textTwo}</p>
        <p className="sub-text">{languageMap.textThree}</p>
      </div>
    );
  }
}

export default withRouter(connect(null, {})(RedirectionLoader));
