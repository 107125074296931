import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modal';

const Component = ({ close, children }) => {
  let component = React.cloneElement(children, { close });
  return <>{component}</>;
};

function ActionSheet(props) {
  const dispatch = useDispatch(),
    [show, setShow] = useState(null);

  function close() {
    setShow(false);
  }

  useEffect(() => {
    if (props.hideModal) {
      close();
    }
  }, [props.hideModal]);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (show === false) {
      setTimeout(() => {
        if (props.close) {
          props.close();
        }
        dispatch(
          hideModal(null, {
            ...props,
          })
        );
      }, 1000);
    }
  }, [show]);

  return (
    <div
      className={'action-sheet ' + (show ? 'show' : '') + (' ' + props.className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="backdrop" onClick={close}></div>
      <Component close={close}>{props.component}</Component>
    </div>
  );
}

ActionSheet.propTypes = {
  component: PropTypes.any.isRequired,
};

export default ActionSheet;
