import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/th';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import api from '../commonjs/api';
import { default as PROVINCES } from '../constants/provinces.json';
import { getDistrictOptions, getSubDistrictOptions, scrollTop, validateMobileNumber } from '../utils/helper';
import InlineError from './InlineError';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '20px',
    width: '100%',
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  formLabel: {
    margin: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  inlineRadio: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: 0,
  },
  noGutter: {
    marginBottom: 10,
  },
  button: {
    margin: theme.spacing.unit,
  },
  fullWidth: {
    width: '100%',
  },
  datePicker: {
    width: '100%',
    marginBottom: '24px',
  },
  delete: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    zIndex: '99',
  },
  negMT: {
    marginTop: '-16px',
  },
});

class DriverForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        title: '',
        fullname: '',
        id_number: '',
        gender: null,
        dob: null,
        house_number: '',
        fairdee_address: {},
      },
      errors: {},
      driverList: [],
      loading: false,
      updatedData: {
        fairdee_address: {},
      },
      componentMounted: false,
      districts: [],
      sub_districts: [],
    };
    window.console.log(this.props);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.setState({
      componentMounted: true,
    });

    if (this.props.driver && this.props.driver.data) {
      this.setState(
        {
          data: { ...this.state.data, ...this.props.driver.data },
        },
        () => {
          if (this.props.driver.data.fairdee_address) {
            this.populateDropDowns(this.props.driver.data.fairdee_address);
          }
        }
      );
    }
  }

  handleAddressChange = (name) => (event) => {
    let data = this.state.data;
    let updatedData = this.state.updatedData;
    data.fairdee_address[name] = event.target.value;
    updatedData.fairdee_address[name] = event.target.value;
    this.setState({
      data,
      updatedData,
    });
  };

  handleChange = (name) => (event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event;
    } else {
      value = event.target.value;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  handleProvinceChange = async (e, data) => {
    let updatedValues = {
      fairdee_address: {
        ...this.state.data.fairdee_address,
        district: null,
        subdistrict: null,
        province: data.value,
      },
    };

    let provinceCode = PROVINCES.find((province) => province.provincename === data.value).provincecode;
    let districts = await getDistrictOptions(provinceCode);

    this.setState((state) => ({
      districts: districts,
      sub_districts: [],
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  handleDistrictChange = async (e, data) => {
    let { districts } = this.state;

    let updatedValues = {
      fairdee_address: {
        ...this.state.data.fairdee_address,
        district: data.value,
        subdistrict: null,
      },
    };

    let districtCode = districts.find((district) => district.districtname === data.value).districtcode;
    let subDistricts = await getSubDistrictOptions(districtCode);

    this.setState((state) => ({
      sub_districts: subDistricts,
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  handleSubDistrictChange = (e, data) => {
    let { sub_districts } = this.state;

    let updatedValues = {
      fairdee_address: {
        ...this.state.data.fairdee_address,
        subdistrict: data.value,
        postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === data.value).zip,
      },
    };
    window.console.log(updatedValues);

    this.setState((state) => ({
      data: { ...state.data, ...updatedValues },
      updatedData: { ...state.updatedData, ...updatedValues },
    }));
  };

  populateDropDowns = async (fairdee_address) => {
    if (!fairdee_address) {
      return;
    }

    if (fairdee_address.subdistrict) {
      let provinceName = fairdee_address.province,
        districtName = fairdee_address.district,
        province = PROVINCES.find((province) => province.provincename === provinceName);

      let districts = [],
        subDistricts = [];

      if (province) {
        // Populate list of districts if province selected
        districts = await getDistrictOptions(province.provincecode);
      }
      if (districtName) {
        // Populate list of sub-districts if district selected
        let district = districts.find((district) => district.districtname === districtName);
        if (district) {
          subDistricts = await getSubDistrictOptions(district.districtcode);
        }
      }

      this.setState({
        districts: districts,
        sub_districts: subDistricts,
      });
    }
  };

  validate = (data) => {
    window.console.log(data);
    let messages = this.props.languageMap.messages;
    const errors = {};
    if ('fullname' in data && !data.fullname) {
      errors.fullname = messages.required;
    }
    if ('dob' in data && !data.dob) {
      errors.dob = messages.required;
    }
    if (!data.id_number) {
      errors.id_number = messages.required;
    }
    if (data.dob && data.dob === 'Invalid date') {
      errors.dob = 'Invalid Date';
    }
    if (data.mobile && !validateMobileNumber(data.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }
    window.console.log(errors);
    return errors;
  };

  patchDriver = async (data) => {
    this.setState({
      loading: true,
    });
    return api.dataEntry
      .patchDriver(parseInt(this.props.carId, 10), { drivers: data, car_id: parseInt(this.props.carId, 10) })
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
          updatedData: {},
        });
        return {
          success: true,
        };
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        return {
          error: true,
          details: error,
        };
      });
  };

  saveDriver = async (data) => {
    let drivers = [data];
    return api.dataEntry
      .driver(parseInt(this.props.userId, 10), { drivers, car_id: parseInt(this.props.carId, 10) })
      .then((response) => {
        window.console.log(response);
        this.setState({
          loading: false,
        });
        return {
          success: true,
        };
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        return {
          error: true,
          details: error,
        };
      });
  };

  deleteDriver = (data) => {
    window.console.log(data);
    if (!data.id) {
      this.props.deleteDriver(this.props.index);
      return;
    }
    api.dataEntry
      .deleteDriver(data.id)
      .then((response) => {
        this.props.action(this.props.carId);
      })
      .catch((error) => console.log(error));
  };

  save = async () => {
    let errors = this.validate(this.state.data);

    this.setState({
      errors,
    });
    window.console.log(errors);
    if (Object.keys(errors).length) {
      scrollTop();
      return true;
    }
    let data = this.state.data;
    if (data.id) {
      let updatedData = { ...this.state.updatedData };
      if ((updatedData && !Object.keys(updatedData).length) || !Object.keys(updatedData.fairdee_address).length) {
        delete updatedData.fairdee_address;
      }
      if (!Object.keys(updatedData).length) {
        window.console.log('You did not change any data');
        return false;
      }
      window.console.log(updatedData);
      let driver = { ...updatedData, id: data.id };
      if (driver.fairdee_address && Object.keys(driver.fairdee_address).length) {
        let obj = {};
        if (driver.fairdee_address.district) {
          obj.district = driver.fairdee_address.district;
        }
        if (driver.fairdee_address.first_line) {
          obj.first_line = driver.fairdee_address.first_line;
        }
        if (driver.fairdee_address.postal_code) {
          obj.postal_code = driver.fairdee_address.postal_code;
        }
        if (driver.fairdee_address.province) {
          obj.province = driver.fairdee_address.province;
        }
        if (driver.fairdee_address.subdistrict) {
          obj.subdistrict = driver.fairdee_address.subdistrict;
        }
        if (driver.fairdee_address.id) {
          obj.id = driver.fairdee_address.id;
        }
        driver.fairdee_address = obj;
      }
      if (driver.dob) {
        driver.dob = moment(driver.dob).format('YYYY-MM-DD');
      }
      window.console.log(driver);
      let result = await this.patchDriver([driver]);
      window.console.log(result);
      if (result.success) {
        return false;
      }
      if (result.error) {
        if (
          result.details &&
          result.details.response &&
          result.details.response.data &&
          result.details.response.data.message
        ) {
          let errors = {};
          if (Object.keys(result.details.response.data.message).length) {
            Object.keys(result.details.response.data.message).forEach((key) => {
              errors[key] = result.details.response.data.message[key][0];
            });
            this.setState({
              errors,
            });
          }
        }
        return true;
      }
    }
    let params = {};
    if (data.fullname) {
      params.fullname = data.fullname;
    }
    if (data.gender) {
      params.gender = data.gender;
    }
    if (data.fairdee_address && Object.keys(data.fairdee_address).length) {
      params.fairdee_address = data.fairdee_address;
    }
    if (data.id_number) {
      params.id_number = data.id_number;
    }
    if (data.email) {
      params.email = data.email;
    }
    if (data.postal_code) {
      params.postal_code = data.postal_code;
    }
    if (data.mobile) {
      params.mobile = data.mobile;
    }
    if (data.dob) {
      params.dob = moment(data.dob).format('YYYY-MM-DD');
    }

    let result = await this.saveDriver(params);

    if (result.success) {
      return false;
    }
    if (result.error) {
      if (
        result.details &&
        result.details.response &&
        result.details.response.data &&
        result.details.response.data.message
      ) {
        let errors = {};
        if (Object.keys(result.details.response.data.message).length) {
          Object.keys(result.details.response.data.message).forEach((key) => {
            errors[key] = result.details.response.data.message[key][0];
          });
          this.setState({
            errors,
          });
        }
      }
      return true;
    }
  };

  render() {
    let { data, errors, componentMounted, districts, sub_districts } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.driverForm;

    return (
      <div className={classes.fullWidth}>
        {componentMounted && (
          <form className={classes.container + ' vouch-form'} noValidate autoComplete="nope">
            {this.props.index > 0 ? (
              <DeleteIcon className={classes.delete} onClick={this.deleteDriver.bind(null, data)} />
            ) : (
              ''
            )}
            <TextField
              id="fullname"
              label={languageMap.textOne}
              className={classes.textField}
              value={data.fullname}
              margin="normal"
              error={errors.fullname ? true : false}
              helperText={errors.fullname}
              autoComplete="off"
              index={1}
              name="fullname"
              onChange={this.handleChange('fullname')}
            />
            <FormControl fullWidth={true} className={classes.textField + ' ' + classes.noGutter}>
              <FormLabel component="legend">{languageMap.textTwo}</FormLabel>
              <div className={classes.inlineRadio}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.gender === 'M'}
                      onChange={this.handleChange('gender')}
                      value="M"
                      name="gender"
                      aria-label={`Male`}
                      color="primary"
                    />
                  }
                  label={languageMap.textNine}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.gender === 'F'}
                      onChange={this.handleChange('gender')}
                      value="F"
                      name="gender"
                      aria-label={`Female`}
                      color="primary"
                    />
                  }
                  label={languageMap.textTen}
                />
              </div>
              {errors.gender && <p className="error-text pull-up">{errors.gender}</p>}
            </FormControl>

            <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
              <InlineDatePicker
                // keyboard
                id="dob"
                label={languageMap.textThree}
                className={classes.datePicker}
                value={data.dob}
                onChange={this.handleChange('dob')}
                minDate={moment().subtract(100, 'year')}
                maxDate={moment().subtract(18, 'year')}
                initialFocusedDate={moment().subtract(24, 'year')}
                format="DD/MM/YYYY"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            </MuiPickersUtilsProvider>
            {errors.dob ? <InlineError className={classes.negMT} text={errors.dob} /> : ''}
            <TextField
              id="id_number"
              label={languageMap.textFour}
              className={classes.textField}
              value={data.id_number}
              margin="normal"
              error={errors.id_number ? true : false}
              helperText={errors.id_number}
              autoComplete="off"
              index={6}
              name="id_number"
              onChange={this.handleChange('id_number')}
            />
            <TextField
              id="first_line"
              label={languageMap.textSix}
              className={classes.textField}
              value={data.fairdee_address.first_line || ''}
              margin="normal"
              error={errors.first_line ? true : false}
              helperText={errors.first_line}
              autoComplete="off"
              index={6}
              name="first_line"
              onChange={this.handleAddressChange('first_line')}
            />
            <div className="form-field with-width">
              <label htmlFor="" className="form-label">
                {languageMap.textEleven}
              </label>
              <Dropdown
                placeholder={languageMap.textEleven}
                selection
                search
                options={PROVINCES}
                onChange={this.handleProvinceChange}
                name="province"
                value={data.fairdee_address.province}
              />
            </div>
            <div className={'form-field with-width ' + (!districts.length ? 'disabled' : '')}>
              <label htmlFor="" className="form-label">
                {languageMap.textTwelve}
              </label>
              <Dropdown
                placeholder={languageMap.textTwelve}
                selection
                search
                options={districts}
                onChange={this.handleDistrictChange}
                name="district"
                value={data.fairdee_address.district}
              />
            </div>
            <div className={'form-field with-width ' + (!sub_districts.length ? 'disabled' : '')}>
              <label htmlFor="" className="form-label">
                {languageMap.textThirteen}
              </label>
              <Dropdown
                placeholder={languageMap.textThirteen}
                selection
                search
                options={sub_districts}
                onChange={this.handleSubDistrictChange}
                name="subdistrict"
                value={data.fairdee_address.subdistrict}
              />
            </div>
            <TextField
              id="postal_code"
              label={languageMap.textFive}
              className={classes.textField}
              value={data.fairdee_address.postal_code || ''}
              margin="normal"
              error={errors.postal_code ? true : false}
              helperText={errors.postal_code}
              autoComplete="off"
              index={10}
              name="postal_code"
              onChange={this.handleAddressChange('postal_code')}
            />
            <TextField
              type="number"
              id="mobile"
              label={languageMap.textEight}
              className={classes.textField}
              value={data.mobile || ''}
              margin="normal"
              error={errors.mobile ? true : false}
              helperText={errors.mobile}
              autoComplete="off"
              index={13}
              name="mobile"
              onChange={this.handleChange('mobile')}
            />
          </form>
        )}
      </div>
    );
  }
}

DriverForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, {})(withStyles(styles)(DriverForm));
