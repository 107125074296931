import { Button } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import api from '../../commonjs/api';
import { paymentStatusOptions, policyStatusOptions } from '../../commonjs/commonData';
import { errorHandler, notify } from '../../utils/helper';
import Loader from '../utils/Loader';
import './_order-status-update-form.scss';

const OrderStatusUpdateForm = ({ id, data }) => {
  const languageMap = useSelector((state) => state.languageMap.components.eventForm),
    [statusData, setStatusData] = useState({
      policy_status: data?.order_details?.length ? data?.order_details[0]?.policy_status : '',
      payment_status: data?.order_details?.length ? data?.order_details[0]?.payment_status : '',
    }),
    [loading, setLoading] = useState(false);

  const handleDropdownChange = (data) => {
    setStatusData({
      ...statusData,
      [data.name]: data.value,
    });
  };

  const submitData = () => {
    if (statusData.policy_status || statusData.payment_status) {
      let params = {
        id: data?.order_details[0].id,
      };
      setLoading(true);

      if (statusData.policy_status) {
        params.policy_status = statusData.policy_status;
      }

      if (statusData.payment_status) {
        params.payment_status = statusData.payment_status;
      }

      api.utils
        .patchQoalaOrder(id, [params])
        .then((res) => {
          notify('Success', 'success');
          setLoading(false);
        })
        .catch((error) => {
          errorHandler(error, true);
          setLoading(false);
        });
    }
  };

  if (loading) {
    return <Loader titleText={'Loading'} />;
  }

  return (
    <div className="status-dropdown-container">
      <Dropdown
        placeholder="Policy Status"
        selection
        search
        options={policyStatusOptions}
        onChange={(e, data) => handleDropdownChange(data)}
        value={statusData.policy_status}
        name="policy_status"
        required
        className="custom"
      />
      <Dropdown
        placeholder="Payment Status"
        selection
        search
        options={paymentStatusOptions}
        onChange={(e, data) => handleDropdownChange(data)}
        value={statusData.payment_status}
        name="payment_status"
        required
        className="custom"
      />
      <Button className="orange-button" onClick={submitData}>
        Update
      </Button>
    </div>
  );
};

export default OrderStatusUpdateForm;
