export const validHostNames: { [unit: string]: boolean } = {
  'fairdee.co.th': true,
  'www.fairdee.co.th': true,
};

export const getUrlparamsAsObject = () => {
  let params: Record<any, any> = {};
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  if (!query) {
    return {};
  }
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (params[pair[0]] && params[pair[0]].constructor !== Array) {
      params[pair[0]] = [params[pair[0]]];
      params[pair[0]].push(decodeURIComponent(pair[1]));
    } else if (params[pair[0]] && params[pair[0]].constructor === Array) {
      params[pair[0]].push(decodeURIComponent(pair[1]));
    } else {
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
  }
  Object.keys(params).forEach((key) => {
    if (!key) {
      delete params[key];
    }
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
};
