function open(message, deepLink) {
  let element = document.createElement('div');
  element.classList.add('notification-popup');
  element.innerHTML = `
    <div class="success-box">
      <div class="dot" id="close"></div>
      <div class="face">
        <div class="eye"></div>
        <div class="eye right"></div>
        <div class="mouth happy"></div>
      </div>
      <div class="shadow scale"></div>
      <div class="message"><h1 class="alert">${message}</h1></div>
      <button class="button-box"><h1 class="green">continue</h1></button>
    </div>
  `;
  document.body.appendChild(element);
  document.getElementsByClassName('dot')[0].addEventListener('click', close);
  document.getElementsByClassName('button-box')[0].addEventListener('click', () => navigate(deepLink));
}

function navigate(deepLink) {
  window.location = deepLink;
}

function close() {
  document.getElementsByClassName('notification-popup')[0].remove();
}

const NotificationPopup = {
  open,
  close,
};

export default NotificationPopup;
