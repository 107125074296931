import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { toThaiDateTimeBase } from '../../utils/helper';

class TrackingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        remark: '',
      },
      errors: {},
    };
  }

  componentDidMount() {}

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.trackingModal;
    const { data } = this.props;

    window.console.log(data);

    if (!data || !data.voluntary_policy) {
      this.close();
      return null;
    }

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <div className="mobile-content">
            <h1 className="title-text">{languageMap.textOne}</h1>
            <p className="text">{languageMap.textTwo}</p>
            <div className="content-details-wrap">
              <div className="key-value-wrap">
                <p className="key">{languageMap.textThree} : </p>
                <p className="value">{data.voluntary_policy.postal_tracking_code}</p>
              </div>
              <div className="key-value-wrap">
                <p className="key cust-width">{languageMap.textFour} :</p>
                <p className="value link">
                  <a
                    href={`https://www.shippop.com/tracking/?tracking_code=${data.voluntary_policy.postal_tracking_code}`}
                    target="_blank"
                  >
                    https://www.shippop.com/
                  </a>
                </p>
              </div>
              <div className="key-value-wrap">
                <p className="key">{languageMap.textFive} : </p>
                <p className="value">{toThaiDateTimeBase(data.voluntary_policy.dispatch_date)}</p>
              </div>
            </div>
          </div>
          <div className="buttons-wrap single">
            <button className="button medium" onClick={this.close}>
              {languageMap.textSeven}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TrackingModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(TrackingModal));
