import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import validator from 'validator';

class AffiliateCoverNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        client_title: props.quotation.client_title || '',
        client_first_name: props.quotation.client_first_name || '',
        client_last_name: props.quotation.client_last_name || '',
        vehicle_number: props.quotation.vehicle_number || '',
        chassis_number: props.quotation.chassis_number || '',
        cover_note_code: props.quotation.cover_note_code || '',
        is_locked: null,
      },
      errors: {},
      loading: false,
      updatedData: {},
    };
    window.console.log(this.state.data);
  }

  componentDidMount() {}

  validate = (data) => {
    const errors = {};

    return errors;
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;

    if (name === 'chassis_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'chassis_number') {
      value = value ? value.toUpperCase() : value;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  save = () => {
    let errors = this.validate(this.state.updatedData);
    window.console.log(this.state.data, errors);

    if (Object.keys(errors).length) {
      return;
    }

    let params = Object.assign({}, this.state.updatedData);

    api.utils
      .patchFairdeeQuotation(this.props.quotation.id, params)
      .then((resp) => {
        window.console.log(resp);
        if (this.props.action) {
          this.props.action();
        }
        this.close();
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, errors, loading } = this.state;
    const languageMap = this.props.languageMap.components.coverNote;

    return (
      <div className="fairdee-modal-wrapper customer-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Generate Covernote</span>
            </p>
          </div>
          <h1 className="title-text">Generate Covernote</h1>
          {/* <p className="text">{languageMap.textTwo}</p> */}
          <Form noValidate className="quotation-form">
            {/* <Form.Field>
              <input
                type="text"
                id="covernote_number"
                name="covernote_number"
                placeholder='Covernote Number'
                value={data.covernote_number}
                onChange={this.onChange}
              />
              {errors.covernote_number && <InlineError text={errors.covernote_number} />}
              <label className="form-label" htmlFor="covernote_number">Covernote Number</label>
            </Form.Field>
            <Form.Field>
              <input
                type="text"
                id="chassis_number"
                name="chassis_number"
                placeholder='Chassis Number'
                value={data.chassis_number}
                onChange={this.onChange}
              />
              {errors.chassis_number && <InlineError text={errors.chassis_number} />}
              <label className="form-label" htmlFor="chassis_number">Chassis Number</label>
            </Form.Field> */}
            <Form.Field className="disabled">
              <input
                type="text"
                id="client_title"
                placeholder={languageMap.form.textOne}
                value={data.client_title || ''}
                margin="normal"
                autoComplete="off"
                name="client_title"
                onChange={this.handleChange('client_title')}
                className="full-width"
              />
              {errors.client_title && <InlineError text={errors.client_title} />}
              <label className="form-label" htmlFor="client_title">
                {languageMap.form.textOne}
              </label>
            </Form.Field>
            <Form.Field className="disabled">
              <input
                type="text"
                id="client_first_name"
                placeholder={languageMap.form.textTwo}
                value={data.client_first_name || ''}
                margin="normal"
                autoComplete="off"
                name="client_first_name"
                onChange={this.handleChange('client_first_name')}
                className="full-width"
              />
              {errors.client_first_name && <InlineError text={errors.client_first_name} />}
              <label className="form-label" htmlFor="client_first_name">
                {languageMap.form.textTwo}
              </label>
            </Form.Field>
            <Form.Field className="disabled">
              <input
                type="text"
                id="client_last_name"
                placeholder={languageMap.form.textThree}
                value={data.client_last_name || ''}
                margin="normal"
                autoComplete="off"
                name="client_last_name"
                onChange={this.handleChange('client_last_name')}
                className="full-width"
              />
              {errors.client_last_name && <InlineError text={errors.client_last_name} />}
              <label className="form-label" htmlFor="client_last_name">
                {languageMap.form.textThree}
              </label>
            </Form.Field>
            <Form.Field className="disabled">
              <input
                type="text"
                id="vehicle_number"
                placeholder={languageMap.form.textFour}
                value={data.vehicle_number || ''}
                margin="normal"
                autoComplete="off"
                name="vehicle_number"
                onChange={this.handleChange('vehicle_number')}
                className="full-width"
              />
              {errors.vehicle_number && <InlineError text={errors.vehicle_number} />}
              <label className="form-label" htmlFor="vehicle_number">
                {languageMap.form.textFour}
              </label>
            </Form.Field>
            <Form.Field>
              <input
                type="text"
                id="chassis_number"
                placeholder={languageMap.form.textFive}
                value={data.chassis_number || ''}
                margin="normal"
                autoComplete="off"
                name="chassis_number"
                onChange={this.handleChange('chassis_number')}
                className="full-width"
              />
              {errors.chassis_number && <InlineError text={errors.chassis_number} />}
              <label className="form-label" htmlFor="chassis_number">
                {languageMap.form.textFive}
              </label>
            </Form.Field>
            <div className="buttons-wrap single">
              <button className="button medium" onClick={this.save}>
                Update
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

AffiliateCoverNote.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(AffiliateCoverNote));
