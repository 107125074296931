import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import cross from '../../images/close.svg';
import { isMobileDevice, setLanguageToElement } from '../../utils/helper';

const GOOGLE_FORM_LINK = 'https://forms.gle/bgdgiinULej8XeNY9';

class AffiliateConditionsModal extends React.Component {
  componentDidMount() {
    setLanguageToElement();

    setTimeout(() => {
      if (window.location.pathname === '/aff-additional-info') {
        this.close();
      }
    }, 100);
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  continue = () => {
    if (this.props.action) {
      this.props.action();
    }
    this.close();
  };

  gotoBankDetails = () => {
    this.props.history.push('/bank-details');
    this.close();
  };

  gotoProfile = () => {
    if (!isMobileDevice()) {
      this.props.history.push('/profile');
    } else {
      this.props.history.push('/buy-insurance?type=profile');
    }

    this.close();
  };

  renderContent = () => {
    const languageMap = this.props.languageMap.components.affiliateConditionModal;
    if (this.props.type === 'no-license') {
      return (
        <div>
          <p className="text-one">
            ผู้เอาประกันต้องมีนามสกุลเดียวกับเจ้าของบัญชี
            เนื่องจากคุณยังไม่มีบัตรนายหน้าจึงซื้อให้ตนเองและครอบครัวเท่านั้น{' '}
          </p>
          <p className="text-one">ถ้าคุณได้สอบผ่านแล้วรบกวนติดต่อบริษัทเพื่อให้เราตรวจเอกสาร </p>
          <p className="text-one">
            ท่านที่สนใจสามารถลงทะเบียนแสดงความประสงค์เข้ารับการอบรมเพื่อสอบใบนายหน้าได้โดยกดปุ่มข้างล่าง
          </p>
          <div className="buttons-wrap">
            <a className="button primary medium" target="_blank" href={GOOGLE_FORM_LINK}>
              สนใจเข้าอบรมสอบบัตรนายหน้า
            </a>
            <button className="button primary medium" onClick={this.continue}>
              ดำเนินการซื้อ
            </button>
          </div>
        </div>
      );
    }
    if (this.props.type === 'no-doc') {
      return (
        <div>
          <p className="text-one">ขออภัย คุณไม่สามารถสร้างใบเสนอราคาได้เนื่องจากคุณยังไม่ได้อัพโหลดสำเนาบัตรนายหน้า</p>
          <div className="buttons-wrap">
            <a className="button primary medium" target="_blank" href={GOOGLE_FORM_LINK}>
              สนใจเข้าอบรมสอบบัตรนายหน้า
            </a>
            <button className="button primary medium" onClick={this.gotoBankDetails}>
              อัพโหลดบัตรตัวแทน/นายหน้า
            </button>
          </div>
        </div>
      );
    }
    if (this.props.type === 'no-license-onload') {
      return (
        <div>
          <p className="text-one">
            {languageMap.nonLicensed.textOne}{' '}
            <span className="link-text" onClick={this.gotoProfile}>
              {languageMap.nonLicensed.textTwo}
            </span>
          </p>
          <p className="text-one">{languageMap.nonLicensed.textThree} </p>
          <div className="buttons-wrap">
            <a className="button primary medium" target="_blank" href={GOOGLE_FORM_LINK}>
              {languageMap.nonLicensed.textFour}
            </a>
            <button className="button primary medium" onClick={this.close}>
              {languageMap.nonLicensed.textFive}
            </button>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="fairdee-modal-wrapper small-medium message">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="message-modal-content">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

AffiliateConditionsModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(AffiliateConditionsModal));
