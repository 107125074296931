import React from 'react';
import { useSelector } from 'react-redux';
import AppUpdateIcon from '../../../images/fairdee-x-plus/icons/app-update.svg';
import { RootState } from '../../../index';
import { detectPlatform } from '../../../utils/helper';
import './_app-update.scss';

const AppUpdate: React.FC<{ url: string }> = (url) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus);
  const platformInUse = detectPlatform();
  let redirectUrl = 'https://bit.ly/3Ry7wkw';

  if (platformInUse === 'ios-wv') {
    redirectUrl = 'https://apple.co/3AL9KHr';
  }

  function openUrl() {
    let link: any = document.createElement('a');
    link.textContent = 'download';
    link.href = redirectUrl;
    link.style.visibility = 'hidden';
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="app-update-wrapper">
      <div className="app-update">
        <div className="title-section">{languageMap['Update']}</div>
        <div className="content-section">
          <div className="img-wrap">
            <img src={AppUpdateIcon} alt="icon" />
          </div>
          <p className="text">{languageMap['There is a new application update!!']}</p>
          <div className="bg-wrap">
            <p className="text-one">{languageMap['About Update']}</p>
            <p className="text-two">
              <span></span>
              {languageMap['Revamped User Experience']}
            </p>
            <p className="text-two">
              <span></span>
              {languageMap['New look and feel']}
            </p>
          </div>
          <p className="helper-text">{languageMap['To download the latest update']}</p>
          <p className="helper-text">
            {
              languageMap[
                `Please go to your ${detectPlatform() === 'ios-wv' ? 'Apple App store' : 'Google Play store'}`
              ]
            }
          </p>
          <p className="info-text">{languageMap['*If updated']}</p>
          <p className="info-text">{languageMap['close- open new applications']}</p>
          <div className="button-wrapper">
            <button className="ant-btn" onClick={openUrl}>
              {languageMap['Update']}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUpdate;
