import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, Form, Radio } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import PACard from '../../components/pa/PACard';
import PACardMobile from '../../components/pa/PACardMobile';
import { S3_URL } from '../../constants/config';
import { MODAL_TYPE_AFFILIATE_INFO, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { getParameterByName, isAgent, isMember, isMobileDevice, numberWithCommas } from '../../utils/helper';

const steps = ['showFlow', 'showFilters', 'showCards', 'showConfirmation'];
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const documents = {
    smk: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/pa-form/smk-pa-application-new.pdf',
    tip: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/pa-form/tip-pa-application-new.pdf',
    mti: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/pa-form/mti-pa-application-new.pdf',
    vib: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/pa-form/vib-pa-application.pdf',
    asia: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/pa-form/asia-pa-application.pdf',
  },
  PAPrices = `${S3_URL}docs/pa_plans_comparison.pdf`,
  CancerPrices = `${S3_URL}docs/cancer_plans_comparison.pdf`;

function PACardsModal(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const messages = languageMap.messages;
  const paLangMap = languageMap.components.paModal;
  const [plans, setPlans] = useState([]);
  const [step, setStep] = useState('showFlow');
  const [occupations, setOccupations] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const [isCancerFlow, setIsCancerFlow] = useState(props.type === 'cancer');

  const [parameters, setParameters] = useState({
    min_age__gte: 0,
    max_age__lte: 100,
    hospital_cash__gte: 0,
    hospital_cash__lte: 1000,
    premium_after_tax__gte: 0,
    premium_after_tax__lte: 25000,
    sum_insured__gte: 0,
    sum_insured__lte: 1200000,
    medical_coverage__gte: 0,
    medical_coverage__lte: 300000,
    funeral_coverage__gte: 0,
    funeral_coverage__lte: 30000,
  });

  useEffect(() => {
    if (step === 'showFlow') {
      loadPAOccupations();
    }
  }, [step]);

  function close() {
    props.hideModal(null, { ...props });
  }

  function openAffiliateEditModal(affiliate, nextFn = null) {
    props.showModal(MODAL_TYPE_AFFILIATE_INFO, {
      // action: this.getAffiliate
      hideAffiliateForm: true,
      hidePasswordForm: true,
      currentTab: 'two',
      bankDetailAddress: true,
      data: affiliate,
      nextFn: nextFn,
    });
  }

  function loadPAOccupations() {
    api.masterData.getPAOccupations().then((res) => {
      setOccupations(
        res.map((res) => ({
          value: res.id,
          text: res.name,
          bki_occupation_class: res.bki_occupation_class,
          oic_occupation_class: res.oic_occupation_class,
        }))
      );
    });
  }

  function hasValidData(affiliate) {
    if (!affiliate.bank_details || !affiliate.bank_details.length) {
      return false;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      let bankDetails = affiliate.bank_details[0];
      if (!bankDetails || !bankDetails.ac_number || !bankDetails.bank_name) {
        return false;
      }
    }
    if (!affiliate.documents || !affiliate.documents.length) {
      return false;
    }
    if (affiliate.documents && affiliate.documents.length) {
      let obj = {};
      affiliate.documents.forEach((data) => {
        obj[data.document_type] = true;
      });
      if (!obj.broker_license) {
        return false;
      }
    }
    if (!affiliate.address) {
      return false;
    }
    if (
      affiliate.address &&
      (!affiliate.address.first_line ||
        !affiliate.address.district ||
        !affiliate.address.province ||
        !affiliate.address.postal_code)
    ) {
      return false;
    }

    if (!affiliate.address_nid) {
      return false;
    }
    if (
      !affiliate.address_nid ||
      !affiliate.address_nid.first_line ||
      !affiliate.address_nid.district ||
      !affiliate.address_nid.province ||
      !affiliate.address_nid.postal_code
    ) {
      return false;
    }

    if (!affiliate.national_id || !affiliate.broker_license_number) {
      return false;
    }
    return true;
  }

  function checkAndOpenLicenseModal(nextFn, affiliate = undefined) {
    //ToDo check if the flag is right
    if (!affiliate) {
      affiliate = props.affiliate;
    }
    if (isAgent() && !hasValidData(affiliate)) {
      openAffiliateEditModal(affiliate, (aff = undefined) => checkAndOpenLicenseModal(nextFn, aff));
      return true;
    } else if (props.user.is_banned) {
      notify(props.languageMap.messages.bannedUser, 'error');
      return true;
    } else {
      nextFn();
      return false;
    }
  }

  function getAndProcessPAPlans(params = {}) {
    api.masterData
      .getPAPlans(params)
      .then((res) => {
        setPlans(res.sort((res1, res2) => res1.premium_after_tax - res2.premium_after_tax));
      })
      .catch((err) => {});
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message,
      type,
      autoClose: true,
    });
  }

  function downloadPdf(comparisionCard = false) {
    let link = document.createElement('a');
    let name = '';
    // link.href = generateUrl();
    if (comparisionCard) {
      if (isCancerFlow) {
        link.href = CancerPrices;
      } else {
        link.href = PAPrices;
      }
      name = 'plans_comparision';
    } else if (selectedPrice) {
      if (selectedPrice.insurer.code == 'bki') {
        link.href = selectedPrice.application_form;
      } else {
        link.href = documents[selectedPrice.insurer.code];
      }
      name = `${selectedPrice.insurer.code.toUpperCase()}.pdf`;
    }
    link.textContent = 'download';
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function gotoWizard() {
    let selectedAffiliate = getParameterByName('affiliate');
    api.utils
      .postPAForm(
        {
          is_disclaimer_accepted: true,
          plan: selectedPrice.id,
        },
        selectedAffiliate ? parseInt(selectedAffiliate) : null
      )
      .then((res) => {
        let search = `?id=${res.id}`;
        if (selectedAffiliate) {
          search += '&affiliate=' + selectedAffiliate;
        }

        let pathname = '/pa-wizard';
        close();
        props.history.push({ pathname, search });
      })
      .catch((err) => {});
  }

  function previous() {
    setStep(steps[steps.indexOf(step) - 1]);
  }

  function next(selectedPrice) {
    if (step === 'showFilters') {
      let mandatoryFields = ['age', 'occupation'];
      if (isCancerFlow) {
        mandatoryFields = ['age'];
      }
      let params = { ...parameters };
      const validationErrors = {};
      mandatoryFields.forEach((field) => {
        if (!params[field]) {
          validationErrors[field] = messages.required;
        }
        setErrors(validationErrors);
      });
      if (Object.keys(validationErrors).length) {
        return;
      }
      if (params.occupation) {
        params.bki_occupation_class = occupations.find(
          (occupation) => occupation.value === params.occupation
        ).bki_occupation_class;
        if (params.bki_occupation_class === 'send_for_consideration') {
          delete params.bki_occupation_class;
        }
      }
      if (params.age) {
        params.min_age__lte = params.age;
        params.max_age__gte = params.age;
      }
      delete params.occupation;
      delete params.age;
      params.is_cancer_insurance = isCancerFlow;
      getAndProcessPAPlans(params);
    } else {
      if (selectedPrice && selectedPrice.insurer) {
        setSelectedPrice(selectedPrice);
      }
    }
    setStep(steps[steps.indexOf(step) + 1]);
  }

  function handleRangeChange(value, type) {
    const params = { ...parameters };
    params[`${type}__gte`] = value[0];
    params[`${type}__lte`] = value[1];
    setParameters(params);
  }

  function disablePrev() {
    return steps.indexOf(step) === 0;
  }

  function disableNext() {
    return (step === 'showCards' && !selectedPrice) || steps.indexOf(step) === steps.length - 1;
  }

  function handleDropdownChange(e, { name, value }) {
    setParameters({ ...parameters, [name]: +value });
  }

  function handleChange(e, { name, value }) {
    if (name === 'isCancerFlow') {
      setIsCancerFlow(value === 'true');
      return;
    }
    if (isNaN(value)) {
      return;
    }

    setParameters({ ...parameters, [name]: +value });
  }

  return (
    <div
      className={'fairdee-modal-wrapper customer-info corona-cards' + (step === 'showFlow' ? ' medium' : ' x-large')}
    >
      <div className={'modal-content'}>
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">
              {step === 'showFlow' ? paLangMap.processOfInsurance : paLangMap.buyPAInsurance}
            </span>
          </p>
        </div>
        <h1 className={step === 'showFilters' || step === 'showCards' ? `title-text with-pdf-link` : `title text`}>
          {step === 'showFlow' ? paLangMap.processOfInsurance : paLangMap.buyPAInsurance}
          {(step === 'showFilters' || step === 'showCards') &&
            (isCancerFlow ? (
              <div className="button primary medium blue" onClick={downloadPdf.bind(null, true)}>
                ดูแผนประกันมะเร็งทั้งหมด
              </div>
            ) : (
              <div className="button primary medium blue" onClick={downloadPdf.bind(null, true)}>
                ดูแผนประกันPA ทั้งหมด
              </div>
            ))}
        </h1>
        <div className="conditional-content">
          {/* {
            step === 'showCards' &&
            <div className="download-button">
            <button className="button medium" onClick={() => checkAndOpenLicenseModal(
              .bind(null, true))}>
              {paLangMap.downloadComparisonSheet}
            </button>
            </div>
          } */}
          {step === 'showCards' && (
            <div className="cards-holder">
              {plans.map((plan) =>
                !isMobileDevice() ? <PACard data={plan} next={next} /> : <PACardMobile data={plan} next={next} />
              )}
            </div>
          )}
          {step === 'showFilters' && (
            <div className="ui form quotation-form confirmation-step filter-wrap">
              <p className="dropdown-title">{paLangMap.whatAreYou}</p>
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label="Personal Accident"
                    name="isCancerFlow"
                    value="false"
                    checked={isCancerFlow === false}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Cancer"
                    name="isCancerFlow"
                    value="true"
                    checked={isCancerFlow === true}
                    onChange={handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field error={errors.occupation}>
                <div className="dropdown-title">{paLangMap.occupation}</div>
                <Dropdown
                  placeholder="Select"
                  onChange={handleDropdownChange}
                  value={parameters.occupation}
                  name="occupation"
                  search
                  selection
                  options={occupations}
                  icon="angle down"
                  error={errors.occupation}
                />
              </Form.Field>
              {errors.occupation && <InlineError className="mb-10" text={errors.occupation} />}

              <Form.Input
                label={<div className="dropdown-title">{paLangMap.age}</div>}
                name="age"
                onChange={handleChange}
                type="text"
                value={parameters.age}
                error={errors.age}
                inputMode="numeric"
                pattern="[0-9]*"
              />
              {errors.age && <InlineError className="mb-10" text={errors.age} />}
              {/* <Form.Input
                label={<div className="dropdown-title">Max Age</div>}
                name='max_age__lte'
                onChange={handleChange}
                type='text'
                value={parameters.max_age__lte}
                inputMode="numeric" pattern="[0-9]*"
              /> */}
              <div className="each-filter">
                <p className="dropdown-title">{`${paLangMap.premium}: ${numberWithCommas(
                  parameters.premium_after_tax__gte
                )} - ${numberWithCommas(parameters.premium_after_tax__lte)}`}</p>
                <div className="range-slider-wrap premium-slider">
                  <Range
                    min={0}
                    max={25000}
                    marks={{ 0: '0', 25000: '25,000' }}
                    defaultValue={[0, 25000]}
                    value={[parameters.premium_after_tax__gte, parameters.premium_after_tax__lte]}
                    onChange={(value) => handleRangeChange(value, 'premium_after_tax')}
                    step={100}
                    pushable={true}
                  />
                </div>
              </div>
              <div className="dropdown-title section-title">เลือกความคุ้มครอง</div>
              <div className="each-filter">
                <p className="dropdown-title">{`${paLangMap.sumInsured}: ${numberWithCommas(
                  parameters.sum_insured__gte
                )} - ${numberWithCommas(parameters.sum_insured__lte)}`}</p>
                <div className="range-slider-wrap">
                  <Range
                    min={0}
                    max={1200000}
                    marks={{ 0: '0', 1200000: '1,200,000' }}
                    defaultValue={[0, 1200000]}
                    value={[parameters.sum_insured__gte, parameters.sum_insured__lte]}
                    onChange={(value) => handleRangeChange(value, 'sum_insured')}
                    step={10000}
                    pushable={true}
                  />
                </div>
              </div>
              <div className="each-filter">
                <p className="dropdown-title">{`${paLangMap.medicalCoverage}: ${numberWithCommas(
                  parameters.medical_coverage__gte
                )} - ${numberWithCommas(parameters.medical_coverage__lte)}`}</p>
                <div className="range-slider-wrap">
                  <Range
                    min={0}
                    max={300000}
                    marks={{ 0: '0', 300000: '300,000' }}
                    defaultValue={[0, 300000]}
                    value={[parameters.medical_coverage__gte, parameters.medical_coverage__lte]}
                    onChange={(value) => handleRangeChange(value, 'medical_coverage')}
                    step={10000}
                    pushable={true}
                  />
                </div>
              </div>
              <div className="each-filter">
                <p className="dropdown-title">{`${paLangMap.hospitalCash}: ${numberWithCommas(
                  parameters.hospital_cash__gte
                )} - ${numberWithCommas(parameters.hospital_cash__lte)}`}</p>
                <div className="range-slider-wrap">
                  <Range
                    min={0}
                    max={1000}
                    marks={{ 0: '0', 1000: '1,000' }}
                    defaultValue={[0, 1000]}
                    value={[parameters.hospital_cash__gte, parameters.hospital_cash__lte]}
                    onChange={(value) => handleRangeChange(value, 'hospital_cash')}
                    step={100}
                    pushable={true}
                  />
                </div>
              </div>
              <div className="each-filter">
                <p className="dropdown-title">{`${paLangMap.funeralCoverage}: ${numberWithCommas(
                  parameters.funeral_coverage__gte
                )} - ${numberWithCommas(parameters.funeral_coverage__lte)}`}</p>
                <div className="range-slider-wrap">
                  <Range
                    min={0}
                    max={30000}
                    marks={{ 0: '0', 30000: '30,000' }}
                    defaultValue={[0, 30000]}
                    value={[parameters.funeral_coverage__gte, parameters.funeral_coverage__lte]}
                    onChange={(value) => handleRangeChange(value, 'funeral_coverage')}
                    step={1000}
                    pushable={true}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 'showFlow' ? (
            isMember() ? (
              <div className="flow-steps flow-steps-pa"></div>
            ) : (
              <div className="flow-steps flow-steps-pa"></div>
            )
          ) : null}
          {step === 'showConfirmation' && (
            <div className="confirmation-step">
              <p className="important-text">{paLangMap.proceed}</p>
              <div className="button-wrap">
                <div>
                  <p className="text">{paLangMap.iHearbyDeclare}</p>
                  {selectedPrice &&
                    selectedPrice.metadata &&
                    selectedPrice.metadata.declarations &&
                    selectedPrice.metadata.declarations.map((declaration, index) => (
                      <div>
                        <p className="text">{`${index + 1}. ${declaration}`}</p>
                      </div>
                    ))}
                </div>
                <button className="button medium" onClick={() => checkAndOpenLicenseModal(gotoWizard)}>
                  {paLangMap.proceedToBuy}
                </button>
              </div>
              <div className="button-wrap">
                <p className="text">{paLangMap.or}</p>
                <button
                  className="button medium orange-bordered-button"
                  onClick={() => checkAndOpenLicenseModal(downloadPdf.bind(null, false))}
                >
                  {paLangMap.downloadApplication}
                </button>
                {selectedPrice.insurer.code === 'tip' && (
                  <p className="text">
                    เปลี่ยนใบคำขอ (เพิ่มระยะรอคอย 14 วัน) กรุณาใช้ใบคำขอใหม่เท่านั้น
                    มิเช่นนั้นเราอาจจะยกเลิกกรมธรรม์ทุกกรณี
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="prev-next">
          <div>
            {!disablePrev() && (
              <button className="button medium orange-bordered-button prev" onClick={previous}>
                &lt; {paLangMap.prev}
              </button>
            )}
          </div>
          <div>
            {!disableNext() && (
              <button className="button medium orange-bordered-button next" onClick={next}>
                {paLangMap.next} &gt;
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
    insurers: state.insurers,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, hideModal, showNotification })(PACardsModal));
