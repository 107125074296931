import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    marginLeft: 5,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
});

class LoadingButton extends React.Component {
  render() {
    const { classes, children, loading, is_success, is_failure, className, ...rest } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: is_success,
      [classes.buttonFail]: is_failure,
    });
    return (
      <Button {...rest} className={`${buttonClassname} ${className}`}>
        {children}
        {loading && <CircularProgress className={classes.spinner} size={10} />}
      </Button>
    );
  }
}

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  is_success: PropTypes.bool.isRequired,
  is_failure: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LoadingButton);
