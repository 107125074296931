import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import DengueCard from '../../components/dengue/DengueCard';
import DengueCardMobile from '../../components/dengue/DengueCardMobile';
import { S3_URL } from '../../constants/config';
import { MODAL_TYPE_AFFILIATE_INFO, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { getParameterByName, isAgent, isMember, isMobileDevice } from '../../utils/helper';

const steps = ['showFlow', 'showCards', 'showConfirmation'];

const documents = {
    smk: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/smk-dengue-application-new.pdf',
    tip: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/tip-dengue-application-new.pdf',
    mti: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/mti-dengue-application-new.pdf',
    vib: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/vib-dengue-application.pdf',
    asia: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/asia-dengue-application.pdf',
    bki: 'https://vouch-av.s3-ap-southeast-1.amazonaws.com/dengue-form/bki-dengue-application.pdf',
  },
  DenguePrices = `${S3_URL}docs/dengue_plans_comparison.pdf`;

function DengueCardsModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components),
    dengueLangMap = languageMap.dengueModal,
    [plans, setPlans] = useState([]),
    [step, setStep] = useState('showFlow'),
    [selectedPrice, setSelectedPrice] = useState(null);

  useEffect(() => {
    if (step === 'showFlow') {
      getAndProcessDenguePlans();
    }
  }, [step]);

  function close() {
    props.hideModal(null, { ...props });
  }

  function openAffiliateEditModal(affiliate, nextFn = null) {
    props.showModal(MODAL_TYPE_AFFILIATE_INFO, {
      // action: this.getAffiliate
      hideAffiliateForm: true,
      hidePasswordForm: true,
      currentTab: 'two',
      bankDetailAddress: true,
      data: affiliate,
      nextFn: nextFn,
    });
  }

  function hasValidData(affiliate) {
    if (!affiliate.bank_details || !affiliate.bank_details.length) {
      return false;
    }
    if (affiliate.bank_details && affiliate.bank_details.length) {
      let bankDetails = affiliate.bank_details[0];
      if (!bankDetails || !bankDetails.ac_number || !bankDetails.bank_name) {
        return false;
      }
    }
    if (!affiliate.documents || !affiliate.documents.length) {
      return false;
    }
    if (affiliate.documents && affiliate.documents.length) {
      let obj = {};
      affiliate.documents.forEach((data) => {
        obj[data.document_type] = true;
      });
      if (!obj.broker_license) {
        return false;
      }
    }
    if (!affiliate.address) {
      return false;
    }
    if (
      affiliate.address &&
      (!affiliate.address.first_line ||
        !affiliate.address.district ||
        !affiliate.address.province ||
        !affiliate.address.postal_code)
    ) {
      return false;
    }

    if (!affiliate.address_nid) {
      return false;
    }
    if (
      !affiliate.address_nid ||
      !affiliate.address_nid.first_line ||
      !affiliate.address_nid.district ||
      !affiliate.address_nid.province ||
      !affiliate.address_nid.postal_code
    ) {
      return false;
    }

    if (!affiliate.national_id || !affiliate.broker_license_number) {
      return false;
    }
    return true;
  }

  function checkAndOpenLicenseModal(nextFn, affiliate = undefined) {
    //ToDo check if the flag is right
    if (!affiliate) {
      affiliate = props.affiliate;
    }
    if (isAgent() && !hasValidData(affiliate)) {
      openAffiliateEditModal(affiliate, (aff = undefined) => checkAndOpenLicenseModal(nextFn, aff));
      return true;
    } else if (props.user.is_banned) {
      notify(props.languageMap.messages.bannedUser, 'error');
      return true;
    } else {
      nextFn();
      return false;
    }
  }

  function getAndProcessDenguePlans() {
    api.masterData
      .getDenguePlans()
      .then((res) => {
        setPlans(res.sort((res1, res2) => res1.premium_after_tax - res2.premium_after_tax));
      })
      .catch((err) => {});
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message,
      type,
      autoClose: true,
    });
  }

  function downloadPdf(comparisionCard = false) {
    let link = document.createElement('a');
    let name = '';
    // link.href = generateUrl();
    if (comparisionCard) {
      link.href = DenguePrices;
      name = 'ใบเสนอราคาของประกันยุง';
    } else if (selectedPrice) {
      link.href = documents[selectedPrice.insurer.code];
      name = `${selectedPrice.insurer.code.toUpperCase()}.pdf`;
    }
    link.textContent = 'download';
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function gotoWizard() {
    let selectedAffiliate = getParameterByName('affiliate');
    api.utils
      .postDengueForm(
        {
          is_disclaimer_accepted: true,
          plan: selectedPrice.id,
        },
        selectedAffiliate ? parseInt(selectedAffiliate) : null
      )
      .then((res) => {
        let search = `?id=${res.id}`;
        if (selectedAffiliate) {
          search += '&affiliate=' + selectedAffiliate;
        }

        let pathname = '/dengue-wizard';
        close();
        props.history.push({ pathname, search });
      })
      .catch((err) => {});
  }

  function previous() {
    setStep(steps[steps.indexOf(step) - 1]);
  }

  function next(selectedPrice) {
    if (selectedPrice && selectedPrice.insurer) {
      setSelectedPrice(selectedPrice);
    }
    setStep(steps[steps.indexOf(step) + 1]);
  }

  function disablePrev() {
    return steps.indexOf(step) === 0;
  }

  function disableNext() {
    return (step === 'showCards' && !selectedPrice) || steps.indexOf(step) === steps.length - 1;
  }

  return (
    <div
      className={'fairdee-modal-wrapper customer-info corona-cards' + (step === 'showFlow' ? ' medium' : ' x-large')}
    >
      <div className={'modal-content'}>
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">
              {step === 'showFlow' ? dengueLangMap.processOfInsurance : dengueLangMap.buyDengueInsurance}
            </span>
          </p>
        </div>
        <h1 className="title-text">
          {step === 'showFlow' ? dengueLangMap.processOfInsurance : dengueLangMap.buyDengueInsurance}
        </h1>
        <div className="conditional-content">
          {step === 'showCards' && (
            <div className="download-button">
              <button className="button medium" onClick={() => checkAndOpenLicenseModal(downloadPdf.bind(null, true))}>
                {dengueLangMap.downloadComparisonSheet}
              </button>
            </div>
          )}
          {step === 'showCards' && (
            <div className="cards-holder">
              {plans.map((plan) =>
                !isMobileDevice() ? (
                  <DengueCard data={plan} next={next} />
                ) : (
                  <DengueCardMobile data={plan} next={next} />
                )
              )}
            </div>
          )}
          {step === 'showFlow' ? (
            isMember() ? (
              <div className="flow-steps flow-steps-dengue"></div>
            ) : (
              <div className="flow-steps flow-steps-dengue"></div>
            )
          ) : null}
          {step === 'showConfirmation' && (
            <div className="confirmation-step">
              <p className="important-text">{dengueLangMap.proceed}</p>
              {/* <p className="text">{dengueLangMap.clickToDownloadOrBuy}</p> */}
              <div className="button-wrap">
                <div>
                  <p className="text">{dengueLangMap.iHearbyDeclare}</p>
                  {selectedPrice &&
                    selectedPrice.metadata &&
                    selectedPrice.metadata.declarations &&
                    selectedPrice.metadata.declarations.map((declaration, index) => (
                      <div>
                        <p className="text">{`${index + 1}. ${declaration}`}</p>
                      </div>
                    ))}
                </div>
                <button className="button medium" onClick={() => checkAndOpenLicenseModal(gotoWizard)}>
                  {dengueLangMap.proceedToBuy}
                </button>
              </div>
              <div className="button-wrap">
                <p className="text">{dengueLangMap.or}</p>
                <button
                  className="button medium orange-bordered-button"
                  onClick={() => checkAndOpenLicenseModal(downloadPdf.bind(null, false))}
                >
                  {dengueLangMap.downloadApplication}
                </button>
                {selectedPrice.insurer.code === 'tip' && (
                  <p className="text">
                    เปลี่ยนใบคำขอ (เพิ่มระยะรอคอย 14 วัน) กรุณาใช้ใบคำขอใหม่เท่านั้น
                    มิเช่นนั้นเราอาจจะยกเลิกกรมธรรม์ทุกกรณี
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="prev-next">
          <div>
            {!disablePrev() && (
              <button className="button medium orange-bordered-button prev" onClick={previous}>
                &lt; {dengueLangMap.prev}
              </button>
            )}
          </div>
          <div>
            {!disableNext() && (
              <button className="button medium orange-bordered-button next" onClick={next}>
                {dengueLangMap.next} &gt;
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
    insurers: state.insurers,
  };
}

export default withRouter(connect(mapStateToProps, { showModal, hideModal, showNotification })(DengueCardsModal));
