import { HOLIDAYS } from '../constants/types';

function holidays(state = [], action = {}) {
  switch (action.type) {
    case HOLIDAYS:
      return action.holidays;
    default:
      return state;
  }
}

export { holidays };
