import React from 'react';
import { DEFAULT_SIGNUP } from '../../../constants';
import RegisterEmail from './RegisterEmail';
import RegisterPhone from './RegisterPhone';
import './_login-register.scss';

interface Props {
  close?: () => void;
  isIg?: boolean;
}

const Register: React.FC<Props> = ({ isIg, close }) => {
  function renderView() {
    if (DEFAULT_SIGNUP.includes('phone')) {
      return <RegisterPhone isIg={isIg} close={close} />;
    } else if (DEFAULT_SIGNUP.includes('email')) {
      return <RegisterEmail isIg={isIg} close={close} />;
    }
    return null;
  }

  return <div>{renderView()}</div>;
};

export default Register;
