export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_REGISTERED = 'USER_REGISTERED';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const NOTIFICATION = 'NOTIFICATION';
export const MODAL_TYPE_QUICKVIEWINSURER = 'MODAL_TYPE_QUICKVIEWINSURER';
export const MODAL_TYPE_SHARE_DETAILS = 'MODAL_TYPE_SHARE_DETAILS';
export const MODAL_TYPE_FAIRDEE_LOADER = 'MODAL_TYPE_FAIRDEE_LOADER';
export const MODAL_TYPE_REDIRECTION_LOADER = 'MODAL_TYPE_REDIRECTION_LOADER';
export const MODAL_TYPE_LOGIN_SIGNUP = 'MODAL_TYPE_LOGIN_SIGNUP';
export const MODAL_TYPE_LOGIN = 'MODAL_TYPE_LOGIN';
export const MODAL_TYPE_SIGNUP = 'MODAL_TYPE_SIGNUP';
export const CURRENT_LANGUAGE_MAP = 'CURRENT_LANGUAGE_MAP';
export const CURRENT_LANGUAGE = 'CURRENT_LANGUAGE';
export const FILTER = 'FILTER';
export const INSURERS = 'INSURERS';
export const MODAL_TYPE_MAKE_MODEL = 'MODAL_TYPE_MAKE_MODEL';
export const MODAL_TYPE_SIGNUP_STEP = 'MODAL_TYPE_SIGNUP_STEP';
export const WIZARD_STEP_ONE_MODAL = 'WIZARD_STEP_ONE_MODAL';
export const SUM_INSURED_MODAL = 'SUM_INSURED_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const MODAL_TYPE_AFFILIATE_EXTRA_INFO = 'MODAL_TYPE_AFFILIATE_EXTRA_INFO';
export const MODAL_TYPE_QUOTATION_DOCUMENT = 'MODAL_TYPE_QUOTATION_DOCUMENT';
export const MODAL_TYPE_WIZARD_THANKYOU = 'MODAL_TYPE_WIZARD_THANKYOU';
export const MODAL_TYPE_MANUAL_QUOTE = 'MODAL_TYPE_MANUAL_QUOTE';
export const MODAL_TYPE_COVERNOTE_FORM = 'MODAL_TYPE_COVERNOTE_FORM';
export const MODAL_TYPE_SALE_TRACK = 'MODAL_TYPE_SALE_TRACK';
export const MODAL_TYPE_CUSTOMER_INFO = 'MODAL_TYPE_CUSTOMER_INFO';
export const MODAL_TYPE_USER_ADDRESS = 'MODAL_TYPE_USER_ADDRESS';
export const MODAL_TYPE_REMARK = 'MODAL_TYPE_REMARK';
export const MODAL_TYPE_TRACKING = 'MODAL_TYPE_TRACKING';
export const MODAL_TYPE_PAYMENT = 'MODAL_TYPE_PAYMENT';
export const MODAL_TYPE_COVERNOTE_DOWNLOAD = 'MODAL_TYPE_COVERNOTE_DOWNLOAD';
export const MODAL_TYPE_REMARKS = 'MODAL_TYPE_REMARKS';
export const MODAL_TYPE_AFFILIATE_COVERNOTE = 'MODAL_TYPE_AFFILIATE_COVERNOTE';
export const MODAL_TYPE_DELETE = 'MODAL_TYPE_DELETE';
export const MODAL_TYPE_POLICY = 'MODAL_TYPE_POLICY';
export const MODAL_TYPE_POLICY_NON_MOTOR = 'MODAL_TYPE_POLICY_NON_MOTOR';
export const MODAL_TYPE_CHANGE_PASSWORD = 'MODAL_TYPE_CHANGE_PASSWORD';
export const MODAL_TYPE_FORGOT_PASSWORD = 'MODAL_TYPE_FORGOT_PASSWORD';
export const MODAL_TYPE_RESET_PASSWORD = 'MODAL_TYPE_RESET_PASSWORD';
export const MODAL_TYPE_QUOTATION_CONFIRMATION = 'MODAL_TYPE_QUOTATION_CONFIRMATION';
export const PROVINCES = 'PROVINCES';
export const MODAL_TYPE_PROVINCE = 'MODAL_TYPE_PROVINCE';
export const MODAL_TYPE_INVOICE = 'MODAL_TYPE_INVOICE';
export const MODAL_TYPE_ADD_CREDIT = 'MODAL_TYPE_ADD_CREDIT';
export const MODAL_TYPE_EDIT_CREDIT = 'MODAL_TYPE_EDIT_CREDIT';
export const MODAL_TYPE_IMPORTED_SALE_DETAILS = 'MODAL_TYPE_IMPORTED_SALE_DETAILS';
export const MODAL_TYPE_MESSAGE = 'MODAL_TYPE_MESSAGE';
export const AFFILIATE = 'AFFILIATE';
export const MODAL_TYPE_AFFILIATE_CONDITION = 'MODAL_TYPE_AFFILIATE_CONDITION';
export const MODAL_TYPE_COMPULSORY_INVOICE = 'MODAL_TYPE_COMPULSORY_INVOICE';
export const MODAL_TYPE_ADD_EMAIL = 'MODAL_TYPE_ADD_EMAIL';
export const MODAL_TYPE_REFER_EARN = 'MODAL_TYPE_REFER_EARN';
export const MODAL_TYPE_FAIRDEE_SALE_INCENTIVES = 'MODAL_TYPE_FAIRDEE_SALE_INCENTIVES';
export const MODAL_TYPE_MAKE_MODEL_BIKE = 'MODAL_TYPE_MAKE_MODEL_BIKE';
export const MODAL_TYPE_COA_FORM = 'MODAL_TYPE_COA_FORM';
export const MODAL_TYPE_ACCESSORIES = 'MODAL_TYPE_ACCESSORIES';
export const MODAL_TYPE_THANKYOU = 'MODAL_TYPE_THANKYOU';
export const MODAL_TYPE_AFFILIATE_ADDITIONAL_INFO = 'MODAL_TYPE_AFFILIATE_ADDITIONAL_INFO';
export const MODAL_TYPE_DOCUMENTS = 'MODAL_TYPE_DOCUMENTS';
export const MODAL_TYPE_SELECT_INSURER = 'MODAL_TYPE_SELECT_INSURER';
export const MODAL_TYPE_AFFILIATE_EDIT = 'MODAL_TYPE_AFFILIATE_EDIT';
export const MODAL_TYPE_DRAWER_VIEW = 'MODAL_TYPE_DRAWER_VIEW';
export const MODAL_TYPE_CRM_MODAL = 'MODAL_TYPE_CRM_MODAL';
export const MODAL_TYPE_VIDEO_PLAYER = 'MODAL_TYPE_VIDEO_PLAYER';
export const MODAL_TYPE_DOCUMENTS_UPLOAD = 'MODAL_TYPE_DOCUMENTS_UPLOAD';
export const MODAL_TYPE_FILE_VIEWER = 'MODAL_TYPE_FILE_VIEWER';
export const MODAL_TYPE_AFFILIATE_INFO = 'MODAL_TYPE_AFFILIATE_INFO';
export const YOUTUBE_MODAL = 'YOUTUBE_MODAL';
export const MODAL_TYPE_LICENSEANDID_UPDATE = 'MODAL_TYPE_LICENSEANDID_UPDATE';
export const MODAL_TYPE_BANK_STATEMENT_UPLOAD = 'MODAL_TYPE_BANK_STATEMENT_UPLOAD';
export const MODAL_TYPE_INTERMEDIATE_PAYMENT = 'MODAL_TYPE_INTERMEDIATE_PAYMENT';
export const MODAL_TYPE_INCENTIVE_INVOICE = 'MODAL_TYPE_INCENTIVE_INVOICE';
export const MODAL_TYPE_SALE_DOCUMENTS = 'MODAL_TYPE_SALE_DOCUMENTS';
export const MODAL_TYPE_AFTER_SIGNUP_CAROUSEL = 'MODAL_TYPE_AFTER_SIGNUP_CAROUSEL';
export const MODAL_TYPE_RENEWED_QUOTATION = 'MODAL_TYPE_RENEWED_QUOTATION';
export const FIRST_LOAD = 'FIRST_LOAD';
export const MODAL_TYPE_QR_PAYMENT = 'MODAL_TYPE_QR_PAYMENT';
export const MODAL_TYPE_DELETE_IP = 'MODAL_TYPE_DELETE_IP';
export const MODAL_TYPE_DOWNLOAD_DOCUMENTS = 'MODAL_TYPE_DOWNLOAD_DOCUMENTS';
export const MODAL_TYPE_UPLOAD_SOA = 'MODAL_TYPE_UPLOAD_SOA';
export const MODAL_TYPE_UPDATE_INSURER_CREDITS = 'MODAL_TYPE_UPDATE_INSURER_CREDITS';
export const MODAL_TYPE_CORONA_CARDS = 'MODAL_TYPE_CORONA_CARDS';
export const MODAL_TYPE_CORONA_BANNER = 'MODAL_TYPE_CORONA_BANNER';
export const MODAL_TYPE_COVID_CANCELLATION = 'MODAL_TYPE_COVID_CANCELLATION';
export const MODAL_TYPE_LEADGEN_CODE = 'MODAL_TYPE_LEADGEN_CODE';
export const MODAL_TYPE_AFFILIATE_LEADGEN_GENERATE = 'MODAL_TYPE_AFFILIATE_LEADGEN_GENERATE';
export const MODAL_TYPE_RECORD_PAYMENT = 'MODAL_TYPE_RECORD_PAYMENT';
export const MODAL_TYPE_SHOW_PAYMENT = 'MODAL_TYPE_SHOW_PAYMENT';
export const MODAL_TYPE_SELECT_VENDOR = 'MODAL_TYPE_SELECT_VENDOR';
export const MODAL_TYPE_FD_TO_VENDOR_IP = 'MODAL_TYPE_FD_TO_VENDOR_IP';
export const MODAL_TYPE_DENGUE_CARDS = 'MODAL_TYPE_DENGUE_CARDS';
export const MODAL_TYPE_DENGUE_BANNER = 'MODAL_TYPE_DENGUE_BANNER';
export const NEW_MODAL = 'NEW_MODAL';
export const MODAL_TYPE_PA_CARDS = 'MODAL_TYPE_PA_CARDS';
export const MODAL_TYPE_PA_BANNER = 'MODAL_TYPE_PA_BANNER';
export const ACTION_SHEET = 'ACTION_SHEET';
export const MODAL_TYPE_INVOICE_DETAIL = 'MODAL_TYPE_INVOICE_DETAIL';
export const MODAL_TYPE_ATTACH_LEADGEN = 'MODAL_TYPE_ATTACH_LEADGEN';
export const MODAL_TYPE_RECORD_ENDORSEMENT = 'MODAL_TYPE_RECORD_ENDORSEMENT';
export const LOCATION = 'LOCATION';
export const CLEAR_MODAL = 'CLEAR_MODAL';
export const MODAL_TYPE_CORONA_RENEWAL_CARDS = 'MODAL_TYPE_CORONA_RENEWAL_CARDS';
export const MODAL_TYPE_LG_MODAL = 'MODAL_TYPE_LG_MODAL';
export const MODAL_TYPE_TOPUP_PREPAID_CREDIT = 'MODAL_TYPE_TOPUP_PREPAID_CREDIT';
export const MODAL_TYPE_SUBAGENT = 'MODAL_TYPE_SUBAGENT';
export const MODAL_TYPE_UPLOAD_SUBAGENT_RECONCILIATION = 'MODAL_TYPE_UPLOAD_SUBAGENT_RECONCILIATION';
export const MOBILE_MODAL = 'MOBILE_MODAL';
export const BREADCRUMB = 'BREADCRUMB';
export const MODAL_TYPE_RECORD_CIF_SALE = 'MODAL_TYPE_RECORD_CIF_SALE';
export const SYSTEM_SETTINGS = 'SYSTEM_SETTINGS';
export const SELECTED_AFFILIATE = 'SELECTED_AFFILIATE';
export const OCR = 'OCR';
export const HOLIDAYS = 'HOLIDAYS';
export const SC_ASSIGNMENT_MODAL = 'SC_ASSIGNMENT_MODAL';
export const DOWLOAD_DOCUMENTS_GENERIC = 'DOWLOAD_DOCUMENTS_GENERIC';
export const MODAL_TYPE_EVENT_FORM = 'MODAL_TYPE_EVENT_FORM';
export const MODAL_TYPE_NON_MOTOR_ORDER_STATUS = 'MODAL_TYPE_NON_MOTOR_ORDER_STATUS';
