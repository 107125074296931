import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import PolicyForm from '../../components/PolicyForm';
import cross from '../../images/cross.svg';

class PolicyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      policyData: {},
      policyDataCompulsary: {},
      quotation: {},
      tab: 0,
      showCompulsaryInsurance: props.data.needs_compulsory_insurance,
    };
  }

  componentDidMount() {
    this.getFairdeeQuotation();
  }

  close = (action) => {
    this.props.hideModal(null, {
      ...this.props,
    });
    if (action === true) {
      this.props.action();
    }
  };

  getFairdeeQuotation = () => {
    api.utils
      .getFairdeeQuotation(this.props.data.id)
      .then((resp) => {
        let policyData = {
          gross_premium: parseFloat(resp.message.price_list.gross_premium, 10).toFixed(2),
          affiliate_commission: parseFloat(resp.message.price_list.affiliate_commission, 10).toFixed(2),
          subsidy: parseFloat(resp.message.price_list.subsidy || '0', 10).toFixed(2),
          deductible: resp.message.price_list.deductible,
          garage_type: resp.message.price_list.garage_type,
          insurance_class: resp.message.price_list.insurance_class,
          insurer: resp.message.price_list.insurer ? resp.message.price_list.insurer.id : null,
          sum_insured: resp.message.quotation_query.sum_insured,
          policy_start_date: resp.message.policy_start_date || '',
          policy_end_date: resp.message.policy_start_date ? moment(resp.message.policy_start_date).add('years', 1) : '',
          tax: parseFloat(resp.message.price_list.tax || 0, 10).toFixed(2),
          duty: parseFloat(resp.message.price_list.duty || 0, 10).toFixed(2),
          policy_number: resp.message.policy_number,
          postal_tracking_code: resp.message.postal_tracking_code,
          dispatch_date: resp.message.dispatch_date,
        };
        let netPremium = policyData.gross_premium * 0.9309;
        let duty = netPremium * 0.004;
        let tax = (netPremium + duty) * 0.07;
        let net_premium = policyData.gross_premium + duty + tax;
        policyData.net_premium = parseFloat(net_premium, 10).toFixed(2);
        // policyData.tax = parseFloat(tax, 10).toFixed(2);
        // policyData.duty = parseFloat(duty, 10).toFixed(2);

        let policyDataCompulsary = {};
        if (resp.message.needs_compulsory_insurance) {
          let gross_premium = parseFloat(resp.message.cmi_price_list.gross_premium, 10);
          let duty = Math.ceil(gross_premium * 0.004);
          let tax = (gross_premium + duty) * 0.07;
          policyDataCompulsary.affiliate_commission = parseFloat(
            resp.message.cmi_price_list.affiliate_commission,
            10
          ).toFixed(2);
          policyDataCompulsary.gross_premium = gross_premium;
          policyDataCompulsary.insurer = resp.message.price_list.insurer ? resp.message.price_list.insurer.id : null;
          policyDataCompulsary.subsidy = '0';
          policyDataCompulsary.policy_start_date = resp.message.policy_start_date || '';
          policyDataCompulsary.policy_end_date = resp.message.policy_start_date
            ? moment(resp.message.policy_start_date).add('years', 1)
            : '';
          policyDataCompulsary.net_premium = parseFloat(resp.message.cmi_price_list.premium_after_tax, 10);
          policyDataCompulsary.duty = duty ? duty.toFixed(2) : null;
          policyDataCompulsary.tax = tax ? tax.toFixed(2) : null;
        }

        if (resp.message.sale && resp.message.sale.voluntary_policy) {
          resp.message.sale.voluntary_policy.documents = resp.message.sale.voluntary_policy.documents.map((data) => {
            data.name = data.file.original_file_name;
            delete data.file;
            return data;
          });
        }

        if (resp.message.sale && resp.message.sale.compulsory_policy) {
          resp.message.sale.compulsory_policy.documents = resp.message.sale.compulsory_policy.documents.map((data) => {
            data.name = data.file.original_file_name;
            delete data.file;
            return data;
          });
        }

        this.setState({
          userData: resp.message.user || {
            fullname: resp.message.client_first_name || '',
            email: resp.message.client_email || '',
            phone: resp.message.client_phone || '',
          },
          policyData,
          quotation: resp.message,
          policyDataCompulsary,
          showCompulsaryInsurance: resp.message.needs_compulsory_insurance,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  showCompulsaryPolicy = () => {
    this.setState({
      tab: 1,
    });
  };

  handleTabChange = (e, tab) => {
    this.setState({ tab });
  };

  changeStep = (step) => {
    this.handleTabChange(null, step);
  };

  scrollTo = (element, offset = 0) => {
    let elem = document.getElementsByClassName('sale-track-stepper-content')[0];
    if (elem) {
      setTimeout(() => {
        elem.scrollTop = offset;
      }, 1000);
    }
  };

  renderPolicyForm = () => {
    const { showCompulsaryInsurance, tab, policyData, policyDataCompulsary, quotation } = this.state;
    const { data, statusUpdate, adminAction, scrollToBottom } = this.props;
    const languageMap = this.props.languageMap.components.saleTrackModal;

    if (!Object.keys(quotation).length) {
      return null;
    }
    if (statusUpdate && scrollToBottom) {
      this.scrollTo(null, 800);
    }

    if (quotation.needs_compulsory_insurance) {
      return (
        <div>
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className="tabs"
          >
            <Tab className="policy-tab" label={languageMap.textTwo} />
            <Tab className={'policy-tab'} label={languageMap.textOne} />
          </Tabs>
          {tab === 0 ? (
            <PolicyForm
              adminAction={adminAction}
              statusUpdate={statusUpdate}
              quotation={quotation}
              saleTrack={data.sale}
              is_compulsory={false}
              changeStep={this.changeStep}
              carId={data.car ? data.car.id : null}
              policyData={policyData}
              showCompulsaryPolicy={this.showCompulsaryPolicy}
              action={this.close}
              scrollTo={this.scrollTo}
              saleStatus={this.props.saleStatus}
            />
          ) : (
            <PolicyForm
              adminAction={adminAction}
              statusUpdate={statusUpdate}
              quotation={quotation}
              saleTrack={data.sale}
              is_compulsory={true}
              changeStep={this.changeStep}
              carId={data.car ? data.car.id : null}
              policyData={policyDataCompulsary}
              showCompulsaryPolicy={this.showCompulsaryPolicy}
              action={this.close}
              scrollTo={this.scrollTo}
              saleStatus={this.props.saleStatus}
            />
          )}
        </div>
      );
    } else {
      return (
        <PolicyForm
          adminAction={adminAction}
          statusUpdate={statusUpdate}
          quotation={quotation}
          saleTrack={data.sale}
          is_compulsory={false}
          changeStep={this.changeStep}
          carId={data.car ? data.car.id : null}
          policyData={policyData}
          showCompulsaryPolicy={this.showCompulsaryPolicy}
          action={this.close}
          scrollTo={this.scrollTo}
          saleStatus={this.props.saleStatus}
        />
      );
    }
  };

  render() {
    const { policyData } = this.props;
    return (
      <div className="fairdee-modal-wrapper sale-track">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="sales-track-wrap policy-wrap">
            <div className="sale-track-stepper-content">{this.renderPolicyForm()}</div>
          </div>
        </div>
      </div>
    );
  }
}

PolicyModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(PolicyModal));
