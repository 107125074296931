import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import loading from '../images/loader.gif';

class FairdeeLoader extends React.Component {
  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    return (
      <div className="fairdee-loader">
        <div className="content">
          <img src={loading} alt="" />
          <p className="text">Hold up tight!</p>
          <p className="text">We are getting the best results for you. </p>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, { hideModal })(FairdeeLoader));
