import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { affiliateProfile } from '../../../actions/affiliate';
import { userProfile } from '../../../actions/auth';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { CLIENT_ID, CLIENT_SECRET } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import SetPinBanner from '../../../images/fairdee-x-plus/auth/set-pin.svg';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import { RootState } from '../../../index';
import {
  errorHandler,
  getAccessToken,
  getParameterByName,
  isEmailOtp,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
  setUserData,
  validatePassword,
} from '../../../utils/helper';
import { Mixpanel } from '../../../utils/tracking';
import InlineError from '../../InlineError';
import InfoModal from '../common/InfoModal';
import PasscodeSetSuccess from './PasscodeSetSuccess';
import './_login-register.scss';

interface Props {
  close?: () => void;
  action?: (passCode: string) => void;
  intent?: string | undefined;
  otp?: string | undefined;
  username: string;
  emailLogin?: boolean;
}

interface Errors {
  passCode?: string;
  passcodeConfirmation?: string;
  passCodeLength?: string,
  passCodeLowercase?: string,
  passCodeUppercase?: string,
  passCodeNumber?: string,
  passCodeSpecial?: string,
}

const CreatePassCode: React.FC<Props> = ({ close, otp, username, intent, emailLogin }) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    [passCode, setPassCode] = useState({
      passcode: '',
      passcodeConfirmation: '',
    }),
    [isPasswordVisible, setIsPasswordVisible] = useState(false),
    [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false),
    [disableBtn, setDisableBtn] = useState(true),
    user = useSelector((state: RootState) => state.user),
    affiliate = useSelector((state: RootState) => state.affiliate),
    dispatch = useDispatch(),
    history = useHistory(),
    [updateSuccess, setUpdateSuccess] = useState(false),
    [errors, setErrors] = useState<Errors>({}),
    [loading, setLoading] = useState(false),
    isIg = getParameterByName('ig') === 'true',
    isDirectAgent = getParameterByName('direct_agent') === 'true',
    isBusinessManager = getParameterByName('business_manager') === 'true',
    trackEvent = (window as { [key: string]: any })['trackEvent'] as any;

  let path = '/signup',
    params = window.location.search;

  if (params) {
    path = `${path}${params}`;
  }

  useEffect(() => {
    let errors = validate();
    setErrors(errors)
    if (!errors.passcodeConfirmation && !errors.passCode) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [passCode]);

  useEffect(() => {
    if (user.id) {
      trackEvent.register({
        Name: user.fullname || '',
        Identity: user.id,
      });
      if (user.affiliates.length > 1) {
        if (user.is_pin_set) {
          redirectToWelcome();
        }
      } else {
        setAffiliateProfile();
      }
    }
  }, [user]);

  useEffect(() => {
    if (affiliate.id) {
      if (affiliate.is_signup_complete || (isEmailOtp() && affiliate.has_broker_license !== null)) {
        history.push('/buy-insurance');
      } else {
        history.push(path);
      }
      if (updateSuccess) {
        close && close();
      }
    }
  }, [affiliate]);

  function redirectToWelcome() {
    close && close();
    history.push('/welcome');
  }

  function setAffiliateProfile() {
    dispatch(affiliateProfile());
  }

  function getUserProfile() {
    dispatch(userProfile(isIg || isDirectAgent || isBusinessManager));
  }

  function handleInputChange(name: string, value: string) {
    setPassCode({ ...passCode, [name]: value });
  }

  function togglePasswordVisibility() {
    setIsPasswordVisible(prevState => !prevState);
  }

  function toggleConfirmPasswordVisibility() {
    setIsConfirmPasswordVisible(prevState => !prevState);
  }
  function openPasscodeSuccess() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <PasscodeSetSuccess />,
        uid: 'PASSCODE_SET',
        backgroundClick: true,
      })
    );
  }

  function validate() {
    let errors = {
      passCode: '',
      passcodeConfirmation: '',
      passCodeLength: '',
      passCodeLowercase: '',
      passCodeUppercase: '',
      passCodeNumber: '',
      passCodeSpecial: '',
    };
    
    if (!validatePassword(passCode.passcode)) {
      errors.passCode = languageMap[`Invalid Password`];
    }

    if (passCode.passcode.length < 8) {
      errors.passCode = languageMap[`Invalid Password`];
      errors.passCodeLength = "Less than 8 charecters"
    }
    if (!/[A-Z]/.test(passCode.passcode)) {
      errors.passCode = languageMap[`Invalid Password`];
      errors.passCodeUppercase = "Password must contain at least one uppercase letter";
    }
    if (!/[a-z]/.test(passCode.passcode)) {
      errors.passCode = languageMap[`Invalid Password`];
      errors.passCodeLowercase = "Password must contain at least one lowercase letter";
    }
    if (!/\d/.test(passCode.passcode)) {
      errors.passCode = languageMap[`Invalid Password`];
      errors.passCodeNumber = "Password must contain at least one digit";
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(passCode.passcode)) {
      errors.passCode = languageMap[`Invalid Password`];
      errors.passCodeSpecial = "Password must contain at least one special character";
    }

    if (!passCode.passcodeConfirmation || passCode.passcodeConfirmation.length < 8) {
      errors.passcodeConfirmation = languageMap[`Invalid Password`];
    }

    if (passCode.passcode && passCode.passcodeConfirmation && passCode.passcode !== passCode.passcodeConfirmation) {
      errors.passcodeConfirmation = languageMap[`Invalid Password`];
    }
    return errors;
  }

  function openInfoModal(title: string, text: string, btnText: string, action: any) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} btnText={btnText} action={action} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function handlePasscodeLogin() {
    let params: any = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      username, // user mobile number
      password: passCode.passcode,
      is_mlm: false,
      is_inspection_garage: false,
      is_direct_agent: false,
      is_business_manager: false,
      username_type: emailLogin ? 'email' : 'phone',
    };
    if (!(isIg || isDirectAgent || isBusinessManager)) {
      params.is_mlm = true;
    } else if (isIg) {
      params.is_inspection_garage = true;
    } else if (isBusinessManager) {
      params.is_business_manager = true;
    } else {
      params.is_direct_agent = true;
    }
    setLoading(true);
    api.user
      .login(params)
      .then((response: any) => {
        setAxiosHeaders(response.access_token);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setUserData({ accessToken: response.access_token });
        Mixpanel.track('Login/signup', {
          object: 'Login',
          objectContext: {
            parent: 'Otp Modal',
          },
          action: 'Successful',
        });
        getUserProfile();
      })
      .catch((error: any) => {
        setLoading(false);
        setDisableBtn(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_pin' &&
          error.response.data.remaining_attempts > 0
        ) {
          openInfoModal(languageMap[`Incorrect Password`], languageMap[`Please check and enter correct Password.`], '', null);
          return;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_pin' &&
          error.response.data.remaining_attempts === 0
        ) {
          openInfoModal(
            languageMap[`Multiple failed PIN attempts`],
            languageMap[`Your account has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`],
            '',
            () => {}
          );
        } else if (error.response && error.response.data && error.response.data.detail) {
          openInfoModal(
            languageMap[`Multiple failed PIN attempts`],
            languageMap[`Your account has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`],
            '',
            () => {}
          );
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function patchUser() {
    api.user
      .patchProfile({ pin: passCode.passcode })
      .then(() => {
        if (user.id) {
          getUserProfile();
          if (user.affiliates.length > 1) {
            redirectToWelcome();
          } else {
            setAffiliateProfile();
          }
          openPasscodeSuccess();
          setUpdateSuccess(true);
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  function otpPinReset() {
    window.console.log('emailLogin', emailLogin);
    let params = {
      username_type: emailLogin ? 'email' : 'phone',
      username,
      otp,
      pin: passCode.passcode,
      is_email_otp: emailLogin ? 'true' : isEmailOtp(),
    };

    api.user
      .otpPinReset(params)
      .then(() => {
        if (!getAccessToken()) {
          handlePasscodeLogin();
        }
      })
      .catch((error) => {
        errorHandler(error);
        if (error.response && error.response.data && error.response.data.otp) {
          openInfoModal(languageMap[`Wrong OTP entered`], languageMap[`Please enter correct OTP.`], '', () => {
            close && close();
          });
        }
      });
  }

  function handleNext() {
    let tempErrors = validate();
    setErrors(tempErrors);

    if (errors.passCode) {
      return;
    }
    if (intent === 'reset_pin') {
      otpPinReset();
      return;
    }
    patchUser();
  }
  function countSuccess() {
    let successCount = Object.keys(errors).length - 2; // Subtracting 2 for 'passCode' and 'passcodeConfirmation'
    Object.keys(errors).forEach(successKey => {
      if (successKey !== 'passCode' && successKey !== 'passcodeConfirmation' && errors[successKey as keyof Errors]) {
        successCount--;
      }
    });
    return successCount;
  }
  return (
    <div className="mobile-layout login-register otp">
      <div className="header">
        <div className="back" onClick={close}>
          <img src={BackArrow} alt="back_arrow" />
        </div>
        <p className="text">{languageMap[`Set Password`]} </p>
      </div>
      <div className="content-wrapper">
        <div className="otp-img-wrap">
          <img src={SetPinBanner} alt="set-pin-screen" />
        </div>
        <div className="text-wrapper">
          <h2 className="title-text">{languageMap[`Set Password`]}</h2>
          <p className="sub-text">
            {languageMap[`Set a Password that you will use to log into your account. You can change this Password later.`]}{' '}
          </p>
        </div>
        <div className={'input-wrapper ' + (errors.passCode ? 'error' : '')} id = "passwordReset">
          <label htmlFor="">{languageMap[`Enter Password`]} </label>
          <div className="input-row">
            <input className= {"password " + (errors.passCode ? 'error' : '')} value={passCode.passcode} type={isPasswordVisible ? 'text' : 'password'} onChange={(e: any) => handleInputChange('passcode', e.target.value)} />
            <button className= {"toggleEyeButton " + (errors.passCode ? 'error' : '')} onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <i className="fas fa-eye-slash"></i>
              ) : (
                <i className="fas fa-eye"></i>
              )}
            </button>
          </div>
        </div>
        <div className="progress-lines">
          <div className={"progress-line " + (countSuccess() > 0 ? 'valid' : '')}></div>
          <div className={"progress-line " + (countSuccess() > 1 ? 'valid' : '')}></div>
          <div className={"progress-line " + (countSuccess() > 2 ? 'valid' : '')}></div>
          <div className={"progress-line " + (countSuccess() > 3 ? 'valid' : '')}></div>
          <div className={"progress-line " + (countSuccess() > 4 ? 'valid' : '')}></div>
        </div>
        <div className="password-indicators">
          <div><span id="passCodeLength" className={"indicator " + (errors.passCodeLength ? 'error' : '')}>{passCode.passcode.length} characters</span></div>
          <div><span id="passCodeLowercase" className={"indicator " + (errors.passCodeLowercase ? 'error' : '')}>Lowercase</span></div>
          <div><span id="passCodeUppercase" className={"indicator " + (errors.passCodeUppercase ? 'error' : '')}>Uppercase</span></div>
          <div><span id="passCodeNumber" className={"indicator " + (errors.passCodeNumber ? 'error' : '')}>Number</span></div>
          <div><span id="passCodeSpecial" className={"indicator " + (errors.passCodeSpecial ? 'error' : '')}>Symbol</span></div>
        </div>
        <div className={'input-wrapper ' + (errors.passCode ? 'error' : '')}>
          <label htmlFor="">{languageMap[`Confirm Password`]} </label>
          <div className="input-row">
            <input className= {"password " + (errors.passcodeConfirmation ? 'error' : '')} type={isConfirmPasswordVisible ? 'text' : 'password'} value={passCode.passcodeConfirmation}
              onChange={(e: any) => handleInputChange('passcodeConfirmation', e.target.value)} />
            <button className= {"toggleEyeButton " + (errors.passcodeConfirmation ? 'error' : '')} onClick={toggleConfirmPasswordVisibility}>
              {isConfirmPasswordVisible ? (
                <i className="fas fa-eye-slash"></i>
              ) : (
                <i className="fas fa-eye"></i>
              )}
            </button>
          </div>
        </div>
      </div>
      {passCode.passcode !== passCode.passcodeConfirmation ? (
        <div className="text-wrapper">
          <p className="sub-text warning">{languageMap['Error: Password does not match']}</p>
        </div>
      ) : null}
      <div className="bottom-section">
        <Button className={'orange-button ' + (disableBtn || loading ? 'disabled' : '')} onClick={handleNext}>
          {languageMap[`Done`]}
        </Button>
      </div>
    </div>
  );
};

export default CreatePassCode;
