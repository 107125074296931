import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { hideModal } from '../../actions/modal';
import apiEndpoints from '../../commonjs/apiEndpoints';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { snakeToTitleCase } from '../../utils/helper';

const styles = (theme) => ({
  flex: {
    flexGrow: 1,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  gridItem: {
    paddingRight: 0,
  },
});

class QuotationDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('QuotationDocumentModal => ', props);

    let documents = {};
    props.quotation.documents.forEach((doc) => {
      if (!documents[doc.document_type]) {
        documents[doc.document_type] = [];
      }

      documents[doc.document_type].push(doc);
    });

    this.state = {
      documents: documents,
    };
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  render() {
    const { documents } = this.state;
    const { classes } = this.props;

    return (
      <div className="fairdee-modal-wrapper small">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">Documents</span>
            </p>
          </div>
          {Object.keys(documents).map((document_type) => (
            <div key={document_type} className={classes.flex}>
              <span className="title-text">{snakeToTitleCase(document_type)}</span>
              <Grid container spacing={24}>
                {documents[document_type].map((doc) => (
                  <Grid item="true" xs={12} key={doc.id} className={classes.gridItem}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      href={apiEndpoints.quotationDocumentUpload + '/' + doc.id}
                    >
                      {doc.file.original_file_name}
                      <CloudDownload className={classes.rightIcon} />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

QuotationDocumentModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(withStyles(styles)(QuotationDocumentModal)));
