import { FILTER } from '../constants/types';

function filter(state = {}, action = {}) {
  switch (action.type) {
    case FILTER:
      return action.filter;
    default:
      return state;
  }
}

export { filter };
