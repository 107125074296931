import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Checkbox, Dropdown, Form, Radio } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import FileUpload from '../../components/FileUpload';
import { default as PROVINCES } from '../../constants/provinces.json';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { getDistrictOptions, getSubDistrictOptions } from '../../utils/helper';

class AffiliateExtraInfoModal extends React.Component {
  constructor(props) {
    super(props);

    window.console.log('AffiliateExtraInfoModal => ', props);

    this.state = {
      data: {},
      districts: [],
      sub_districts: [],
      errors: {},
      loading: false,
      extraInfo: [],
    };
  }

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleRadioChange = (e, data) => {
    this.setState({
      data: { ...this.state.data, [data.name]: data.value },
    });
  };

  handleCheckboxChange = (e, data) => {
    window.console.log(data);
    let extraInfo = this.state.extraInfo;
    if (extraInfo.includes(data.value)) {
      window.console.log(data.value);
      extraInfo = extraInfo.filter((d) => d !== data.value);
    } else {
      extraInfo.push(data.value);
    }
    window.console.log(extraInfo);
    this.setState({
      extraInfo,
    });
  };

  handleSelectChange = (e, data) => {
    window.console.log(data.name);
    this.setState(
      {
        data: { ...this.state.data, [data.name]: data.value },
      },
      async () => {
        window.console.log(this.state.data);
        let tempData = this.state.data;
        if (data.name === 'province') {
          tempData.district = null;
          tempData.subdistrict = null;
          this.setState({
            districts: [],
            sub_districts: [],
            data: tempData,
          });
          let districts = await getDistrictOptions(data.value);
          this.setState({
            districts: districts,
          });
        } else if (data.name === 'district') {
          let subDistricts = await getSubDistrictOptions(data.value);
          tempData.subdistrict = null;
          this.setState({
            sub_districts: subDistricts,
            data: tempData,
          });
        } else if (data.name === 'subdistrict') {
          this.state.sub_districts.forEach((d) => {
            if (d.subdistrictcode === data.value) {
              tempData.postal_code = d.zip;
              this.setState({
                data: tempData,
              });
            }
          });
        }
      }
    );
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  submit = () => {
    this.close();
  };

  render() {
    const { data, districts, sub_districts, extraInfo } = this.state;
    const languageMap = this.props.languageMap.components.affiliateExtraInfoModal;

    return (
      <div className="fairdee-modal-wrapper small affiliate-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.textOne}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.textTwo}</h1>
          <Form noValidate className="quotation-form">
            <Form.Field className="mbo">
              <p className="label-text">Please Upload National Id</p>
              <FileUpload files={[]} loading={false} name="national_id" />
            </Form.Field>
            <Form.Field>
              <p className="label-text">Please Upload Front Page of Your Bank account</p>
              <FileUpload files={[]} loading={false} name="bank_account" />
            </Form.Field>
            <Form.Field>
              <div className="ui radio checkbox">
                <Radio
                  name="address_type"
                  onChange={this.handleRadioChange}
                  checked={data.address_type === 'type_one'}
                  label="According to address in national id"
                  value="type_one"
                />
              </div>
            </Form.Field>
            <Form.Field>
              <div className="ui radio checkbox">
                <Radio
                  name="address_type"
                  onChange={this.handleRadioChange}
                  checked={data.address_type === 'type_two'}
                  label="Other Address for all cases"
                  value="type_two"
                />
              </div>
            </Form.Field>
            <Form.Field>
              <div className="ui radio checkbox">
                <Radio
                  name="address_type"
                  onChange={this.handleRadioChange}
                  checked={data.address_type === 'type_three'}
                  label="Let us know at the time of purchase"
                  value="type_three"
                />
              </div>
              {data.address_type === 'type_two' && (
                <div>
                  <Form.Field>
                    <Dropdown
                      placeholder="Province"
                      selection
                      options={PROVINCES || []}
                      onChange={this.handleSelectChange}
                      name="province"
                      value={data.province}
                    />
                  </Form.Field>
                  {districts.length ? (
                    <Form.Field>
                      <Dropdown
                        placeholder="Districts"
                        selection
                        options={districts}
                        onChange={this.handleSelectChange}
                        name="district"
                        value={data.district}
                      />
                    </Form.Field>
                  ) : (
                    ''
                  )}
                  {sub_districts.length ? (
                    <Form.Field>
                      <Dropdown
                        placeholder="Sub Districts"
                        selection
                        options={sub_districts}
                        onChange={this.handleSelectChange}
                        name="subdistrict"
                        value={data.subdistrict}
                      />
                    </Form.Field>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field>
              <div className="field">
                <div className="ui radio checkbox">
                  <Radio
                    name="license_holder"
                    onChange={this.handleRadioChange}
                    checked={data.license_holder === 'yes'}
                    label="Have License"
                    value="yes"
                  />
                </div>
              </div>
              <div className="field">
                <div className="ui radio checkbox">
                  <Radio
                    name="license_holder"
                    onChange={this.handleRadioChange}
                    checked={data.license_holder === 'no'}
                    label="Dont have license"
                    value="no"
                  />
                </div>
              </div>
            </Form.Field>
            {data.license_holder === 'yes' ? (
              <Form.Field>
                <p className="label-text">Please Upload Front Page of Your Bank account</p>
                <FileUpload files={[]} loading={false} name="bank_account" />
              </Form.Field>
            ) : (
              <Form.Field>
                <div className="field">
                  <div className="ui checkbox">
                    <Checkbox
                      name="extra-info"
                      onChange={this.handleCheckboxChange}
                      checked={extraInfo.includes('Insurance Shop')}
                      label="Insurance Shop"
                      value="Insurance Shop"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="ui checkbox">
                    <Checkbox
                      name="extra-info"
                      onChange={this.handleCheckboxChange}
                      checked={extraInfo.includes('Insurance Garage')}
                      label="Insurance Garage"
                      value="Insurance Garage"
                    />
                  </div>
                </div>
              </Form.Field>
            )}
            <div className="button-wrap">
              <button className="button medium" onClick={this.submit}>
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

AffiliateExtraInfoModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(AffiliateExtraInfoModal));
