import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modal';
import apiEndpoints from '../../commonjs/apiEndpoints';
import cross from '../../images/cross.svg';
import '../../scss/consumer/_qr-payment.scss';
import { commaSeperatedNumbers } from '../../utils/helper';

function QRPaymentModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.qrPayment);
  useEffect(() => {
    // api.billing.getQRImage(props.data.id).then(res => res.data);
  }, [props]);
  function close() {
    props.hideModal(null, { ...props });
  }

  return (
    <div className="fairdee-modal-wrapper file-viewer small">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <h1 className="title-text">Pay Via QR</h1>
        <div className="qr-details">
          <p className="bold-text flex-item">Amount to be paid: {commaSeperatedNumbers(props.data.amount)}</p>
          <p className="pay-via-qr">Scan QR to Pay</p>
          <div className="flex-item">
            <img className="qr-image" src={`${apiEndpoints.thaiQRRequest}/${props.data.id}`}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal })(QRPaymentModal);
