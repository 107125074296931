import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/th';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import api from '../commonjs/api';
import { setLanguageToElement, snakeToTitleCase } from '../utils/helper';

const THAI_TODAY = moment().locale('th').add(543, 'years');

const SALE_TYPE = ['cbc_to_fairdee', 'cbc_to_insurer', 'credit', 'credit_exceeded'];

class SaleTrackOtherDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        compulsory_start_date: null,
        cover_note_code: '',
        sale_type: props.quotation.sale.sale_type || '',
        insurer_quotation_id: '',
        ...props.quotation,
      },
      errors: {},
      loading: false,
      updatedData: {},
    };
    window.console.log(this.props);
  }

  componentDidMount() {
    let data = this.state.data;
    // if(data.compulsory_start_date) {
    //   data.compulsory_start_date = toThaiDate(data.compulsory_start_date);
    // }
    this.setState({
      data,
    });
    moment().locale('th');
    setLanguageToElement();
  }

  handleChange = (name) => (event) => {
    let value = null;
    if (moment.isMoment(event)) {
      value = event;
    } else {
      value = event.target.value;
    }
    window.console.log(name, value);
    this.setState({
      data: { ...this.state.data, [name]: value },
      updatedData: { ...this.state.updatedData, [name]: value },
    });
  };

  validate = (data) => {
    let errors = {};

    // if (!data.client_title) {
    //   errors.client_title = 'Required Field';
    // }
    // if (!data.client_first_name) {
    //   errors.client_first_name = 'Required Field';
    // }
    // if (!data.client_last_name) {
    //   errors.client_last_name = 'Required Field';
    // }
    // if (!data.vehicle_number) {
    //   errors.vehicle_number = 'Required Field';
    // }
    // if (!data.cover_note_code) {
    //   errors.cover_note_code = 'Required Field';
    // }

    return errors;
  };

  patchSaletrack = (params) => {
    this.setState({
      loading: true,
    });

    api.crm
      .patchSaletrack(this.props.quotation.sale.id, params)
      .then((response) => {
        window.console.log(response);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
      });
  };

  save = () => {
    let errors = this.validate(this.state.updatedData);
    this.setState({
      errors,
    });
    window.console.log(this.state.data, errors);

    if (Object.keys(errors).length) {
      return;
    }

    let params = Object.assign({}, this.state.updatedData);

    if (params.sale_type || params.sale_type === '') {
      this.patchSaletrack({ sale_type: params.sale_type });
      delete params.sale_type;
    }

    if (!Object.keys(params).length) {
      if (this.props.action) {
        this.props.action();
      }
      return;
    }

    if (params.compulsory_start_date) {
      params.compulsory_start_date = params.compulsory_start_date.format('YYYY-MM-DD');
    }

    if (params.cover_note_code) {
      params.is_locked = true;
    }

    api.utils
      .patchFairdeeQuotation(this.props.quotation.id, params)
      .then((resp) => {
        window.console.log(resp);
        if (this.props.action) {
          this.props.action();
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { data, errors, loading } = this.state;
    const languageMap = this.props.languageMap.components.coverNote;

    return (
      <div className="cover-note-wrap">
        <TextField
          id="cover_note_code"
          label={languageMap.form.textSix}
          value={data.cover_note_code || ''}
          margin="normal"
          error={errors.cover_note_code ? true : false}
          helperText={errors.cover_note_code}
          autoComplete="off"
          name="cover_note_code"
          onChange={this.handleChange('cover_note_code')}
          className="full-width"
        />
        <div className="calendar-wrap">
          <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
            <InlineDatePicker
              // keyboard
              id="compulsory_start_date"
              label={languageMap.form.textEight}
              initialFocusedDate={moment()}
              value={data.compulsory_start_date}
              onChange={this.handleChange('compulsory_start_date')}
              // onInputChange={this.handleChange('compulsory_start_date')}
              // minDate={MIN_DATE}
              // maxDate={MAX_DATE}
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
        </div>
        <TextField
          select
          id="sale_type"
          label="Sale Type"
          value={data.sale_type || ''}
          margin="normal"
          error={errors.sale_type ? true : false}
          helperText={errors.sale_type}
          autoComplete="off"
          name="sale_type"
          onChange={this.handleChange('sale_type')}
          className="full-width"
        >
          <MenuItem value="" className="default-item">
            <em>Select Sale Type</em>
          </MenuItem>
          {SALE_TYPE.map((key) => (
            <MenuItem key={SALE_TYPE[key]} value={SALE_TYPE[key]}>
              {snakeToTitleCase(SALE_TYPE[key])}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="insurer_quotation_id"
          label={languageMap.form.textNine}
          value={data.insurer_quotation_id || ''}
          margin="normal"
          error={errors.insurer_quotation_id ? true : false}
          helperText={errors.insurer_quotation_id}
          autoComplete="off"
          name="insurer_quotation_id"
          onChange={this.handleChange('insurer_quotation_id')}
          className="full-width"
        />
        <div className="buttons-wrap">
          <div>
            <Button className="mr-24" onClick={this.props.changeStep.bind(null, 2)}>
              Prev
            </Button>
          </div>
          <div>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={this.save}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SaleTrackOtherDetails.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  window.console.log(state);
  return {
    languageMap: state.languageMap,
    insurers: state.insurers,
    filter: state.filter,
  };
}

export default withRouter(connect(mapStateToProps, {})(SaleTrackOtherDetails));
