import api from '../../commonjs/api';
import InlineError from '../../components/InlineError';
import { errorHandler, getQuotationQueryId } from '../../utils/helper';
import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

function SumInsuredFeedbackModal(props) {
  const languageMap = useSelector((state) => state.languageMap.components.sumInsuredFeedbackModal),
    [reasonOpt, setReasonOpt] = useState(null),
    [reason, setReason] = useState(''),
    [feedback, setFeedback] = useState({}),
    [insurerCompanyOpt, setInsurerCompanyOpt] = useState([]),
    [insurerCompany, setInsurerCompany] = useState(''),
    [loading, setLoading] = useState(false),
    [errors, setErrors] = useState({}),
    history = useHistory();

  useEffect(() => {
    loadReason();
    getInsurers();

    let unlisten = history.listen((location) => {
      window.console.log('Location', location);
      if (location.pathname !== '/get-quotes') {
        props.close && props.close();
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  function getInsurers() {
    const params = {
      code: 'th',
    };
    api.masterData
      .getInsurers(params)
      .then((resp) => {
        resp.results.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        let insurersList = resp.results;
        setInsurerCompanyOpt(
          insurersList.map((item) => ({
            text: item.name,
            value: item.name,
          }))
        );
      })
      .catch((error) => errorHandler(error.response, true));
  }

  function loadReason() {
    const reasonList = languageMap.reason;
    setReasonOpt(
      reasonList.map((item, index) => ({
        text: item,
        value: index + 1,
      }))
    );
  }

  function handleInputChange(name, e) {
    const value = e.target.value;
    setFeedback({ ...feedback, [name]: value });
  }

  function handleSelectChange(name, value) {
    if (name === 'reason') {
      setReason(value);
      setFeedback({});
      return;
    }
    if (name === 'insurer_company') {
      setInsurerCompany(value);
      return;
    }
  }

  function insurerSpecificComp() {
    return (
      <div className="form-field">
        <label htmlFor="insurer_company" className="leftLabel">
          {languageMap.companyName}
        </label>
        <Select
          allowClear
          value={insurerCompany}
          name="insurer_company"
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(value) => handleSelectChange('insurer_company', value)}
          showSearch
        >
          {insurerCompanyOpt &&
            insurerCompanyOpt.map((opt, ind) => (
              <Option value={opt.value} key={ind}>
                {opt.text}
              </Option>
            ))}
        </Select>
      </div>
    );
  }

  function othersField() {
    return (
      <div className="form-field">
        <label htmlFor="other" className="leftLabel">
          {languageMap.otherField}
        </label>
        <Input name="other" value={feedback.other} onChange={(e) => handleInputChange('other', e)} />
      </div>
    );
  }

  function selectedReasonComponent() {
    if (reason === 2) {
      return insurerSpecificComp();
    } else if (reason === 8) {
      return othersField();
    }
  }

  function validate() {
    let errors = {},
      messages = languageMap.messages;
    if (!reason) {
      errors.reason = messages.required;
    }
    return errors;
  }

  function submitFeedback() {
    let remark = null,
      errors = validate();

    setErrors(errors);

    if (Object.keys(errors).length) {
      return;
    }

    // getting text from option value
    const reasonOptText = reasonOpt.filter((item) => {
      if (reason === item.value) {
        return item.text;
      }
    });

    if (reason === 2) {
      remark = `${reasonOptText[0].text} - ${insurerCompany}`;
    } else if (reason === 8) {
      remark = `${reasonOptText[0].text} - ${feedback.other}`;
    } else {
      remark = reasonOptText[0].text;
    }

    let params = {
      quotation_query: getQuotationQueryId(),
      remark,
      data_affected: 'sum_insured',
      data_value: props.filterData.sum_insured,
    };

    if (!params.quotation_query) {
      return;
    }

    setLoading(true);

    api.utils
      .quotationRemark(params)
      .then(() => {
        setLoading(false);
        props.close && props.close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
        setLoading(false);
      });
  }

  return (
    <div className="sum-insured-feedback-modal">
      <h1 className="title">{languageMap.title}</h1>
      <div className="form-wrapper">
        <div className="form-field">
          <label htmlFor="reason" className="leftLabel">
            {languageMap.selectReason}
          </label>

          <Select
            allowClear
            value={reason}
            name="reason"
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => handleSelectChange('reason', value)}
          >
            {reasonOpt &&
              reasonOpt.map((opt, ind) => (
                <Option value={opt.value} key={ind}>
                  {opt.text}
                </Option>
              ))}
          </Select>

          {errors.reason && <InlineError text={errors.reason} />}
        </div>
        {selectedReasonComponent()}
        <div className="button-wrap">
          <button className={'button medium ' + (loading ? 'disabled' : '')} onClick={submitFeedback}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default SumInsuredFeedbackModal;
