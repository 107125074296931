import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import LogoPlus from '../../../images/fairdee-x-plus/common/fairdee-plus-logo.svg';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import { RootState } from '../../../index';
import { errorHandler, handlecontactOps, isEmailOtp, validateMobileNumber } from '../../../utils/helper';
import InlineError from '../../InlineError';
import InfoModal from '../common/InfoModal';
import InputPhoneNumber from '../common/InputPhoneNumber';
import RecaptchaButton from '../common/RecaptchaButton';
import Otp from './Otp';
import './_login-register.scss';

interface Props {
  close?: () => void;
}

interface Errors {
  phone: string;
  message?: string;
}

interface ErrorResponse {
  response: any;
}

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const UpdatePhoneNumber: React.FC<Props> = ({ close }) => {
  const dispatch = useDispatch(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    [phone, setPhone] = useState(''),
    [errors, setErrors] = useState<Errors>({ phone: '' }),
    [disableBtn, setDisableBtn] = useState(true),
    captchaSiteKey = RECAPTCHA_SITEKEY,
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    });

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

  function validate(phone: string = '') {
    let errors = {
      phone: '',
    };

    if (!phone || !validateMobileNumber(phone)) {
      errors.phone = 'Invalid Phone';
    }

    return errors;
  }

  function openInfoModal(title: string, text: string, btnText: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} btnText={btnText} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function openOtpScreen(intent: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={phone} intent={intent} />,
        uid: 'OTP_SCREEN',
      })
    );
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let phone = e.target.value;
    setPhone(phone);
    let errors = validate(phone);
    if (!errors.phone) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }

  function requestOtp(intent: string = '', headers?: any) {
    let params = {
      username: phone,
      intent,
      username_type: 'phone',
      is_email_otp: isEmailOtp(),
    };
    setDisableBtn(true);
    api.user
      .requestOtp(params, headers)
      .then(() => {
        openOtpScreen(intent);
        setDisableBtn(false);
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_exists') {
          openInfoModal(
            languageMap[`Mobile number already exists`],
            languageMap[
              `Entered mobile number already linked to another account please try with a different mobile number`
            ],
            languageMap[`Change Mobile Number`]
          );
        } else if (errors && errors.phone === 'Invalid Mobile Number') {
          setErrors({
            phone: languageMap[`Invalid Mobile Number`],
          });
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          openInfoModal('', languageMap[`Too many OTP requests! Please try again in 24 hours.`], '');
        } else if (errors && errors.non_field_errors) {
          handlecontactOps(errors.non_field_errors);
        } else {
          errorHandler(error.response, true);
        }
        setDisableBtn(false);
      });
  }

  function handleNext(headers?: any) {
    let tempErrors = validate(phone);
    setErrors(tempErrors);
    if (tempErrors.phone) {
      return;
    }
    setDisableBtn(true);
    requestOtp('update_phone', headers);
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps: any) {
    let headers = {
      headers: {
        'G-Recaptcha-Response': captchaProps.token,
      },
    };

    if (captchaProps.btnName === 'next_btn') {
      handleNext(headers);
    }
  }

  function generateButton(): any {
    if (!systemSettings.should_validate_recaptcha) {
      return (
        <>
          <div className="buttons-wrapper">
            <Button className={'orange-button ' + (disableBtn ? 'disabled' : '')} onClick={() => handleNext()}>
              {languageMap[`Next`]}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
            <RecaptchaButton
              action={getCaptchaToken}
              btnType="button"
              name="next_btn"
              label={languageMap['Next']}
              disableBtn={disableBtn}
            />
          </GoogleReCaptchaProvider>
        </>
      );
    }
  }

  return (
    <div className="mobile-layout login-register login">
      <div className="header">
        <div className="back" onClick={close}>
          <img src={BackArrow} alt="back_arrow" />
        </div>
        <p className="text">{languageMap[`Change Mobile Number`]}</p>
      </div>
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img src={LogoPlus} alt="fairdee-logo" />
        </div>
        <h2 className="title-text">{languageMap[`Enter new mobile number`]}</h2>
        <p className="sub-text">
          {languageMap[`Enter the new mobile number that you’d like to use to login to your account.`]}
        </p>
        <div className="mobile-form">
          <div className={'form-field ' + (errors.phone ? 'error' : '')}>
            <InputPhoneNumber
              onChange={handleInputChange}
              name="mobile"
              value={phone}
              placeholder={languageMap[`Enter Mobile Number`] + 'asu'}
              autoFocus={true}
            />
            {errors.phone ? <InlineError text={errors.phone} className="" /> : null}
          </div>
        </div>
        {generateButton()}
      </div>
    </div>
  );
};

export default UpdatePhoneNumber;
