import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clearModal } from '../../../actions/modal';
import WelcomeBanner from '../../../images/fairdee-x-plus/auth/welcome-banner.svg';
import CloseIcon from '../../../images/fairdee-x-plus/common/close.svg';
import { RootState } from '../../../index';

interface Props {
  close?: () => void;
}

const WelcomeProfileNudge: React.FC<Props> = ({ close }) => {
  const user = useSelector((state: RootState) => state.user),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    dispatch = useDispatch(),
    history = useHistory();

  function goToProfile() {
    history.push('/profile');
    dispatch(clearModal());
  }

  return (
    <div className="mobile-layout login-register welcome-modal">
      <div className="content-wrapper">
        <img src={CloseIcon} alt="close" onClick={close} className="close" />
        <div className="img-wrap">
          <img src={WelcomeBanner} alt="welcome-banner" />
        </div>
        <p className="title-text">
          {languageMap[`Welcome`]} {user.fullname}!
        </p>
        <p className="text">
          {languageMap[`Don’t forget to complete your profile to start quoting and sell insurance to your customers!`]}{' '}
        </p>
        <Button className="orange-button" onClick={goToProfile}>
          {languageMap[`Complete Profile Now`]}
        </Button>
      </div>
    </div>
  );
};

export default WelcomeProfileNudge;
