import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import SubagentCommissionForm from '../../components/subagent/SubagentCommissionForm';
import SubAgentForm from '../../components/SubAgentForm';
import { default as cross, default as crossLg } from '../../images/close.svg';

class SubAgentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: props.currentTab || 'one',
      affiliate: props.affiliate,
    };
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  closeThisModal = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  handleChange = (event, currentTab) => {
    this.setState({
      currentTab,
    });
  };

  updateAffiliate = (updatedAffiliate) => {
    let affiliate = { ...this.state.affiliate, ...updatedAffiliate };
    this.setState({
      affiliate,
    });
  };

  next = () => {
    let { currentTab } = this.state;
    currentTab = 'two';
    this.setState({
      currentTab,
    });
  };

  render() {
    const languageMap = this.props.languageMap.components.editAffiliateModal;
    const { currentTab, affiliate } = this.state;
    let disabled = true;
    if (affiliate) {
      disabled = false;
    }
    function TabContainer(props) {
      return <div className="tab-container">{props.children}</div>;
    }

    return (
      <div className="fairdee-modal-wrapper large edit-affiliate-info">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close.bind(null, false)} />
          <div className="mobile-header">
            <p className="current-page" onClick={this.close.bind(null, false)}>
              <img src={crossLg} alt="" />
              <span className="title-text">{languageMap.subagentForm}</span>
            </p>
          </div>
          <h1 className="title-text">{languageMap.subagentForm}</h1>
          <div className="tabs-wrapper">
            <Tabs value={currentTab} onChange={this.handleChange} className="tabs">
              {!this.props.hideAffiliateForm && <Tab className="tab" value="one" label={languageMap.textTwo} />}
              <Tab className="tab" value="two" label={languageMap.commissionRates} disabled={disabled}></Tab>
            </Tabs>
            {currentTab === 'one' && !this.props.hideAffiliateForm && (
              <TabContainer>
                <SubAgentForm
                  affiliate={this.state.affiliate}
                  action={this.props.action}
                  isSubagentFlow={this.props.isSubagentFlow}
                  masterAgent={this.props.masterAgent}
                  close={this.closeThisModal}
                  next={this.next}
                  updateAffiliate={this.updateAffiliate}
                />
              </TabContainer>
            )}
            {currentTab === 'two' && (
              <TabContainer>
                <SubagentCommissionForm
                  action={this.props.action}
                  affiliate={this.state.affiliate}
                  close={this.closeThisModal}
                  masterAgent={this.props.masterAgent}
                  languageMap={this.props.languageMap.components.subagentPortal}
                />
              </TabContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SubAgentModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal, showModal })(SubAgentModal));
