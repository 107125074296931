import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import DropdownDatepicker from '../../components/datepicker/DropdownDatepicker';
import InlineError from '../../components/InlineError';
import { default as PROVINCES } from '../../constants/provinces.json';
import { isAdmin, toThaiDate } from '../../utils/helper';

const MOMENT = moment();

function SubtypeDetails(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const [formattedProvinces, setFormattedProvinces] = useState([]);
  const endorsementLangMap = languageMap.components.endorsement;
  const modalLangMap = endorsementLangMap.endorsementModal;

  useEffect(() => {
    setFormattedProvinces(
      PROVINCES.map((data) => {
        data.key = data.provincecode;
        data.text = data.provincename;
        data.value = data.provincename;

        return data;
      })
    );
  }, [props]);
  function shouldEnableField(key) {
    if (['insured_person', 'insured_address', 'insured_name', 'insured_dob'].includes(key)) {
      return props.subTypes.hasOwnProperty('insured_person') || props.subTypes.hasOwnProperty(key);
    }
    return props.subTypes.hasOwnProperty(key);
  }

  function getAddressValue(key) {
    return props.subTypes.insured_address && props.subTypes.insured_address[key]
      ? props.subTypes.insured_address[key]
      : '';
  }

  return (
    <Form className="no-frills-form">
      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        {endorsementLangMap.typeOfEndorsement.insured_person}
      </Label>
      <Form.Group widths={2}>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="insured_name">{endorsementLangMap.typeOfEndorsement.insured_name}</label>
          <input
            type="text"
            id="insured_name"
            name="insured_name"
            disabled={!shouldEnableField('insured_name')}
            onChange={props.handleChange.bind(null, 'insured_name')}
            value={props.subTypes.insured_name}
          />
          {props.errors.insured_name && <InlineError text={props.errors.insured_name} />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths={2} unstackable disabled={!shouldEnableField('insured_address')}>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="" className="form-label">
            {modalLangMap.firstLine}
          </label>
          <input
            type="text"
            placeholder={modalLangMap.firstLine}
            onChange={(e) => props.handleAddressChange(e, 'first_line')}
            value={getAddressValue('first_line')}
            autocomplete="off"
          />
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="" className="form-label open">
            {modalLangMap.province}
          </label>
          <Dropdown
            placeholder={modalLangMap.province}
            selection
            search
            searchInput={{ autoComplete: 'off' }}
            options={PROVINCES}
            onChange={props.handleProvinceChange}
            name="province"
            value={getAddressValue('province')}
          />
        </Form.Field>
        <Form.Field disabled={!props.districts.length || props.blockDataUpdate}>
          <label htmlFor="" className="form-label open">
            {modalLangMap.district}
          </label>
          <Dropdown
            placeholder={modalLangMap.district}
            selection
            search
            searchInput={{ autoComplete: 'off' }}
            options={props.districts}
            onChange={props.handleDistrictChange}
            name="district"
            value={getAddressValue('district')}
          />
        </Form.Field>
        <Form.Field disabled={!props.sub_districts.length || props.blockDataUpdate}>
          <label htmlFor="" className="form-label open">
            {modalLangMap.subDistrict}
          </label>
          <Dropdown
            placeholder={modalLangMap.subDistrict}
            selection
            search
            searchInput={{ autoComplete: 'off' }}
            options={props.sub_districts}
            onChange={props.handleSubDistrictChange}
            name="subdistrict"
            value={getAddressValue('subdistrict')}
          />
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="" className="form-label">
            {modalLangMap.postalCode}
          </label>
          <input
            type="number"
            placeholder={modalLangMap.postalCode}
            onChange={(e) => props.handleAddressChange(e, 'postal_code')}
            value={getAddressValue('postal_code')}
          />
          {props.errors.postal_code && <InlineError text={props.errors.postal_code} />}
        </Form.Field>
      </Form.Group>
      <Form.Field disabled={props.blockDataUpdate}>
        <label htmlFor="insured_dob">{endorsementLangMap.typeOfEndorsement.insured_dob}</label>
        <DropdownDatepicker
          selectedDate={props.subTypes.insured_dob ? toThaiDate(props.subTypes.insured_dob) : null}
          order={['day', 'month', 'year']}
          onDateChange={props.onDobChange}
          format="YYYY-MM-DD"
          startDate={
            props.subTypes.insured_dob
              ? toThaiDate(props.subTypes.insured_dob)
              : toThaiDate(MOMENT.clone().subtract(101, 'year'))
          }
          duration={`${isAdmin() ? '100 years' : '82 years'}`}
          errors={props.errors.dob} //Format: {day:'Error mesage', month:'Error message'..}
        />
      </Form.Field>

      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        Sum Insured
      </Label>
      <Form.Group widths={2} unstackable>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="sum_insured_increase">{endorsementLangMap.typeOfEndorsement.sum_insured_increase}</label>
          <input
            type="text"
            id="sum_insured_increase"
            name="sum_insured_increase"
            disabled={!shouldEnableField('sum_insured_increase')}
            onChange={props.handleChange.bind(null, 'sum_insured_increase')}
            value={props.subTypes.sum_insured_increase}
          />
          {props.errors.sum_insured_increase && <InlineError text={props.errors.sum_insured_increase} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="sum_insured_decrease">{endorsementLangMap.typeOfEndorsement.sum_insured_decrease}</label>
          <input
            type="text"
            id="sum_insured_decrease"
            name="sum_insured_decrease"
            disabled={!shouldEnableField('sum_insured_decrease')}
            onChange={props.handleChange.bind(null, 'sum_insured_decrease')}
            value={props.subTypes.sum_insured_decrease}
          />
          {props.errors.sum_insured_decrease && <InlineError text={props.errors.sum_insured_decrease} />}
        </Form.Field>
      </Form.Group>

      <Label size="medium" as="div" color="teal" className="mb-10 ml-0">
        Vehicle Details
      </Label>
      <Form.Group widths={2} unstackable>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="" className="form-label open">
            {modalLangMap.province}
          </label>
          {formattedProvinces && formattedProvinces.length && (
            <Dropdown
              disabled={!shouldEnableField('registration_province')}
              placeholder={modalLangMap.province}
              selection
              search
              options={formattedProvinces}
              searchInput={{ autoComplete: 'off' }}
              onChange={props.handleRegistrationProvinceChange}
              name="registration_province"
              value={props.subTypes.registration_province}
            />
          )}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="vehicle_number">{endorsementLangMap.typeOfEndorsement.vehicle_number}</label>
          <input
            type="text"
            id="vehicle_number"
            name="vehicle_number"
            disabled={!shouldEnableField('vehicle_number')}
            onChange={props.handleChange.bind(null, 'vehicle_number')}
            value={props.subTypes.vehicle_number}
          />
          {props.errors.vehicle_number && <InlineError text={props.errors.vehicle_number} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="make_model">{endorsementLangMap.typeOfEndorsement.make_model}</label>
          <input
            type="text"
            id="make_model"
            name="make_model"
            disabled={!shouldEnableField('make_model')}
            onChange={props.handleChange.bind(null, 'make_model')}
            value={props.subTypes.make_model}
          />
          {props.errors.make_model && <InlineError text={props.errors.make_model} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="vehicle_cc">{endorsementLangMap.typeOfEndorsement.vehicle_cc}</label>
          <input
            type="text"
            id="vehicle_cc"
            name="vehicle_cc"
            disabled={!shouldEnableField('vehicle_cc')}
            onChange={props.handleChange.bind(null, 'vehicle_cc')}
            value={props.subTypes.vehicle_cc}
          />
          {props.errors.vehicle_cc && <InlineError text={props.errors.vehicle_cc} />}
        </Form.Field>
        <Form.Field disabled={props.blockDataUpdate}>
          <label htmlFor="chassis_number">{endorsementLangMap.typeOfEndorsement.chassis_number}</label>
          <input
            type="text"
            id="chassis_number"
            name="chassis_number"
            disabled={!shouldEnableField('chassis_number')}
            onChange={props.handleChange.bind(null, 'chassis_number')}
            value={props.subTypes.chassis_number}
          />
          {props.errors.chassis_number && <InlineError text={props.errors.chassis_number} />}
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

export default connect(null, { showModal, hideModal, showNotification })(SubtypeDetails);
