import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { NOTIFICATION } from '../../constants/types';
import cross from '../../images/close.svg';
import crossLg from '../../images/cross-blue.svg';

const AFFILIATE_INCENTIVE_REASONS = ['self_serve'];

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  stepper: {
    padding: '24px 0',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    width: '100%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginTop: theme.spacing.unit * 2,
    position: 'relative',
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
    width: '100%',
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  errorTitle: {
    color: red[500],
  },
  fullWidth: {
    width: '100%',
  },
  inline: {
    flexGrow: 1,
    flexBasis: 0,
  },
  subTitle: {
    fontSize: '16px',
    color: '#1b1b1b',
    fontWeight: '600',
    marginBottom: '10px',
  },
  text: {
    fontSize: '14px',
    color: '#1b1b1b',
    marginBottom: '10px',
  },
  inlineWrap: {
    display: 'flex',
  },
});

class IncentivesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      incentives: AFFILIATE_INCENTIVE_REASONS.map((data) => {
        return {
          fairdee_sale: this.props.id || '',
          reason: data,
          amount: '',
          hasChanged: false,
          errors: {},
        };
      }),
      request_success: null,
      show_error_modal: false,
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data) {
      let emptyDiscounts = [];
      let types = {};
      let incentives = this.props.data;

      if (incentives.length) {
        incentives.forEach((data) => {
          data.errors = {};
          types[data.reason] = true;
        });
      }
      AFFILIATE_INCENTIVE_REASONS.forEach((d) => {
        window.console.log(d, types);
        if (!types[d]) {
          emptyDiscounts.push({
            fairdee_sale: '',
            reason: d,
            amount: '',
            hasChanged: false,
            errors: {},
          });
        }
      });

      incentives = incentives.concat(emptyDiscounts);
      window.console.log(incentives);
      this.setState({
        incentives,
      });
    }
  }

  handleChange = (name, index) => (event) => {
    let incentives = this.state.incentives;
    incentives[index][name] = event.target.value;
    incentives[index].hasChanged = true;
    this.setState({
      incentives,
    });
  };

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  notify = (message, type) => {
    this.props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  };

  done = () => {
    this.close();
  };

  validateIncentive = (data) => {
    let errors = {};
    if (!data.amount) {
      errors.amount = 'Required Field';
    }
    if ((data.amount || data.amount === '0') && !validator.isDecimal(data.amount)) {
      errors.amount = 'Invalid Value';
    }

    return errors;
  };

  createFairdeeSaleIncentiveRequest = (params) => {
    return api.crm.fairdeeSaleIncentive(params);
  };

  patchFairdeeSaleIncentiveRequest = (id, params = {}) => {
    window.console.log(params);
    return api.crm.patchFairdeeSaleIncentive(id, params);
  };

  createFairdeeSaleIncentives = () => {
    let promises = [];
    let hasError = false;
    window.console.log(this.state.incentives);
    this.state.incentives.forEach((data, index) => {
      if (data.hasChanged || (!data.id && data.incentive_type && (data.value || data.value === '0'))) {
        let errors = this.validateIncentive(data);
        let incentives = this.state.incentives;
        incentives[index].errors = errors;
        this.setState({
          incentives,
        });
        if (Object.keys(errors).length) {
          hasError = true;
          return;
        }
        let params = {
          fairdee_sale: this.props.id,
          reason: data.reason,
          amount: data.amount,
        };
        window.console.log(params);

        if (data.id) {
          promises.push(this.patchFairdeeSaleIncentiveRequest(data.id, params));
          return;
        }
        promises.push(this.createFairdeeSaleIncentiveRequest(params));
      }
    });
    if (!promises.length && this.props.isEdit && !hasError) {
      this.setState((state) => ({
        active_step: state.active_step + 1,
      }));
      return;
    } else if (!promises.length && !this.props.isEdit) {
      let data = this.state.incentives[0];
      let errors = this.validateIncentive(data);
      let incentives = this.state.incentives;
      incentives[0].errors = errors;
      this.setState({
        incentives,
      });
      return;
    } else if (!promises.length) {
      return;
    }
    this.setState({
      loading: true,
    });
    Promise.all(promises)
      .then((response) => {
        let incentives = this.state.incentives;
        response.forEach((resp) => {
          window.console.log(response, resp);
          incentives.forEach((data) => {
            data.hasChanged = false;
            if (!data.id && resp.reason === data.reason && resp.incentive_type === data.incentive_type) {
              data.id = resp.id;
            }
          });
        });
        this.setState((state) => ({
          loading: false,
          active_step: state.active_step + 1,
          request_success: true,
          incentives,
        }));
        if (this.props.action) {
          this.props.action();
        }
        this.close();
      })
      .catch((error) => {
        window.console.log(error.response);
        let api_form_errors;
        if (error.response && error.response.status === 400) {
          api_form_errors = error.response.data.errors;
        }
        if (error.response && error.response.data && error.response.data && error.response.data.errors) {
          if (typeof error.response.data.errors === 'object') {
            Object.keys(error.response.data.errors).forEach((key) => {
              window.console.log(error.response.data.errors[key]);
              error.response.data.errors[key].forEach((data, index) => {
                window.console.log(data);
                ((i) => {
                  setTimeout(() => {
                    this.notify(data, 'error');
                  }, 1000 + 2000 * i);
                })(index);
              });
            });
          }
        }
        this.setState((state) => ({
          loading: false,
          request_success: false,
          api_form_errors: api_form_errors,
          show_error_modal: Boolean(api_form_errors),
        }));
      });
  };

  render() {
    const { classes } = this.props;
    const { incentives } = this.state;

    return (
      <div className="fairdee-modal-wrapper small-medium customer-info refer-earn-modal">
        <div className="modal-content">
          <div className="mobile-header">
            <p className="current-page" onClick={this.close}>
              <img src={crossLg} alt="" />
              <span className="title-text"></span>
            </p>
          </div>
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div>
            {incentives.map((data, index) => {
              window.console.log(data.amount);
              return (
                <div key={index} className={classes.inlineWrap}>
                  <TextField
                    required
                    id="reason"
                    label="Reason"
                    className={classes.textField + ' ' + classes.inline}
                    value={data.reason || ''}
                    error={Boolean(data.errors.reason)}
                    helperText={data.errors.reason}
                    disabled
                  ></TextField>
                  <TextField
                    id="amount"
                    label="Amount"
                    className={classes.textField + ' ' + classes.inline}
                    value={data.amount || ''}
                    error={Boolean(data.errors.amount)}
                    helperText={data.errors.amount}
                    autoComplete="off"
                    required
                    onChange={this.handleChange('amount', index)}
                  />
                </div>
              );
            })}
            <div className="button-wrap">
              <Button
                onClick={this.createFairdeeSaleIncentives}
                className={classes.button}
                color="secondary"
                variant="contained"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IncentivesModal.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
  };
}

export default connect(mapStateToProps, { hideModal, showModal, showNotification })(
  withStyles(styles)(IncentivesModal)
);
