import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import TocIcon from '@material-ui/icons/Toc';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import addIcon from '../images/onboarding/add-icon.svg';
import iconOneActive from '../images/onboarding/icon-one-active.svg';
import iconOne from '../images/onboarding/icon-one.svg';
import { isDealerOrLeadgen, isSubAgent, isTelesales } from '../utils/helper';

class MobileBottomNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      affiliate: {},
      pageType: window.location.pathname || '/buy-insurance',
    };
  }

  componentDidMount() {
    this.getAffiliateUser();
  }

  getAffiliateUser = () => {
    let affiliate = this.props.affiliate;
    let showCompulsoryInsurance = false;

    if (affiliate && affiliate.credit && affiliate.credit.length) {
      affiliate.credit.forEach((data) => {
        if (data.product === 'combined_credit') {
          showCompulsoryInsurance = true;
        }
      });
    }
    this.setState({
      showCompulsoryInsurance,
      affiliate,
    });
  };

  buyInsurance = (type) => {
    if (type === 'compulsary') {
      this.state.affiliate.credit.forEach((data) => {
        if (data.product === 'combined_credit' && parseFloat(data.available, 10) <= 0) {
          this.openMsgModal();
        }
      });
      this.props.history.push('/compulsory-insurance');
    } else if (type === 'voluntary') {
      this.props.history.push('/voluntary-insurance');
    } else if (type === 'bike') {
      this.props.history.push('/bike-insurance');
    } else if (type === 'taxi') {
      this.props.history.push('/taxi-insurance');
    } else if (type === 'pa') {
      this.props.history.push('/personal-accident');
    }
  };

  action = (data) => {
    this.setState({
      pageType: data.name,
    });
    // this.props.openModal(data.name, true);
    this.props.history.push(data.name);
  };

  render() {
    const languageMap = this.props.languageMap.components.mobileBottomNavigation;
    const { pageType } = this.state;

    let baseOptions = [
      {
        text: languageMap.textOne,
        icon: iconOne,
        name: '/leads',
        activeIcon: iconOneActive,
      },
      {
        text: languageMap.textTwo,
        icon: <SignalCellularAltIcon />,
        name: '/policies',
        activeIcon: <SignalCellularAltIcon className="active" />,
        type: 'icon',
      },
      {
        text: languageMap.textThree,
        icon: addIcon,
        name: '/buy-insurance',
        activeIcon: addIcon,
      },
    ];

    if (!isDealerOrLeadgen() && !isSubAgent()) {
      baseOptions.push({
        text: languageMap.textFour,
        icon: <DashboardOutlinedIcon />,
        name: '/affiliate-dashboard',
        activeIcon: <DashboardOutlinedIcon className="active" />,
        type: 'icon',
      });
      baseOptions.push({
        text: languageMap.textEight,
        icon: <TocIcon />,
        name: '/tutorials',
        activeIcon: <TocIcon className="active" />,
        type: 'icon',
      });
    } else if (isTelesales()) {
      baseOptions = [
        {
          text: languageMap.textOne,
          icon: iconOne,
          name: '/leads',
          activeIcon: iconOneActive,
        },
        {
          text: languageMap.textThree,
          icon: addIcon,
          name: '/buy-insurance',
          activeIcon: addIcon,
        },
        {
          text: languageMap.textTwo,
          icon: <SignalCellularAltIcon />,
          name: '/policies',
          activeIcon: <SignalCellularAltIcon className="active" />,
          type: 'icon',
        },
      ];
    } else {
      baseOptions.push({
        text: languageMap.textEight,
        icon: <TocIcon />,
        name: '/tutorials',
        activeIcon: <TocIcon className="active" />,
        type: 'icon',
      });
    }

    return (
      <div className="mobile-bottom-nav">
        {baseOptions.map((data, index) => {
          return (
            <div
              key={index}
              className={
                (data.name === '/buy-insurance' ? 'large-icon' : '') + (pageType === data.name ? ' active' : '')
              }
              onClick={this.action.bind(null, data)}
            >
              {data.type === 'icon' ? (
                <div className="img-wrap">{pageType === data.name ? data.activeIcon : data.icon}</div>
              ) : (
                <div className="img-wrap">
                  <img src={data.activeIcon} alt="" className={pageType === data.name ? 'show' : 'hide'} />
                  <img src={data.icon} alt="" className={pageType !== data.name ? 'show' : 'hide'} />
                </div>
              )}

              <p className="text">{data.text}</p>
            </div>
          );
        })}
      </div>
    );
  }
}

MobileBottomNav.propTypes = {};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    user: state.user,
    affiliate: state.affiliate,
  };
}

export default withRouter(connect(mapStateToProps, {})(MobileBottomNav));
