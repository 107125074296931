import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import validator from 'validator';
import { affiliateProfile } from '../../../actions/affiliate';
import { userProfile } from '../../../actions/auth';
import { clearModal, showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { CLIENT_ID } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import RightArrow from '../../../images/fairdee-x-plus/icons/right-arrow.svg';
import { RootState } from '../../../index';
import {
  errorHandler,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
  setUserData,
  validateMobileNumber,
} from '../../../utils/helper';
import { Mixpanel } from '../../../utils/tracking';
import InlineError from '../../InlineError';
import InputPhoneNumber from '../common/InputPhoneNumber';
import AccountFoundModal from './AccountFoundModal';
import AccountNotFoundModal from './AccountNotFoundModal';
import ContactUsModal from './ContactUsModal';
import CreatePassCode from './CreatePassCode';
import Login from './LoginPhone';
import RegisterPhone from './RegisterPhone';
import './_login-register.scss';

interface Props {
  close?: () => void;
  isIg?: boolean;
}

interface Errors {
  mobile: string;
  message?: string;
  email?: string;
}

interface ErrorResponse {
  response: any;
}

const RegisterEmail: React.FC<Props> = ({ close, isIg }) => {
  const dispatch = useDispatch(),
    languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    [state, setState] = useState({
      email: '',
      mobile: '',
    }),
    [errors, setErrors] = useState<Errors>({ mobile: '' }),
    history = useHistory(),
    affiliate = useSelector((state: RootState) => state.affiliate),
    user = useSelector((state: RootState) => state.user),
    [disableBtn, setDisableBtn] = useState(true);

  let path = '/signup',
    params = window.location.search;

  if (params) {
    path = `${path}${params}`;
  }

  useEffect(() => {
    if (user.id) {
      if (user.affiliates.length > 1) {
        redirectToWelcome();
      } else {
        setAffiliateProfile();
        window.location.reload();
      }
      dispatch(clearModal());
    }
  }, [user]);

  useEffect(() => {
    if (affiliate.id) {
      dispatch(clearModal());
      if (affiliate.is_signup_complete) {
        history.push('/buy-insurance');
      } else {
        history.push(path);
      }
    }
  }, [affiliate]);

  function redirectToWelcome() {
    history.push('/welcome');
  }

  function openAccountNotFound() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountNotFoundModal username={state.mobile} />,
        uid: 'ACCOUNT_NOT_FOUND',
      })
    );
  }

  function openAccountFound(errors: any) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <AccountFoundModal email={state.email} errors={errors} />,
        uid: 'ACCOUNT_FOUND',
      })
    );
  }

  function login() {
    dispatch(clearModal());
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Login isIg={isIg} />,
        uid: 'LOGIN',
      })
    );
  }

  function openPhoneSignup() {
    dispatch(clearModal());
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <RegisterPhone />,
        uid: 'EMAIL_SIGNUP',
      })
    );
  }

  function handleMoreHelp() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <ContactUsModal />,
        uid: 'CONTACT_US_MODAL',
      })
    );
  }

  function openStePasscode() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <CreatePassCode action={handlePassCode} username={state.email} />,
        uid: 'CREATE_PASSCODE',
      })
    );
  }

  function handlePassCode(passcode: string) {
    signupEmail(passcode);
  }

  function validate(state: any) {
    let errors = {
      mobile: '',
      email: '',
    };

    if (!state.mobile || !validateMobileNumber(state.mobile)) {
      errors.mobile = languageMap['Invalid Mobile Number'];
    }

    if (!state.email || !validator.isEmail(state.email)) {
      errors.email = languageMap['Invalid Email'];
    }

    return errors;
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value,
      name = e.target.name;

    // if (name === 'mobile' && parseInt(value) > 10) {
    //   return;
    // }

    let tempState = { ...state, [name]: value };
    setState(tempState);
    let errors = validate(tempState);
    if (!errors.mobile && !errors.email) {
      setDisableBtn(false);
    }
  }

  function getUserProfile() {
    dispatch(userProfile(isIg));
  }

  function setAffiliateProfile() {
    dispatch(affiliateProfile());
  }

  function signupEmail(password: string) {
    let params = {
      phone: state.mobile,
      email: state.email,
      client_id: CLIENT_ID,
      is_inspection_garage: false,
      password,
    };
    if (isIg) {
      params.is_inspection_garage = true;
    }
    setDisableBtn(true);
    api.user
      .signupEmail(params)
      .then((response) => {
        setAxiosHeaders(response.access_token);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setUserData({ accessToken: response.access_token });
        Mixpanel.track('Login/signup', {
          object: 'Login',
          objectContext: {
            parent: 'Otp Modal',
          },
          action: 'Successful',
        });
        getUserProfile();
      })
      .catch((error: ErrorResponse) => {
        let errors: any = errorHandler(error.response);
        if (errors && errors.error === 'account_does_not_exist') {
          openAccountNotFound();
        } else if (errors && errors.error === 'account_exists') {
          openAccountFound(errors);
        } else if (errors && errors.mobile === 'Invalid Mobile Number') {
          setErrors({
            mobile: languageMap[`Invalid Mobile Number`],
          });
        } else if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          setErrors({
            mobile: languageMap[`Too many OTP requests! Please try again in 24 hours.`],
          });
        } else {
          errorHandler(error.response, true);
        }
        setDisableBtn(false);
      });
  }

  function handleNext() {
    let tempErrors = validate(state);
    setErrors(tempErrors);

    if (tempErrors.mobile || tempErrors.email) {
      return;
    }

    openStePasscode();
  }

  return (
    <div className="mobile-layout login-register login">
      <div className="header">
        {!isIg ? (
          <div className="back" onClick={close}>
            <img src={BackArrow} alt="back_arrow" />
          </div>
        ) : null}
        <p className="text">{languageMap[`Signup via Email`]} </p>
      </div>
      <div className="content-wrapper">
        <h2 className="title-text">{languageMap[`Create an Account`]}</h2>
        <p className="sub-text">
          {
            languageMap[
              `Please enter a mobile number and email address that you would like to use to log into your account.`
            ]
          }
        </p>
        <div className="mobile-form">
          <div className={'form-field ' + (errors.mobile ? 'error' : '')}>
            <label htmlFor="">{languageMap[`Mobile Number`]} </label>
            <InputPhoneNumber
              onChange={handleInputChange}
              name="mobile"
              value={state.mobile}
              placeholder={languageMap[`Enter Mobile Number`]}
              autoFocus={true}
            />
            {errors.mobile ? <InlineError text={errors.mobile} className="" /> : null}
          </div>
          <div className={'form-field ' + (errors.mobile ? 'error' : '')}>
            <label htmlFor="">{languageMap[`Email`]} </label>
            <input
              type="text"
              onChange={handleInputChange}
              name="email"
              value={state.email}
              placeholder={languageMap[`example@email.com`]}
            />
            {errors.email ? <InlineError text={errors.email} className="" /> : null}
          </div>
        </div>
        <div className="buttons-wrapper">
          <Button className={'orange-button ' + (disableBtn ? 'disabled' : '')} onClick={() => handleNext()}>
            {languageMap[`Signup via Email`]}
          </Button>
          <p className="or">
            <span>{languageMap[`or`]} </span>
          </p>
          <Button className="orange-button" onClick={() => openPhoneSignup()}>
            {languageMap[`Sign up via Mobile`]}
          </Button>
        </div>
        <div className="link-wrap">
          <p className="center">
            {languageMap[`Already have an account?`]}{' '}
            <span className="link" onClick={login}>
              {languageMap[`Login Here`]}
            </span>
            <img src={RightArrow} alt="" />
          </p>
        </div>
        <div className="bottom-section">
          <div className="link-wrap">
            <p className="center">
              {languageMap[`Need more help?`]}{' '}
              <span className="link" onClick={handleMoreHelp}>
                {languageMap[`Click here`]}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterEmail;
