export const actions = {
  Clicked: 'Clicked',
  Selected: 'Selected',
  Unselected: 'Unselected',
  Login: 'Login',
  Signout: 'Signout',
  Loaded: 'Loaded',
  Redirected: 'Redirected',
  Hovered: 'Hovered',
  Updated: 'Updated',
  Landed: 'Landed',
  Viewed: 'Viewed',
  Added: 'Added',
  Skipped: 'Skipped',
  Quote: 'Quote',
  Downloaded: 'Downloaded',
  Completed: 'Completed',
  Delete: 'Delete',
  Upload: 'Upload',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Signup: 'Signup',
};

export const events = {
  buyInsurance: 'Buy Insurance ',
  buyInsuranceScrollMap: 'Buy Insurance: Scroll Map ',
  buyInsuranceIncomeDashboard: 'Buy Insurance: Income dashboard ',
  buyInsuranceIncomeDashboardShowBreakup: 'Buy Insurance: Income dashboard  c breakup ',
  buyInsuranceCommissionRates: 'Buy Insurance: Commission Rates View ',
  buyInsuranceMonthChange: 'Buy Insurance: Month ',
  buyInsuranceDateFilter: 'BUY Insurance: Date Filter ',
  learning: 'Learning: ',
  referral: 'Referral: ',
  profileDataLoaded: 'Profile: Data Load',
  profileMobileModal: 'Profile: Mobile number change Modal ',
  profileMobileNoChange: 'Profile: Mobile number change Modal Next ',
  profilePersonalDetailsModal: 'Profile: Personal Details Modal ',
  profilePersonalDetailsModalEdit: 'Profile: Personal Details Modal - Edit ',
  profilePersonalDetailsModalSave: 'Profile: Personal Details Modal - Save ',
  profilleAddressModal: 'Profile: Address Modal ',
  profileAddressModalEdit: 'Profile: Address Modal - Edit ',
  profileAddressModalSave: 'Profile: Address Modal - Save ',
  profileBrokerLicenseModal: 'Profile: Broker License Modal ',
  profileBrokerLicenseModalEdit: 'Profile: Broker License Modal - Edit ',
  profileBrokerLicenseModalSave: 'Profile: Broker License Modal - Save ',
  profileBankAccountModal: 'Profile: Bank Account Modal ',
  profileBankAccountModalEdit: 'Profile: Bank Account Modal - Edit ',
  profileBankAccountModalSave: 'Profile: Bank Account Modal - Save ',
  profileAgentDetailsModal: 'Profile: Agent Details Modal ',
  profileAgentDetailsModalEdit: 'Profile: Agent Details Modal - Edit ',
  profileAgentDetailsModalSave: 'Profile: Agent Details Modal - Save ',
  profileReferralModal: 'Profile: Referral Modal ',
  vmiBrand: 'Voluntary Insurance: Make ',
  vmiModel: 'Voluntary Insurance: Model ',
  vmiYear: 'Voluntary Insurance: Year ',
  vmiCC: 'Voluntary Insurance: CC ',
  vmiSubModel: 'Voluntary Insurance: Submodel ',
  vmiKnownSubModel: 'Voluntary Insurance: Known Submodel  ',
  vmiUnknownSubModel: 'Voluntary Insurance: Unknown Submodel ',
  vmiInsuranceClass: 'Voluntary Insurance: Insurance class ',
  vmiSumInsured: 'Voluntary Insurance: Sum Insured value ',
  vmiCarRegistrationProvince: 'Voluntary Insurance: Car Registration Province ',
  vmiPreviousInsurer: 'Voluntary Insurance: Previous Insurer ',
  vmiGarageType: 'Voluntary Insurance: Garage Type ',
  vmiGetQuotes: 'Voluntary Insurance: Get Quotes ',
  getQuotesDataLoaded: 'Get Quotes: Data Load',
  getQuotesFilterFloodProtection: 'Get Quotes: Flood_protection Filter ',
  getQuotesFilterGarageType: 'Get Quotes: Garage_type Filter ',
  getQuotesFilterInsureanceClass: 'Get Quotes: Insurance_class Filter ',
  getQuotesFilterInsurer: 'Get Quotes: Insurer Filter ',
  getQuotesFilterSumInsured: 'Get Quotes: Sum Insured Filter ',
  getQuotesFilterDeductible: 'Get Quotes: Deductible Filter ',
  getQuotesFilter3rdParty: 'Get Quotes: 3rd Party Property Filter ',
  getQuotesFilterPageView: 'Get Quotes: Page view Filter ',
  getQuotesInsurerCard: 'Get Quotes: Insurer Card Checkbox ',
  getQuotesInsurer: 'Get Quotes: Insurer ',
  getQuotesInsuranceClass: 'Get Quotes: Insurance class ',
  getQuotesSeeDetails: 'Get Quotes: See details ',
  getQuotesTandC: 'Get Quotes: Terms & Conditions ',
  getQuotesPriceList: 'Get Quotes:  Pricelist Selection ',
  getQuotesSendToCustomer: 'Get Quotes: Send Price to Customer ',
  getQuotesReportSale: 'Get Quotes: Report Sale Button ',
  getQuotesBrokerDetailsModal: 'Get Quotes: Broker details modal ',
  getQuotesBrokerDetailsContinue: 'Get Quotes: Broker details continue ',
  getQuotesCOABanner: 'Get Quotes: COA Banner ',
  getQuotesAddSumInsuredModal: 'Get Quotes: Add Sum Insured Modal ',
  getQuotesAddSumInsuredModalSubmit: 'Get Quotes: Add Sum Insured Modal - Submit Button',
  getQuotesDiscountModal: 'Get Quotes: Discount Modal ',
  getQuotesDiscountModalNext: 'Get Quotes: Discount ',
  getQuotesData: 'Get Quotes: Data ',
  getQuotesPage: 'Get Quotes: Page ',
  getQuotesCOAModal: 'Get Quotes: COA Modal ',
  getQuotesCOAModalSendApproval: 'Get Quotes: COA Modal - Send Approval Details ',
  getQuotesCustomerDetails: 'Get Quotes:Customer Details ',
  getQuotesAccessaryModal: 'Get Quotes: Accesories modal ',
  quoteAffilateDownload: 'Quote Affiliate: Download ',
  quoteAffilateShare: 'Quote Affiliate: Share ',
  quoteAffiliateInstalment: 'Quote Affiliate: With/Without Instalment ',
  pageLanding: 'Page Landed ',
  pageViewed: 'Page Viewed ',
  policyLandingTab: 'Policies landing: ',
  policyLandingSubView: 'Policies landing: ',
  plicyLandingSubViewSearchFilter: 'Policies landing: Sub View Seacrh Filter ',
  plicyLandingSubViewDateFilter: 'Policies landing: Sub View Date Filter ',
  leadsGoToQuotes: 'Leads: Go to Quotes Button ',
  leadsPhone: 'Leads: Phone Icon ',
  leadsAlertNoCustomer: 'Leads: Alert Modal - No customer number Found ',
  leadsAlertAction: 'Leads: Alert Modal Got it or back button ',
  leadsNoResult: 'Leads: No Results Modal ',
  leadsDataLoaded: 'Leads: Data Load',
  leadsExit: 'Leads: Exit ',
  pipNoResults: 'PIP: No Results Modal ',
  pipGoToWizards: 'PIP: Go To Wizard Button ',
  pipAlertNoCustomer: 'Pip: Alert Modal - No customer number Found ',
  pipPhone: 'Pip: Phone Icon ',
  pipDownloadModal: 'Pip: Download Document Modal ',
  pipExit: 'Pip: Exit ',
  pipDownloadModalDownload: 'PIP: Download Document Modal ',
  pipDataLoaded: 'Pip: Data Load',
  salesDataLoaded: 'Sales: Data Load',
  salesNoResult: 'Sales: No Results Modal viewed ',
  salesPhone: 'Sales: Phone Icon ',
  salesAlertNoCustomer: 'Sales: Alert Modal - No customer number Found ',
  salesDownloadModal: 'Sales: Download Document Modal ',
  salesTrackPolicyModal: 'Sales: Track Policy ',
  salesViewDetailsModal: 'Sales: View Details ',
  salesTrackPolicyModalTrackLink: 'Sales: Track Policy Tracking Link ',
  instalmentDataLoaded: 'Instalments: Data Load',
  instalmentNoResult: 'Instalments: No Results Modal viewed ',
  instalmentPhone: 'Instalments: Phone Icon ',
  instalmentAlertNoCustomer: 'Instalments: Alert Modal - No customer number Found ',
  instalmentDownloadModal: 'Instalments: Download Document Modal ',
  instalmentGoToWizards: 'Instalments: Go To Wizard Button ',
  instalmentExit: 'Instalments: Exit ',
  renewalDataLoaded: 'Renewals: Data Load',
  renewalGoToQuotes: 'Renewals: Go to Quotes Button ',
  renewalPhone: 'Renewals: Phone Icon ',
  renewalAlertNoCustomer: 'Renewals: Alert Modal - No customer number Found ',
  renewalAlertAction: 'Renewals: Alert Modal Got it or back button ',
  renewalNoResult: 'Renewals: No Results Modal ',
  renewalExit: 'Renewals: Exit ',
  renewalCompare: 'Renewals: Compare ',
  renewalReceipt: 'Renewals: Receipt ',
  renewalReceiptView: 'Renewals: Receipt View ',
  motorDocumentsDataLoaded: 'Motor Documents: Data Load',
  motorDocumentsDownload: 'Motor Documents: Download ',
  motorDocumentsExit: 'Motor Documents: Exit',
  tools: 'Tools: ',
  toolsBrandModel: 'Tools: Car ',
  toolsEngine: 'Tools: ',
  toolsTaxDueDate: 'Tools: Tax Due date ',
  toolsCalculateTax: 'Tools: Calculate Road Tax ',
  cancelledPolicyDataLoaded: 'Cancelled Policies: Data Load',
  cancelledPolicyNoResult: 'Cancelled Policies: No Results Modal viewed ',
  cancelledPolicyPhone: 'Cancelled Policies: Phone Icon ',
  cancelledPolicyAlertNoCustomer: 'Cancelled Policies: Alert Modal - No customer number Found ',
  cancelledPolicyDownloadModal: 'Cancelled Policies: Download Document Modal ',
  cancelledPolicyGoToWizards: 'Cancelled Policies: Go To Wizard Button ',
  cancelledPolicyExit: 'Cancelled Policies: Exit ',
  wizardForm1ClientName: 'Wizard Form 1: client_first_name ',
  wizardForm1Fields: 'Wizard Form 1: ',
  wizardForm1ClientTitle: 'Wizard Form 1: client_title ',
  wizardForm1Checkbox: 'Wizard Form 1:  Checkbox ',
  wizardForm1CoverNote: 'Wizard Form 1: Generate Cover Note ',
  wizardForm1AddPolicy: 'Wizard Form 1: Add Policy ',
  wizardForm1DownloadInvoice: 'Wizard Form 1: Download Invoice ',
  wizardForm1Discount: 'Wizard Form 1: Add/Update Discount ',
  wizardForm1RecordEndorsments: 'Wizard Form 1: Record Endorsements ',
  wizardForm1CreatedByDetails: 'Wizard Form 1: Created by Details ',
  wizardForm1DocsApprovedInvoice: 'Wizard Form 1: Docs Approved by Invoice ',
  wizardForm1VehicleDocument: 'Wizard Form 1: Vehicle Document ',
  wizardFormStep: 'Wizard Form: Step button ',
  wizardForm1Continue: 'Wizard Form 1: Continue button',
  wizardForm1StepCompleted: 'Wizard Form 1: Step ',
  wizardForm1Step1: 'Wizard Form 1: Step 1 ',
  wizardForm1Page: 'Wizard Form 1: Page ',
  wizardForm2DocumentUpload: 'Wizard Form 2: Document ',
  wizardForm2DocumentDelete: 'Wizard Form 2: Document ',
  wizardForm2DocumentDeleteConfirm: 'Wizard Form 2: Document Delete Confirmation ',
  wizardForm2Continue: 'Wizard Form 2: Continue button',
  wizardForm2StepCompleted: 'Wizard Form 2: Step ',
  wizardForm3Field: 'Wizard Form 3: Field ',
  wizardForm3Continue: 'Wizard Form 3: Continue button ',
  wizardForm3StepComplete: 'Wizard Form 3: Step ',
  wizardForm4Title: 'Wizard Form 4: Client Title ',
  wizardForm4Field: 'Wizard Form 4: Field ',
  wizardForm4Provience: 'Wizard Form 4: Provience ',
  wizardForm4District: 'Wizard Form 4: District ',
  wizardForm4SubDistrict: 'Wizard Form 4: Sub District ',
  wizardForm4Address: 'Wizard Form 4: Address ',
  wizardForm4Continue: 'Wizard Form 4: Continue button ',
  wizardForm4StepComplete: 'Wizard Form 4: Step ',
  wizardForm4AdditionalDriver: 'Wizard Form 4: Additional Driver button ',
  wizardForm4DOB: 'Wizard Form 4: DOB ',
  wizardForm4ClientTitle: 'Wizard Form 4: Client Title',
  wizardForm5Title: 'Wizard Form 5: Client Title ',
  wizardForm5Field: 'Wizard Form 5: Field ',
  wizardForm5Provience: 'Wizard Form 5: Provience ',
  wizardForm5District: 'Wizard Form 5: District ',
  wizardForm5SubDistrict: 'Wizard Form 5: Sub District ',
  wizardForm5Address: 'Wizard Form 5: Address ',
  wizardForm5Continue: 'Wizard Form 5: Continue button ',
  wizardForm5StepComplete: 'Wizard Form 5: Step ',
  wizardForm5UseDriverAddress: 'Wizard Form 5: Use Driver Address ',
  wizardForm5AddBeneficiary: 'Wizard Form 5: Add Beneficiary button ',
  wizardForm5PolicyStartDate: 'Wizard Form 5: Policy Start Date ',
  wizardForm5DownloadCredit: 'Wizard Step 5: Download credit form',
  wizardForm5UploadCredit: 'Wizard Step 5: Upload credit form',
  wizardForm6Fields: 'Wizard Form 6: Fields ',
  wizardForm6CarInspectionDate: 'Wizard Form 6: Car Inspection Completion Date ',
  wizardForm6SoldDate: 'Wizard Form 6: Sold On Date ',
  wizardForm6FileVieweModal: 'Wizard Form 6: File Viewe Modal ',
  wizardForm6FileViewerModal: 'Wizard Form 6: File Viewer Modal ',
  wizardForm6AdjustDiscount: 'Wizard Form 6: QR Discount ',
  wizardForm6QrPayment: 'Wizard Form 6: QR Payment amount ',
  wizardForm6PaymentProof: 'Wizard Form 6: Payment Proof ',
  wizardForm6Continue: 'Wizard Form 6: Continue button ',
  wizardForm6StepComplete: 'Wizard Form 6: Step ',
  wizardForm6FileUploaded: 'Wizard Form 6: File ',
  login: 'login ',
  signup: 'signup ',
  fmTopUp: 'Buy Insurance: Top Up ',
  fmTopUpQR: 'Buy Insurance: Top Up QR Page ',
  fmDashboardInfo: 'Buy Insurance: Dashboard Info ',
  fmTeamsRequestUpgrade: 'Teams: Request Upgrade ',
  fmTeamsDashboardInfo: 'Teams: Teams Dashboard Info ',
  fmTeamsMyTeamsIcon: 'Teams: My Teams Info ',
  fmTeamsDownloadReport: 'Teams: Download Report ',
  fmTeamsSortByModal: 'Teams: Sort by Modal ',
  fmTeamsSortByModalHighestLevel: 'Teams: Sort by Modal - Filter Level Highest ',
  fmTeamsSortByModalLowestLevel: 'Teams: Sort by Modal - Filter Level Lowest ',
  fmTeamsSortByModalHighestDownlines: 'Teams: Sort by Modal - Filter Downlines Highest ',
  fmTeamsSortByModalLowestDownlines: 'Teams: Sort by Modal - Filter Downlines Highest ',
  fmTeamsSortByModalHighestJoiningDate: 'Teams:  Sort by Modal - Filter Joining Date Highest',
  fmTeamsSortByModalLowestJoiningDate: 'Teams:  Sort by Modal - Filter Joining Date Lowest',
  fmTeamsSortByModalApplyClicked: 'Teams: Sort by Modal - Filter Apply ',
  fmTeamsShowDownLines: 'Teams: Show Downlines ',
  fmTeamsCall: 'Teams: Call ',
  fmTeamsDateFilter: 'Teams: Date Filter ',
  getQuotesCoaInformation: 'Get Quotes: Coa Information bar',
  getQuotesRemovesAll: 'Get Quotes: Remove all ',
  buyInsuranceAction: 'Buy Insurance:',
  tabClicked: 'Tab ',
  affiliateLandingSearch: 'Affiliate: Search Filter Updated',
  affiliateLandingDateFilter: 'Affiliate: Date Filter Updated',
  affiliateLandingHideRenewal: 'Affiliate: Hide Renewal',
  inspectionForm: 'Self Photo Inspection',
};
